import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../../../../configuration/settings.service';
import {MapLayersManager} from '../map-layers-manager';
import {RasterLayerSpecification, RasterSourceSpecification} from 'maplibre-gl';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherRadarLayerService {

  static readonly RADAR_LAYER_ID = 'weather-radar';
  static readonly RADAR_SOURCE_ID = 'weather-radar-source';
  static readonly RADAR_SERVICE_URL = 'https://nowcoast.noaa.gov/geoserver/weather_radar/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image/png8&TRANSPARENT=true&LAYERS=base_reflectivity_mosaic&STYLES=&CRS=EPSG:3857&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256';
  private mapLayersManager: MapLayersManager;
  private isEnabled: boolean;
  settingsSubscription: Subscription;
  readonly refreshInterval = 600; // 10 minutes
  refreshTimer = null;

  constructor(
      private http: HttpClient,
      private settingsService: SettingsService,
  ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSourceAndLayer();

    if (this.isEnabled) {
      this.connectToManager();
    }
  }

  release() {
    this.settingsSubscription?.unsubscribe();
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }
    this.cancelRefresh();
    this.mapLayersManager = null;
  }

  addSourceAndLayer(resetRefreshTimer: boolean = false) {
    if (this.isEnabled) {
      const shouldBeVisible = this.settingsService.getBooleanValue(
          SettingsService.WEATHER_RADAR_LAYER_KEY
      );
      const layer = {
        id: WeatherRadarLayerService.RADAR_LAYER_ID,
        source: WeatherRadarLayerService.RADAR_SOURCE_ID,
        type: 'raster',
        layout: {
          visibility: shouldBeVisible ? 'visible' : 'none',
        },
        paint: {
          'raster-opacity': 0.5,
        },
      } as RasterLayerSpecification;

      const source = {
        type: 'raster',
        tiles: [WeatherRadarLayerService.RADAR_SERVICE_URL],
        tileSize: 256,
      } as RasterSourceSpecification;

      this.mapLayersManager.addSource(WeatherRadarLayerService.RADAR_SOURCE_ID, source);
      this.mapLayersManager.addLayer(layer);
      if (resetRefreshTimer) {
        this.resetTimer(shouldBeVisible);
      }
    }
  }

  private updateSource() {
    const source = this.mapLayersManager?.getRasterSource(WeatherRadarLayerService.RADAR_SOURCE_ID);
    if (!!source) {
      source.setTiles([WeatherRadarLayerService.RADAR_SOURCE_ID]);
    }
  }

  private handleLayerVisibilityChange(makeVisible: boolean) {
    this.mapLayersManager.setLayerVisibility(WeatherRadarLayerService.RADAR_LAYER_ID, makeVisible);
    this.resetTimer(makeVisible);
  }

  private resetTimer(setTimer: boolean) {
    if (setTimer) {
      this.scheduleRefresh();
    } else {
      this.cancelRefresh();
    }
  }

  private refreshHandler() {
    this.updateSource();
    this.scheduleRefresh();
  }

  private scheduleRefresh() {
    const that = this;
    if (!!this.mapLayersManager) {
      this.refreshTimer = setTimeout(() => {
        that.refreshHandler();
      }, that.refreshInterval * 1000);
    }
  }

  private cancelRefresh() {
    if (!!this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  private connectToManager() {
    const that = this;

    this.settingsSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.WEATHER_RADAR_LAYER_KEY) {
          that.handleLayerVisibilityChange(newSettings.value);
        }
      }
    });
  }
}
