import { ObservationType } from './observation-type';

export interface ObservationDialogData {
  name: string;
  duration: number;
  isAudible: boolean;
  mapColor: string;
  vehicleGroups: VehicleGroupData[];
  defaultMapColor: string;
}

export interface EditObservationGroup {
  name: string;
  duration: number;
  isAudible: boolean;
  mapColor: string;
}


export interface CreateObservationGroup {
  name: string;
  duration: number;
  isAudible: boolean;
  mapColor: string;
}

export interface ObservationGroupDto {
  name: string;
  durationInSeconds: number;
  isAudible: boolean;
  mapColor: string;
}

export interface VehicleGroupData {
  id: number;
  name: string;
  title: string;
}

export class ObservationTypeGroup {
  isAudible = false;

  constructor(public id: number,
              public name: string,
              public durationInSeconds: number,
              public observationTypes: ObservationType[],
              public readonly: boolean,
              public vehicleGroups: VehicleGroupData[],
              public mapColor: string,
              isAudible?: boolean,
  ) {
    this.vehicleGroups = vehicleGroups;
    this.isAudible = !!isAudible;
  }

  public static observationTypeGroupCompareFn = (a, b) => a.name.localeCompare(b.name);
}
