<mat-card appearance="outlined" class="dialog-card">
    <mat-card-title>Mapping setting for form processing</mat-card-title>
    <mat-card-content>
        <ng-container>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Mapper type</mat-label>
                <mat-select
                        [disabled]="!data.editing"
                        [(ngModel)]="question.answerMapper"
                        (ngModelChange)="updateLabels()">
                    <mat-option (click)="question.answerMapper = null"></mat-option>
                    <mat-option
                            *ngFor="let mapper of data.answerProviders"
                            [value]="mapper.id">
                        {{mapper.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <br/>
        <ng-container>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Mapper value</mat-label>
                <mat-select
                        [disabled]="!data.editing || !question.answerMapper"
                        [(ngModel)]="question.answerMapperData"
                        (ngModelChange)="updateLabels()">
                    <mat-option (click)="question.answerMapperData = null"></mat-option>
                    <mat-option
                            *ngFor="let mapper of data.answerMappings[question.answerMapper]" [value]="mapper.id">
                        {{mapper.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </mat-card-content>
    <mat-card-footer align="right">
        <button mat-button mat-dialog-close type="button">
            Cancel
        </button>
        <button mat-button type="button" color="accent" cdkFocusInitial [mat-dialog-close]="question" [disabled]="!data.editing">
            Update
        </button>
    </mat-card-footer>
</mat-card>

