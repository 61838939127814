<div class="result-box">
    <mat-spinner *ngIf="isSending"></mat-spinner>
    <div *ngIf="!!result">
        {{ result }}
    </div>
</div>

<ng-container *ngIf="!!configuration && !!asset && !!asset.shiftId">
    <form [formGroup]="formGroup" (ngSubmit)="sendMessage()">
        <mat-form-field class="message__input w-100" appearance="outline" color="accent">
            <mat-label>Subject:</mat-label>
            <input
                    matInput
                    formControlName="subject"
                    autocomplete="off"
                    placeholder="Your message subject..."
                    maxlength="500"
            />
        </mat-form-field>
        <mat-form-field class="message__input w-100" appearance="outline" color="accent">
            <mat-label>Message:</mat-label>
            <textarea
                    matInput
                    formControlName="body"
                    autocomplete="off"
                    rows="15"
                    placeholder="Your message text..."
                    maxlength="2000"
            ></textarea>
        </mat-form-field>
        <div class="action__row">
            <button
                    color="accent"
                    mat-button
                    type="submit"
                    [disabled]="formGroup.controls.subject.pristine || formGroup.controls.body.pristine || !formGroup.valid"
            >
                Send
            </button>
        </div>
    </form>
</ng-container>

<div *ngIf="!!asset && !asset.shiftId">
    <span>Asset is offline</span>
</div>

<div *ngIf="!asset">
    <span>Select asset in left panel</span>
</div>
