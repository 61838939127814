<h1 mat-dialog-title>
    <ng-container *ngIf="isModeCreate()">Create a new User</ng-container>
    <ng-container *ngIf="isModeUpdate()">Update User {{data.user.email}}</ng-container>
</h1>

<mat-dialog-content *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!isLoading">

    <form [formGroup]="userForm">

        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Role</mat-label>
                <mat-select formControlName="userType">
                    <mat-option *ngFor="let userType of userTypes" [value]="userType">{{userType.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>First Name</mat-label>
                <input matInput
                       autocomplete="off"
                       placeholder="First Name"
                       formControlName="givenName"
                       required>
                <mat-error *ngIf="ngGivenName?.invalid && ngGivenName?.errors?.required">
                    First name is required.
                </mat-error>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Last Name</mat-label>
                <input matInput
                       autocomplete="off"
                       placeholder="Last Name"
                       formControlName="familyName"
                       required>
                <mat-error *ngIf="ngFamilyName?.invalid && ngFamilyName?.errors?.required">
                    Last name is required.
                </mat-error>
            </mat-form-field>
        </p>

        <p *ngIf="isModeCreate() && ngEmailPrefix">
            <mat-form-field class="input-65" appearance="outline" color="accent">
                <mat-label>Email</mat-label>
                <input matInput
                       autocomplete="off"
                       placeholder="Email Prefix"
                       formControlName="emailPrefix"
                       required>
                <mat-error *ngIf="ngEmailPrefix?.invalid && ngEmailPrefix?.errors?.required">
                    Email is required.
                </mat-error>
                <mat-error *ngIf="ngEmailPrefix?.invalid && ngEmailPrefix?.errors?.pattern">
                    Email format is not valid.
                </mat-error>
            </mat-form-field>
            <span>{{configuration.driverSuffix}}</span>
        </p>

        <p *ngIf="ngPhoneNumber">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Phone Number</mat-label>
                <input matInput
                       autocomplete="off"
                       placeholder="Phone Number"
                       type="tel"
                       formControlName="phoneNumber">
                <mat-error *ngIf="ngPhoneNumber?.invalid && ngPhoneNumber?.errors?.pattern">
                    Please enter a valid phone number. ({{ngPhoneNumber?.errors?.pattern?.requiredPattern}})
                </mat-error>
            </mat-form-field>
        </p>

        <p *ngIf="ngHideFromLogin">
            <mat-slide-toggle labelPosition="before" formControlName="hideFromLogin">
                <mat-label>Hide from PlowOps mobile login</mat-label>
            </mat-slide-toggle>
        </p>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>
                Cancel
            </button>
            <button mat-button color="accent" [mat-dialog-close]="data" [disabled]="userForm.invalid">
                <ng-container *ngIf="isModeCreate()">Create</ng-container>
                <ng-container *ngIf="isModeUpdate()">Update</ng-container>
            </button>
        </mat-dialog-actions>
    </form>
</mat-dialog-content>

