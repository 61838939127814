import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {InsightsComponent} from './pages/insights/components/insights.component';
import {SettingsComponent} from './pages/settings/components/settings.component';
import {LiveMapModule} from './pages/live-map/live-map.module';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {SettingsModule} from './pages/settings/settings.module';
import {UnauthorizedComponent} from './pages/public/components/unauthorized/unauthorized.component';
import {InsightsModule} from './pages/insights/insights.module';
import {ShiftMapComponent} from './pages/shift-map/components/shift-map.component';
import {AboutComponent} from './pages/settings/components/about/about.component';
import {CallbackComponent} from './pages/public/components/callback/callback.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'live-map/assets' },
  { path: 'live-map', pathMatch: 'full', redirectTo: 'live-map/assets' },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'live-map',
        loadChildren: () => LiveMapModule,
      },
      {
        path: 'shift-detail/:id',
        component: ShiftMapComponent,
      },
      {
        path: 'insights',
        component: InsightsComponent,
        loadChildren: () => InsightsModule,
      },
      {
        path: 'settings',
        component: SettingsComponent,
        loadChildren: () => SettingsModule,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
      routes,
      {
        useHash: false,
        enableTracing: false
      }
      )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
