<div class="observations-container">

  <div class="observation-lines">
    <ng-container *ngFor="let item of items">
      <div [id]="item.id">
        <app-action-menu-item-2line
                [input]="item"
        ></app-action-menu-item-2line>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!!items && items.length === 0" class="no-items">
    No Results match current filters
  </div>
</div>

<!-- right panel -->
<ng-template #rightPanelTemplate let-option>
</ng-template>
