<div class="routes-right-panel">
    <div class="panel__header">
        <span class="routes-title">Status</span>
        <div class="time-filter">
            <button
                    mat-button
                    color="accent"
                    [matMenuTriggerFor]="timeFilterMenu"
            >
                <div class="time-filter-button">
                        <span *ngIf="timeFromHours === 24">
                            Last 24 hours
                        </span>
                    <span *ngIf="timeFromHours === 168">
                            Last Week
                        </span>
                    <mat-icon>
                        arrow_drop_down
                    </mat-icon>
                </div>
            </button>
            <mat-menu #timeFilterMenu="matMenu">
                <button
                        mat-menu-item
                        (click)="changeTimeFilter(24)"
                >
                    Last 24 hours
                </button>
                <button
                        mat-menu-item
                        (click)="changeTimeFilter(168)"
                >
                    Last Week
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="panel__body">
        <div *ngIf="!completedItems" class="panel__body__row">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!!completedItems && !!configuration" class="panel__body__row text-secondary">
            <div>
                <span class="metric">{{ assignmentInProgressCount }}</span> Active assignment<span *ngIf="assignmentInProgressCount !== 1">s</span>
            </div>
            <div>
                <span class="metric">{{ completedItems.length }}</span> Completed assignment<span *ngIf="completedItems.length !== 1">s</span>
            </div>
            <div>
                <span class="metric">{{ (totalDistance / 1000 || 0) | distance:configuration.useMetricSystem:false }}</span>
                Total&nbsp;<span *ngIf="configuration.useMetricSystem">kilometers</span><span *ngIf="!configuration.useMetricSystem">miles</span>
            </div>
            <div>
                <span class="metric">{{ (totalTime / 3600 || 0) | number:'1.0-2' }}</span>
                Total hours
            </div>
            <div>
                <span class="metric">{{ (avgDistancePerAssignment / 1000 || 0) | distance:configuration.useMetricSystem:false }}&nbsp;</span>
                <span *ngIf="configuration.useMetricSystem">Kilometers</span><span *ngIf="!configuration.useMetricSystem">Miles</span>
                averaged per assignment
            </div>
            <div>
                <span class="metric">{{ (avgTimePerAssignment / 3600 || 0) | number:'1.0-2' }}</span>
                Hrs averaged per assignment
            </div>
        </div>
    </div>

    <div class="panel__header" *ngIf="!!completedItems && completedItems.length > 0">
        <span class="routes-title">Completed Assignments</span>
    </div>
    <div class="panel__body">
        <div *ngIf="!completedItems" class="panel__body__row">
            <mat-spinner></mat-spinner>
        </div>
        <ng-container *ngIf="!!completedItems">
            <ng-container *ngFor="let item of completedItems">
                <app-action-menu-item [input]="item" [small]="true">
                </app-action-menu-item>
            </ng-container>
        </ng-container>
    </div>
</div>
