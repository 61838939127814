import {MifNodeDto} from './MifNodeDto';
import {MifFormOptionDto} from './MifFormOptionDto';

export class MifFormDto {
  id: number;
  journalId: number;
  title: string;
  note: string;
  options: MifFormOptionDto[];
  hasRemarks: boolean;
  settings: MifFormSettingsDto;
  created: Date;
  modified: Date;
  questionsCount: number;
  nodes: MifNodeDto[];

  constructor(id: number,
              journalId: number,
              title: string,
              note: string,
              options: MifFormOptionDto[],
              hasRemarks: boolean,
              settings: MifFormSettingsDto,
              created: Date,
              modified: Date,
              questionsCount,
              nodes: MifNodeDto[]) {
    this.id = id;
    this.journalId = journalId;
    this.title = title;
    this.note = note;
    this.options = options;
    this.hasRemarks = hasRemarks;
    this.settings = settings;
    this.created = created;
    this.modified = modified;
    this.questionsCount = questionsCount;
    this.nodes = nodes;
  }
}

export class MifFormSettingsDto {
  enableEmailNotifications: boolean;
  emailNotificationType?: MifFormEmailNotificationType;
  emailRecipients?: string[];

  constructor() {
    this.enableEmailNotifications = false;
  }
}

export enum MifFormEmailNotificationType {
  ALL = 'ALL',
  ON_DEFECT = 'ON_DEFECT',
  ON_OUT_OF_SERVICE = 'ON_OUT_OF_SERVICE',
}
