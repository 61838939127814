<div class="toggle-wrapper mat-elevation-z2">
    <div>
        Legacy Mode
    </div>
    <div>
        <mat-slide-toggle
                class="mat-small"
                [(ngModel)]="legacyMode"
                (change)="toggleMode()"
        ></mat-slide-toggle>
    </div>
</div>
