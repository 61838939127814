import {MifNodeType} from './MifNodeType';
import {MifNodeTypes} from './MifNodeTypes';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MifNodeDialogComponent} from '../../../form/mif-node-dialog/mif-node-dialog.component';
import {MifGroupDto} from '../../dto/MifGroupDto';
import {MifQuestionDto} from '../../dto/MifQuestionDto';
import {MifSectionDto} from '../../dto/MifSectionDto';

export class MifFormType implements MifNodeType {

  constructor() {}

  nodeLabel(): string {
    return `form`;
  }

  nodeType(): MifNodeTypes {
    return MifNodeTypes.FORM;
  }

  showEditDialog(dialogs: MatDialog): MatDialogRef<MifNodeDialogComponent> {
    throw new Error('cannot show dialogs for Form-type node');
  }

  sectionDto(externalId: number, order: number): MifSectionDto {
    return null;
  }

  groupDto(externalId: number, order: number): MifGroupDto {
    return null;
  }

  questionDto(externalId: number, order: number): MifQuestionDto {
    return null;
  }

  hasNodeTypeInstanceRemarks(): boolean {
    return false;
  }
}
