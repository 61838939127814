<div class="setup-page-container">
    <div class="header-container">
        Inspection forms
    </div>
    <div class="content-container">
        <div class="selection-container">
            <app-manage-dvir-questions></app-manage-dvir-questions>
        </div>
    </div>
</div>








