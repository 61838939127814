import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../../../../../shared/models/route';
import {ActionMenuItem} from '../../../../../../shared/models/action-menu-item.class';
import {RoutesService} from '../../../../../../data/routes/routes.service';
import {LiveMapDataService} from '../../../../services/live-map-data.service';

@Component({
  selector: 'app-route-selector',
  templateUrl: './route-selector.component.html',
  styleUrls: ['./route-selector.component.scss']
})
export class RouteSelectorComponent implements OnInit {

  @Output() itemSelected: EventEmitter<RouteHierarchyItem> = new EventEmitter<RouteHierarchyItem>();

  routeItems: RouteHierarchyItem[];
  currentLevelItems: ActionMenuItem[] = [];

  path: string[] = [];
  selectedItem: RouteHierarchyItem;

  constructor(
      private routesService: RoutesService,
      private liveMapDataService: LiveMapDataService,
  ) {
  }

  ngOnInit() {
    this.liveMapDataService.routeConfiguration$.subscribe(routeConfiguration => {
      this.routeItems = routeConfiguration.map(routeConfig => {
        return this.routeConfigToHierarchy(routeConfig);
      });
      this.updateUiModel();
    });
  }

  getPathLabel(): string {
    return RouteHierarchyItem.getPathLabel(this.path, this.routeItems);
  }

  showUpperHierarchyLevel() {
    this.path.pop();
    this.selectedItem = null;
    this.itemSelected.emit(null);
    this.updateUiModel();
  }

  public resetSelectedItem() {
    this.selectedItem = null;
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItem {
    const items = RouteHierarchyItem.getVisibleItems(routeConfiguration.configuration.children, routeConfiguration.id);
    return {
      routeId: -1,
      routeName: routeConfiguration.name,
      children: items,
      childrenAttribute: '',
      value: String(routeConfiguration.id)
    };
  }

  private isSelected(routeHierarchyItem: RouteHierarchyItem): boolean {
    if (!!this.selectedItem) {
      return this.selectedItem.value === routeHierarchyItem.value;
    } else {
      return false;
    }
  }

  private selectItem(routeHierarchyItem: RouteHierarchyItem) {
    if (!!routeHierarchyItem.children) {
      this.viewChildren(routeHierarchyItem);
    } else {
      this.selectedItem = routeHierarchyItem;
      this.itemSelected.emit(this.selectedItem);
      this.updateUiModel();
    }
  }

  private viewChildren(routeHierarchyItem: RouteHierarchyItem) {
    this.path.push(routeHierarchyItem.value);
    this.selectedItem = null;
    this.itemSelected.emit(null);
    this.updateUiModel();
  }

  private updateUiModel() {
    // based on path and selectedItem update currentLevelItems
    if (this.path.length === 0) {
      this.currentLevelItems = this.routeItems.map(routeItem => this.routeHierarchyItemToActionMenuItem(routeItem));
      return;
    }
    this.currentLevelItems = RouteHierarchyItem.getByPath(this.path, this.routeItems)
        .children
        .map(routeItem => this.routeHierarchyItemToActionMenuItem(routeItem));
  }

  private routeHierarchyItemToActionMenuItem(routeHierarchyItem: RouteHierarchyItem): ActionMenuItem {
    return new ActionMenuItem(
        routeHierarchyItem.routeId,
        'route',
        routeHierarchyItem.routeId === -1 ? routeHierarchyItem.routeName : routeHierarchyItem.value,
        null,
        null,
        !!routeHierarchyItem.children ? 'chevron_right' : null,
        () => this.isSelected(routeHierarchyItem),
        null,
        () => this.selectItem(routeHierarchyItem),
        null,
        null,
        []
    );
  }
}
