import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-line-dash-array-view',
  templateUrl: './line-dash-array-view.component.html',
  styleUrls: ['./line-dash-array-view.component.scss']
})
export class LineDashArrayViewComponent {

    @Input() color = 'grey';
    @Input() gapColor = 'white';
    @Input() dashArray: number[];

    protected readonly Math = Math;

    getDashArrayPixelLength(dashArray: number[]) {
        return dashArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }
}
