import { trigger, style, transition, animate } from '@angular/animations';

export const loadAnimations = {
  opacityLoadIn: trigger('opacityIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('200ms ease-in', style({ opacity: 1 })),
    ]),
    transition(':leave', [animate('100ms ease-in', style({ opacity: 0 }))]),
  ]),
};
