import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {RouteAssignment, RouteAssignmentQueue, VehicleRouteAssignmentStatus} from '../../shared/models/route-assignment';

@Injectable({
  providedIn: 'root'
})
export class RouteAssignmentService {

  constructor(private http: HttpClient) { }

  getRouteAssignments(
      timeFrom: Date,
      vehicleId: number,
      shiftId: number,
      routeConfigId: number,
      routeIds: number[],
      includeCompleted: boolean = true,
      includeDeleted: boolean = false
  ) {
    let params: HttpParams = new HttpParams()
        .set('includeCompleted', includeCompleted)
        .set('includeDeleted', includeDeleted);
    if (!!timeFrom) {
      params = params.set('timeFrom', timeFrom.toISOString());
    }
    if (!!vehicleId) {
      params = params.set('vehicleId', vehicleId);
    }
    if (!!shiftId) {
      params = params.set('shiftId', shiftId);
    }
    if (!!routeConfigId) {
      params = params.set('routeConfigId', routeConfigId);
    }
    if (!!routeIds && routeIds.length > 0) {
      params = params.set('routeIds', routeIds.join(':::'));
    }
    return this.http.get<JsonApiResponse<RouteAssignment[]>>(
        `${environment.services.service}v1/route-assignment/list`, {params}
    )
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  createRouteAssignment(assignment: RouteAssignment, queueOrder: RouteAssignmentQueue) {
    return this.http.post<JsonApiResponse<RouteAssignment[]>>(
        `${environment.services.service}v1/route-assignment/create/${queueOrder}`,
        assignment)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        ).toPromise();
  }

  deleteRouteAssignment(assignment: RouteAssignment) {
    return this.http.post(
        `${environment.services.service}v1/route-assignment/delete`,
        assignment,
    ).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  getVehicleStatus() {
    return this.http.get<JsonApiResponse<VehicleRouteAssignmentStatus[]>>(
        `${environment.services.service}v1/route-assignment/vehicle-status`, {}
    )
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }
}
