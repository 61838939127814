import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationModel, FeatureFlag, PublicPortalSettings} from '../shared/models/configuration.model';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../http.error.handler';
import { environment } from 'src/environments/environment';
import {BehaviorSubject} from 'rxjs';
import {JsonApiResponse} from '../shared/models/JsonApiResponse';
import {MapLayerConfiguration} from '../shared/models/map-layer.model';
import {TrackStyles} from './model/TrackStyles';
import {TrackStylesService} from './track-styles.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configUrl = `${environment.services.service}`;

  private configurationModel = new BehaviorSubject<ConfigurationModel>(null);
  sharedConfigurationModel = this.configurationModel.asObservable();

  trackStyles = new TrackStyles();
  trackStylesChangedSubject = new BehaviorSubject<TrackStyles>(this.trackStyles);

  constructor(
    private http: HttpClient,
    private trackStylesService: TrackStylesService) {
  }

  refreshTrackStyles() {
    this.trackStylesService.loadTrackStyles()
      .then(trackStyles => {
        this.trackStyles = trackStyles;
        this.trackStylesChangedSubject.next(this.trackStyles);
      });
  }

  refreshConfiguration() {
    this.getConfiguration()
        .toPromise()
        .then(
            (response: JsonApiResponse<ConfigurationModel>) => {
              // initialize shared config model
              this.nextConfigurationModel(response.data);
            }, // success path
            (error) => console.log(error)
        );
  }

  private getConfiguration()  {
    return this.http.get<JsonApiResponse<ConfigurationModel>>(this.configUrl + `v1/configuration/currentTenant`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  private nextConfigurationModel(model: ConfigurationModel) {
    this.configurationModel.next(model);
  }

  getMapLayers(isPublic: boolean) {
    return this.http.get<JsonApiResponse<MapLayerConfiguration[]>>(this.configUrl + `v1/configuration/mapLayer/public/${isPublic}`)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  updateMapLayers(mapLayers: MapLayerConfiguration[], isPublic: boolean): Promise<JsonApiResponse<MapLayerConfiguration[]>> {
    const requestUrl = `${environment.services.service}v1/configuration/mapLayer/public/${isPublic}`;
    return this.http.post<JsonApiResponse<MapLayerConfiguration[]>>(requestUrl, mapLayers).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateRouteMapLayer(routeConfigurationId: number): Promise<JsonApiResponse<MapLayerConfiguration>> {
    const requestUrl = `${environment.services.service}v1/configuration/mapLayer/routeConfiguration/${routeConfigurationId}`;
    return this.http.post<JsonApiResponse<MapLayerConfiguration>>(requestUrl, {}).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  getCustomerId(): Promise<JsonApiResponse<string>> {
    const requestUrl = `${environment.services.service}v1/configuration/customerId`;

    return this.http.get<JsonApiResponse<string>>(requestUrl)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  getPublicPortalSettings(): Promise<JsonApiResponse<PublicPortalSettings>> {
    const requestUrl = `${environment.services.service}v1/configuration/publicPortalSettings`;

    return this.http.get<JsonApiResponse<PublicPortalSettings>>(requestUrl)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  updatePublicPortalSettings(settings: PublicPortalSettings) {
    const requestUrl = `${environment.services.service}v1/configuration/publicPortalSettings`;
    return this.http.post(requestUrl, settings).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateLogo(logo: string) {
    const requestUrl = `${environment.services.service}v1/configuration/logo`;
    return this.http.post(requestUrl, logo).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateGuestDriverFeature(allowGuestDrivers: boolean) {
    const requestUrl = `${environment.services.service}v1/configuration/guestDrivers/${allowGuestDrivers}`;
    return this.http.post(requestUrl, {}).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Returns true if there is a feature flag in the list and its value is true.
   * @param flags feature flags
   * @param featureFlag feature flag
   */
  hasFeatureFlag(flags: FeatureFlag[], featureFlag: string): boolean {
    return flags && flags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

}
