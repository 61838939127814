<h1 mat-dialog-title>
    {{ data.dialogTitle }}
</h1>

<mat-dialog-content>
    <div class="data-section">
        {{ data.dialogSectionTitle }}
    </div>
    <div class="data-section-wrapper">

        <mat-form-field class="form-input" appearance="outline" color="accent">
            <mat-label>{{ data.textFieldLabel }}</mat-label>
            <input matInput (keydown.enter)="buttonClickedSave()" [(ngModel)]="data.textField" cdkFocusInitial >
        </mat-form-field>

        <mat-checkbox *ngIf="textData" class="form-input" [(ngModel)]="textData.notApplicable">Allow N/A option</mat-checkbox>

        <mat-checkbox *ngIf="baseData" class="form-input" [(ngModel)]="baseData.hasRemarks">Remarks</mat-checkbox>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        Cancel
    </button>
    <button mat-button color="accent" (click)="buttonClickedSave()" [disabled]="!isDialogValid()">
        {{ data.okButtonLabel }}
    </button>
</mat-dialog-actions>
