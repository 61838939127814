import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CartegraphConfiguration,
  CartegraphProcessing, CartegraphProcessingNotification,
  ProcessingType
} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  ProcessTypeSelection
} from '../cartegraph-config/cartegraph-settings/cartegraph-settings-import-filters.component';


@Component({
  selector: 'app-cartegraph-settings-processing',
  templateUrl: './cartegraph-settings-processing.component.html',
  styleUrls: ['../cartegraph-config/cartegraph-settings/cartegraph-settings.scss'],
})
export class CartegraphSettingsProcessing implements OnInit {

  @Output()
  configurationChange = new EventEmitter<CartegraphConfiguration>();

  @Input()
  configuration: CartegraphConfiguration;

  processing: CartegraphProcessing;
  emails: string;
  excludeFilter: string;

  processingTypes: ProcessTypeSelection[] = [
    {
      type: ProcessingType.UNDEFINED,
      note: 'Undefined. Tasks won\'t goto OpenGov.'
    },
    {
      type: ProcessingType.ROSEMOUNTMN,
      note: 'Custom 1, e.g. Rosemount,MN'
    },
    {
      type: ProcessingType.WINDSORCO,
      note: 'Custom 2, e.g. Windsor,CO'
    }
  ];

  uiError: string;
  isLoading: boolean;

  constructor(private cartegraphManagementService: CartegraphManagementService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.processing = this.configuration?.settings?.processing ?? new CartegraphProcessing();
    this.processing.notification = this.processing.notification ?? new CartegraphProcessingNotification();
    this.emails = this.processing.notification.notificationEmails.join(',');
    this.excludeFilter = this.processing.notification.excludeFilter.join(',');
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }


  writeChanges() {
    this.uiError = null;
    this.isLoading = true;
    this.cartegraphManagementService.updateProcessing(this.processing).then(response => {
      if (!!response.error) {
        this.uiError = response.error;
        this.showSnackBar('Failed to write changes.');
      } else {
        this.configuration = response.data;
        this.init();
        this.configurationChange.emit(response.data);
      }
      this.showSnackBar('Configuration successfully updated');
    }).catch(error => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  updateEmails(value: string) {
    this.processing.notification.notificationEmails = [];
    value?.split(',').forEach(email => {
      const emailTrimmed = email.trim();
      if (emailTrimmed.match(/[^@]+@.+\..+/g)) {
        this.processing.notification.notificationEmails.push(emailTrimmed);
      }
    });
    console.log('emails', this.processing.notification.notificationEmails);
  }

  updateExcludeFilter(value: string) {
    this.processing.notification.excludeFilter = [];
    value?.split(',').forEach(filter => {
      const trimmed = filter.trim();
      if (trimmed.length > 0) {
        this.processing.notification.excludeFilter.push(trimmed);
      }
    });
    console.log('exclude filter', this.processing.notification.excludeFilter);
  }

}
