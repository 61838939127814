import { NgModule } from '@angular/core';
import {ManageVehiclesComponent} from './manage-vehicles.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {
  DialogConfirmDeleteVehicleComponent,
  DialogCreateVehicleComponent,
  DialogMoveVehicleComponent,
  DialogUpdateVehicleComponent
} from './dialogs-vehicle/vehicle-dialog-components';
import { VehicleGroupComponent } from './vehicle-group/vehicle-group.component';
import {UniqueValueDirective} from '../../../../shared/directives/unique-value.directive';
import {ImportVehiclesComponent} from './import-vehicles.component';
import {RouterModule} from '@angular/router';
import {ManageVehiclesMainComponent} from './manage-vehicles-main.component';
import {ImportVehiclesCartegraphComponent} from './vehicle-import-cartegraph/import-vehicles-cartegraph.component';
import { EquipmentComponent } from './equipment/equipment.component';
import {DigitalSpreaderComponent} from './digital-spreader/digital-spreader.component';
import {
  DialogConfirmDeleteVehicleCategoryComponent,
  DialogCreateVehicleCategoryComponent, DialogUpdateVehicleCategoryComponent
} from './dialogs-vehicle-group/vehicle-group-dialog-components';


@NgModule({
  declarations: [
    ManageVehiclesMainComponent,
    ManageVehiclesComponent,
    ImportVehiclesComponent,
    ImportVehiclesCartegraphComponent,
    DialogConfirmDeleteVehicleCategoryComponent,
    DialogConfirmDeleteVehicleComponent,
    DialogCreateVehicleCategoryComponent,
    DialogCreateVehicleComponent,
    DialogUpdateVehicleCategoryComponent,
    DialogUpdateVehicleComponent,
    DialogMoveVehicleComponent,
    VehicleGroupComponent,
    UniqueValueDirective,
    EquipmentComponent,
    DigitalSpreaderComponent
  ],
    exports: [
        ManageVehiclesMainComponent,
        ManageVehiclesComponent,
        ImportVehiclesComponent,
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
  ]
})
export class VehicleManagementModule { }
