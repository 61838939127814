<div class="main">
    <p class="p-15 m-25" *ngIf="!authRetry">Setting everything up...you are getting redirected in
        <b>{{ timerValue }}</b> seconds ...
    </p>

    <p class="p-15 m-25" *ngIf="authRetry">
        Authentication failed.
        <button mat-raised-button color="accent" (click)="retry()">Retry</button>
    </p>

    <p class="ui-error p-15 m-25" *ngIf="authError"><small>{{ authError }}</small></p>
</div>

