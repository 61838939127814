import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import {MaterialModule} from './shared/material/material.module';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {StoreModule} from '@ngrx/store';
import {SharedModule} from './shared/shared.module';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {LiveMapModule} from './pages/live-map/live-map.module';
import {InsightsModule} from './pages/insights/insights.module';
import {SettingsModule} from './pages/settings/settings.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {liveMapReducer} from './pages/live-map/store/live-map.reducer';
import {Router, RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './router-strategy';
import {AuthInterceptor, AuthModule, LogLevel} from 'angular-auth-oidc-client';
import {environment} from '../environments/environment';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RightDrawerComponent} from './layouts/right-drawer/right-drawer.component';
import {ShiftMapModule} from './pages/shift-map/shift-map.module';
import {PublicModule} from './pages/public/public.module';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardLayoutComponent,
    RightDrawerComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PublicModule,
    LiveMapModule,
    SettingsModule,
    InsightsModule,
    ShiftMapModule,
    HttpClientModule,
    StoreModule.forRoot({ ui: liveMapReducer }),
    AuthModule.forRoot({
      config: {
        postLoginRoute: '/',
        unauthorizedRoute: '/unauthorized',
        authority: environment.authentication.authority.startsWith('/')
            ? `${window.location.origin}${environment.authentication.authority}` : environment.authentication.authority,
        redirectUrl: `${window.location.origin}${environment.base_href}callback`,
        postLogoutRedirectUri: `${window.location.origin}${environment.base_href}`,
        triggerAuthorizationResultEvent: true,
        clientId: 'plowOpsUser',
        autoUserInfo: false,
        scope: 'openid email roles tenant auth_roles',
        responseType: 'code',
        silentRenew: true,
        startCheckSession: false,
        silentRenewUrl: `${window.location.origin}${environment.base_href}silent-renew.html`,
        useRefreshToken: false,
        historyCleanupOff: true,
        autoCleanStateAfterAuthentication: true,
        ignoreNonceAfterRefresh: true,
        logLevel: LogLevel.None,
        secureRoutes: [
          'https://dev.plowops.com',
          'https://test.plowops.com',
          'https://portal.plowops.com',
          'http://127.0.0.1'
        ],
      }
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-5SBHCNH'
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
