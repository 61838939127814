import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ImageWithDriverAndVehicle} from '../../../../../shared/models/image';
import {InfiniteScroll} from '../../../../../shared/tools/InfiniteScroll';
import {InsightsRoute} from '../../../insights-routing.module';

@Component({
  selector: 'app-image-report-table',
  templateUrl: './image-report-table.component.html',
  styleUrls: ['./image-report-table.component.scss']
})
export class ImageReportTableComponent {

  selectedRowId: number;

  @ViewChild(MatSort) sort: MatSort;
  @Input() dataSource: MatTableDataSource<ImageWithDriverAndVehicle>;
  @Output() sortChangedEvent: EventEmitter<void> = new EventEmitter();
  @Output() scrolledToBottomEvent: EventEmitter<void> = new EventEmitter();
  @Output() imageSelected: EventEmitter<ImageWithDriverAndVehicle> = new EventEmitter<ImageWithDriverAndVehicle>();

  displayedColumns: string[] = [
    'shift.id',
    'time',
    'driver',
    'vehicle',
  ];

  InsightsRoute = InsightsRoute;

  getSort(): string | undefined {
    if (!this.sort.active) {
      return undefined;
    }

    return `${this.sort.active},${this.sort.direction}`;
  }

  onScroll(e) {
    InfiniteScroll.onScroll(e, () => this.scrolledToBottomEvent.emit());
  }

  highlight(image: ImageWithDriverAndVehicle){
    this.selectedRowId = image.id;
    this.imageSelected.emit(image);
  }
}
