<div class="setup-page-container">

    <div class="header-container">
        <h2 class="header-container-filters">
            My Inspection Forms
        </h2>
        <div class="header-container-buttons">
            <div *ngIf="loadState === LoadState.LOADED_OK" class="">

                <button mat-button
                        color="accent"
                        [disabled]="false"
                        [matMenuTriggerFor]="newFormMenu">
                    <div class="new-button">
                        <span>Create New Form</span>
                        <mat-icon>
                            arrow_drop_down
                        </mat-icon>
                    </div>
                </button>
                <mat-menu #newFormMenu="matMenu">
                    <button mat-menu-item
                            (click)="buttonClickedNew()">
                        From Scratch
                    </button>
                    <button mat-menu-item
                            [matMenuTriggerFor]="templateFormMenu">
                        Choose Template
                    </button>
                </mat-menu>
                <mat-menu #templateFormMenu="matMenu">
                    <button mat-menu-item *ngFor="let form of systemForms"
                            (click)="buttonClickAddFromTemplate(form)">
                        <span [ngClass]="{'bolder' : form.default}">{{form.form.title}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="content-container" *ngIf="loadState === LoadState.LOADED_OK">
        <div class="selector-container">
            <div class="selector-container-list">
                <ng-container *ngFor="let leftItem of items">
                    <app-action-menu-item
                            style="width: 100%;"
                            [input]="leftItem"
                    >
                    </app-action-menu-item>
                </ng-container>
            </div>
        </div>
        <div class="divider"></div>

        <div class="selection-container">
            <h2>Form Questions</h2>
            <app-inspection-form-tree
                    class="questions-tree"
                    *ngIf="!!selectedInspectionFormNodes"
                    [isEditable]="false"
                    [nodes]="selectedInspectionFormNodes"
                    [nodeOperations]="[]"
            >
            </app-inspection-form-tree>
        </div>
    </div>

    <div class="content-container" *ngIf="loadState === LoadState.LOADED_ERROR">
        <div class="load-error">
            <span *ngIf="loadError !== null && loadError.length > 0">{{ loadError }}</span>
        </div>
    </div>

    <div class="content-container" *ngIf="loadState === LoadState.LOADING">

        <div class="loading__wrapper">
            <div>Loading inspection forms</div>
            <mat-spinner class="mt-10"></mat-spinner>
        </div>

    </div>
</div>
