import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {UserManagement, UserModel, UserModelView, UserType} from '../../../../../shared/models/User';
import {FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-dialog-confirm-delete-user',
  templateUrl: 'dialog-confirm-delete-user.html',
  styleUrls: ['dialogs.css']
})
export class DialogConfirmDeleteUserComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel) {
  }
}

@Component({
  selector: 'app-dialog-change-password',
  templateUrl: 'dialog-change-password.html',
  styleUrls: ['dialogs.css']
})
export class DialogChangePasswordComponent {

  passwordUpdateForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private fb: UntypedFormBuilder
  ) {
    this.initForm();
  }

  initForm() {
    this.passwordUpdateForm = this.fb.group({
      newPassword: '',
    }, {
      validators: []
    });
    this.passwordUpdateForm.controls['newPassword'].setValidators([
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{4,}')
    ]);
    this.passwordUpdateForm.controls['newPassword'].markAsTouched();
  }
}

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: 'dialog-edit-user.html',
  styleUrls: ['dialogs.css']
})
export class DialogEditUserComponent implements OnInit, OnDestroy {

  isLoading = true;
  configuration: ConfigurationModel;
  private readonly openSubscriptions = Array<Subscription>();

  // user type dropdown
  private userTypeDriver = {name: UserManagement.ROLE_DRIVER, label: UserManagement.ROLE_DRIVER_LABEL} as UserType;
  private userTypeSupervisor = {
    name: UserManagement.ROLE_PORTAL_USER,
    label: UserManagement.ROLE_PORTAL_USER_LABEL
  } as UserType;
  private userTypeAdmin = {
    name: UserManagement.ROLE_PORTAL_ADMIN,
    label: UserManagement.ROLE_PORTAL_ADMIN_LABEL
  } as UserType;
  userTypes: UserType[] = [
    this.userTypeDriver,
    this.userTypeSupervisor,
    this.userTypeAdmin,
  ];
  userType: UserType;

  userForm: UntypedFormGroup;
  private hideFromLogin = false;
  private emailPrefix: string;

  constructor(
    public dialogRef: MatDialogRef<DialogEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'create' | 'update', user: UserModelView },
    protected fb: UntypedFormBuilder,
    private configurationService: ConfigurationService
  ) {

    // init form
    this.userForm = this.fb.group({
      userType: this.fb.control(this.userType),
      givenName: this.fb.control(this.data.user.givenName, {
        validators: [
          Validators.required
        ]
      }),
      familyName: this.fb.control(this.data.user.familyName),
      emailPrefix: this.buildEmailControl(),
      phoneNumber: this.buildPhoneNumberControl(),
      hideFromLogin: this.buildHideFromLogin()
    });

    // on change listeners, update the data back to the model
    this.userForm.valueChanges.subscribe(change => {
      this.data.user.givenName = change['givenName'];
      this.data.user.familyName = change['familyName'];
      this.data.user.phoneNumber = change['phoneNumber'];
      if (this.isModeCreate() && !this.isDriverType(change['userType'])) {
        this.data.user.email = change['emailPrefix'] + this.configuration.driverSuffix;
      }
      if (this.isModeCreate() && this.isDriverType(change['userType'])) {
        delete this.data.user.email;
      }
    });

    this.userForm.get('userType').valueChanges.subscribe(change => {
      this.userTypeChange(change);
    });
  }

  ngOnDestroy(): void {
    try {
      this.openSubscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    } catch (e) {
    }
  }

  ngOnInit(): void {
    this.loadGeneralConfiguration();

    // set if creating new user
    if (this.data.user.roles === undefined) {
      this.data.user.roles = [UserManagement.ROLE_DRIVER];
    }
    if (this.data.user.roles.includes(UserManagement.ROLE_PORTAL_ADMIN)) {
      this.ngUserType.setValue(this.userTypeAdmin);
    } else if (this.data.user.roles.includes(UserManagement.ROLE_PORTAL_USER)) {
      this.ngUserType.setValue(this.userTypeSupervisor);
    } else if (this.data.user.roles.includes(UserManagement.ROLE_DRIVER)) {
      this.ngUserType.setValue(this.userTypeDriver);
    } else {
      this.ngUserType.setValue(this.userTypeDriver); // for new user
    }

    if (this.ngHideFromLogin) {
      if (this.data.user.roles.includes(UserManagement.ROLE_DRIVER)) {
        this.ngHideFromLogin.setValue(false);
      } else {
        this.ngHideFromLogin.setValue(true);
      }
    }

    if (this.isModeUpdate()) {
      if (this.userForm.get('emailPrefix') !== null) {
        this.userForm.removeControl('emailPrefix');
      }
    }

  }

  isModeCreate() {
    return this.data.mode === 'create';
  }

  isModeUpdate() {
    return this.data.mode === 'update';
  }

  isDriverType(userType: UserType) {
    return userType.name === UserManagement.ROLE_DRIVER;
  }

  private hasDriverRole() {
    return this.data.user.roles.indexOf(UserManagement.ROLE_DRIVER) > -1;
  }

  private loadGeneralConfiguration() {
    const configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      this.configuration = model;
      this.isLoading = false;
    });
    this.openSubscriptions.push(configSubscription);
  }

  get ngUserType(): FormControl {
    return this.userForm.get('userType') as FormControl;
  }

  get ngGivenName(): FormControl {
    return this.userForm.get('givenName') as FormControl;
  }

  get ngFamilyName(): FormControl {
    return this.userForm.get('familyName') as FormControl;
  }

  get ngEmailPrefix(): FormControl {
    return this.userForm.get('emailPrefix') as FormControl;
  }

  get ngPhoneNumber(): FormControl | null {
    return this.userForm.get('phoneNumber') as FormControl;
  }

  get ngHideFromLogin(): FormControl | null {
    return this.userForm.get('hideFromLogin') as FormControl;
  }

  userTypeChange(userType: UserType) {
    // handle toggle to hide driver or defaults
    this.setUserRoles(userType);

    if (userType.name === UserManagement.ROLE_DRIVER) {
      if (this.ngHideFromLogin) {
        this.ngHideFromLogin.setValue(false);
        this.userForm.removeControl('hideFromLogin');
      }
      if (this.userForm.get('phoneNumber') !== null) {
        this.userForm.removeControl('phoneNumber');
      }
      if (this.userForm.get('emailPrefix') !== null) {
        this.userForm.removeControl('emailPrefix');
      }
    } else {
      if (!this.ngHideFromLogin) {
        this.userForm.addControl('hideFromLogin', this.buildHideFromLogin());
        this.ngHideFromLogin.setValue(false);
      }
      if (this.userForm.get('phoneNumber') === null) {
        this.userForm.addControl('phoneNumber', this.buildPhoneNumberControl());
      }
      if (this.isModeCreate() && this.userForm.get('emailPrefix') === null) {
        this.userForm.addControl('emailPrefix', this.buildEmailControl());
      }
    }
  }

  private buildEmailControl(): UntypedFormControl {
    return this.fb.control(this.emailPrefix, {
      validators: [
        Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+$')
      ]
    });
  }

  private buildPhoneNumberControl(): UntypedFormControl {
    return this.fb.control(this.data.user.phoneNumber, {
      validators: [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')
      ]
    });
  }

  buildHideFromLogin() {
    const control = this.fb.control(this.hideFromLogin);
    control.valueChanges.subscribe(change => {
      this.updateDriverRole(!change);
    });
    return control;
  }

  private setUserRoles(userType: UserType) {
    const hasDriverRole = this.hasDriverRole();
    switch (userType.name) {
      case UserManagement.ROLE_DRIVER:
        this.data.user.roles = [
          UserManagement.ROLE_DRIVER
        ];
        break;
      case UserManagement.ROLE_PORTAL_USER:
        this.data.user.roles = [
          UserManagement.ROLE_PORTAL_USER
        ];
        break;
      case UserManagement.ROLE_PORTAL_ADMIN:
        this.data.user.roles = [
          UserManagement.ROLE_PORTAL_USER,
          UserManagement.ROLE_PORTAL_ADMIN];
        break;
      default:
        this.data.user.roles = [UserManagement.ROLE_DRIVER];
    }
    this.updateDriverRole(hasDriverRole);
  }

  private updateDriverRole(keepDriver: boolean) {
    const driverRoleIndex = this.data.user.roles.indexOf(UserManagement.ROLE_DRIVER);
    if (keepDriver && driverRoleIndex === -1) {
      this.data.user.roles.push(UserManagement.ROLE_DRIVER);
    }
    if (!keepDriver && driverRoleIndex > -1) {
      this.data.user.roles.splice(driverRoleIndex, 1);
    }
  }
}

