import {UListItem} from '../../../../../../shared/tools/UListItem';
import {UId} from '../../../../../../shared/tools/UId';
import {MifNodeType} from './node-types/MifNodeType';
import {MifNodes} from './MifNodes';
import {MifNodeTypes} from './node-types/MifNodeTypes';
import {MifMoveViolation} from './MifMoveViolation';
import {MifMoveResult} from './MifMoveResult';
import {MifNodeDto} from '../dto/MifNodeDto';
import {NodeOperation} from './NodeOperation';
import {NodeOperationEnum} from './NodeOperationEnum';
import {MatDialog} from '@angular/material/dialog';
import {MifNodeHelper} from './MifNodeHelper';

export class MifNode implements UListItem {
  externalId: number;
  internalId: UId = UId.createNew();
  selected = false;

  private parent: MifNode = null;
  private children: MifNodes = new MifNodes([]);

  private nodeTypeInstance: MifNodeType;

  constructor(externalId: number, nodeType: MifNodeType) {
    this.nodeTypeInstance = nodeType;
    this.externalId = externalId;
  }

  static fromDto(dto: MifNodeDto): MifNode {
    const node = MifNodeHelper.transformDtoToNode(dto);

    if (dto.nodes != null) {
      MifNodeHelper.sortDTOs(dto.nodes)
        .forEach(childDto => node.addChildNode(MifNode.fromDto(childDto)));
    }

    return node;
  }

  showEditDialog(dialogs: MatDialog, afterEdit: () => void) {
    this.nodeTypeInstance.showEditDialog(dialogs, afterEdit);
  }

  hasSameInternalId(id: UId): boolean {
    return this.internalId.isSameAs(id);
  }

  hasSameInternalIdAs(other: MifNode): boolean {
    return other.hasSameInternalId(this.internalId);
  }

  // ------------------------------------
  // parent delegates
  // ------------------------------------

  hasParent(): boolean {
    return this.parent != null;
  }

  /**
   * Recursively finds parent
   */
  hasSuchParentNode(searched: MifNode): boolean {
    if (this.parent == null) {
      return false;
    } else {
      const foundAsParent = this.parent.hasSameInternalIdAs(searched);
      if (foundAsParent) {
        return true;
      } else {
        return this.parent.hasSuchParentNode(searched);
      }
    }
  }

  /**
   * Recursively finds parent Form-type node (root node)
   */
  private getParentFormNode(): MifNode {
    if (this.parent == null) {
      if (!this.isForm()) {
        throw Error('no parent found on non-Form type node');
      }
      return this;
    } else {
      return this.parent.getParentFormNode();
    }
  }

  removeFromParent() {
    if (this.parent == null) {
      throw new Error('attempted to remove node with no parent');
    }
    this.parent.removeChildNode(this);
  }

  // ------------------------------------
  // children delegates
  // ------------------------------------

  hasChildNodes(): boolean {
    return this.children.isNotEmpty();
  }

  addChildNode(child: MifNode): MifNode {
    child.parent = this;
    this.children.add(child);
    return child;
  }

  addChildNodeAtIndex(child: MifNode, targetIndex: number): MifNode {
    child.parent = this;
    this.children.addAtIndex(child, targetIndex);
    return child;
  }

  moveChildNode(child: MifNode, targetIndex: number) {
    this.children.move(child, targetIndex);
  }

  removeChildNode(child: MifNode) {
    this.children.remove(child);
  }

  removeChildNodeAtIndex(sourceIndex: number) {
    this.children.removeAtIndex(sourceIndex);
  }

  hasSectionTypeChildren(): boolean {
    return this.children.contents.some(child => child.isSection());
  }

  hasGroupTypeChildren(): boolean {
    return this.children.contents.some(child => child.isGroup());
  }

  hasQuestionTypeChildren(): boolean {
    return this.children.contents.some(child => child.isQuestion());
  }

  private hasCombinedChildrenOnThisLevel(): boolean {
    const hasSections: number = this.hasSectionTypeChildren() ? 1 : 0;
    const hasGroups: number = this.hasGroupTypeChildren() ? 1 : 0;
    const hasQuestions: number = this.hasQuestionTypeChildren() ? 1 : 0;
    return (hasSections + hasGroups + hasQuestions) > 1;
  }

  /**
   * Recursively checks if structure combines sections / groups / questions on one level anywhere
   */
  hasCombinedChildren(): boolean {
    if (this.hasCombinedChildrenOnThisLevel()) {
      return true;
    } else {
      for (const child of this.children.contents) {
        const foundInChild = child.hasCombinedChildren();
        if (foundInChild) {
          return true;
        }
      }
      return false;
    }
  }

  /**
   * Recursively finds child
   */
  findChildNode(searched: MifNode): MifNode {
    if (this.hasSameInternalIdAs(searched)) {
      return this;
    } else {
      for (const child of this.children.contents) {
        const found = child.findChildNode(searched);
        if (found) {
          return found;
        }
      }
      return null;
    }
  }

  /**
   * Recursively finds child by internal ID
   */
  findChildNodeByInternalId(searched: UId): MifNode {
    if (this.hasSameInternalId(searched)) {
      return this;
    } else {
      for (const child of this.children.contents) {
        const found = child.findChildNodeByInternalId(searched);
        if (found) {
          return found;
        }
      }
      return null;
    }
  }

  /**
   * For debugging only.
   * Recursively finds child by (non-unique) label.
   */
  findChildNodeByNodeLabel(searched: string): MifNode {
    if (this.nodeLabel() === searched) {
      return this;
    } else {
      for (const child of this.children.contents) {
        const found = child.findChildNodeByNodeLabel(searched);
        if (found) {
          return found;
        }
      }
      return null;
    }
  }

  // ------------------------------------
  // node type delegates
  // ------------------------------------

  isForm(): boolean {
    return this.nodeTypeInstance.nodeType() === MifNodeTypes.FORM;
  }

  isSection(): boolean {
    return this.nodeTypeInstance.nodeType() === MifNodeTypes.SECTION;
  }

  isGroup(): boolean {
    return this.nodeTypeInstance.nodeType() === MifNodeTypes.GROUP;
  }

  isQuestion(): boolean {
    return this.nodeTypeInstance.nodeType() === MifNodeTypes.QUESTION;
  }

  hasNodeTypeInstanceRemarks(): boolean {
    return this.nodeTypeInstance.hasNodeTypeInstanceRemarks();
  }

  nodeLabel(): string {
    return this.nodeTypeInstance.nodeLabel();
  }

  nodeType(): string {
    return this.nodeTypeInstance.nodeType().label;
  }

  // ------------------------------------
  // drag & drop
  // ------------------------------------

  moveTo(target: MifNode): MifMoveResult {
    // console.log(`moving '${this.nodeLabel()}' to '${target.nodeLabel()}'`);
    return this.moveWhileChecking(target, false, 0, () => {
      this.parent.removeChildNode(this);
      target.addChildNode(this);
      this.externalId = null; // node is considered new after it was moved

      return MifMoveResult.success()
        .addOperation(NodeOperation.from(target.internalId.id, target.nodeLabel(), NodeOperationEnum.EXPAND));
    });
  }

  moveUnder(target: MifNode, targetIndex: number): MifMoveResult {
    // console.log(`moving '${this.nodeLabel()}' under '${target.nodeLabel()}' at index '${targetIndex}'`);
    return this.moveWhileChecking(target, true, targetIndex, () => {

      // move within same parent
      if (target.hasSameInternalIdAs(this.parent) ) {
        this.parent.moveChildNode(this, targetIndex);
        this.externalId = null; // node is considered new after it was moved

      } else { // move outside of parent
        this.parent.removeChildNode(this);
        target.addChildNodeAtIndex(this, targetIndex);
        this.externalId = null; // node is considered new after it was moved
      }
      return MifMoveResult.success();
    });
  }

  moveUnderForm(targetIndex: number): MifMoveResult {
    // console.log(`moving '${this.nodeLabel()}' under form at index '${targetIndex}'`);
    const target = this.getParentFormNode();
    return this.moveUnder(target, targetIndex);
  }

  // ------------------------------------

  /**
   * Generates node used as an input for MifTreeComponent
   */
  asInputNode(): any {
    return {
      id: this.internalId.id,
      name: this.nodeLabel(),
      children: this.children.contents.map(child => child.asInputNode()),
      hasChildren: this.isSection() || this.isGroup(),
      isExpanded: true,
      mifNode: this,
      mifHasRemarks: this.hasNodeTypeInstanceRemarks(),
      mifIsQuestion: this.isQuestion(),
      mifIsGroup: this.isGroup(),
      mifIsSection: this.isSection(),
    };
  }

  /**
   * Generates all nodes (complete structure) used as an input for MifTreeComponent.
   * Intended only for root node (Form-type node).
   */
  generateAllInputNodes(): any[] {

    if (!this.isForm()) {
      throw new Error('intended only for root node (Form-type node)');
    }

    return this.children.contents.map(child => child.asInputNode());
  }

  private moveWhileChecking(
    target: MifNode,
    toSpecificIndex: boolean,
    targetIndex: number,
    move: () => MifMoveResult
  ): MifMoveResult {

    if (this.isForm()) {
      throw Error('attempted to move Form-type node');

    } else if (!this.hasParent()) {
      throw Error('attempted to move node with no parent');
    }

    const violation = MifMoveViolation.checkMoveViolations(this, target, toSpecificIndex, targetIndex);
    if (violation != null) {
      return MifMoveResult.failure(violation);
    } else {
      return move();
    }
  }

  isDirectChildOfTarget(target: MifNode): boolean {
    return target.children.containsOneWithSameInternalIdAs(this);
  }

  /**
   * Previously used, might be needed later.
   * To check if user is unnecessarily moving (practically to the same index) within the same parent.
   * And to prevent it (similar to use of a function above).
   */
  isDirectChildOfTargetOnIndex(target: MifNode, targetIndex: number) {
    const myIndex = target.children.findIndexOfOneWithSameInternalIdAs(this);
    return myIndex === targetIndex || myIndex === (targetIndex - 1); // source is already there or move is unnecessary
  }

  asNodeOperation(operation: NodeOperationEnum): NodeOperation {
    return NodeOperation.from(this.internalId.id, this.nodeLabel(), operation);
  }

  toDto(order: number): MifNodeDto {
    return new MifNodeDto(
      this.nodeTypeInstance.nodeType().code,
      this.nodeTypeInstance.sectionDto(this.externalId, order),
      this.nodeTypeInstance.groupDto(this.externalId, order),
      this.nodeTypeInstance.questionDto(this.externalId, order),
      this.childrenDtos()
    );
  }

  /**
   * Returns children as DTOs.
   * Used by toDto() functions.
   */
  childrenDtos(): MifNodeDto[] {
    return this.children.contents.map((it: MifNode, index: number) => it.toDto(index + 1));
  }
}
