import {SensorInput, SensorType} from '../../../../../shared/models/vehicle.model';

export class Equipment {
    type?: SensorType;
    inputUp?: SensorInput;
    inputDown?: SensorInput;
    label?: string;
    rate?: number;
    reversed?: boolean;
    bitNumber?: number;
}
