import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ShiftStatisticsStatus, ShiftWithDriverAndVehicleModel} from '../../../../shared/models/shift.model';
import {VehiclesService} from '../../../../data/vehicles/vehicles.service';
import {VehicleStats} from '../../../../shared/models/vehicle.model';
import moment from 'moment';
import {ReportsService} from '../../../../data/reports/reports.service';
import {saveAs} from 'file-saver-es';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../shared/models/configuration.model';
import {ToastService} from '../../../../shared/services/toast.service';
import {GpsSourcePipe} from '../../../../shared/formatting/gps-source.pipe';
import {MatDialog} from '@angular/material/dialog';
import {ShiftMapInspectionDialogComponent} from './shift-map-panel-dialogs';
import {LiveMapTab} from '../../../live-map/models/live-map-tabs';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {InsightsRoute} from '../../../insights/insights-routing.module';
import {DeviceInfoService} from '../../../../data/device/device-info.service';
import {DeviceInfo} from '../../../../shared/models/device-info';

@Component({
  selector: 'app-shift-map-panel',
  templateUrl: './shift-map-panel.component.html',
  styleUrls: ['./shift-map-panel.component.scss'],
})
export class ShiftMapPanelComponent implements OnInit {
  @Input() configuration: ConfigurationModel;
  @Input() shift: ShiftWithDriverAndVehicleModel;
  @Input() source: string = InsightsRoute.SHIFT_LOOKUP;
  @Input() collapseMenu?: boolean;
  background: ThemePalette = undefined;
  sidenavOpen$?: Observable<boolean>;

  statsLoading = true;
  stats: VehicleStats;
  deviceInfo: DeviceInfo;

  FeatureFlagEnum = FeatureFlagEnum;
  LiveMapTab = LiveMapTab;
  InsightsRoute = InsightsRoute;

  StatisticsStatus = ShiftStatisticsStatus;

  @Input() selectedIndex: number;
  @Output() indexSelected = new EventEmitter<number>();

  constructor(
    private store: Store<any>,
    private vehicleService: VehiclesService,
    private reportService: ReportsService,
    private deviceInfoService: DeviceInfoService,
    private gpsSourcePipe: GpsSourcePipe,
    private toast: ToastService,
    private dialog: MatDialog,
    private navigation: NavigationService,
  ) {}

  ngOnInit() {
    this.sidenavOpen$ = this.store.pipe(
      select((state) => state.ui.sidenavOpen)
    );

    this.loadStats();
  }

  getDuration() {
    const end = moment(this.shift.end);
    const start = moment(this.shift.start);
    return moment.duration(end.diff(start)).humanize();
  }

  loadStats() {
    if (this.shift != null) {
      this.statsLoading = true;
      Promise.all([
        this.vehicleService
          .getVehicleStats(this.shift.vehicle.id, this.shift.id),
        this.deviceInfoService.getDeviceInfoForShift(this.shift.id).toPromise()
      ]).then(responses => {
        const [vehicleStats, deviceInfo] = responses;
        this.stats = vehicleStats.data;
        this.deviceInfo = deviceInfo.data;
      }).catch((message) => {
        this.toast.long(message.error);
      }).finally(() => {
        this.statsLoading = false;
      });
    }
  }

  getGpsSource() {
    return (this.stats && this.stats.gpsSources && this.stats.gpsSources.length > 0)
      ? this.stats.gpsSources.map((source) => (this.gpsSourcePipe.transform(source))).join(', ')
      : 'N/A';
  }

  previewDVIR(preTrip: boolean) {
      const dialog = this.dialog.open(ShiftMapInspectionDialogComponent, {
        data: {
          shiftId: this.shift.id,
          preTrip
        }
      });
      dialog.afterClosed().toPromise().then(result => {
        if (result) {
          this.downloadDVIR(preTrip);
        }
      });
  }

  private downloadDVIR(preTrip: boolean) {
    this.reportService.getDvirByShiftPDF(this.shift.id, preTrip).toPromise().then(response => {
      const blob = new Blob([response], { type: 'pdf' });
      const suffix = preTrip ? 'pre' : 'post';
      saveAs(blob, `Inspection_Report__id_${this.shift.id}_${suffix}.pdf`);
    });
  }

  observationSelected(index: number) {
    this.selectedIndex = index;
    this.indexSelected.emit(index);
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  goBack() {
    let fallbackPath;
    if (this.source === LiveMapTab.ASSETS || this.source === LiveMapTab.OBSERVATIONS) {
      fallbackPath = `/live-map/${this.source}`;
    } else {
      fallbackPath = `/insights/${this.source}`;
    }
    this.navigation.backToPreviousPage('/shift-detail', fallbackPath);
  }
}
