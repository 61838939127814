<div class="dash-array">
    <div
            *ngIf="!dashArray || dashArray.length === 0"
            class="line full"
            [ngStyle]="{ background: color }"
    >
    </div>
    <div
            *ngIf="!!dashArray && dashArray.length > 0"
            class="line"
    >
        <ng-container *ngFor="let i of [].constructor(Math.round(36 / getDashArrayPixelLength(dashArray)))">
            <ng-container *ngFor="let dash of dashArray; let dashIndex = index">
                <div [ngStyle]="{ 'height.px': 3,
                                          'width.px': dashIndex % 2 > 0 ? dash : 3*dash,
                                          'min-width.px': dashIndex % 2 > 0 ? dash : 3*dash,
                                           background: dashIndex % 2 > 0 ? gapColor : color,
                                           display: 'inline-block'
                                         }">
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
