<p>
    <strong>Road Status</strong>
</p>

<mat-spinner *ngIf="isLoading"></mat-spinner>

<table *ngIf="!isLoading && !!roadSegmentStatus">
    <tr>
        <th>Last Pass on:</th>
        <td [matTooltip]="roadSegmentStatus.lastPassOn | date:'shortDate'">{{ roadSegmentStatus.lastPassOn | date:'shortTime'}}</td>
    </tr>
    <tr>
        <th># of Passes:</th>
        <td>{{ roadSegmentStatus.passCount }}</td>
    </tr>
    <tr>
        <th>Last Vehicle:</th>
        <td>{{ roadSegmentStatus.lastShift.vehicle.name}}</td>
    </tr>
    <tr>
        <th>Last Driver:</th>
        <td>{{ roadSegmentStatus.lastShift.driver.name}}</td>
    </tr>
</table>
