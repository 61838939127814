export class Base64Tools {

    // https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    private static base64ToBytes(base64) {
        const binString = window.atob(base64);
        return Uint8Array.from(binString, (m) => m.codePointAt(0));
    }

    private static bytesToBase64(bytes) {
        const binString = String.fromCodePoint(...bytes);
        return window.btoa(binString);
    }

    static decodeText(base64: string): string {
        return new TextDecoder().decode(Base64Tools.base64ToBytes(base64));
    }

    static encodeText(text: string): string {
        return Base64Tools.bytesToBase64(new TextEncoder().encode(text));
    }
}
