import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {inOutAnimation} from 'src/app/shared/animations/animations';
import {MapPanelComponent} from '../../../shared/components/map-viewer/map-panel/map-panel.component';
import {ConfigurationModel} from '../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {VehiclesService} from '../../../data/vehicles/vehicles.service';
import {VehiclesManagerService} from '../../../data/vehicles/vehicles-manager.service';
import {ObservationsService} from '../../../data/observations/observations.service';
import {ObservationsManagerService} from '../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../data/images/images-manager.service';
import {DriversService} from '../../../data/drivers/drivers.service';
import {DriversManagerService} from '../../../data/drivers/drivers-manager.service';
import {ShiftsService} from '../../../data/shifts/shifts.service';
import {ShiftEvent, ShiftEventType, ShiftsManagerService} from '../../../data/shifts/shifts-manager.service';
import {ServicesSocketService} from '../../../data/websocket/services-socket.service';
import {MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {JsonApiResponse} from '../../../shared/models/JsonApiResponse';
import {LiveMapDataService} from '../services/live-map-data.service';
import {select, Store} from '@ngrx/store';
import {ISettingKeyValuePair, SettingsService} from '../../../configuration/settings.service';
import {ObservationManagementService} from '../../../data/observations/observation-management.service';
import {DriverWithShiftCount} from '../../../shared/models/driver.model';
import {ServerEventService} from '../../../data/server-events/server-event.service';
import {ToastService} from '../../../shared/services/toast.service';
import {ShiftState} from '../../../shared/models/shift.model';
import {ActivatedRoute, Router} from '@angular/router';
import {RoutesComponent} from './panel-sections/routes/routes.component';
import {AssetsManagerService} from '../../../data/assets/assets-manager.service';
import {AssetComponent} from './panel-sections/assets/asset/asset.component';
import {RoutesService} from '../../../data/routes/routes.service';
import {RouteAssignmentService} from '../../../data/routes/route-assignment.service';
import {RouteAssignmentManagerService} from '../../../data/routes/route-assignment-manager.service';
import {MultiSelectFilter} from '../../../shared/components/multi-select-component';
import {VehicleCategoryModel} from '../../../shared/models/vehicle';
import {AssetStatus} from '../../../shared/models/asset-status';
import {ObservationTypeGroup} from '../../../shared/models/observation-group';
import {HardwareType} from '../../../shared/components/hardware-filter/hardware-filter.component';
import {LabeledDateFilter} from '../../../shared/models/DateFilter';
import {debounceTime} from 'rxjs/operators';
import {MapControlService} from '../../../shared/components/map-viewer/services/map-control.service';
import {SearchResult, SearchResultType} from './ui-components/search-bar/search-bar.component';
import {LocationInfo} from '../../../shared/models/AddressLookup';
import {AddressSuggestion, ArcgisApiService} from '../../../data/address-search/arcgis-api.service';
import {AddressLookupMapMarkerService} from '../../../shared/components/map-viewer/services/address-lookup-map-marker.service';
import {LiveMapTab} from '../models/live-map-tabs';
import {Asset} from '../models/asset.class';
import {RouteHierarchyItemWithPath} from '../../../shared/models/route';
import { Poi } from 'src/app/shared/models/poi.model';
import {LocationSocketService} from '../../../data/websocket/location-socket.service';
import {ObservationsComponent} from './panel-sections/observations/observations.component';
import {MessagesComponent} from './panel-sections/messages/messages.component';
import {AssetsComponent} from './panel-sections/assets/assets/assets.component';
import {RoutesManagerService} from '../../../data/routes/routes-manager.service';
import moment from 'moment';

@Component({
  selector: 'app-live-map',
  templateUrl: './live-map.component.html',
  styleUrls: ['./live-map.component.scss'],
  animations: [inOutAnimation],
})
export class LiveMapComponent implements OnInit, OnDestroy {
  sidenavOpen$?: Observable<boolean>;

  @ViewChild(MapPanelComponent)
  mapComponent: MapPanelComponent;

  configuration: ConfigurationModel;
  isLoading = true;
  loadingDataName: string;
  loadError: string;
  private readonly openSubscriptions = Array<Subscription>();

  // live map bar settings
  currentFilter?: string;

  private pushStatusUnavailableSnack: MatSnackBarRef<SimpleSnackBar> = null;

  currentRightPanelWidth = 10;
  childComponent: RoutesComponent | AssetComponent | ObservationsComponent | MessagesComponent | AssetsComponent;
  option = 'default';

  routeStatusMode = false;

  private mapResizeObserver: ResizeObserver;
  private mapResizeObserverSubscription: Subscription;
  @ViewChild('mapContent') private mapContainer: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
    private vehiclesService: VehiclesService,
    private vehiclesManager: VehiclesManagerService,
    private observationsService: ObservationsService,
    private observationGroupsService: ObservationManagementService,
    private observationsManager: ObservationsManagerService,
    private imagesManager: ImagesManagerService,
    private driversService: DriversService,
    private driversManager: DriversManagerService,
    private shiftsService: ShiftsService,
    private shiftsManager: ShiftsManagerService,
    private routesService: RoutesService,
    private routeManagerService: RoutesManagerService,
    private routeAssignmentService: RouteAssignmentService,
    private routeAssignmentManager: RouteAssignmentManagerService,
    private serverEventService: ServerEventService,
    private toast: ToastService,
    private servicesSocketService: ServicesSocketService,
    private locationSocketService: LocationSocketService,
    private liveMapDataService: LiveMapDataService,
    private assetsManager: AssetsManagerService,
    private mapControlService: MapControlService,
    private arcGisApiService: ArcgisApiService,
    private addressLookupMapMarkerService: AddressLookupMapMarkerService,
    private store: Store<any>,
  ) {}

  ngOnInit(): void {
    this.servicesSocketService.init().then(() => {
        const servicesSubscription = this.servicesSocketService
            .onMessage('/shift')
            .subscribe((message: ShiftEvent) => {
                this.handleShiftEvent(message);
            });
        this.openSubscriptions.push(servicesSubscription);

        const serviceSocketStatusSubscription =
            this.servicesSocketService.connectionStatusObservable.subscribe(
                (status) => {
                    if (!status) {
                        console.log('WebSocket: waiting...');
                    } else {
                        console.log('WebSocket: connected');
                        if (this.pushStatusUnavailableSnack != null) {
                            this.pushStatusUnavailableSnack.dismiss();
                            this.pushStatusUnavailableSnack = null;
                        }
                    }
                }
            );
        this.openSubscriptions.push(serviceSocketStatusSubscription);
    }).catch(error => {
        console.log('Error while connecting to PlowOps Svc Web Socket!');
    });
    this.locationSocketService.init().then(() => {}).catch(error => {
        console.log('Error while connecting to Location Svc Web Socket!');
    });

    // NGRX Stuff
    this.sidenavOpen$ = this.store.pipe(
      select((state) => state.ui.sidenavOpen)
    );
    this.loadGeneralConfiguration();

    const settingsSubscription =
      this.settingsService.settingsChangedObservable.subscribe(
        (keyValuePair: ISettingKeyValuePair) => {
          this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
        }
      );
    this.openSubscriptions.push(settingsSubscription);

    const rightPanelSubscription = this.liveMapDataService.rightPanelWidth$.subscribe(rightPanelWidth => {
        this.currentRightPanelWidth = rightPanelWidth;
    });
    this.openSubscriptions.push(rightPanelSubscription);

    // wait for settings initialization
    setTimeout(
        () => this.routeStatusMode = this.settingsService.getBooleanValue(SettingsService.ROUTE_STATUS_MODE),
        400,
    );
  }

  ngOnDestroy() {
    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;

    this.servicesSocketService.release();
    this.locationSocketService.release();
    this.routeManagerService.release();
    this.routeAssignmentManager.release();
    this.shiftsManager.release();
    this.driversManager.release();
    this.observationsManager.release();
    this.imagesManager.release();
    this.vehiclesManager.release();
    this.assetsManager.release();
    this.serverEventService.release();
    this.mapResizeObserver?.disconnect();
    this.mapResizeObserverSubscription?.unsubscribe();
  }

  private subscribeToMapResize() {
    const resizeObservable = new Observable<void>(subscriber => {
        this.mapResizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                subscriber.next();
            });
        });
    });

    // added delay to allow mapContainer to be initialized
    setTimeout(() => {
        this.mapResizeObserverSubscription = resizeObservable
            .pipe(debounceTime(200))
            .subscribe({ next: () => {
                if (!this.activatedRoute.snapshot.queryParams?.drawer) {
                    console.log('Resizing maplibre component explicitly.');
                    this.mapControlService.resizeMap();
                }
            } });
        this.mapResizeObserver.observe(this.mapContainer?.nativeElement);
    }, 2000);
  }

  handleShiftEvent(shiftEvent: ShiftEvent) {
    this.shiftsService
      .getShiftInfo(shiftEvent.shiftId)
      .toPromise()
      .then((response) => {
        const data = response.data;
        const vehicleName = !!data.vehicle.label ? data.vehicle.label : data.vehicle.name;
        let message;
        switch (shiftEvent.type) {
            case ShiftEventType.START:
                this.liveMapDataService.sendShiftInfo(data);
                message = `Driver "${data.driver.name}" has just started his shift with "${vehicleName}".`;
                break;
            case ShiftEventType.UPDATE:
                switch (shiftEvent.state) {
                    case ShiftState.NORMAL:
                        message = `Vehicle "${vehicleName}" is back in normal state`;
                        break;
                    case ShiftState.STATIONARY:
                        message = `Vehicle "${vehicleName}" is stationary`;
                        break;
                    case ShiftState.PAUSED:
                        message = `Vehicle "${vehicleName}" is paused`;
                        break;
                }
                break;
            case ShiftEventType.END:
                this.liveMapDataService.sendShiftInfo(data);
                message = `Driver "${data.driver.name}" has just ended his shift with "${vehicleName}".`;
                break;
            default:
        }
        this.toast.long(message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private onSettingsChanged(key: string, value: any) {
    if (key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
      this.currentFilter = value;
    }
    if (key === SettingsService.ROUTE_STATUS_MODE) {
      this.routeStatusMode = value;
    }
  }

  private loadGeneralConfiguration() {
    this.loadingDataName = 'Configuration';

    const configSubscription =
      this.configurationService.sharedConfigurationModel.subscribe(
        (model) => {
          if (model) {
            this.configuration = model;
            this.loadVehiclesConfiguration();
          }
        }, // success path
        (error) => (this.loadError = error) // error path
      );
    this.openSubscriptions.push(configSubscription);
  }

  private loadVehiclesConfiguration() {
    this.loadingDataName = 'Vehicles';

    Promise.all([
      this.vehiclesService.getVehicleCategories().toPromise(),
      this.vehiclesService.getVehiclesWithActiveShift().toPromise(),
    ])
      .then((responses) => {
        const [categoriesResponse, vehiclesResponse] = responses;
        // initialize data managers
        this.serverEventService.init();
        this.vehiclesManager.init(
          categoriesResponse.data,
          vehiclesResponse.data
        );
        // send data to child components
        this.liveMapDataService.sendVehicleCategories(categoriesResponse.data);
        this.liveMapDataService.sendVehicles(vehiclesResponse.data);
        // continue with initial data load
        this.loadDriversConfiguration();
      })
      .catch((error) => {
        this.loadError = error; // error path
      });
  }

    private loadDriversConfiguration() {
        this.loadingDataName = 'Drivers';

        const driversSubscription = this.driversService.getDriverList().subscribe(
            (response: JsonApiResponse<DriverWithShiftCount[]>) => {
                this.driversManager.init(response.data);
                this.liveMapDataService.sendDrivers(response.data);
                this.loadObservationsConfiguration();
            }, // success path
            (error) => (this.loadError = error)
        );
        this.openSubscriptions.push(driversSubscription);
    }

  private loadObservationsConfiguration() {
    this.loadingDataName = 'Observations';

    Promise.all([
        this.observationsService.getObservationTypes().toPromise(),
        this.observationGroupsService.getObservationTypeGroups().toPromise()
    ]).then(responses => {
        const [observationTypesResponse, observationGroupsResponse] = responses;
        // initialize observation manager
        this.observationsManager.init(
            null,
            observationTypesResponse.data,
            observationGroupsResponse.data
        );
        // initialize image manager
        this.imagesManager.init(
            null,
            this.configuration,
        );
        this.loadShiftsConfiguration();
    });
  }

    private loadShiftsConfiguration() {
      this.shiftsManager.init(this.configuration).then(() => {
        this.loadAssets();
      });
    }

    private loadAssets() {
      this.assetsManager.init();
      this.loadRouteConfiguration();
    }

    private loadRouteConfiguration() {
        this.loadingDataName = 'Routes';

        this.routesService.getRouteConfigurations().then(response => {
            this.liveMapDataService.sendRouteConfiguration(response.data);
            this.routeManagerService.init(response.data);
            this.loadVehiclesRouteAssignmentStatus();
        }).catch((error) => {
            this.loadError = error; // error path
        });
    }

    private loadVehiclesRouteAssignmentStatus() {
        this.loadingDataName = 'Route Assignments';

        Promise.all([
            this.routeAssignmentService.getRouteAssignments(
                moment().subtract(168, 'hour').toDate(),
                null,
                null,
                null,
                null,
                true,
                false,
            ),
            this.routeAssignmentService.getVehicleStatus(),
        ]).then(responses => {
            const [recentAssignments, vehicleStatus] = responses;
            this.routeAssignmentManager.init(recentAssignments.data, vehicleStatus.data);
            this.isLoading = false;
            this.subscribeToMapResize();
        }).catch((error) => {
            this.loadError = error; // error path
        });
    }

    childComponentChanged(e) {
        if (e instanceof AssetComponent) {
            this.childComponent = e;
        } else if (e instanceof AssetsComponent) {
            this.childComponent = e;
        } else if (e instanceof RoutesComponent) {
            this.childComponent = e;
        } else if (e instanceof ObservationsComponent) {
            this.childComponent = e;
        } else if (e instanceof MessagesComponent) {
            this.childComponent = e;
        } else {
            this.childComponent = null;
        }
    }

    assetFiltersShown() {
        return !!this.childComponent &&
            !(this.childComponent instanceof AssetComponent) && // not shown on single Asset
            !(this.childComponent instanceof ObservationsComponent) && // not shown on Observations
            !(this.childComponent instanceof MessagesComponent) && // not shown on Messages
            (!this.routeStatusMode || !(this.childComponent instanceof RoutesComponent)); // not shown on Routes in Status Mode
    }

    observationFiltersShown() {
        return this.childComponent instanceof ObservationsComponent;
    }

    routeModeToggleShown() {
        return this.childComponent instanceof RoutesComponent;
    }

    locationLayersHidden() {
        return this.childComponent instanceof ObservationsComponent ||
            this.childComponent instanceof MessagesComponent ||
            (this.routeStatusMode && this.childComponent instanceof RoutesComponent);
    }

    onVehicleGroupFilterChange(filter: MultiSelectFilter<VehicleCategoryModel>) {
      this.assetsManager.filterByVehicleGroup(filter.elements?.map(group => group.id));
    }

    onStatusFilterChange(filter: MultiSelectFilter<AssetStatus>) {
      this.assetsManager.filterByStatus(filter.elements?.map(status => status.id));
    }

    onHardwareFilterChange(filter: HardwareType) {
      this.assetsManager.filterByHardware(filter?.id);
    }

    onObservationTypeGroupFilterChange(filter: MultiSelectFilter<ObservationTypeGroup>) {
      this.observationsManager.filterByObservationTypeGroup(filter.elements?.map(group => group.id));
    }

    onDateFilterChange(filter: LabeledDateFilter) {
      this.observationsManager.filterByDate(filter);
    }

    onSearchResult(searchResult: SearchResult) {
        switch (searchResult.type) {
            case SearchResultType.ADDRESS:
                this.onAddressSearch(searchResult.result as AddressSuggestion);
                break;
            case SearchResultType.VEHICLE:
                this.router.navigate(['/live-map', LiveMapTab.ASSETS, (searchResult.result as Asset).id]);
                break;
            case SearchResultType.ROUTE:
                const routeItem = searchResult.result as RouteHierarchyItemWithPath;
                const path = [String(routeItem.configId)];
                if (!!routeItem.path && routeItem.path.length > 0) {
                    path.push(...routeItem.path);
                }
                path.push(routeItem.value);
                this.router.navigate(['/live-map', LiveMapTab.ROUTES], {
                    queryParams: {'path-ids': path.join(':::')}
                });
                break;
            case SearchResultType.POI:
                this.onPoiSearch(searchResult.result as Poi);
                break;
            default:
                console.warn('Missing implementation for live map search!');
        }
    }

    private onAddressSearch(addressSuggestion: AddressSuggestion) {
        this.arcGisApiService.findAddressBasedOnSuggestion(addressSuggestion.text, addressSuggestion.magicKey).then(response => {
            const address = LocationInfo.fromArcGisGeocodeResponse(response, 1);
            this.addressLookupMapMarkerService.addLocations([address]);
            this.mapControlService.zoomToCoordinates(address.geometry, 15);
        });
    }

    private onPoiSearch(poi: Poi) {
        const location = LocationInfo.fromPoi(poi);
        this.addressLookupMapMarkerService.addLocations([location]);
        this.mapControlService.zoomToCoordinates(location.geometry, 15);
    }
}
