import {Component, OnInit} from '@angular/core';
import {SecurityService} from '../../../../security/security.service';
import {UpdateUserPasswordModel, UserManagement, UserModel} from '../../../../shared/models/User';
import {UserManagementService} from '../../../../data/users/user-management.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ActivatedRoute, Router} from '@angular/router';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  isLoading = true;
  isEditing = false;
  isResetting = false;
  user: UserModel;
  customerId: string;

  passwordUpdateForm: UntypedFormGroup;
  errorMatcher = new CrossFieldErrorMatcher();

  constructor(private securityService: SecurityService,
              private userManagementService: UserManagementService,
              private configurationService: ConfigurationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private snackBar: MatSnackBar,
  ) {
    this.initForm();
  }

  initForm() {
    this.passwordUpdateForm = this.fb.group({
      newPassword: '',
      verifyPassword: ''
    }, {
      validators: [this.passwordValidator]
    });
    this.passwordUpdateForm.controls['newPassword'].setValidators([
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{4,}')
    ]);
  }

  passwordValidator(form: UntypedFormGroup) {
    const condition = form.get('newPassword').value !== form.get('verifyPassword').value;
    return condition ? { passwordsDoNotMatch: true} : null;
  }

  ngOnInit() {
    this.isEditing = this.activatedRoute.snapshot.queryParams.edit === 'true';
    this.isResetting = this.activatedRoute.snapshot.queryParams.reset === 'true';

    this.activatedRoute.queryParams.subscribe((params) => {
      this.isEditing = params.edit === 'true';
      this.isResetting = params.reset === 'true';

      if (!this.isEditing && !this.isResetting) {
        this.passwordUpdateForm.reset();
      }
    });

    this.configurationService.getCustomerId().then(response => {
      this.customerId = response.data;
    });

    this.isLoading = true;
    this.securityService.getUserEmail().then(email => {
      this.userManagementService.getUser(email).toPromise().then(response => {
        this.user = response.data;
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    }).catch(error => this.isLoading = false);
  }

  save() {
    this.isLoading = true;
    const updateModel = new UserModel();
    updateModel.email = this.user.email;
    updateModel.phoneNumber = this.user.phoneNumber;
    updateModel.givenName = this.user.givenName;
    updateModel.familyName = this.user.familyName;
    updateModel.roles = this.user.roles;
    this.userManagementService.updateUser(updateModel).then(response => {
      this.user = response.data;
      this.isLoading = false;
      this.snackBar.open('User updated.', null, { duration: 2000 });
      this.router.navigate([], {
        queryParams: {
          edit: undefined
        }
      });
    }).catch(message => {
      console.error(message);
    });
  }

  updatePassword() {
    this.isLoading = true;
    this.userManagementService.updatePassword(
        new UpdateUserPasswordModel(this.user.email, this.passwordUpdateForm.get('newPassword').value)
    ).then(response => {
      this.user = response.data;
      this.passwordUpdateForm.controls['newPassword'].reset();
      this.passwordUpdateForm.controls['verifyPassword'].reset();
      this.isLoading = false;
      this.snackBar.open('Password updated.', null, { duration: 2000 });
    }).catch(message => {
      console.error(message);
    });
  }

  listRoles() {
    return this.user.roles.map((role) => this.getRoleLabel(role)).join(', ');
  }

  private getRoleLabel(role: string) {
    switch (role) {
      case UserManagement.ROLE_DRIVER:
        return UserManagement.ROLE_DRIVER_LABEL;
      case UserManagement.ROLE_PORTAL_USER:
        return UserManagement.ROLE_PORTAL_USER_LABEL;
      case UserManagement.ROLE_PORTAL_ADMIN:
        return UserManagement.ROLE_PORTAL_ADMIN_LABEL;
      case UserManagement.ROLE_SYSTEM:
        return UserManagement.ROLE_SYSTEM_LABEL;
      default:
        return 'UNKNOWN';
    }
  }
}
