import {MifMoveViolation} from './MifMoveViolation';
import {NodeOperation} from './NodeOperation';

export class MifMoveResult {
  succeeded: boolean;
  violation: MifMoveViolation;
  operations: NodeOperation[] = [];

  constructor(succeeded: boolean, violation: MifMoveViolation) {
    this.succeeded = succeeded;
    this.violation = violation;
  }

  static success(): MifMoveResult {
    return new MifMoveResult(true, null);
  }

  static failure(violation: MifMoveViolation): MifMoveResult {
    return new MifMoveResult(false, violation);
  }

  addOperation(it: NodeOperation): MifMoveResult {
    this.operations.push(it);
    return this;
  }
}
