import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Subscription} from 'rxjs';
import {ActivityFilter} from '../map-layer-switcher.component';
import {SecurityService} from '../../../../security/security.service';

@Component({
  selector: 'app-coverage-legend',
  templateUrl: './coverage-legend.component.html',
  styleUrls: ['./coverage-legend.component.scss']
})
export class CoverageLegendComponent implements OnInit, OnDestroy {

  @Input() appliedFilter: ActivityFilter;
  @Output() settings = new EventEmitter();
  @Output() clearFilterEvent = new EventEmitter();
  layerTypeFilter: LayerTypeFilter;
  stylesSubscription: Subscription;
  isAdmin: boolean;

  constructor(
    private securityService: SecurityService,
    private configurationService: ConfigurationService,
  ) {}

  ngOnInit() {
    this.isAdmin = this.securityService.isAdminSync();
    this.stylesSubscription = this.configurationService.trackStylesChangedSubject.subscribe(trackStyles => {
      this.layerTypeFilter = LayerTypeFilter.coverageLayerTypeFilter(trackStyles);
    });
  }

  ngOnDestroy() {
    this.stylesSubscription?.unsubscribe();
  }

  goToSettings() {
    this.settings.emit();
  }

  clearFilter() {
    this.clearFilterEvent.emit();
  }
}
