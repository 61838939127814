import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ObservationDialogData} from '../../../../../../shared/models/observation-group';

@Component({
  selector: 'app-create-observation-group-dialog',
  templateUrl: 'create-observation-group-dialog.component.html',
  styleUrls: ['create-observation-group-dialog.component.scss'],
})
export class CreateObservationGroupDialogComponent {

  defaultMapColor: string;

  constructor(
    public dialogRef: MatDialogRef<CreateObservationGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObservationDialogData,
  ) {
    this.defaultMapColor = data.defaultMapColor;
  }
}
