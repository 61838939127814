<table
        mat-table
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        class="ops-table"
>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by vehicle name">
            Vehicle Name
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by map label">
            Map Label
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.label }}
        </td>
    </ng-container>

    <ng-container matColumnDef="licensePlate">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by license plate">
            License Plate
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.licensePlate }}
        </td>
    </ng-container>

    <ng-container matColumnDef="mapColor">
        <th mat-header-cell *matHeaderCellDef class="mat-body">
            Track Color
        </th>
        <td mat-cell *matCellDef="let element">
            <div *ngIf="element.mapColor"
                    class="track-color-rectangle"
                    [ngStyle]="{ 'background-color': element.mapColor }"
            >
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="hasNoTablet">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by tablet">
            Tablet
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.hasNoTablet ? 'No' : 'Yes' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="useCamera">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by dash camera">
            Dash Camera
        </th>
        <td mat-cell *matCellDef="let element">
            {{ !!element.cameraConfiguration ? 'Yes' : 'No' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="hardwareConfiguration">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by dedicated AVL">
            Dedicated AVL
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.hardwareConfiguration !== null ? 'Yes' : 'No' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="lmuId">
        <th mat-header-cell *matHeaderCellDef class="mat-body"
            mat-sort-header sortActionDescription="Sort by AVL S/N">
            AVL S/N
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.lmuId }}
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element; let i = index" class="ops-table-action-cell" >
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{vehicleModel: row}"
    ></tr>
</table>

<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-vehicle="vehicleModel">
        <button mat-menu-item (click)="updateVehicle(vehicle)">
            <mat-icon>edit</mat-icon>
            <span>Edit vehicle</span>
        </button>
        <button mat-menu-item (click)="deleteVehicle(vehicle)" [disabled]="isImported">
            <mat-icon>delete</mat-icon>
            <span>Delete vehicle</span>
        </button>
        <button mat-menu-item (click)="moveVehicle(vehicle)" [disabled]="isImported">
            <mat-icon>move_up</mat-icon>
            <span>Move vehicle</span>
        </button>
    </ng-template>
</mat-menu>
