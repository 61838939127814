import {Component, OnDestroy, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {timestamp} from 'rxjs/operators';
import {interval, Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {

  private storageKey = '$__callback__attempt_counter__$';

  private isAuthenticated = false;
  private timer$: Subscription;
  private timerWait = 2; // number of seconds to wait on callback before trying to auth again

  timerValue = this.timerWait;
  authError: string;
  authRetry = false;

  constructor(private readonly oidcSecurityService: OidcSecurityService,
              private readonly router: Router) {

    // check if the user is already authenticated
    this.oidcSecurityService.isAuthenticated$.subscribe(a => {
      this.isAuthenticated = a.isAuthenticated;
    });

  }

  ngOnInit(): void {

    // #30534: Dashboard | Redirect Issue
    // this is a hotfix for ending up on callback component without possibility to get out there
    // the angular-auth-oidc-client lib fails with error 'could not find matching config for state ${stateparamfromurl}'
    // it is because of sessionStorage overwrites or something connected to it

    // re-authenticate and wait for results
    this.authenticate();
  }

  ngOnDestroy(): void {
    this.unsubscribeTimer();
  }

  private authenticate() {
    console.log('authenticating...');
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse) => {
          if (!!loginResponse && loginResponse.isAuthenticated) {
            this.removeAttemptCount();
          } else {
            this.handleAuthError('missing authentication');
          }
        },
        error => {
          this.handleAuthError(error);
        });
  }

  private handleAuthError(error: any) {
    // in case of error 'could not find matching config for state ${stateparamfromurl}'
    console.warn('authentication failed', error);
    this.authError = error;

    // ensure max N reloads are done
    if (this.getAttemptCount() > 3) {
      this.authRetry = true;
      return;
    }

    this.timer$ = interval(1000).pipe(timestamp()).subscribe(val => {
      this.timerValue = this.timerWait - val.value;
      if (!this.isAuthenticated && val.value === this.timerWait) {
        this.updateAttemptCount();
        // reload page to main, it should check for login again and probably correctly
        this.reloadPage();
      }
    });
  }

  private reloadPage() {
    // reload page to main, it should check for login again and probably correctly
    const t = setTimeout(() => {
      const url = new URL(window.location.href);
      url.searchParams.delete('code');
      url.searchParams.delete('state');
      url.pathname = url.pathname.replace('callback', '');
      window.location.replace(url);
      clearTimeout(t);
    }, 100);
  }

  retry() {
    this.removeAttemptCount();
    // re-authenticate and wait for results
    this.authenticate();
  }

  private unsubscribeTimer() {
    console.log('authentication timer unsubscribed');
    if (!!this.timer$ && !this.timer$.closed) {
      this.timer$.unsubscribe();
    }
  }

  private updateAttemptCount() {
    const value = window.localStorage.getItem(this.storageKey);
    const counter = value ? +value : 0;
    window.localStorage.setItem(this.storageKey, (counter + 1).toString());
  }

  private getAttemptCount() {
    const value = window.localStorage.getItem(this.storageKey);
    return value ? +value : undefined;
  }

  private removeAttemptCount() {
    window.localStorage.removeItem(this.storageKey);
  }

}
