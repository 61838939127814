import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettingsComponent } from './components/settings.component';
import { ManageRoutesComponent } from './components/manage-routes/manage-routes.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import {VehicleManagementModule} from './components/manage-vehicles/vehicle-management.module';
import {ObservationManagementModule} from './components/manage-observations/observation-management.module';
import {UserManagementModule} from './components/manage-users/user-management.module';
import {AboutComponent} from './components/about/about.component';
import {MarkdownModule} from 'ngx-markdown';
import {MatRadioModule} from '@angular/material/radio';
import {MatTreeModule} from '@angular/material/tree';
import { RouteConfigurationComponent } from './components/manage-routes/route-configuration/route-configuration.component';
import { RouteTreeComponent } from './components/manage-routes/route-tree/route-tree.component';
import {RouterModule} from '@angular/router';
import {SettingsRoutingModule} from './settings-routing.module';
import { RouteStyleEditorComponent } from './components/manage-routes/route-style-editor/route-style-editor.component';
import {CartegraphManagementModule} from './components/manage-cartegraph/cartegraph-management.module';
import {DvirManagementModule} from './components/manage-dvir/dvir-management.module';
import { RouteConfigurationListComponent } from './components/manage-routes/route-configuration-list/route-configuration-list.component';
import {DialogConfirmDeleteRouteConfigComponent} from './components/manage-routes/dialogs/dialog-components';
import { HeadlineComponent } from './components/manage-public-portal/headline/headline.component';
import { MapLayerPreviewComponent } from './components/manage-public-portal/map-layer-preview/map-layer-preview.component';
import {MatSliderModule} from '@angular/material/slider';
import { ManagePublicPortalComponent } from './components/manage-public-portal/manage-public-portal.component';
import {ManageInspectionFormsModule} from './components/manage-inspection-forms/manage-inspection-forms.module';


@NgModule({
  declarations: [
    SettingsComponent,
    ManageRoutesComponent,
    MyAccountComponent,
    AboutComponent,
    RouteConfigurationComponent,
    RouteTreeComponent,
    RouteStyleEditorComponent,
    RouteConfigurationListComponent,
    DialogConfirmDeleteRouteConfigComponent,
    HeadlineComponent,
    MapLayerPreviewComponent,
    ManagePublicPortalComponent,
  ],
  imports: [
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    VehicleManagementModule,
    ObservationManagementModule,
    UserManagementModule,
    MarkdownModule.forRoot(),
    SettingsRoutingModule,
    CartegraphManagementModule,
    DvirManagementModule,
    ManageInspectionFormsModule,
  ],
  providers: [],
})
export class SettingsModule {}
