import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {FilterCompositeComponent} from './composites/filter-composite.component';


@Component({
  selector: 'app-composite-filter-bar',
  templateUrl: './composite-filter-bar.component.html',
  styleUrls: ['./composite-filter-bar.component.scss'],
})
export class CompositeFilterBarComponent implements AfterViewInit {

  @ContentChildren(FilterCompositeComponent) components: QueryList<FilterCompositeComponent>;

  @Output() onSearch = new EventEmitter<FilterBarSearchEvent[]>();

  @Input()
  showBar = false;

  constructor() {
  }

  ngAfterViewInit(): void {
    // if needed to listen on sub components instead of waiting for doSearch called
    // this.components.changes.subscribe(changes => {
    //   console.log('changes', changes);
    // });
  }

  clearFilters() {
    this.components?.forEach(component => {
      component.clear();
      this.onSearch.emit();
    });
  }

  doFilter() {
    const criteria = [];
    this.components?.forEach(component => {
      if (!!component.criteria()) {
        criteria.push(new FilterBarSearchEvent(component.id, component.criteria()));
      }
    });
    this.onSearch.emit(criteria);
  }

  toggleFilterBar() {
    this.showBar = !this.showBar;
  }

}

export class FilterBarSearchEvent {
  constructor(
    public id: string,
    public search: string) {
  }
}
