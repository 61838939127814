<div class="frame">
    <mat-dialog-actions>
        <button color="accent" mat-button mat-dialog-close>Close</button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button color="accent" mat-button [mat-dialog-close]="true">Download DVIR (pdf)</button>
    </mat-dialog-actions>
    <mat-divider></mat-divider>

    <iframe class="iframe" [src]="sanitizedUrl"></iframe>

</div>
