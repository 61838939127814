import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {

  transform(value: any, useMetricSystem?: boolean, showUnits: boolean = true): any {
    if (typeof(value) === 'number') {
      if (useMetricSystem) { // use metric system
        return `${formatNumber(value, 'en-US', '0.1-2')}${showUnits ? ' km' : ''}`;
      } else {
        return `${formatNumber(value * 0.621371192, 'en-US', '0.1-2')}${showUnits ? ' mi' : ''}`;
      }
    } else {
      return 'N/A';
    }
  }
}
