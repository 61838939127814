import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LiveMapComponent } from './components/live-map.component';
import { ObservationsComponent } from './components/panel-sections/observations/observations.component';
import { AllMessagesComponent } from './components/panel-sections/messages/all-messages/all-messages.component';
import { MessagesComponent } from './components/panel-sections/messages/messages.component';
import { NewMessageComponent } from './components/panel-sections/messages/new-message/new-message.component';
import { OpenMessageComponent } from './components/panel-sections/messages/open-message/open-message.component';
import {RoutesComponent} from './components/panel-sections/routes/routes.component';
import {LiveMapTab} from './models/live-map-tabs';
import {AssetsComponent} from './components/panel-sections/assets/assets/assets.component';
import {AssetComponent} from './components/panel-sections/assets/asset/asset.component';



const mixedRoutes: Routes = [
  {
    path: '',
    component: LiveMapComponent,
    children: [
      {
        path: LiveMapTab.ASSETS,
        component: AssetsComponent,
      },
      {
        path: LiveMapTab.ASSETS + '/:id',
        component: AssetComponent,
      },
      {
        path: LiveMapTab.ROUTES,
        component: RoutesComponent,
      },
      {
        path: LiveMapTab.MESSAGES,
        component: MessagesComponent,
        children: [
          {
            path: '',
            component: AllMessagesComponent,
          },
          {
            path: 'new-message',
            component: NewMessageComponent,
          },
          {
            path: 'message',
            component: OpenMessageComponent,
          },
        ],
      },
      {
        path: LiveMapTab.OBSERVATIONS,
        component: ObservationsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(mixedRoutes)],
  exports: [RouterModule],
})
export class LiveMapRoutingModule {}
