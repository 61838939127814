import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyAccountComponent} from './components/my-account/my-account.component';
import {ManageVehiclesMainComponent} from './components/manage-vehicles/manage-vehicles-main.component';
import {ManageObservationsComponent} from './components/manage-observations/manage-observations.component';
import {ManageRoutesComponent} from './components/manage-routes/manage-routes.component';
import {adminGuard, oldInspectionForms, newInspectionForms} from '../../security/admin-guard.service';
import {ManageUsersMainComponent} from './components/manage-users/manage-users-main.component';
import {ManageCartegraphComponent} from './components/manage-cartegraph/manage-cartegraph.component';
import {ManageDvirComponent} from './components/manage-dvir/manage-dvir.component';
import {RouteConfigurationListComponent} from './components/manage-routes/route-configuration-list/route-configuration-list.component';
import {ManagePublicPortalComponent} from './components/manage-public-portal/manage-public-portal.component';
import {InspectionFormListComponent} from './components/manage-inspection-forms/form-list/inspection-form-list.component';
import {InspectionFormComponent} from './components/manage-inspection-forms/form/inspection-form.component';

const routes: Routes = [
  {
    path: 'manage-users',
    component: ManageUsersMainComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'manage-vehicles',
    component: ManageVehiclesMainComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'manage-observations',
    component: ManageObservationsComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'manage-routes',
    component: RouteConfigurationListComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'manage-routes/:configurationId',
    component: ManageRoutesComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'manage-public-portal',
    component: ManagePublicPortalComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'dvir-questions',
    component: ManageDvirComponent,
    canActivate: [adminGuard, oldInspectionForms],
  },
  {
    path: 'inspection-forms',
    component: InspectionFormListComponent,
    canActivate: [adminGuard, newInspectionForms],
  },
  {
    path: 'inspection-forms/:inspectionFormId',
    component: InspectionFormComponent,
    canActivate: [adminGuard, newInspectionForms],
  },
  {
    path: 'inspection-forms/:inspectionFormId/copy',
    component: InspectionFormComponent,
    canActivate: [adminGuard, newInspectionForms],
  },
  {
    path: 'cartegraph-settings',
    component: ManageCartegraphComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'cartegraph-settings/:tabId',
    component: ManageCartegraphComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'cartegraph-settings/:tabId/:stepId',
    component: ManageCartegraphComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'my-account',
    component: MyAccountComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class SettingsRoutingModule { }
