import {VehicleModel} from './vehicle.model';
import {DriverModel} from './driver.model';
import {LatLngModel} from './lat.lng.model';
import { LngLat } from 'maplibre-gl';
import {Poi} from './poi.model';

export class LocationInfo {
  id: number;
  name: string;
  street: string;
  street_nr: string;
  city: string;
  country: string;
  state: string;
  formatted_address: string;
  geometry: LngLat;

  static toLatLngModel(locationInfo: LocationInfo): LatLngModel {
    return new LatLngModel(locationInfo.geometry.lat, locationInfo.geometry.lng);
  }

  static fromArcGisGeocodeResponse(esriCandidate: any, id: number): LocationInfo {
    const location = new LocationInfo();
    location.id = id;
    location.formatted_address = esriCandidate.address;
    location.name = esriCandidate?.attributes?.ShortLabel;
    location.street = esriCandidate?.attributes?.StName;
    location.city = esriCandidate?.attributes?.City;
    location.country = esriCandidate?.attributes?.Country;
    location.state = esriCandidate?.attributes?.Region;
    location.geometry = new LngLat(
        esriCandidate.location.x,
        esriCandidate.location.y
    );
    return location;
  }

  static fromPoi(poi: Poi): LocationInfo {
    const locationInfo = new LocationInfo();
    locationInfo.id = poi.id;
    locationInfo.name = poi.name;
    locationInfo.formatted_address = poi.type;
    locationInfo.geometry = new LngLat(poi.longitude, poi.latitude);
    return locationInfo;
  }
}

export class AddressVisit {
  shiftId: number;
  vehicleId: number;
  vehicle: VehicleModel;
  driverId: number;
  driver: DriverModel;
  time: Date;
}

export class AddressPass {
  location: LocationInfo;
  shiftId: number;
  vehicleId: number;
  vehicle: VehicleModel;
  driverId: number;
  driver: DriverModel;
  time: Date;
}
