import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../../../../shared/models/route';
import {RmNode} from './RmNode';
import {RmIntermediary} from './RmIntermediary';
import {NodesAndBreadcrumbs} from './NodesAndBreadcrumbs';
import {ErrorTools} from '../../../../../shared/tools/ErrorTools';

export class RmRoute implements RmNode {
  base: RouteHierarchyItem;
  level: number;

  parent: RmNode = null;
  selected = false;

  constructor(base: RouteHierarchyItem,
              level: number) {
    this.base = base;
    this.level = level;
  }

  static fromRouteHierarchyItem(routeHierarchyItem: RouteHierarchyItem, level: number): RmNode {
    const hasRouteId = routeHierarchyItem.routeId != null;
    const unprocessedChildren = routeHierarchyItem.children;
    const children = (unprocessedChildren == null) ? []
      : unprocessedChildren
        .filter(value => !value.hidden)
        .sort(RouteHierarchyItem.routeHierarchyItemCompareFn)
        .map(value => RmRoute.fromRouteHierarchyItem(value, level + 1));
    if (hasRouteId) {
      const route = new RmRoute(
        routeHierarchyItem,
        level
      );
      route.setParentOnChildren();
      return route;
    } else {
      const intermediary = new RmIntermediary(
        routeHierarchyItem,
        level,
        children
      );
      intermediary.setParentOnChildren();
      return intermediary;
    }
  }

  hasChildren(): boolean {
    return false;
  }

  isSelected(): boolean {
    return this.selected;
  }

  setParentOnMe(parent: RmNode) {
    this.parent = parent;
  }

  setParentOnChildren() {}

  collectVisibleRouteIds(routeIds: number[]) {
    routeIds.push(this.base.routeId);
  }

  collectRouteIds(routeIds: number[]) {
    routeIds.push(this.base.routeId);
  }

  breadcrumbsR(breadcrumbs: string[]): string[] {
    return this.parent.breadcrumbsR([]);
  }

  rightColumnNodesR(): NodesAndBreadcrumbs {
    throw ErrorTools.unsupported();
  }

  delete() {
    this.parent.deleteChild(this);
  }

  deleteChild(it: RmNode) {}

  isSimilarTo(it: RmNode) {
    return it.isRoute() && this.base.routeId === it.asRouteOrIntermediaryBase().routeId;
  }

  actionMenuItemId(): number {
    return this.base.routeId;
  }

  actionMenuItemTitle(): string {
    return this.base.value;
  }

  actionMenuItemSubTitle(): string {
    return '';
  }

  select() {
    this.parent.unselectMyChildren();
    this.selected = true;
  }

  unselectMyChildren() {}

  unselectMeAndMyChildren() {
    this.selected = false;
  }

  isRoot(): boolean {
    return false;
  }

  isConfig(): boolean {
    return false;
  }

  isIntermediary(): boolean {
    return false;
  }

  isRoute(): boolean {
    return true;
  }

  asConfigBase(): RouteConfigurationWithSchema {
    throw ErrorTools.unsupported();
  }

  asRouteOrIntermediaryBase(): RouteHierarchyItem {
    return this.base;
  }

  collectPathIdsForQueryParamsR(pathIds: string[]) {
    if (this.selected) {
      pathIds.push(this.base.routeId.toString());
    }
  }

  applyPathIdsFromQueryParamsR(remainingIds: string[]) {
    const currentId = remainingIds[0];
    let currentIdMatches = false;
    if (this.base.routeId.toString() === currentId) {
      currentIdMatches = true;
    }
    this.selected = currentIdMatches;
    if (currentIdMatches) {
      remainingIds.splice(0, 1);
    }
  }
}
