import {DateTools} from '../../../../../shared/tools/DateTools';
import {UListItem} from '../../../../../shared/tools/UListItem';
import {UId} from '../../../../../shared/tools/UId';
import {MifFormQuestionFormatOption} from './form-options/MifFormQuestionFormatOption';
import {MifFormReviewingSignaturesOption} from './form-options/MifFormReviewingSignaturesOption';
import {MifNode} from './questions-tree/MifNode';
import {MifFormType} from './questions-tree/node-types/MifFormType';
import {MifFormDto, MifFormSettingsDto} from './dto/MifFormDto';
import {MifFormOptionDto} from './dto/MifFormOptionDto';
import {MifNodeHelper} from './questions-tree/MifNodeHelper';

export class MifForm implements UListItem {
    internalId: UId = UId.createNew();
    externalId: number;
    selected = false;

    journalId: number; // kept for backend use only, ID that groups versions of a form
    title: string;
    note: string;
    questionFormat: MifFormQuestionFormatOption;
    reviewingSignatures: MifFormReviewingSignaturesOption;
    hasRemarks: boolean;
    created: Date;
    modified: Date;
    numberOfQuestions: number; // this static value is set only when form is loaded
    settings: MifFormSettingsDto;

    node: MifNode; // root, Form-type node that contains all other nodes in tree structure

    constructor(externalId: number,
                journalId: number,
                title: string,
                note: string,
                questionFormat: MifFormQuestionFormatOption,
                reviewingSignatures: MifFormReviewingSignaturesOption,
                hasRemarks: boolean,
                settings: MifFormSettingsDto,
                created: Date,
                modified: Date,
                numberOfQuestions,
                node: MifNode) {
        this.externalId = externalId;
        this.journalId = journalId;
        this.title = title;
        this.note = note;
        this.questionFormat = questionFormat;
        this.reviewingSignatures = reviewingSignatures;
        this.hasRemarks = hasRemarks;
        this.settings = settings;
        this.created = created;
        this.modified = modified;
        this.numberOfQuestions = numberOfQuestions;
        this.node = node;
    }

    static createNew(): MifForm {
        return new MifForm(
          0,
          0,
          '',
          '',
          MifFormQuestionFormatOption.FORM,
          MifFormReviewingSignaturesOption.ALWAYS_REQUIRED,
          false,
          new MifFormSettingsDto(),
          DateTools.dateCurrent(),
          null,
          0,
          new MifNode(0, new MifFormType())
        );
    }

    static fromDto(dto: MifFormDto): MifForm {

        const reviewingSignatures = MifFormReviewingSignaturesOption.fromCode(
          dto.options.find(option => option.name === MifFormReviewingSignaturesOption.optionCode).value);
        const questionFormat = MifFormQuestionFormatOption.fromCode(
          dto.options.find(option => option.name === MifFormQuestionFormatOption.optionCode).value);

        const form = new MifForm(
          dto.id,
          dto.journalId,
          dto.title,
          dto.note,
          questionFormat,
          reviewingSignatures,
          dto.hasRemarks,
          dto.settings,
          DateTools.fromDtoDateOrNull(dto.created),
          DateTools.fromDtoDateOrNull(dto.modified),
          dto.questionsCount == null ? 0 : dto.questionsCount,
          new MifNode(dto.id, new MifFormType())
        );

        if (dto.nodes != null) {
            MifNodeHelper.sortDTOs(dto.nodes)
              .forEach(childDto => form.addChildNode(MifNode.fromDto(childDto)));
        }

        return form;
    }

    /**
     * Checks if structure combines sections / groups / questions on one level anywhere
     */
    hasUnsupportedStructure(): boolean {
        return this.node.hasCombinedChildren();
    }

    /**
     * Generates all nodes (complete structure) used as an input for MifTreeComponent
     */
    generateAllInputNodes(): any[] {
        return this.node.generateAllInputNodes();
    }

    hasChildNodes(): boolean {
        return this.node.hasChildNodes();
    }

    addChildNode(child: MifNode): MifNode {
        return this.node.addChildNode(child);
    }

    /**
     * Copy without IDs (new form)
     */
    duplicate(): MifForm {
        const duplicate = new MifForm(
          0,
          0,
          this.title.concat(' (copy)'),
          this.note,
          this.questionFormat,
          this.reviewingSignatures,
          this.hasRemarks,
          this.settings,
          DateTools.dateCurrent(),
          null,
          0,
          new MifNode(0, new MifFormType())
        );

        // copying children by creating DTOs and then parsing DTOs
        // (shallow copy is not enough and deep copy would mess up parent / child relationships)
        this.node.childrenDtos().forEach(childDto => duplicate.addChildNode(MifNode.fromDto(childDto)));

        return duplicate;
    }

    toDto(): MifFormDto {
        return new MifFormDto(
          this.externalId === 0 ? null : this.externalId,
          this.journalId === 0 ? null : this.journalId,
          this.title,
          this.note,
          [
              new MifFormOptionDto(MifFormQuestionFormatOption.optionCode, this.questionFormat.code),
              new MifFormOptionDto(MifFormReviewingSignaturesOption.optionCode, this.reviewingSignatures.code),
          ],
          this.hasRemarks,
          this.settings,
          this.created,
          this.modified,
          0,
          this.node.childrenDtos()
        );
    }
}
