import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ImageFilterUpdate} from '../../../models/images-filter';
import {ImagesManagerService} from '../../../../data/images/images-manager.service';
import {ImageModel} from '../../../models/image';
import {MapLayersManager} from '../map-layers-manager';
import {PointFeature} from '../../../models/GeoJson';
import {Subscription} from 'rxjs';
import {CircleLayerSpecification, GeoJSONSourceSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {MapControlService} from './map-control.service';
import {environment} from '../../../../../environments/environment';
import {MapStyles} from '../../../../configuration/map-styles';

@Injectable({
  providedIn: 'root'
})
export class ImageMapMarkerService {

  static readonly LAYER_ID_IMAGES_CIRCLE = 'images-circle';
  static readonly LAYER_ID_IMAGES_SHADOW = 'images-shadow';
  static readonly LAYER_ID_IMAGES_ICON = 'images-camera';
  static readonly IMAGE_SOURCE_ID = 'images';
  static readonly ICON_CAMERA = 'camera';

  private mapLayersManager: MapLayersManager;
  private readonly imageMap = new Map<number, ImageModel>();
  private imageSourceData: PointFeature[] = [];
  private highlightedImageId: number = null;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(@Inject(DOCUMENT) private document: Document,
              private imageManager: ImagesManagerService,
              private mapControlService: MapControlService,
  ) {}

  init(mapLayersManager: MapLayersManager) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.imageSourceData = [];

    // source: https://fonts.google.com/icons?selected=Material+Icons:menu
    this.mapLayersManager.loadImage(
        ImageMapMarkerService.ICON_CAMERA,
        `${environment.base_href}assets/baseline_photo_camera_black_24dp.png`,
        { sdf: true }
    );

    this.addSourceAndLayers();
    this.connectToManager();
  }

  addSourceAndLayers() {
    // set geojson source
    this.mapLayersManager.addSource(ImageMapMarkerService.IMAGE_SOURCE_ID, this.createImageSource());

    // set image layers
    this.mapLayersManager.addLayer(this.createShadowLayer());
    this.mapLayersManager.addLayer(this.createCircleLayer());
    this.mapLayersManager.addLayer(this.createIconLayer());
  }

  private getImageSourceData() {
    return {
      type: 'FeatureCollection',
      features: this.imageSourceData
    };
  }

  private createImageSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.getImageSourceData(), // 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'
      cluster: false,
    } as GeoJSONSourceSpecification;
  }

  private updateImageSource() {
    const source = this.mapLayersManager.getGeoJsonSource(ImageMapMarkerService.IMAGE_SOURCE_ID);
    if (!!source) {
      source.setData(this.getImageSourceData() as any);
    }
  }

  private createCircleLayer(): CircleLayerSpecification {
    return {
      id: ImageMapMarkerService.LAYER_ID_IMAGES_CIRCLE,
      type: 'circle',
      source: ImageMapMarkerService.IMAGE_SOURCE_ID,
      paint: {
        'circle-color': 'white',
        'circle-radius': 12,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'white',
        'circle-opacity': 1.0
      },
    } as CircleLayerSpecification;
  }

  private createShadowLayer(): CircleLayerSpecification {
    return {
      id: ImageMapMarkerService.LAYER_ID_IMAGES_SHADOW,
      type: 'circle',
      source: ImageMapMarkerService.IMAGE_SOURCE_ID,
      paint: {
        'circle-radius': 18,
        'circle-color': '#000',
        'circle-blur': 0.75,
        'circle-translate': [2, 2],
      },
    } as CircleLayerSpecification;
  }

  private createIconLayer(): SymbolLayerSpecification {
    return {
      id: ImageMapMarkerService.LAYER_ID_IMAGES_ICON,
      type: 'symbol',
      source: ImageMapMarkerService.IMAGE_SOURCE_ID,
      layout: {
        'icon-image': ImageMapMarkerService.ICON_CAMERA,
        'icon-allow-overlap': true,
        'symbol-sort-key': [
          'case',
          ['boolean', ['get', 'time']],
          2,
          1,
        ],
      },
      paint: {
        'icon-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          MapStyles.LIVE_COLOR,
        ],
      },
    } as SymbolLayerSpecification;
  }

  private imageToFeature(image: ImageModel) {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          image.location.longitude,
          image.location.latitude,
        ]
      },
      properties: {
        id: image.id,
        time: new Date(image.time).valueOf() / 1000,
        highlighted: image.id === this.highlightedImageId,
      }
    };
  }

  private handleImagesUpdate(images: ImageModel[]) {
    this.imageMap.clear();
    this.imageSourceData = [];
    images.forEach(image => {
      this.imageMap.set(image.id, image);
      this.imageSourceData.push(this.imageToFeature(image));
    });

    // update source
    this.updateImageSource();
  }

  private highlightImage(update: ImageFilterUpdate) {
    this.imageSourceData.forEach(feature => {
      feature.properties.highlighted = (update.image?.id === feature.properties.id);
    });
    this.updateImageSource();

    // zoom to image if not selected from map
    if (update.source !== ImagesManagerService.MAP_SOURCE) {
      if (!!update.image) {
        this.mapControlService.zoomToCoordinates(
            [update.image.location.longitude, update.image.location.latitude]
        );
      }
    }
  }

  private connectToManager() {
    const imageSubscription = this.imageManager.filteredImages$.subscribe(images => {
      this.handleImagesUpdate(images);
    });
    this.openSubscriptions.push(imageSubscription);

    const filterSubscription = this.imageManager.highlightedImage$.subscribe(highlightedImageFilter => {
      this.highlightImage(highlightedImageFilter);
    });
    this.openSubscriptions.push(filterSubscription);
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
    this.openSubscriptions.length = 0;
    this.imageMap.clear();
    this.imageSourceData = [];
    this.mapLayersManager = null;
  }
}
