<mat-card appearance="outlined">
    <mat-card-title>Inspection Questions Mapping</mat-card-title>
    <mat-card-content *ngIf="isLoading" class="mt-25">
        <div *ngIf="isLoading" class="loading__wrapper">
            <mat-spinner></mat-spinner>
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="!isLoading && questionsList.length==0" class="mt-25">
        No questions synchronized yet
    </mat-card-content>
    <mat-card-content *ngIf="!isLoading && questionsList.length>0" class="mt-25">
        <form>
            <ng-container *ngFor="let question of questionsList">
                <div class="form-row">
                    <div class="form-label bolder mat-caption">
                        {{question.displayText}}
                    </div>
                    <div class="mat-caption">
                        <mat-icon>arrow_right_alt</mat-icon>
                    </div>
                    <mat-form-field appearance="outline" color="accent" class="wide no-hint form-input">
                        <mat-select (selectionChange)="select(question, $event)" [value]="selected(question)">
                            <mat-option [value]="'-without-note-field-'">- Without note field -</mat-option>
                            <mat-option *ngFor="let option of getSelectableQuestions(question)" [value]="option"
                                        [disabled]="option.selected">
                                {{option.displayText}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </form>
    </mat-card-content>

    <mat-card-actions *ngIf="!isLoading" class="my-25">
        <button
                mat-button
                color="accent"
                type="button"
                [disabled]="isLoading"
                (click)="reload()">
            <mat-icon>restart_alt</mat-icon>
            Reload
        </button>
        <button
                mat-button
                color="accent"
                type="button"
                [disabled]="isLoading"
                (click)="update()">
            <mat-icon>save</mat-icon>
            Save
        </button>
    </mat-card-actions>

    <mat-card-footer *ngIf="!isLoading">
        <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
            <span class="ui-error">{{ uiError }}</span>
        </div>
    </mat-card-footer>

</mat-card>
