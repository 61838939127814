import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { inOutAnimation } from 'src/app/shared/animations/animations';
import {ConfigurationModel, FeatureFlagEnum} from '../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {ActionMenuItem} from '../../../shared/models/action-menu-item.class';
import {InsightsRoute} from '../insights-routing.module';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
  animations: [inOutAnimation],
})
export class InsightsComponent implements OnInit, OnDestroy {
  configuration: ConfigurationModel;
  public collapseMenu = false;
  public currentRoute?: any;

  public insightsSections: ActionMenuItem[] = [];
  private configurationSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configurationService: ConfigurationService,
  ) {}

  toggleSideMenu() {
    this.collapseMenu = !this.collapseMenu;
  }

  ngOnInit() {
    this.currentRoute = this.route.firstChild?.routeConfig.path;

    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
        if (this.hasFeatureFlag(FeatureFlagEnum.ReportShift)) {
          this.insightsSections.push(
              new ActionMenuItem(
                  InsightsRoute.SHIFT_REPORT,
                  'description',
                  'Shift Report',
                  'Retrieve shift based stats for your fleet',
                  '',
                  null,
                  () => this.currentRoute === InsightsRoute.SHIFT_REPORT,
                  null,
                  () => {
                    this.selectReport(InsightsRoute.SHIFT_REPORT);
                  },
                  null,
                  null,
                  [],
              )
          );
        }
        this.insightsSections.push(
            new ActionMenuItem(
                InsightsRoute.SHIFT_LOOKUP,
                'description',
                'Shift Lookup',
                'Browse vehicles and drivers to review past shifts',
                '',
                null,
                () => this.currentRoute === InsightsRoute.SHIFT_LOOKUP,
                null,
                () => {
                  this.selectReport(InsightsRoute.SHIFT_LOOKUP);
                },
                null,
                null,
                [],
            )
        );
        if (this.hasFeatureFlag(FeatureFlagEnum.ReportDvir)) {
          this.insightsSections.push(
            new ActionMenuItem(
                InsightsRoute.DVIR_REPORT,
                'description',
                'Inspection Form Retrieval',
                'Review past inspection forms',
                '',
                null,
                () => this.currentRoute === InsightsRoute.DVIR_REPORT,
                null,
                () => { this.selectReport(InsightsRoute.DVIR_REPORT); },
                null,
                null,
                [],
            ),
          );
        }
        if (this.hasFeatureFlag(FeatureFlagEnum.ReportImage)) {
          this.insightsSections.push(
              new ActionMenuItem(
                  InsightsRoute.IMAGE_REPORT,
                  'description',
                  'Image Retrieval',
                  'Retrieve images from past shifts',
                  '',
                  null,
                  () => this.currentRoute === InsightsRoute.IMAGE_REPORT,
                  null,
                  () => { this.selectReport(InsightsRoute.IMAGE_REPORT); },
                  null,
                  null,
                  [],
              ),
          );
        }
        if (this.hasFeatureFlag(FeatureFlagEnum.AddressLookup)) {
          this.insightsSections.push(
              new ActionMenuItem(
                  InsightsRoute.ADDRESS_LOOKUP,
                  'map-marker',
                  'Address Lookup',
                  'See every time a vehicle has passed an address',
                  '',
                  null,
                  () => this.currentRoute === InsightsRoute.ADDRESS_LOOKUP,
                  null,
                  () => {
                    this.selectReport(InsightsRoute.ADDRESS_LOOKUP);
                  },
                  null,
                  null,
                  [],
              ),
          );
        }

        // todo: make feature flag or let it make available  for all or for all admins only
        this.insightsSections.push(
          new ActionMenuItem(
            InsightsRoute.DEVICE_REPORT,
            'map-marker',
            'Device Report',
            'See devices information',
            '',
            null,
            () => this.currentRoute === InsightsRoute.DEVICE_REPORT,
            null,
            () => {
              this.selectReport(InsightsRoute.DEVICE_REPORT);
            },
            null,
            null,
            [],
          ),
        );

      }
    });
  }

  ngOnDestroy() {
    if (this.configurationSubscription != null) {
      this.configurationSubscription.unsubscribe();
      this.configurationSubscription = null;
    }
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  selectReport(sectionId: string) {
    this.router.navigate(['/insights', sectionId]);
    this.currentRoute = sectionId;
  }
}
