<div class="shift-report-container">
  <div class="report-menu">

    <app-filter-bar
            class="report-menu__filters"
            [origin]="'shiftReport'"
            [useDateFilter]="true"
            [useVehicleGroupFilter]="true"
            (dateFilterChanged)="dateFilterChanged($event)"
            (vehicleGroupFilterChanged)="vehicleGroupFilterChanged($event)"
    >
    </app-filter-bar>

    <div class="report-menu__actions">
      <button mat-button
              color="accent"
              (click)="showSettingsDialog()"
              class="caption text-primary">
        Settings
      </button>
      <button
              mat-button
              color="accent"
              [matMenuTriggerFor]="downloadMenu"
              class="caption text-primary"
      >
        <div class="download-button">
          <span>Download</span>
          <mat-icon>
            arrow_drop_down
          </mat-icon>
        </div>
      </button>
      <mat-menu #downloadMenu="matMenu">
        <button
                mat-menu-item
                (click)="loadCSVExportFile()"
        >
          CSV
        </button>
        <button
                mat-menu-item
                (click)="loadPDFExportFile()"
        >
          PDF
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="report__table__wrapper">
    <app-shift-report-table
      [configuration]="configuration"
      [columns]="columns"
      [dataSource]="dataSource"
      [reportTotal]="reportTotal"
      (sortChangedEvent)="loadReport()"
      (scrolledToBottomEvent)="extendReport()"
    >
    </app-shift-report-table>
  </div>
</div>
