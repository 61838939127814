<div class="legend-wrapper">
    <div class="legend-header">
        <div>
            Route Status Mode
        </div>
        <button mat-icon-button
                class="configure-button"
                (click)="toggleSettings()"
        >
            <mat-icon *ngIf="displaySettings">close</mat-icon>
            <mat-icon *ngIf="!displaySettings">settings</mat-icon>
        </button>
    </div>
    <div class="legend-subtitle">
        <span>Service status of routes</span>
    </div>
    <div class="legend-settings" *ngIf="displaySettings">
        <mat-slider min="1"
                    max="24"
                    step="1"
                    showTickMarks
                    discrete
                    [displayWith]="formatLabel(thresholdValues)"
                    color="accent"
        >
            <input matSliderThumb
                   [(ngModel)]="thresholdIndex"
                   (change)="onThresholdChange($event)"
            >
        </mat-slider>

    </div>
    <div class="legend-line">
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Serviced
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.ROUTE_STATUS_SERVICED }"
            >
            </div>
        </div>
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                In Progress
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.ROUTE_STATUS_INPROGRESS }"
            >
            </div>
        </div>
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Not Serviced
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.ROUTE_STATUS_NOTSERVICED }"
            >
            </div>
        </div>
    </div>
</div>
