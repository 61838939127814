import {LngLatBounds, LngLatLike} from 'maplibre-gl';

export class TrackBoundingBox {
  southWest: Coordinates;
  northEast: Coordinates;

  static toLngLatBounds(bounds: TrackBoundingBox): LngLatBounds {
    return new LngLatBounds(
        Coordinates.toLngLatLike(bounds.southWest),
        Coordinates.toLngLatLike(bounds.northEast)
    );
  }
}

export class Coordinates {
  latitude: number;
  longitude: number;

  static toLngLatLike(coordinates: Coordinates): LngLatLike {
    return [coordinates.longitude, coordinates.latitude];
  }
}
