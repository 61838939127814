<mat-stepper orientation="vertical"
             *ngIf="!isLoading && configuration"
             [linear]="false"
             [selectedIndex]="selectedStepIndex"
             (selectionChange)="onSelectedStep($event)"
             #stepper
>
    <mat-step [stepControl]="fgFeatures" [label]="'processing'">
        <ng-template matStepLabel>Processing</ng-template>
        <app-cartegraph-settings-processing [configuration]="configuration"
                                          (configurationChange)="configurationChange($event)"></app-cartegraph-settings-processing>
    </mat-step>
    <mat-step [stepControl]="fgFeatures" [label]="'features'">
        <ng-template matStepLabel>Features</ng-template>
        <app-cartegraph-settings-features [configuration]="configuration"
                                          (configurationChange)="configurationChange($event)"></app-cartegraph-settings-features>
    </mat-step>
    <mat-step [stepControl]="fgSetup" [label]="'setup'">
        <ng-template matStepLabel>Setup Cartegraph account details</ng-template>
        <app-manage-cartegraph-setup [configuration]="configuration"
                                     (configurationChange)="configurationChange($event)"></app-manage-cartegraph-setup>
    </mat-step>
    <mat-step [stepControl]="fgFetch" [label]="'fetch'">
        <ng-template matStepLabel>Fetch data from Cartegraph</ng-template>
        <app-manage-cartegraph-fetch [configuration]="configuration"></app-manage-cartegraph-fetch>
    </mat-step>
    <mat-step [stepControl]="fgConfig" [label]="'config'">
        <ng-template matStepLabel>Configure import parameters</ng-template>
        <app-manage-cartegraph-config [configuration]="configuration"
                                      (configurationChange)="configurationChange($event)"></app-manage-cartegraph-config>
    </mat-step>
    <mat-step [stepControl]="fgInspection" [label]="'inspection'" *ngIf="importQuestionsEnabled">
        <ng-template matStepLabel>Configure inspection questions import mapping</ng-template>
        <app-manage-cartegraph-questions-mapping></app-manage-cartegraph-questions-mapping>
    </mat-step>
    <mat-step [stepControl]="fgImport" [label]="'import'">
        <ng-template matStepLabel>Import data into PlowOps</ng-template>
        <app-manage-cartegraph-import [configuration]="configuration"></app-manage-cartegraph-import>
    </mat-step>
</mat-stepper>
