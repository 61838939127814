import {Component, Input, OnInit} from '@angular/core';
import {
  BaseObjectClass,
  CartegraphClassFilter,
  CartegraphFeature,
  CartegraphFiltersSettings,
  CartegraphSettings,
  CgDatasetClassField,
  EquipmentClass,
  LaborClass,
  ProcessingType
} from '../../../../../../shared/models/cartegraph.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CartegraphManagementService} from '../../../../../../data/cartegraph/cartegraph-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-cartegraph-settings-import-filters',
  templateUrl: './cartegraph-settings-import-filters.component.html',
  styleUrls: ['./cartegraph-settings.scss'],
})
export class CartegraphSettingsImportFilters implements OnInit {

  @Input()
  settings: CartegraphSettings;
  filterSettings: CartegraphFiltersSettings;

  isLoading = true;
  isTesting = {
    driver: false,
    vehicle: false,
    equipment: false,
    workorder: false,
    activityFilter: false,
    materialFilter: false
  };
  uiError: string;
  connectionTested = '';
  updateForm: UntypedFormGroup;

  laborFilterResult: LaborClass[];
  vehicleFilterResult: EquipmentClass[];
  vehicleEquipmentFilterResult: EquipmentClass[];
  equipmentClassFields: CgDatasetClassField[];
  workOrderFilterResult: BaseObjectClass[];
  activityFilterResult: BaseObjectClass[];
  materialFilterResult: BaseObjectClass[];

  constructor(private fb: UntypedFormBuilder,
              private cartegraphManagementService: CartegraphManagementService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.filterSettings = this.settings?.import?.filter ?? new CartegraphFiltersSettings();
    this.filterSettings.classFilters = this.settings?.import?.filter?.classFilters ?? [];
    this.loadMetadata();
    this.initForm();
  }

  initForm() {

    this.updateForm = this.fb.group({
      driverFilter: new UntypedFormControl(this.filterSettings.importDriverFilter),
      vehicleFilter: new UntypedFormControl(this.filterSettings.importVehicleFilter),
      vehicleEquipmentFilter: new UntypedFormControl(this.filterSettings.importVehicleEquipmentFilter),
      vehicleGroupField: new UntypedFormControl(this.filterSettings.importVehicleGroupField),
      workOrderFilter: new UntypedFormControl(this.filterSettings.importWorkOrderFilter),
      materialMatchingFields: new UntypedFormControl(this.filterSettings.materialMatchingFields),
      activityFilter: new UntypedFormControl(this.filterSettings.classFilters.find(f => f.cgClassName === 'cgActivitiesClass')?.filter),
      materialFilter: new UntypedFormControl(this.filterSettings.classFilters.find(f => f.cgClassName === 'cgMaterialsClass')?.filter),
    });
    this.updateForm.valueChanges.subscribe(() => {
      this.connectionTested = null;
    });
    this.updateForm.controls['driverFilter'].valueChanges.subscribe(value => {
      this.filterSettings.importDriverFilter = value;
    });
    this.updateForm.controls['vehicleFilter'].valueChanges.subscribe(value => {
      this.filterSettings.importVehicleFilter = value;
    });
    this.updateForm.controls['vehicleEquipmentFilter'].valueChanges.subscribe(value => {
      this.filterSettings.importVehicleEquipmentFilter = value;
    });
    this.updateForm.controls['vehicleGroupField'].valueChanges.subscribe(value => {
      this.filterSettings.importVehicleGroupField = value;
    });
    this.updateForm.controls['workOrderFilter'].valueChanges.subscribe(value => {
      this.filterSettings.importWorkOrderFilter = value;
    });
    this.updateForm.controls['materialMatchingFields'].valueChanges.subscribe(value => {
      this.filterSettings.materialMatchingFields = value;
    });
    this.updateForm.controls['activityFilter'].valueChanges.subscribe(value => {
      const index = this.filterSettings.classFilters.findIndex(f => f.cgClassName === 'cgActivitiesClass');
      if (index >= 0) {
        this.filterSettings.classFilters.splice(index, 1);
      }
      this.filterSettings.classFilters.push(new CartegraphClassFilter('cgActivitiesClass', value));
    });
    this.updateForm.controls['materialFilter'].valueChanges.subscribe(value => {
      const index = this.filterSettings.classFilters.findIndex(f => f.cgClassName === 'cgMaterialsClass');
      if (index >= 0) {
        this.filterSettings.classFilters.splice(index, 1);
      }
      this.filterSettings.classFilters.push(new CartegraphClassFilter('cgMaterialsClass', value));
    });
  }


  private loadMetadata() {
    this.cartegraphManagementService.getDatasetClassFields([{dataClass: 'cgEquipmentClass'}])
      .then(r => {
        this.equipmentClassFields = r.data;
      }).catch(e => {
      this.uiError = e.error;
    })
      .finally(() => {
        this.isLoading = false;
      });
  }

  testLaborFilter() {
    this.isTesting.driver = true;
    this.uiError = null;
    this.laborFilterResult = null;
    this.cartegraphManagementService.fetchLabor(this.filterSettings.importDriverFilter)
      .then(result => {
        if (result.error) {
          this.uiError = result.error;
        } else {
          this.laborFilterResult = result.data;
        }
      }).catch(() => {
      this.connectionTested = 'no';
    }).finally(() => {
      this.isTesting.driver = false;
      this.showSnackBar('Drivers loaded.');
    });
  }

  testVehicleFilter() {
    this.isTesting['vehicle'] = true;
    this.uiError = null;
    this.vehicleFilterResult = null;
    this.cartegraphManagementService.fetchEquipment(this.filterSettings.importVehicleFilter)
      .then(result => {
        if (result.error) {
          this.uiError = result.error;
        } else {
          this.vehicleFilterResult = result.data;
        }
      }).catch(() => {
      this.connectionTested = 'no';
    }).finally(() => {
      this.isTesting['vehicle'] = false;
      this.showSnackBar('Vehicles loaded.');
    });
  }

  testVehicleEquipmentFilter() {
    this.isTesting['equipment'] = true;
    this.uiError = null;
    this.vehicleEquipmentFilterResult = null;
    this.cartegraphManagementService.fetchEquipment(this.filterSettings.importVehicleEquipmentFilter)
      .then(result => {
        if (result.error) {
          this.uiError = result.error;
        } else {
          this.vehicleEquipmentFilterResult = result.data;
        }
      }).catch(() => {
      this.connectionTested = 'no';
    }).finally(() => {
      this.isTesting['equipment'] = false;
      this.showSnackBar('Vehicle equipment loaded.');
    });
  }

  testWorkOrderFilter() {
    this.isTesting['workorder'] = true;
    this.uiError = null;
    this.workOrderFilterResult = null;
    this.cartegraphManagementService.fetchBaseObject('cgWorkOrdersClass', this.filterSettings.importWorkOrderFilter)
      .then(result => {
        if (result.error) {
          this.uiError = result.error;
        } else {
          this.workOrderFilterResult = result.data;
        }
      }).catch(() => {
      this.connectionTested = 'no';
    }).finally(() => {
      this.isTesting['workorder'] = false;
      this.showSnackBar('Work Orders loaded.');
    });
  }

  testActivityFilter() {
    this.isTesting['activityFilter'] = true;
    this.uiError = null;
    this.activityFilterResult = null;
    const filter = this.filterSettings.classFilters.find(f => f.cgClassName === 'cgActivitiesClass')?.filter;
    this.cartegraphManagementService.fetchBaseObject('cgActivitiesClass', filter)
      .then(result => {
        if (result.error) {
          this.uiError = result.error;
        } else {
          this.activityFilterResult = result.data;
        }
      }).catch(() => {
      this.connectionTested = 'no';
    }).finally(() => {
      this.isTesting['activityFilter'] = false;
      this.showSnackBar('Activities loaded.');
    });
  }

  testMaterialFilter() {
    this.isTesting['materialFilter'] = true;
    this.uiError = null;
    this.materialFilterResult = null;
    const filter = this.filterSettings.classFilters.find(f => f.cgClassName === 'cgMaterialsClass')?.filter;
    this.cartegraphManagementService.fetchBaseObject('cgMaterialsClass', filter)
      .then(result => {
        if (result.error) {
          this.uiError = result.error;
        } else {
          this.materialFilterResult = result.data;
        }
      }).catch(() => {
      this.connectionTested = 'no';
    }).finally(() => {
      this.isTesting['materialFilter'] = false;
      this.showSnackBar('Materials loaded.');
    });
  }

  hasWorkOrdersFeature(): boolean {
    return this.hasFeature(CartegraphFeature.IMPORT_WORK_ORDERS);
  }

  hasMaterialMatchingFeature(): boolean {
    return this.hasFeature(CartegraphFeature.USE_MATERIAL_MATCHING);
  }

  hasFeature(feature: CartegraphFeature): boolean {
    const found = this.settings?.features?.find(
      f => f.feature === feature
    );
    return found?.enabled ?? false;
  }


  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

}

export class ProcessTypeSelection {
  type: ProcessingType;
  note: string;
}
