
export class ShiftReportColumn {

  static readonly LOCAL_STORAGE_DISPLAYED_COLUMNS = 'shiftReportDisplayedColumns';
  static readonly displayedByDefault = [
    'id',
    'driver',
    'vehicle',
    // 'distanceDriven',
    // 'distancePlowed',
    // 'distanceSpreadGranular',
    // 'distanceSpreadLiquid',
    // 'distanceMowed',
    // 'distanceSwept',
    // 'materialUsedGranular',
    // 'materialUsedLiquid',
    'startTime',
    'endTime',
    'duration',
    'maxSpeed',
    'observations',
    'phoneNumber'
  ];

  id: string;
  name: string;
  displayed: boolean;

  constructor(id: string, name: string, displayed: boolean = false) {
    this.id = id;
    this.name = name;
    this.displayed = displayed;
  }

  static columns(displayedColumns: string[]): ShiftReportColumn[] {

    const columns = [
      new ShiftReportColumn('id', 'ID'),
      new ShiftReportColumn('driver', 'Driver'),
      new ShiftReportColumn('vehicle', 'Vehicle'),
      new ShiftReportColumn('distanceDriven', 'Distance Driven'),
      new ShiftReportColumn('distancePlowed', 'Distance Plowed'),
      new ShiftReportColumn('distanceSpreadGranular', 'Distance Spread (Granular)'),
      new ShiftReportColumn('distanceSpreadLiquid', 'Distance Spread (Liquid)'),
      new ShiftReportColumn('distanceMowed', 'Distance Mowed'),
      new ShiftReportColumn('distanceSwept', 'Distance Swept'),
      new ShiftReportColumn('materialUsedGranular', 'Material Used (Granular)'),
      new ShiftReportColumn('materialUsedLiquid', 'Material Used (Liquid)'),
      new ShiftReportColumn('startTime', 'Shift Start'),
      new ShiftReportColumn('endTime', 'Shift End'),
      new ShiftReportColumn('duration', 'Duration'),
      new ShiftReportColumn('maxSpeed', 'Max Speed'),
      new ShiftReportColumn('observations', 'Observations'),
      new ShiftReportColumn('phoneNumber', 'Phone Number'),
    ];

    columns.forEach(column => {
      column.displayed = displayedColumns.some(value => value === column.id);
    });

    return columns;
  }
}
