import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, Type} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Map, Popup} from 'maplibre-gl';
import {BreadcrumbsManagerService} from '../../../../data/breadcrumbs/breadcrumbs-manager.service';
import {BreadcrumbInfoWindowContentComponent} from '../breadcrumb-info-window-content/breadcrumb-info-window-content.component';
import {VehicleBreadcrumb, VehicleBreadcrumbUpdate} from '../../../models/vehicle-breadcrumb';
import {ConfigurationModel} from '../../../models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbInfoWindowService {

  static readonly BREADCRUMB_INFO_WINDOW_SOURCE = 'breadcrumb-info-window';

  private useManager = false;
  private map: Map;
  private lastComponentRef: ComponentRef<any>;
  private configuration: ConfigurationModel;

  constructor(private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              @Inject(DOCUMENT) private document: Document,
              private breadcrumbsManager: BreadcrumbsManagerService) { }

  init(map: Map, useManager: boolean, configuration: ConfigurationModel) {
    if (!!this.map) {
      throw Error('The map has already been set.');
    }
    this.map = map;
    this.useManager = useManager;
    this.configuration = configuration;
    if (useManager) {
      this.connectToManager();
    }
  }

  private instantiateComponent<T>(componentType: Type<T>): ComponentRef<T> {
    const compFactory = this.resolver.resolveComponentFactory(componentType);
    const newComponentRef = compFactory.create(this.injector);

    this.appRef.attachView(newComponentRef.hostView);
    this.lastComponentRef = newComponentRef;

    return newComponentRef;
  }

  private showBreadcrumbInfo(breadcrumbs: Array<VehicleBreadcrumb>) {
    const infoComponentRef = this.instantiateComponent(BreadcrumbInfoWindowContentComponent);
    infoComponentRef.instance.breadcrumbs = breadcrumbs;
    infoComponentRef.instance.isLiveMap = this.useManager;
    infoComponentRef.instance.configuration = this.configuration;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    const coordinates = breadcrumbs[0].breadcrumb.coords;

    const that = this;
    const popup = new Popup({offset: [0, -15], className: 'marker-popup', maxWidth: '300px'})
      .setLngLat([coordinates.lng, coordinates.lat])
      .setDOMContent(div)
      .on('close', () => {
        if (that.useManager) {
          this.breadcrumbsManager.clearBreadcrumbSelection(BreadcrumbInfoWindowService.BREADCRUMB_INFO_WINDOW_SOURCE);
        }
      })
      .addTo(this.map);

    // adjust position of the popup if selection next or previous
    infoComponentRef.instance.visibleBreadcrumbChanged.subscribe((vehicleBreadcrumb) => {
      popup.setLngLat([vehicleBreadcrumb.breadcrumb.coords.lng, vehicleBreadcrumb.breadcrumb.coords.lat]);
    });
  }

  private handleVehicleBreadcrumbSelectionChanged(breadcrumbSelectionUpdate: VehicleBreadcrumbUpdate) {
    if (!!this.lastComponentRef) {
      this.lastComponentRef.destroy();
      this.lastComponentRef = null;
    }

    if (!breadcrumbSelectionUpdate.breadcrumbs || breadcrumbSelectionUpdate.breadcrumbs.length === 0) {
      return;
    }

    this.showBreadcrumbInfo(breadcrumbSelectionUpdate.breadcrumbs);
  }

  // use this method if not using manager
  public showShiftBreadcrumbInfoWindow(breadcrumbSelectionUpdate: VehicleBreadcrumbUpdate) {
    this.handleVehicleBreadcrumbSelectionChanged(breadcrumbSelectionUpdate);
  }

  private connectToManager() {
    const that = this;
    this.breadcrumbsManager.selectedBreadcrumbObservable.subscribe({
      next(breadcrumbSelectionUpdate) {
        that.handleVehicleBreadcrumbSelectionChanged(breadcrumbSelectionUpdate);
      }
    });
  }

  release() {
    if (!this.map) {
      throw Error('The map has not been set. Only a single map instance is supported.');
    }

    this.map = null;
    this.lastComponentRef = undefined;
    this.configuration = undefined;
  }
}
