import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-manage-users-main',
  templateUrl: './manage-users-main.component.html',
})
export class ManageUsersMainComponent implements OnInit, OnDestroy {
  isLoading = false;
  cartegraphEnabled = false;
  private configuration: ConfigurationModel;
  private configurationSubscription: Subscription;
  page: string;

  constructor(private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.isLoading = true;

    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(cfg => {
      if (cfg) {
        this.configuration = cfg;
        this.cartegraphEnabled = this.configurationService.hasFeatureFlag(cfg.featureFlags, FeatureFlagEnum.CartegraphIntegration);
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  pageChanged(page: string) {
    this.page = page;
  }
}
