import {Asset} from '../../pages/live-map/models/asset.class';
import {ShiftState} from './shift.model';
import {RouteAssignmentStatus} from './route-assignment';

export class AssetStatus {
    static readonly ONLINE  = new AssetStatus('ONLINE', 'Online');
    static readonly STATIONARY = new AssetStatus('STATIONARY', 'Stationary');
    static readonly PAUSED  = new AssetStatus('PAUSED', 'Paused');
    static readonly OFFLINE  = new AssetStatus('OFFLINE', 'Offline');
    static readonly ASSIGNMENT_PENDING  = new AssetStatus('ASSIGNMENT_PENDING', 'Assignment Pending');
    static readonly ASSIGNMENT_REQUESTED  = new AssetStatus('ASSIGNMENT_REQUESTED', 'Assignment Requested');
    static readonly ON_ASSIGNMENT  = new AssetStatus('ON_ASSIGNMENT', 'On Assignment');

    static getAssetStatus(asset: Asset): AssetStatus {
        switch (asset.shiftStatus) {
            case ShiftState.NORMAL:
                switch (asset.routeAssignmentStatus) {
                    case RouteAssignmentStatus.NO_ASSIGNMENT:
                        return AssetStatus.ONLINE;
                    case RouteAssignmentStatus.PENDING:
                        return AssetStatus.ASSIGNMENT_PENDING;
                    case RouteAssignmentStatus.REQUESTED:
                        return AssetStatus.ASSIGNMENT_REQUESTED;
                    case RouteAssignmentStatus.ON_ASSIGNMENT:
                        return AssetStatus.ON_ASSIGNMENT;
                }
                return AssetStatus.ONLINE;
            case ShiftState.STATIONARY:
                return AssetStatus.STATIONARY;
            case ShiftState.PAUSED:
                return AssetStatus.PAUSED;
            case ShiftState.ENDED:
                return AssetStatus.OFFLINE;
        }
    }

    static getAll(): AssetStatus[] {
        return [
            AssetStatus.ONLINE,
            AssetStatus.STATIONARY,
            AssetStatus.PAUSED,
            AssetStatus.OFFLINE,
            AssetStatus.ASSIGNMENT_PENDING,
            AssetStatus.ASSIGNMENT_REQUESTED,
            AssetStatus.ON_ASSIGNMENT,
        ];
    }

    // private to disallow creating other instances of this type
    private constructor(
        public readonly id: string,
        public readonly label: string,
    ) {
    }

    toString() {
        return this.id;
    }
}
