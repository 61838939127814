import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ObservationDialogData} from '../../../../../../shared/models/observation-group';

@Component({
  selector: 'app-delete-observation-group-dialog',
  templateUrl: 'delete-observation-group-dialog.component.html',
  styleUrls: ['delete-observation-group-dialog.component.scss']
})
export class DeleteObservationGroupDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteObservationGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObservationDialogData) {}

}
