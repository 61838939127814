import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// for local environment don't turn on Sentry for any loggin nor tracing
if (environment.name !== 'default' && environment.name !== 'dev' && environment.name !== 'local' ) {
  Sentry.init({
    dsn: 'https://75794090e88b4d31ad570505ee976abd@o4504848593715200.ingest.sentry.io/4505125554225152',
    environment: environment.name,
    release: environment.version,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    initialScope: {
      tags: {
        product: 'Dashboard'
      }
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
    enabled: true,

    // ignore errors
    ignoreErrors: [
    ],
    denyUrls: [
      // unreliable service
      /api\.weather\.gov/i,
    ],
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
