import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {TreeComponent, TreeModel, TreeNode} from '@ali-hm/angular-tree-component';
import {MifNode} from '../../model/questions-tree/MifNode';
import {MifMoveResult} from '../../model/questions-tree/MifMoveResult';
import {NodeOperation} from '../../model/questions-tree/NodeOperation';
import {NodeOperationEnum} from '../../model/questions-tree/NodeOperationEnum';
import {MifQuestionType} from '../../model/questions-tree/node-types/MifQuestionType';
import {MifSectionType} from '../../model/questions-tree/node-types/MifSectionType';
import {MifGroupType} from '../../model/questions-tree/node-types/MifGroupType';

/**
 * MifNodes are a model for the inspection form structure including its parent / child relationships.
 * MifTreeComponent displays this model, but accepts simplified input.
 * MifTreeComponent input nodes are simplified input data generated from MifNodes model.
 * However, MifNode reference is included in input nodes for future use.
 *
 * MifTreeComponent updates its internal model according to the input.
 *
 * CRUD operations are done directly on MifNodes, then input nodes are generated
 * and sent to MifTreeComponent as input.
 *
 * Drag & Drop is done in MifTreeComponent, user drags & drops, event is generated, we intercept it,
 * then operate on MifNode, then input nodes are generated and sent to MifTreeComponent as input again.
 * We do not let MifTreeComponent handle drag & drop and implement rules in MifNodes instead.
 *
 * When user selects (activates) and deselects (deactivates) MifTreeComponent nodes, we intercept it,
 * and store the information in a MifTreeComponent's parent component to be able to determine contextual actions.
 *
 * Expansion is a bit tricky and is explained in code below.
 */
@Component({
  selector: 'app-inspection-form-tree',
  templateUrl: './inspection-form-tree.component.html',
  styleUrls: ['./inspection-form-tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InspectionFormTreeComponent implements OnChanges {

  @Input()
  nodes: any[];

  @Input()
  nodeOperations: NodeOperation[]; // always create new array with new operations

  @Input()
  isEditable = true;

  @Output() moveEvent: EventEmitter<MifMoveResult> = new EventEmitter();
  @Output() deselectEvent: EventEmitter<void> = new EventEmitter();
  @Output() selectEvent: EventEmitter<MifNode> = new EventEmitter();
  @Output() expandEvent: EventEmitter<MifNode> = new EventEmitter();
  @Output() collapseEvent: EventEmitter<MifNode> = new EventEmitter();

  @Output() clickedDeselectEvent: EventEmitter<MifNode> = new EventEmitter();
  @Output() clickedAddQuestionEvent: EventEmitter<MifNode> = new EventEmitter();
  @Output() clickedAddGroupEvent: EventEmitter<MifNode> = new EventEmitter();
  @Output() clickedEditEvent: EventEmitter<MifNode> = new EventEmitter();
  @Output() clickedRemoveEvent: EventEmitter<MifNode> = new EventEmitter();

  @ViewChild(TreeComponent)
  private tree: TreeComponent;

  options = {
    allowDrag: this.isEditable,
    allowDrop: this.isEditable,
    actionMapping: {
      mouse: {
        drop: (tree: TreeModel, node: TreeNode, $event: any, {from , to}: {from: any, to: any}) => {

          const draggedNode = from?.data?.mifNode == null ? null : from.data.mifNode as MifNode;
          const dropParent = to?.parent?.data?.mifNode == null ? null : to.parent.data.mifNode as MifNode;

          if (draggedNode != null) {
            const dropOnNode = (to.dropOnNode != null && to.dropOnNode === true);
            const index = (dropOnNode) ? 0 : to.index;

            if (dropParent == null) {
              this.moveEvent.emit(draggedNode.moveUnderForm(index));
            } else {
              if (dropOnNode) {
                this.moveEvent.emit(draggedNode.moveTo(dropParent));
              } else {
                this.moveEvent.emit(draggedNode.moveUnder(dropParent, index));
              }
            }

          } else {
            throw new Error('insufficient drag & drop event information');
          }
        }
      }
    }
  };

  constructor() { }

  /**
   * Expansion is not tracked in MifNodes model, only in MifTreeComponent internal model.
   * When we need to expand or collapse a MifTreeComponent node, we sent input NodeOperation[].
   *
   * Input nodes have isExpanded and hasChildren attributes.
   * Both isExpanded and hasChildren attribute is used only once, when MifTreeComponent internal model node is created.
   * This can be used for example to initially expand all MifTreeComponent nodes, but provides no control later.
   * We set both of these to true for all MifNodes that are able to have children and expand (i.e. section and group).
   *
   * Setting attribute hasChildren is usually not needed, setting it to true means that even thought there are
   * no children in an expandable node yet, it already displays expanded / collapsed icon. But there is an issue when
   * we create expandable node A, it has no children yet, and we add a child node B to it and at the same time
   * we now want to expand it. So we send NodeOperation for A. OnChanges hook runs before internal model is updated
   * (input nodes contain B node already, but internal model does not). OnChanges hook tries to expand node A
   * inside internal model, but that does nothing, because internal node is not expandable (it has no children,
   * and it has hasChildren to false). Setting hasChildren to true solves this problem.
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (this.tree != null) {
      // this code doesn't seem to be working ... at this time the tree model contains the old nodes
      this.nodeOperations.forEach(nodeOperation => {
        const node = this.tree.treeModel.getNodeById(nodeOperation.id);
        if (node != null) {

          switch (nodeOperation.operation) {
            case NodeOperationEnum.EXPAND:
              node.expand();
              break;
            case NodeOperationEnum.DESELECT:
              node.setIsActive(false);
              node.blur();
              break;
            default:
              break;
          }
        }
      });
    }
  }

  expandAll() {
    this.tree.treeModel.expandAll();
  }

  treeEvent_activate(event: any) {
    const mifNode = this.eventToMifNode(event);
    if (mifNode != null) {
      this.selectEvent.emit(mifNode);
    }
  }

  treeEvent_deactivate(event: any) {
    const mifNode = this.eventToMifNode(event);
    if (mifNode != null) {
      this.deselectEvent.emit();
    }
  }

  treeEvent_toggleExpanded(event: any) {
    const mifNode = this.eventToMifNode(event);
    if (mifNode != null) {
      if (event.isExpanded) {
        this.expandEvent.emit(mifNode);
      } else {
        this.collapseEvent.emit(mifNode);
      }
    }
  }

  buttonTooltipDeselect(node: any): string {
    return `Deselect ${this.nodeToNodeType(node)}`;
  }

  buttonClickedDeselect(node: any) {
    const mifNode = this.nodeToMifNode(node);
    if (mifNode != null) {
      this.clickedDeselectEvent.emit(mifNode);
    }
  }

  buttonVisibleAddQuestion(node: any): boolean {
    return !this.menuItemVisibleAddGroup(node) && this.menuItemVisibleAddQuestion(node);
  }

  buttonTooltipAddQuestion(node: any): string {
    return `Add question to ${this.nodeToNodeType(node)}`;
  }

  buttonClickedAddQuestion(node: any) {
    this.menuItemClickedAddQuestion(node);
  }

  buttonVisibleAdd(node: any): boolean {
    return !this.buttonVisibleAddQuestion(node)
      && (this.menuItemVisibleAddGroup(node) || this.menuItemVisibleAddQuestion(node));
  }

  buttonTooltipAdd(node: any): string {
    return `Add to ${this.nodeToNodeType(node)}`;
  }

  menuItemVisibleAddGroup(node: any): boolean {
    return node.data.mifIsSection;
  }

  menuItemClickedAddGroup(node: any) {
    const mifNode = this.nodeToMifNode(node);
    if (mifNode != null) {
      this.clickedAddGroupEvent.emit(mifNode);
    }
  }

  menuItemVisibleAddQuestion(node: any): boolean {
    return (node.data.mifIsSection || node.data.mifIsGroup);
  }

  menuItemClickedAddQuestion(node: any) {
    const mifNode = this.nodeToMifNode(node);
    if (mifNode != null) {
      this.clickedAddQuestionEvent.emit(mifNode);
    }
  }

  buttonTooltipEdit(node: any): string {
    return `Edit ${this.nodeToNodeType(node)}`;
  }

  buttonClickedEdit(node: any) {
    const mifNode = this.nodeToMifNode(node);
    if (mifNode != null) {
      this.clickedEditEvent.emit(mifNode);
    }
  }

  buttonTooltipRemove(node: any): string {
    return `Delete ${this.nodeToNodeType(node)}`;
  }

  buttonClickedRemove(node: any) {
    const mifNode = this.nodeToMifNode(node);
    if (mifNode != null) {
      this.clickedRemoveEvent.emit(mifNode);
    }
  }

  private eventToMifNode(event: any): MifNode {
    return event?.node?.data?.mifNode == null ? null : event?.node?.data.mifNode as MifNode;
  }

  private nodeToMifNode(node: any): MifNode {
    return node?.data?.mifNode == null ? null : node?.data.mifNode as MifNode;
  }

  private nodeToNodeType(node: any): string {
    if (node.data.mifIsQuestion) {
      return 'question';
    } else if (node.data.mifIsGroup) {
      return 'group';
    } else if (node.data.mifIsSection) {
      return 'section';
    } else {
      return '';
    }
  }
}
