import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionMenuItem} from '../../models/action-menu-item.class';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input() listData: ActionMenuItem[];
  @Input() collapseMenu?: boolean;
  @Output() toggleSideMenu: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

  closeSideMenu() {
    this.toggleSideMenu.emit();
  }
}
