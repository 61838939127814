<div class="nav-container">
  <div class="logo-wrapper">
    <img class="logo__icon"
         [ngClass]="'logo__' + envName"
         src="assets/PlowOpsLogo_Snowflake.svg"
         [alt]="dashboardVersion"
         [matTooltip]="'PlowOps: ' + dashboardVersion"
         [routerLink]="['/about']"
    />
    <img class="logo__text"
         src="assets/plowops_logo_text_only.png"
         [alt]="dashboardVersion"
    />
  </div>

  <div class="nav-items">
    <a *ngFor="let route of routeLinks"
        routerLink="{{ route.link }}"
        routerLinkActive
        class="nav-item ops-selectable-primary"
       [ngClass]="{ active: isActive(rla) }"
        #rla="routerLinkActive"
    >
      <div class="mat-body nav-item-text noselect"
           [@opacityIn]
      >
        {{ route.name }}
      </div>
    </a>
  </div>

  <app-weather-news
          class="weather-news"
          [configuration]="configuration"
          *ngIf="configuration && hasFeatureFlag(FeatureFlagEnum.Weather)"
  >
  </app-weather-news>

  <div class="account-block" *ngIf="!!configuration">
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon> login</mat-icon><span>Logout</span>
      </button>
    </mat-menu>
    <div [@opacityIn] class="account">
      <div class="account__tenant">
        {{ configuration.customerName }}
      </div>
      <div class="account__username mat-small" [matMenuTriggerFor]="menu">
        <div [matTooltip]="username" class="account__username__email">{{ (userData$ | async)?.email }}</div>
        <mat-icon class="account__username__icon">
          expand_more
        </mat-icon>
      </div>
    </div>
  </div>
</div>
