import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {LayerSpecification, LineLayerSpecification} from 'maplibre-gl';
import {RouteStyle} from '../../../../models/route';

export class ExternalConfigRouteLayer extends MapContentLayer {

  constructor(
    private routeId: number,
    private routeVisible: boolean,
    private routeColorOverride: string,
    private routeLineTypeOverride: number[],
    private routeStyle: RouteStyle,
    private fallbackRouteStyle: RouteStyle,
    source: MapContentSource
  ) {
    super(routeId.toString(), source);
  }

  toLayerSpecification(): LayerSpecification {
    const routeStyleOrFallback = !!this.routeStyle ? this.routeStyle : this.fallbackRouteStyle;
    return {
      id: this.layerId,
      type: 'line',
      source: this.sourceRef.sourceId,
      filter: [
        'all',
        [ '==', ['get', 'routeId'], ['literal', this.routeId] ],
      ],
      layout: {
        visibility: this.routeVisible ? 'visible' : 'none',
        'line-join': 'miter',
        'line-cap': 'butt',
        'line-miter-limit': 2,
      },
      paint: {
        'line-color': !!this.routeColorOverride ? this.routeColorOverride : routeStyleOrFallback.color,
        'line-width': [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          10, ['*', routeStyleOrFallback.width, ['^', 2, -1]],
          24, ['*', routeStyleOrFallback.width, ['^', 2, 8]]
        ],
        'line-opacity': routeStyleOrFallback.opacity,
        'line-dasharray': !!this.routeLineTypeOverride
            ? ['literal', this.routeLineTypeOverride]
            : ['literal', !!routeStyleOrFallback.lineType ? routeStyleOrFallback.lineType : [10]],
      }
    } as LineLayerSpecification;
  }
}
