import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-line-type-selector',
  templateUrl: './line-type-selector.component.html',
  styleUrls: ['./line-type-selector.component.scss']
})
export class LineTypeSelectorComponent implements OnInit {

  @Input() dashArray: number[];
  @Output() dashArrayChange = new EventEmitter<number[]>();
  @Output() lineTypeChanged = new EventEmitter();

  lineTypes: number[][] = [
    undefined,
    [5, 4],
    [2, 4],
    [1, 4],
    [4, 5, 1, 5],
  ];
  readonly Math = Math;

  ngOnInit() {
    this.dashArray = this.lineTypes.find(lineType => {
      return (lineType === undefined && this.dashArray === undefined) ||
          lineType?.join(':::') === this.dashArray?.join(':::');
    });
  }

  onChange(e: number[]) {
    this.dashArrayChange.emit(e);
    this.lineTypeChanged.emit();
  }
}
