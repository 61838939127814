<h1 mat-dialog-title>
  Confirmation
</h1>

<mat-dialog-content>
  <p>
    Are you sure you want to delete user <b>{{data.givenName}} {{data.familyName}} ({{data.email}})</b>?
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cancel
  </button>
  <button mat-button color="warn" [mat-dialog-close]="data.email" cdkFocusInitial>
    Yes
  </button>
</mat-dialog-actions>
