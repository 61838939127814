import { Injectable } from '@angular/core';
import {RoutesService} from '../../../../data/routes/routes.service';
import {MapLayersManager} from '../map-layers-manager';
import {FeatureCollection} from 'geojson';

@Injectable({
  providedIn: 'root'
})
export class RouteSourceService {

  static readonly ROUTES_SOURCE_ID = 'routes-source';

  private mapLayersManager: MapLayersManager;
  private isEnabled: boolean;
  private routeGeometries: FeatureCollection;

  constructor(
      private routesService: RoutesService,
  ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.isEnabled = isEnabled;
    this.mapLayersManager = mapLayersManager;
    this.addSource();
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }
    this.mapLayersManager = null;
  }

  // on init
  private addSource() {
    this.addEmptySource();
    if (this.isEnabled) {
      this.initializeSource();
    }
  }

  // execute on map style change
  public reloadSource() {
    this.addEmptySource();
    if (this.isEnabled) {
      this.loadGeometries();
    }
  }

  public getFeatureCollection(): FeatureCollection {
    return this.routeGeometries;
  }

  private addEmptySource() {
    this.mapLayersManager.addSource(RouteSourceService.ROUTES_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });
  }

  private initializeSource() {
    // update source
    this.routesService.getRouteGeoJson().then(geometries => {
      this.routeGeometries = geometries;
      this.loadGeometries();
    }).catch(message => {
      console.log('Loading Route Geometries failed!');
    });
  }

  private loadGeometries() {
    if (!!this.routeGeometries) {
      const source = this.mapLayersManager.getGeoJsonSource(RouteSourceService.ROUTES_SOURCE_ID);
      if (!!source) {
        source.setData(this.routeGeometries);
      }
    }
  }
}
