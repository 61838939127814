import {Component, Input, OnInit} from '@angular/core';
import {RoadSegmentStatusService} from '../../../../data/road/road-segment-status.service';
import {RoadSegmentStatus} from '../../../models/road-status';

@Component({
  selector: 'app-road-status-info-window-content',
  templateUrl: './road-status-info-window-content.component.html',
  styleUrls: ['./road-status-info-window-content.component.scss']
})
export class RoadStatusInfoWindowContentComponent implements OnInit {

  @Input() roadSegmentId: number;
  roadSegmentStatus: RoadSegmentStatus;
  isLoading = true;

  constructor(private roadSegmentStatusService: RoadSegmentStatusService) { }

  ngOnInit(): void {
    this.roadSegmentStatusService.getRouteSegmentStatus(this.roadSegmentId).then(response => {
      this.roadSegmentStatus = response.data;
      this.isLoading = false;
    }).catch(error => {
      console.error(error);
      this.isLoading = false;
    });
  }
}
