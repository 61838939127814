import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ShiftReportTableSettingsDialogData} from './ShiftReportTableSettingsDialogData';

@Component({
  selector: 'app-shift-report-table-settings-dialog',
  templateUrl: './shift-report-table-settings-dialog.component.html',
  styleUrls: ['./shift-report-table-settings-dialog.component.scss']
})
export class ShiftReportTableSettingsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ShiftReportTableSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShiftReportTableSettingsDialogData) {}

  buttonClickedOk(): void {
    this.dialogRef.close(this.data);
  }

  isDialogValid(): boolean {
    // if at least one column is selected
    return this.data.columns.some(value => value.displayed);
  }
}
