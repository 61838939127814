import {MapContentSource} from './MapContentSource';
import {LayerSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {MapTools} from '../../../tools/MapTools';

export abstract class MapContentLayer {

  protected constructor(
    public layerId: string,
    protected sourceRef: MapContentSource
  ) {}

  abstract toLayerSpecification(): LayerSpecification;
}
