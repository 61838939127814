import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

export interface HardwareType {
  id: string;
  name: string;
}

export enum HardwareTypeId {
  LMU = 'lmu',
  TABLET = 'tablet',
}

@Component({
  selector: 'app-hardware-filter',
  templateUrl: './hardware-filter.component.html',
  styleUrls: ['./hardware-filter.component.scss']
})
export class HardwareFilterComponent implements OnInit {

  SETTINGS_KEY = 'selectedHardwareType';
  URL_PARAM_NAME = 'hardwareType';

  items: HardwareType[] = [
    {
      id: HardwareTypeId.LMU,
      name: 'LMU'
    },
    {
      id: HardwareTypeId.TABLET,
      name: 'Tablet',
    }
  ];
  selected: HardwareType;

  @Input() origin;
  @Output() filterChanged = new EventEmitter<HardwareType>();

  constructor(
      private router: Router,
      private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    let itemId = this.route.snapshot.queryParams[this.URL_PARAM_NAME];
    if (!itemId) {
      itemId = this.getCachedSelectedItemId();
    }
    if (!!itemId) {
      const selectedItem = this.items.find(item => item.id === itemId);
      this.setFilter(selectedItem);
    }
  }

  setFilter(item: HardwareType) {
    this.selected = item;
    this.appendFilterToURL(item?.id);
    if (!!item?.id) {
      localStorage.setItem(this.getSelectedItemKey(), item.id);
    } else {
      localStorage.removeItem(this.getSelectedItemKey());
    }
    this.filterChanged.emit(this.selected);
  }

  appendFilterToURL(itemIdsString: string) {
    this.router.navigate([], {
      queryParams: {
        [this.URL_PARAM_NAME]: itemIdsString,
      },
      queryParamsHandling: 'merge',
    });
  }

  getCachedSelectedItemId(): string {
    const selectedItemId = localStorage.getItem(
        this.getSelectedItemKey()
    );
    return selectedItemId;
  }

  private getSelectedItemKey() {
    return `${this.SETTINGS_KEY}:${this.origin}`;
  }
}
