import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'volume'
})
export class VolumePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof(value) === 'number') {
      if (args[0]) { // use metric system
        return `${formatNumber(value, 'en-US', '0.1-2')} l`;
      } else {
        return `${formatNumber(value / 3.785, 'en-US', '0.1-2')} gal`;
      }
    } else {
      return 'N/A';
    }
  }
}
