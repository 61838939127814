import {Component, Input} from '@angular/core';

export class VerticalIconButton {
  constructor(
      readonly icon: string,
      readonly title: string,
      readonly activePredicate: () => boolean,
      readonly onClick: () => void,
  ) {}
}

@Component({
  selector: 'app-vertical-icon-button',
  templateUrl: './vertical-icon-button.component.html',
  styleUrls: ['./vertical-icon-button.component.scss']
})
export class VerticalIconButtonComponent {
  @Input() input: VerticalIconButton;
}
