import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {Asset} from '../../../models/asset.class';
import {Observable, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ShiftState} from '../../../../../shared/models/shift.model';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {map, startWith} from 'rxjs/operators';
import {AddressSuggestion, ArcgisApiService} from '../../../../../data/address-search/arcgis-api.service';
import {AddressLookupMapMarkerService} from '../../../../../shared/components/map-viewer/services/address-lookup-map-marker.service';
import {Router} from '@angular/router';
import {RouteConfigurationWithSchema, RouteHierarchyItem, RouteHierarchyItemWithPath} from '../../../../../shared/models/route';
import {Poi} from '../../../../../shared/models/poi.model';
import {PoiService} from '../../../../../data/address-search/poi.service';
import {LiveMapDataService} from '../../../services/live-map-data.service';

export class SearchResult {
  constructor(
      public type: SearchResultType,
      public result: Asset | AddressSuggestion | RouteHierarchyItem | Poi,
  ) {
  }
}

export enum SearchResultType {
  ADDRESS = 'address',
  VEHICLE = 'vehicle',
  ROUTE = 'route',
  POI = 'poi',
}

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  @Input() configuration: ConfigurationModel;
  @Output() searchResulted = new EventEmitter<SearchResult>();

  private assets: Asset[] = [];
  filteredAssets$: Observable<Asset[]>;
  addressSuggestions: AddressSuggestion[];
  private routeItems: RouteHierarchyItemWithPath[] = []; // routes (only leaves)
  routeSuggestions: RouteHierarchyItemWithPath[];
  pois: Poi[] = [];

  searchControl = new FormControl<string | SearchResult>('');
  showAutocomplete = false;

  FeatureFlagEnum = FeatureFlagEnum;
  ShiftStatus = ShiftState;
  SearchResultType = SearchResultType;
  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private router: Router,
      private assetsManager: AssetsManagerService,
      private liveMapDataService: LiveMapDataService,
      private arcGisApiService: ArcgisApiService,
      private poiService: PoiService,
      private addressLookupMapMarkerService: AddressLookupMapMarkerService,
  ) {
  }

  ngOnInit() {
    const assetSubscription = this.assetsManager.assets$.subscribe(assets => {
      this.assets = assets;
    });
    this.openSubscriptions.push(assetSubscription);

    this.filteredAssets$ = this.searchControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this.filterSearchQuery(value);
        })
    );

    const routeSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfiguration => {
      this.routeItems = routeConfiguration.map(routeConfig => {
        return this.routeConfigToHierarchy(routeConfig);
      }).flat();
    });
    this.openSubscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  showDropdown(e: any) {
    e && e.length >= 1
        ? (this.showAutocomplete = true)
        : (this.showAutocomplete = false);
  }

  onFocusOut(e) {
    this.showAutocomplete = false;
  }

  filterSearchQuery(value: string | SearchResult): Asset[] {
    if (!value || value === '') {
      return [];
    }
    if (typeof value === 'string') {

      // find poi
      if (this.hasFeatureFlag(FeatureFlagEnum.Poi)) {
        this.poiAutocomplete(value);
      }

      // find route
      this.routeAutocomplete(value);

      // find addresses/places
      this.addressAutocomplete(value);

      // filter assets
      return this.assets.filter(
          asset => JSON.stringify(asset).toLowerCase().indexOf(String(value).toLowerCase()) !== -1
      );
    } else {
      return [];
    }
  }

  onPressEnter(e: Event) {
    const searchResult: SearchResult = this.searchControl.value as SearchResult;
    switch (searchResult?.type) {
      case SearchResultType.VEHICLE:
        this.vehicleSelected(searchResult.result as Asset);
        break;
      case SearchResultType.ADDRESS:
        this.addressSelected(searchResult.result as AddressSuggestion);
        break;
      case SearchResultType.ROUTE:
        this.routeSelected(searchResult.result as RouteHierarchyItemWithPath);
        break;
      case SearchResultType.POI:
        this.poiSelected(searchResult.result as Poi);
        break;
      default:
        console.warn('Missing implementation to get search result on enter press! Probably nothing found.');
        console.log(searchResult);
    }
  }

  displayFn(searchResult: SearchResult): string {
    if (!searchResult) {
      return '';
    }
    switch (searchResult.type) {
      case SearchResultType.VEHICLE:
        const asset = searchResult.result as Asset;
        return !!asset && !!asset.name ? asset.name : '';
      case SearchResultType.ADDRESS:
        const addressSuggestion = searchResult.result as AddressSuggestion;
        return addressSuggestion.text;
      case SearchResultType.ROUTE:
        const routeItem = searchResult.result as RouteHierarchyItem;
        return routeItem.value;
      case SearchResultType.POI:
        const poi = searchResult.result as Poi;
        return poi.name;
      default:
        console.warn('Missing implementation to display search result!');
        return '';
    }
  }

  clearSearch() {
    this.searchControl.enable();
    this.searchControl.reset();
    if (this.addressLookupMapMarkerService.isInitialized) {
      this.addressLookupMapMarkerService.clearLocations();
    }
    this.showAutocomplete = false;
    this.addressSuggestions = [];
    this.routeSuggestions = [];
    this.pois = [];
  }

  private addressAutocomplete(value: string) {
    this.arcGisApiService.suggestAddresses(value).then(suggestions => {
      this.addressSuggestions = suggestions;
    });
  }

  private poiAutocomplete(value: string) {
    if (value.length > 3) {
      this.poiService.searchByName(value).toPromise().then(response => {
        this.pois = response.data;
      });
    }
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItemWithPath[] {
    return RouteHierarchyItem.getLeaves(routeConfiguration.schema.classification.length, routeConfiguration.configuration.children)
        .map(item => {
          item.configId = routeConfiguration.id;
          item.routeName = null;
          return item;
        });
  }

  private routeAutocomplete(value: string) {
    this.routeSuggestions = this.routeItems.filter(
        route => JSON.stringify(route).toLowerCase().indexOf(String(value).toLowerCase()) !== -1
    );
  }

  getPoiIconName() {
    // the only POIs so far are Wyoming mileposts
    return 'signpost';
  }

  vehicleSelected(asset: Asset) {
    this.searchResulted.emit(
        new SearchResult(
            SearchResultType.VEHICLE,
            asset,
        )
    );
    this.clearSearch();
  }

  addressSelected(addressSuggestion: AddressSuggestion) {
    this.searchResulted.emit(
        new SearchResult(
            SearchResultType.ADDRESS,
            addressSuggestion,
        )
    );
    this.searchControl.disable();
  }

  routeSelected(routeItem: RouteHierarchyItemWithPath) {
    this.searchResulted.emit(
        new SearchResult(
            SearchResultType.ROUTE,
            routeItem,
        )
    );
    this.clearSearch();
  }

  poiSelected(poi: Poi) {
    this.searchResulted.emit(
        new SearchResult(
            SearchResultType.POI,
            poi,
        )
    );
    this.searchControl.disable();
  }
}
