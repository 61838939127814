export class RouteFilterUpdate {
    constructor(
        readonly configId: number,
        readonly routeIds: number[],
    ) {}
}

export class RouteAssignmentFilterUpdate {
    constructor(
        readonly configId: number,
        readonly routeIds: number[],
    ) {}
}
