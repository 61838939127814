import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../../../../../shared/models/observation-type';

@Component({
  selector: 'app-delete-observation-type-dialog',
  templateUrl: 'delete-observation-type-dialog.component.html',
  styleUrls: ['delete-observation-type-dialog.component.scss']
})
export class DeleteObservationTypeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteObservationTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
