<div class="image-report-container">
    <div class="image-report-header">

        <app-filter-bar
                class="header__filters"
                [origin]="'imageReport'"
                [useDateFilter]="true"
                [useVehicleGroupFilter]="true"
                (dateFilterChanged)="dateFilterChanged($event)"
                (vehicleGroupFilterChanged)="vehicleGroupFilterChanged($event)"
        >
        </app-filter-bar>

        <div class="header__actions">
            <button
                    mat-button
                    color="accent"
                    [disabled]="!imagePreview"
                    (click)="downloadImage()"
            >
                <mat-icon>download</mat-icon> Download
            </button>
        </div>
    </div>

    <div class="content__wrapper">
        <div class="table__wrapper">
            <app-image-report-table
                    [dataSource]="dataSource"
                    (sortChangedEvent)="loadReport()"
                    (scrolledToBottomEvent)="extendReport()"
                    (imageSelected)="onImageSelectionChanged($event)"
            >
            </app-image-report-table>
        </div>
        <div class="image__preview">
            <app-retrying-image-viewer *ngIf="!!imagePreview"
                                       [imageUrl]="imagePreview?.imageUrl"
                                       [alternativeText]="'Dashboard Camera Image'"></app-retrying-image-viewer>
        </div>
    </div>
</div>
