import {Component, Input, OnInit} from '@angular/core';
import {InspectionReport} from '../../../../shared/models/dvir';

@Component({
  selector: 'app-dvir-inspection',
  templateUrl: './dvir-inspection.component.html',
  styleUrls: ['./dvir-inspection.component.scss']
})
export class DvirInspectionComponent implements OnInit {

  @Input() inspection: InspectionReport;

  displayedColumns: string[] = ['check', 'status', 'explained'];

  constructor() { }

  ngOnInit(): void {
  }

}
