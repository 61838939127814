import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {CreateVehicleGroupDialogInput} from '../model/CreateVehicleGroupDialogInput';
import {UpdateVehicleGroupDialogInput} from '../model/UpdateVehicleGroupDialogInput';
import {NamedId} from '../../../../../shared/models/NamedId';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';

export interface VehicleCategoryData {
    id: number;
    name: string;
    title: string;
    useDvir: boolean;
    maxStationaryTime: number;
    observationTypeGroups: number[];
}

abstract class VehicleCategoryBase {
    // this is a hack to access enums from templates
    FeatureFlagEnum = FeatureFlagEnum;
    configuration: ConfigurationModel;
    configSubscription: Subscription;

    protected constructor(
        public readonly dialogRef: MatDialogRef<DialogUpdateVehicleCategoryComponent | DialogCreateVehicleCategoryComponent>,
        private readonly configurationService: ConfigurationService,
        public data: CreateVehicleGroupDialogInput | UpdateVehicleGroupDialogInput) {}

    protected loadGeneralConfiguration() {
        this.configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
            this.configuration = model;
        });
    }

    public namedIdComparison = (option: NamedId, value: NamedId): boolean => {
        return option?.id === value?.id;
    }

    hasFeatureFlag(featureFlag: string): boolean {
        return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
    }

    onMaxStationaryTimeCheckboxChange(event: MatCheckboxChange) {
        this.data.model.maxStationaryTime = event.checked ? 10 : null;
    }

    isFormValid() {
        return !!this.data.model.title && this.data.model.title.length > 0 &&
            (this.data.model.maxStationaryTime === undefined || this.data.model.maxStationaryTime === null ||
                (!!this.data.model.maxStationaryTime && this.data.model.maxStationaryTime >= 10)) &&
            this.isDvirFormSelectionValid();
    }

    private isDvirFormSelectionValid(): boolean {
        return !this.data.model.useDvir ||
            this.hasFeatureFlag(FeatureFlagEnum.CartegraphIntegration) ||
            (this.data.model.useDvir && (this.data.model.preTripForm != null || this.data.model.postTripForm != null));
    }

    isInspectionFormMissingWhenUsingDvir(): boolean {
        return this.data.model.useDvir &&
            this.data.model.preTripForm == null && this.data.model.postTripForm == null;
    }
}

@Component({
    selector: 'app-dialog-confirm-delete-vehicle-category',
    templateUrl: './dialog-confirm-delete-vehicle-category.html',
})
export class DialogConfirmDeleteVehicleCategoryComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmDeleteVehicleCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VehicleCategoryData) {}
}

@Component({
    selector: 'app-dialog-update-vehicle-category',
    templateUrl: './dialog-edit-vehicle-category.html',
    styleUrls: ['../dialogs-vehicle/dialog-components.scss'],
})
export class DialogUpdateVehicleCategoryComponent extends VehicleCategoryBase implements OnInit, OnDestroy {

    headline = 'Update Vehicle Category';
    actionButtonLabel = 'Update';

    constructor(
        dialogRef: MatDialogRef<DialogUpdateVehicleCategoryComponent>,
        configurationService: ConfigurationService,
        @Inject(MAT_DIALOG_DATA) public data: UpdateVehicleGroupDialogInput) {
        super(dialogRef, configurationService, data);
    }

    ngOnInit(): void {
        this.loadGeneralConfiguration();
    }

    ngOnDestroy(): void {
        this.configSubscription?.unsubscribe();
    }
}

@Component({
    selector: 'app-dialog-create-vehicle-category',
    templateUrl: './dialog-edit-vehicle-category.html',
    styleUrls: ['../dialogs-vehicle/dialog-components.scss'],
})
export class DialogCreateVehicleCategoryComponent extends VehicleCategoryBase implements OnInit, OnDestroy {

    headline = 'Create a new Vehicle Category';
    actionButtonLabel = 'Create';

    constructor(
        dialogRef: MatDialogRef<DialogCreateVehicleCategoryComponent>,
        configurationService: ConfigurationService,
        @Inject(MAT_DIALOG_DATA) public data: CreateVehicleGroupDialogInput) {
        super(dialogRef, configurationService, data);
    }

    ngOnInit(): void {
        this.loadGeneralConfiguration();
    }

    ngOnDestroy(): void {
        this.configSubscription?.unsubscribe();
    }
}
