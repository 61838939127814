<form [formGroup]="updateForm" *ngIf="!!inspectionSettings && !!updateForm">

    <mat-card appearance="outlined" class="mb-15">
        <mat-card-header>
            <mat-card-title>Inspection form configuration</mat-card-title>
        </mat-card-header>

        <mat-card-content class="mt-25">

            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['inspectionFormSection'].touched && updateForm.controls['inspectionFormSection'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>e.g. 'Vehicle Inspection',Inspection form section (cgTaskClass layout)</mat-label>
                    <input type="text" matInput
                           placeholder="Vehicle Inspection"
                           formControlName='inspectionFormSection'
                           name="inspectionFormSection">
                    <mat-error *ngIf="updateForm.controls['inspectionFormSection'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['inspectionOdometerField'].touched && updateForm.controls['inspectionOdometerField'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>cgTask odometer field</mat-label>
                    <input type="text" matInput
                           placeholder="UsageField"
                           formControlName='inspectionOdometerField'
                           name="inspectionOdometerField">
                    <mat-error *ngIf="updateForm.controls['inspectionOdometerField'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>
            </div>


        </mat-card-content>
    </mat-card>
</form>