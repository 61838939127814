import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterSetting, LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';

@Component({
  selector: 'app-location-history-category-view',
  templateUrl: './location-history-category-view.component.html',
  styleUrls: ['./location-history-category-view.component.scss'],
})
export class LocationHistoryCategoryViewComponent {

  @Input() layerType?: LayerTypeFilter;
  @Output() colorsChangedEvent: EventEmitter<LayerTypeFilter> = new EventEmitter();

  showDetailIndex: number = null;

  protected readonly Math = Math;

  editLineStyle(index: number, lineStyle: FilterSetting) {
    if (this.showDetailIndex === null || index !== this.showDetailIndex) {
      this.showDetailIndex = index;
    } else {
      this.showDetailIndex = null;
    }
  }

  onLineStyleChange() {
    this.colorsChangedEvent.emit(this.layerType);
  }
}
