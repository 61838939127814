import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MifForm} from '../../pages/settings/components/manage-inspection-forms/model/MifForm';
import {MifFormDto} from '../../pages/settings/components/manage-inspection-forms/model/dto/MifFormDto';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {MifForms} from '../../pages/settings/components/manage-inspection-forms/model/MifForms';
import {MifSystemForm} from '../../pages/settings/components/manage-inspection-forms/model/MifSystemForm';

@Injectable({
  providedIn: 'root'
})
export class InspectionFormsService {

  constructor(private http: HttpClient) {}

  saveForm(form: MifForm): Promise<MifForm> {
    const formDto = form.toDto();
    // console.log('outgoing (save)');
    // console.log(JSON.stringify(formDto));
    return this.http.post<JsonApiResponse<MifFormDto>>(
      `${environment.services.service}v1/dvir-form`, formDto
    ).pipe(catchError(HttpErrorHandler.handleError)).toPromise()
      .then(response => {
        // console.log('incoming (save)');
        // console.log(JSON.stringify(formDto));
        const savedForm = MifForm.fromDto(response.data);
        return Promise.resolve(savedForm);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteForm(externalId: number): Promise<number> {
    return this.http.delete<JsonApiResponse<number>>(
      `${environment.services.service}v1/dvir-form/${externalId}`, {}
    ).pipe(catchError(HttpErrorHandler.handleError)).toPromise()
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  getForms(): Promise<MifForms> {
    const params = {
      'fetch-type': 'FULL'
      // 'fetch-type': 'SIMPLE'
    };
    return this.http.get<JsonApiResponse<MifFormDto[]>>(
      `${environment.services.service}v1/dvir-form/list`, { params }
    ).pipe(catchError(HttpErrorHandler.handleError)).toPromise()
      .then(response => {
        const forms = response.data.map(dto => MifForm.fromDto(dto));
        return Promise.resolve(new MifForms(forms));
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  getSystemForms(): Promise<MifSystemForm[]> {
    return this.http.get<JsonApiResponse<MifSystemForm[]>>(
      `${environment.services.service}v1/configuration/system-inspection-forms`, {}
    ).pipe(catchError(HttpErrorHandler.handleError)).toPromise()
      .then(response => {
        const forms = response.data;
        return Promise.resolve(forms);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  getForm(externalId: number): Promise<MifForm> {
    return this.http.get<JsonApiResponse<MifFormDto>>(
      `${environment.services.service}v1/dvir-form/${externalId}`, {}
    ).pipe(catchError(HttpErrorHandler.handleError)).toPromise()
      .then(response => {
        // console.log('incoming (load)');
        // console.log(JSON.stringify(response.data));
        const form = MifForm.fromDto(response.data);
        return Promise.resolve(form);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
