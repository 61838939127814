import {NamedId} from '../../../../../shared/models/NamedId';
import {VehicleGroupModelWithVehicles} from './VehicleGroupModelWithVehicles';
import {VehicleCategoryModelWithIds} from '../../../../../shared/models/vehicle';

export class VehicleGroupModel {
    id: number;
    name: string;
    title: string;
    useDvir: boolean;
    allowRouteSelfAssign: boolean;
    preTripForm: NamedId;
    postTripForm: NamedId;
    showVehicles: boolean;
    showVehicleHistory: boolean;
    showVehicleObservations: boolean;
    maxStationaryTime?: number;
    observationTypeGroups: NamedId[] = [];
    allowedRouteConfigs: NamedId[] = [];

    static fromModelWithVehicles(modelWithVehicles: VehicleGroupModelWithVehicles) {
        const model = new VehicleGroupModel();
        model.id = modelWithVehicles.id;
        model.name = modelWithVehicles.name;
        model.title = modelWithVehicles.title;
        model.useDvir = modelWithVehicles.useDvir;
        model.allowRouteSelfAssign = modelWithVehicles.allowRouteSelfAssign;
        model.preTripForm = modelWithVehicles.preTripForm;
        model.postTripForm = modelWithVehicles.postTripForm;
        model.showVehicles = modelWithVehicles.showVehicles;
        model.showVehicleHistory = modelWithVehicles.showVehicleHistory;
        model.showVehicleObservations = modelWithVehicles.showVehicleObservations;
        model.maxStationaryTime = modelWithVehicles.maxStationaryTime;
        model.observationTypeGroups = [...modelWithVehicles.observationTypeGroups];
        model.allowedRouteConfigs = [...modelWithVehicles.allowedRouteConfigs];
        return model;
    }

    toDto(): VehicleCategoryModelWithIds {
        const dto = new VehicleCategoryModelWithIds();
        dto.id = this.id;
        dto.name = this.name;
        dto.title = this.title;
        dto.useDvir = this.useDvir;
        dto.allowRouteSelfAssign = this.allowRouteSelfAssign;
        dto.preTripFormJournalId = this.preTripForm?.id;
        dto.postTripFormJournalId = this.postTripForm?.id;
        dto.showVehicles = this.showVehicles;
        dto.showVehicleHistory = this.showVehicleHistory;
        dto.showVehicleObservations = this.showVehicleObservations;
        dto.maxStationaryTime = this.maxStationaryTime;
        dto.observationTypeGroupIds = this.observationTypeGroups.map(value => value.id);
        dto.allowedRouteConfigIds = this.allowedRouteConfigs.map(value => value.id);
        return dto;
    }
}
