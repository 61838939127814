import {NamedId} from '../../../../../shared/models/NamedId';
import {VehicleGroupModel} from './VehicleGroupModel';

export class CreateVehicleGroupDialogInput {
    model: VehicleGroupModel;
    allInspectionForms: NamedId[];
    allObservationTypeGroups: NamedId[];
    allAllowedRouteConfigs: NamedId[];
    isImported: boolean;

    constructor(
        model: VehicleGroupModel,
        allInspectionForms: NamedId[],
        allObservationTypeGroups: NamedId[],
        allAllowedRouteConfigs: NamedId[],
        isImported: boolean
    ) {
        this.model = model;
        this.allInspectionForms = allInspectionForms;
        this.allObservationTypeGroups = allObservationTypeGroups;
        this.allAllowedRouteConfigs = allAllowedRouteConfigs;
        this.isImported = isImported;
    }
}
