import {Component, EventEmitter, Input, Output} from '@angular/core';
import { MapStyles } from 'src/app/configuration/map-styles';
import {ActivityFilter} from '../map-layer-switcher.component';

@Component({
  selector: 'app-gps-tracks-legend',
  templateUrl: './gps-tracks-legend.component.html',
  styleUrls: ['./gps-tracks-legend.component.scss']
})
export class GpsTracksLegendComponent {

  @Input() appliedFilter: ActivityFilter;
  @Output() settings = new EventEmitter();
  @Output() clearFilterEvent = new EventEmitter();

  MapStyles = MapStyles;

  goToSettings() {
    this.settings.emit();
  }

  clearFilter() {
    this.clearFilterEvent.emit();
  }
}
