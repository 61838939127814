import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';

@Component({
  selector: 'app-gradient-filter-container',
  templateUrl: './gradient-filter-container.component.html',
  styleUrls: ['./gradient-filter-container.component.scss'],
})
export class GradientFilterContainerComponent {

  @Input() layerType?: LayerTypeFilter;
  @Input() editable: boolean;

  @Output() colorsChangedEvent: EventEmitter<string[]> = new EventEmitter();

  constructor() {}

  colorChanged(pickedColor: string) {
    if (this.editable && this.layerType != null) {
      this.colorsChangedEvent.emit(this.layerType.measurementUnits.map(value => value.color));
    }
  }
}
