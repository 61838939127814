<div class="left-panel-container noselect">
  <div class="collapse__btn__wrapper">
    <app-map-panel-toggle-button></app-map-panel-toggle-button>
  </div>
  <div class="live-map-navigation">
    <ng-container *ngFor="let item of items">
      <app-vertical-icon-button
        [input]="item"></app-vertical-icon-button>
    </ng-container>
  </div>

  <div class="panel-content">
    <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
  </div>

</div>
