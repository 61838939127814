import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {LayerSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {MapFilters} from '../../../../../configuration/map-filters';
import {TracksLayerFilter} from '../../../../../configuration/settings.service';
import {MapPreviewComponent} from '../../map-preview.component';

export class ShiftTrackArrowsLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('shift-track-arrows-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceRef.sourceId,
      filter: MapFilters.getShiftGeoJsonFilter(
        TracksLayerFilter.NONE, null, null
      ),
      layout: {
        'symbol-placement': 'line',
        'icon-image': MapPreviewComponent.TRACK_ARROW_IMAGE,
        'icon-rotate': 90,
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      },
      paint: {
      }
    } as SymbolLayerSpecification;
  }
}
