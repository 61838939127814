import { Injectable } from '@angular/core';
import {Subscription} from 'rxjs';
import {Map, GeoJSONSource, MapMouseEvent, LngLat} from 'maplibre-gl';
import {PointFeature} from '../../../models/GeoJson';
import {ObservationMapMarkerService} from './observation-map-marker.service';
import {VehicleMapMarkerService} from './vehicle-map-marker.service';
import {VehiclesManagerService} from '../../../../data/vehicles/vehicles-manager.service';
import {ObservationsManagerService} from '../../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../../data/images/images-manager.service';
import {MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {BreadcrumbsManagerService} from '../../../../data/breadcrumbs/breadcrumbs-manager.service';
import {BreadcrumbsService} from '../../../../data/breadcrumbs/breadcrumbs.service';
import {TracksVectorTilesLayerService} from './tracks-vector-tiles-layer.service';
import {ShiftWithDriverAndVehicleModel} from '../../../models/shift.model';
import {BreadcrumbInfoWindowService} from './breadcrumb-info-window.service';
import {Vehicle, VehicleGroup} from '../../../models/vehicle';
import {PopupInfoService} from './popup-info.service';
import {AddressLookupMapMarkerService} from './address-lookup-map-marker.service';
import {ShiftTrackLayerService} from './shift-track-layer.service';
import {TracksGeoJsonLayerService} from './tracks-geo-json-layer.service';
import {VehicleBreadcrumb, VehicleBreadcrumbUpdate} from '../../../models/vehicle-breadcrumb';
import {LocationSearchParams} from '../../../models/breadcrumb.model';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {RoadStatusCoverageLayerService} from './road-status-coverage-layer.service';
import {RoadStatusCurrencyLayerService} from './road-status-currency-layer.service';
import {WeatherWarningsLayerService} from './weather-warnings-layer.service';
import {ToastService} from '../../../services/toast.service';
import {GpsSourcePipe} from '../../../formatting/gps-source.pipe';
import { AssetsManagerService } from 'src/app/data/assets/assets-manager.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';
import {ActivatedRoute, Router} from '@angular/router';
import {LiveMapTab} from '../../../../pages/live-map/models/live-map-tabs';
import {MapControlService} from './map-control.service';
import {ImageMapMarkerService} from './image-map-marker.service';
import {TrafficLayerService} from './traffic-layer.service';
import {DrawerContent} from '../../../../layouts/right-drawer/right-drawer.component';

@Injectable({
  providedIn: 'root'
})
export class MapEventService {

  private map: Map;
  private openBreadcrumbSearchSubscription: Subscription = null;
  private vehiclesFilterSubscription: Subscription = null;
  private searchSnackbar: MatSnackBarRef<SimpleSnackBar> = null;
  private vehicleIdsFilter: number[] = null;
  private shift: ShiftWithDriverAndVehicleModel = null;

  constructor(private assetManager: AssetsManagerService,
              private vehiclesManager: VehiclesManagerService,
              private breadcrumbsService: BreadcrumbsService,
              private breadcrumbManagerService: BreadcrumbsManagerService,
              private vehiclesManagerService: VehiclesManagerService,
              private vehicleMapMarkerService: VehicleMapMarkerService,
              private observationsManagerService: ObservationsManagerService,
              private observationMapMarkerService: ObservationMapMarkerService,
              private imagesManagerService: ImagesManagerService,
              private shiftsManagerService: ShiftsManagerService,
              private breadcrumbInfoWindowService: BreadcrumbInfoWindowService,
              private markerInfoWindowService: PopupInfoService,
              private addressLookupMapMarkerService: AddressLookupMapMarkerService,
              private weatherWarningsService: WeatherWarningsLayerService,
              private trafficLayerService: TrafficLayerService,
              private mapControlService: MapControlService,
              private gpsSourcePipe: GpsSourcePipe,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toast: ToastService) { }

  init(map: Map, shift?: ShiftWithDriverAndVehicleModel) {
    if (!!this.map) {
      throw Error('The map has already been set. Only a single map instance is supported.');
    }

    this.map = map;
    if (shift) {
      this.shift = shift;
    }
    this.attachOnClickHandlers();

    this.vehiclesFilterSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleAssetsChanged(assets);
    });
  }

  release() {
    if (!this.map) {
      throw Error('The map has not been set.');
    }

    this.cancelOpenBreadcrumbsSearch();
    if (this.vehiclesFilterSubscription) {
      this.vehiclesFilterSubscription.unsubscribe();
    }

    this.vehicleIdsFilter = null;
    this.map = null;
    this.shift = null;
  }

  private onClusteredPointClick(e: MapMouseEvent, clusterLayerId: string, sourceId: string) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: [clusterLayerId]
    });
    const clusterId = features[0].properties.cluster_id;
    (this.map.getSource(sourceId) as GeoJSONSource).getClusterExpansionZoom(
      clusterId,
      (err, zoom) => {
        if (err) {
          return;
        }
        // @ts-ignore
        const point = features[0] as PointFeature;
        this.map.easeTo({
          center: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
          zoom,
          essential: true
        });
      }
    );
  }

  attachOnClickHandlers() {
    const that = this;
    // on observation point click handler
    this.map.on('click', ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_CIRCLE, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.observationsManagerService.highlightObservation(pointFeature.properties.id, ObservationsManagerService.MAP_SOURCE);
    });
    this.showPointCursorForLayer(ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_CIRCLE);

    // on clustered observations click handler
    /*this.map.on('click', ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      that.onClusteredPointClick(e,
        ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS,
        ObservationMapMarkerService.OBSERVATION_SOURCE_ID
      );
    });
    this.showPointCursorForLayer(ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS);*/

    // on vehicle point click handler
    this.map.on('click', VehicleMapMarkerService.LAYER_ID_VEHICLES_CIRCLE, (e) => {
      // console.log('vehicle marker click');
      if (this.checkPropagation(e)){
        return;
      }
      const clickedFromMessages = this.router.routerState.snapshot.url.startsWith('/live-map/' + LiveMapTab.MESSAGES);
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.router.navigate(['live-map', LiveMapTab.ASSETS, pointFeature.properties.id], {
        queryParams: {
          drawer: clickedFromMessages ? DrawerContent.QUICK_MESSAGE : undefined
        }
      });
    });
    this.showPointCursorForLayer(VehicleMapMarkerService.LAYER_ID_VEHICLES_CIRCLE);

    // on clustered vehicles click handler
    /*this.map.on('click', VehicleMapMarkerService.LAYER_ID_VEHICLE_CLUSTER_CIRCLE, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      that.onClusteredPointClick(e,
        VehicleMapMarkerService.LAYER_ID_VEHICLE_CLUSTER_CIRCLE,
        VehicleMapMarkerService.VEHICLE_SOURCE_ID
      );
    });
    this.showPointCursorForLayer(VehicleMapMarkerService.LAYER_ID_VEHICLE_CLUSTER_CIRCLE);*/

    // on image point click handler
    this.map.on('click', ImageMapMarkerService.LAYER_ID_IMAGES_CIRCLE, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.imagesManagerService.highlightImage(pointFeature.properties.id, ImagesManagerService.MAP_SOURCE);
    });
    this.showPointCursorForLayer(ImageMapMarkerService.LAYER_ID_IMAGES_CIRCLE);

    // on clustered images click handler
    /*this.map.on('click', ImageMapMarkerService.LAYER_ID_IMAGE_CLUSTERS, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      that.onClusteredPointClick(e,
        ImageMapMarkerService.LAYER_ID_IMAGE_CLUSTERS,
        ImageMapMarkerService.IMAGE_SOURCE_ID
      );
    });
    this.showPointCursorForLayer(ImageMapMarkerService.LAYER_ID_IMAGE_CLUSTERS);*/

    // road status layers
    for (const i of [1, 2, 3, 4, 5]) {
      that.map.on('click', `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-${i}`, (e) => {
        this.handleRoadStatusLayerClick(e);
      });
      this.showPointCursorForLayer(`${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-${i}`);

      that.map.on('click', `${RoadStatusCurrencyLayerService.LAYER_ID_PREFIX}-${i}`, (e) => {
        this.handleRoadStatusLayerClick(e);
      });
      this.showPointCursorForLayer(`${RoadStatusCurrencyLayerService.LAYER_ID_PREFIX}-${i}`);
    }

    // on breadcrumb search
    that.map.on('click', TracksVectorTilesLayerService.LAYER_ID_ACTIVE, (e) => {
      that.handleBreadCrumbSearchClick(e);
    });
    this.showPointCursorForLayer(TracksVectorTilesLayerService.LAYER_ID_ACTIVE);
    that.map.on('click', TracksVectorTilesLayerService.LAYER_ID_HISTORY, (e) => {
      that.handleBreadCrumbSearchClick(e);
    });
    this.showPointCursorForLayer(TracksVectorTilesLayerService.LAYER_ID_HISTORY);
    that.map.on('click', TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON, (e) => {
      that.handleBreadCrumbSearchClick(e, true);
    });
    this.showPointCursorForLayer(TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON);
    that.map.on('click', TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON, (e) => {
      that.handleBreadCrumbSearchClick(e, true);
    });
    this.showPointCursorForLayer(TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON);

    // on shift breadcrumb search
    that.map.on('click', ShiftTrackLayerService.LAYER_ID_TRACK, (e) => {
      that.handleBreadCrumbSearchClick(e);
    });
    this.showPointCursorForLayer(ShiftTrackLayerService.LAYER_ID_TRACK);

    // on default map click (nothing from PlowOps is clicked)
    this.map.on('click', (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      // deselect observation
      this.observationsManagerService.highlightObservation(null, ObservationsManagerService.MAP_SOURCE);
      // deselect image
      this.imagesManagerService.highlightImage(null, ImagesManagerService.MAP_SOURCE);
      // close map layer switcher
      this.mapControlService.toggleMapLayerSwitcher(false);
      // show popup on Weather Warnings layer
      if (this.weatherWarningsService.isVisible()) {
        this.weatherWarningsService.getWmsFeatures(e.lngLat).then(featureCollection => {
          if (featureCollection.features.length === 0) {
            return;
          }
          const feature = featureCollection.features[0];
          this.markerInfoWindowService.showWeatherAlert(e.lngLat, feature.properties.cap_id, feature.properties.prod_type);
        });
      }

      // show popup on Traffic layer
      if (this.trafficLayerService.isVisible()) {
        this.trafficLayerService.queryIncidents(this.map, e.lngLat).then(json => {
          // @ts-ignore
          if (json.features.length === 0) {
            return;
          }
          // @ts-ignore
          const r = json.features[0];
          // @ts-ignore
          this.markerInfoWindowService.showTrafficIncidentInfo(
              new LngLat(r.geometry.x, r.geometry.y),
              {
                description: r.attributes.description,
                end_localtime: r.attributes.end_localtime,
                end_utctime: r.attributes.end_utctime,
                fulldescription: r.attributes.fulldescription,
                incidenttype: r.attributes.incidenttype,
                lastupdated_localtime: r.attributes.lastupdated_localtime,
                lastupdated_utctime: r.attributes.lastupdated_utctime,
                location: r.attributes.location,
                objectid: r.attributes.objectid,
                severity: r.attributes.severity,
                start_localtime: r.attributes.start_localtime,
                start_utctime: r.attributes.start_utctime,
              },
          );
        });
      }
    });
  }

  private checkPropagation(e: MapMouseEvent): boolean {
    // https://github.com/mapbox/mapbox-gl-js/issues/9369
    if (e.originalEvent.cancelBubble){
      return true;
    } else {
      e.originalEvent.cancelBubble = true;
      return false;
    }
  }

  private handleRoadStatusLayerClick(e: any) {
    if (this.checkPropagation(e)){
      return;
    }
    const roadSegmentId = e.features[0]?.properties?.id as number;
    if (!!roadSegmentId) {
      this.markerInfoWindowService.showRoadStatusInfoWindow(roadSegmentId, e.lngLat);
    }
  }

  private handleBreadCrumbSearchClick(event: MapMouseEvent, includeInbox: boolean = false) {
    if (this.checkPropagation(event)){
      return;
    }
    this.cancelOpenBreadcrumbsSearch();

    // credits to https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
    const metersPerPx = 156543.03392 * Math.cos(event.lngLat.lat * Math.PI / 180) / Math.pow(2, this.map.getZoom());

    const hoursCount = 12;
    const searchParams = {
      coordinate: {
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng
      },
      timeFrom: this.shift
          ? Math.floor((new Date(this.shift.start)).valueOf() / 1000)
          : Math.floor((new Date().valueOf() - hoursCount * 60 * 60 * 1000) / 1000),
      timeTo: this.shift ? Math.floor((new Date(this.shift.end)).valueOf() / 1000) : null,
      radius: Math.min(metersPerPx * 15, 5000), // toleration 15 pixels. maximum 5km because of ws limitation
      locationSources: this.shift ? [this.shift.vehicleId] : this.vehicleIdsFilter,
      sessions: this.shift ? [this.shift.id] : null,
      includeInbox,
    } as LocationSearchParams;

    this.showSearchSnackBar('Searching for nearest breadcrumbs...');
    const that = this;
    this.openBreadcrumbSearchSubscription = this.breadcrumbsService.search(searchParams)
      .subscribe((response) => {
          const features = response.features;
          if (features.length === 0) {
            that.showSearchSnackBar('No breadcrumbs found.');
          } else {
            that.selectBreadcrumbs(features as PointFeature[], 'map-viewer');
            this.hideSearchSnackBar();
          }
        }, (error) => {
          console.error('Breadcrumbs search failed.', error);
          that.showSearchSnackBar('Breadcrumbs search failed.');
        }
      );
  }

  private selectBreadcrumbs(breadcrumbs: Array<PointFeature>, source: string) {
    const vehicleBreadcrumbs = breadcrumbs.map((breadcrumb) => {
      const vehicleId = breadcrumb.properties.locationsourceid;

      const vehicle = this.shift ? new Vehicle(
        this.shift.vehicle.id,
        new VehicleGroup(this.shift.vehicle.groupId, String(this.shift.vehicle.groupId)),
        !!this.shift.vehicle.label ? this.shift.vehicle.label : this.shift.vehicle.name,
        this.shift.vehicle.licensePlate,
        this.shift.vehicleHardwareConfiguration,
        this.shift.vehicle.cameraConfiguration,
      ) : this.vehiclesManager.getVehicle(vehicleId);

      const location = {
        id: breadcrumb.properties.id,
        coords: {
          lat: breadcrumb.geometry.coordinates[1],
          lng: breadcrumb.geometry.coordinates[0]
        },
        time: new Date(breadcrumb.properties.unixtime * 1000),
        speed: breadcrumb.properties.speed,
        heading: breadcrumb.properties.heading,
        imageUrl: null,
        flags: breadcrumb.properties.flags,
        gpsSource: breadcrumb.properties.gpssource,
      };
      return new VehicleBreadcrumb(vehicle, null, location, breadcrumb.properties.sessionkey);
    });

    if (!this.shift) {
      this.breadcrumbManagerService.selectBreadcrumb(vehicleBreadcrumbs, source);
    } else {
      this.breadcrumbInfoWindowService.showShiftBreadcrumbInfoWindow(new VehicleBreadcrumbUpdate(vehicleBreadcrumbs, source));
    }
  }

  private cancelOpenBreadcrumbsSearch() {
    if (this.openBreadcrumbSearchSubscription != null) {
      this.openBreadcrumbSearchSubscription.unsubscribe();
      this.openBreadcrumbSearchSubscription = null;
    }
  }

  private hideSearchSnackBar() {
    if (this.searchSnackbar != null) {
      this.searchSnackbar.dismiss();
      this.searchSnackbar = null;
    }
  }

  private showSearchSnackBar(message: string) {
    this.hideSearchSnackBar();
    this.searchSnackbar = this.toast.short(message);
  }

  private handleAssetsChanged(assets: Asset[]) {
    this.vehicleIdsFilter = assets.map(asset => asset.id);
  }

  private showPointCursorForLayer(layerId: string) {
    const that = this;
    that.map.on('mouseenter', layerId, () => {
      that.map.getCanvas().style.cursor = 'pointer';
    });
    that.map.on('mouseleave', layerId, () => {
      that.map.getCanvas().style.cursor = '';
    });
  }
}
