import {Component, ViewChild} from '@angular/core';
import {DateFilter} from '../../../../shared/models/DateFilter';
import {MultiSelectFilter} from '../../../../shared/components/multi-select-component';
import {MatTableDataSource} from '@angular/material/table';
import {ImageWithDriverAndVehicle} from '../../../../shared/models/image';
import {ImageReportTableComponent} from './image-report-table/image-report-table.component';
import {ToastService} from '../../../../shared/services/toast.service';
import {ImageService} from '../../../../data/images/image.service';
import {HttpClient} from '@angular/common/http';
import {SecurityService} from '../../../../security/security.service';
import {saveAs} from 'file-saver-es';
import moment from 'moment';

@Component({
  selector: 'app-image-retrieval',
  templateUrl: './image-retrieval.component.html',
  styleUrls: ['./image-retrieval.component.scss']
})
export class ImageRetrievalComponent {

  @ViewChild(ImageReportTableComponent) reportTableComponent: ImageReportTableComponent;

  pageSize = 50;
  pageIndex = 0;
  totalElements = 0;

  dataSource: MatTableDataSource<ImageWithDriverAndVehicle> = new MatTableDataSource([]);
  isLoading = false;
  isScrollLoading = false;
  imagePreview: ImageWithDriverAndVehicle;

  dateFilter: DateFilter = undefined;
  vehicleGroupFilter: MultiSelectFilter<number> = undefined;

  constructor(
      private imageService: ImageService,
      private toast: ToastService,
      private httpClient: HttpClient,
      private securityService: SecurityService,
  ) {
  }

  toggleLoading() {
    setTimeout(() => this.isLoading = !this.isLoading);
  }

  private filtersInitialized() {
    return this.vehicleGroupFilter !== undefined && this.dateFilter !== undefined;
  }

  loadReport() {
    if (this.filtersInitialized() && !!this.reportTableComponent) {
      this.toggleLoading();
      this.pageIndex = 0;
      this.isScrollLoading = false;
      this.imageService.getImagesPage(
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
          this.pageIndex,
          this.pageSize,
          this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        // console.log(`initialized, load count ${response.data.content.length} of ${response.data.totalElements}`);
        this.dataSource.data = response.data.content;
        this.totalElements = response.data.totalElements;
        this.pageSize = response.data.pageable.pageSize;
        this.toggleLoading();
        if (this.dataSource.data.length > 0) {
          if (!this.imagePreview) {
            // select the first item
            this.reportTableComponent.highlight(this.dataSource.data[0]);
          }
        } else {
          // deselect if empty response
          this.imagePreview = null;
        }
      }).catch(error => {
        this.toggleLoading();
        this.toast.longer('Error while fetching initial data from the server');
        console.error(error);
      });
    }
  }

  extendReport() {
    if (!this.isScrollLoading && this.dataSource.data.length < this.totalElements) {
      this.pageIndex++;
      this.isScrollLoading = true;
      this.imageService.getImagesPage(
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
          this.pageIndex,
          this.pageSize,
          this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        const extendedData = [...this.dataSource.data];
        extendedData.push(...response.data.content);
        // console.log(`extended, load count ${extendedData.length} of ${this.totalElements}`);
        this.dataSource.data = extendedData;

        this.isScrollLoading = false;
      }).catch(error => {
        this.toast.longer('Error while fetching more data from the server');
        console.error(error);
        this.isScrollLoading = false;
      });
    }
  }

  dateFilterChanged(dateFilter: DateFilter) {
    this.dateFilter = dateFilter;
    this.loadReport();
  }

  vehicleGroupFilterChanged(vehicleGroupFilter: MultiSelectFilter<number>) {
    this.vehicleGroupFilter = vehicleGroupFilter;
    this.loadReport();
  }

  onImageSelectionChanged(image: ImageWithDriverAndVehicle) {
    this.imagePreview = image;
  }

  downloadImage() {
    if (!!this.imagePreview?.imageUrl) {
      this.httpClient.get(
          this.imagePreview.imageUrl, // no need to add access_token because http interceptor adds auth http header
          {responseType: 'blob' as 'json'},
      ).toPromise().then(response => {
        // @ts-ignore
        const blob = new Blob([response], {type: response.type});
        saveAs(blob, `shift_${this.imagePreview.shiftId}__${moment(this.imagePreview.time).format('YYYY-MM-DD_HH-mm')}`);
      }).catch(error => {
        this.toast.longer('Error while fetching data from the server');
        console.error(error);
      });
    }
  }
}
