import {Component, Inject, OnInit} from '@angular/core';
import {DropdownOption, InspectionQuestionEditable} from '../../../../../shared/models/dvir';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dvir-dropdown-provider',
  templateUrl: './dvir-dialogs-dropdown-provider.component.html',
  styleUrls: ['./dvir-dialogs.scss']
})
export class DvirDropdownProviderComponent {

  question: InspectionQuestionEditable;

  constructor(public dialogRef: MatDialogRef<DvirDropdownProviderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DropdownProviderDialogData) {
    this.question = Object.assign({}, data.question);
  }

  dropdownOptions(provider: string, data: string): DropdownOption[] {
    return this.data.dropdowns[provider]?.find(d => d.id === data)?.values;
  }

  updateLabels() {
    if (this.question.dropdownProvider) {
      this.question.dropdownProviderLabel = this.data.dropdownProviders.find(p => p.id === this.question.dropdownProvider)?.label;
    }
    if (this.question.dropdownData) {
      this.question.dropdownDataLabel = this.data.dropdowns[this.question.dropdownProvider]
        ?.find(p => p.id === this.question.dropdownData)?.label;
    }
    if (this.question.defaultValue) {
      this.question.dropdownDefaultValueLabel = this.data.dropdowns[this.question.dropdownProvider]
        ?.find(p => p.id === this.question.dropdownData)?.values?.find(v => v.id === this.question.defaultValue)?.label;
    }
  }
}

@Component({
  selector: 'app-dvir-answer-mapper',
  templateUrl: './dvir-dialogs-answer-mapper.component.html',
  styleUrls: ['./dvir-dialogs.scss']
})
export class DvirAnswerMapperComponent {
  question: InspectionQuestionEditable;

  constructor(public dialogRef: MatDialogRef<DvirAnswerMapperComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AnswerMapperDialogData) {
    this.question = Object.assign({}, data.question);
  }

  updateLabels() {
    this.question.answerMapperLabel =
      this.data.answerProviders?.find(p => p.id === this.question.answerMapper)?.label;
    this.question.answerDataLabel =
      this.data.answerMappings[this.question.answerMapper]?.find(p => p.id === this.question.answerMapperData)?.label;
  }
}


export interface DropdownProviderDialogData {
  question: InspectionQuestionEditable;
  editing: boolean;
  dropdownProviders: DropdownOption[];
  dropdowns: Map<string, DropdownOption[]>;
}

export interface AnswerMapperDialogData {
  question: InspectionQuestionEditable;
  editing: boolean;
  answerProviders: DropdownOption[];
  answerMappings: Map<string, DropdownOption[]>;

}

