import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {CartegraphConfiguration, CartegraphFeature} from '../../../../../shared/models/cartegraph.model';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

@Component({
  selector: 'app-manage-cartegraph-setup-stepper',
  templateUrl: './cartegraph-setup-stepper.component.html',
  styleUrls: ['./cartegraph-setup-stepper.component.scss'],
})
export class CartegraphSetupStepperComponent implements OnInit {

  isLoading = true;
  selectedStepIndex = 4;

  @Input()
  configuration: CartegraphConfiguration;

  @ViewChild(MatStepper) stepper: MatStepper;
  private stepList = ['processing', 'features', 'setup', 'fetch', 'config', 'inspection', 'import'];

  fgFeatures = this.fb.group({});
  fgSetup = this.fb.group({});
  fgConfig = this.fb.group({});
  fgImport = this.fb.group({});
  fgFetch = this.fb.group({});
  fgInspection = this.fb.group({});

  importQuestionsEnabled = false;


  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router
  ) {
  }

  ngOnInit() {
    this.updateFlags();
    this.route.paramMap.subscribe((params: ParamMap) => {
      const stepId = params.get('stepId');
      const index = this.stepList.findIndex(i => i === stepId);
      this.selectedStepIndex = (index >= 0) ? index : this.selectedStepIndex;
    });
    this.isLoading = false;
  }

  configurationChange(event: CartegraphConfiguration) {
    this.configuration = event;
    this.updateFlags();
  }

  onSelectedStep(event: StepperSelectionEvent) {
    this.router.navigate([`settings/cartegraph-settings/config/${this.stepList[event.selectedIndex]}`]);
  }

  updateFlags() {
    this.importQuestionsEnabled = this.configuration?.settings?.features
      ?.find(f => f.feature === CartegraphFeature.IMPORT_INSPECTION_QUESTIONS)?.enabled ?? false;
  }
}

