import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ObservationDialogData} from '../../../../../../shared/models/observation-group';

@Component({
  selector: 'app-update-observation-group-dialog',
  templateUrl: 'update-observation-group-dialog.component.html',
  styleUrls: ['update-observation-group-dialog.component.scss'],
})
export class UpdateObservationGroupDialogComponent {

  defaultMapColor: string;

  constructor(
    public dialogRef: MatDialogRef<UpdateObservationGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObservationDialogData,
  ) {
    this.defaultMapColor = data.defaultMapColor;
  }
}
