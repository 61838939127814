import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ServicesSocketService} from '../websocket/services-socket.service';
import {ImageModel} from '../../shared/models/image';
import {ImageFilterUpdate} from '../../shared/models/images-filter';
import {ImageService} from './image.service';
import {ImageEvent} from '../websocket/model/image.event';
import {ConfigurationModel, FeatureFlagEnum} from '../../shared/models/configuration.model';
import {ToastService} from '../../shared/services/toast.service';
import {ShiftWithDriverAndVehicleModel} from '../../shared/models/shift.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DrawerContent, DrawerType} from '../../layouts/right-drawer/right-drawer.component';

@Injectable({
  providedIn: 'root'
})
export class ImagesManagerService {
  static readonly LIST_ACTION_SOURCE = 'panel';
  static readonly MAP_SOURCE = 'map';
  static readonly URL_SOURCE = 'url';

  private isInitialized = false;

  private readonly imagesMap = new Map<number, ImageModel>();

  private filteredImagesSource = new BehaviorSubject<ImageModel[]>([]);
  public filteredImages$ = this.filteredImagesSource.asObservable();

  highlightedImageId: number = null;
  private highlightedImageSource = new BehaviorSubject<ImageFilterUpdate>(new ImageFilterUpdate(null, null));
  readonly highlightedImage$ = this.highlightedImageSource.asObservable();

  private shiftId: number = null;
  private readonly openSubscriptions = new Array<Subscription>();

  constructor(private imagesService: ImageService,
              private serviceSocketService: ServicesSocketService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toast: ToastService) {
  }

  public init(shift: ShiftWithDriverAndVehicleModel, configuration: ConfigurationModel) {
    if (this.isInitialized) {
      throw Error('The ImagesManagerService has already been initialized.');
    }

    this.shiftId = shift?.id;
    if (this.hasFeatureFlag(configuration, FeatureFlagEnum.ReportImage)) {
      // load images
      if (!!this.shiftId) {
        if (shift.vehicle.cameraConfiguration?.streaming?.captureImageInterval > 0) {
          this.loadShiftImages();
        }
      } else {
        // listen to web socket updates on Live Map
        const imageSubscription = this.serviceSocketService.onMessage('/image')
            .subscribe(((imageEvent: ImageEvent) => {
              if (imageEvent.type === 'new') {
                if (imageEvent.image.shiftId === this.shiftId) {
                  this.addImages([imageEvent.image]);
                } else {
                  console.log('Ignoring new image. Not on selected shift.');
                }
              }
            }));
        this.openSubscriptions.push(imageSubscription);
      }
    }

    this.isInitialized = true;
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    this.imagesMap.clear();
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
    this.openSubscriptions.length = 0;
    this.isInitialized = false;
  }

  hasFeatureFlag(configuration: ConfigurationModel, featureFlag: string): boolean {
    return configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  public filterByShift(shiftId: number) {
    if (this.shiftId !== shiftId) {
      this.shiftId = shiftId;
      if (!!this.shiftId) {
        this.loadShiftImages();
      } else {
        this.imagesMap.clear();
        this.onFilterChanged();
      }
    }
  }

  private loadShiftImages() {
    this.imagesService.getCadenceImagesPerShift(this.shiftId).toPromise().then(response => {
      this.addImages(response.data);
    }).catch(() => {
      this.toast.short('Images sync error.');
    });
  }

  private addImages(images: ImageModel[]) {
    images.forEach(image => {
      this.addImage(image);
    });
    this.onImagesUpdated();
  }

  private addImage(image: ImageModel) {
    if (this.imagesMap.has(image.id)) {
      throw new Error(`An image with id ${image.id} already exists.`);
    }
    this.imagesMap.set(image.id, image);
  }

  private onImagesUpdated() {
    this.onFilterChanged();
  }

  private onFilterChanged() {
    const images = [];
    if (!!this.shiftId) {
      this.imagesMap.forEach(image => {
        if (this.shiftId === image.shiftId) {
          images.push(image);
        }
      });
    }
    this.filteredImagesSource.next(images);
  }

  public highlightImage(imageId: number, source: string) {
    let image: ImageModel;
    if (!!imageId) {
      image = this.imagesMap.get(imageId);
      if (!image) {
        console.log(`Unknown image ID ${imageId}.`);
        console.log('Probably not initialized yet!');
        return;
      }
    }

    if (this.highlightedImageId !== imageId) {
      this.highlightedImageId = imageId;
      this.onHighlightedChanged(image, source);
      if (!!image && source === ImagesManagerService.MAP_SOURCE) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { drawer: DrawerContent.LIVE_VIDEO },
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  private onHighlightedChanged(image: ImageModel, source: string) {
    this.highlightedImageSource.next(
        new ImageFilterUpdate(image, source)
    );
  }
}
