import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {MatTreeModule} from '@angular/material/tree';
import {MatStepperModule} from '@angular/material/stepper';
import {ManageDvirComponent} from './manage-dvir.component';
import {DvirQuestionsComponent} from './dvir-questions/dvir-questions.component';
import {DvirAnswerMapperComponent, DvirDropdownProviderComponent} from './dvir-questions/dvir-dialogs';

@NgModule({
  declarations: [
    ManageDvirComponent,
    DvirQuestionsComponent,
    DvirDropdownProviderComponent,
    DvirAnswerMapperComponent
  ],
  exports: [
    ManageDvirComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    MatTreeModule,
    MatStepperModule,
  ],
})
export class DvirManagementModule {
}
