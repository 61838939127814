import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ShiftReportRecordModel, ShiftReportTotalModel} from '../../../../shared/models/shift.model';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {ShiftReportColumn} from '../shift-report/ShiftReportColumn';
import {InfiniteScroll} from '../../../../shared/tools/InfiniteScroll';
import {InsightsRoute} from '../../insights-routing.module';

@Component({
  selector: 'app-shift-report-table',
  templateUrl: './shift-report-table.component.html',
  styleUrls: ['./shift-report-table.component.scss']
})
export class ShiftReportTableComponent implements OnInit {

  @Input() configuration: ConfigurationModel;
  @ViewChild(MatSort) sort: MatSort;

  @Input() columns: ShiftReportColumn[];
  @Input() dataSource: MatTableDataSource<ShiftReportRecordModel>;
  @Input() reportTotal?: ShiftReportTotalModel | null;

  @Output() sortChangedEvent: EventEmitter<void> = new EventEmitter();
  @Output() scrolledToBottomEvent: EventEmitter<void> = new EventEmitter();

  displayedColumnsWithAction: string[];

  InsightsRoute = InsightsRoute;

  ngOnInit(): void {
    const displayedColumns = this.columns.map(value => value.id);
    this.displayedColumnsWithAction = [...displayedColumns, 'action'];
  }

  isColumnHidden(columnId: string): boolean {
    return this.columns.find(value => !value.displayed && value.id === columnId) != null;
  }

  getSort(): string | undefined {
    if (!this.sort.active) {
      return undefined;
    }
    return `${this.sort.active},${this.sort.direction}`;
  }

  onScroll(e) {
    InfiniteScroll.onScroll(e, () => this.scrolledToBottomEvent.emit());
  }
}
