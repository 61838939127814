import {Injectable} from '@angular/core';
import {SettingsService, TracksLayerFilter, TracksLayerType} from '../../../../configuration/settings.service';
import {VehiclesManagerService} from '../../../../data/vehicles/vehicles-manager.service';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {Subscription} from 'rxjs';
import {ShiftModel, ShiftState} from '../../../models/shift.model';
import {LineLayerSpecification, LngLatBounds} from 'maplibre-gl';
import {MapLayersManager} from '../map-layers-manager';
import {MapStyles, VehicleColor} from '../../../../configuration/map-styles';
import {LiveMapDataService} from '../../../../pages/live-map/services/live-map-data.service';
import {VehicleModelWithActiveShift} from '../../../models/vehicle.model';
import {MapFilters} from '../../../../configuration/map-filters';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {GeobufResponseHandler} from '../../../../geobuf.response.handler';
import {HttpErrorHandler} from '../../../../http.error.handler';
import {FeatureCollection, GeoJSON, LineString} from 'geojson';
import {ServerEventService} from '../../../../data/server-events/server-event.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';
import {AssetsManagerService} from '../../../../data/assets/assets-manager.service';
import {LocationHistoryService} from './location-history.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LiveMapTab} from '../../../../pages/live-map/models/live-map-tabs';

@Injectable({
  providedIn: 'root'
})
export class TracksGeoJsonLayerService {

  static readonly TRACKS_GEOJSON_SOURCE_ID = 'tracks-geojson';
  static readonly LAYER_ID_TRACK_GEOJSON = 'track-geojson';
  static readonly LAYER_ID_TRACK_HISTORY_GEOJSON = 'track-geojson-history';

  private mapLayersManager: MapLayersManager;
  private lineLayers: LineLayerSpecification[] = [];
  private sourceData: FeatureCollection;

  private readonly openSubscriptions = Array<Subscription>();

  selectedVehicleIds = null;
  activeShiftIds = null;
  assetDetailVisibility = false;
  locationHistoryVisibility = false;
  highlightedShift: ShiftModel = null;
  // map layers visibility
  isVisible = true;
  // selected track layer type
  currentTrackLayerType = null;
  // live feed?
  isEnabled: boolean;
  // custom vehicle colors
  customColors: VehicleColor[] = [];
  // flags filter
  private flagsFilter: any[] = [];
  // recent toggle on track bar
  showRecent = false;
  // vector tile cache should not be older than 1 hour
  hours = 12;
  // vector tile cache timestamp
  cacheDate: Date = new Date();

  constructor(private locationHistoryService: LocationHistoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private serverEventService: ServerEventService,
              private settingsService: SettingsService,
              private vehiclesManager: VehiclesManagerService,
              private shiftsManagerService: ShiftsManagerService,
              private assetManager: AssetsManagerService,
              private liveMapDataService: LiveMapDataService,
              private configurationService: ConfigurationService,
              private http: HttpClient
              ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSourceAndLayers();
    this.connectToManager();
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
  }

  updateGeojsonSourceData() {
    this.getLocationHistorySince(this.cacheDate).then(response => {
      this.sourceData = response;
      const source = this.mapLayersManager?.getGeoJsonSource(TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID);
      if (!!source) {
        source.setData(response);
      }
    });
  }

  private handleWebSocketGeoJsonUpdate(geojson: GeoJSON) {
    this.sourceData = geojson as FeatureCollection;
    const source = this.mapLayersManager.getGeoJsonSource(TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID);
    if (!!source) {
      source.setData(geojson);
    }
  }

  private getLocationHistorySince(maxAge: Date) {
    return this.http.get(
        `${environment.services.location}v1/track/head?origin=${maxAge.valueOf() / 1000}`,
        {observe: 'response', responseType: 'arraybuffer', headers: {Accept: 'application/octet-stream'}}
    )
        .pipe(
            map( response => GeobufResponseHandler.handleResponse(response)),
            catchError(HttpErrorHandler.handleError) // then handle the error
        ).toPromise();
  }

  addSourceAndLayers() {
    this.mapLayersManager.addSource(TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    // add layers
    this.currentTrackLayerType = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY);
    this.setVisibility();
    const isVisible = this.isEnabled && this.isVisible ? 'visible' : 'none';
    this.getLineLayers().forEach(layer => {
      layer.layout.visibility = isVisible;
      this.lineLayers.push(layer);
      this.mapLayersManager.addLayer(layer);
    });
  }

  private setVisibility() {
    // GPS tracks layer is visible also for Asset Detail when displaying Currency/Coverage
    switch (this.currentTrackLayerType) {
      case TracksLayerType.GPS_TRACKS:
        this.isVisible = !!this.assetDetailVisibility || this.locationHistoryVisibility;
        break;
      case TracksLayerType.CURRENCY:
      case TracksLayerType.COVERAGE:
        this.isVisible = !!this.assetDetailVisibility;
        break;
      default:
        this.isVisible = !!this.assetDetailVisibility;
    }
  }

  private handleCustomVehicleMapColor(vehicles: VehicleModelWithActiveShift[]) {
    this.customColors = vehicles
        .filter(vehicle => !!vehicle.mapColor)
        .map(vehicle => {
          return {id: vehicle.id, color: vehicle.mapColor};
        });
    this.updateFiltersAndStyles();
  }

  private handleHighlightedShiftChanged(shift: ShiftModel) {
    this.highlightedShift = shift;
    this.updateFiltersAndStyles();
  }

  private handleAssetsChanged(assets: Asset[]) {
    const activeShiftIds = [];
    const vehicleIds = [];
    assets.forEach(asset => {
      if (asset.shiftStatus !== ShiftState.ENDED && !!asset.shiftId) {
        activeShiftIds.push(asset.shiftId);
      }
      vehicleIds.push(asset.id);
    });
    this.activeShiftIds = activeShiftIds;
    this.selectedVehicleIds = vehicleIds;
    this.updateFiltersAndStyles();
  }

  private handleTileCacheChanged(cacheDate: Date) {
    this.cacheDate = cacheDate;
    this.updateGeojsonSourceData();
  }

  private handleLayerVisibilityChange() {
    if (this.isEnabled) {
      this.setVisibility();
      this.lineLayers.forEach(lineLayer => {
        this.mapLayersManager.setLayerVisibility(lineLayer.id, this.isVisible);
      });
    }
  }

  private handleTrackFilterChanged(trackFilter: TracksLayerFilter) {
    this.flagsFilter = MapFilters.getTrackFilter(trackFilter);
    this.updateFiltersAndStyles();
  }

  private handleShowRecentChanged(showRecent: boolean) {
    this.showRecent = showRecent;
    this.updateFiltersAndStyles();
  }

  private connectToManager() {
    const that = this;

    const settingsChangedSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
          that.currentTrackLayerType = newSettings.value;
          that.handleLayerVisibilityChange();
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_LAYER_FILTER) {
          that.handleTrackFilterChanged(newSettings.value);
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_SHOW_RECENT) {
          that.handleShowRecentChanged(newSettings.value);
        }
      }
    });
    this.openSubscriptions.push(settingsChangedSubscription);

    const routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const oldIsAssetDetail = this.assetDetailVisibility;
        this.assetDetailVisibility =
            this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(LiveMapTab.ASSETS + '/:id');

        // if any change
        if (oldIsAssetDetail !== this.assetDetailVisibility) {
          that.handleLayerVisibilityChange();
        }
      }
    });
    this.openSubscriptions.push(routerSubscription);

    // handle visibility based on route
    const locationHistoryVisibilitySubscription = this.locationHistoryService.visibility$.subscribe(visible => {
      this.locationHistoryVisibility = visible;
      this.handleLayerVisibilityChange();
    });
    this.openSubscriptions.push(locationHistoryVisibilitySubscription);

    const allVehiclesSubscription = this.liveMapDataService.vehicles$.subscribe(vehicles => {
      this.handleCustomVehicleMapColor(vehicles);
    });
    this.openSubscriptions.push(allVehiclesSubscription);

    const assetsSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleAssetsChanged(assets);
    });
    this.openSubscriptions.push(assetsSubscription);

    const highlightingUpdateSubscription = this.shiftsManagerService.highlightedShift$.subscribe({
      next(shiftFilterUpdate) {
        that.handleHighlightedShiftChanged(shiftFilterUpdate.shift);
      }
    });
    this.openSubscriptions.push(highlightingUpdateSubscription);

    const tileCacheChangeSubscription = this.serverEventService.tilesCacheUpdateObservable.subscribe({
      next(cacheUpdate: Date) {
        that.handleTileCacheChanged(cacheUpdate);
      }
    });
    this.openSubscriptions.push(tileCacheChangeSubscription);

    const initTileCacheChangeSubscription = this.serverEventService.initialTilesCacheUpdateObservable.subscribe({
      next(cacheUpdate: Date) {
        that.handleTileCacheChanged(cacheUpdate);
      }
    });
    this.openSubscriptions.push(initTileCacheChangeSubscription);

    const cacheChangeSubscription = this.serverEventService.historyLocationsObservable.subscribe({
      next(geojson: GeoJSON) {
        that.handleWebSocketGeoJsonUpdate(geojson);
      }
    });
    this.openSubscriptions.push(cacheChangeSubscription);
  }

  private getTrackHistoryLayer(): LineLayerSpecification {
    const highlightedShift = this.highlightedShift;
    const layer = {
      type: 'line',
      filter: MapFilters.getInactiveShiftsFilter(
        this.activeShiftIds,
        this.selectedVehicleIds,
        this.hours,
        this.flagsFilter,
        highlightedShift?.id,
        this.showRecent,
      ),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': MapStyles.getHighlightedColor(
            highlightedShift ? [highlightedShift.id] : [],
            this.configurationService.trackStyles.liveMapPlowHighlighted.color,
            this.configurationService.trackStyles.liveMapPlowInactive.color,
            this.customColors,
        ),
            'line-opacity': this.configurationService.trackStyles.liveMapPlowInactive.opacity,
            'line-width': MapStyles.getHighlightedProperty(
            highlightedShift ? [highlightedShift.id] : [],
            this.configurationService.trackStyles.liveMapPlowHighlighted.width,
            this.configurationService.trackStyles.liveMapPlowInactive.width,
        ),
      }
    } as LineLayerSpecification;
    layer.id = TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON;
    layer.source = TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID;
    return layer;
  }

  private getActiveTrackLayer(): LineLayerSpecification {
    const highlightedShift = this.highlightedShift;
    const layer = {
      type: 'line',
      filter: MapFilters.getActiveShiftsFilter(
          this.activeShiftIds,
          this.selectedVehicleIds,
          this.hours,
          this.flagsFilter,
          highlightedShift?.id,
      ),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': MapStyles.getHighlightedColor(
            highlightedShift ? [highlightedShift.id] : [],
            this.configurationService.trackStyles.liveMapPlowHighlighted.color,
            this.configurationService.trackStyles.liveMapPlowLive.color,
            this.customColors,
        ),
        'line-opacity': this.configurationService.trackStyles.liveMapPlowLive.opacity,
        'line-width': MapStyles.getHighlightedProperty(
            highlightedShift ? [highlightedShift.id] : [],
            this.configurationService.trackStyles.liveMapPlowHighlighted.width,
            this.configurationService.trackStyles.liveMapPlowLive.width,
        ),
      }
    } as LineLayerSpecification;
    layer.id = TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON;
    layer.source = TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID;
    return layer;
  }

  getLineLayers(): LineLayerSpecification[] {
    return [
      this.getTrackHistoryLayer(),
      this.getActiveTrackLayer(),
    ];
  }

  updateFiltersAndStyles() {
    const highlightedShift = this.highlightedShift;
    this.lineLayers.forEach(lineLayer => {
      switch (lineLayer.id) {
        case TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON:
          this.mapLayersManager.setFilter(lineLayer.id, MapFilters.getInactiveShiftsFilter(
              this.activeShiftIds,
              this.selectedVehicleIds,
              this.hours,
              this.flagsFilter,
              highlightedShift?.id,
              this.showRecent,
          ));
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-color',
              MapStyles.getHighlightedProperty(
                  highlightedShift ? [highlightedShift.id] : [],
                  this.configurationService.trackStyles.liveMapPlowHighlighted.color,
                  this.configurationService.trackStyles.liveMapPlowInactive.color,
              )
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              MapStyles.getHighlightedProperty(
                  highlightedShift ? [highlightedShift.id] : [],
                  this.configurationService.trackStyles.liveMapPlowHighlighted.width,
                  this.configurationService.trackStyles.liveMapPlowInactive.width,
              )
          );
          break;
        case TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON:
          this.mapLayersManager.setFilter(lineLayer.id, MapFilters.getActiveShiftsFilter(
              this.activeShiftIds,
              this.selectedVehicleIds,
              this.hours,
              this.flagsFilter,
              highlightedShift?.id,
          ));
          this.mapLayersManager.setLayerPaintProperty(
            lineLayer.id,
            'line-color',
            MapStyles.getHighlightedColor(
                highlightedShift ? [highlightedShift.id] : [],
              this.configurationService.trackStyles.liveMapPlowHighlighted.color,
              this.configurationService.trackStyles.liveMapPlowLive.color,
              this.customColors,
            ),
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              MapStyles.getHighlightedProperty(
                  highlightedShift ? [highlightedShift.id] : [],
                  this.configurationService.trackStyles.liveMapPlowHighlighted.width,
                  this.configurationService.trackStyles.liveMapPlowLive.width,
              )
          );
          break;
        default:
          console.warn('This should not happen! The map layer doesn\'t exist');
      }
    });
  }

  public getBounds(shiftId: number) {
    const features = this.sourceData?.features?.filter(feature => feature.properties['sessionkey'] === shiftId);
    if (!!features && features.length > 0) {
      const coordinatesByFeature = features.map(feature => (feature.geometry as LineString).coordinates);
      // flatten the array Position[][] to Position[]
      const coordinates = coordinatesByFeature.reduce((accumulator, value) => accumulator.concat(value), []);
      if (!!coordinates && coordinates.length > 0) {
        return coordinates.reduce((bounds, position) => {
          return bounds.extend([position[0], position[1]]);
        }, new LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[0][0], coordinates[0][1]]));
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
