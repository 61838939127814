import {Injectable} from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {CircleLayerSpecification, GeoJSONSourceSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {ShiftWithDriverAndVehicleModel} from '../../../models/shift.model';
import {environment} from '../../../../../environments/environment';
import {FeatureCollection} from 'geojson';
import {ShiftsService} from '../../../../data/shifts/shifts.service';
import {MapStyles} from '../../../../configuration/map-styles';

@Injectable({
  providedIn: 'root'
})
export class ShiftMarkerLayerService {

  static readonly SHIFT_MARKER_SOURCE_ID = 'shift-marker-source';
  static readonly LAYER_ID_WHITE_CIRCLE = 'shift_white_circle';
  static readonly LAYER_ID_SHADOW = 'shift_shadow';
  static readonly LAYER_ID_ICON = 'shift_marker_icon';
  static readonly ICON_FLAG = 'flag';

  private mapLayersManager: MapLayersManager;
  shift: ShiftWithDriverAndVehicleModel = null;
  featureCollection: FeatureCollection;

  constructor(
    private shiftService: ShiftsService,
  ) { }

  init(shift: ShiftWithDriverAndVehicleModel, mapLayersManager: MapLayersManager) {
    this.shift = shift;
    if (!!this.mapLayersManager) {
      throw Error('The mapLayersManager has already been set yet.');
    }
    this.mapLayersManager = mapLayersManager;

    // source: https://fonts.google.com/icons?selected=Material+Icons:menu
    this.mapLayersManager.loadImage(
        ShiftMarkerLayerService.ICON_FLAG,
        `${environment.base_href}assets/baseline_flag_black_24dp.png`,
        { sdf: true }
    );

    this.featureCollection = this.getEmptyFeatureCollection();
    this.initializeSourceAndLayers();
    if (!!shift) {
      this.shiftService.getShiftStatistics(shift.id).toPromise().then(response => {
        if (!!response.data && response.data.length > 0) {
          const shiftStats = response.data[0];
          if (!!shiftStats?.start?.coordinates?.longitude && !!shiftStats?.start?.coordinates?.latitude) {
            this.featureCollection.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [shiftStats.start.coordinates.longitude, shiftStats.start.coordinates.latitude]
              },
              properties: {
                start: true
              }
            });
          }
          if (!!shiftStats?.end?.coordinates?.longitude && !!shiftStats?.end?.coordinates?.latitude) {
            this.featureCollection.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [shiftStats.end.coordinates.longitude, shiftStats.end.coordinates.latitude]
              },
              properties: {
                start: false
              }
            });
          }
          this.updateSource();
        }
      }).catch(error => {
        console.error(error);
      });
    }
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The mapLayersManager has not been set yet.');
    }
    this.mapLayersManager = null;
    this.featureCollection = this.getEmptyFeatureCollection();
  }

  initializeSourceAndLayers() {
    this.mapLayersManager.addSource(ShiftMarkerLayerService.SHIFT_MARKER_SOURCE_ID, this.getSource());
    this.mapLayersManager.addLayer(this.createShadowLayer());
    this.mapLayersManager.addLayer(this.createWhiteCircleLayer());
    this.mapLayersManager.addLayer(this.createFlagLayer());
  }

  private createWhiteCircleLayer(): CircleLayerSpecification {
    return {
      id: ShiftMarkerLayerService.LAYER_ID_WHITE_CIRCLE,
      type: 'circle',
      source: ShiftMarkerLayerService.SHIFT_MARKER_SOURCE_ID,
      paint: {
        'circle-color': '#FEFEFE',
        'circle-radius': 15,
        'circle-opacity': 1.0
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

  private createShadowLayer(): CircleLayerSpecification {
    return {
      id: ShiftMarkerLayerService.LAYER_ID_SHADOW,
      type: 'circle',
      source: ShiftMarkerLayerService.SHIFT_MARKER_SOURCE_ID,
      paint: {
        'circle-radius': 18,
        'circle-color': '#000',
        'circle-blur': 0.75,
        'circle-translate': [2, 2],
      },
    } as CircleLayerSpecification;
  }

  private createFlagLayer(): SymbolLayerSpecification {
    return {
      id: ShiftMarkerLayerService.LAYER_ID_ICON,
      type: 'symbol',
      source: ShiftMarkerLayerService.SHIFT_MARKER_SOURCE_ID,
      layout: {
        'icon-image': ShiftMarkerLayerService.ICON_FLAG,
        'icon-allow-overlap': true,
        'symbol-sort-key': [
          'case',
          ['boolean', ['get', 'start']],
          2,
          1,
        ],
      },
      paint: {
        'icon-color': [
          'case',
          ['boolean', ['get', 'start']],
          MapStyles.SHIFT_START_COLOR,
          MapStyles.SHIFT_END_COLOR,
        ],
        'icon-halo-width': 1,
        'icon-halo-color': [
          'case',
          ['boolean', ['get', 'start']],
          MapStyles.SHIFT_START_COLOR,
          MapStyles.SHIFT_END_COLOR,
        ],
      },
    } as SymbolLayerSpecification;
  }

  private getSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.featureCollection
    } as GeoJSONSourceSpecification;
  }

  private updateSource() {
    this.mapLayersManager.getGeoJsonSource(ShiftMarkerLayerService.SHIFT_MARKER_SOURCE_ID)
        ?.setData(this.featureCollection as any);
  }

  private getEmptyFeatureCollection(): FeatureCollection {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }
}
