import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, ReplaySubject, Subject, Subscription} from 'rxjs';
import {FeatureCollection, GeoJSON, Point} from 'geojson';
import {SourceDataEvent} from '../websocket/model/source-data-event.class';
import {MapCacheEvent} from '../websocket/model/map-cache-event.class';
import {ServicesSocketService} from '../websocket/services-socket.service';
import {LocationSocketService} from '../websocket/location-socket.service';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {PresentationCacheMetadata} from '../../shared/models/PresentationCacheMetadata';
import {catchError, map, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {environment} from '../../../environments/environment';
import {VehicleLocationUpdate} from '../../shared/models/vehicle-breadcrumb';
import {LatLngModel} from '../../shared/models/lat.lng.model';
import {VehiclesManagerService} from '../vehicles/vehicles-manager.service';
import {GeobufResponseHandler} from '../../geobuf.response.handler';

@Injectable({
    providedIn: 'root'
})
export class ServerEventService {

    private isInitialized = false;
    private readonly openSubscriptions = Array<Subscription>();

    readonly currentLocationsObservable = new BehaviorSubject<GeoJSON>(JSON.parse('{"type": "FeatureCollection", "features": []}'));
    readonly historyLocationsObservable = new Subject<GeoJSON>();

    // handle derived layers source updated
    readonly derivedLayerCacheUpdateObservable = new Subject<Date>();
    // handle tile cache updated
    readonly tilesCacheUpdateObservable = new Subject<Date>();
    readonly initialTilesCacheUpdateObservable = new ReplaySubject<Date>();

    constructor(private http: HttpClient,
                private serviceSocketService: ServicesSocketService,
                private locationSocketService: LocationSocketService,
                private vehiclesManager: VehiclesManagerService,
    ) { }

    public init() {
        if (this.isInitialized) {
            throw Error('The BreadcrumbsManagerService has already been initialized.');
        }
        this.connectToManager();

        this.isInitialized = true;
    }

    public release() {
        if (!this.isInitialized) {
            return;
        }

        for (const subscription of this.openSubscriptions) {
            subscription.unsubscribe();
        }
        this.openSubscriptions.length = 0;
        this.isInitialized = false;
    }

    private connectToManager() {
        // subscribe to backend location changes push
        const locationHistorySubscription = this.serviceSocketService
            .onMessage('/locationHistory')
            .subscribe((e: SourceDataEvent) => {
                this.handleLocationHistoryUpdate(e.geoJson);
            });
        this.openSubscriptions.push(locationHistorySubscription);

        // subscribe to backend current locations push
        const currentLocationSubscription = this.serviceSocketService
            .onMessage('/currentLocation')
            .subscribe((e: SourceDataEvent) => {
                this.handleCurrentLocationUpdate(e.geoJson);
            });
        this.openSubscriptions.push(currentLocationSubscription);

        // do not wait for first update, initialize currentLocation
        this.getCurrentLocations().then(response => {
            this.handleCurrentLocationUpdate(response);
        }).catch(message => {
            console.log(message);
        });

        const that = this;
        // subscribe to geometry cache updates
        const derivedLayerCacheEventSubscription = this.locationSocketService
            .onMessage('/derivedLayerCache')
            .subscribe((e: MapCacheEvent) => {
                const date = new Date(e.date);
                that.handleDerivedLayerCacheUpdate(date);
                console.log(`Derived layer data updated: ${date}`);
            });
        this.openSubscriptions.push(derivedLayerCacheEventSubscription);

        // subscribe to tile cache refresh
        const tileCacheEventSubscription = this.locationSocketService
            .onMessage('/tileCache')
            .subscribe((e: MapCacheEvent) => {
                const date = new Date(e.date);
                that.handleTilesCacheUpdate(date);
                console.log(`Tile cache updated: ${date}`);
            });
        this.openSubscriptions.push(tileCacheEventSubscription);

        this.getCacheLastUpdate().then(lastUpdateDate => {
            this.initialTilesCacheUpdateObservable.next(lastUpdateDate);
        });
    }

    private handleLocationHistoryUpdate(geojson: GeoJSON) {
        this.historyLocationsObservable.next(geojson);
    }

    public handleCurrentLocationUpdate(geojson: GeoJSON) {
        // update observable - map markers
        this.currentLocationsObservable.next(geojson);

        // update location in vehicle manager - used to display in Asset detail
        const features = (geojson as FeatureCollection).features;
        const vehicleLocations = features.map(feature => {
            const prop = feature.properties;
            const coord = (feature.geometry as Point).coordinates;
            return new VehicleLocationUpdate(
                feature.properties.locationsourceid,
                {
                    id: prop.id,
                    coords: new LatLngModel(coord[1], coord[0]),
                    time: prop.unixtime,
                    speed: prop.speed,
                    heading: prop.heading,
                    flags: prop.flags,
                    gpsSource: prop.gpssource,
                }
            );
        });
        this.vehiclesManager.updateVehicleLocation(vehicleLocations);
    }

    private handleDerivedLayerCacheUpdate(date: Date) {
        this.derivedLayerCacheUpdateObservable.next(date);
    }

    private handleTilesCacheUpdate(date: Date) {
        this.tilesCacheUpdateObservable.next(date);
    }

    private getCacheLastUpdate(): Promise<Date> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const url = `${environment.services.location}v1/state`;

        return this.http.get<JsonApiResponse<PresentationCacheMetadata>>(url)
            .pipe(
                map(received => received.data && new Date(received.data.trackHeadOrigin * 1000)),
                retry(3),
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    private getCurrentLocations() {
        return this.http.get(
            `${environment.services.location}v1/location/current`,
            {observe: 'response', responseType: 'arraybuffer', headers: {Accept: 'application/octet-stream'}}
        )
            .pipe(
                map( response => GeobufResponseHandler.handleResponse(response)),
                catchError(HttpErrorHandler.handleError) // then handle the error
            ).toPromise();
    }
}
