<div class="basic-page-container">
  <div
    class="left__content"
    [ngClass]="{ collapse: collapseMenu }"
  >
    <app-sidemenu
            *ngIf="configuration"
            (toggleSideMenu)="toggleSideMenu()"
            [collapseMenu]="collapseMenu"
            [listData]="insightsSections"
            class="left__panel">
    </app-sidemenu>
  </div>

  <div class="page__container">
    <div class="visible__page__container"
         [@inOutAnimation]
         [ngClass]="{ collapse: collapseMenu }"
    >
      <div class="round__container">

        <div class="wide__page__container"
             *ngIf="currentRoute; else noSelect"
             [ngSwitch]="currentRoute">
          <router-outlet></router-outlet>
        </div>

        <ng-template #noSelect>
          <app-blank-page
                  class="centered__page__container"
                  headline="No Insight Selected"
                  text="Select an insight report from the list on the left to retrieve information about your operations."
          >
          </app-blank-page>
        </ng-template>

      </div>
    </div>
  </div>

</div>
