import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CreateObservationGroup, EditObservationGroup, ObservationTypeGroup, ObservationGroupDto } from '../../shared/models/observation-group';
import { environment } from '../../../environments/environment';
import { HttpErrorHandler } from '../../http.error.handler';
import { catchError } from 'rxjs/operators';
import { ObservationTypeDto, ObservationType } from '../../shared/models/observation-type';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';

@Injectable({
  providedIn: 'root'
})
export class ObservationManagementService {

  constructor(private http: HttpClient) {
  }

  getObservationTypeGroups() {
    return this.http.get<JsonApiResponse<ObservationTypeGroup[]>>(`${environment.services.service}v1/observation-type-group/list`)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      );
  }

  editObservationTypeGroup(editObservationGroup: EditObservationGroup, observationGroupId: number): Promise<JsonApiResponse<ObservationTypeGroup>> {
    const requestUrl = `${environment.services.service}v1/observation-type-group/${observationGroupId}`;
    const data = this.toObservationTypeGroupData(editObservationGroup);

    return this.http.patch<JsonApiResponse<ObservationTypeGroup>>(requestUrl, data)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  createObservationTypeGroup(createObservationGroup: CreateObservationGroup): Promise<JsonApiResponse<ObservationTypeGroup>> {
    const requestUrl = `${environment.services.service}v1/observation-type-group`;
    const data = this.toObservationTypeGroupData(createObservationGroup);

    return this.http.post<JsonApiResponse<ObservationTypeGroup>>(requestUrl, data)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  deleteObservationTypeGroup(id: number): Promise<void> {
    const requestUrl = `${environment.services.service}v1/observation-type-group/${id}`;

    return this.http.delete<void>(requestUrl)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  editObservationType(groupId: number, id: number, observationTypeDto: ObservationTypeDto): Promise<JsonApiResponse<ObservationType>> {
    const requestUrl = `${environment.services.service}v1/observation-type-group/${groupId}/type/${id}`;

    return this.http.patch<JsonApiResponse<ObservationType>>(requestUrl, observationTypeDto)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  createObservationType(groupId: number, observationTypeDto: ObservationTypeDto): Promise<JsonApiResponse<ObservationType>> {
    const requestUrl = `${environment.services.service}v1/observation-type-group/${groupId}/type`;

    return this.http.post<JsonApiResponse<ObservationType>>(requestUrl, observationTypeDto)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  deleteObservationType(groupId: number, id: number): Promise<void> {
    const requestUrl = `${environment.services.service}v1/observation-type-group/${groupId}/type/${id}`;

    return this.http.delete<void>(requestUrl)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  private toObservationTypeGroupData(inputData: CreateObservationGroup | EditObservationGroup): ObservationGroupDto {
    return {
      name: inputData.name,
      durationInSeconds: inputData.duration * 60 * 60,
      isAudible: inputData.isAudible,
      mapColor: inputData.mapColor,
    };
  }
}
