import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mowerStatus'
})
export class MowerStatusPipe implements PipeTransform {

  transform(bitNumber: number, flags: number): string {
    if (flags !== undefined && flags !== null && typeof bitNumber === 'number') {
      // tslint:disable-next-line:no-bitwise
      return (flags & Math.pow(2, bitNumber)) === 0 ? 'Not Mowing' : 'Mowing';
    } else {
      // null or undefined
      return 'N/A';
    }
  }
}
