import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'passFail'
})
export class PassFailPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value === 'Yes' ? 'Pass' : 'Fail';
    } else {
      return 'Fail';
    }
  }

}
