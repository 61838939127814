import { v4 as uuid } from 'uuid';

export class UId {
    id: string;

    constructor(id: string) {
        this.id = id;
    }

    static createNew(): UId {
        return new UId(uuid());
    }

    static createNewUuid(): string {
        return uuid();
    }

    isSameAs(other: UId): boolean {
        return this.id === other.id;
    }
}
