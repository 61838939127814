import {Component, Inject} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SecurityService} from '../../../../security/security.service';
import {environment} from '../../../../../environments/environment';

@Component({
  templateUrl: 'dialogs/shift-map-inspection-dialog.html',
  styles: [
    '.iframe { border: 0; width: 1024px; height: 800px; }',
    '.frame { overflow: clip; }',
  ]
})
export class ShiftMapInspectionDialogComponent {

  sanitizedUrl: SafeResourceUrl;
  private token: string;

  constructor(
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ShiftMapInspectionDialogComponent>,
    private securityService: SecurityService,
    @Inject(MAT_DIALOG_DATA) public data: { shiftId: string, preTrip: boolean }) {
    this.securityService.getToken().then(accessToken => {
      this.token = accessToken;
      const url = `${environment.services.service}v1/report/inspection/${this.data.shiftId}/html?pre-trip=${this.data.preTrip}&access_token=${this.token}`;
      this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  // buttonClickedOk(): void {
  //   this.dialogRef.close(this.data);
  // }
}
