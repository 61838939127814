import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FilterCompositeComponent} from './filter-composite.component';
import {MatSelectChange} from '@angular/material/select';


@Component({
  selector: 'app-dropdown-composite',
  templateUrl: './dropdown-composite.html',
  providers: [
    {
      provide: FilterCompositeComponent,
      useExisting: forwardRef(() => DropdownCompositeComponent)
    }
  ]
})
export class DropdownCompositeComponent extends FilterCompositeComponent implements OnInit {

  @Input()
  values: DropdownCompositeValue[] = [];

  @Input()
  multiple = false;

  selectedValues: DropdownCompositeValue[] = [];

  constructor() {
    super();
  }

  override criteria(): string {
    if (this.selectedValues.length > 0) {
      return this.selectedValues.map(i => i.id).join(',');
    } else {
      return null;
    }
  }

  override clear() {
    this.selectedValues = this.values; // default to all
  }

  ngOnInit(): void {
    this.selectedValues = this.values; // default to all
  }

  onSelectionChange(changeEvent: MatSelectChange) {
  }
}

export class DropdownCompositeValue {
  id: string;
  label: string;
}
