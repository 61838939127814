import {Component, Input, OnInit} from '@angular/core';
import {CartegraphInspectionSettings, CartegraphSettings} from '../../../../../../shared/models/cartegraph.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-cartegraph-settings-inspection-form',
  templateUrl: './cartegraph-settings-inspection-form.component.html',
  styleUrls: ['./cartegraph-settings.scss'],
})
export class CartegraphSettingsInspectionFormComponent implements OnInit {

  @Input()
  settings: CartegraphSettings;
  inspectionSettings: CartegraphInspectionSettings;

  isLoading = true;
  uiError: string;
  updateForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.inspectionSettings = this.settings?.import?.inspection ?? new CartegraphInspectionSettings();
    this.initForm();
  }

  initForm() {
    this.updateForm = this.fb.group({
      inspectionFormSection: new UntypedFormControl(this.inspectionSettings.inspectionFormSection),
      inspectionOdometerField: new UntypedFormControl(this.inspectionSettings.inspectionOdometerField)
    });
    this.updateForm.valueChanges.subscribe(() => {
      // on any chaged update ...
    });
    this.updateForm.controls['inspectionFormSection'].valueChanges.subscribe(value => {
      this.inspectionSettings.inspectionFormSection = value;
    });
    this.updateForm.controls['inspectionOdometerField'].valueChanges.subscribe(value => {
      this.inspectionSettings.inspectionOdometerField = value;
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

}
