<div class="my-25">
    <mat-card appearance="outlined">

        <mat-card-header>
            <mat-card-title>Cartegraph management</mat-card-title>
        </mat-card-header>

        <mat-card-content class="mt-25">
            <mat-tab-group color="accent"
                           [(selectedIndex)]="selectedTabIndex"
                           (selectedTabChange)="onSelectedTab($event)"
                           *ngIf="!isLoading && configuration">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">settings</mat-icon>
                        Setup and configuration
                    </ng-template>
                    <ng-template matTabContent>
                        <app-manage-cartegraph-setup-stepper
                                [configuration]="configuration"></app-manage-cartegraph-setup-stepper>
                    </ng-template>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">publish</mat-icon>
                        Submissions
                    </ng-template>

                    <ng-template matTabContent>
                        <app-manage-cartegraph-tasks [configuration]="configuration"></app-manage-cartegraph-tasks>
                    </ng-template>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">download</mat-icon>
                        Imports
                    </ng-template>

                    <ng-template matTabContent>
                        <app-manage-cartegraph-tasks-import
                                [configuration]="configuration"></app-manage-cartegraph-tasks-import>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>

        </mat-card-content>
    </mat-card>
</div>