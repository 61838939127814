<h1 mat-dialog-title>Edit Location</h1>

<mat-dialog-content>
  <div class="map-container">
    <div #map class="map"></div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="reset()">Reset to default</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Update</button>
</mat-dialog-actions>
