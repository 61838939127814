import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ShiftWithDriverAndVehicleModel} from '../../../shared/models/shift.model';

@Injectable({
  providedIn: 'root'
})
export class ShiftMapDataService {

  constructor() { }

  private shiftSource = new BehaviorSubject<ShiftWithDriverAndVehicleModel>(null);
  shift$ = this.shiftSource.asObservable();

  sendShift(data: ShiftWithDriverAndVehicleModel) {
    this.shiftSource.next(data);
  }
}
