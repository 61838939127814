
<div class="setup-page-container">

    <div class="header-container">
        <div class="portal-url-section">
            <span>Your public portal:</span>
            <a class="ops-link" [href]="publicMapUrl" target="_new">{{publicMapUrl}}</a>
            <button mat-icon-button
                    color="accent"
                    class="smaller"
                    [matTooltip]="'Copy to clipboard'"
                    [cdkCopyToClipboard]="publicMapUrl">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>

    <div class="content-container">
        <div class="ops-form form-container">

            <!-- Page Settings-->
            <ng-container *ngIf="settings">

                <mat-form-field appearance="outline" color="accent" class="wide no-hint base-map">
                    <mat-label>Base Map</mat-label>
                    <mat-select
                            [(ngModel)]="settings.baseMapLayer"
                            (ngModelChange)="updatePublicPortalSettings()">
                        <mat-option [value]="BaseMapType.OUTDOORS">{{ BaseMapType.OUTDOORS }}</mat-option>
                        <mat-option [value]="BaseMapType.LIGHT">{{ BaseMapType.LIGHT }}</mat-option>
                        <mat-option [value]="BaseMapType.DARK">{{ BaseMapType.DARK }}</mat-option>
                        <mat-option [value]="BaseMapType.IMAGERY">{{ BaseMapType.IMAGERY }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-checkbox
                        [(ngModel)]="showTitleBar"
                        (change)="updatePublicPortalSettings()"
                >
                    Title Bar
                </mat-checkbox>

                <div class="banner-section wide indent" *ngIf="!settings.hideHeader">

                    <div class="field-with-right-buttons wide">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>Page Title</mat-label>
                            <input matInput
                                   autocomplete="off"
                                   type=text
                                   [(ngModel)]="currentPageTitle"
                                   (ngModelChange)="currentEditing.changed('title')"
                            >
                            <mat-hint>Title in bold text at the top of the page. 40 characters max.</mat-hint>
                        </mat-form-field>
                        <div>
                            <button mat-icon-button
                                    color="accent"
                                    (colorPickerChange)="titleColorChanged($event)"
                                    [(colorPicker)]="settings.titleColor"
                                    [cpAlphaChannel]="'disabled'"
                                    [cpFallbackColor]="'#0b568a'"
                            >
                                <mat-icon>format_color_text</mat-icon>
                            </button>
                            <button mat-icon-button
                                    color="accent"
                                    (colorPickerChange)="titleBackgroundColorChanged($event)"
                                    [(colorPicker)]="settings.titleBackgroundColor"
                                    [cpAlphaChannel]="'disabled'"
                                    [cpFallbackColor]="'#FFFFFF'"
                            >
                                <mat-icon>format_color_fill</mat-icon>
                            </button>
                            <ng-container *ngIf="currentEditing.isDirty('title')">
                                <button mat-icon-button
                                        color="accent"
                                        (click)="saveTitle()"
                                        [matTooltip]="'Save'"
                                >
                                    <mat-icon>save</mat-icon>
                                </button>
                                <button mat-icon-button
                                        color="accent"
                                        (click)="cancelTitle()"
                                        [matTooltip]="'Cancel'"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <div class="logo-section wide">
                        <div class="section-header">
                            <div class="section-title">Logo</div>
                            <div class="section-buttons">
                                <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="fileInputChange($event)" accept="image/png,image/jpeg"/>
                                <button mat-icon-button
                                        color="accent"
                                        (click)="fileInput.click()"
                                >
                                    <mat-icon>upload</mat-icon>
                                </button>
                                <button mat-icon-button
                                        color="accent"
                                        *ngIf="currentEditing.isDirty('logo')"
                                        (click)="saveLogo()"
                                        [matTooltip]="'Update Logo Image'"
                                >
                                    <mat-icon>save</mat-icon>
                                </button>
                                <button mat-icon-button
                                        color="accent"
                                        *ngIf="currentEditing.isDirty('logo')"
                                        (click)="cancelLogo()"
                                        [matTooltip]="'Cancel'"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                        <img *ngIf="currentLogo?.length > 0"
                             [src]="getBase64Image()"
                             class="logo"
                             alt="Logo"
                        >
                    </div>

                    <div class="field-with-right-buttons wide">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>Image URL</mat-label>
                            <input matInput
                                   autocomplete="off"
                                   type=text
                                   [(ngModel)]="currentImageUrl"
                                   (ngModelChange)="currentEditing.changed('image-url')"
                            >
                            <mat-hint>Users who click on your logo will be taken to this URL</mat-hint>
                        </mat-form-field>
                        <div *ngIf="currentEditing.isDirty('image-url')">
                            <button mat-icon-button
                                    color="accent"
                                    (click)="saveImageUrl()"
                                    [matTooltip]="'Save'"
                            >
                                <mat-icon>save</mat-icon>
                            </button>
                            <button mat-icon-button
                                    color="accent"
                                    (click)="cancelImageUrl()"
                                    [matTooltip]="'Cancel'"
                            >
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>

                <mat-checkbox
                        [(ngModel)]="showMessageBar"
                        (change)="updatePublicPortalSettings()"
                >
                    Message Bar
                </mat-checkbox>

                <div class="banner-section wide indent" *ngIf="!settings.hideMessageBar">
                    <div class="field-with-right-buttons wide">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>Message Text</mat-label>
                            <input matInput
                                   autocomplete="off"
                                   type=text
                                   [(ngModel)]="newHeadline.text"
                                   (ngModelChange)="currentEditing.changed('headline')"
                            >
                            <mat-hint>This message appears in the blue bar below the page title.</mat-hint>
                        </mat-form-field>
                        <div *ngIf="currentEditing.isDirty('headline')">
                            <button mat-icon-button
                                    color="accent"
                                    [(colorPicker)]="newHeadline.color"
                                    [cpAlphaChannel]="'disabled'"
                                    [cpFallbackColor]="'#FFFFFF'"
                            >
                                <mat-icon>format_color_text</mat-icon>
                            </button>
                            <button mat-icon-button
                                    color="accent"
                                    [(colorPicker)]="newHeadline.backgroundColor"
                                    [cpAlphaChannel]="'disabled'"
                                    [cpFallbackColor]="'#0b568a'"
                            >
                                <mat-icon>format_color_fill</mat-icon>
                            </button>
                            <button mat-icon-button
                                    color="accent"
                                    (click)="saveHeadline()"
                                    [matTooltip]="'Create new headline'"
                            >
                                <mat-icon>add</mat-icon>
                            </button>
                            <button mat-icon-button
                                    color="accent"
                                    (click)="cancelHeadline()"
                                    [matTooltip]="'Cancel'"
                            >
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                    </div>

                    <mat-radio-group class="headline-radio-buttons indent">
                        <ng-container *ngFor="let headline of headlines; let i = index">
                            <app-headline [headline]="headline"
                                          [index]="i"
                                          (headlineDeleted)="deleteHeadline($event)"
                                          (headlineUpdated)="updateHeadline($event.index, $event.headline)"
                                          (headlineSelected)="selectedHeadlineChanged($event)"
                                          class="w-100"
                            ></app-headline>
                        </ng-container>
                    </mat-radio-group>

                    <mat-checkbox
                            [(ngModel)]="settings.forceScrollingHeadline"
                            (change)="updatePublicPortalSettings()"
                    >
                        Message Always Scrolling
                    </mat-checkbox>

                    <div class="headline-speed-slider indent" *ngIf="settings.forceScrollingHeadline">
                        <div>Slow</div>
                        <mat-slider
                                class="scrolling-speed-slider"
                                [max]="3"
                                [min]="1"
                                [step]="1"
                                #ngSlider
                                color="accent"
                        >
                            <input matSliderThumb [(value)]="settings.scrollingSpeed" (change)="updatePublicPortalSettings()" #ngSliderThumb="matSliderThumb" />
                        </mat-slider>
                        <div>Fast</div>
                    </div>
                </div>

            </ng-container>

            <!-- Map Settings-->
            <ng-container *ngIf="!!settings">

                <mat-checkbox
                        [(ngModel)]="settings.showVehicleMarkers"
                        (change)="updatePublicPortalSettings()"
                >
                    Show Vehicle Locations
                </mat-checkbox>

                <div class="vehicle-location-section indent" *ngIf="settings.showVehicleMarkers || settings.tracksLayer === 'Gps'">
                    <ng-container *ngFor="let group of vehicleGroups">
                        <div>
                            <mat-checkbox
                                    [(ngModel)]="group.selected"
                                    (change)="updateExcludedVehicleGroups()"
                            >
                                {{ group.name }}
                            </mat-checkbox>
                        </div>
                    </ng-container>
                </div>

                <mat-checkbox
                        [(ngModel)]="showVehicleHistory"
                        (change)="toggleVehicleHistory()"
                >
                    Show Vehicle History
                </mat-checkbox>

                <div class="vehicle-history-section indent" *ngIf="!!showVehicleHistory">
                    <mat-form-field appearance="outline" color="accent" class="wide no-hint">
                        <mat-label>Layer</mat-label>
                        <mat-select
                                [(ngModel)]="settings.tracksLayer"
                                (ngModelChange)="onVehicleHistoryChange()"
                        >
                            <mat-option [value]="PublicPortalTrackLayer.GPS">Gps</mat-option>
                            <mat-option [value]="PublicPortalTrackLayer.CURRENCY">Currency</mat-option>
                            <mat-option [value]="PublicPortalTrackLayer.ROUTE_STATUS">Route Status</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="settings.tracksLayer === PublicPortalTrackLayer.ROUTE_STATUS" class="indent">
                        <mat-form-field appearance="outline" color="accent" class="wide no-hint">
                            <mat-label>Route Configuration</mat-label>
                            <mat-select
                                    [(ngModel)]="settings.routeStatusLayerConfig.routeConfigurationId"
                                    (ngModelChange)="updatePublicPortalSettings()"
                            >
                                <mat-option *ngFor="let routeConfig of routeConfigurations" [value]="routeConfig.id">{{ routeConfig.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="mt-10">
                            <span>Threshold when route is still serviced</span>
                            <mat-slider min="1"
                                        max="24"
                                        step="1"
                                        showTickMarks
                                        discrete
                                        [displayWith]="formatServicedThresholdLabel(RouteStatusLayerConfig.THRESHOLD_VALUES)"
                                        color="accent"
                                        style="width: 95%"
                            >
                                <input matSliderThumb
                                       [(ngModel)]="servicedThresholdIndex"
                                       (change)="onServicedThresholdChange($event)"
                                >
                            </mat-slider>
                        </div>
                    </div>
                </div>

                <mat-checkbox
                        [(ngModel)]="showAdditionalMapLayers"
                        (change)="toggleAdditionalMapLayers()"
                >
                    Show Additional Map Layers
                </mat-checkbox>

                <div class="layers-section wide indent" *ngIf="!!showAdditionalMapLayers">
                    <div class="section-header">
                        <div class="section-title">Layers</div>
                        <div class="section-buttons">
                            <button mat-icon-button
                                    color="accent"
                                    (click)="clickedAddLayer()"
                                    [matTooltip]="'Add layer'"
                            >
                                <mat-icon>add</mat-icon>
                            </button>
                            <button mat-icon-button
                                    *ngIf="!!mapLayers && mapLayers.length > 0"
                                    color="accent"
                                    (click)="openMapPreview()"
                                    [matTooltip]="'View on map'"
                            >
                                <mat-icon>map</mat-icon>
                            </button>
                        </div>
                    </div>
                    <ng-container *ngFor="let mapLayer of mapLayers; let i = index">
                        <app-map-layer-configuration [mapLayer]="mapLayer"
                                                     (mapLayerDeleted)="onMapLayerDeleted(i)"
                                                     (mapLayerMoved)="onMapLayerMoveDown(i)"
                                                     (mapLayerToggled)="onMapLayerToggled()"
                                                     (mapLayerSaved)="onMapLayerSaved(mapLayer)"
                                                     (mapLayerCancelled)="onMapLayerCancelled(mapLayer)"
                        ></app-map-layer-configuration>
                    </ng-container>

                </div>

            </ng-container>

            <div class="embed-section wide">
                <div class="section-title">Embed Code</div>
                <pre class="iframe-block">
                    <code>{{ getIframeCode() }}</code>
                </pre>
            </div>

        </div>

        <div class="public-portal-preview-container">
            <app-tenant-public-map
                    [customerId]="customerId"
                    [previewTimestamp]="previewTimestamp">
            </app-tenant-public-map>
        </div>
    </div>
</div>



