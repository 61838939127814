import {VehicleModel} from './vehicle.model';
import {DriverModel} from './driver.model';

export class DvirReport {
  readonly id: number;
  readonly vehicleId: number;
  readonly vehicle: VehicleModel;
  readonly driverId: number;
  readonly driver: DriverModel;
  readonly start: Date;
  readonly startInspectionId: number;
  readonly end: Date;
  readonly endInspectionId: number;
}

export class InspectionReport {
  readonly id: number;
  readonly odometer: number;
  readonly answers: InspectionAnswer[];
}

export class InspectionAnswer {
  readonly inspectionQuestionId: number;
  readonly questionText: string;
  readonly questionOrder: number;
  readonly answer: string;
  readonly detailedMessage?: string;
}

export class InspectionQuestion {
  readonly id: number;
  text: string;
  order: number;
  deleted: boolean;
  dataType: string;
  stage: string;
  creationType: string;
  dropdownProvider?: string = null;
  dropdownData?: string;
  defaultValue?: string;
  answerMapper?: string;
  answerMapperData?: string;
}

export class InspectionQuestionEditable extends InspectionQuestion {
  editable: boolean; // only manual are editable nto the imported ones
  dropdownProviderLabel: string;
  dropdownDataLabel: string;
  dropdownDefaultValueLabel: string;
  answerMapperLabel: string;
  stageLabel: string;
  answerDataLabel: string;
}

export class VehicleInspectionQuestionDropdown {
  readonly id: string;
  readonly label: string;
  readonly default?: boolean;
}

export class Dropdown {
  id: string;
  label: string;
  values: DropdownOption[];
}

export class DropdownOption {
  id: string;
  label: string;
}

export class QuestionMapping {
  id: string;
  label: string;
}

export class QuestionStage {
  id: string;
  label: string;
}
