import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdditionalMapLayerManager} from '../../../../pages/settings/components/manage-public-portal/additional-map-layer-manager';
import {ConfigurationModel} from '../../../models/configuration.model';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ToastService} from '../../../services/toast.service';
import {MatDialog} from '@angular/material/dialog';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../models/action-menu-item.class';
import {MapLayerConfiguration, MapLayerType} from '../../../models/map-layer.model';
import {ExtAuthService} from '../../../../data/ext-auth/ext-auth.service';

@Component({
  selector: 'app-custom-layers-config',
  templateUrl: './custom-layers-config.component.html',
  styleUrls: ['./custom-layers-config.component.scss']
})
export class CustomLayersConfigComponent extends AdditionalMapLayerManager implements OnInit {

  @Input() configuration: ConfigurationModel;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected publicLayers = false;
  layerEditMode = false;

  items: ActionMenuItem[];
  mapLayer: MapLayerConfiguration;

  MapLayerType = MapLayerType;

  constructor(protected configurationService: ConfigurationService,
              private extAuthService: ExtAuthService,
              protected toast: ToastService,
              public dialog: MatDialog,
  ) {
    super(configurationService, toast, dialog);
  }

  ngOnInit(): void {
    this.reloadConfigurations();
  }

  private reloadConfigurations() {
      this.loadMapLayerConfigurations(notEmpty => {
          this.updateItems();
      });
  }

  addLayer() {
    this.addMapLayer();
    this.mapLayer = this.mapLayers[this.mapLayers.length - 1];
    this.layerEditMode = true;
  }

  back() {
    this.layerEditMode = false;
    this.reloadConfigurations();
  }

  editLayer(index) {
    this.layerEditMode = true;
    this.mapLayer = this.mapLayers[index];
  }

  close() {
    this.onClose.emit(true);
  }

  changed() {
    this.mapLayer.dirty = true;
  }

  onCredentialsToggle() {
      if (!this.mapLayer.useCredentials) {
          this.mapLayer.currentUsername = null;
          this.mapLayer.currentPassword = null;
      }
  }

  testCredentials() {
      this.extAuthService.verifyEsriCredentials(
          this.mapLayer.currentUrl,
          this.mapLayer.currentUsername,
          this.mapLayer.currentPassword
      ).then(response => {
          if (response.data) {
              this.toast.short('Connection is OK!');
          } else {
              this.toast.long('Error on connection!');
          }
      });
  }

  private updateItems() {
    this.items = this.mapLayers.map((layer, index) => this.mapLayerToActionMenuItem(layer, index));
  }

  private mapLayerToActionMenuItem(layer: MapLayerConfiguration, index: number): ActionMenuItem {
    return new ActionMenuItem(
        layer.id,
        'layers',
        layer.name,
        '',
        null,
        null,
        () => false,
        null,
        () => this.editLayer(index),
        null,
        null,
        [
          new ActionMenuItemSubMenu(
              'delete',
              'Delete',
              () => {
                this.deleteLayer(index);
                this.updateItems();
              },
          ),
          new ActionMenuItemSubMenu(
              'south',
              'Move Layer',
              () => {
                this.moveLayerDown(index);
                this.updateItems();
              },
          ),
        ],
    );
  }
}
