import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {VehicleBreadcrumb} from '../../../models/vehicle-breadcrumb';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {HardwareConfiguration, SensorType} from '../../../models/vehicle.model';
import {ConfigurationModel, FeatureFlagEnum} from '../../../models/configuration.model';
import {environment} from '../../../../../environments/environment';
import {SecurityService} from '../../../../security/security.service';
import {MetricType, SessionMetricService} from '../services/session-metric.service';
import {DigitalSpreaderStatus} from '../../../../pages/live-map/models/asset.class';
import {ToastService} from '../../../services/toast.service';

@Component({
  selector: 'app-breadcrumb-info-window-content',
  templateUrl: './breadcrumb-info-window-content.component.html',
  styleUrls: ['./breadcrumb-info-window-content.component.scss']
})
export class BreadcrumbInfoWindowContentComponent implements OnInit {

  @Input()
  breadcrumbs: Array<VehicleBreadcrumb>;

  @Input()
  isLiveMap: boolean;

  @Input()
  configuration: ConfigurationModel;

  @Output() visibleBreadcrumbChanged = new EventEmitter<VehicleBreadcrumb>();

  currentBreadcrumb: VehicleBreadcrumb;
  HardwareConfiguration = HardwareConfiguration;
  SensorType = SensorType;
  imageData: any;
  token: string;
  loadingImage = false;
  imageError = false;
  noCamera = false;

  hasDashCamFeatureFlag: boolean;
  digitalGranularStatus: DigitalSpreaderStatus;
  digitalLiquidStatus: DigitalSpreaderStatus;

  constructor(private shiftManagerService: ShiftsManagerService,
              private securityService: SecurityService,
              private sessionMetricService: SessionMetricService,
              private toast: ToastService,
  ) { }

  ngOnInit() {
    if (this.breadcrumbs.length === 0) {
      throw Error('The breadcrumbs array cannot be empty.');
    }
    this.hasDashCamFeatureFlag = this.hasFeatureFlag(FeatureFlagEnum.DashCam);

    this.currentBreadcrumb = this.breadcrumbs[0];
    this.loadDigitalSpreaderStatus();
    this.securityService.getToken().then(accessToken => {
      this.token = accessToken;
      this.loadDashCamImage();
    });

  }

  handlePageEvent(event: PageEvent) {
    this.currentBreadcrumb = this.breadcrumbs[event.pageIndex];
    this.visibleBreadcrumbChanged.emit(this.currentBreadcrumb);
    this.loadDigitalSpreaderStatus();
    this.loadDashCamImage();
  }

  isShiftActive(shiftId: number) {
    return this.shiftManagerService.isShiftActive(shiftId);
  }

  loadDashCamImage() {
    this.imageError = false;
    if (!!this.currentBreadcrumb.vehicle.cameraConfiguration && this.currentBreadcrumb.vehicle.cameraConfiguration.streaming?.captureStreamDuration > 0) {
      this.loadingImage = true;
      this.imageData = undefined;
      this.noCamera = false;
      this.imageData = `${environment.services.service}v1/vehicle/${this.currentBreadcrumb.vehicle.id}/dashcam-image?access_token=${this.token}&from=${this.currentBreadcrumb.breadcrumb.time.toISOString()}`;
    } else {
      this.noCamera = true;
    }
  }

  loadDigitalSpreaderStatus() {
    const hwConfig = this.currentBreadcrumb.vehicle.hardwareConfiguration;
    if (!!hwConfig && !!hwConfig.digitalSpreader) {
      if (hwConfig.digitalSpreader.settings.readGranular) {
        this.digitalGranularStatus = null;
        this.sessionMetricService.closestSessionMetricEvent(
            this.currentBreadcrumb.shiftId,
            this.currentBreadcrumb.vehicle.id,
            MetricType.GRANULAR_RATE,
            (this.currentBreadcrumb.breadcrumb.time.getTime() / 1000),
        ).then(metric => {
          this.digitalGranularStatus = {
            controllerCurrentRate: metric.data.metricValue,
            controllerMaxRate: hwConfig.digitalSpreader.settings.granularMaxRate,
          };
        }).catch(error => {
          console.error(error);
          this.toast.short('Failed to load Digital Spreader granular values!');
        });
      }

      if (hwConfig.digitalSpreader.settings.readLiquid) {
        this.digitalLiquidStatus = null;
        this.sessionMetricService.closestSessionMetricEvent(
            this.currentBreadcrumb.shiftId,
            this.currentBreadcrumb.vehicle.id,
            MetricType.LIQUID_RATE,
            (this.currentBreadcrumb.breadcrumb.time.getTime() / 1000),
        ).then(metric => {
          this.digitalLiquidStatus = {
            controllerCurrentRate: metric.data.metricValue,
            controllerMaxRate: hwConfig.digitalSpreader.settings.granularMaxRate,
          };
        }).catch(error => {
          console.error(error);
          this.toast.short('Failed to load Digital Spreader liquid values!');
        });
      }
    }
  }

  onImageError() {
    // console.log('on error');
    this.imageError = true;
    this.loadingImage = false;
  }

  onImageLoad() {
    // console.log('on load');
    this.imageError = false;
    this.loadingImage = false;
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

}
