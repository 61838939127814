<mat-card appearance="outlined" class="mb-10 mt-10">

    <mat-card-header>
        <mat-card-title>Features configuration</mat-card-title>
        <mat-card-subtitle>
            Features enable or disable parts of the integration; import, export, processing.
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="mt-25">
        <div *ngFor="let feature of features">
            <mat-checkbox [(ngModel)]="feature.checked" (ngModelChange)="changeFeature(feature, $event)"></mat-checkbox>
            <mat-label>{{feature.note}}</mat-label>
            <div class="ml-25"><small class="ml-10">{{feature.details}}</small></div>
        </div>
    </mat-card-content>

    <mat-card-footer>
        <div *ngIf="!isLoading && uiError && uiError.length > 0" class="w-100 mt-15 content">
            <span class="ui-error">{{ uiError }}</span>
        </div>
        <div class="form-row mb-25 mt-10">
            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="updateFeatures()"
            >
                <mat-icon>save</mat-icon>
                Update configuration
            </button>
        </div>
    </mat-card-footer>

</mat-card>

