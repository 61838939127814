import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Headline} from '../../../../../shared/models/configuration.model';

export interface IndexedHeadline {
  index: number;
  headline: Headline;
}

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent implements OnInit {

  @Input() headline: Headline;
  @Input() index: number;

  @Output() headlineDeleted = new EventEmitter<number>();
  @Output() headlineSelected = new EventEmitter<number>();
  @Output() headlineUpdated = new EventEmitter<IndexedHeadline>();

  beingEdited: boolean;
  headlineText: string;
  headlineColor: string;
  headlineBackground: string;

  constructor() { }

  ngOnInit(): void {
    this.beingEdited = false;
    this.headlineText = this.headline.text;
    this.headlineColor = this.headline.color;
    this.headlineBackground = this.headline.backgroundColor;
  }

  edit() {
    this.beingEdited = true;
  }

  delete() {
    this.headlineDeleted.emit(this.index);
  }

  update() {
    this.beingEdited = false;
    this.headline.text = this.headlineText;
    this.headline.color = this.headlineColor;
    this.headline.backgroundColor = this.headlineBackground;
    this.headlineUpdated.emit({
      index: this.index,
      headline: this.headline
    });
  }

  cancel() {
    this.beingEdited = false;
    this.headlineText = this.headline.text;
    this.headlineColor = this.headline.color;
    this.headlineBackground = this.headline.backgroundColor;
  }

  selectedChanged() {
    this.headlineSelected.emit(this.index);
  }
}
