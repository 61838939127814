import {Injectable} from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {GeoJSONSourceSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {PointFeature} from '../../../models/GeoJson';
import {LocationInfo} from '../../../models/AddressLookup';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressLookupMapMarkerService {

  static readonly LAYER_ID_ADDRESS_LOOKUP_POINTS = 'address-lookup-points';
  static readonly ADDRESS_LOOKUP_SOURCE_ID = 'address_lookup';
  static readonly ADDRESS_ICON_NAME = 'south_arrow';

  private mapLayersManager: MapLayersManager;
  private readonly locationMap = new Map<number, LocationInfo>();
  private sourceData: PointFeature[] = [];

  isInitialized = false;

  constructor() {}

  init(mapLayersManager: MapLayersManager) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.sourceData = [];

    // load icon for found addresses
    // source: https://fonts.google.com/icons?selected=Material+Icons:menu
    this.mapLayersManager.loadImage(
        AddressLookupMapMarkerService.ADDRESS_ICON_NAME,
        `${environment.base_href}assets/baseline_location_on_black_24dp.png`,
        { sdf: true });

    this.addSourcesAndLayers();
    this.isInitialized = true;
  }

  addSourcesAndLayers() {
    // set geojson source
    this.mapLayersManager.addSource(AddressLookupMapMarkerService.ADDRESS_LOOKUP_SOURCE_ID, this.createSource());

    // set map layers
    this.mapLayersManager.addLayer(this.createPointsLayer());
  }

  private getSourceData() {
    return {
      type: 'FeatureCollection',
      features: this.sourceData
    };
  }

  private createSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.getSourceData()
    } as GeoJSONSourceSpecification;
  }

  private updateSource() {
    this.mapLayersManager.getGeoJsonSource(AddressLookupMapMarkerService.ADDRESS_LOOKUP_SOURCE_ID)
        ?.setData(this.getSourceData() as any);
  }

  private createPointsLayer(): SymbolLayerSpecification {
    return {
      id: AddressLookupMapMarkerService.LAYER_ID_ADDRESS_LOOKUP_POINTS,
      type: 'symbol',
      source: AddressLookupMapMarkerService.ADDRESS_LOOKUP_SOURCE_ID,
      filter: ['in', 'type', 'address'],
      layout: {
        'icon-image': AddressLookupMapMarkerService.ADDRESS_ICON_NAME
      },
      paint: {
        'icon-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          '#F8BA00',
          'rgb(255, 0, 0)'
        ],
      },
      visibility: 'visible'
    } as SymbolLayerSpecification;
  }

  private locationToFeature(location: LocationInfo): PointFeature {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          location.geometry.lng,
          location.geometry.lat
        ]
      },
      properties: {
        id: location.id,
        type: 'address',
        highlighted: false
      }
    };
  }

  private addLocation(location: LocationInfo) {
    this.sourceData.push(this.locationToFeature(location));
  }

  public highlightLocation(location?: LocationInfo) {
    this.sourceData.forEach(feature => {
      if (feature.properties.type === 'address') {
        feature.properties.highlighted = (!!location && location.id === feature.properties.id);
      }
    });
    this.updateSource();
  }


  public clearLocations() {
    this.sourceData = this.sourceData.filter(feature => feature.properties.type !== 'address');
    this.updateSource();
  }

  public addLocations(locations: LocationInfo[]) {
    this.sourceData = this.sourceData.filter(feature => feature.properties.type !== 'address');
    locations.forEach(location => {
      this.addLocation(location);
    });
    this.updateSource();
  }


  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    this.isInitialized = false;
    this.locationMap.clear();
    this.sourceData = [];
    this.mapLayersManager = null;
  }
}
