<div>
    <mat-card appearance="outlined">
        <mat-card-header>
            <div class="header__wrapper">
                <div class="mat-headline-6">
                    Questions list
                </div>
                <div class="header-actions">
                    <button
                            mat-button
                            color="accent"
                            aria-label="add question"
                            [matTooltip]="'Add questions'"
                            *ngIf="!isLoading && editing"
                            (click)="addQuestion()"
                    >
                        <mat-icon>add_circle</mat-icon>
                        Add
                    </button>
                    <button
                            mat-button
                            color="accent"
                            aria-label="cancel"
                            *ngIf="editing"
                            [matTooltip]="'Cancel editing'"
                            (click)="toggleEdit(false)"
                    >
                        <mat-icon>cancel</mat-icon>
                        Cancel
                    </button>
                    <button
                            mat-button
                            color="accent"
                            aria-label="edit questions"
                            [matTooltip]="'Edit questions'"
                            [disabled]="isLoading"
                            (click)="toggleEdit(true)"
                    >
                        <mat-icon>edit</mat-icon>
                        <ng-container [ngSwitch]="editing">
                            <ng-container *ngSwitchCase="true"> Update</ng-container>
                            <ng-container *ngSwitchDefault> Edit</ng-container>
                        </ng-container>
                    </button>
                </div>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div *ngIf="isLoading" class="loading__wrapper">
                <mat-spinner></mat-spinner>
            </div>

            <div class="card__content mt-25">
                <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                    <span class="ui-error">{{ uiError }}</span>
                </div>
                <div *ngIf="!isLoading && (!questions || questions.data?.length<=0)">There is nothing now</div>

                <mat-table cdkDropList
                           (cdkDropListDropped)="drop($event)" *ngIf="!isLoading && questions && questions.data?.length>0"
                           [dataSource]="questions.data">

                    <ng-container matColumnDef="order">
                        <th
                                mat-header-cell
                                *matHeaderCellDef> Order
                        </th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <ng-container>
                                <button mat-flat-button
                                        cdkDrag
                                        [cdkDragData]="{question: element}"
                                        [cdkDragDisabled]="!editing">
                                    <mat-icon>reorder</mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="text">
                        <th mat-header-cell *matHeaderCellDef> Text</th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <ng-container *ngIf="!editing || !element.editable">
                                {{element.text}}
                            </ng-container>
                            <ng-container *ngIf="editing && element.editable">
                                <mat-form-field appearance="outline" class="dvir-form-field" subscriptSizing="dynamic">
                                    <input matInput [(ngModel)]="element.text">
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="stage">
                        <th mat-header-cell
                            *matHeaderCellDef> Stage
                        </th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <ng-container *ngIf="!editing || !element.editable">
                                {{element.stageLabel}}
                            </ng-container>
                            <ng-container *ngIf="editing && element.editable">
                                <mat-form-field appearance="outline" color="accent" class="dvir-form-field" subscriptSizing="dynamic">
                                    <mat-select [(ngModel)]="element.stage">
                                        <mat-option
                                                *ngFor="let stage of stages"
                                                [value]="stage.id">{{stage.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="dataType">
                        <th mat-header-cell
                            *matHeaderCellDef> Data Type
                        </th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <ng-container *ngIf="!editing || !element.editable">
                                {{element.dataType}}
                            </ng-container>
                            <ng-container *ngIf="editing && element.editable">
                                <mat-form-field appearance="outline" color="accent" class="dvir-form-field" subscriptSizing="dynamic">
                                    <mat-select [(ngModel)]="element.dataType"
                                                (selectionChange)="dataTypeChanged(element, $event)">
                                        <mat-option
                                                *ngFor="let type of dataTypes"
                                                [value]="type.id">{{type.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="dropdownProvider">
                        <th mat-header-cell *matHeaderCellDef> Dropdown provider</th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <ng-container
                                    *ngIf="element.editable && hasAnyDropdownProvider && (element.dataType == 'DROPDOWN' || element.dataType == 'MULTIDROPDOWN')">
                                <button mat-button class="btn-small"
                                        [matTooltip]="'Configure dropdown provider'"
                                        (click)="selectDropdownProvider(element)">
                                    <mat-icon class="btn-small">checklist</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container>
                                {{element.dropdownDataLabel}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="mappingProvider">
                        <th mat-header-cell *matHeaderCellDef> Answer provider</th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <ng-container
                                    *ngIf="element.editable && hasAnyAnswerMapper && element.dataType != 'HEADER'">
                                <button mat-button class="btn-small"
                                        [matTooltip]="'Configure mapping for form processing (optional)'"
                                        (click)="selectQuestionMapper(element)">
                                    <mat-icon class="btn-small">sync_alt</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container>
                                {{element.answerDataLabel}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> ...</th>
                        <td mat-cell *matCellDef="let element" class="table-cell">
                            <button
                                    mat-button class="btn-small"
                                    color="accent"
                                    aria-label="delete question"
                                    [matTooltip]="'Delete question'"
                                    [disabled]="element.deleted"
                                    *ngIf="!isLoading && editing && element.editable"
                                    (click)="deleteQuestion(element)"
                            >
                                <mat-icon class="btn-small">remove_circle</mat-icon>
                            </button>
                        </td>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
                </mat-table>

                <!-- note: cannot be in the ngIf statement! -->
                <mat-paginator showFirstLastButtons
                               (page)="onPage($event)"
                               [pageSize]="pageSize"
                               [pageIndex]="pageIndex"
                               [length]="itemCount"
                               aria-label="Select page">
                </mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>

</div>
