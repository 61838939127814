import { Injectable } from '@angular/core';
import {geocode, reverseGeocode, suggest} from '@esri/arcgis-rest-geocoding';
import {ApiKeyManager, IExtent, IPoint} from '@esri/arcgis-rest-request';
import {ConfigurationService} from '../../configuration/configuration.service';
import {LatLngModel} from '../../shared/models/lat.lng.model';
import {MapLayersManager} from '../../shared/components/map-viewer/map-layers-manager';
import {LatLngPipe} from '../../shared/formatting/latLng.pipe';

export class ReverseGeocodeInfo {
  constructor(
    public coordinates: LatLngModel,
    public address: any,
  ) {}

  static fromLocation(location: LatLngModel): ReverseGeocodeInfo {
    return new ReverseGeocodeInfo(location, null);
  }

  hasCoordinates(): boolean {
    return this.coordinates != null;
  }

  hasAddress(): boolean {
    return this.address?.Address != null && this.address?.Address.trim().length > 0;
  }

  isStreetAndNumber(): boolean {
    return this.address?.AddNum != null && (this.address?.AddNum as string).trim().length > 0;
  }

  coordinatesForAsset(): string {
    return (this.hasCoordinates()) ? `at ${new LatLngPipe().transform(this.coordinates)}` : 'at ?';
  }

  stoppedLocationForAsset(): string {
    if (this.hasAddress()) {
      if (this.isStreetAndNumber()) {
        return `at ${this.address?.Address}`;
      } else { // street only
        return `on ${this.address?.Address}`;
      }
    } else {
      return 'at ?';
    }
  }

  movingLocationForAsset(): string {
    if (this.hasAddress()) {
      const streetAddress = this.address?.Address == null ? '' : (this.address?.Address as string).trim();
      const streetAddressNumber = this.address?.AddNum == null ? '' : (this.address?.AddNum as string).trim();
      const possibleToRemoveNumber = streetAddress.length !== streetAddressNumber.length;
      if (this.isStreetAndNumber() && possibleToRemoveNumber) {
        return `on ${streetAddress.replace(streetAddressNumber, '').trim()}`;
      } else {
        return `on ${streetAddress}`;
      }
    } else {
      return 'on ?';
    }
  }
}

export interface AddressSuggestion {
  text: string;
  magicKey: string;
  isCollection: boolean;
}

export interface Address {
  address: string;
  location: IPoint;
  extent?: IExtent;
  score: number;
  attributes: object;
}

interface SearchExtent {
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
  spatialReference: any;
}

class Location {
  constructor(
      readonly longitude: number,
      readonly latitude: number,
  ) {}

  static fromSearchExtent(extent: SearchExtent): Location {
    if (!extent) {
      return new Location(-109.0, 39.6);
    }
    return new Location(
        extent.xmin + (extent.xmax - extent.xmin) / 2,
        extent.ymin + (extent.ymax - extent.ymin) / 2,
    );
  }

  public toGeocodeParam(): string {
    return `${this.longitude},${this.latitude}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ArcgisApiService {

  static ArcGISAPIKey = 'AAPKee01bd8ccc974b609712fb3e55f1ea5a5Lx8edUITJJ_h5DYoTYBGfcTuMlytQrYAnnXsHpANPuCx6KNU59hTToe2tGRb4Si';
  readonly arcgisRestAuth = ApiKeyManager.fromKey(ArcgisApiService.ArcGISAPIKey);
  region: SearchExtent;
  regionCenter: Location;
  mapLayersManager: MapLayersManager;

  constructor(private configurationService: ConfigurationService) {
    this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model !== null) {
        this.region = this.getBoundsFromRegionPolygon(model.region);
        this.regionCenter = Location.fromSearchExtent(this.region);
      }
    });
  }

  findAddressOfLocation(location: LatLngModel): Promise<ReverseGeocodeInfo> {
    return reverseGeocode(
      [location.lng, location.lat],
      {
        authentication: this.arcgisRestAuth
      }
    ).then(response => {
      // console.log(`'response = '${JSON.stringify(response, null, 2)}'`);
      return new ReverseGeocodeInfo(
        location,
        response.address
      );
    }).catch(error => {
      console.log(`error while reverse geocoding`);
      console.log(JSON.stringify(error, null, 2));
      return ReverseGeocodeInfo.fromLocation(location);
    });
  }

  findAddress(query: string): Promise<Address[]> {
    return geocode({
      singleLine: query,
      authentication: this.arcgisRestAuth,
      params: {
        searchExtent: this.region,
        location: this.getSearchCenter(),
        maxLocations: 10,
        outFields: '*'
      }
    }).then(response => {
      return response.candidates;
    });
  }

  findAddressBasedOnSuggestion(query: string, magicKey: string): Promise<Address> {
    return geocode({
      singleLine: query,
      magicKey,
      authentication: this.arcgisRestAuth,
      params: {
        // searchExtent: this.region,
        location: this.getSearchCenter(),
        maxLocations: 10,
        outFields: '*'
      }
    }).then(response => {
      return response.candidates[0];
    });
  }

  suggestAddresses(query: string, withinTenantRegion = false): Promise<AddressSuggestion[]> {
    return suggest(query, {
      authentication: this.arcgisRestAuth,
      params: {
        category: 'Address',
        maxSuggestions: 5,
        searchExtent: withinTenantRegion ? this.region : undefined,
        location: this.getSearchCenter(),
        outFields: '*'
      }
    }).then(response => {
      return response.suggestions;
    });
  }

  // returns searchExtent
  // https://developers.arcgis.com/rest/services-reference/enterprise/geocode-addresses.htm
  private getBoundsFromRegionPolygon(region: LatLngModel[]): SearchExtent  {
    const latArray = region.map( point => point.lat);
    const lngArray = region.map( point => point.lng);

    // return null if no bounds are defined
    const minLat = Math.min(...latArray);
    if (minLat === 0) { return null; }

    return {
      xmax: Math.max(...lngArray),
      ymax: Math.max(...latArray),
      ymin: minLat,
      xmin: Math.min(...lngArray),
      spatialReference : {wkid : 4326},
    };
  }

  private getSearchCenter() {
    return !!this.mapLayersManager ? this.mapLayersManager.getMapCenter().toArray().join(',') : this.regionCenter.toGeocodeParam();
  }
}
