<div class="legend-wrapper">
    <div class="legend-header">
        <div>
            Traffic
        </div>
    </div>

    <div class="legend-subtitle">
        <span>Current traffic conditions and incidents</span>
    </div>

    <div class="legend-line">
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Free Flow
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: '#6dccac' }"
            >
            </div>
        </div>
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Moderate
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: '#e9d14c' }"
            >
            </div>
        </div>
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Slow
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: '#ec8d00' }"
            >
            </div>
        </div>
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Stop and Go
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: '#e6002e' }"
            >
            </div>
        </div>
    </div>
</div>
