import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { LatLngPipe } from './latLng.pipe';
import { SpeedPipe } from './speed.pipe';
import { CompassPipe } from './compass.pipe';
import { HdopPipe } from './hdop.pipe';
import { YesNoPipe } from './yesno.pipe';
import { DurationPipe } from './duration.pipe';
import { PassFailPipe } from './passfail.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { TruncatePipe } from './truncate.pipe';
import {HoursMinutesPipe} from './hours-minutes.pipe';
import {HoursMinutesSecondsPipe} from './hours-minutes-seconds.pipe';
import {PlowStatusPipe} from './plow-status.pipe';
import {SpreaderStatusPipe} from './spreader-status.pipe';
import {DistancePipe} from './distance.pipe';
import {WeightPipe} from './weight.pipe';
import {VolumePipe} from './volume.pipe';
import {MowerStatusPipe} from './mower-status.pipe';
import {SweeperStatusPipe} from './sweeper-status.pipe';
import { GpsSourcePipe } from './gps-source.pipe';
import { ShortDateOrTimePipe } from './short-date-or-time.pipe';
import {RateUnitPipe} from './rate-unit.pipe';
import {SpreaderMassPipe} from './spreader-mass.pipe';
import {SpreaderLiquidPipe} from './spreader-liquid.pipe';

@NgModule({
  declarations: [
    LatLngPipe,
    SpeedPipe,
    CompassPipe,
    HdopPipe,
    YesNoPipe,
    DurationPipe,
    HoursMinutesPipe,
    HoursMinutesSecondsPipe,
    PassFailPipe,
    DateAgoPipe,
    TruncatePipe,
    PlowStatusPipe,
    SpreaderStatusPipe,
    MowerStatusPipe,
    SweeperStatusPipe,
    DistancePipe,
    WeightPipe,
    VolumePipe,
    GpsSourcePipe,
    ShortDateOrTimePipe,
    RateUnitPipe,
    SpreaderMassPipe,
    SpreaderLiquidPipe,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    LatLngPipe,
    PlowStatusPipe,
    SpreaderStatusPipe,
    MowerStatusPipe,
    SweeperStatusPipe,
    GpsSourcePipe,
  ],
  exports: [
    LatLngPipe,
    SpeedPipe,
    CompassPipe,
    HdopPipe,
    YesNoPipe,
    DurationPipe,
    HoursMinutesPipe,
    HoursMinutesSecondsPipe,
    PassFailPipe,
    DateAgoPipe,
    TruncatePipe,
    PlowStatusPipe,
    SpreaderStatusPipe,
    MowerStatusPipe,
    SweeperStatusPipe,
    DistancePipe,
    WeightPipe,
    VolumePipe,
    GpsSourcePipe,
    RateUnitPipe,
    SpreaderMassPipe,
    SpreaderLiquidPipe,
  ]
})
export class FormattingModule {
}
