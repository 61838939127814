import {McRouteConfiguration} from '../McRouteConfiguration';
import {Map} from 'maplibre-gl';
import {Feature, FeatureCollection} from 'geojson';
import {MapContent} from '../MapContent';
import {RouteStyle} from '../../../../models/route';
import {MapContentSource} from '../MapContentSource';
import {PersistedConfigRouteLayer} from './PersistedConfigRouteLayer';
import {MapTools} from '../../../../tools/MapTools';

export class RouteConfigListMapContent implements MapContent {

  private readonly fallbackRouteStyle =  new RouteStyle('#000000', 2, 1.0);

  private map: Map = null;
  private source: MapContentSource = new MapContentSource('only-source');
  private configRouteLayers: PersistedConfigRouteLayer[] = [];

  // stored for finding currently displayed features
  private visibleLayerIds = new Set<string>();

  constructor(
    configurations: McRouteConfiguration[],
    configRouteFeatureCollection: FeatureCollection,
  ) {
    this.source.loadFromFeatureCollection(configRouteFeatureCollection);

    configurations.forEach(config => {
      config.routes.forEach(route => {
        const layerId = PersistedConfigRouteLayer.assemble(config.id, route.id);
        if (route.visible) {
          this.visibleLayerIds.add(layerId);
        }
        const layer = new PersistedConfigRouteLayer(
            config.id,
            route.id,
            route.visible,
            route.colorOverride,
            route.lineTypeOverride,
            config.style,
            this.fallbackRouteStyle,
            this.source,
        );
        this.configRouteLayers.push(layer);
      });
    });
  }

  load(map: Map) {
    console.log('Loading a map...');
    this.map = map;

    this.source.updateMap(this.map);
    console.log('Setting up route list layers...');
    this.configRouteLayers.forEach(layer => this.map.addLayer(layer.toLayerSpecification()));

    console.log('Filtering visible features...');
    const visibleFeatures = this.filterVisibleFeatures();

    console.log('Zooming to visible features...');
    MapTools.zoomToFeatures(this.map, visibleFeatures);
    console.log('Map loaded.');
  }

  unload() {
    this.configRouteLayers.forEach(layer => this.map.removeLayer(layer.layerId));
    this.map.removeSource(this.source.sourceId);
    this.map = null;
  }

  // TODO tk, get rid of visibleLayerIds, move it as property to each route layer
  //  update route layer when visibility / style / color overrides change
  //  in addition to direct map layer changes here;
  //  now changes would be lost on map content reload

  changeRouteVisibility(configurations: McRouteConfiguration[]) {
    if (this.map != null) {
      this.visibleLayerIds.clear();

      configurations.forEach(config => {
        config.routes.forEach(route => {
          const layerId = PersistedConfigRouteLayer.assemble(config.id, route.id);
          if (route.visible) {
            this.visibleLayerIds.add(layerId);
          }
          this.map.setLayoutProperty(
            layerId,
            'visibility',
            route.visible ? 'visible' : 'none'
          );
        });
      });

      MapTools.zoomToFeatures(this.map, this.filterVisibleFeatures());
    }
  }

  private filterVisibleFeatures(): Feature[] {
    const allFeatures = this.source.features;
    if (allFeatures != null && allFeatures.length > 0) {
      return allFeatures.filter(feature => {
        const configId = feature.properties['configid']; // [sic]
        const routeId = feature.properties['routeid']; // [sic]
        const layerId = PersistedConfigRouteLayer.assemble(configId, routeId);
        return this.visibleLayerIds.has(layerId);
      });
    } else {
      return [];
    }
  }
}
