
export class MifFormQuestionFormatOption {
    public static FORM = new MifFormQuestionFormatOption('Form (All questions presented as a single form)', 'FORM');
    public static WIZARD = new MifFormQuestionFormatOption('Wizard (One question at a time)', 'WIZARD');

    public static options = [
      MifFormQuestionFormatOption.FORM,
      MifFormQuestionFormatOption.WIZARD
    ];
    static label = 'Questions format on Tablet';
    static default = MifFormQuestionFormatOption.FORM;
    static optionCode = 'QUESTION_FORMAT';

    label: string;
    code: string;

    constructor(label: string, code: string) {
        this.label = label;
        this.code = code;
    }

    static fromCode(code: string) {
        const found = MifFormQuestionFormatOption.options.find(value => value.code === code);
        if (found == null) { throw new Error(`error parsing MifFormQuestionFormatOption, code = ${code}`); }
        return found;
    }
}
