import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartegraphMessagingService {

  constructor() {
  }

  public dataObservable: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public onDataReceived(msg: string) {
    this.dataObservable.next(msg);
  }

}
