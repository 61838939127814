<div class="ops-action-menu-item ops-selectable-primary noselect"
     [ngClass]="{ 'active': input.activePredicate(), 'inactive': !!input.inactivePredicate && input.inactivePredicate() }"
     (click)="input.onClick()"
     (mouseenter)="!!input.onHoverOn && input.onHoverOn()"
     (mouseleave)="!!input.onHoverOff && input.onHoverOff()"
>
    <div class="item-icon-and-text">
        <mat-icon class="item-icon">
            {{ input.icon }}
        </mat-icon>
        <div class="item-texts">
            <div class="first-line"
                 [ngClass]="{ 'text-primary': !input.activePredicate() && (!input.inactivePredicate || (!!input.inactivePredicate && !input.inactivePredicate())) }"
            >
                {{ input.title }}
            </div>
            <div class="second-line">
                {{ input.subtitle }}
            </div>
        </div>
    </div>
    <div class="ami-subtitle-and-action">
        <div class="ami-subtitle"
             *ngIf="!!input.text3">
            {{ input.text3 }}
        </div>
        <div class="ami-action-icon"
             *ngIf="!input.text3 && !!input.actionIcon">
            <mat-icon>
                {{ input.actionIcon }}
            </mat-icon>
        </div>
        <button
                *ngIf="!!input.subMenus && input.subMenus.length > 0"
                class="ami-action-button"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
                mat-icon-button
        >
            <mat-icon class="ami-action-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let subMenu of input.subMenus" >
                <button mat-menu-item (click)="subMenu.onClick()">
                    <mat-icon>{{ subMenu.icon }}</mat-icon>
                    <span>{{ subMenu.label }}</span>
                </button>
            </ng-container>
        </mat-menu>
    </div>
</div>
