import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';

// tslint:disable:no-redundant-jsdoc
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private data: AssociativeArray[] = [];

  /**
   * Write the value to the cache, existing value is overwritten.
   * @param key
   * @param payload
   */
  put(key: string, payload: any) {
    this.data[key] = payload;
  }

  /**
   * Take the value by the key and remove it from the cache.
   * @param key
   */
  takeOut(key: string): any {
    const value = this.data[key];
    delete this.data['key'];
    return value;
  }

  /**
   * Take the value by key and keep it in the cache.
   * @param key
   */
  take(key: string): any {
    return this.data[key];
  }
}

interface AssociativeArray {
  [key: string]: any;
}
