<div class="legend-activity-filter" *ngIf="!!appliedFilter">
    <div>
        <span class="activity-name">{{ appliedFilter.label }}</span>&nbsp;<span>activity filter applied</span>
    </div>
    <button mat-icon-button
            class="configure-button"
            (click)="clearFilter()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="legend-wrapper mat-elevation-z2">
    <div class="legend-header">
        <div>
            GPS Tracks Map
        </div>
        <button mat-icon-button
                class="configure-button"
                (click)="goToSettings()"
        >
            <mat-icon>settings</mat-icon>
        </button>
    </div>

    <div class="legend-subtitle">
        <span>Current GPS tracks of active shifts</span>
    </div>

    <div class="legend-line">
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Active
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.LIVE_COLOR }"
            >
            </div>
        </div>
        <div class="legend-item__wrapper">
            <div class="legend-item__name">
                Recent
            </div>
            <div class="legend-item__box"
                 [ngStyle]="{ background: MapStyles.INACTIVE_COLOR }"
            >
            </div>
        </div>
    </div>
</div>
