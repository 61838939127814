<h1 mat-dialog-title>
    {{data.dialogTitle}}
</h1>

<mat-dialog-content class="scrollable">
    <div [innerHTML]="data.dialogContent"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" mat-dialog-close cdkFocusInitial>
        {{data.closeButtonLabel}}
    </button>
</mat-dialog-actions>
