<div class="user-report-container">
    <div class="report-menu">

        <app-user-filter-bar [origin]="origin"
                             (userFilterChanged)="onUserFilterChanged($event)"></app-user-filter-bar>

        <div class="report-menu__actions">
            <button (click)="addNewUser()" mat-button color="accent" *ngIf="!cartegraphEnabled">
                <mat-icon>person_add</mat-icon>
                Add user
            </button>
            <button (click)="changePage('import')" mat-button color="accent" *ngIf="!cartegraphEnabled">
                <mat-icon>group_add</mat-icon>
                Import users
            </button>
            <button (click)="changePage('import-drivers')" mat-button color="accent" *ngIf="!cartegraphEnabled">
                <mat-icon>group_add</mat-icon>
                Import drivers
            </button>
            <button (click)="changePage('import-drivers-cartegraph')" mat-button color="accent"
                    *ngIf="cartegraphEnabled">
                <mat-icon>group_add</mat-icon>
                Import drivers from Cartegraph
            </button>

        </div>
    </div>
    <div class="report__table__wrapper">
        <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content">
            <span class="ui-error">{{ uiError }}</span>
        </div>

        <div class="user-list-spinner-container"
             *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <!-- User Table -->

        <div class="dvir-report-table">
            <table mat-table
                   matSort
                   matSortDisableClear
                   matSortActive="firstName"
                   matSortDirection="desc"
                   (matSortChange)="sortBy()"
                   (scroll)="onScroll($event)"
                   [dataSource]="dataSource" class="ops-table">

                <ng-container matColumnDef="firstName">
                    <th
                            mat-header-cell
                            mat-sort-header
                            class="mat-body text-accent table-column"
                            *matHeaderCellDef
                    >
                        First Name
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.givenName }}</td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th
                            mat-header-cell
                            mat-sort-header
                            class="mat-body text-accent table-column"
                            *matHeaderCellDef
                    >
                        Last Name
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.familyName }}</td>
                </ng-container>

                <ng-container matColumnDef="roles">
                    <th
                            mat-header-cell
                            class="mat-body text-accent table-column"
                            *matHeaderCellDef
                    >
                        Roles
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.roleLabels }}</td>
                </ng-container>

                <ng-container matColumnDef="login">
                    <th
                            mat-header-cell
                            mat-sort-header
                            class="mat-body text-accent table-column"
                            *matHeaderCellDef
                    >
                        Email
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="!element.isDriverOnly">{{ element.email }}</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="phoneNumber">
                    <th
                            mat-header-cell
                            mat-sort-header
                            class="mat-body text-accent table-column"
                            *matHeaderCellDef
                    >
                        Phone Number
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="table-header mw-25"></th>
                    <td mat-cell *matCellDef="let element" class="edit__icon__wrapper">
                        <ng-container
                                *ngIf="element.canUpdateInfo || element.canUpdatePassword || element.canDelete">
                            <button
                                    [matMenuTriggerFor]="menu"
                                    class="edit__icon__button"
                                    mat-icon-button
                                    aria-label="Actions"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="updateUser(element.email)"
                                        *ngIf="element.canUpdateInfo">
                                    <mat-icon>edit</mat-icon>
                                    <span>Update User Infomation</span>
                                </button>
                                <button mat-menu-item (click)="changePassword(element.email)"
                                        *ngIf="element.canUpdatePassword">
                                    <mat-icon>password</mat-icon>
                                    <span>Update User Password</span>
                                </button>
                                <button mat-menu-item (click)="deleteUser(element.email)"
                                        *ngIf="element.canDelete">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete user</span>
                                </button>
                            </mat-menu>
                        </ng-container>
                    </td>
                </ng-container>

                <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="report-table-row"></tr>
            </table>
        </div>

    </div>
</div>
