<div class="setup-page-container">

  <div class="header-container">
    <h2 class="header-container-filters">
      My observation type groups
    </h2>
    <div class="header-container-buttons">
      <div *ngIf="!isLoading">
        <button
                (click)="addObservationTypeGroupDialog()"
                mat-button
                color="accent"
        >
          <mat-icon>add</mat-icon>
          Add Group
        </button>
        <button
                (click)="addObservationTypeDialog()"
                [disabled]="isAddTypeDisabled()"
                mat-button
                color="accent"
        >
          <mat-icon>add</mat-icon>
          Add Type
        </button>
      </div>

    </div>
  </div>

  <div class="content-container">
    <div class="selector-container">
      <div class="selector-container-list">
        <ng-container *ngFor="let observationGroupMenuItem of observationTypeGroupMenuItems">
          <app-action-menu-item style="width: 100%;" [input]="observationGroupMenuItem"></app-action-menu-item>
        </ng-container>
      </div>
    </div>
    <div class="divider"></div>
    <div class="selection-container">
      <mat-spinner *ngIf="isSpinnerShown()"></mat-spinner>
      <ng-container *ngIf="isDetailShown()">
        <app-observation-group
                [observationTypes]="selectedObservationTypeGroup?.observationTypes"
                (editObservationType)="editObservationType($event)"
                (deleteObservationType)="deleteObservationType($event)"
        >
        </app-observation-group>
      </ng-container>
      <ng-container *ngIf="isNoGroupMsgShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No observation type group"
                text="You can add observation type group by using 'Add Group' button."
        >
        </app-blank-page>
      </ng-container>
      <ng-container *ngIf="isSelectionPromptShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No observation type group selected"
                text="Select group from list on the left to see all associated types."
        >
        </app-blank-page>
      </ng-container>
      <ng-container *ngIf="isNoTypeMsgShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No observation types in selected group"
                text="You can add observation type by using 'Add Type' button."
        >
        </app-blank-page>
      </ng-container>
      <ng-container *ngIf="isNoSuchIdErrorShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No such observation type group"
                text="No observation type group could be found for ID provided in URL."
        >
        </app-blank-page>
      </ng-container>
    </div>
  </div>

</div>
