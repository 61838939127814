import moment from 'moment';

export class DateTools {

  static dateWithDayOffset(value: number): Date {
    return moment().utc().add(value, 'days').toDate();
  }

  static dateWithMonthOffset(value: number): Date {
    return moment().utc().add(value, 'months').toDate();
  }

  static dateWithYearOffset(value: number): Date {
    return moment().utc().add(value, 'years').toDate();
  }

  static dateCurrent(): Date {
    return new Date();
  }

  static copyDateOrNull(it: Date): Date {
    return it == null ? null : new Date(it.getTime());
  }

  static fromDtoDateOrNull(it: Date): Date {
    return it == null ? null : moment(it).utc().toDate();
  }
}
