import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {InspectionFormListComponent} from './form-list/inspection-form-list.component';
import {InspectionFormComponent} from './form/inspection-form.component';
import {TreeModule} from '@ali-hm/angular-tree-component';
import {InspectionFormTreeComponent} from './form/tree/inspection-form-tree.component';
import {MifNodeDialogComponent} from './form/mif-node-dialog/mif-node-dialog.component';
import { MifConfirmDeleteFormDialogComponent } from './form-list/mif-confirm-delete-form-dialog/mif-confirm-delete-form-dialog.component';


@NgModule({
  declarations: [
    InspectionFormListComponent,
    InspectionFormComponent,
    InspectionFormTreeComponent,
    MifNodeDialogComponent,
    MifConfirmDeleteFormDialogComponent,
  ],
  exports: [

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    TreeModule,
  ]
})
export class ManageInspectionFormsModule { }
