export class MapLayerConfiguration {
  readonly id: number;
  name: string;
  url: string;
  configuration: string;
  routeConfigurationId: number;
  order: number;
  type: MapLayerType;
  public: boolean;
  enabled: boolean;
  username: string;
  password: string;

  // editor support
  dirty: boolean;
  new: boolean;
  useCredentials: boolean;

  currentType: MapLayerType;
  currentName: string;
  currentUrl: string;
  currentConfiguration: string;
  currentUsername: string;
  currentPassword: string;

  // preview support
  checked = true;
  disabledOnPreview = false;

  static isValid(config: MapLayerConfiguration): boolean {
    return !!config.currentName &&
        (
            (config.currentType === MapLayerType.RASTER && !!config.currentUrl) ||
            (config.currentType === MapLayerType.VECTOR && !!config.currentConfiguration) ||
            (config.currentType === MapLayerType.ROUTE && !!config.routeConfigurationId)
        );
  }
}

export enum MapLayerType {
  RASTER = 'RASTER',
  VECTOR = 'VECTOR',
  ROUTE = 'ROUTE',
}
