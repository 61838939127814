<div class="shift-report-table">
    <table
            mat-table
            matSort
            matSortDisableClear
            [dataSource]="dataSource"
            (matSortChange)="sortChangedEvent.emit()"
            (scroll)="onScroll($event)"
            matSortActive="id"
            matSortDirection="desc"
            class="ops-table"
    >
        <ng-container matColumnDef="shiftId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="shift.id" class="mat-body">
                Shift
            </th>
            <td mat-cell *matCellDef="let element">
                <a
                        [routerLink]="['/shift-detail', element.shiftId]"
                        [queryParams]="{ source: InsightsRoute.SHIFT_REPORT }"
                        class="ops-link"
                >{{ element.shiftId }}</a
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body">Phone number</th>
            <td mat-cell *matCellDef="let element">
                {{ element.phoneNumber }}
            </td>
        </ng-container>

        <ng-container matColumnDef="imei">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body">IMEI</th>
            <td mat-cell *matCellDef="let element">
                {{ element.imei }}
            </td>
        </ng-container>

        <ng-container matColumnDef="sn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body">Serial Number</th>
            <td mat-cell *matCellDef="let element">
                {{ element.sn }}
            </td>
        </ng-container>

        <ng-container matColumnDef="appVersionCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="appVersion" class="mat-body">App Version Code</th>
            <td mat-cell *matCellDef="let element">
                {{ element.appVersionCode }}
            </td>
        </ng-container>

        <ng-container matColumnDef="appVersionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body">App Version Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.appVersionName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="deviceManufacturer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="manufacturer" class="mat-body">Manufacturer</th>
            <td mat-cell *matCellDef="let element">
                {{ element.deviceManufacturer }}
            </td>
        </ng-container>

        <ng-container matColumnDef="deviceModel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="model" class="mat-body">Model</th>
            <td mat-cell *matCellDef="let element">
                {{ element.deviceModel }}
            </td>
        </ng-container>

        <ng-container matColumnDef="deviceSdkVersion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="sdkVersion" class="mat-body">SdkVersion</th>
            <td mat-cell *matCellDef="let element">
                {{ element.deviceSdkVersion }}
            </td>
        </ng-container>

        <ng-container matColumnDef="deviceOsVersion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="osVersion" class="mat-body">OsVersion</th>
            <td mat-cell *matCellDef="let element">
                {{ element.deviceOsVersion }}
            </td>
        </ng-container>

        <ng-container matColumnDef="deviceOsUid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="osUid" class="mat-body">OsUid</th>
            <td mat-cell *matCellDef="let element">
                {{ element.deviceOsUid }}
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let element" class="ops-table-action-cell">
                <button mat-icon-button class="ops-table-action-button">
                    <mat-icon class="ops-table-action-icon">more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithAction; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsWithAction"></tr>
    </table>
</div>
