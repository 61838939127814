import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {Poi, PoiType} from '../../shared/models/poi.model';

@Injectable({
  providedIn: 'root'
})
export class PoiService {

  constructor(private http: HttpClient) { }

  searchByName(name: string) {
    const params: HttpParams = new HttpParams()
        .set('type', PoiType.MILEPOST)
        .set('name', name);
    return this.http.get<JsonApiResponse<Poi[]>>(`${environment.services.service}v1/poi/searchByName`, {params})
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }
}
