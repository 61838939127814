<div class="shift-report-container">
    <div class="report-menu">

        <app-composite-filter-bar (onSearch)="doSearch($event)">
            <app-text-search-composite [title]="'Phone'" [id]="'phone'"></app-text-search-composite>
            <app-text-search-composite [title]="'IMEI'" [id]="'imei'"></app-text-search-composite>
            <app-text-search-composite [title]="'S/N'" [id]="'sn'"></app-text-search-composite>
        </app-composite-filter-bar>

        <div class="report-menu__actions">
<!--            todo: add csv and pdf export-->
<!--            <button-->
<!--                    mat-button-->
<!--                    color="accent"-->
<!--                    [matMenuTriggerFor]="downloadMenu"-->
<!--                    class="caption text-primary"-->
<!--            >-->
<!--                <div class="download-button">-->
<!--                    <span>Download</span>-->
<!--                    <mat-icon>-->
<!--                        arrow_drop_down-->
<!--                    </mat-icon>-->
<!--                </div>-->
<!--            </button>-->
<!--            <mat-menu #downloadMenu="matMenu">-->
<!--                <button-->
<!--                        mat-menu-item-->
<!--                        (click)="loadCSVExportFile()"-->
<!--                >-->
<!--                    CSV-->
<!--                </button>-->
<!--                <button-->
<!--                        mat-menu-item-->
<!--                        (click)="loadPDFExportFile()"-->
<!--                >-->
<!--                    PDF-->
<!--                </button>-->
<!--            </mat-menu>-->
        </div>
    </div>

    <div class="report__table__wrapper">
        <app-device-report-table
                [configuration]="configuration"
                [dataSource]="dataSource"
                (sortChangedEvent)="loadReport()"
                (scrolledToBottomEvent)="extendReport()"
        >
        </app-device-report-table>
    </div>
</div>
