<div class="account__wrapper">
  <div class="account__header">
    <h2 *ngIf="!isResetting">My Account Information</h2>
    <h2 *ngIf="isResetting">My Account: Change Password</h2>

    <div class="account__header__actions">
      <button mat-button
              color="accent"
              [routerLink]="[]"
              [queryParams]="{'reset': undefined, 'edit': undefined}"
              *ngIf="isResetting || isEditing"
      >
        <mat-icon>cancel</mat-icon> Cancel
      </button>

      <button mat-button
              color="accent"
              [routerLink]="[]"
              [queryParams]="{'edit': true}"
              *ngIf="!isEditing && !isResetting"
      >
        <mat-icon>edit</mat-icon> Edit
      </button>

      <button mat-button
              color="accent"
              (click)="save()"
              *ngIf="isEditing && !isResetting"
      >
        <mat-icon>update</mat-icon>
        Update
      </button>

      <button mat-button
              color="accent"
              (click)="updatePassword()"
              [disabled]="passwordUpdateForm.hasError('passwordsDoNotMatch') ||
            passwordUpdateForm.controls['newPassword'].hasError('required') ||
            passwordUpdateForm.controls['newPassword'].hasError('pattern')"
              *ngIf="isResetting"
      >
        <mat-icon>security</mat-icon>Change password
      </button>
    </div>
  </div>

  <div class="account__info__wrapper" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div class="account__info__wrapper" *ngIf="!isLoading && !isResetting">
    <div class="account__row">
      <div class="account__label">
        Account ID
      </div>
      <div class="account__value">
        <span *ngIf="customerId">{{ customerId }}</span>
      </div>
    </div>

    <div class="account__row">
      <div class="account__label">
        Name
      </div>
      <div class="account__value" *ngIf="!isEditing">
        {{ user?.givenName}} {{ user?.familyName }}
      </div>
      <div class="account__value editing" *ngIf="isEditing">
        <mat-form-field class="form-input" appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input matInput [(ngModel)]="user.givenName" autocomplete="off">
        </mat-form-field>
        <mat-form-field class="form-input" appearance="outline" color="accent">
          <mat-label>Last Name</mat-label>
          <input matInput [(ngModel)]="user.familyName" autocomplete="off">
        </mat-form-field>
      </div>
    </div>

    <div class="account__row">
      <div class="account__label">
        Email
      </div>
      <div class="account__value">
        {{ user?.email }}
      </div>
    </div>

    <div class="account__row">
      <div class="account__label">
        Phone
      </div>
      <div class="account__value">
        {{ user?.phoneNumber }}
      </div>
    </div>

    <div class="account__row">
      <div class="account__label">
        Roles
      </div>
      <div class="account__value">
        {{ listRoles() }}
      </div>
    </div>

    <div class="account__row">
      <div class="account__label">
        Password
      </div>
      <div class="account__value padding-unset">
        <button mat-button
                color="accent"
                [routerLink]="[]"
                [queryParams]="{'reset': true}"
        >
          Change Password
        </button>
      </div>
    </div>
  </div>

  <!-- if password reset path -->
  <div class="account__info__wrapper" *ngIf="!isLoading && isResetting">

    <form [formGroup]="passwordUpdateForm">
      <div class=""
           [ngClass]="{'password-error-row': passwordUpdateForm.controls['newPassword'].touched && passwordUpdateForm.controls['newPassword'].invalid}">
        <mat-form-field class="password-form-input"
                        appearance="outline"
                        color="accent"
                        [floatLabel]="'always'"
                        [subscriptSizing]="'dynamic'"
        >
          <mat-label>Password</mat-label>
          <input type="password" matInput placeholder="Password" formControlName='newPassword' name="newPassword" autocomplete="new-password" >
          <mat-error *ngIf="passwordUpdateForm.controls['newPassword'].hasError('minlength')">
            Password must contain at least 8 characters.
          </mat-error>
          <mat-error *ngIf="passwordUpdateForm.controls['newPassword'].hasError('required')">
            Password is required.
          </mat-error>
          <mat-error *ngIf="passwordUpdateForm.controls['newPassword'].hasError('pattern')">
            Password must contain a lower case letter, an upper case letter, a number and a special character.
          </mat-error>
        </mat-form-field>

      </div>
      <div class="account__row">
        <mat-form-field class="password-form-input"
                        appearance="outline"
                        color="accent"
                        [floatLabel]="'always'"
        >
          <mat-label>Verify Password</mat-label>
          <input type="password" matInput placeholder="Verify Password" formControlName='verifyPassword' [errorStateMatcher]="errorMatcher">
          <mat-error *ngIf="passwordUpdateForm.hasError('passwordsDoNotMatch')">
            Passwords do not match!
          </mat-error>
        </mat-form-field>

      </div>
    </form>
  </div>
</div>
