<div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="route-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <mat-checkbox class="checklist-leaf-node"
                          [checked]="checklistSelection.isSelected(node)"
                          (change)="leafItemSelectionToggle(node)">
                <span>{{node.value}}</span>
            </mat-checkbox>
            <div class="node-actions" *ngIf="editing">
                <button
                        (colorPickerChange)="colorChanged()"
                        [(colorPicker)]="node.color"
                        [cpDisabled]="!editing"
                        [disabled]="!editing"
                        [cpAlphaChannel]="'disabled'"
                        mat-icon-button
                        [style.color]="!!node.color ? node.color : colorNoOverride"
                >
                    <mat-icon>palette</mat-icon>
                </button>
                <button
                        *ngIf="!!node.color"
                        (click)="clearColor(node)"
                        [matTooltip]="'Remove color override'"
                        mat-icon-button
                        color="accent"
                >
                    <mat-icon>close</mat-icon>
                </button>
                <button
                        mat-icon-button
                        [disabled]="!editing"
                        [style.color]="!!node.lineType ? node.color : colorNoOverride"
                        [matMenuTriggerFor]="menu"
                >
                    <mat-icon *ngIf="!node.lineType">horizontal_rule</mat-icon>
                    <div class="line-type-wrapper">
                        <app-line-dash-array-view
                                *ngIf="!!node.lineType"
                                [dashArray]="node.lineType"
                        ></app-line-dash-array-view>
                    </div>
                </button>
                <mat-menu #menu="matMenu">
                    <div class="line-type-wrapper">
                        <ng-container *ngFor="let dashArray of allDashArrays; let dashArrayIndex = index">
                            <app-line-dash-array-view
                                    [dashArray]="dashArray"
                                    (click)="selectDashArray(node, dashArrayIndex)"
                            ></app-line-dash-array-view>
                        </ng-container>
                    </div>
                </mat-menu>
                <button
                        mat-icon-button
                        *ngIf="!!node.lineType"
                        (click)="clearDashArray(node)"
                        [matTooltip]="'Remove line type override'"
                        color="accent"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.value">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox [checked]="descendantsAllSelected(node)"
                              [indeterminate]="descendantsPartiallySelected(node)"
                              (change)="itemSelectionToggle(node)">{{node.value}}</mat-checkbox>
                <div class="node-actions" *ngIf="getLevel(node) > 0 && editing">
                    <button
                            (colorPickerChange)="colorChanged()"
                            [(colorPicker)]="node.color"
                            [cpDisabled]="!editing"
                            [disabled]="!editing"
                            [cpAlphaChannel]="'disabled'"
                            mat-icon-button
                            [style.color]="!!node.color ? node.color : colorNoOverride"
                    >
                        <mat-icon>palette</mat-icon>
                    </button>
                    <button
                            *ngIf="!!node.color"
                            (click)="clearColor(node)"
                            [matTooltip]="'Remove color override'"
                            mat-icon-button
                            color="accent"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <button
                            mat-icon-button
                            [disabled]="!editing"
                            [style.color]="!!node.lineType ? node.color : colorNoOverride"
                            [matMenuTriggerFor]="menu"
                    >
                        <mat-icon *ngIf="!node.lineType">horizontal_rule</mat-icon>
                        <div class="line-type-wrapper">
                            <app-line-dash-array-view
                                    *ngIf="!!node.lineType"
                                    [dashArray]="node.lineType"
                            ></app-line-dash-array-view>
                        </div>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div class="line-type-wrapper">
                            <ng-container *ngFor="let dashArray of allDashArrays; let dashArrayIndex = index">
                                <app-line-dash-array-view
                                        [dashArray]="dashArray"
                                        (click)="selectDashArray(node, dashArrayIndex)"
                                ></app-line-dash-array-view>
                            </ng-container>
                        </div>
                    </mat-menu>
                    <button
                            mat-icon-button
                            *ngIf="!!node.lineType"
                            (click)="clearDashArray(node)"
                            [matTooltip]="'Remove line type override'"
                            color="accent"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.route-tree-invisible]="!treeControl.isExpanded(node)"
                 role="group">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
</div>
