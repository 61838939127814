import {Component, OnInit} from '@angular/core';
import {
  CreateObservationGroup,
  EditObservationGroup,
  ObservationTypeGroup
} from '../../../../shared/models/observation-group';
import {MatDialog} from '@angular/material/dialog';
import {ObservationManagementService} from '../../../../data/observations/observation-management.service';
import {
  CreateObservationGroupDialogComponent
} from './dialogs/create-observation-group-dialog/create-observation-group-dialog.component';
import {
  UpdateObservationGroupDialogComponent
} from './dialogs/update-observation-group-dialog/update-observation-group-dialog.component';
import {
  DeleteObservationGroupDialogComponent
} from './dialogs/delete-observation-group-dialog/delete-observation-group-dialog.component';
import {
  CreateObservationTypeDialogComponent
} from './observation-group/dialogs/create-observation-type-dialog/create-observation-type-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../../shared/services/toast.service';
import {ObservationType} from '../../../../shared/models/observation-type';
import {
  UpdateObservationTypeDialogComponent
} from './observation-group/dialogs/update-observation-type-dialog/update-observation-type-dialog.component';
import {
  DeleteObservationTypeDialogComponent
} from './observation-group/dialogs/delete-observation-type-dialog/delete-observation-type-dialog.component';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../shared/models/action-menu-item.class';
import {MapStyles} from '../../../../configuration/map-styles';

@Component({
  selector: 'app-manage-observations',
  templateUrl: './manage-observations.component.html',
  styleUrls: ['./manage-observations.component.scss']
})
export class ManageObservationsComponent implements OnInit {

  observationTypeGroups: ObservationTypeGroup[] = [];

  isLoading = true;
  observationTypeGroupMenuItems: ActionMenuItem[];
  selectedObservationTypeGroup: ObservationTypeGroup;

  constructor(
      public dialog: MatDialog,
      private observationManagementService: ObservationManagementService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.observationManagementService.getObservationTypeGroups().toPromise().then(result => {
      this.observationTypeGroups = result.data;
      this.observationTypeGroups.sort(ObservationTypeGroup.observationTypeGroupCompareFn);
      this.selectFirstObservationTypeGroup();
      this.updateObservationTypeGroupMenu();
      this.subscribeToQueryParamChanges();
      this.isLoading = false;
    }).catch(error => {
      const msg = 'Error while loading data from server';
      this.toastService.long(msg);
      console.error(`${msg} :: ${error}`);
      this.isLoading = false;
    });
  }

  private subscribeToQueryParamChanges() {
    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      const observationTypeGroupIdQueryParam = paramMap.get('observation-type-group');
      if (!!observationTypeGroupIdQueryParam) {
        const observationTypeGroupId = Number(observationTypeGroupIdQueryParam);
        const foundObservationTypeGroup = this.observationTypeGroups.find(value => value.id === observationTypeGroupId);
        if (foundObservationTypeGroup != null) {
          this.selectedObservationTypeGroup = foundObservationTypeGroup;
        } else {
          this.selectedObservationTypeGroup = null;
        }
      }
    });
  }

  addObservationTypeGroupDialog(): void {
    const dialogRef = this.dialog.open(CreateObservationGroupDialogComponent, {
      width: '450px',
      data: {
        name: '',
        duration: 0,
        isAudible: false,
        mapColor: null,
        defaultMapColor: MapStyles.LIVE_COLOR,
      },
    });

    dialogRef.afterClosed().subscribe((data: CreateObservationGroup) => {
      if (!!data) {
        this.observationManagementService.createObservationTypeGroup(data)
          .then(result => {
            const withVehicleGroups = new ObservationTypeGroup(
                result.data.id,
                result.data.name,
                result.data.durationInSeconds,
                [],
                false,
                [],
                result.data.mapColor,
                result.data.isAudible,
            );
            this.observationTypeGroups.push(withVehicleGroups);
            this.observationTypeGroups.sort(ObservationTypeGroup.observationTypeGroupCompareFn);
            this.selectObservationTypeGroupAndChangeQueryParams(withVehicleGroups);
            this.updateObservationTypeGroupMenu();
          }).catch(error => {
            const msg = 'Error while adding observation type group';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
          });
      }
    });
  }

  addObservationTypeDialog(): void {
    const dialogRef = this.dialog.open(CreateObservationTypeDialogComponent, {
      width: '450px',
      data: { title: '', abbreviation: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        const observationTypeData = {
          title: result.title,
          abbreviation: result.abbreviation
        };
        this.observationManagementService.createObservationType(this.selectedObservationTypeGroup.id, observationTypeData)
            .then(response => {
              this.selectedObservationTypeGroup.observationTypes.push(response.data);
              this.selectedObservationTypeGroup.observationTypes = [...this.selectedObservationTypeGroup.observationTypes];
              this.updateObservationTypeGroupMenu();
            })
            .catch(error => {
              const msg = 'Error while adding observation type';
              this.toastService.long(msg);
              console.error(`${msg} :: ${error}`);
            });
      }
    });
  }

  editObservationTypeGroup(edited: ObservationTypeGroup) {
    const dialogRef = this.dialog.open(UpdateObservationGroupDialogComponent, {
      width: '450px',
      data: {
        name: edited.name,
        duration: edited.durationInSeconds / 60 / 60,
        isAudible: edited.isAudible,
        mapColor: edited.mapColor,
        defaultMapColor: MapStyles.LIVE_COLOR,
      },
    });

    dialogRef.afterClosed().subscribe((data: EditObservationGroup) => {
      if (!!data) {
        this.observationManagementService.editObservationTypeGroup(data, edited.id).then(result => {
          edited.name = result.data.name;
          edited.durationInSeconds = result.data.durationInSeconds;
          edited.isAudible = result.data.isAudible;
          edited.mapColor = result.data.mapColor;
          this.observationTypeGroups.sort(ObservationTypeGroup.observationTypeGroupCompareFn);
          this.updateObservationTypeGroupMenu();
        }).catch(error => {
          const msg = 'Error while editing observation type group';
          this.toastService.long(msg);
          console.error(`${msg} :: ${error}`);
        });
      }
    });
  }

  deleteObservationTypeGroup(deleted: ObservationTypeGroup) {
    const indexToDelete = this.observationTypeGroups.indexOf(deleted);

    const dialogRef = this.dialog.open(DeleteObservationGroupDialogComponent, {
      width: '450px',
      data: deleted,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.observationManagementService.deleteObservationTypeGroup(deleted.id).then(() => {
          this.observationTypeGroups.splice(indexToDelete, 1);
          this.deselectDeletedObservationTypeGroup(deleted);
          this.updateObservationTypeGroupMenu();
        }).catch(error => {
          const msg = 'Error while deleting observation type group';
          this.toastService.long(msg);
          console.error(`${msg} :: ${error}`);
        });
      }
    });
  }

  editObservationType(edited: ObservationType): void {
    const dialogRef = this.dialog.open(UpdateObservationTypeDialogComponent, {
      width: '450px',
      data: { title: edited.title, abbreviation: edited.abbreviation }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        const observationTypeData = {
          title: result.title,
          abbreviation: result.abbreviation
        };
        this.observationManagementService.editObservationType(this.selectedObservationTypeGroup.id, edited.id, observationTypeData)
            .then(response => {
              edited.title = response.data.title;
              edited.abbreviation = response.data.abbreviation;
              this.selectedObservationTypeGroup.observationTypes = [...this.selectedObservationTypeGroup.observationTypes];
            })
            .catch(error => {
              const msg = 'Error while editing observation type';
              this.toastService.long(msg);
              console.error(`${msg} :: ${error}`);
            });
      }
    });
  }

  deleteObservationType(deleted: ObservationType): void {
    const indexToDelete = this.selectedObservationTypeGroup.observationTypes.indexOf(deleted);

    const dialogRef = this.dialog.open(DeleteObservationTypeDialogComponent, {
      width: '450px',
      data: deleted,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.observationManagementService.deleteObservationType(this.selectedObservationTypeGroup.id, deleted.id)
            .then(response => {
              this.selectedObservationTypeGroup.observationTypes.splice(indexToDelete, 1);
              this.selectedObservationTypeGroup.observationTypes = [...this.selectedObservationTypeGroup.observationTypes];
              this.updateObservationTypeGroupMenu();
            })
            .catch(error => {
              const msg = 'Error while deleting observation type';
              this.toastService.long(msg);
              console.error(`${msg} :: ${error}`);
            });
      }
    });
  }

  deselectDeletedObservationTypeGroup(deleted: ObservationTypeGroup) {
    if (this.selectedObservationTypeGroup === deleted) {
      this.selectedObservationTypeGroup = null;
      this.changeObservationTypeGroupIdQueryParam(null);
    }
  }

  selectObservationTypeGroupAndChangeQueryParams(observationTypeGroup: ObservationTypeGroup) {
    this.selectedObservationTypeGroup = observationTypeGroup;
    this.changeObservationTypeGroupIdQueryParam(this.selectedObservationTypeGroup.id);
  }

  isSpinnerShown(): boolean {
    return this.isLoading;
  }

  isNoGroupMsgShown() {
    return this.isLoadedAndEmpty()
        && this.selectedObservationTypeGroup == null
        && !this.observationTypeGroupIdQueryParamIsSet();
  }

  isNoTypeMsgShown(): boolean {
    return this.isLoadedAndNotEmpty()
        && this.selectedObservationTypeGroup != null
        && this.selectedObservationTypeGroup.observationTypes.length <= 0;
  }

  isDetailShown(): boolean {
    return this.isLoadedAndNotEmpty()
        && this.selectedObservationTypeGroup != null
        && this.selectedObservationTypeGroup.observationTypes.length > 0;
  }

  isNoSuchIdErrorShown(): boolean {
    return !this.isLoading
        && this.selectedObservationTypeGroup == null
        && this.observationTypeGroupIdQueryParamIsSet();
  }

  isSelectionPromptShown(): boolean {
    return this.isLoadedAndNotEmpty()
        && this.selectedObservationTypeGroup == null &&
        !this.observationTypeGroupIdQueryParamIsSet();
  }

  private observationTypeGroupIdQueryParamIsSet() {
    const observationTypeGroupIdQueryParam = this.activatedRoute.snapshot.queryParamMap.get('observation-type-group');
    return !!observationTypeGroupIdQueryParam;
  }

  isAddTypeDisabled(): boolean {
    return this.isLoading || this.selectedObservationTypeGroup == null;
  }

  updateObservationTypeGroupMenu() {
    this.observationTypeGroupMenuItems = this.observationTypeGroups.map(value => this.actionMenuItem(value));
  }

  private actionMenuItem(observationTypeGroup: ObservationTypeGroup) {
    return new ActionMenuItem(
        observationTypeGroup.id,
        'stars',
        observationTypeGroup.name,
        `${observationTypeGroup.observationTypes?.length} types`,
        '',
        null,
        () => observationTypeGroup === this.selectedObservationTypeGroup,
        null,
        () => { this.selectObservationTypeGroupAndChangeQueryParams(observationTypeGroup); },
        null,
        null,
        [
          new ActionMenuItemSubMenu(
              'edit',
              'Edit group',
              () => { this.editObservationTypeGroup(observationTypeGroup); }
          ),
          new ActionMenuItemSubMenu(
              'delete',
              'Delete group',
              () => { this.deleteObservationTypeGroup(observationTypeGroup); }
          )
        ]
    );
  }

  private isLoadedAndEmpty() {
    return !this.isLoading && this.observationTypeGroups.length <= 0;
  }

  private isLoadedAndNotEmpty() {
    return !this.isLoading && this.observationTypeGroups.length > 0;
  }

  private selectFirstObservationTypeGroup() {
    this.selectedObservationTypeGroup = this.observationTypeGroups.length > 0 ? this.observationTypeGroups[0] : null;
  }

  private selectFirstObservationTypeGroupAndChangeQueryParams() {
    if (this.observationTypeGroups.length > 0) {
      this.selectObservationTypeGroupAndChangeQueryParams(this.observationTypeGroups[0]);
    } else {
      this.selectedObservationTypeGroup = null;
    }
  }

  private changeObservationTypeGroupIdQueryParam(observationTypeGroupId: number) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { 'observation-type-group': observationTypeGroupId },
      queryParamsHandling: 'merge',
    });
  }
}
