import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-loading-spinner',
  templateUrl: './card-loading-spinner.component.html',
  styleUrls: ['./card-loading-spinner.component.scss'],
})
export class CardLoadingSpinnerComponent implements OnInit {
  @Input() showLoading?: boolean;
  constructor() {}

  ngOnInit(): void {}
}
