import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {
  VehicleBreadcrumb,
  VehicleBreadcrumbUpdate
} from '../../shared/models/vehicle-breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsManagerService {

  // search breadcrumbs and show info window
  private selectedBreadcrumb: Array<VehicleBreadcrumb> = null;
  private readonly selectedBreadcrumbSubscribers = new Array<Subscriber<VehicleBreadcrumbUpdate>>();
  readonly selectedBreadcrumbObservable = new Observable<VehicleBreadcrumbUpdate>((observer) => {
    observer.next(new VehicleBreadcrumbUpdate(this.selectedBreadcrumb, null));
    this.selectedBreadcrumbSubscribers.push(observer);
  });

  constructor() { }

  public clearBreadcrumbSelection(source: string) {
    this.selectedBreadcrumb = null;
    this.raiseSelectedBreadcrumbChanged(new VehicleBreadcrumbUpdate(this.selectedBreadcrumb, source));
  }

  public selectBreadcrumb(vehicleBreadcrumbs: Array<VehicleBreadcrumb>, source: string) {
    this.selectedBreadcrumb = vehicleBreadcrumbs;
    this.raiseSelectedBreadcrumbChanged(new VehicleBreadcrumbUpdate(this.selectedBreadcrumb, source));
  }

  private raiseSelectedBreadcrumbChanged(breadcrumbUpdate: VehicleBreadcrumbUpdate) {
    this.selectedBreadcrumbSubscribers.forEach(subscriber => {
      subscriber.next(breadcrumbUpdate);
    });
  }
}
