import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LatLngModel } from '../../../models/lat.lng.model';
import { ShiftWithDriverAndVehicleModel } from '../../../models/shift.model';
import { Subscription } from 'rxjs';
import {LngLatBounds, LngLatLike, Map, NavigationControl, ScaleControl, Style} from 'maplibre-gl';
import { SecurityService } from '../../../../security/security.service';
import { ConfigurationModel } from '../../../models/configuration.model';
import { ConfigurationService } from '../../../../configuration/configuration.service';
import {
  BaseMapType,
  SettingsService,
} from '../../../../configuration/settings.service';
import { MapLayersManager } from '../map-layers-manager';

import { VehicleMapMarkerService } from '../services/vehicle-map-marker.service';
import { ObservationMapMarkerService } from '../services/observation-map-marker.service';
import { ImageMapMarkerService } from '../services/image-map-marker.service';
import { PopupInfoService } from '../services/popup-info.service';
import { BreadcrumbInfoWindowService } from '../services/breadcrumb-info-window.service';
import { MapEventService } from '../services/map-event.service';
import { TracksVectorTilesLayerService } from '../services/tracks-vector-tiles-layer.service';
import { ShiftTrackLayerService } from '../services/shift-track-layer.service';
import { TracksGeoJsonLayerService } from '../services/tracks-geo-json-layer.service';
import { RoadStatusService } from '../services/road-status.service';
import { RoadStatusCurrencyLayerService } from '../services/road-status-currency-layer.service';
import { RoadStatusCoverageLayerService } from '../services/road-status-coverage-layer.service';
import { AddressLookupMapMarkerService } from '../services/address-lookup-map-marker.service';
import {MapControlService} from '../services/map-control.service';
import {RoutesLayerService} from '../services/routes-layer.service';
import {ShiftPlaybackService} from '../services/shift-playback.service';
import {WeatherRadarLayerService} from '../services/weather-radar-layer.service';
import {WeatherWarningsLayerService} from '../services/weather-warnings-layer.service';
import {ShiftMarkerLayerService} from '../services/shift-marker-layer.service';
import {TrafficLayerService} from '../services/traffic-layer.service';
import {ExtAuthService} from '../../../../data/ext-auth/ext-auth.service';
import {RouteSourceService} from '../services/route-source.service';
import {LocationHistoryService} from '../services/location-history.service';
import {StaticRoutesLayerService} from '../services/static-routes-layer.service';

@Component({
  selector: 'app-map-panel',
  templateUrl: './map-panel.component.html',
  styleUrls: ['./map-panel.component.scss'],
})
export class MapPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  bounds: LngLatBounds;

  @Input()
  shift: ShiftWithDriverAndVehicleModel = null;

  @Input()
  useLiveFeed = true;

  @Input()
  rightSideControlsOffset = 10;

  @ViewChild('map')
  private mapContainer: ElementRef<HTMLElement>;

  private initialMapCenter: LatLngModel = new LatLngModel(39.4, -104.89); // Castle Rock fallback
  private zoomLevel = 12;
  private mapCenter: LngLatLike;
  configuration: ConfigurationModel;
  configSubscription: Subscription;
  resizeSubscription: Subscription;
  mapLayersManager: MapLayersManager;
  isMapInitialized = false;
  map: Map;
  mapStyle: Style | string;

  constructor(
    private configurationService: ConfigurationService,
    private vehicleMapMarkerService: VehicleMapMarkerService,
    private observationMapMarkerService: ObservationMapMarkerService,
    private imageMapMarkerService: ImageMapMarkerService,
    private markerInfoWindowService: PopupInfoService,
    private breadcrumbInfoWindowService: BreadcrumbInfoWindowService,
    private mapEventService: MapEventService,
    private tracksVectorTilesLayerService: TracksVectorTilesLayerService,
    private shiftTrackVectorTilesLayerService: ShiftTrackLayerService,
    private shiftPlaybackService: ShiftPlaybackService,
    private shiftMarkerLayerService: ShiftMarkerLayerService,
    private tracksGeoJsonLayerService: TracksGeoJsonLayerService,
    private routeSourceService: RouteSourceService,
    private routesLayerService: RoutesLayerService,
    private staticRoutesLayerService: StaticRoutesLayerService,
    private roadStatusService: RoadStatusService,
    private roadStatusCoverageLayerService: RoadStatusCoverageLayerService,
    private roadStatusCurrencyLayerService: RoadStatusCurrencyLayerService,
    private addressLookupMapMarkerService: AddressLookupMapMarkerService,
    private weatherRadarLayerService: WeatherRadarLayerService,
    private weatherWarningsLayerService: WeatherWarningsLayerService,
    private trafficLayerService: TrafficLayerService,
    private extAuthService: ExtAuthService,
    private settingsService: SettingsService,
    private securityService: SecurityService,
    private injector: Injector,
    private appRef: ApplicationRef,
    private mapControlService: MapControlService,
    private locationHistoryService: LocationHistoryService,
  ) {}

  ngOnInit(): void {
    this.configSubscription =
        this.configurationService.sharedConfigurationModel.subscribe((model) => {
          const oldConfig = this.configuration;
          this.configuration = model;
          if (!!oldConfig) {
            // configuration has been updated, previous value = undefined
            this.onConfigurationChange();
          }
        });

    this.resizeSubscription = this.mapControlService.mapResizeEvent.subscribe((val) => {
      setTimeout(() => {
        this.resize();
      }, 300);
    });
  }

  ngAfterViewInit(): void {
    // convert bounds to zoom level and map center
    this.fromBoundsToZoomLevelAndMapCenter();

    this.mapCenter = !!this.mapCenter ? this.mapCenter : [this.initialMapCenter.lng, this.initialMapCenter.lat];
    // this.mapCenter = [-107, 43]; // Wyoming
    // this.mapCenter = [18.3, 49.8]; // Ostrava
    // this.mapCenter = [-104.89, 39.4]; // CastleRock
    // this.mapCenter = [-98.7, 30.0]; // New Mexico
    // this.mapCenter = [-121, 44]; // Oregon
    // this.mapCenter = [-70.75, 43.38]; // Sanford

    const useMetric = this.configuration.useMetricSystem;
    const baseMapName = this.settingsService.getStringValue(
      SettingsService.BASE_MAP_LAYER_KEY
    );
    switch (baseMapName) {
      case BaseMapType.OUTDOORS:
        this.mapStyle = useMetric ? MapLayersManager.ACCUTERRA_OUTDOORS_M_MAP_STYLE : MapLayersManager.ACCUTERRA_OUTDOORS_MAP_STYLE;
        break;
      case BaseMapType.LIGHT:
        this.mapStyle = useMetric ? MapLayersManager.ACCUTERRA_LIGHT_M_MAP_STYLE : MapLayersManager.ACCUTERRA_LIGHT_MAP_STYLE;
        break;
      case BaseMapType.DARK:
        this.mapStyle = useMetric ? MapLayersManager.ACCUTERRA_DARK_M_MAP_STYLE : MapLayersManager.ACCUTERRA_DARK_MAP_STYLE;
        break;
      default:
        this.mapStyle = MapLayersManager.IMAGERY_MAP_STYLE;
    }

    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: this.mapStyle,
      center: this.mapCenter,
      zoom: this.zoomLevel,
      attributionControl: false,
      fadeDuration: 0,
    });

    this.map.on('load', () => {
      this.onMapLoad();
    });
  }

  private onMapLoad() {
    this.mapLayersManager = new MapLayersManager(
      this.map,
      this.configuration,
      this.settingsService,
      this.securityService,
      this.shift,
      this.useLiveFeed,
      this.observationMapMarkerService,
      this.vehicleMapMarkerService,
      this.imageMapMarkerService,
      this.markerInfoWindowService,
      this.breadcrumbInfoWindowService,
      this.mapEventService,
      this.mapControlService,
      this.tracksVectorTilesLayerService,
      this.shiftTrackVectorTilesLayerService,
      this.shiftPlaybackService,
      this.shiftMarkerLayerService,
      this.tracksGeoJsonLayerService,
      this.routeSourceService,
      this.routesLayerService,
      this.staticRoutesLayerService,
      this.roadStatusService,
      this.roadStatusCoverageLayerService,
      this.roadStatusCurrencyLayerService,
      this.addressLookupMapMarkerService,
      this.weatherRadarLayerService,
      this.weatherWarningsLayerService,
      this.trafficLayerService,
      this.extAuthService,
      this.locationHistoryService,
    );
    this.mapLayersManager.init();
    this.isMapInitialized = true;

    this.map.addControl(
        new ScaleControl(
            { unit: this.configuration.useMetricSystem ? 'metric' : 'imperial' }
        ),
        'bottom-right'
    );
    this.map.addControl(
      new NavigationControl({ showCompass: true, showZoom: true }),
      'top-right'
    );
  }

  private onConfigurationChange() {
    if (!!this.mapLayersManager) {
      this.mapLayersManager.updateConfiguration(this.configuration);
    }
  }

  ngOnDestroy() {
    if (this.isMapInitialized) {
      this.mapLayersManager.release();
      this.shift = null;
      this.mapLayersManager = null;
      this.isMapInitialized = false;
    }
    this.configSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    this.map?.remove();
  }

  private resize() {
    this.map?.resize();
  }

  private fromBoundsToZoomLevelAndMapCenter() {
    if (!!this.configuration?.region && !!this.mapContainer) {
      let boundingBox: LngLatBounds;
      if (!!this.bounds) {
        // use bounds from input
        boundingBox = this.bounds;
      } else {
        // use bounds from tenant configuration
        if (this.configuration.region.length > 0) {
          boundingBox = this.configuration.region.reduce((bounds, latLngModel) => {
            return bounds.extend([latLngModel.lng, latLngModel.lat]);
          }, new LngLatBounds(this.configuration.region[0], this.configuration.region[0]));
        } else {
          console.warn('Tenant Region is empty, leaving default map center.');
          return;
        }
      }

      const dims = {
        height: this.mapContainer.nativeElement.offsetHeight,
        width: this.mapContainer.nativeElement.offsetWidth - 310 // hideable left panel
      };
      this.zoomLevel = MapControlService.getBoundsZoomLevel(boundingBox, dims);
      this.mapCenter = boundingBox.getCenter();
    } else {
      console.error('Error reading Region!');
    }
  }
}
