<h1 mat-dialog-title>
    Delete form
</h1>

<mat-dialog-content>
    <p>
        Do you really want to delete form <i>{{ data.form.title }}</i>?
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        Cancel
    </button>
    <button mat-button color="accent" [mat-dialog-close]="data" cdkFocusInitial>
        Yes, delete please
    </button>
</mat-dialog-actions>
