<div class="loading-container">
  <mat-spinner></mat-spinner>
  <div class="loading__msg__wrapper mt-25">
    <div class="mat-subtitle-1">Loading {{ message }}...</div>
  </div>

  <div *ngIf="loadErrorMessage">
    {{ loadErrorMessage }}
    <button mat-raised-button color="primary" (click)="onLogout()">
      Logout
    </button>
  </div>
</div>
