<div class="image-wrapper" *ngIf="!!selectedItem">
    <div class="image">
        <img
                class="preview_image"
                [src]="imageData"
                [alt]="'dashcam preview'"
                (error)="onImageError()"
                (load)="onImageLoad()"
                [hidden]="imageError || loadingImage"
        />
        <mat-spinner *ngIf="loadingImage"></mat-spinner>
    </div>

    <div class="image-info">
        <span>{{ selectedItem.time | date:'short' }}</span>
        <span>{{ positionString }}</span>
    </div>

    <div class="image-controls">
        <button mat-icon-button
                (click)="previousImage()"
        >
            <mat-icon>west</mat-icon>
        </button>
        <button mat-icon-button
                (click)="nextImage()"
        >
            <mat-icon>east</mat-icon>
        </button>
    </div>
</div>
