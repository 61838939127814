import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {ManageUsersComponent} from './manage-users.component';
import {
  DialogChangePasswordComponent,
  DialogConfirmDeleteUserComponent,
  DialogEditUserComponent,
} from './dialogs/dialog-components';
import {ManageUsersMainComponent} from './manage-users-main.component';
import {ImportUsersComponent} from './manage-users-import.component';
import {ImportDriversComponent} from './drivers-import/manage-drivers-import.component';
import {ImportDriversCartegraphComponent} from './drivers-import-cartegraph/manage-drivers-import-cartegraph.component';
import {UserFilterBarComponent} from './user-filter/user-filter-bar.component';

@NgModule({
  declarations: [
    ManageUsersMainComponent,
    ManageUsersComponent,
    ImportUsersComponent,
    DialogEditUserComponent,
    DialogChangePasswordComponent,
    DialogConfirmDeleteUserComponent,
    ImportDriversComponent,
    ImportDriversCartegraphComponent,
    UserFilterBarComponent
  ],
  exports: [
    ManageUsersMainComponent,
    ManageUsersComponent,
    ImportUsersComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
  ]
})
export class UserManagementModule { }
