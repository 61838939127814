<div class="setup-page-container">
    <div class="header-container">
        <h2 class="header-container-filters">
            My users
        </h2>
    </div>

    <div class="selector-container mt-25">

        <mat-card appearance="outlined">
            <mat-card-title class="text-accent pl-15">Import users from CSV file</mat-card-title>
            <mat-card-content class="w-100 content">
                <div class="loading__wrapper" *ngIf="currentState == states.WORKING">
                    <div>Working on it ...</div>
                    <mat-spinner></mat-spinner>
                </div>
                <div *ngIf="currentState == states.NOOP" class="w-100">
                    <input hidden type="file"
                           id="file"
                           accept=".csv"
                           #fileInput
                           (change)="handleFileInput($event)">
                    <button mat-button
                            color="accent" (click)="fileInput.click()"> Choose CSV file
                    </button>
                    <span class="ml-10" *ngIf="selectedFile">{{selectedFile.name}}</span>
                    <span class="ml-10">
                    <button mat-button
                            color="accent" [disabled]="selectedFile == null" (click)="upload()"> Upload CSV file
                    </button>
                </span>
                    <span class="ml-10">
                    <button mat-button
                            color="accent" (click)="cancelImport()"> Cancel
                    </button>
                </span>
                    <div class="mt-15">
                        <h4>Sample csv</h4>
                        <div>
                            Basic import. Key is: <em>email</em>.
                            <pre>
email, familyName, givenName, isAdmin, phoneNumber, sendWelcomeEmail
johndoe@email.com, Doe, John, false, 000111222, false
...
...
                </pre>
                        </div>
                    </div>
                </div>

                <div *ngIf="currentState == states.NOOP && uiError && uiError.length > 0" class="w-100 content">
                    <span class="ui-error">{{ uiError }}</span>
                </div>

                <div *ngIf="currentState == states.IMPORTED" class="w-100 content">
                    <div>Import successfully finished.
                        <button mat-button color="accent" (click)="changePage()"
                                class="text-accent">
                            Navigate to user management
                        </button>
                        to see changes.
                    </div>
                </div>
            </mat-card-content>
        </mat-card>


        <ng-container *ngIf="currentState == states.NOOP">
            <mat-card appearance="outlined" *ngIf="validData && validData.length>0">
                <mat-card-title class="text-accent pl-15"> Valid data</mat-card-title>
                <mat-card-actions>
                    <button mat-button
                            color="accent"
                            (click)="doCancel()">Cancel
                    </button>
                    <button mat-button
                            color="accent"
                            [disabled]="importDisabled()"
                            (click)="doImport()">Import
                    </button>
                </mat-card-actions>
                <mat-card-content>
                    <ng-container
                            *ngTemplateOutlet="table;context:{importData: validData,displayedColumns:displayedColumnsValid}"></ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mt-15" *ngIf="invalidData && invalidData.length>0">
                <mat-card-title class="text-accent pl-15"> Invalid data</mat-card-title>
                <mat-card-content>
                    <ng-container
                            *ngTemplateOutlet="table;context:{importData: invalidData,displayedColumns:displayedColumnsInvalid}"></ng-container>
                </mat-card-content>
            </mat-card>
        </ng-container>

    </div>
</div>


<ng-template #table let-data="importData" let-displayedColumns="displayedColumns">
    <table *ngIf="data && data.length>0" mat-table
           [dataSource]="data">

        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef> Import</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef> Line</th>
            <td mat-cell *matCellDef="let element">{{element.index}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email</th>
            <td mat-cell *matCellDef="let element">{{element.row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="givenName">
            <th mat-header-cell *matHeaderCellDef> Given name</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.givenName}}
                <del *ngIf="element.dbData.user?.givenName != element.row?.givenName">{{element.dbData.user?.givenName}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="familyName">
            <th mat-header-cell *matHeaderCellDef> Family name</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.familyName}}
                <del *ngIf="element.dbData.user?.familyName != element.row?.familyName">{{element.dbData.user?.familyName}}</del>
            </td>
        </ng-container>


        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef> Phone number</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.phoneNumber}}
                <del *ngIf="element.dbData.user?.phoneNumber != element.row?.phoneNumber">{{element.dbData.user?.phoneNumber}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="isAdmin">
            <th mat-header-cell *matHeaderCellDef> Admin?</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="bool;context:{value:element.row?.isAdmin}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="sendWelcomeEmail">
            <th mat-header-cell *matHeaderCellDef> Send email</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="bool;context:{value:element.row?.sendWelcomeEmail}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="action;context:{action:element.action}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Errors</th>
            <td mat-cell *matCellDef="let element">
                <div *ngFor="let error of element.errors">{{error}}</div>
                <div *ngIf="element.action=='NONE'">no changes - skipping import</div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>

<ng-template #action let-action="action">
    <ng-container [ngSwitch]="action">
        <mat-icon *ngSwitchCase="'CREATE'" color="accent" [title]="action">add_circle</mat-icon>
        <mat-icon *ngSwitchCase="'UPDATE'" color="accent" [title]="action">sync</mat-icon>
        <mat-icon *ngSwitchCase="'NONE'" color="accent" [title]="action">nothing</mat-icon>
    </ng-container>
</ng-template>

<ng-template #bool let-value="value">
    <ng-container [ngSwitch]="value">
        <mat-icon *ngSwitchCase="'true'" color="accent" [title]="action">check</mat-icon>
        <mat-icon *ngSwitchCase="'1'" color="accent" [title]="action">check</mat-icon>
        <mat-icon *ngSwitchCase="'yes'" color="accent" [title]="action">check</mat-icon>
    </ng-container>
</ng-template>
