<div class="icon-text-button"
     (click)="input.onClick()">
    <mat-icon class="button-icon ops-selectable-primary"
              [ngClass]="{ 'active': input.activePredicate() }">
        {{ input.icon }}
    </mat-icon>
    <div class="button-text"
         [ngClass]="{ 'active': input.activePredicate() }">
        {{ input.title }}
    </div>
</div>
