<div class="headline">

    <mat-radio-button class="headline-radio-button"
                      [value]="headline.text"
                      [checked]="headline.selected"
                      (change)="selectedChanged()"
    >
        <span *ngIf="!beingEdited">{{ headline.text }}</span>
    </mat-radio-button>

    <div class="field-with-right-buttons wide" *ngIf="beingEdited">
        <mat-form-field appearance="outline" color="accent" class="no-hint">
            <mat-label>Message Text</mat-label>
            <input matInput
                   autocomplete="off"
                   type=text
                   [(ngModel)]="headlineText"
            >
        </mat-form-field>
        <div>
            <button mat-icon-button
                    color="accent"
                    [(colorPicker)]="headlineColor"
                    [cpAlphaChannel]="'disabled'"
                    [cpFallbackColor]="'#FFFFFF'"
            >
                <mat-icon>format_color_text</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    [(colorPicker)]="headlineBackground"
                    [cpAlphaChannel]="'disabled'"
                    [cpFallbackColor]="'#0b568a'"
            >
                <mat-icon>format_color_fill</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    (click)="update()"
                    [matTooltip]="'Save'"
            >
                <mat-icon>save</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    (click)="cancel()"
                    [matTooltip]="'Cancel'"
            >
                <mat-icon>Cancel</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="!beingEdited" class="action-buttons">
        <button mat-icon-button
                color="accent"
                (click)="edit()"
                [matTooltip]="'Edit message'"
        >
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button
                color="accent"
                (click)="delete()"
                [matTooltip]="'Remove message'"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
