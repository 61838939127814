import { RouterModule } from '@angular/router';
import { MaterialModule } from './material/material.module';
import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ExploreCardComponent } from './components/cards/explore-card/explore-card.component';
import { ShiftDetailCardComponent } from './components/cards/shift-detail-card/shift-detail-card.component';
import { DriverStatsCardComponent } from './components/cards/driver-stats-card/driver-stats-card.component';
import { BlankPageComponent } from './components/blank-page-image/blank-page.component';
import { MenuToggleButtonComponent } from './components/buttons/menu-toggle-button/menu-toggle-button.component';
import { MapViewerModule } from './components/map-viewer/map-viewer.module';
import { FormattingModule } from './formatting/formatting.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { VehicleStatsCardComponent } from './components/cards/vehicle-stats-card/vehicle-stats-card.component';
import { CardLoadingSpinnerComponent } from './components/loading/card-loading-spinner/card-loading-spinner.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MapPanelToggleButtonComponent } from './components/buttons/map-panel-toggle-button/map-panel-toggle-button.component';
import {SettingsSectionHeaderComponent} from './components/settings-section-header/settings-section-header.component';
import {SimpleDialogComponent} from './components/dialogs/simple-dialog/simple-dialog.component';
import {ConfirmDeleteShiftDialogComponent} from './components/dialogs/confirm-delete-shift-dialog/confirm-delete-shift-dialog.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import {SideMenuComponent} from './components/side-menu/side-menu.component';
import { ActionMenuItemComponent } from './components/action-menu-item/action-menu-item.component';
import { VerticalIconButtonComponent } from './components/buttons/vertical-icon-button/vertical-icon-button.component';
import { ObservationGroupFilterComponent } from './components/observation-group-filter/observation-group-filter.component';
import { ActionMenuItem2LineComponent } from './components/action-menu-item-2line/action-menu-item-2line.component';
import { TenantPublicMapComponent } from './components/tenant-public-map/tenant-public-map.component';
import { AssetStatusFilterComponent } from './components/asset-status-filter/asset-status-filter.component';
import { HardwareFilterComponent } from './components/hardware-filter/hardware-filter.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import {DateFilterDropdownComponent} from './components/date-filter-dropdown/date-filter-dropdown.component';
import {VehicleGroupFilterComponent} from './components/vehicle-group-filter/vehicle-group-filter.component';
import {MapLayerSwitcherComponent} from './components/map-layer-switcher/map-layer-switcher.component';
import {CustomLayersConfigComponent} from './components/map-layer-switcher/custom-layers-config/custom-layers-config.component';
import { MapPreviewComponent } from './components/map-preview/map-preview.component';
import {
    LocationHistoryLayersConfigComponent
} from './components/map-layer-switcher/location-history-layers-config/location-history-layers-config.component';
import {
    GradientFilterContainerComponent
} from './components/map-layer-switcher/gradient-filter-container/gradient-filter-container.component';
import {TimeFilterBarComponent} from './components/time-filter-bar/time-filter-bar.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MapLayerConfigurationComponent} from './components/map-layer-configuration/map-layer-configuration.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import {UserRoleFilterComponent} from './components/user-role-filter/user-role-filter.component';
import { RouteStatusModeLegendComponent } from './components/map-layer-switcher/route-status-mode-legend/route-status-mode-legend.component';
import { TrafficLegendComponent } from './components/map-layer-switcher/traffic-legend/traffic-legend.component';
import { GpsTracksLegendComponent } from './components/map-layer-switcher/gps-tracks-legend/gps-tracks-legend.component';
import { WeatherLegendComponent } from './components/map-layer-switcher/weather-legend/weather-legend.component';
import { CurrencyLegendComponent } from './components/map-layer-switcher/currency-legend/currency-legend.component';
import { CoverageLegendComponent } from './components/map-layer-switcher/coverage-legend/coverage-legend.component';
import { WeatherNewsComponent } from './components/navigation/weather-news/weather-news.component';
import { WeatherForecastComponent } from './components/weather-forecast/weather-forecast.component';
import {PointEditorComponent} from './components/point-editor/point-editor.component';
import { WeatherWarningComponent } from './components/weather-warning/weather-warning.component';
import {FilterCompositeComponent} from './components/filter-bar-composite/composites/filter-composite.component';
import {TextSearchCompositeComponent} from './components/filter-bar-composite/composites/text-search-composite';
import {CompositeFilterBarComponent} from './components/filter-bar-composite/composite-filter-bar.component';
import { LocationHistoryCategoryViewComponent } from './components/map-layer-switcher/location-history-category-view/location-history-category-view.component';
import { LineStyleEditorComponent } from './components/map-layer-switcher/line-style-editor/line-style-editor.component';
import {DropdownCompositeComponent} from './components/filter-bar-composite/composites/dropdown-composite';
import { LineTypeSelectorComponent } from './components/line-type-selector/line-type-selector.component';
import { LineDashArrayViewComponent } from './components/line-type-item/line-dash-array-view.component';

@NgModule({
  declarations: [
    NavigationComponent,
    DriverStatsCardComponent,
    VehicleStatsCardComponent,
    ExploreCardComponent,
    ShiftDetailCardComponent,
    BlankPageComponent,
    MenuToggleButtonComponent,
    CardLoadingSpinnerComponent,
    DateFilterDropdownComponent,
    MapPanelToggleButtonComponent,
    SettingsSectionHeaderComponent,
    MapPreviewComponent,
    SimpleDialogComponent,
    ConfirmDeleteShiftDialogComponent,
    VehicleGroupFilterComponent,
    VideoPlayerComponent,
    SideMenuComponent,
    ActionMenuItemComponent,
    VerticalIconButtonComponent,
    ObservationGroupFilterComponent,
    ActionMenuItem2LineComponent,
    TenantPublicMapComponent,
    AssetStatusFilterComponent,
    HardwareFilterComponent,
    FilterBarComponent,
    MapLayerSwitcherComponent,
    CustomLayersConfigComponent,
    LocationHistoryLayersConfigComponent,
    GradientFilterContainerComponent,
    TimeFilterBarComponent,
    MapLayerConfigurationComponent,
    ImageViewerComponent,
    UserRoleFilterComponent,
    RouteStatusModeLegendComponent,
    TrafficLegendComponent,
    GpsTracksLegendComponent,
    WeatherLegendComponent,
    CurrencyLegendComponent,
    CoverageLegendComponent,
    WeatherNewsComponent,
    WeatherForecastComponent,
    PointEditorComponent,
    WeatherWarningComponent,
    CompositeFilterBarComponent,
    FilterCompositeComponent,
    TextSearchCompositeComponent,
    DropdownCompositeComponent,
    LocationHistoryCategoryViewComponent,
    LineStyleEditorComponent,
    LineTypeSelectorComponent,
    LineDashArrayViewComponent,
  ],
    exports: [
        CommonModule,
        NavigationComponent,
        DriverStatsCardComponent,
        VehicleStatsCardComponent,
        ExploreCardComponent,
        ShiftDetailCardComponent,
        MapPanelToggleButtonComponent,
        BlankPageComponent,
        MenuToggleButtonComponent,
        MapViewerModule,
        FormattingModule,
        DateFilterDropdownComponent,
        CardLoadingSpinnerComponent,
        SettingsSectionHeaderComponent,
        MapPreviewComponent,
        SimpleDialogComponent,
        ConfirmDeleteShiftDialogComponent,
        VehicleGroupFilterComponent,
        VideoPlayerComponent,
        SideMenuComponent,
        ActionMenuItemComponent,
        VerticalIconButtonComponent,
        ActionMenuItem2LineComponent,
        TenantPublicMapComponent,
        FilterBarComponent,
        HardwareFilterComponent,
        MapLayerSwitcherComponent,
        TimeFilterBarComponent,
        ColorPickerModule,
        MapLayerConfigurationComponent,
        ImageViewerComponent,
        UserRoleFilterComponent,
        PointEditorComponent,
        FilterCompositeComponent,
        CompositeFilterBarComponent,
        TextSearchCompositeComponent,
        DropdownCompositeComponent,
        LineTypeSelectorComponent,
        LineDashArrayViewComponent,
    ],
  imports: [
        FormattingModule,
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        MatMomentDateModule,
        MapViewerModule,
        ReactiveFormsModule,
        ColorPickerModule,
        NgOptimizedImage,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SharedModule {}
