import {Router} from '@angular/router';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShiftsService} from '../../../../data/shifts/shifts.service';
import {DomSanitizer} from '@angular/platform-browser';
import moment from 'moment';
import {ImageGenerationStatus, ShiftWithDriverAndVehicleModel} from '../../../models/shift.model';
import {environment} from '../../../../../environments/environment';
import {ConfigurationModel} from '../../../models/configuration.model';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDeleteShiftDialogComponent} from '../../dialogs/confirm-delete-shift-dialog/confirm-delete-shift-dialog.component';
import {SecurityService} from '../../../../security/security.service';
import {ToastService} from '../../../services/toast.service';
import {InsightsRoute} from '../../../../pages/insights/insights-routing.module';

@Component({
  selector: 'app-shift-detail-card',
  templateUrl: './shift-detail-card.component.html',
  styleUrls: ['./shift-detail-card.component.scss'],
})
export class ShiftDetailCardComponent implements OnInit {
  @Input() configuration: ConfigurationModel;
  @Input() shift: ShiftWithDriverAndVehicleModel;
  @Input() source = 'vehicles';
  @Input() token: string = null;
  @Output() shiftDeleted = new EventEmitter<number>();
  imageData: any = null;
  isLoading = true;
  isAdmin = false;

  constructor(
    private router: Router,
    private securityService: SecurityService,
    private shiftService: ShiftsService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();

    if (this.shift.imageGenerationStatus === ImageGenerationStatus.GENERATED) {
      // image is generated, use the cached one
      this.imageData = `${environment.services.service}v1/shift/${this.shift.id}/cached-track-thumbnail?access_token=${this.token}`;
      this.isLoading = false;
    } else if (this.shift.imageGenerationStatus !== ImageGenerationStatus.NOT_GENERATED) {
      // the generation failed, display empty
      this.imageData = null;
      this.isLoading = false;
    } else {
      // image for the shift was not generated yet
      this.shiftService.getShiftTrackThumbnail(this.shift.id, 720, 360).then((response) => {
        this.imageData = this.domSanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(response)
        );
        this.isLoading = false;
      }).catch((message) => {
        console.log(message);
        this.isLoading = false;
      });
    }
  }

  getDuration() {
    const end = moment(this.shift.end);
    const start = moment(this.shift.start);
    // @ts-ignore
    return moment.duration(end - start).humanize();
  }

  viewShift() {
    this.router.navigate([`/shift-detail/${this.shift.id}`], {
      queryParams: {
        source: InsightsRoute.SHIFT_LOOKUP,
      },
    });
  }

  canDeleteShift() {
    return this.isAdmin;
  }

  confirmDeletingShift() {
    const dialogRef = this.dialog.open(ConfirmDeleteShiftDialogComponent, {
      data: this.shift,
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.shiftService.deleteShift(this.shift.id)
            .then(() => {
              this.shiftDeleted.emit(this.shift.id);
            })
            .catch(error => {
              console.error(error);
              this.toast.longer('Deleting Shift failed!');
            });
      }
    });
  }
}
