import {Component, OnDestroy, OnInit} from '@angular/core';
import {AssetRightPanel} from '../../assets/asset-right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../../data/assets/assets-manager.service';
import {ToastService} from '../../../../../../shared/services/toast.service';
import {MessagesService} from '../../../../../../data/messages/messages.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {MessageRecipientModel, MessageRecipientType} from '../../../../models/message-recipient.model';

@Component({
  selector: 'app-quick-message',
  templateUrl: './quick-message.component.html',
  styleUrls: ['./quick-message.component.scss']
})
export class QuickMessageComponent extends AssetRightPanel implements OnInit, OnDestroy {

  result: string;
  isSending = false;

  formGroup = this.formBuilder.group({
    subject: new FormControl(''),
    body: new FormControl(''),
    recipients: new FormControl([]),
  });

  constructor(
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private messagesService: MessagesService,
      private formBuilder: FormBuilder,
      private toast: ToastService,
  ) {
    super(
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
  }

  ngOnInit() {
    this.initializeAssets();
    this.loadConfiguration();
  }

  ngOnDestroy() {
    this.configSubscription?.unsubscribe();
  }

  onAssetChange() {
    this.result = null;
    if (!!this.asset) {
      this.resetForm();
    }
  }

  onConfigurationLoad() {
    // do nothing
  }

  sendMessage() {
    this.isSending = true;
    // @ts-ignore
    this.messagesService.sendMessage(this.formGroup.value).then((response) => {
      const msg = 'Message sent successfully.';
      this.result = msg;
      this.resetForm();
      this.toast.short(msg);
      this.isSending = false;
    }).catch((error: Error) => {
      console.error(error);
      const msg = 'Failed to send a message.';
      this.result = msg;
      this.toast.longer(msg);
      this.isSending = false;
    });
  }

  private getRecipients(): MessageRecipientModel[] {
    if (!!this.asset) {
      return [
        {id: this.asset.id, type: MessageRecipientType.VEHICLE, name: this.asset.name}
      ];
    } else {
      return [];
    }
  }

  private resetForm() {
    this.formGroup.reset({
      subject: '',
      body: '',
      recipients: this.getRecipients(),
    });
  }
}
