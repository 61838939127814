import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {VehicleModelWithActiveShift} from '../../../shared/models/vehicle.model';
import {VehicleCategoryModel} from '../../../shared/models/vehicle';
import {ShiftWithDriverAndVehicleModel} from '../../../shared/models/shift.model';
import {DriverModel} from '../../../shared/models/driver.model';
import {MessageDeliveryDto, MessageModel} from '../models/message.model';
import {MessageRecipientCollectionModel} from '../models/message-recipient-collection.model';
import {RouteConfigurationWithSchema} from '../../../shared/models/route';

@Injectable({
    providedIn: 'root',
})
export class LiveMapDataService {

    private vehicleCategoriesSource = new BehaviorSubject<VehicleCategoryModel[]>([]);
    readonly vehicleCategories$ = this.vehicleCategoriesSource.asObservable();

    private vehiclesSource = new BehaviorSubject<VehicleModelWithActiveShift[]>([]);
    vehicles$ = this.vehiclesSource.asObservable();

    private shiftInfoSource = new BehaviorSubject<ShiftWithDriverAndVehicleModel>(null);
    shiftInfo$ = this.shiftInfoSource.asObservable();

    private driversSource = new BehaviorSubject<DriverModel[]>([]);
    drivers$ = this.driversSource.asObservable();

    private recentMessagesSource = new BehaviorSubject<MessageModel[]>([]);
    recentMessages$ = this.recentMessagesSource.asObservable();

    private archivedMessagesSource = new BehaviorSubject<MessageModel[]>([]);
    archivedMessages$ = this.archivedMessagesSource.asObservable();

    private availableRecipientsSource = new BehaviorSubject<MessageRecipientCollectionModel>(null);
    availableRecipients$ = this.availableRecipientsSource.asObservable();

    private rightPanelWidthSource = new BehaviorSubject<number>(10);
    rightPanelWidth$ = this.rightPanelWidthSource.asObservable();

    private routeConfigurationSource = new BehaviorSubject<RouteConfigurationWithSchema[]>([]);
    routeConfiguration$ = this.routeConfigurationSource.asObservable();

    sendVehicleCategories(data: VehicleCategoryModel[]) {
        this.vehicleCategoriesSource.next(data);
    }

    sendVehicles(data: VehicleModelWithActiveShift[]) {
        this.vehiclesSource.next(data);
    }

    sendShiftInfo(data: ShiftWithDriverAndVehicleModel) {
        this.shiftInfoSource.next(data);
    }

    sendDrivers(data: DriverModel[]) {
        this.driversSource.next(data);
    }

    sendRecentMessages(data: MessageModel[]) {
        this.recentMessagesSource.next(data);
    }

    sendArchivedMessages(data: MessageModel[]) {
        this.archivedMessagesSource.next(data);
    }

    handleMessageRead(messageReadEvent) {
        const recentMessages = this.recentMessagesSource.getValue();
        const message = recentMessages.find((data: MessageModel) => data.id === messageReadEvent.messageId);
        if (message) {
            const values = message.messageDeliveries.filter(
                (delivery: MessageDeliveryDto) => delivery.driverId === messageReadEvent.driverId
            );
            values.forEach(value => value.seenAt = messageReadEvent.seenAt);
        }
        this.sendRecentMessages(recentMessages);
    }

    handleMessageSent(message: MessageModel) {
        const recentMessages = this.recentMessagesSource.getValue();
        recentMessages.unshift(message);
        this.sendRecentMessages(recentMessages);
    }

    sendAvailableRecipients(data: MessageRecipientCollectionModel) {
        this.availableRecipientsSource.next(data);
    }

    sendRightPanelWidth(data: number) {
        this.rightPanelWidthSource.next(data);
    }

    sendRouteConfiguration(data: RouteConfigurationWithSchema[]) {
        this.routeConfigurationSource.next(data);
    }
}
