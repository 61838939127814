import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterSetting} from '../../../../configuration/model/LayerTypeFilter';


@Component({
  selector: 'app-line-style-editor',
  templateUrl: './line-style-editor.component.html',
  styleUrls: ['./line-style-editor.component.scss'],
})
export class LineStyleEditorComponent implements OnInit {

    @Input() lineStyle: FilterSetting;
    @Output() lineStyleChange = new EventEmitter<FilterSetting>();

    allDashArrays: number[][] = [
      [5, 4],
      [2, 4],
      [1, 4],
      [],
      [4, 5, 1, 5],
    ];
    selectedDashArray: number[] = [];

    protected readonly Math = Math;

    ngOnInit() {
        this.selectedDashArray = Array.from(!this.lineStyle.dashArray ? this.allDashArrays[3] : this.lineStyle.dashArray);
    }

    selectDashArray(index: number) {
        this.selectedDashArray = Array.from(this.allDashArrays[index]);
        this.lineStyle.dashArray = this.selectedDashArray.length === 0 ? undefined : Array.from(this.selectedDashArray);
        this.lineStyleChange.emit(this.lineStyle);
    }

    onColorChange() {
        this.lineStyleChange.emit(this.lineStyle);
    }

    arraysEqual(a: number[], b: number[]) {
        if (a === b) { return true; }
        if (a == null || b == null) { return false; }
        if (a.length !== b.length) { return false; }

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) { return false; }
        }
        return true;
    }
}
