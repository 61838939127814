import {inject} from '@angular/core';
import {SecurityService} from './security.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {FeatureFlagEnum} from '../shared/models/configuration.model';
import {ConfigurationService} from '../configuration/configuration.service';

export const adminGuard = () => {
    const securityService = inject(SecurityService);
    return securityService.isAdmin();
};

export const oldInspectionForms = () => {
  const configurationService = inject(ConfigurationService);
  return new Promise<boolean>((resolve, reject) => {
    if (!configurationService || !configurationService.sharedConfigurationModel) {
      resolve(false);
    }
    configurationService.sharedConfigurationModel.subscribe(model => {
      if (model && model.featureFlags) {
        const enabled = configurationService.hasFeatureFlag(model.featureFlags, FeatureFlagEnum.CartegraphIntegration);
        const useNewDvir = configurationService.hasFeatureFlag(model.featureFlags, FeatureFlagEnum.CartegraphIntegrationNewDvir);
        resolve(enabled && !useNewDvir);
      } else {
        // unfortunately this is initiated in ngOnInit in layout, but we need it earlier
        configurationService.refreshConfiguration();
      }
    });
  });
};

export const newInspectionForms = () => {
  return new Promise<boolean>((resolve, reject) => {
    oldInspectionForms().then(x => resolve(!x)).catch(x => resolve(false));
  });
};

