import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { CloseSidenav, OpenSidenav } from '../../../../pages/live-map/store/live-map.actions';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-map-panel-toggle-button',
  templateUrl: './map-panel-toggle-button.component.html',
  styleUrls: ['./map-panel-toggle-button.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class MapPanelToggleButtonComponent implements OnInit, OnDestroy {
  navSubscription: Subscription;
  sidenavOpen?: boolean;

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.navSubscription = this.store.subscribe(
      (state) => (this.sidenavOpen = state.ui.sidenavOpen)
    );
  }

  ngOnDestroy() {
    this.navSubscription.unsubscribe();
  }

  toggleMenu() {
    this.sidenavOpen
      ? this.store.dispatch(new CloseSidenav())
      : this.store.dispatch(new OpenSidenav());
  }
}
