import { Component } from '@angular/core';

@Component({
  selector: 'app-traffic-legend',
  templateUrl: './traffic-legend.component.html',
  styleUrls: ['./traffic-legend.component.scss']
})
export class TrafficLegendComponent {

}
