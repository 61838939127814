export class RouteAssignment {
    id: number;
    created: Date;
    deleted: Date;
    completed: Date;
    onAssignmentFrom: Date;
    configId: number;
    routeId: number;
    routeName: string;
    vehicleId: number;
    shiftId: number;
    queueOrder: number;
    distanceDriven: number; // meters
    timeDriven: number; // seconds
}

export class VehicleRouteAssignmentStatus {
    vehicleId: number;
    status: RouteAssignmentStatus;
    routeAssignment?: RouteAssignment;
}

export enum RouteAssignmentStatus {
    NO_ASSIGNMENT = 'NO_ASSIGNMENT',
    REQUESTED = 'REQUESTED',
    PENDING = 'PENDING',
    ON_ASSIGNMENT = 'ON_ASSIGNMENT',
}

export enum RouteAssignmentQueue {
    IMMEDIATE = 'IMMEDIATE',
    NEXT = 'NEXT',
    END = 'END',
}
