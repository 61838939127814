import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {VehiclesService} from '../../../../data/vehicles/vehicles.service';
import {ObservationManagementService} from '../../../../data/observations/observation-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VehicleImport} from '../../../../shared/models/vehicle-import';


@Component({
    selector: 'app-import-vehicles',
    templateUrl: './import-vehicles.component.html',
    styleUrls: ['./import-vehicles.component.scss'],
})
export class ImportVehiclesComponent {

    @Output() changePageEvent = new EventEmitter<string>();

    uiError: string;

    selectedFile: File;
    validData: VehicleImport[];
    invalidData: VehicleImport[];
    displayedColumnsValid: string[];
    displayedColumnsInvalid: string[];
    allColumns: {
        title: string;
        valid: boolean;
        invalid: boolean;
    }[] = [
        {title: 'line', valid: true, invalid: true},
        {title: 'name', valid: true, invalid: true},
        {title: 'mapLabel', valid: true, invalid: true},
        {title: 'licensePlate', valid: true, invalid: true},
        {title: 'vGroup', valid: true, invalid: true},
        {title: 'action', valid: true, invalid: false},
        {title: 'errors', valid: false, invalid: true},
    ];
    ImportVehiclesStateEnum = ImportVehiclesStateEnum;
    currentState = ImportVehiclesStateEnum.NOOP;

    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(public dialog: MatDialog,
                private snackBar: MatSnackBar,
                private observationManagementService: ObservationManagementService,
                private vehicleService: VehiclesService) {

        this.updateColumns();
    }

    handleFileInput(event: Event) {
        this.selectedFile = (event.target['files'] as FileList)[0];
    }

    validateDataToImport() {
        this.currentState = ImportVehiclesStateEnum.WORKING;
        const formData: FormData = new FormData();
        formData.append('file', this.selectedFile, this.selectedFile.name);
        this.vehicleService.validateVehicleImport(formData).then(response => {
            this.validData = response.data.filter(row => row.errors == null || row.errors.length === 0);
            this.invalidData = response.data.filter(row => row.errors != null && row.errors.length > 0);
            this.currentState = ImportVehiclesStateEnum.NOOP;
        }).catch(error => {
            this.uiError = error;
        }).finally(() => {
            this.currentState = ImportVehiclesStateEnum.NOOP;
        });
    }

    changePage(page?: string) {
        this.changePageEvent.emit(page);
    }

    cancelImport() {
        this.doCancel();
        this.changePage();
    }

    doImport() {
        this.currentState = ImportVehiclesStateEnum.WORKING;
        this.vehicleService.importVehicles(this.validData).then((response) => {
                this.currentState = ImportVehiclesStateEnum.IMPORTED;
        }).catch(error => {
            this.uiError = error;
            this.currentState = ImportVehiclesStateEnum.NOOP;
        });
    }

    doCancel() {
        this.currentState = ImportVehiclesStateEnum.WORKING;
        this.validData = [];
        this.invalidData = [];
        this.fileInput.nativeElement.value = '';
        this.selectedFile = undefined;
        this.currentState = ImportVehiclesStateEnum.NOOP;
    }

    importDisabled(): boolean {
        return this.validData.filter(row => row.action !== 'NONE').length === 0;
    }

    enhancedFormatChanged() {
        this.currentState = ImportVehiclesStateEnum.WORKING;
        this.validData = [];
        this.invalidData = [];
        this.updateColumns();
        this.currentState = ImportVehiclesStateEnum.NOOP;
    }

    private updateColumns() {
        this.displayedColumnsValid = this.allColumns
            .filter(col => col.valid === true)
            .map(col => col.title);

        this.displayedColumnsInvalid = this.allColumns
            .filter(col => col.invalid === true)
            .map(col => col.title);
    }

}

enum ImportVehiclesStateEnum {
    NOOP,
    WORKING,
    IMPORTED
}
