<div class="message-top-bar">
  <button
          class="back__btn"
          (click)="closeMsgWindow()"
          mat-icon-button
  >
    <mat-icon class="action-icon">arrow_back</mat-icon>
  </button>
</div>

<div class="message-status-box">
  <div *ngIf="isSending">
    <mat-spinner [diameter]="24"></mat-spinner> Message is being sent.
  </div>
  <mat-error class="no-recipients-error" *ngIf="!this.isRecipientPresent()">
    <mat-icon>warning_amber</mat-icon>
    <span
      >No active recipients at the moment, please wait for a driver to start a
      shift</span
    >
  </mat-error>
</div>

<div class="message-form">
  <h2>
    New Message
  </h2>

  <mat-form-field class="message__input" appearance="outline">
    <mat-label>Recipients</mat-label>
    <mat-chip-grid #chipGrid aria-label="Recipients selection" required>
      <mat-chip-row
              *ngFor="let recipient of formData.recipients"
              [editable]="selectable"
              [removable]="removable"
              (removed)="remove(recipient)"
      >
        <div class="recipient-chip">
          <mat-icon>{{ getIconName(recipient.type) }}</mat-icon>
          <span>{{ recipient.name }}</span>
        </div>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
      <input
              placeholder="{{ getRecipientsPlaceholder() }}"
              #recipientInput
              [formControl]="recipientsForm"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-grid>
    <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
    >
      <mat-optgroup
              *ngFor="let recipientType of filteredRecipients | async"
              [label]="recipientType.type"
      >
        <mat-option
                *ngFor="let recipient of recipientType.recipients"
                [value]="recipient"
        >
          {{ recipient.name }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="message__input w-100" appearance="outline">
    <mat-label>Subject:</mat-label>
    <input
            matInput
            autocomplete="off"
            [(ngModel)]="formData.subject"
            placeholder="Your message subject..."
            #ngSubject="ngModel"
            minlength="2"
            maxlength="500"
            required
    />
  </mat-form-field>
  <mat-form-field class="message__input w-100" appearance="outline">
    <mat-label>Message:</mat-label>
    <textarea
            matInput
            autocomplete="off"
            rows="15"
            placeholder="Your message text..."
            [(ngModel)]="formData.body"
            #ngBody="ngModel"
            minlength="2"
            maxlength="2000"
            required
    ></textarea>
  </mat-form-field>
  <div class="action__row">
    <button
            color="accent"
            mat-button
            (click)="sendMessage()"
            [disabled]="
          ngSubject.invalid || ngBody.invalid || !formData.recipients.length
        "
    >
      Send
    </button>
  </div>
</div>
