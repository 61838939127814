<div>
  <p class="mat-body">Odometer: {{inspection.odometer}}</p>

  <table mat-table [dataSource]="inspection.answers" class="mat-body">

    <ng-container matColumnDef="check">
      <th mat-header-cell *matHeaderCellDef class="mat-body"> Check </th>
      <td mat-cell *matCellDef="let element"> {{element.questionText}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="mat-body"> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.answer | passFail}} </td>
    </ng-container>

    <ng-container matColumnDef="explained">
      <th mat-header-cell *matHeaderCellDef class="mat-body"> Explained </th>
      <td mat-cell *matCellDef="let element"> {{element.detailedMessage}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</div>

