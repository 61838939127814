import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MultiSelectComponent, MultiSelectFilter} from '../../../../../../shared/components/multi-select-component';
import {AssetStatus} from '../../../../../../shared/models/asset-status';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-status-filter-button',
  templateUrl: './status-filter-button.component.html',
  styleUrls: ['./status-filter-button.component.scss']
})
export class StatusFilterButtonComponent extends MultiSelectComponent<AssetStatus> implements OnInit {

  SETTINGS_KEY = 'selectedAssetStatusIds';
  OPERATOR_SETTINGS_KEY = null;
  URL_PARAM_NAME = 'assetStatusIds';
  URL_OP_NAME = null;
  USE_FULLY_CHECKED_AS_DEFAULT = true;

  @Input() origin;
  @Input() disabled = false;
  @Output() filterChanged = new EventEmitter<MultiSelectFilter<AssetStatus>>();

  filteredItems: AssetStatus[];

  constructor(
      protected router: Router,
      protected route: ActivatedRoute,
      private toast: ToastService,
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.initComponent(AssetStatus.getAll());
    this.filteredItems = this.selected?.slice();
  }

  getLabel(item: AssetStatus): string {
    return item.label;
  }

  onApply() {
    this.filteredItems = this.selected?.slice();
  }

  openMenu() {
    // not implemented
  }

  getSelectedList(): string {
    if (this.filteredItems.length > 3) {
      return 'Multiple Statuses Selected';
    } else {
      return this.filteredItems.map(group => this.getLabel(group)).join(', ');
    }
  }
}
