<ng-container *ngIf="!!configuration && !!asset && !!asset.shiftId">
    <ng-container *ngIf="!assigningMode">
        <div class="assign-button-wrapper" *ngIf="isAdmin">
            <button
                    mat-raised-button
                    class="assign-button"
                    color="accent"
                    (click)="toggleAssigningMode()"
            >
                Create New Assignment
            </button>
        </div>

        <div *ngIf="!!items && items.length > 0">
            <ng-container *ngFor="let item of items">
                <app-action-menu-item [input]="item"></app-action-menu-item>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="assigningMode">
        <div class="select-header">
            <span class="headline">Select Route</span>
            <button mat-icon-button
                    (click)="toggleAssigningMode()"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <app-route-selector (itemSelected)="onRouteSelected($event)"></app-route-selector>

        <div class="buttons-wrapper" *ngIf="!!selectedRoute">
            <button mat-raised-button
                    color="primary"
                    class="assign-button"
                    (click)="assignRoute(RouteAssignmentQueue.END)"
                    *ngIf="!!items && items.length >= 1"
            >
                Add to End of Queue
            </button>

            <button mat-raised-button
                    color="primary"
                    class="assign-button"
                    (click)="assignRoute(RouteAssignmentQueue.NEXT)"
                    *ngIf="!!items && items.length >= 2"
            >
                Make Next in Queue
            </button>

            <button mat-raised-button
                    *ngIf="getCurrentAssignmentInProgressIfAny()"
                    [color]="showConfirmDialog ? 'accent' : 'primary'"
                    class="assign-button"
                    (click)="confirm()"
            >
                Start Immediately
            </button>

            <button mat-raised-button
                    *ngIf="!getCurrentAssignmentInProgressIfAny()"
                    color="primary"
                    class="assign-button"
                    (click)="assignRoute(RouteAssignmentQueue.IMMEDIATE)"
            >
                Add to Queue
            </button>
        </div>

        <div class="buttons-wrapper" *ngIf="showConfirmDialog">
            <span>The current assignment {{ getCurrentAssignmentInProgressIfAny()?.routeName }} will be closed and driver will be instructed to proceed to this route immediately. Do you wish to proceed?</span>
            <div class="confirm-wrapper">
                <button mat-raised-button
                        color="primary"
                        class="confirm-button"
                        (click)="cancelConfirmation()"
                >
                    Cancel
                </button>
                <button mat-raised-button
                        color="accent"
                        class="confirm-button"
                        (click)="assignRoute(RouteAssignmentQueue.IMMEDIATE)"
                >
                    Continue
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>

<div *ngIf="!!asset && !asset.shiftId">
    <span>Asset is offline</span>
</div>

<div *ngIf="!asset">
    <span>Select asset in left panel</span>
</div>
