import {Injectable} from '@angular/core';
import {VehiclesManagerService} from '../vehicles/vehicles-manager.service';
import {Observation, ObservationType} from '../../shared/models/observation';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ServicesSocketService} from '../websocket/services-socket.service';
import {ObservationEvent} from '../websocket/model/observation.event';
import SortedList from '@warren-bank/node-sortedlist';
import {ObservationsService} from './observations.service';
import {ObservationModel, ObservationTypeModel} from '../../shared/models/observation.model';
import {LatLngModel} from '../../shared/models/lat.lng.model';
import {LocationModel} from '../../shared/models/location.model';
import {ObservationFilterUpdate} from '../../shared/models/observations-filter';
import Timeout = NodeJS.Timeout;
import {ObservationTypeGroup} from '../../shared/models/observation-group';
import {DriversManagerService} from '../drivers/drivers-manager.service';
import {ToastService} from '../../shared/services/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LiveMapTab} from '../../pages/live-map/models/live-map-tabs';
import {AssetsManagerService} from '../assets/assets-manager.service';
import {DateFilter} from '../../shared/models/DateFilter';
import {ShiftWithDriverAndVehicleModel} from '../../shared/models/shift.model';
import {DrawerContent} from '../../layouts/right-drawer/right-drawer.component';


@Injectable({
  providedIn: 'root'
})
export class ObservationsManagerService {
  static readonly LIST_ACTION_SOURCE = 'panel';
  static readonly MAP_SOURCE = 'map';
  static readonly URL_SOURCE = 'url';

  private isInitialized = false;
  private highlightedObservationId: number = null;
  private shift: ShiftWithDriverAndVehicleModel = null;

  private readonly observationTypeGroupsMap = new Map<number, ObservationTypeGroup>();
  private readonly observationTypesMap = new Map<number, ObservationType>();
  private readonly observationsMap = new Map<number, Observation>();
  /**
   * Sorted in ascending order, first item will expire the soonest
   */
  private readonly observationsByDate = SortedList.create({
    compare: Observation.observationDateCompare,
    filter: (observation => {
      const now = new Date().getTime();
      // filter out expired observations (only for live map)
      return !!this.shift || observation.expiration === null || observation.expiration.getTime() > now;
    })
  });
  private observationsRemovalTimer: Timeout;

  private readonly openSubscriptions = new Array<Subscription>();

  private filteredObservationsSource = new BehaviorSubject<Observation[]>([]);
  public filteredObservations$ = this.filteredObservationsSource.asObservable();

  private highlightedObservationSource = new BehaviorSubject<ObservationFilterUpdate>(new ObservationFilterUpdate(null, null));
  readonly highlightedObservation$ = this.highlightedObservationSource.asObservable();

  // filters
  private observationTypeGroupFilter: number[] = null;
  private shiftFilter: number[] = null;
  private dateFilter: DateFilter = new DateFilter(null, null);

  constructor(private vehiclesManagerService: VehiclesManagerService,
              private assetManager: AssetsManagerService,
              private driverManagerService: DriversManagerService,
              private observationsService: ObservationsService,
              private serviceSocketService: ServicesSocketService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toast: ToastService) {
  }

  public init(
      shift: ShiftWithDriverAndVehicleModel,
      observationTypes: ObservationTypeModel[],
      observationTypeGroups: ObservationTypeGroup[]
  ) {
    if (this.isInitialized) {
      throw Error('The ObservationsManagerService has already been initialized.');
    }
    this.shift = shift;

    // load observation type groups
    for (const observationTypeGroup of observationTypeGroups) {
      this.addObservationTypeGroup(observationTypeGroup);
    }

    // load observation types
    for (const observationType of observationTypes) {
      this.addObservationType(new ObservationType(
          observationType.id,
          observationType.title,
          !!observationType.abbreviation ? observationType.abbreviation : observationType.title,
          observationType.observationTypeGroupId,
      ));
    }
    // add deleted observation type
    this.addObservationType(new ObservationType(
        -1, 'Deleted Observation Type', 'DELETED TYPE', -1
    ));

    // live map initialization
    if (!this.shift) {
      // load observations
      this.loadRecentObservations();

      // listen to observation updates
      const observationSubscription = this.serviceSocketService.onMessage('/observation')
          .subscribe(((observationEvent: ObservationEvent) => {
            if (observationEvent.type === 'new') {
              this.addOneObservation(observationEvent.observation);
            } else if (observationEvent.type === 'update') {
              this.updateObservations([observationEvent.observation]);
            } else {
              // 'delete' event
              this.deleteObservations([observationEvent.observation]);
            }
          }));
      this.openSubscriptions.push(observationSubscription);

      // schedule expiration of observations
      this.rescheduleRemovalOfOutdatedObservations();
    } else {
      // shift map initialization
      this.loadShiftObservations();
    }

    this.isInitialized = true;
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    if (this.observationsRemovalTimer) {
      clearTimeout(this.observationsRemovalTimer);
      this.observationsRemovalTimer = null;
    }

    this.observationTypeGroupsMap.clear();
    this.observationTypesMap.clear();
    this.observationsMap.clear();
    this.observationsByDate.length = 0;

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;

    this.resetFilters();
    this.shift = null;
    this.isInitialized = false;
  }

  public filterByObservationTypeGroup(observationTypeGroupFilter: number[]) {
    this.observationTypeGroupFilter = observationTypeGroupFilter;
    this.onFilterChanged();
  }

  public filterByDate(dateFilter: DateFilter) {
    this.dateFilter = dateFilter;
    this.onFilterChanged();
  }

  public filterByShiftIds(shiftIds: number[]) {
    this.shiftFilter = shiftIds;
    this.onFilterChanged();
  }

  public resetFilters() {
    this.observationTypeGroupFilter = null;
    this.shiftFilter = null;
    this.dateFilter = new DateFilter(null, null);
    this.highlightedObservationId = null;
    this.onHighlightedChanged(null, null);
    this.onFilterChanged();
  }

  public hideAll() {
    this.shiftFilter = [];
    this.onFilterChanged();
  }

  private onFilterChanged() {
    const observations = [];
    this.observationsMap.forEach(observation => {
      if ((!this.observationTypeGroupFilter || this.observationTypeGroupFilter.includes(observation.observationType.observationTypeGroupId)) &&
          (!this.shiftFilter || this.shiftFilter.includes(observation.shiftId)) &&
          (!this.dateFilter.from || this.dateFilter.from.isBefore(observation.location.time)) &&
          (!this.dateFilter.to || this.dateFilter.to.isAfter(observation.location.time))
      ) {
        observations.push(observation);
      }
    });
    this.filteredObservationsSource.next(observations);
  }

  private onObservationsUpdate() {
    this.onFilterChanged();
  }

  public highlightObservation(observationId: number, source: string) {
    this.highlightedObservationId = observationId;
    let observation: Observation;
    if (!!observationId) {
      observation = this.observationsMap.get(observationId);
      if (!observation) {
        console.log(`Unknown observation ID ${observationId}.`);
        console.log('Probably not initialized yet!');
        return;
      }
    }

    if (this.highlightedObservationSource.value.observation !== observation) {
      this.onHighlightedChanged(observation, source);
      if (!!observation && source === ObservationsManagerService.MAP_SOURCE) {
        if (!this.shift) {
          this.router.navigate(['live-map', LiveMapTab.OBSERVATIONS], {
            queryParams: {id: observation.id, scrollToView: true}
          });
        } else {
          this.router.navigate(['shift-detail', this.shift.id], {
            queryParams: {observationId: observation.id, scrollToView: true, drawer: DrawerContent.OBSERVATION},
            queryParamsHandling: 'merge',
          });
        }
      }
    }
  }

  private onHighlightedChanged(highlightedObservation: Observation, source: string) {
    this.highlightedObservationSource.next(
        new ObservationFilterUpdate(highlightedObservation, source)
    );
  }

  private loadShiftObservations() {
    if (!!this.shift) {
      this.observationsService.getObservationsByShiftId(this.shift.id).toPromise().then(response => {
        this.addObservations(response.data);
      }).catch(message => {
        console.error(message);
        this.toast.short('Observations sync error.');
      });
    }
  }

  private loadRecentObservations() {
    this.observationsService.getObservations().toPromise().then(response => {
      this.addObservations(response.data);

      // set active observation if contained in URL params
      const idParam = this.activatedRoute.snapshot.queryParams['id'];
      const activatedTab = this.router.url.split(/[\/?]+/)[2]?.toLowerCase();
      if (activatedTab === LiveMapTab.OBSERVATIONS && !!idParam) {
        this.highlightObservation(+idParam, ObservationsManagerService.URL_SOURCE);
      }
    }).catch(message => {
      console.error(message);
      this.toast.short('Observations sync error.');
    });
  }

  private rescheduleRemovalOfOutdatedObservations() {
    if (this.observationsRemovalTimer) {
      clearTimeout(this.observationsRemovalTimer);
      this.observationsRemovalTimer = null;
    }

    if (this.observationsByDate.length === 0 || this.observationsByDate[0].expiration === null) {
      return;
    }

    const timeTillExpiration = this.observationsByDate[0].expiration.getTime() - new Date().getTime();
    if (timeTillExpiration < 0) {
      this.removeExpiredObservations();
      return;
    }

    const that = this;
    this.observationsRemovalTimer = setTimeout(() => {
      that.removeExpiredObservations();
      this.rescheduleRemovalOfOutdatedObservations();
    }, timeTillExpiration);
  }

  private removeExpiredObservations() {
    const removedObservations = this.observationsByDate.refilter();
    for (const removedObservation of removedObservations) {
      this.observationsMap.delete(removedObservation.id);
    }
    this.onObservationsUpdate();
  }

  public getObservationTypeGroups(): ObservationTypeGroup[] {
    return [...this.observationTypeGroupsMap.values()];
  }

  private addOneObservation(observationModel: ObservationModel) {
    const observation = this.composeObservation(observationModel);
    const addedObservation = this.addObservation(observation);
    if (!!addedObservation) {
      this.onObservationsUpdate();
      this.rescheduleRemovalOfOutdatedObservations();
    }
  }

  private addObservations(observationModels: ObservationModel[]) {
    const observations = [];
    for (const observationModel of observationModels) {
      const observation = this.addObservation(this.composeObservation(observationModel));
      if (!!observation) {
        observations.push(observation);
      }
    }
    this.onObservationsUpdate();
    if (!this.shift) {
      this.rescheduleRemovalOfOutdatedObservations();
    }
  }

  private composeObservation(observationModel: ObservationModel): Observation {
    let observationType = this.observationTypesMap.get(observationModel.typeId);
    if (!observationType) {
      console.warn(`Observation type with id ${observationModel.typeId} not found, probably deleted!`);
      observationType = this.observationTypesMap.get(-1);
    } else {
      observationType.group = this.observationTypeGroupsMap.get(observationType.observationTypeGroupId);
    }

    const vehicle = !!this.shift
        ? this.shift.vehicle
        : this.vehiclesManagerService.getVehicle(observationModel.vehicleId);
    const driver = !!this.shift
        ? this.shift.driver
        : this.driverManagerService.getDriver(observationModel.driverId);

    const {id, timestamp, expiration, shiftId, location: {latitude, longitude, speed, heading}, imageUrl} = observationModel;

    const location = {
      id,
      coords: {lat: latitude, lng: longitude} as LatLngModel,
      time: new Date(timestamp),
      speed,
      heading,
      imageUrl
    } as LocationModel;
    return new Observation(
        observationModel.id,
        shiftId,
        vehicle,
        driver,
        observationType,
        location,
        expiration ? new Date(expiration) : null
    );
  }

  private updateObservations(observations: ObservationModel[]) {
    for (const observation of observations) {
      this.updateObservation(this.composeObservation(observation));
    }
    this.onObservationsUpdate();
  }

  private deleteObservations(observations: ObservationModel[]) {
    for (const observation of observations) {
      this.observationsMap.delete(observation.id);
    }
    this.onObservationsUpdate();
  }

  private addObservationTypeGroup(newObservationTypeGroup: ObservationTypeGroup) {
    if (this.observationTypeGroupsMap.has(newObservationTypeGroup.id)) {
      throw Error(`An observation type group with id ${newObservationTypeGroup.id} already exists.`);
    }

    this.observationTypeGroupsMap.set(newObservationTypeGroup.id, newObservationTypeGroup);
  }

  private addObservationType(newObservationType: ObservationType) {
    if (this.observationTypesMap.has(newObservationType.id)) {
      throw Error(`An observation type with id ${newObservationType.id} already exists.`);
    }

    this.observationTypesMap.set(newObservationType.id, newObservationType);
  }

  private addObservation(newObservation: Observation): Observation {
    if (!this.observationTypesMap.has(newObservation.observationType.id)) {
      throw new Error(`Unregistered observation type ${newObservation.observationType.id}.`);
    }
    if (this.observationsMap.has(newObservation.id)) {
      throw new Error(`An observation with id ${newObservation.id} already exists.`);
    }
    if (!this.shift && ObservationType.isStationaryAlert(newObservation.observationType)) {
      // ignoring stationary alerts
      return null;
    }

    const inserted = this.observationsByDate.insertOne(newObservation) !== false;
    if (inserted) {
      this.observationsMap.set(newObservation.id, newObservation);
      return newObservation;
    }
    return null;
  }

  // update observation - image uploaded
  private updateObservation(newObservation: Observation) {
    if (this.observationsMap.has(newObservation.id)) {
      // delete the old one
      const oldObservation = this.observationsMap.get(newObservation.id);
      this.observationsByDate.splice(this.observationsByDate.indexOf(oldObservation), 1);
      // add the new one
      this.observationsMap.set(newObservation.id, newObservation);
    }
  }
}
