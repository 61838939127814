import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SecurityService} from '../../../../security/security.service';

@Component({
  selector: 'app-retrying-image-viewer',
  templateUrl: './retrying-image-viewer.component.html',
  styleUrls: ['./retrying-image-viewer.component.css']
})
export class RetryingImageViewerComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  imageUrl: string;

  @Input()
  alternativeText: string;

  @ViewChild('imageElement') imageElement;

  uniqueImageUrl: string;
  isImageLoaded = false;
  token: string;

  retryTimer = null;

  constructor(private securityService: SecurityService) { }

  ngOnInit(): void {
    this.securityService.getToken().then(accessToken => {
      this.token = accessToken;
      this.uniqueImageUrl = this.imageUrl + `?access_token=${this.token}`;
      this.attachImageHandlers();
    }).catch(error => {
      console.error(error);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.token) {
      this.isImageLoaded = false;
      this.uniqueImageUrl = this.imageUrl + `?access_token=${this.token}`;
    }
  }

  ngOnDestroy() {
    if (!!this.retryTimer) {
      clearTimeout(this.retryTimer);
      this.retryTimer = null;
    }
  }

  private retryToLoadImage() {
    this.uniqueImageUrl = this.imageUrl + `?access_token=${this.token}&t=${encodeURIComponent(new Date().getTime().toString())}`;
  }

  private handleImageNotLoaded() {
    console.warn('Image not loaded.', this.uniqueImageUrl);

    const that = this;
    this.retryTimer = setTimeout(() => {
      that.retryToLoadImage();
    }, 3000);
  }

  private handleImageLoaded() {
    console.log('Image loaded.', this.uniqueImageUrl);
    this.retryTimer = null;
    this.isImageLoaded = true;
  }

  private attachImageHandlers() {
    const that = this;

    const nativeImageElement = this.imageElement.nativeElement;

    nativeImageElement.addEventListener('error', () => {
      that.handleImageNotLoaded();
    });
    nativeImageElement.addEventListener('load', function imageLoadedHandler() {
      that.handleImageLoaded();
    });
  }
}
