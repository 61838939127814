import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ShiftWithDriverAndVehicleModel} from '../../../../shared/models/shift.model';
import {ToastService} from '../../../../shared/services/toast.service';
import {ActionMenuItem} from '../../../../shared/models/action-menu-item.class';
import {Observation} from '../../../../shared/models/observation';
import {ShortDateOrTimePipe} from '../../../../shared/formatting/short-date-or-time.pipe';
import {Subscription} from 'rxjs';
import {ShiftMapDataService} from '../../services/shift-map-data.service';
import {ObservationsManagerService} from '../../../../data/observations/observations-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DrawerContent} from '../../../../layouts/right-drawer/right-drawer.component';

@Component({
  selector: 'app-shift-observations',
  templateUrl: './shift-observations.component.html',
  styleUrls: ['./shift-observations.component.scss']
})
export class ShiftObservationsComponent implements OnInit, OnDestroy {

  @Input() shift: ShiftWithDriverAndVehicleModel;

  observations: Observation[];
  items: ActionMenuItem[];
  selectedObservationId: number = null;

  observationSubscription: Subscription;
  highlightedObservationSubscription: Subscription;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private shiftMapDataService: ShiftMapDataService,
      private observationsManager: ObservationsManagerService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
      private toast: ToastService,
  ) {}


  ngOnInit() {
    this.observationSubscription = this.observationsManager.filteredObservations$.subscribe(observations => {
      this.observations = observations;
      this.items = this.observations.map(observation => this.observationToActionMenuItem(observation));
    });

    // set active observation when coming through link
    this.activatedRoute.queryParams.subscribe((params) => {
      this.selectedObservationId = +params.observationId;
      if (!!params.observationId) {
        this.observationsManager.highlightObservation(
            +params.observationId,
            !!params.scrollToView ? ObservationsManagerService.MAP_SOURCE : ObservationsManagerService.URL_SOURCE
        );
      }
    });

    this.highlightedObservationSubscription = this.observationsManager.highlightedObservation$.subscribe(activeObservation => {
      this.selectedObservationId = activeObservation?.observation?.id;

      if (activeObservation.source === ObservationsManagerService.MAP_SOURCE) {
        // update URL param
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {observationId: this.selectedObservationId, drawer: DrawerContent.OBSERVATION},
          queryParamsHandling: '',
        });
      }
    });
  }

  ngOnDestroy() {
    this.observationSubscription?.unsubscribe();
    this.highlightedObservationSubscription?.unsubscribe();
  }

  private observationToActionMenuItem(observation: Observation): ActionMenuItem {
    return new ActionMenuItem(
        observation.id,
        'stars',
        observation.observationType.title,
        this.shortDateOrTimePipe.transform(observation.location.time),
        '',
        null,
        () => this.selectedObservationId === observation.id,
        null,
        () => this.selectObservation(observation),
        null,
        null,
        [],
    );
  }

  private selectObservation(observation: Observation) {
    this.selectedObservationId = observation.id;
    this.observationsManager.highlightObservation(observation.id, ObservationsManagerService.LIST_ACTION_SOURCE);
  }
}
