import {Component, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartegraphConfiguration} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-manage-cartegraph-import',
  templateUrl: './cartegraph-import.component.html',
  styleUrls: ['./cartegraph-import.component.scss'],
})
export class CartegraphImportComponent implements OnInit {

  isLoading = true;
  uiError: string;

  @Input()
  configuration: CartegraphConfiguration;
  fetchAllData = false;

  constructor(
    private router: Router,
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar
  ) {
  }


  ngOnInit() {
    this.isLoading = false;
  }


  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  importAllData() {
    this.isLoading = true;
    this.cartegraphManagementService.importAllData(this.fetchAllData).then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        // on success
        this.router.navigate(['settings/cartegraph-settings/imports']).then(() => {
          this.showSnackBar('Import is in progress.');
        });
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

}
