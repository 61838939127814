import {AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, ViewChild} from '@angular/core';
import {LngLat, Map as MapLibre, MapMouseEvent, Marker} from 'maplibre-gl';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigurationModel, Point} from '../../models/configuration.model';
import {MapLayersManager} from '../map-viewer/map-layers-manager';

@Component({
  selector: 'app-point-editor',
  templateUrl: './point-editor.component.html',
  styleUrls: ['./point-editor.component.scss']
})
export class PointEditorComponent implements AfterViewInit, OnDestroy {

  configuration: ConfigurationModel;
  @ViewChild('map')
  private mapContainer: ElementRef<HTMLElement>;
  private map: MapLibre;
  private marker: Marker;

  constructor(public dialogRef: MatDialogRef<PointEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Point) {
  }

  ngAfterViewInit() {
    const initialState = {
      lng: -104.9,
      lat: 39.4,
      zoom: 10,
    };

    if (!!this.data) {
      initialState.lat = this.data.y;
      initialState.lng = this.data.x;
    }

    this.map = new MapLibre({
      container: this.mapContainer.nativeElement,
      style: this.configuration.useMetricSystem ? MapLayersManager.ACCUTERRA_OUTDOORS_M_MAP_STYLE : MapLayersManager.ACCUTERRA_OUTDOORS_MAP_STYLE,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
    });

    this.map.on('click', (e) => {
      this.onMapClick(e);
    });

    this.marker = new Marker({draggable: true})
      .setLngLat([initialState.lng, initialState.lat])
      .addTo(this.map);
    this.marker.on('dragend', () => {
      const lngLat = this.marker.getLngLat();
      this.updatePoint(lngLat);
    });

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  private onMapClick(e: MapMouseEvent) {
    this.marker.setLngLat(e.lngLat);
    this.updatePoint(e.lngLat);
  }

  private updatePoint(lngLat: LngLat) {
    this.data = new Point(lngLat.lng, lngLat.lat);
  }

  reset() {
    this.marker.setLngLat([this.configuration.weatherLocation.x, this.configuration.weatherLocation.y]);
    this.data = this.configuration.weatherLocation;
  }
}
