import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WeatherForecast, WeatherUnit, WeatherWarningsLayerService} from '../map-viewer/services/weather-warnings-layer.service';
import {GridInfo} from '../navigation/weather-news/weather-news.component';
import {ConfigurationModel, Point} from '../../models/configuration.model';
import {ToastService} from '../../services/toast.service';
import {PointEditorComponent} from '../point-editor/point-editor.component';
import {SettingsService} from '../../../configuration/settings.service';
import {LngLat} from 'maplibre-gl';
import {Subscription} from 'rxjs';
import {ArcgisApiService} from '../../../data/address-search/arcgis-api.service';

@Component({
  selector: 'app-weather-forecast',
  templateUrl: './weather-forecast.component.html',
  styleUrls: ['./weather-forecast.component.scss']
})
export class WeatherForecastComponent implements OnInit, OnDestroy {

  configuration: ConfigurationModel;
  hourlyData: WeatherForecast[];
  dailyData: WeatherForecast[];
  gridInfo: GridInfo;
  lngLat: LngLat;
  city = '';

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private weatherWarningsService: WeatherWarningsLayerService,
      public dialogRef: MatDialogRef<WeatherForecastComponent>,
      @Inject(MAT_DIALOG_DATA) public data: GridInfo,
      private dialog: MatDialog,
      private toast: ToastService,
      private settingsService: SettingsService,
      private arcgisService: ArcgisApiService,
  ) {
  }

  ngOnInit() {
    const weatherLocationSubscription = this.weatherWarningsService.weatherLocation$.subscribe(weatherLocation => {
      if (!!weatherLocation) {
        this.lngLat = weatherLocation;
        this.city = '';
        this.arcgisService.findAddressOfLocation(this.lngLat).then(response => {
          this.city = response.address.City;
        });
      }
    });
    this.openSubscriptions.push(weatherLocationSubscription);

    const gridInfoSubscription = this.weatherWarningsService.gridInfo$.subscribe(gridInfo => {
      if (!!gridInfo) {
        this.gridInfo = gridInfo;
        this.loadData();
      }
    });
    this.openSubscriptions.push(gridInfoSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
    this.openSubscriptions.length = 0;
  }

  loadData() {
    const debugLevel = false;
    this.weatherWarningsService.getWeatherForecastHourly(
        this.gridInfo.cwa,
        this.gridInfo.gridX,
        this.gridInfo.gridY,
        this.configuration.useMetricSystem ? WeatherUnit.SI : WeatherUnit.US,
    ).toPromise().then(response => {
      if (debugLevel) {
        console.log('Hourly Forecast:');
        console.log('Generated at ' + response.properties.generatedAt);
        console.log(response.properties);
      }
      this.hourlyData = response.properties.periods.slice(0, 24);
      this.hourlyData.forEach(period => {
        // remove percentage parameter if zero because the service is returning http 400
        if (period.icon.includes(',0?')) {
          period.icon = period.icon.replace(',0?', '?');
        }
      });
    }).catch(error => {
      console.log(error);
      this.toast.long('Loading Hourly Forecast failed!');
    });

    this.weatherWarningsService.getWeatherForecast(
        this.gridInfo.cwa,
        this.gridInfo.gridX,
        this.gridInfo.gridY,
        this.configuration.useMetricSystem ? WeatherUnit.SI : WeatherUnit.US,
    ).toPromise().then(response => {
      if (debugLevel) {
        console.log('Daily Forecast:');
        console.log('Generated at ' + response.properties.generatedAt);
        console.log(response.properties);
      }
      this.dailyData = response.properties.periods;
    }).catch(error => {
      console.log(error);
      this.toast.long('Loading Hourly Forecast failed!');
    });
  }

  editLocation() {
    const locationFromSettings = this.settingsService.getStringValue(SettingsService.WEATHER_LOCATION)
        ?.split(',')
        ?.map(value => +value);
    const point = !!locationFromSettings && locationFromSettings.length === 2
        ? new Point(locationFromSettings[0], locationFromSettings[1])
        : this.configuration.weatherLocation;
    const dialog = this.dialog.open(
        PointEditorComponent,
        {
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: '80vw',
          height: '85vh',
          data: point,
        }
    );
    dialog.componentInstance.configuration = this.configuration;
    dialog.afterClosed().subscribe(result => {
      if (result !== undefined && result) {
        if (this.configuration.weatherLocation.x === result.x &&
            this.configuration.weatherLocation.y === result.y
        ) {
          this.settingsService.setStringValue(SettingsService.WEATHER_LOCATION, null);
        } else {
          this.settingsService.setStringValue(
              SettingsService.WEATHER_LOCATION,
              `${result.x},${result.y}`
          );
        }
      }
    });
  }

  getIconUrl(iconUrl: string): string {
    if (iconUrl?.startsWith('/')) {
      // input url is relative
      return `https://api.weather.gov${iconUrl}`;
    } else {
      return iconUrl;
    }
  }
}
