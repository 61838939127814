import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveMapDataService } from '../../../../services/live-map-data.service';
import { MessageModel } from '../../../../models/message.model';
import {ActionMenuItem} from '../../../../../../shared/models/action-menu-item.class';
import {ShortDateOrTimePipe} from '../../../../../../shared/formatting/short-date-or-time.pipe';
import {AssetsManagerService} from '../../../../../../data/assets/assets-manager.service';
import {ObservationsManagerService} from '../../../../../../data/observations/observations-manager.service';

@Component({
  selector: 'app-all-messages',
  templateUrl: './all-messages.component.html',
  styleUrls: ['./all-messages.component.scss'],
})
export class AllMessagesComponent implements OnInit {
  recentMessages: MessageModel[] = [];
  archivedMessages: MessageModel[] = [];

  recentItems: ActionMenuItem[] = [];
  archivedItems: ActionMenuItem[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private liveMapDataService: LiveMapDataService,
    private assetsManager: AssetsManagerService,
    private observationManager: ObservationsManagerService,
    private shortDateOrTimePipe: ShortDateOrTimePipe,
  ) {}

  ngOnInit(): void {
    this.liveMapDataService.recentMessages$.subscribe((messages) => {
      this.recentMessages = messages;
      this.recentItems = messages.map(message => {
        return new ActionMenuItem(
          message.id,
          'message',
          message.subject,
          `${message.messageDeliveries.length} Recipients (${this.seenMessagesCount(message)}/${message.messageDeliveries.length})`,
            this.shortDateOrTimePipe.transform(new Date(message.createdAt)),
          null,
          () => false,
          null,
          () => { this.navigateToSelectedMessage(message); },
          null,
          null,
          [],
        );
      });
    });

    this.liveMapDataService.archivedMessages$.subscribe((messages) => {
      this.archivedMessages = messages;
      this.archivedItems = messages.map(message => {
        return new ActionMenuItem(
            message.id,
            'message',
            message.subject,
            `${this.seenMessagesCount(message)} of ${message.messageDeliveries.length} recipients read`,
            this.shortDateOrTimePipe.transform(new Date(message.createdAt)),
            null,
            () => false,
            null,
            () => { this.navigateToSelectedMessage(message); },
            null,
            null,
            [],
        );
      });
    });

    this.liveMapDataService.sendRightPanelWidth(10);
  }

  navigateToSelectedMessage(selectedMsg: MessageModel) {
    this.router.navigate(['message'], {
      relativeTo: this.activatedRoute,
      queryParams: { id: selectedMsg.id },
      queryParamsHandling: 'merge',
    });
  }

  navigateToNewMessagePanel() {
    this.router.navigate([`live-map/messages/new-message`]);
  }

  seenMessagesCount(message: MessageModel) {
    if (!message.messageDeliveries.length) {
      return 0;
    }

    return message.messageDeliveries.filter((delivery) => delivery.seenAt !== null).length;
  }
}
