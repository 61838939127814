declare function require(path: string): any;

const pkg = require('../../package.json');
const buildVersion = pkg.version;
const domain = 'portal.plowops.com';

export const environment = {
  name: 'prod',
  production: true,
  authentication: {
    authority: `https://${domain}/auth`,
  },
  base_href: '/dashboard/',
  services: {
    location: `https://${domain}/location/`,
    service: `https://${domain}/api/`,
  },
  version: buildVersion
};
