
export class CurrentEditing {

  dirtyControlIdStores: string[] = [];

  isDirty(controlId: string): boolean {
    return this.dirtyControlIdStores.some(value => value === controlId);
  }

  cancel(controlId: string) {
    const dirtyControlIdStoreIndex = this.dirtyControlIdStores.findIndex(value => value === controlId);

    if (dirtyControlIdStoreIndex >= 0) {
      this.dirtyControlIdStores.splice(dirtyControlIdStoreIndex, 1);
    }
  }

  loaded(controlId: string) {
    this.cancel(controlId);
  }

  saved(controlId: string) {
    const dirtyControlIdStoreIndex = this.dirtyControlIdStores.findIndex(value => value === controlId);

    if (dirtyControlIdStoreIndex >= 0) {
      this.dirtyControlIdStores.splice(dirtyControlIdStoreIndex, 1);
    }
  }

  changed(controlId: string) {
    const dirtyControlIdStoreIndex = this.dirtyControlIdStores.findIndex(value => value === controlId);

    if (dirtyControlIdStoreIndex < 0) {
      this.dirtyControlIdStores.push(controlId);
    }
  }
}
