export class CartegraphConfiguration {
  tenantId: number;
  baseUrl: string;
  username: string;
  password: string;
  lastUpdate?: Date;
  settings: CartegraphSettings;

  // @deprecated moved to CartegraphSettings
  importDriverFilter: string;
  // @deprecated moved to CartegraphSettings
  importVehicleFilter: string;
  // @deprecated moved to CartegraphSettings
  importWorkOrderFilter: string;
  // @deprecated moved to CartegraphSettings
  importVehicleEquipmentFilter: string;
  // @deprecated moved to CartegraphSettings
  importVehicleGroupField: string;
  // @deprecated moved to CartegraphSettings
  cgEquipmentAssetType: string;
  // @deprecated moved to CartegraphSettings
  cgStatusCompleted: string;
  // @deprecated moved to CartegraphSettings
  cgInspectionPriority: string;
  // @deprecated moved to CartegraphSettings
  cgInspectionFormSection: string;
  // @deprecated moved to CartegraphSettings
  cgPreInspectionActivityField: string;
  // @deprecated moved to CartegraphSettings
  cgPostInspectionActivityField: string;
  // @deprecated moved to CartegraphSettings
  cgInspectionExcludeFields: string;
}

export class ImportResultRow {
  key: string;
  note?: string;
}

/**
 * this is dynamically assigned properties class
 */
export class BaseObjectClass {
}

/**
 * Note upper case field naming, this is due to accessing the model directly from CG.
 */
export class LaborClass {
  Oid: number;
  Address1Field: string;
  CityField: string;
  DepartmentField: string;
  EmailAddressField: string;
  EntryDateField: Date;
  FirstNameField: string;
  FullNameField: string;
  IDField: string;
  InactiveField: boolean;
  LastModifiedByField: string;
  LastNameField: string;
  MiddleField: string;
  NotesField: string;
  StateField: string;
  SupervisorField: string;
  TitleField: string;
  ZipCodeField: string;

}

/**
 * Note upper case field naming, this is due to accessing the model directly from CG.
 */
export class EquipmentClass {
  Oid: number;
  CityField: string;
  CountyField: string;
  DepartmentField: string;
  DescriptionField: string;
  ClassificationField: string;
  FuelTypeField: string;
  IDField: string;
  InactiveField: boolean;
  LastModifiedByField: string;
  LicenseNumberField: string;
  ManufacturerField: string;
  ModelField: string;
  NotesField: string;
  VendorField: string;
  SerialVINNumberField: string;
  YearField: number;
}

export class CgQuestion {
  oid: number;
  tenantId: number;
  inspectionQuestionId: number;
  metaClassFieldOid: number;
  lookupClass?: string;
  lookupField?: string;
  defaultValue: string;
  parentQuestion?: number;
  layoutFieldId: string;
  displayText: string;
  fieldType: string;
  readOnly: boolean;
  index: number;
  importType: string;
}

export class CgQuestionSelectable extends CgQuestion {
  selected: boolean;
}

export class CgDatasetListRequest {
  dataset: string;
  field: string;
}

export class CgDatasetClassFieldRequest {
  dataClass: string;
}

export class CgDatasetList {
  name: string;
  list: CgDatasetEntry[];
}

export class CgDatasetEntry {
  oid: string;
  label: string;
}

export class CgDatasetClassField {
  oid: string;
  uid: string;
  displayName: string;
  name: string;
}

export class CartegraphSyncTask {
  id: number;
  tenantId: number;
  shiftId: number;
  taskType: CartegraphSyncTaskType;
  created: Date;
  submitted: Date;
  retryCount: number;
  cgResult: string;
  processStatus: CartegraphSyncTaskStatus;
  processMessage: string;
}

export class CgImportTask {
  id: number;
  created: Date;
  finished: Date;
  taskStatus: CartegraphImportTaskStatus;
  taskMessage: string;
}

export enum CartegraphSyncTaskStatus {
  WAITING,
  SUCCESS,
  FAIL
}

export enum CartegraphImportTaskStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

export enum CartegraphSyncTaskType {
  DVIR_PRE_INSPECTION,
  DVIR_POST_INSPECTION,
  SHIFT_END_POSTPROCESS
}

export class CartegraphSyncTaskResult {
  tasks: CartegraphSyncTaskResultTask[];
}

export class CartegraphSyncTaskResultTask {
  type: string;
  cgId: string;
  cgOid: string;
}

export enum CgQuestionType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

// NEW settings model

export class CartegraphSettings {
  processing: CartegraphProcessing;
  features: CartegraphFeatureFlag[];
  taskDefaults: CartegraphTaskDefaults;
  import: CartegraphImportSettings;
  cgEquipmentAssetType: string;
}

export enum ProcessingType {
  UNDEFINED = 'UNDEFINED',
  WINDSORCO = 'WINDSORCO',
  ROSEMOUNTMN = 'ROSEMOUNTMN'
}

export enum CartegraphFeature {
  SEND_SHIFT = 'SEND_SHIFT',
  SEND_PRE_INSPECTION = 'SEND_PRE_INSPECTION',
  SEND_POST_INSPECTION = 'SEND_POST_INSPECTION',
  SEND_OBSERVATION = 'SEND_OBSERVATION',
  SEND_EQUIPMENT_LOG = 'SEND_EQUIPMENT_LOG',
  SEND_MATERIAL_LOG = 'SEND_MATERIAL_LOG',
  IMPORT_INSPECTION_QUESTIONS = 'IMPORT_INSPECTION_QUESTIONS',
  IMPORT_WORK_ORDERS = 'IMPORT_WORK_ORDERS',
  USE_MATERIAL_MATCHING = 'USE_MATERIAL_MATCHING',
}

export class CartegraphFiltersSettings {
  importDriverFilter?: string;
  importVehicleFilter?: string;
  importVehicleGroupField?: string;
  importVehicleEquipmentFilter?: string;
  importWorkOrderFilter?: string;
  materialMatchingFields?: string;
  classFilters?: CartegraphClassFilter[] = [];
}

export class CartegraphTaskDefaults {
  shift: CartegraphShiftTaskDefault;
  observation: CartegraphTaskDefault;
  preInspect: CartegraphTaskDefault;
  postInspect: CartegraphTaskDefault;
}

export class CartegraphTaskDefault {
  priority?: string;
  status?: string;
  activity?: string;
  workCompletedFor?: string;
}

export class CartegraphShiftTaskDefault extends CartegraphTaskDefault {
  sectorAreaStatus?: string;
  workCompletedForCDOT?: string;
}

export class CartegraphImportSettings {
  filter: CartegraphFiltersSettings;
  inspection: CartegraphInspectionSettings;
}

export class CartegraphInspectionSettings {
  inspectionFormSection?: string;
  inspectionOdometerField?: string;
  // cgInspectionExcludeFields?: string; // this is hardcoded ath the moment
}

export class CartegraphFeatureFlag {
  feature: CartegraphFeature;
  enabled: boolean;

}

export class CartegraphClassFilter {
  constructor(cgClassName: string, filter?: string) {
    this.cgClassName = cgClassName;
    this.filter = filter;
  }

  cgClassName: string;
  filter?: string;
}

export class CartegraphProcessing {
  processingType?: ProcessingType;
  notification?: CartegraphProcessingNotification = new CartegraphProcessingNotification();
}

export class CartegraphProcessingNotification {
  notificationEmails: string[] = [];
  excludeFilter: string[] = [];
}
