import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { VehicleInfoWindowContentComponent } from './vehicle-info-window-content/vehicle-info-window-content.component';
import { ObservationInfoWindowContentComponent } from './observation-info-window-content/observation-info-window-content.component';
import { BreadcrumbInfoWindowContentComponent } from './breadcrumb-info-window-content/breadcrumb-info-window-content.component';
import { MapPanelComponent } from './map-panel/map-panel.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FormattingModule } from '../../formatting/formatting.module';
import { RetryingImageViewerComponent } from './retrying-image-viewer/retrying-image-viewer.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialModule } from '../../material/material.module';
import { FormsModule } from '@angular/forms';
import { RoadStatusInfoWindowContentComponent } from './road-status-info-window-content/road-status-info-window-content.component';
import {AlertInfoWindowContentComponent} from './alert-info-window-content/alert-info-window-content.component';
import { IncidentInfoWindowContentComponent } from './incident-info-window-content/incident-info-window-content.component';

@NgModule({
    declarations: [
        VehicleInfoWindowContentComponent,
        ObservationInfoWindowContentComponent,
        BreadcrumbInfoWindowContentComponent,
        AlertInfoWindowContentComponent,
        MapPanelComponent,
        RetryingImageViewerComponent,
        RoadStatusInfoWindowContentComponent,
        IncidentInfoWindowContentComponent,
    ],
    exports: [
        MapPanelComponent,
        RetryingImageViewerComponent,
    ],
  imports: [
        CommonModule,
        MatSlideToggleModule,
        MatIconModule,
        MatSnackBarModule,
        MatSliderModule,
        MaterialModule,
        FormattingModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        RouterModule,
        FormsModule,
    ]
})
export class MapViewerModule {}
