<div class="message-top-bar">
  <button
          class="back__btn"
          (click)="closeMsgWindow()"
          mat-icon-button
  >
    <mat-icon class="action-icon">arrow_back</mat-icon>
  </button>

  <button class="delete__btn text-primary-dark"
          mat-icon-button
          (click)="deleteMsg()"
  >
    <mat-icon class="delete-icon action-icon">delete</mat-icon>
  </button>
</div>

<h2>
  {{ openMessage?.subject }}
</h2>
<div>
  {{ openMessage?.body }}
</div>

<div *ngIf="!!openMessage">
  <div class="message-recipient-filters">
    <span class="highlighted">Recipients</span>
    <ng-container *ngFor="let filter of allFilters">
      <button class="filter-button"
              color="accent"
              [ngClass]="{'highlighted': filter === recipientFilter}"
              (click)="changeFilter(filter)"
              mat-button
      >
        {{filter}}
      </button>
    </ng-container>
  </div>
  <div *ngFor="let recipient of openMessage.messageDeliveries | filterRecipients: recipientFilter" class="message-recipient">
    <div>{{ recipient.recipientName }}</div>
    <div *ngIf="recipient.seenAt === null">Unread</div>
    <div *ngIf="recipient.seenAt !== null">{{ recipient.seenAt | date:'short' }}</div>
  </div>
</div>
