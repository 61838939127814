import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../configuration/configuration.service';

@Component({
    selector: 'app-manage-vehicles-main',
    templateUrl: './manage-vehicles-main.component.html',
})
export class ManageVehiclesMainComponent implements OnInit, OnDestroy {
    page: string;
    configuration: ConfigurationModel;
    configurationSubscription: Subscription;

    constructor(private configurationService: ConfigurationService) {
    }

    ngOnInit() {
        this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
            if (model) {
                this.configuration = model;
            }
        });
    }

    ngOnDestroy() {
        this.configurationSubscription?.unsubscribe();
    }

    pageChanged(page: string) {
        this.page = page;
    }
}
