import { NavigationEnd, Router } from '@angular/router';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../shared/models/configuration.model';
import {RoutesComponent} from '../panel-sections/routes/routes.component';
import {VerticalIconButton} from '../../../../shared/components/buttons/vertical-icon-button/vertical-icon-button.component';
import {LiveMapTab} from '../../models/live-map-tabs';
import {AssetComponent} from '../panel-sections/assets/asset/asset.component';
import {ObservationsComponent} from '../panel-sections/observations/observations.component';
import {MessagesComponent} from '../panel-sections/messages/messages.component';
import {AssetsComponent} from '../panel-sections/assets/assets/assets.component';


@Component({
  selector: 'app-live-map-left-panel',
  templateUrl: './live-map-panel.component.html',
  styleUrls: ['./live-map-panel.component.scss'],
})
export class LiveMapPanelComponent implements OnInit {
  @Input() configuration: ConfigurationModel;
  @Input() collapseMenu?: boolean;
  @Output() childComponent = new EventEmitter<AssetComponent | RoutesComponent | ObservationsComponent | MessagesComponent | AssetsComponent>();
  route?: string;
  items: VerticalIconButton[] = [];
  activeLink: string;
  background: ThemePalette = undefined;
  sidenavOpen$?: Observable<boolean>;

  constructor(private store: Store<any>, private router: Router) {
    this.activeLink = this.router.url.split(/[\/?]+/)[2]?.toLowerCase();
  }

  ngOnInit() {
    this.items.push(...[
      {
        title: 'Vehicles',
        icon: 'directions_car',
        activePredicate: () => this.activeLink === LiveMapTab.ASSETS,
        onClick: () => { this.showTab(LiveMapTab.ASSETS); },
      },
      {
        title: 'Routes',
        icon: 'route',
        activePredicate: () => this.activeLink === LiveMapTab.ROUTES,
        onClick: () => { this.showTab(LiveMapTab.ROUTES); },
      },
    ]);
    this.items.push(...[
      {
        title: 'Observations',
        icon: 'stars',
        activePredicate: () => this.activeLink === LiveMapTab.OBSERVATIONS,
        onClick: () => { this.showTab(LiveMapTab.OBSERVATIONS); },
      },
    ]);
    if (this.hasFeatureFlag(FeatureFlagEnum.FleetMessaging)) {
      this.items.push(...[
        {
          title: 'Messages',
          icon: 'message',
          activePredicate: () => this.activeLink === LiveMapTab.MESSAGES,
          onClick: () => { this.showTab(LiveMapTab.MESSAGES); },
        },
      ]);
    }

    this.sidenavOpen$ = this.store.pipe(
      select((state) => state.ui.sidenavOpen)
    );
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeLink = this.router.url.split(/[\/?]+/)[2]?.toLowerCase();
      }
    });
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  showTab(tab: LiveMapTab) {
    this.router.navigate(['/live-map', tab]);
  }

  onOutletLoaded(e) {
    if (e instanceof AssetComponent ||
        e instanceof RoutesComponent ||
        e instanceof ObservationsComponent ||
        e instanceof MessagesComponent ||
        e instanceof AssetsComponent
    ) {
      this.childComponent.emit(e);
    } else {
      this.childComponent.emit(null);
    }
  }
}
