import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AssetsManagerService} from '../../../../../../data/assets/assets-manager.service';
import {Asset} from '../../../../models/asset.class';
import {VehiclesManagerService} from '../../../../../../data/vehicles/vehicles-manager.service';
import {LiveMapDataService} from '../../../../services/live-map-data.service';
import {AssetStatus} from '../../../../../../shared/models/asset-status';
import {ActionMenuItem} from '../../../../../../shared/models/action-menu-item.class';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ShiftsManagerService} from '../../../../../../data/shifts/shifts-manager.service';
import {ShiftState} from '../../../../../../shared/models/shift.model';
import {ObservationsManagerService} from '../../../../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../../../../data/images/images-manager.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit, OnDestroy {

  assets: Asset[] = [];
  items: ActionMenuItem[];

  private readonly openSubscriptions = Array<Subscription>();
  @ViewChild('rightPanelTemplate') rightPanelTemplate: TemplateRef<string>;

  constructor(
      private router: Router,
      private assetsManager: AssetsManagerService,
      private vehiclesManager: VehiclesManagerService,
      private shiftManager: ShiftsManagerService,
      private observationManager: ObservationsManagerService,
      private imageManager: ImagesManagerService,
      private liveMapDataService: LiveMapDataService,
  ) {}

  ngOnInit() {
    this.liveMapDataService.sendRightPanelWidth(10);
    this.assetsManager.filterByVehicleIds(null);
    this.observationManager.hideAll();
    this.imageManager.filterByShift(null);

    const assetManagerSubscription = this.assetsManager.filteredAssets$.subscribe(assets => {
      this.assets = assets;
      this.items = this.assets.map(asset => {
        return new ActionMenuItem(
            asset.id,
            asset.hasNoTablet ? 'mobile_off' : 'smartphone',
            asset.name,
            !!asset.driverId ? asset.driverName : '',
            AssetStatus.getAssetStatus(asset).label,
            null,
            () => false,
            () => asset.shiftStatus === ShiftState.ENDED,
            () => this.onClick(asset),
            () => this.onHoverOn(asset),
            () => this.onHoverOff(asset),
            [],
        );
      });
    });
    this.openSubscriptions.push(assetManagerSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onHoverOn(asset: Asset) {
    if (asset.shiftStatus !== ShiftState.ENDED) {
      this.shiftManager.highlightShift(asset.shiftId, ShiftsManagerService.PANEL_SOURCE);
    } else {
      this.vehiclesManager.highlightVehicle(asset.id);
    }
  }

  onHoverOff(asset: Asset) {
    if (asset.shiftStatus !== ShiftState.ENDED) {
      this.shiftManager.highlightShift(null, ShiftsManagerService.PANEL_SOURCE);
    } else {
      this.vehiclesManager.highlightVehicle(null);
    }
  }

  onClick(asset: Asset) {
    this.shiftManager.highlightShift(null, ShiftsManagerService.PANEL_SOURCE);
    this.vehiclesManager.highlightVehicle(null);
    this.router.navigate(['/live-map', 'assets', asset.id], {queryParamsHandling: 'merge'});
  }
}
