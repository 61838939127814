import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapLayerConfiguration, MapLayerType} from '../../models/map-layer.model';
import {ExtAuthService} from '../../../data/ext-auth/ext-auth.service';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-map-layer-configuration',
  templateUrl: './map-layer-configuration.component.html',
  styleUrls: ['./map-layer-configuration.component.scss']
})
export class MapLayerConfigurationComponent implements OnInit {

  @Input() mapLayer: MapLayerConfiguration;

  @Output() mapLayerToggled = new EventEmitter();
  @Output() mapLayerMoved = new EventEmitter();
  @Output() mapLayerDeleted = new EventEmitter();
  @Output() mapLayerSaved = new EventEmitter();
  @Output() mapLayerCancelled = new EventEmitter();

  MapLayerType = MapLayerType;

  constructor(
      private extAuthService: ExtAuthService,
      private toast: ToastService,
  ) { }

  ngOnInit(): void {
  }

  changed() {
    this.mapLayer.dirty = true;
  }

  toggle() {
    this.mapLayerToggled.emit();
  }

  moveLayerDown() {
    this.mapLayerMoved.emit();
  }

  deleteMapLayer() {
    this.mapLayerDeleted.emit();
  }

  save() {
    this.mapLayerSaved.emit();
  }

  cancel() {
    this.mapLayerCancelled.emit();
  }

  onCredentialsToggle() {
    if (!this.mapLayer.useCredentials) {
      this.mapLayer.currentUsername = null;
      this.mapLayer.currentPassword = null;
    }
  }

  testCredentials() {
    this.extAuthService.verifyEsriCredentials(
        this.mapLayer.currentUrl,
        this.mapLayer.currentUsername,
        this.mapLayer.currentPassword
    ).then(response => {
      if (response.data) {
        this.toast.short('Connection is OK!');
      } else {
        this.toast.long('Error on connection!');
      }
    });
  }
}
