<ng-container *ngIf="!!configuration && !!asset">

    <ng-container *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam)">
        <div *ngIf="!asset.cameraConfiguration?.streaming?.captureStreamDuration">
            <span>Video Streaming not configured for the asset</span>
        </div>
        <ng-container *ngIf="asset.cameraConfiguration?.streaming?.captureStreamDuration > 0">
            <div *ngIf="asset.shiftStatus === ShiftState.ENDED">
                <span>Asset is offline</span>
            </div>
            <ng-container  *ngIf="asset.shiftStatus !== ShiftState.ENDED">
                <div class="mt-15 mb-15 ml-10">
                    <button mat-button mat-stroked-button color="accent"
                            (click)="startStreaming()"
                            class="action-button"
                            [disabled]="streaming"
                    >
                        <mat-icon *ngIf="!streaming">play_arrow</mat-icon>
                        <mat-icon *ngIf="streaming">sync</mat-icon>
                        Start streaming
                    </button>
                </div>
                <app-video-player [vehicleId]="asset.id"
                                  [timeFrom]="asset.shiftStartTime"
                                  class="action__row"
                ></app-video-player>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam) && asset.cameraConfiguration?.streaming?.captureImageInterval > 0">
        <mat-spinner *ngIf="loadingImages"></mat-spinner>
        <div *ngIf="!loadingImages && !!images && images.length === 0">
            No still images
        </div>
        <app-image-viewer
            *ngIf="!!images && images.length > 0"
            [images]="images"
        ></app-image-viewer>
    </ng-container>
</ng-container>

<div *ngIf="!asset">
    <span>Select asset in left panel</span>
</div>
