import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = [];

  constructor(
      private router: Router,
      private location: Location,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  // go to previous component/route no matter query params set
  backToPreviousPage(currentPath: string, fallbackPath: string) {
    const history = Object.assign([], this.history).reverse();
    if (history.length > 0) {
      for (const item of history) {
        this.history.pop();
        if (!item.startsWith(currentPath)) {
          this.router.navigateByUrl(item);
          return;
        }
      }
      this.router.navigateByUrl(fallbackPath);
    } else {
      this.router.navigateByUrl(fallbackPath);
    }
  }
}
