import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MessageRecipientCollectionModel} from '../../../../models/message-recipient-collection.model';
import {LiveMapDataService} from '../../../../services/live-map-data.service';
import {MessageRecipientModel} from '../../../../models/message-recipient.model';
import {MessagesService} from '../../../../../../data/messages/messages.service';
import {ToastService} from '../../../../../../shared/services/toast.service';

export const subFilter = (recipients: any[], value: any): MessageRecipientModel[] => {
  const filterValue = value.hasOwnProperty('name') ? value.name.toLowerCase() : value.toLowerCase();

  return recipients.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
};

interface MessageRecipient {
  type: string;
  recipients: MessageRecipientModel[];
}

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
})
export class NewMessageComponent implements OnInit {

  isSending = false;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredRecipients: Observable<MessageRecipient[]>;
  recipientsForm = new UntypedFormControl();
  formData = {
    subject: '',
    body: '',
    recipients: [],
  };
  availableRecipients: MessageRecipient[];

  @ViewChild('recipientInput') recipientInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private router: Router,
              private liveMapDataService: LiveMapDataService,
              private messagesService: MessagesService,
              private toast: ToastService) {
    this.filteredRecipients = this.recipientsForm.valueChanges.pipe(
        startWith(''),
        map((input: string) => this._filterGroup(input)));
  }

  ngOnInit(): void {
    this.liveMapDataService.availableRecipients$.subscribe(recipients => {
      if (recipients) {
        this.availableRecipients = this.prepareRecipientsForDialog(recipients);
        this.recipientsForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
    });
  }

  sendMessage() {
    this.isSending = true;
    this.messagesService.sendMessage(this.formData).then((response) => {
      this.liveMapDataService.handleMessageSent(response.data);

      this.toast.short('Message sent successfully.');
      this.isSending = false;
      this.closeMsgWindow();
    }).catch((error: Error) => {
      console.error(error);
      this.toast.longer('Failed to send a message.');
      this.isSending = false;
    });
  }

  closeMsgWindow() {
    this.router.navigate(['live-map/messages']);
  }

  remove(recipient: string): void {
    const index = this.formData.recipients.indexOf(recipient);

    if (index >= 0) {
      this.formData.recipients.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.formData.recipients.push(event.option.value);
    this.recipientInput.nativeElement.value = '';
    this.recipientsForm.setValue(null);
  }

  getIconName(type: string): string {
    const mapping = {
      vehicle_group: 'groups',
      vehicle: 'directions_car',
      driver: 'person',
    };

    return mapping[type] ? mapping[type] : 'groups';
  }

  getRecipientsPlaceholder() {
    return this.isRecipientPresent() ? 'Search for active recipients' : 'No active recipients found';
  }

  isRecipientPresent(): boolean {
    if (!this.availableRecipients) {
      return false;
    }
    return Boolean(this.availableRecipients[2].recipients.length);
  }

  private _filterGroup(value: string): MessageRecipient[] {
    if (value) {
      return this.availableRecipients
          .map(group => ({type: group.type, recipients: subFilter(group.recipients, value)}))
          .filter(group => group.recipients.length > 0);
    }

    return this.availableRecipients;
  }

  private prepareRecipientsForDialog(recipients: MessageRecipientCollectionModel): MessageRecipient[] {
    const mappedRecipients = [];

    if (recipients.drivers.length) {
      mappedRecipients.push({
        type: 'System',
        recipients: [{
          name: 'Everyone',
          type: 'everyone'
        }],
      });
    }

    mappedRecipients.push(
        {
          type: 'Vehicles',
          recipients: recipients.vehicles,
        }
    );

    mappedRecipients.push(
        {
          type: 'Vehicle Groups',
          recipients: recipients.vehicleGroups,
        }
    );

    mappedRecipients.push(
        {
          type: 'Drivers',
          recipients: recipients.drivers,
        }
    );

    return mappedRecipients;
  }
}
