<div class="setup-page-container">

    <div class="header-container">
        <h2 class="header-container-filters">
            Drivers Import
        </h2>
    </div>

    <div class="content-container">
        <div class="selection-container">
            <mat-card appearance="outlined">
                <mat-card-content class="w-100 content">
                    <div class="loading__wrapper" *ngIf="currentState == states.WORKING">
                        <div>Working on it ...</div>
                        <mat-spinner></mat-spinner>
                    </div>
                    <div *ngIf="currentState == states.NOOP" class="w-100">
                        <input hidden type="file"
                               id="file"
                               accept=".csv"
                               #fileInput
                               (change)="handleFileInput($event)">
                        <button mat-button
                                color="accent" (click)="fileInput.click()"> Choose CSV file
                        </button>
                        <span class="ml-10" *ngIf="selectedFile">{{selectedFile.name}}</span>
                        <span class="ml-10">
                    <button mat-button
                            color="accent" [disabled]="selectedFile == null" (click)="upload()"> Upload CSV file
                    </button>
                </span>
                        <span class="ml-10">
                    <button mat-button
                            color="accent" (click)="cancelImport()"> Cancel
                    </button>
                </span>
                        <div class="mt-15">
                            <h4>Sample csv</h4>
                            <div>
                        <pre>
driverName
Alfred Nobel
Albert Einstein
...
...
                </pre>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="currentState == states.NOOP && uiError && uiError.length > 0" class="w-100 content">
                        <span class="ui-error">{{ uiError }}</span>
                    </div>

                    <div *ngIf="currentState == states.IMPORTED" class="w-100 content">
                        <div>Import successfully finished.
                            <button mat-button color="accent" (click)="changePage()">
                                Navigate to user management
                            </button>
                            to see changes.
                        </div>
                    </div>


                </mat-card-content>
            </mat-card>


            <mat-card appearance="outlined" class="mt-15"
                      *ngIf="validData && validData.length>0 && currentState == states.NOOP">
                <mat-card-title class="text-accent pl-15"> Valid data</mat-card-title>
                <mat-card-actions>
                    <button mat-button
                            color="accent"
                            (click)="doCancel()">Cancel
                    </button>
                    <button mat-button
                            color="accent"
                            [disabled]="importDisabled()"
                            (click)="doImport()">Import
                    </button>
                </mat-card-actions>
                <mat-card-content>
                    <ng-container
                            *ngTemplateOutlet="table;context:{importData: validData,displayedColumns:displayedColumnsValid}"></ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mt-15"
                      *ngIf="invalidData && invalidData.length>0 && currentState == states.NOOP">
                <mat-card-title class="text-accent pl-15"> Invalid data</mat-card-title>
                <mat-card-content>
                    <ng-container
                            *ngTemplateOutlet="table;context:{importData: invalidData,displayedColumns:displayedColumnsInvalid}"></ng-container>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>

<ng-template #table let-data="importData" let-displayedColumns="displayedColumns">
    <table *ngIf="data && data.length>0" mat-table
           [dataSource]="data">

        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef> Import</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef> Line</th>
            <td mat-cell *matCellDef="let element">{{element.index}}</td>
        </ng-container>

        <ng-container matColumnDef="driverName">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element">{{element.row.driverName}} </td>
        </ng-container>

        <ng-container matColumnDef="login">
            <th mat-header-cell *matHeaderCellDef> Login</th>
            <td mat-cell *matCellDef="let element">{{element.dbData?.driver?.login || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Errors/Notes</th>
            <td mat-cell *matCellDef="let element">
                <div *ngFor="let error of element.errors">{{error}}</div>
                <div *ngIf="element.action=='NONE'">skipping import - driver already exists / is portal or admin user
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>
