<div class="legend-activity-filter" *ngIf="!!appliedFilter">
    <div>
        <span class="activity-name">{{ appliedFilter.label }}</span>&nbsp;<span>activity filter applied</span>
    </div>
    <button mat-icon-button
            class="configure-button"
            (click)="clearFilter()"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="legend-wrapper mat-elevation-z2">
    <div class="legend-header">
        <span>Coverage Map</span>
        <button mat-icon-button
                class="configure-button"
                (click)="goToSettings()"
                *ngIf="isAdmin"
        >
            <mat-icon>settings</mat-icon>
        </button>
    </div>
    <div class="legend-subtitle">
        <span>Number of passes on a road segment</span>
    </div>
    <app-gradient-filter-container
            *ngIf="!!layerTypeFilter"
            [layerType]="layerTypeFilter"
            [editable]="false"
    >
    </app-gradient-filter-container>
</div>
