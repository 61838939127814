<div class="address-lookup-container">

    <div class="map-preview-container">
        <app-map-preview
                [nextContent]="mapContent"
        ></app-map-preview>
    </div>

    <div class="address-lookup-overlay mat-elevation-z2">
        <!-- search box -->
        <div class="search-box-wrapper" *ngIf="!selectedAddress">
            <mat-icon class="search__icon">
                search
            </mat-icon>

            <input
                    class="search__input"
                    type="text"
                    placeholder="Find Address"
                    aria-label="Number"
                    autocomplete="off"
                    matInput
                    [(ngModel)]="searchString"
                    (ngModelChange)="searchStringChanged()"
                    [disabled]="isLoadingFromQueryParams"
            />

            <button mat-icon-button
                    class="clear__button"
                    (click)="cancelSearch()"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <!-- address list -->
        <div class="item-list-wrapper" *ngIf="!selectedAddress">
            <div class="item-list">
                <ng-container *ngFor="let addressItem of addressItems">
                    <app-action-menu-item style="width: 100%;" [input]="addressItem"></app-action-menu-item>
                </ng-container>
            </div>
        </div>



        <!-- current address with back button -->
        <div *ngIf="!!selectedAddress && !selectedVisit" class="navigation-container">
            <button mat-icon-button
                    color="accent"
                    (click)="backToAddressList()"
                    [matTooltip]="'Back to address list'"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div class="selected-address">
                {{ selectedAddress.formatted_address }}
            </div>
        </div>

        <!-- address visits -->
        <div class="item-list-wrapper" *ngIf="!!selectedAddress && !selectedVisit">
            <app-filter-bar
                    [origin]="'addressLookup'"
                    [useDateFilter]="true"
                    [defaultDateFilter]="TimeRangeFilter.PAST_90_DAYS"
                    (dateFilterChanged)="onDateFilterChanged($event)"
            >
            </app-filter-bar>
            <div class="item-list" *ngIf="!isLoadingVisits && visitItems != null">
                <div class="no-visits" *ngIf="visitItems.length === 0">No address visits found</div>
                <ng-container *ngFor="let visitItem of visitItems">
                    <app-action-menu-item-2line style="width: 100%;" [input]="visitItem"></app-action-menu-item-2line>
                </ng-container>
            </div>
            <mat-spinner *ngIf="isLoadingVisits" [diameter]="32" class="visits-error"></mat-spinner>
        </div>



        <!-- current address with back button -->
        <div *ngIf="!!selectedVisit" class="navigation-container">
            <button mat-icon-button
                    color="accent"
                    (click)="backToVisitList()"
                    [matTooltip]="'Back to visit list'"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>

            <div class="selected-address">
                <span>{{ selectedVisit.time | date:'medium' }}</span>
            </div>
        </div>

        <div class="pass-detail-wrapper" *ngIf="showPassDetails() && !!selectedVisit && !!selectedBreadcrumb">
            <div class="pass-icon-wrapper">
                <div class="pass-icon"
                     [ngStyle]="{
                        'background': selectedBreadcrumb.id === closestBreadcrumb.id ? MapStyles.ADDRESS_PASS_BREADCRUMB_CLOSEST : MapStyles.ADDRESS_PASS_BREADCRUMB_HIGHLIGHTED
                     }"
                ></div>
            </div>
            <div class="pass-detail">
                <div *ngIf="!!(selectedVisit.vehicle?.name)">{{ selectedVisit.vehicle.name }}</div>
                <div *ngIf="!!(selectedVisit.driver?.name)">{{ selectedVisit.driver.name }}</div>
                <div>{{ selectedBreadcrumb.time | date:'mediumTime' }}</div>
                <div>{{ selectedBreadcrumb.speed | speed:useMetricSystem }}</div>
                <div>{{ selectedBreadcrumb.heading | compass }}</div>

                <ng-container *ngIf="!!selectedBreadcrumb && !!selectedVisitValidInputs">
                    <ng-container *ngFor="let sensorInput of selectedVisitValidInputs">
                        <div class="content-row" *ngIf="sensorInput.type === SensorType.PLOW || sensorInput.type === SensorType.PLOW2W">
                                <span class="info-name">
                                    {{ !!sensorInput.label ? sensorInput.label : 'Plow' }}:
                                </span>
                            <span class="info-value">
                                    {{ sensorInput.bitNumber | plowStatus: selectedBreadcrumb.flags }}
                                </span>
                        </div>
                        <div class="content-row" *ngIf="sensorInput.type === SensorType.GRANULAR_SPREADER">
                                <span class="info-name">
                                    {{ !!sensorInput.label ? sensorInput.label : 'Granular Spreader' }}:
                                </span>
                            <span class="info-value">
                                    {{ sensorInput.bitNumber | spreaderStatus: selectedBreadcrumb.flags }}
                                </span>
                        </div>
                        <div class="content-row" *ngIf="sensorInput.type === SensorType.LIQUID_SPREADER">
                                <span class="info-name">
                                    {{ !!sensorInput.label ? sensorInput.label : 'Liquid Spreader' }}:
                                </span>
                            <span class="info-value">
                                    {{ sensorInput.bitNumber | spreaderStatus: selectedBreadcrumb.flags }}
                                </span>
                        </div>
                        <div class="content-row" *ngIf="sensorInput.type === SensorType.MOWER">
                                <span class="info-name">
                                    {{ !!sensorInput.label ? sensorInput.label : 'Mower' }}:
                                </span>
                            <span class="info-value">
                                    {{ sensorInput.bitNumber | mowerStatus: selectedBreadcrumb.flags }}
                                </span>
                        </div>
                        <div class="content-row" *ngIf="sensorInput.type === SensorType.SWEEPER">
                                <span class="info-name">
                                    {{ !!sensorInput.label ? sensorInput.label : 'Sweeper' }}:
                                </span>
                            <span class="info-value">
                                    {{ sensorInput.bitNumber | sweeperStatus: selectedBreadcrumb.flags }}
                                </span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
