import {
  RouteConfigurationWithSchema,
  RouteHierarchyItem,
  RouteStyle
} from '../../../models/route';
import {McRoute} from './McRoute';
import {RouteFilter} from '../../../../pages/settings/components/manage-routes/route-tree/route-tree.component';

/**
 * Input for Route configuration map preview content
 */
export class McRouteConfiguration {

  constructor(
    public id: number,
    public style: RouteStyle,
    public routes: McRoute[],
  ) {}

  static fromBaseAndStyle(
    base: RouteConfigurationWithSchema,
    routeStyle: RouteStyle,
    routeFilter: RouteFilter,
    visible: boolean
  ): McRouteConfiguration {

    const routeConfiguration = new McRouteConfiguration(
      base.id,
      routeStyle,
      RouteHierarchyItem.getLeavesWithExplicitColors(
        base.schema.classification.length,
        base.configuration.children
      ).map(value => new McRoute(value.routeId, value.color, value.lineType, visible))
      // all routes have default visibility same as configuration
    );

    // if configuration is visible and there is a route filter that does not show all routes
    // then change route visibility
    if (visible && routeFilter != null && !routeFilter.all) {
      // no routes are shown
      if (routeFilter.routeIds == null || routeFilter.routeIds.length < 1) {
        routeConfiguration.routes.forEach(route => route.visible = false);

      } else { // only routes listed in route filter are shown
        routeConfiguration.routes.forEach(route => {
          route.visible = routeFilter.routeIds.findIndex(value => value === route.id) > -1;
        });
      }
    }

    return routeConfiguration;
  }

  static fromBase(base: RouteConfigurationWithSchema, routeFilter: RouteFilter, visible: boolean): McRouteConfiguration {
    return McRouteConfiguration.fromBaseAndStyle(base, base.schema?.style, routeFilter, visible);
  }
}
