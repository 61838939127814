<div class="shift-report-table">
  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    (matSortChange)="sortChangedEvent.emit()"
    (scroll)="onScroll($event)"
    matSortActive="startTime"
    matSortDirection="desc"
    class="ops-table"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body" [hidden]="isColumnHidden('id')">
        ID
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('id')">
        <a
          [routerLink]="['/shift-detail', element.id]"
          [queryParams]="{ source: InsightsRoute.SHIFT_REPORT }"
          class="ops-link"
          >{{ element.id }}</a
        >
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('id')">
        {{ reportTotal?.count || 0 }} shifts
      </td>
    </ng-container>

    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="driver.name" class="mat-body" [hidden]="isColumnHidden('driver')">
        Driver
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('driver')">
        {{ element.driver }}
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('driver')">
        {{ reportTotal?.driversCount || 0 }} drivers
      </td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="vehicle.name" class="mat-body" [hidden]="isColumnHidden('vehicle')">
        Vehicle
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('vehicle')">
        {{ element.vehicle }}
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('vehicle')">
        {{ reportTotal?.vehiclesCount || 0 }} vehicles
      </td>
    </ng-container>

    <ng-container matColumnDef="distanceDriven">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('distanceDriven')">
        Distance Driven
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('distanceDriven')">
        <span *ngIf="!!configuration">{{ (element.distanceDriven || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('distanceDriven')">
        <span *ngIf="!!configuration">{{ (reportTotal?.distanceDriven || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="distancePlowed">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('distancePlowed')">
        Distance Plowed
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('distancePlowed')">
        <span *ngIf="!!configuration">{{ (element.distancePlowed || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('distancePlowed')">
        <span *ngIf="!!configuration">{{ (reportTotal?.distancePlowed || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="distanceSpreadGranular">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('distanceSpreadGranular')">
        Distance Spread (Granular)
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('distanceSpreadGranular')">
        <span *ngIf="!!configuration">{{ (element.distanceSpreadGranular || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('distanceSpreadGranular')">
        <span *ngIf="!!configuration">{{ (reportTotal?.distanceSpreadGranular || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="distanceSpreadLiquid">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('distanceSpreadLiquid')">
        Distance Spread (Liquid)
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('distanceSpreadLiquid')">
        <span *ngIf="!!configuration">{{ (element.distanceSpreadLiquid || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('distanceSpreadLiquid')">
        <span *ngIf="!!configuration">{{ (reportTotal?.distanceSpreadLiquid || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="distanceMowed">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('distanceMowed')">
        Distance Mowed
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('distanceMowed')">
        <span *ngIf="!!configuration">{{ (element.distanceMowed || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('distanceMowed')">
        <span *ngIf="!!configuration">{{ (reportTotal?.distanceMowed || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="distanceSwept">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('distanceSwept')">
        Distance Swept
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('distanceSwept')">
        <span *ngIf="!!configuration">{{ (element.distanceSwept || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('distanceSwept')">
        <span *ngIf="!!configuration">{{ (reportTotal?.distanceSwept || 0) | distance:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="materialUsedGranular">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('materialUsedGranular')">
        Material Used (Granular)
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('materialUsedGranular')">
        <span *ngIf="!!configuration">{{ (element.materialUsedGranular || 0) | weight:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('materialUsedGranular')">
        <span *ngIf="!!configuration">{{ (reportTotal?.materialUsedGranular || 0) | weight:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="materialUsedLiquid">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('materialUsedLiquid')">
        Material Used (Liquid)
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('materialUsedLiquid')">
        <span *ngIf="!!configuration">{{ (element.materialUsedLiquid || 0) | volume:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('materialUsedLiquid')">
        <span *ngIf="!!configuration">{{ (reportTotal?.materialUsedLiquid || 0) | volume:configuration.useMetricSystem }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body" [hidden]="isColumnHidden('startTime')">
        Shift Start
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('startTime')">
        {{ element.startTime | date: 'medium' }}
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('startTime')">
        {{ reportTotal?.firstStartTime | date: 'medium' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body" [hidden]="isColumnHidden('endTime')">
        Shift End
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('endTime')">
        {{ element.endTime | date: 'medium' }}
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('endTime')">
        {{ reportTotal?.lastEndTime | date: 'medium' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('duration')">
        Duration
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('duration')">
        {{ element.durationInSeconds | duration }}
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('duration')">
        {{ (reportTotal?.durationInSeconds || 0) | duration }}
      </td>
    </ng-container>

    <ng-container matColumnDef="maxSpeed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-body" [hidden]="isColumnHidden('maxSpeed')">
        Max Speed
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('maxSpeed')">
        <span *ngIf="!!configuration">{{ element.maxSpeed | speed:configuration.useMetricSystem }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('maxSpeed')">
        <span *ngIf="!!configuration">{{ reportTotal?.maxSpeed || 0 | speed:configuration.useMetricSystem}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="observations">
      <th mat-header-cell *matHeaderCellDef class="mat-body" [hidden]="isColumnHidden('observations')">
        Observations
      </th>
      <td mat-cell *matCellDef="let element" [hidden]="isColumnHidden('observations')">
        <div>{{ element.observationsCount }}</div>
      </td>
      <td mat-footer-cell *matFooterCellDef [hidden]="isColumnHidden('observations')">
        {{ reportTotal?.observationsCount || 0 }} observations
      </td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="device.phoneNumber" class="mat-body" [hidden]="isColumnHidden('phoneNumber')">Phone number</th>
      <td mat-cell *matCellDef="let element"  [hidden]="isColumnHidden('phoneNumber')">
        {{ element.phoneNumber }}
      </td>
      <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="ops-table-action-cell" >
        <button mat-icon-button class="ops-table-action-button">
          <mat-icon class="ops-table-action-icon">more_vert</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsWithAction; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsWithAction"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsWithAction; sticky: true"></tr>
  </table>
</div>
