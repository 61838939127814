export enum MessageRecipientType {
  DRIVER = 'driver',
  VEHICLE = 'vehicle',
  VEHICLE_GROUP = 'vehicle_group',
  EVERYONE = 'everyone',
}

export class MessageRecipientModel {
  id: number;
  type: MessageRecipientType;
  name: string;
}
