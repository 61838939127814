import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {Subscription} from 'rxjs';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {Asset} from '../../../models/asset.class';
import {LiveMapTab} from '../../../models/live-map-tabs';

export abstract class AssetRightPanel {

    configuration: ConfigurationModel;

    private assetId: number;
    assets: Asset[];
    asset: Asset;

    configSubscription: Subscription;

    abstract onAssetChange();
    abstract onConfigurationLoad();

    protected constructor(
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected configurationService: ConfigurationService,
        protected assetsManager: AssetsManagerService,
    ) {
    }

    protected loadConfiguration() {
        this.configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
            if (model) {
                this.configuration = model;
                this.onConfigurationLoad();
            }
        });
    }

    protected initializeAssets() {
        this.getAssetIdParamFromUrl();
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.getAssetIdParamFromUrl();
                this.changeAsset();
            }
        });

        this.assetsManager.assets$.subscribe(assets => {
            this.assets = assets;
            this.changeAsset();
        });
    }

    private changeAsset() {
        if (!this.assetId) {
            this.asset = null;
            return;
        }
        if (this.asset?.id !== this.assetId) {
            this.asset = this.assets.find(asset => asset.id === this.assetId);
            this.onAssetChange();
        }
    }

    private getAssetIdParamFromUrl() {
        // url: /live-map/assets/21?dateFilter=none&drawer=route
        // components: dashboard-layout -> empty -> live-map -> asset
        const endIndex = this.router.routerState.snapshot.url.indexOf('?') > 0 ? this.router.routerState.snapshot.url.indexOf('?') : null;
        const assetIdFromUrl = +this.router.routerState.snapshot.url.substring(`/live-map/${LiveMapTab.ASSETS}/`.length, endIndex);
        this.assetId = !!assetIdFromUrl ? assetIdFromUrl : null;

        /* The following doesn't work on AWS :-(
        const componentChild = this.activatedRoute.firstChild?.firstChild?.firstChild;
        if (!!componentChild && !!componentChild.snapshot.params) {
            const assetId = +componentChild.snapshot.params.id;
            if (!!assetId) {
                this.assetId = assetId;
                return;
            }
        }
        this.assetId = null;
        */
    }
}
