import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {VehiclesService} from '../../../../data/vehicles/vehicles.service';
import {VehicleModel} from '../../../../shared/models/vehicle.model';
import {ObservationTypeGroup} from '../../../../shared/models/observation-group';
import {ObservationManagementService} from '../../../../data/observations/observation-management.service';
import {
    DialogCreateVehicleComponent,
} from './dialogs-vehicle/vehicle-dialog-components';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {RoutesService} from '../../../../data/routes/routes.service';
import {VehicleGroupModel} from './model/VehicleGroupModel';
import {UpdateVehicleGroupDialogInput} from './model/UpdateVehicleGroupDialogInput';
import {NamedId} from '../../../../shared/models/NamedId';
import {CreateVehicleGroupDialogInput} from './model/CreateVehicleGroupDialogInput';
import {VehicleGroupModelWithVehicles} from './model/VehicleGroupModelWithVehicles';
import {InspectionFormsService} from '../../../../data/inspection-forms/inspection-forms.service';
import {ToastService} from '../../../../shared/services/toast.service';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../shared/models/action-menu-item.class';
import {RouteConfigurationWithSchema} from '../../../../shared/models/route';
import {
    DialogConfirmDeleteVehicleCategoryComponent, DialogCreateVehicleCategoryComponent,
    DialogUpdateVehicleCategoryComponent
} from './dialogs-vehicle-group/vehicle-group-dialog-components';

@Component({
  selector: 'app-manage-vehicles',
  templateUrl: './manage-vehicles.component.html',
  styleUrls: ['./manage-vehicles.component.scss'],
})
export class ManageVehiclesComponent implements OnInit {

  @Input() configuration: ConfigurationModel;
  @Output() changePageEvent = new EventEmitter<string>();

  isLoading = true;
  allVehicleGroupsWithVehicles: VehicleGroupModelWithVehicles[] = [];
  allInspectionForms: NamedId[] = [];
  allObservationTypeGroups: NamedId[] = [];
  allAllowedRouteConfigs: NamedId[] = [];
  isImported = false;

  vehicleGroupMenuItems: ActionMenuItem[];
  selectedVehicleGroup: VehicleGroupModelWithVehicles;

  constructor(public dialog: MatDialog,
              private toastService: ToastService,
              private observationManagementService: ObservationManagementService,
              private routesService: RoutesService,
              private vehicleService: VehiclesService,
              private configurationService: ConfigurationService,
              private inspectionFormsService: InspectionFormsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
  ) {}

  ngOnInit() {

    Promise.all([
        this.vehicleService.getVehicleCategories().toPromise(),
        this.vehicleService.getVehicles().toPromise(),
        this.inspectionFormsService.getForms(),
        this.observationManagementService.getObservationTypeGroups().toPromise(),
        this.routesService.getRouteConfigurations(),
    ])
        .then(responses => {
            const [
              categoriesResponse,
              vehiclesResponse,
              inspectionFormsResponse,
              observationGroupsResponse,
              routeConfigsResponse
            ] = responses;

            this.isImported = this.configurationService
              .hasFeatureFlag(this.configuration?.featureFlags, FeatureFlagEnum.CartegraphIntegration);

            for (const category of categoriesResponse.data) {
                const group = VehicleGroupModelWithVehicles.fromDtoAndAllVehicles(category, vehiclesResponse.data);
                this.allVehicleGroupsWithVehicles.push(group);
            }
            this.allVehicleGroupsWithVehicles.sort(VehicleGroupModelWithVehicles.vehicleGroupCompareFn);
            this.selectFirstVehicleGroup();
            this.updateVehicleGroupMenu();

            this.allInspectionForms = inspectionFormsResponse.contents.map(value => new NamedId(value.journalId, value.title));
            this.allObservationTypeGroups = observationGroupsResponse.data.map((value: ObservationTypeGroup) => {
                return { id: value.id, name: value.name };
            });
            this.allAllowedRouteConfigs = routeConfigsResponse.data.map((value: RouteConfigurationWithSchema) => {
                return { id: value.id, name: value.name };
            });

            this.subscribeToQueryParamChanges();
        })
        .catch(error => {
            const msg = 'Error while loading data from server';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
        }).finally(() => {
        this.isLoading = false;
    });
  }

  private subscribeToQueryParamChanges() {
    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      const vehicleGroupIdQueryParam = paramMap.get('vehicle-group');
      if (!!vehicleGroupIdQueryParam) {
        const vehicleGroupId = Number(vehicleGroupIdQueryParam);
        const foundVehicleGroup = this.allVehicleGroupsWithVehicles.find(value => value.id === vehicleGroupId);
        if (foundVehicleGroup != null) {
          this.selectedVehicleGroup = foundVehicleGroup;
        } else {
          this.selectedVehicleGroup = null;
        }
      }
    });
  }

  deleteVehicleGroup(vehicleGroupId: number) {
      const categoryToDelete = this.allVehicleGroupsWithVehicles.find(group => group.id === vehicleGroupId);
      const index = this.allVehicleGroupsWithVehicles.indexOf(categoryToDelete);
      const dialogRef = this.dialog.open(DialogConfirmDeleteVehicleCategoryComponent, {
          width: '450px',
          data: categoryToDelete
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult !== undefined && dialogResult) {
              this.vehicleService.deleteVehicleCategory(dialogResult).toPromise()
                  .then(response => {
                      this.allVehicleGroupsWithVehicles.splice(index, 1);
                      this.deselectDeletedVehicleGroup(categoryToDelete);
                      this.updateVehicleGroupMenu();
                  }).catch(error => {
                      const msg = 'Error while deleting vehicle group';
                      this.toastService.long(msg);
                      console.error(`${msg} :: ${error}`);
              });
          }
      });
  }

  onVehicleMoved(vehicle: VehicleModel) {
      this.allVehicleGroupsWithVehicles.forEach(group => {
          const index = group.vehicles.findIndex(vehicleModel => vehicleModel.id === vehicle.id);
          if (index >= 0) {
              // delete the old one
              group.vehicles.splice(index, 1);
          }
          if (group.id === vehicle.groupId) {
              // add the new one
              group.vehicles.push(vehicle);
              group.vehicles.sort(VehicleModel.vehicleCompareFn);
          }
      });
      this.updateVehicleGroupMenu();
  }

  updateVehicleGroup(vehicleGroupId: number) {
      const that = this;
      const categoryIndex = this.allVehicleGroupsWithVehicles.findIndex(group => group.id === vehicleGroupId);
      const categoryToUpdate = Object.assign({}, this.allVehicleGroupsWithVehicles[categoryIndex]);
      const vehicleGroupModel = VehicleGroupModel.fromModelWithVehicles(categoryToUpdate);

      const dialogRef = this.dialog.open(DialogUpdateVehicleCategoryComponent, {
          width: '450px',
          data: new UpdateVehicleGroupDialogInput(
              vehicleGroupModel,
              this.allInspectionForms,
              this.allObservationTypeGroups,
              this.allAllowedRouteConfigs,
              this.isImported
          )
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult !== undefined && dialogResult) {
              const candidate = dialogResult as VehicleGroupModel;
              // set the name the same as title
              candidate.name = candidate.title;
              this.clearInspectionFormsWhenNotUsingDvir(candidate);
              this.vehicleService.updateVehicleCategory(categoryToUpdate.id, candidate.toDto())
                  .then(response => {
                      const updatedCategory = that.allVehicleGroupsWithVehicles[categoryIndex];
                      updatedCategory.updateFromDto(response.data);
                      this.allVehicleGroupsWithVehicles.sort(VehicleGroupModelWithVehicles.vehicleGroupCompareFn);
                      this.updateVehicleGroupMenu();
                  }).catch(error => {
                      const msg = 'Error while editing vehicle group';
                      this.toastService.long(msg);
                      console.error(`${msg} :: ${error}`);
              });
          }
      });
  }

  addNewVehicle() {
    const dialogRef = this.dialog.open(DialogCreateVehicleComponent, {
      width: '550px',
      data: {
        model: new VehicleModel(),
        configuration: this.configuration,
        vehicles: this.getVehicles(),
        defaultMapColor: this.configurationService.trackStyles.liveMapPlowLive.color
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined && dialogResult) {
        const vehicleToCreate = dialogResult;
        vehicleToCreate.groupId = this.selectedVehicleGroup.id;
        vehicleToCreate.hasNoTablet = !vehicleToCreate.tablet;

        this.vehicleService.createVehicle(vehicleToCreate)
          .then(response => {
            const newVehicle = response.data;
            this.selectedVehicleGroup.vehicles.push(newVehicle);
            this.selectedVehicleGroup.vehicles = [...this.selectedVehicleGroup.vehicles]; // update mat-table datasource
            this.updateVehicleGroupMenu();
          }).catch(error => {
            const msg = 'Error while adding vehicle';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
        });
      }
    });
  }

  addNewVehicleGroup() {
    const dialogRef = this.dialog.open(DialogCreateVehicleCategoryComponent, {
        width: '450px',
        data: new CreateVehicleGroupDialogInput(
            new VehicleGroupModel(),
            this.allInspectionForms,
            this.allObservationTypeGroups,
            this.allAllowedRouteConfigs,
            false
        )
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
            const candidate = dialogResult as VehicleGroupModel;
            // set the name the same as title
            candidate.name = candidate.title;
            this.clearInspectionFormsWhenNotUsingDvir(candidate);
            this.vehicleService.createVehicleCategory(candidate.toDto())
                .then(response => {
                    const newCategory = response.data;
                    const newVehicleCategory = VehicleGroupModelWithVehicles.fromDto(newCategory);
                    this.allVehicleGroupsWithVehicles.push(newVehicleCategory);
                    this.allVehicleGroupsWithVehicles.sort(VehicleGroupModelWithVehicles.vehicleGroupCompareFn);
                    this.selectVehicleGroupAndChangeQueryParams(newVehicleCategory);
                    this.updateVehicleGroupMenu();
                }).catch(error => {
                    const msg = 'Error while adding vehicle group';
                    this.toastService.long(msg);
                    console.error(`${msg} :: ${error}`);
            });
        }
    });
  }

  private clearInspectionFormsWhenNotUsingDvir(candidate: VehicleGroupModel) {
    if (!candidate.useDvir) {
      candidate.preTripForm = null;
      candidate.postTripForm = null;
    }
  }

  getVehicles(): VehicleModel[] {
    if (!this.allVehicleGroupsWithVehicles.length) {
      return [];
    }

    let vehicles = [];

    this.allVehicleGroupsWithVehicles.forEach(category => {
      if (category.vehicles.length) {
        vehicles = vehicles.concat(category.vehicles);
      }
    });

    return vehicles;
  }

  changePage(page: string) {
      this.changePageEvent.emit(page);
  }

  deselectDeletedVehicleGroup(deleted: VehicleGroupModelWithVehicles) {
    if (this.selectedVehicleGroup === deleted) {
      this.selectedVehicleGroup = null;
      this.changeVehicleGroupIdQueryParam(null);
    }
  }

  selectVehicleGroupAndChangeQueryParams(vehicleGroup: VehicleGroupModelWithVehicles) {
    this.selectedVehicleGroup = vehicleGroup;
    this.changeVehicleGroupIdQueryParam(this.selectedVehicleGroup.id);
  }

  isSpinnerShown(): boolean {
    return this.isLoading;
  }

  isNoGroupMsgShown() {
      return this.isLoadedAndEmpty()
          && this.selectedVehicleGroup == null
          && !this.vehicleGroupIdQueryParamIsSet();
  }

  isNoVehicleMsgShown(): boolean {
      return this.isLoadedAndNotEmpty()
          && this.selectedVehicleGroup != null
          && this.selectedVehicleGroup.vehicles.length <= 0;
  }

  isDetailShown(): boolean {
      return this.isLoadedAndNotEmpty()
          && this.selectedVehicleGroup != null
          && this.selectedVehicleGroup.vehicles.length > 0;
  }

  isNoSuchIdErrorShown(): boolean {
      return !this.isLoading
          && this.selectedVehicleGroup == null
          && this.vehicleGroupIdQueryParamIsSet();
  }

  isSelectionPromptShown(): boolean {
      return this.isLoadedAndNotEmpty()
          && this.selectedVehicleGroup == null &&
          !this.vehicleGroupIdQueryParamIsSet();
  }

  private vehicleGroupIdQueryParamIsSet() {
    const vehicleGroupIdQueryParam = this.activatedRoute.snapshot.queryParamMap.get('vehicle-group');
    return !!vehicleGroupIdQueryParam;
  }

  isAddVehicleDisabled(): boolean {
    return this.isLoading || this.selectedVehicleGroup == null || this.isImported;
  }

  updateVehicleGroupMenu() {
    this.vehicleGroupMenuItems = this.allVehicleGroupsWithVehicles.map(value => this.actionMenuItem(value));
  }

  private actionMenuItem(vehicleGroup: VehicleGroupModelWithVehicles) {
    return new ActionMenuItem(
      vehicleGroup.id,
      'directions_car',
      vehicleGroup.name,
      `${vehicleGroup.vehicles?.length} vehicles`,
      '',
      null,
      () => vehicleGroup === this.selectedVehicleGroup,
      null,
      () => { this.selectVehicleGroupAndChangeQueryParams(vehicleGroup); },
      null,
      null,
      [
        new ActionMenuItemSubMenu(
          'edit',
          'Edit group',
          () => { this.updateVehicleGroup(vehicleGroup.id); }
        ),
        new ActionMenuItemSubMenu(
          'delete',
          'Delete group',
          () => { this.deleteVehicleGroup(vehicleGroup.id); }
        )
      ]
    );
  }

  private isLoadedAndEmpty() {
      return !this.isLoading && this.allVehicleGroupsWithVehicles.length <= 0;
  }

  private isLoadedAndNotEmpty() {
      return !this.isLoading && this.allVehicleGroupsWithVehicles.length > 0;
  }

  private selectFirstVehicleGroup() {
      this.selectedVehicleGroup = this.allVehicleGroupsWithVehicles.length > 0 ? this.allVehicleGroupsWithVehicles[0] : null;
  }

  private selectFirstVehicleGroupAndChangeQueryParams() {
      if (this.allVehicleGroupsWithVehicles.length > 0) {
          this.selectVehicleGroupAndChangeQueryParams(this.allVehicleGroupsWithVehicles[0]);
      } else {
          this.selectedVehicleGroup = null;
      }
  }

  private changeVehicleGroupIdQueryParam(observationTypeGroupId: number) {
      this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { 'vehicle-group': observationTypeGroupId },
          queryParamsHandling: 'merge',
      });
  }
}
