import {Component, OnInit, OnDestroy, ViewChild, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import {LiveMapObservation} from '../../../models/live-map-observation.class';
import {ObservationsManagerService} from '../../../../../data/observations/observations-manager.service';
import {Observation} from '../../../../../shared/models/observation';
import {LiveMapDataService} from '../../../services/live-map-data.service';
import {ShiftModel} from '../../../../../shared/models/shift.model';
import {ShiftsManagerService} from '../../../../../data/shifts/shifts-manager.service';
import {ShiftsService} from '../../../../../data/shifts/shifts.service';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {VehiclesManagerService} from '../../../../../data/vehicles/vehicles-manager.service';
import moment from 'moment';
import {DateFilter} from '../../../../../shared/models/DateFilter';
import {SecurityService} from '../../../../../security/security.service';
import {ObservationsService} from '../../../../../data/observations/observations.service';
import {ObservationTypeGroup} from '../../../../../shared/models/observation-group';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {ShortDateOrTimePipe} from '../../../../../shared/formatting/short-date-or-time.pipe';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ImagesManagerService} from '../../../../../data/images/images-manager.service';

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss'],
})
export class ObservationsComponent implements OnInit, OnDestroy {
  observations: LiveMapObservation[] = [];
  selectedObservationId?: number;

  activeShifts: ShiftModel[] = null;
  items: ActionMenuItem[] = [];

  dateFilter: DateFilter = new DateFilter(null, null);
  observationTypeGroupFilter: number[] = undefined;

  isAdmin = false;
  private readonly openSubscriptions = Array<Subscription>();
  @ViewChild('rightPanelTemplate') rightPanelTemplate: TemplateRef<string>;

  constructor(
      private securityService: SecurityService,
      private activatedRoute: ActivatedRoute,
      private liveMapDataService: LiveMapDataService,
      private observationsManager: ObservationsManagerService,
      private observationsService: ObservationsService,
      private imageManager: ImagesManagerService,
      private shiftsManagerService: ShiftsManagerService,
      private shiftsService: ShiftsService,
      private configurationService: ConfigurationService,
      private vehiclesManagerService: VehiclesManagerService,
      private assetsManager: AssetsManagerService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
      private router: Router,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();
    this.liveMapDataService.sendRightPanelWidth(10);
    this.assetsManager.filterByVehicleIds([]);
    this.observationsManager.filterByShiftIds(null);
    this.imageManager.filterByShift(null);
    const that = this;

    // set active observation when coming through link
    this.activatedRoute.queryParams.subscribe((params) => {
      this.selectedObservationId = +params.id;
      if (!!params.id) {
        this.observationsManager.highlightObservation(
            +params.id,
            !!params.scrollToView ? ObservationsManagerService.MAP_SOURCE : ObservationsManagerService.URL_SOURCE
        );
      }
    });

    // initialize observations
    const observationSubscription = this.observationsManager.filteredObservations$.subscribe(observations => {
      this.filterObservations(observations);
    });
    this.openSubscriptions.push(observationSubscription);

    const activeObservationSubscription = this.observationsManager.highlightedObservation$.subscribe(activeObservation => {
      this.selectedObservationId = activeObservation?.observation?.id;

      if (activeObservation.source === ObservationsManagerService.MAP_SOURCE) {
        // update URL param
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {id: this.selectedObservationId},
          queryParamsHandling: '',
        });

        if (!!activeObservation?.observation) {
          // set small delay to make tab switch in time
          setTimeout(() => {
            const observationElement = document.getElementById(String(activeObservation.observation.id));
            if (!!observationElement) {
              observationElement.scrollIntoView({behavior: 'smooth'});
            }
          }, 200);
        }
      }
    });
    this.openSubscriptions.push(activeObservationSubscription);

    // observe active shifts
    const activeShiftSubscription = this.shiftsManagerService.activeShifts$.subscribe({
      next(e) {
        if (that.activeShifts === null) {
          that.handleActiveShiftsChanged(e.state);
        } else {
          if (e.removed.length > 0) {
            that.handleShiftsEnded(e.removed);
          }
          if (e.added.length > 0) {
            that.handleShiftsStarted(e.added);
          }
        }
      }
    });
    this.openSubscriptions.push(activeShiftSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  private handleActiveShiftsChanged(activeShifts: ShiftModel[]) {
    if (!this.activeShifts) {
      this.activeShifts = activeShifts;
      if (this.observations.length > 0) {
        this.observations.forEach(observation => {
          this.updateActiveShiftId(observation);
        });
      }
    }
  }

  private handleShiftsEnded(endedShifts: ShiftModel[]) {
    // update all observations with ended shift ids
    endedShifts.forEach(shift => {
      this.observations
          .filter(observation => observation.shiftId === shift.id)
          .forEach(observation => {
            observation.activeShift = false;
          });
    });

    // remove from activeShifts
    this.activeShifts = this.activeShifts.filter(shift => !endedShifts.map(endedShift => endedShift.id).includes(shift.id));
  }

  private handleShiftsStarted(startedShifts: ShiftModel[]) {
    this.activeShifts.push(...startedShifts);
  }

  private updateActiveShiftId(observation: LiveMapObservation) {
    if (!!this.activeShifts && this.observations.length > 0) {
      observation.activeShift = !!this.activeShifts && !!this.activeShifts.find(shift => shift.id === observation.shiftId);
    }
  }

  private createLiveMapObservation(observation: Observation, observationTypeGroup: ObservationTypeGroup): LiveMapObservation {
    const obsItem = new LiveMapObservation(
        observation.id,
        !!observation.observationType.mapLabel ? observation.observationType.mapLabel : observation.observationType.title,
        observationTypeGroup,
        observation.shiftId,
        observation.vehicle.id,
        observation.location.time,
    );
    this.updateActiveShiftId(obsItem);
    return obsItem;
  }

  private filterObservations(observationModels: Observation[]) {
    this.observations = [];
    const observationTypeGroups = this.observationsManager.getObservationTypeGroups();
    observationModels.forEach(observationModel => {
      const observationTypeGroup = observationTypeGroups.find(group => {
        return observationModel.observationType.observationTypeGroupId === group.id;
      });
      const observation = this.createLiveMapObservation(observationModel, observationTypeGroup);
      this.observations.splice(0, 0, observation);
    });
    this.updateItems();
  }

  viewShiftDetail(shiftId: number) {
    this.router.navigate(['/shift-detail', shiftId], {
      queryParams: {
        source: 'observations',
      },
    });
  }

  selectObservation(observation: LiveMapObservation) {
    this.observationsManager.highlightObservation(observation.id, ObservationsManagerService.LIST_ACTION_SOURCE);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { id: observation.id, scrollToView: undefined },
      queryParamsHandling: 'merge',
    });
  }

  deleteObservation(observationId: number, index: number) {
    this.observationsService.deleteObservation(observationId).then(() => {
      this.observations.splice(index, 1);
      this.updateItems();
    }).catch(error => {
      console.error('Observation deletion failed!');
      console.error(error);
    });
  }

  private updateItems() {
    this.observations.sort((a, b) => {
      return +new Date(b.time) - +new Date(a.time);
    });
    this.items = this.observations
        .filter(observation => {
          return this.dateFilter.from ? this.dateFilter.from.isBefore(moment(observation.time)) : true;
        })
        .filter(observation => {
          return this.dateFilter.to ? this.dateFilter.to.isAfter(moment(observation.time)) : true;
        })
        .filter(observation => {
          return !this.observationTypeGroupFilter || this.observationTypeGroupFilter.includes(observation.observationTypeGroup.id);
        })
        .map((observation, index) => {
      const actions: ActionMenuItemSubMenu[] = [];
      if (this.isAdmin) {
        actions.push(
            new ActionMenuItemSubMenu(
                'delete',
                'Delete',
                () => this.deleteObservation(observation.id, index),
            )
        );
      }
      if (!observation.activeShift) {
        actions.push(
            new ActionMenuItemSubMenu(
                'map',
                'View Shift Detail',
                () => this.viewShiftDetail(observation.shiftId),
            )
        );
      }
      return new ActionMenuItem(
          observation.id,
          'stars',
          observation.title,
          observation.observationTypeGroup?.name, // typeGroup might be deleted
          this.shortDateOrTimePipe.transform(observation.time),
          null,
          () => observation.id === this.selectedObservationId,
          null,
          () => this.selectObservation(observation),
          null,
          null,
          actions,
      );
    });
  }
}
