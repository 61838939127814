import { Action } from '@ngrx/store';

export enum ActionTypes {
  CloseSidenav = '[Live Map] CloseSidenav',
  OpenSidenav = '[Live Map] OpenSidenav',
}

export class CloseSidenav implements Action {
  readonly type = ActionTypes.CloseSidenav;
}
export class OpenSidenav implements Action {
  readonly type = ActionTypes.OpenSidenav;
}
