import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartegraphConfiguration} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {CartegraphMessagingService} from '../../../../../data/cartegraph/cartegraph-messaging';
import {of, Subscription, timer} from 'rxjs';
import {catchError, map, switchMap, takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-manage-cartegraph-fetch',
  templateUrl: './cartegraph-fetch.component.html',
  styleUrls: ['./cartegraph-fetch.component.scss'],
})
export class CartegraphFetchComponent implements OnInit, OnDestroy {

  isLoading = true;
  uiError: string;

  @Output()
  eventHandler = new EventEmitter<string>();

  @Input()
  configuration: CartegraphConfiguration;
  fetched = false;
  private fetchSubscriber: Subscription;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar,
    private messagingService: CartegraphMessagingService
  ) {
  }

  ngOnDestroy(): void {
    this.fetchSubscriber?.unsubscribe();
  }

  ngOnInit() {
    this.isLoading = false;
    // check/start observing in case if there is or is not the fetch running
    this.startFetchCheck(true);
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 5000, panelClass: ['snackbar_panel']});
  }

  fetchAllData() {
    // start observing fetch progress
    this.startFetchCheck(false);

    this.cartegraphManagementService.fetchAllData().subscribe(response => {
        if (response.error) {
          this.uiError = `Additional info: ${response.error}`;
        }
      },
      error => {
        console.log(error);
        this.uiError = error;
      });
  }

  private startFetchCheck(init = false) {
    const initialDelay = init ? 500 : 4000;
    this.isLoading = true;
    this.fetched = false;
    this.uiError = null;

    this.fetchSubscriber = timer(initialDelay, 5000).pipe(
      switchMap((project) =>
        this.cartegraphManagementService.fetchIsRunning().pipe(
          map((value) => value.data),
          catchError((err, caught) => {
            // console.log('error catch', [project, err, caught]);
            return of(true);
          }))
      ),
      takeWhile(response => response === true, true)
    ).subscribe((response) => {
      // not running on server
      if (response === false) {
        this.isLoading = false;
        if (!init) {
          this.fetched = true;
          this.messagingService.onDataReceived('cg_fetched');
          this.showSnackBar('Download finished.');
        }
      }
    });
  }

}
