<div class="setup-page-container">

    <div class="header-container">
        <h2 class="header-container-filters">
            My route configurations
        </h2>
        <div class="header-container-buttons">
            <div *ngIf="!isLoading">
                <button
                        (click)="addRouteConfiguration()"
                        mat-button
                        color="accent"
                >
                    <mat-icon>add</mat-icon>
                    Add Route Configuration
                </button>
            </div>

        </div>
    </div>

    <div class="content-container">
        <div class="selector-container">
            <div class="selector-container-list">
                <ng-container *ngFor="let leftItem of leftItems">
                    <app-action-menu-item style="width: 100%;" [input]="leftItem"></app-action-menu-item>
                </ng-container>
            </div>
        </div>
        <div class="divider"></div>
        <div class="selector-container">
            <div class="nodes-back-button">
                <button mat-icon-button
                        *ngIf="backButtonEnabled"
                        (click)="backButtonClicked()"
                >
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div class="nodes-breadcrumbs">{{ breadcrumbs }}</div>
            <div class="selector-container-list">
                <ng-container *ngFor="let rightItem of rightItems">
                    <app-action-menu-item style="width: 100%;" [input]="rightItem"></app-action-menu-item>
                </ng-container>
            </div>
        </div>

        <div class="map-preview-container">
            <app-map-preview
                    [nextContent]="mapContent"
            ></app-map-preview>
        </div>
    </div>
</div>
