<h1 mat-dialog-title>
  {{ headline }}
</h1>

<mat-dialog-content>
  <div class="data-section">
    Category Details
  </div>
  <div>
    <mat-form-field class="form-input" appearance="outline" color="accent">
      <mat-label>Name</mat-label>
      <input matInput
             autocomplete="off"
             [disabled]="data.isImported"
             [(ngModel)]="data.model.title"
             #ngName="ngModel"
             required
             [attr.cdkFocusInitial]="data.isImported !== true ? 'true' : null"
             >
      <mat-error *ngIf="ngName.invalid && ngName.errors.required">
        Name is required.
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="form-input form-select" appearance="outline" color="accent">
      <mat-label>Observation Type Groups</mat-label>
      <mat-select [(ngModel)]="data.model.observationTypeGroups" [compareWith]="namedIdComparison" multiple #ngGroups="ngModel">
        <mat-option *ngFor="let observationTypeGroup of data.allObservationTypeGroups" [value]="observationTypeGroup">{{observationTypeGroup.name}}</mat-option>
      </mat-select>
      <mat-hint>Specify which observation groups this vehicle group has access to</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="form-input form-select" appearance="outline" color="accent">
      <mat-label>Route Configurations</mat-label>
      <mat-select [(ngModel)]="data.model.allowedRouteConfigs" [compareWith]="namedIdComparison" multiple #ngGroups="ngModel">
        <mat-option *ngFor="let allowedRouteConfig of data.allAllowedRouteConfigs" [value]="allowedRouteConfig">{{allowedRouteConfig.name}}</mat-option>
      </mat-select>
      <mat-hint>Specify which route configurations this vehicle group has access to</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-checkbox [(ngModel)]="data.model.allowRouteSelfAssign">Allow Drivers to Self-Assign Routes</mat-checkbox>
  </div>

  <div class="data-section">
    Category Options
  </div>

  <ng-container *ngIf="configuration && hasFeatureFlag(FeatureFlagEnum.ReportDvir)">
    <p>
      <mat-checkbox [(ngModel)]="data.model.useDvir">Require Vehicle Inspections</mat-checkbox>
    </p>

      <ng-container *ngIf="data.model.useDvir
        && (!hasFeatureFlag(FeatureFlagEnum.CartegraphIntegration) || hasFeatureFlag(FeatureFlagEnum.CartegraphIntegrationNewDvir))">

      <mat-form-field class="form-sub-input form-select" appearance="outline" color="accent">
        <mat-label>Pre-Trip</mat-label>
        <mat-select [(ngModel)]="data.model.preTripForm" [compareWith]="namedIdComparison" #ngPreTripInspectionForm="ngModel">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let inspectionForm of data.allInspectionForms" [value]="inspectionForm">{{inspectionForm.name}}</mat-option>
        </mat-select>
        <mat-hint [ngClass]="{'mat-error': isInspectionFormMissingWhenUsingDvir()}">
          Specify pre-trip inspection form
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="form-sub-input form-select" appearance="outline" color="accent">
        <mat-label>Post-Trip</mat-label>
        <mat-select [(ngModel)]="data.model.postTripForm" [compareWith]="namedIdComparison" #ngPostTripInspectionForm="ngModel">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let inspectionForm of data.allInspectionForms" [value]="inspectionForm">{{inspectionForm.name}}</mat-option>
        </mat-select>
        <mat-hint [ngClass]="{'mat-error': isInspectionFormMissingWhenUsingDvir()}">
          Specify post-trip inspection form
        </mat-hint>
      </mat-form-field>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="configuration && hasFeatureFlag(FeatureFlagEnum.StationaryAlert)">
    <p>
      <mat-checkbox [checked]="data.model.maxStationaryTime !== null && data.model.maxStationaryTime !== undefined"
                    (change)="onMaxStationaryTimeCheckboxChange($event)"
                    class="form-input">
        Use Stationary Alert
      </mat-checkbox>
    </p>

    <p *ngIf="data.model.maxStationaryTime !== null && data.model.maxStationaryTime !== undefined">
      <mat-form-field class="indented" appearance="outline" color="accent">
        <mat-label>Maximum Stationary Time</mat-label>
        <input matInput
               type="number"
               min="10"
               [(ngModel)]="data.model.maxStationaryTime"
               #ngMaxStationaryTime="ngModel">
        <mat-hint>Number of minutes</mat-hint>
        <mat-error *ngIf="ngMaxStationaryTime.invalid">
          Minimal time is 10 minutes.
        </mat-error>
      </mat-form-field>
    </p>
  </ng-container>

  <div class="data-section">
    Tablet Default Settings
  </div>

  <p>
    <mat-checkbox [(ngModel)]="data.model.showVehicles" class="form-input">Show Other Vehicles</mat-checkbox>
    <mat-checkbox [(ngModel)]="data.model.showVehicleHistory" class="form-input">Show Other Vehicle History</mat-checkbox>
    <mat-checkbox [(ngModel)]="data.model.showVehicleObservations" class="form-input">Show Other Vehicle Observations</mat-checkbox>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cancel
  </button>
  <button mat-button [mat-dialog-close]="data.model" [disabled]="!isFormValid()" cdkFocusInitial>
    {{ actionButtonLabel }}
  </button>
</mat-dialog-actions>
