<div class="live-map-container" *ngIf="!isLoading">

  <div #mapContent class="map__content">
    <app-map-panel [rightSideControlsOffset]="currentRightPanelWidth"></app-map-panel>
  </div>

  <div
    class="live__left__content"
    [ngClass]="{ collapse: (sidenavOpen$ | async) === false }"
  >
    <app-live-map-left-panel
            *ngIf="configuration"
            [configuration]="configuration"
            (childComponent)="childComponentChanged($event)"
            class="left__panel"
    >
    </app-live-map-left-panel>

    <div class="left__panel__round-corners">
      <div class="left__panel__round-corners__box">
      </div>
    </div>

    <div class="left__map__overlay"
         [class.right-panel-10]="currentRightPanelWidth === 10"
         [class.right-panel-50]="currentRightPanelWidth === 50"
         [class.right-panel-200]="currentRightPanelWidth === 250"
    >
      <div class="overlay-top-content">
        <app-search-bar
                *ngIf="!!configuration"
                [configuration]="configuration"
                (searchResulted)="onSearchResult($event)"
        ></app-search-bar>

        <app-vehicle-group-filter-button
                class="overlay-filter"
                *ngIf="assetFiltersShown()"
                [origin]="'liveMap'"
                (filterChanged)="onVehicleGroupFilterChange($event)"
        >
        </app-vehicle-group-filter-button>

        <app-status-filter-button
                class="overlay-filter"
                *ngIf="assetFiltersShown()"
                [origin]="'liveMap'"
                (filterChanged)="onStatusFilterChange($event)"
        >
        </app-status-filter-button>

        <app-hardware-type-filter-button
                class="overlay-filter"
                *ngIf="assetFiltersShown()"
                [origin]="'liveMap'"
                (filterChanged)="onHardwareFilterChange($event)"
        >
        </app-hardware-type-filter-button>

        <app-observation-type-group-filter-button
                class="overlay-filter"
                *ngIf="observationFiltersShown()"
                [origin]="'liveMap'"
                (filterChanged)="onObservationTypeGroupFilterChange($event)"
        >
        </app-observation-type-group-filter-button>

        <app-date-range-filter-button
                class="overlay-filter"
                *ngIf="observationFiltersShown()"
                [origin]="'liveMap'"
                (filterChanged)="onDateFilterChange($event)"
        >
        </app-date-range-filter-button>

        <app-route-mode-toggle
                *ngIf="routeModeToggleShown()"
        ></app-route-mode-toggle>
      </div>

      <div class="overlay-bottom-left-content">
        <app-map-layer-switcher
                *ngIf="mapComponent && mapComponent.mapLayersManager"
                [mapLayersManager]="mapComponent.mapLayersManager"
                [showLocationHistory]="!locationLayersHidden()"
        >
        </app-map-layer-switcher>
      </div>

    </div>
  </div>

  <div class="live__right__content"
       [ngStyle]="{'width.px': currentRightPanelWidth + 10}"
  >
    <div class="right__map__overlay"
         [ngStyle]="{'right.px': currentRightPanelWidth}"
    >
    </div>

    <div class="right__panel__round-corners">
      <div class="right__panel__round-corners__box">
      </div>
    </div>

    <div class="right__panel"
         [ngStyle]="{'width.px': currentRightPanelWidth}"
    >
      <ng-container
              [ngTemplateOutlet]="childComponent?.rightPanelTemplate || defaultTemplate"
              [ngTemplateOutletContext]="{ $implicit: option}"
      >
      </ng-container>

      <ng-template #defaultTemplate let-option></ng-template>
    </div>
  </div>

  <div class="live__bottom__panel">
  </div>
</div>

<div class="loader-container" *ngIf="isLoading">
  <app-live-map-loader
    [message]="loadingDataName"
    [loadErrorMessage]="loadError"
  >
  </app-live-map-loader>
</div>
