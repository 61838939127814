export interface VehicleColor {
  id: number;
  color: string;
}

export class MapStyles {

  static readonly LIVE_COLOR = '#00AEF0';
  static readonly STATIONARY_COLOR = '#FF0000';
  static readonly HIGHLIGHTED_COLOR = '#5ACAF5';
  static readonly PAUSED_COLOR = '#F09600';
  static readonly DISABLED_COLOR = '#c4c4c4';
  static readonly SHIFT_START_COLOR = '#36cc4e';
  static readonly SHIFT_END_COLOR = '#000';
  static readonly INACTIVE_COLOR = 'rgb(119, 119, 119)'; // see TrackStyles.kt

  static readonly ROUTE_STATUS_SERVICED = '#87DC12';
  static readonly ROUTE_STATUS_INPROGRESS = '#00aef0';
  static readonly ROUTE_STATUS_NOTSERVICED = '#838383';

  static readonly ADDRESS_PASS_BREADCRUMB_DEFAULT = '#00ccbe';
  static readonly ADDRESS_PASS_BREADCRUMB_HIGHLIGHTED = '#ff9300';
  static readonly ADDRESS_PASS_BREADCRUMB_CLOSEST = '#00a2ff';

  /*
  // this was used when we highlighted tracks by vehicleId
  static getHighlightedProperty(vehicleIds: number[], highlighted: string, defaultValue: string) {
    if (vehicleIds && vehicleIds.length > 0) {
      return ['match', ['get', 'locationsourceid'], vehicleIds.map(id => String(id)), highlighted, defaultValue];
    } else {
      return defaultValue;
    }
  }
  */
  static getHighlightedProperty(shiftIds: number[], highlighted: string|number, defaultValue: string|number) {
    if (shiftIds && shiftIds.length > 0) {
      return ['match', ['get', 'sessionkey'], shiftIds, highlighted, defaultValue];
    } else {
      return defaultValue;
    }
  }

  static getHighlightedColor(shiftIds: number[], highlighted: string|number, defaultValue: string|number, customColors: VehicleColor[]) {
    let fallback;
    if (customColors && customColors.length > 0) {
      fallback = ['case'];
      customColors.forEach(vehicleColor => {
        fallback.push(['==', ['get', 'locationsourceid'], vehicleColor.id]);
        fallback.push(vehicleColor.color);
      });
      fallback.push(defaultValue);
    } else {
      fallback = defaultValue;
    }
    if (shiftIds && shiftIds.length > 0) {
      return ['match', ['get', 'sessionkey'], shiftIds, highlighted, fallback];
    } else {
      return fallback;
    }
  }
}
