<div class="shift-map-container" *ngIf="!isLoading">

  <div class="map__content" #mapContent>
    <app-map-panel
            *ngIf="!isMapConfigLoading"
            [bounds]="bounds"
            [useLiveFeed]="false"
            [shift]="shift"
            [rightSideControlsOffset]="50"
    ></app-map-panel>
  </div>

  <div
    class="shift__map__left__content"
    [ngClass]="{ collapse: (sidenavOpen$ | async) === false }"
  >
    <app-shift-map-panel
      [configuration]="configuration"
      [shift]="shift"
      [source]="source"
      class="left__panel"
    ></app-shift-map-panel>

    <div class="left__panel__round-corners">
      <div class="left__panel__round-corners__box">
      </div>
    </div>

    <div class="left__map__overlay">
      <div class="overlay-bottom-left-content">
        <app-map-layer-switcher
                [showLocationHistory]="false"
                *ngIf="mapComponent && mapComponent.mapLayersManager"
                [mapLayersManager]="mapComponent.mapLayersManager"
        >
        </app-map-layer-switcher>

        <div class="time__filter__wrapper">
          <app-time-filter-bar [shiftLength]="getShiftLength()"></app-time-filter-bar>
        </div>
      </div>

      <div class="map__loading__content"
           *ngIf="isMapConfigLoading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>

  <div
    class="shift__right__content"
    [ngClass]="{ collapse: (sidenavOpen$ | async) === false }"
  >
    <div class="right__panel__round-corners">
      <div class="right__panel__round-corners__box">
      </div>
    </div>
    <div class="right__panel">
      <button mat-icon-button
              *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam)"
              (click)="showDrawerPanel(DrawerContent.LIVE_VIDEO)"
              [ngClass]="{ 'active': activeDrawer === DrawerContent.LIVE_VIDEO }"
              class="ops-selectable-primary"
      >
        <mat-icon>tap_and_play</mat-icon>
      </button>
      <button mat-icon-button
              (click)="showDrawerPanel(DrawerContent.ROUTE)"
              [ngClass]="{ 'active': activeDrawer === DrawerContent.ROUTE }"
              class="ops-selectable-primary"
      >
        <mat-icon>route</mat-icon>
      </button>
      <button mat-icon-button
              (click)="showDrawerPanel(DrawerContent.OBSERVATION)"
              [ngClass]="{ 'active': activeDrawer === DrawerContent.OBSERVATION }"
              class="ops-selectable-primary"
      >
        <mat-icon>stars</mat-icon>
      </button>
    </div>
  </div>

  <div class="shift__bottom__panel">
  </div>
</div>
