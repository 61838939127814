export enum LiveMapTab {
    ASSETS = 'assets',
    ROUTES = 'routes',
    MESSAGES = 'messages',
    OBSERVATIONS = 'observations',
}

export enum LiveMapComponentEnum {
    ASSET = 'AssetComponent',
    ASSETS = 'AssetsComponent',
    ROUTES = 'RoutesComponent',
    OBSERVATIONS = 'ObservationsComponent',
    MESSAGES = 'MessagesComponent',
}
