export class Poi {
    readonly id: number;
    readonly name: string;
    readonly type: string;
    readonly attr1: string;
    readonly attr2: number;
    readonly latitude: number;
    readonly longitude: number;
}

export enum PoiType {
    MILEPOST = 'MILEPOST'
}
