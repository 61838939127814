import {Component, Input} from '@angular/core';

/**
 * Base filter bar component to get the filter value
 */
@Component({
  selector: 'item-composite',
  template: ''
})
export class FilterCompositeComponent {

  @Input() title: string;
  @Input() id: string;

  /**
   * Returns criteria for search, can be any type
   */
  criteria(): string {
    return undefined;
  }

  /**
   * Clears the filter value
   */
  clear() {
    // nothing
  }

}
