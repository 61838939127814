export class MifQuestionDto {
  id: number;
  rank: number;
  title: string;
  type: string;
  // "notApplicable"
  notApplicable?: boolean;

  constructor(id: number, rank: number, title: string, notApplicable?: boolean, type?: string) {
    this.id = id;
    this.rank = rank;
    this.title = title;
    this.type = type;
    this.notApplicable = notApplicable;
  }
}
