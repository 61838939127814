import { ActionTypes } from './live-map.actions';

const initialState = {
  sidenavOpen: true,
};

export function liveMapReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OpenSidenav: {
      return { ...state, sidenavOpen: true };
    }
    case ActionTypes.CloseSidenav: {
      return { ...state, sidenavOpen: false };
    }
    default:
      return state;
  }
}
