import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-menu-toggle-button',
  templateUrl: './menu-toggle-button.component.html',
  styleUrls: ['./menu-toggle-button.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class MenuToggleButtonComponent {
  @Input() collapseMenu?: boolean;
  @Input() closeSideMenu?: (args: any) => void;
  @Output() toggleSideMenu: EventEmitter<Event> = new EventEmitter<Event>();

  toggleMenu() {
    this.toggleSideMenu.emit();
  }
}
