<ng-container *ngIf="!!asset && !!asset.shiftId">
    <div class="observation-lines" *ngIf="!!items && items.length > 0">
        <ng-container *ngFor="let item of items">
            <div [id]="item.id">
                <app-action-menu-item [input]="item"></app-action-menu-item>
            </div>
        </ng-container>
    </div>

    <div *ngIf="!!asset && !!items && items.length === 0">
        <span>{{ asset.driverName }} has not created any observations on this shift.</span>
    </div>
</ng-container>

<div *ngIf="!!asset && !asset.shiftId">
    <span>Asset is offline</span>
</div>
