<h1 mat-dialog-title>
  Add Observation Type Group
</h1>

<mat-dialog-content class="dialog-content">
  <mat-form-field appearance="outline" color="accent" class="mat-form-field">
    <mat-label>Name</mat-label>
    <input matInput
           autocomplete="off"
           [(ngModel)]="data.name"
           #ngName="ngModel"
           minlength="2"
           maxlength="255"
           required
           cdkfocusinitial>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="mat-form-field">
    <mat-label>Duration</mat-label>
    <input matInput
           autocomplete="off"
           [(ngModel)]="data.duration"
           type="number"
           #ngDuration="ngModel"
           [min]="0"
           [max]="999"
           [step]="1"
           required>
    <mat-hint>Value in hours (e.g 1 = one hour and 0 = indefinite)</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="mat-form-field" floatLabel="always"
                  [(colorPicker)]="data.mapColor"
                  [cpPosition]="'auto'"
                  [cpFallbackColor]="defaultMapColor"
  >
    <mat-label>Map Color</mat-label>
    <input matInput
           autocomplete="off"
           [placeholder]="defaultMapColor"
           [value]="data.mapColor"
    >
    <button *ngIf="data.mapColor" matSuffix mat-icon-button aria-label="Clear"
            (click)="data.mapColor=null">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint>Leave blank to use default color (recommended)</mat-hint>
  </mat-form-field>
  <div class="mat-form-field">
    <mat-checkbox [(ngModel)]="data.isAudible"
                  [required]="true"
                  [matTooltip]="'When enabled, audible alert is played on the device'">Audible Alert
    </mat-checkbox>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="data" [disabled]="ngName.invalid || ngDuration.invalid">
    Add Group
  </button>
</mat-dialog-actions>
