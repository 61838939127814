<div class="mif-form-container">
    <ng-container *ngIf="loadState === LoadState.LOADED_ERROR">
        <div class="load-error">
            <span *ngIf="loadError !== null && loadError.length > 0">{{ loadError }}</span>
        </div>
    </ng-container>

    <ng-container *ngIf="loadState === LoadState.LOADING">

        <div class="loading__wrapper">
            <div>Loading inspection form</div>
            <mat-spinner class="mt-10"></mat-spinner>
        </div>

    </ng-container>

    <ng-container *ngIf="loadState === LoadState.LOADED_OK">

        <div class="mif-header-wrapper">
            <mat-form-field class="form-title" appearance="outline" color="accent" [floatLabel]="'always'">
                <mat-label>Form Name</mat-label>
                <input matInput type=text [(ngModel)]="inspectionForm.title" cdkFocusInitial >
            </mat-form-field>

            <div class="form-actions">
                <button mat-button
                        color="accent"
                        [disabled]="false"
                        (click)="buttonClickedCancel()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
                <button mat-button
                        color="accent"
                        [disabled]="buttonDisabledSave()"
                        [class.spinner]="saving"
                        (click)="buttonClickedSave()">
                    <mat-icon>save_as</mat-icon> Save
                </button>
            </div>
        </div>

        <div class="mif-section-wrapper">
            <div class="mif-section-icon">
                <mat-icon>fact_check</mat-icon>
            </div>
            <div class="mif-section">
                <div class="mif-section-title">
                    <label>{{ MifFormQuestionFormatOption.label }}</label>
                </div>
                <mat-radio-group
                        class="form-radio-group"
                        [(ngModel)]="inspectionForm.questionFormat">
                    <mat-radio-button class="form-radio-button" *ngFor="let option of MifFormQuestionFormatOption.options" [value]="option">
                        {{ option.label }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="mif-section-wrapper">
            <div class="mif-section-icon">
                <mat-icon>signature</mat-icon>
            </div>
            <div class="mif-section">
                <div class="mif-section-title">
                    <label>{{ MifFormReviewingSignaturesOption.label }}</label>
                </div>
                <mat-radio-group
                        class="form-radio-group"
                        [(ngModel)]="inspectionForm.reviewingSignatures">
                    <mat-radio-button class="form-radio-button" *ngFor="let option of MifFormReviewingSignaturesOption.options" [value]="option">
                        {{ option.label }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="mif-section-wrapper">
            <div class="mif-section-icon">
                <mat-icon>chat</mat-icon>
            </div>
            <div class="mif-section">
                <div class="mif-section-title">
                    <label>Remarks</label>
                </div>
                <mat-radio-group
                        class="form-radio-group"
                        [(ngModel)]="inspectionForm.hasRemarks">
                    <mat-radio-button class="form-radio-button" [value]="true">
                        Include input box for Additional Remarks at the end of form
                    </mat-radio-button>
                    <mat-radio-button class="form-radio-button" [value]="false">
                        None
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="mif-section-wrapper">
            <div class="mif-section-icon">
                <mat-icon>email</mat-icon>
            </div>
            <div class="mif-section">
                <div class="mif-section-title">
                    <label>Email Options</label>
                </div>
                <mat-checkbox class="form-radio-button"
                              [(ngModel)]="inspectionForm.settings.enableEmailNotifications"
                              (change)="toggleEmailNotifications()"
                >
                    Enable email notifications
                </mat-checkbox>
                <div *ngIf="inspectionForm.settings.enableEmailNotifications" class="form-checkbox">
                    <mat-radio-group
                            class="form-radio-group"
                            [(ngModel)]="inspectionForm.settings.emailNotificationType">
                        <mat-radio-button class="form-radio-button" [value]="MifFormEmailNotificationType.ON_DEFECT">
                            Email if Defect is Encountered
                        </mat-radio-button>
                        <mat-radio-button class="form-radio-button" [value]="MifFormEmailNotificationType.ON_OUT_OF_SERVICE">
                            Email if Vehicle is Taken out of Service
                        </mat-radio-button>
                        <mat-radio-button class="form-radio-button" [value]="MifFormEmailNotificationType.ALL">
                            Email on All Submissions
                        </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="!!allUserEmails" class="form-radio-button">
                        <div>
                            Recipients:
                        </div>
                        <div *ngFor="let email of allUserEmails">
                            <mat-checkbox [checked]="isEmailRecipient(email)"
                                          (change)="toggleEmailRecipient($event, email)"
                            >
                                {{ email }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mif-section-wrapper">
            <div class="mif-section-icon">
                <mat-icon>quiz</mat-icon>
            </div>
            <div class="mif-section">
                <div class="mif-section-title">
                    <label>Questions</label>
                    <button mat-icon-button
                            aria-label="Add question"
                            color="accent"
                            [matMenuTriggerFor]="menu"
                            [matTooltip]="'Add to form'"
                    >
                        <mat-icon>add_circle</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                (click)="formMenuItemClickedAddSection()"
                        >
                            <span>Section</span>
                        </button>
                        <button mat-menu-item
                                (click)="formMenuItemClickedAddGroup()"
                        >
                            <span>Group</span>
                        </button>
                        <button mat-menu-item
                                (click)="formMenuItemClickedAddQuestion()"
                        >
                            <span>Question</span>
                        </button>
                    </mat-menu>
                </div>
                <p *ngIf="unsupportedStructure">
                    <span class="structure-warning">You cannot combine sections / groups / questions on one level!</span>
                </p>

                <p *ngIf="!inspectionForm.hasChildNodes()">
                    <span class="sentence-icon">Choose an action from the <mat-icon color="accent">add_circle</mat-icon> button to begin creating questions.</span>
                </p>

                <app-inspection-form-tree
                        [nodes]="nodes"
                        [nodeOperations]="nodeOperations"
                        (moveEvent)="moveEvent($event)"
                        (selectEvent)="selectEvent($event)"
                        (deselectEvent)="deselectEvent()"
                        (collapseEvent)="collapseEvent()"
                        (clickedDeselectEvent)="treeButtonClickedDeselect()"
                        (clickedAddQuestionEvent)="treeMenuItemClickedAddQuestion()"
                        (clickedAddGroupEvent)="treeMenuItemClickedAddGroup()"
                        (clickedEditEvent)="treeButtonClickedEdit()"
                        (clickedRemoveEvent)="treeButtonClickedRemove()"
                ></app-inspection-form-tree>
            </div>
        </div>
    </ng-container>
</div>
