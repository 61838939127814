import {NamedItem} from './named-item';

export class DriverModel implements NamedItem {

  created: Date;
  constructor(public readonly id: number,
              public readonly name: string) {}

  get title(): string {
    return this.name;
  }
}

export class Driver {
  id: number;
  name: string;
  login: string;
  guest: boolean;
}

export class DriverWithShiftCount extends DriverModel {
  created: Date;
  shiftCount: number;
}

export class DriverStats {
  id: number;
  trackLength: number;
  hoursInService: number;
  shiftCount: number;
}

export class DriverListUpdate {
  readonly operation = 'add';

  constructor(readonly driver: DriverModel) {}
}

/*csv import driver*/

export class DriverImport {
  index: number;
  row: CsvImportDriver;
  dbData: DriverImportDbData;
  errors?: string[];
  action: string;
  selected: boolean;
}

class DriverImportDbData {
  driver?: Driver;
}

export class CsvImportDriver {
  nane: string;
  sendWelcomeEmail: boolean;
}
