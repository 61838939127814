<div class="top-bar">
    <button
            class="back__btn"
            (click)="backToAssetList()"
            mat-icon-button
    >
        <mat-icon class="action-icon">arrow_back</mat-icon>
    </button>
</div>

<div class="content">
    <div class="content-title-1">
        {{ asset.name }} <span *ngIf="asset.shiftStatus === ShiftState.ENDED">(offline)</span>
    </div>

    <div class="content-paragraph"
         *ngIf="asset.shiftStatus === ShiftState.ENDED">
        <button
                mat-button
                color="accent"
                type="submit"
                [routerLink]="['/insights', InsightsRoute.SHIFT_LOOKUP]"
                [queryParams]="{'category': 'vehicles', 'id': asset.id}"
        >
            View past shifts for this vehicle
        </button>
    </div>

    <ng-container *ngIf="asset.shiftStatus !== ShiftState.ENDED">
        <div class="content-title-2">
            {{ asset.driverName }}<span *ngIf="asset.driverIsGuest"> - Guest</span>
        </div>

        <div class="content-title-3">
            {{ assetMovementAndLocationDescription }}
        </div>

        <div class="content-paragraph"
             *ngIf="asset.vehicleHardwareConfiguration &&
          asset.vehicleHardwareConfiguration.sensorInputs &&
          asset.vehicleHardwareConfiguration.sensorInputs.length > 0">
            <ng-container
                    *ngFor="let sensorInput of HardwareConfiguration.getValidInputs(asset.vehicleHardwareConfiguration.sensorInputs)">
                <div class="content-row" *ngIf="sensorInput.type === SensorType.PLOW || sensorInput.type === SensorType.PLOW2W">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Plow' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | plowStatus: asset.flags }}
                    </div>
                </div>
                <div class="content-row" *ngIf="sensorInput.type === SensorType.GRANULAR_SPREADER">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Granular Spreader' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                        ({{ sensorInput.rate || 'N/A' }} {{'granular' | rateUnit:configuration.useMetricSystem}})
                    </div>
                </div>
                <div class="content-row" *ngIf="sensorInput.type === SensorType.DIGITAL_GRANULAR_SPREADER && !!asset.digitalGranularStatus">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Digital Granular Spreader' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                        ({{ (asset.digitalGranularStatus.controllerCurrentRate | spreaderMass:configuration.useMetricSystem) || 'N/A' }})
                    </div>
                </div>
                <div class="content-row" *ngIf="sensorInput.type === SensorType.LIQUID_SPREADER">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Liquid Spreader' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                        ({{ sensorInput.rate || 'N/A' }} {{ 'liquid' | rateUnit:configuration.useMetricSystem }})
                    </div>
                </div>
                <div class="content-row" *ngIf="sensorInput.type === SensorType.DIGITAL_LIQUID_SPREADER && !!asset.digitalLiquidStatus">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Digital Liquid Spreader' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | spreaderStatus: asset.flags }}
                        ({{ (asset.digitalLiquidStatus.controllerCurrentRate | spreaderLiquid:configuration.useMetricSystem) || 'N/A' }})
                    </div>
                </div>
                <div class="content-row" *ngIf="sensorInput.type === SensorType.MOWER">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Mower' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | mowerStatus: asset.flags }}
                    </div>
                </div>
                <div class="content-row" *ngIf="sensorInput.type === SensorType.SWEEPER">
                    <div class="info-name">
                        {{ !!sensorInput.label ? sensorInput.label : 'Sweeper' }}:
                    </div>
                    <div class="info-value ops-link">
                        {{ sensorInput.bitNumber | sweeperStatus: asset.flags }}
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="content-paragraph">
            <div class="content-row" *ngIf="asset.vehicleLmuId">
                <div class="info-name">
                    LMU:
                </div>
                <div class="info-value ops-link">
                    {{ asset.vehicleLmuId }}
                </div>
            </div>
            <div class="content-row">
                <div class="info-name">
                    Shift Start:
                </div>
                <div class="info-value ops-link" [matTooltip]="asset.shiftStartTime | date: 'shortDate'">
                    {{ asset.shiftStartTime | date: 'shortTime' }}
                </div>
            </div>
            <div class="content-row">
                <div class="info-name">
                    Shift Duration:
                </div>
                <div class="info-value ops-link">
                    {{ asset.shiftDuration | duration }}
                </div>
            </div>
            <div class="content-row">
                <div class="info-name">
                    <ng-container *ngIf="!!configuration">
                        <span *ngIf="!configuration.useMetricSystem">Miles Driven:</span>
                        <span *ngIf="configuration.useMetricSystem">Distance Driven:</span>
                    </ng-container>
                </div>
                <div class="info-value ops-link">
                <span *ngIf="!!configuration">
                    {{ (asset.distanceDriven | distance:configuration.useMetricSystem) || 'N/A' }}
                </span>
                </div>
            </div>
        </div>

        <div class="content-paragraph" *ngIf="!asset.hasNoTablet">
            <div class="content-row">
                <div class="info-name">
                    Current Assignment:
                </div>
                <div class="info-value ops-link">
                    <span *ngIf="asset.routeAssignmentStatus === RouteAssignmentStatus.ON_ASSIGNMENT">{{ asset.currentRoute.routeName }}</span>
                    <span *ngIf="asset.routeAssignmentStatus !== RouteAssignmentStatus.ON_ASSIGNMENT">None</span>
                </div>
            </div>
            <div class="content-row" *ngIf="!!completeRouteAssignments && completeRouteAssignments.length > 0">
                <div class="info-name">
                    Number completed:
                </div>
                <div class="info-value ops-link">
                    {{ completeRouteAssignments.length }}
                </div>
            </div>
            <div class="content-row" *ngIf="!!completeRouteAssignments && completeRouteAssignments.length > 0">
                <div class="info-name">
                    AVG distance per assignment:
                </div>
                <div class="info-value ops-link">
                    {{ (avgDistancePerAssignment / 1000 || 0) | distance:configuration.useMetricSystem:false }}
                    <span *ngIf="configuration.useMetricSystem">km</span><span *ngIf="!configuration.useMetricSystem">mi</span>
                </div>
            </div>
            <div class="content-row" *ngIf="!!completeRouteAssignments && completeRouteAssignments.length > 0">
                <div class="info-name">
                    AVG time per assignment:
                </div>
                <div class="info-value ops-link">
                    <span [matTooltip]="'HH:MM'">{{ (avgTimePerAssignment || 0) | hoursMinutes }}</span>
                </div>
            </div>
        </div>

        <div class="content-paragraph" *ngIf="!asset.hasNoTablet && activeDrawer !== DrawerContent.ROUTE">
            <div class="content-row">
                <button
                        mat-button
                        color="accent"
                        [routerLink]="[]"
                        [queryParams]="{'drawer': DrawerContent.ROUTE}"
                >
                    <span *ngIf="!!isAdmin">Manage</span><span *ngIf="!isAdmin">View</span> Route Assignments
                </button>
            </div>
        </div>

        <div class="content-paragraph">
            <div class="action__row mt-15">
                <button
                        mat-button
                        color="warn"
                        type="submit"
                        (click)="forceEndShift(asset.shiftId)"
                >
                    End Shift
                </button>
            </div>
        </div>
    </ng-container>
</div>

<!-- right panel -->
<ng-template #rightPanelTemplate let-option>
    <button mat-icon-button
            *ngIf="hasFeatureFlag(FeatureFlagEnum.FleetMessaging)"
            (click)="showDrawerPanel(DrawerContent.QUICK_MESSAGE)"
            [ngClass]="{ 'active': activeDrawer === DrawerContent.QUICK_MESSAGE }"
            class="ops-selectable-primary"
    >
        <mat-icon>message</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam)"
            (click)="showDrawerPanel(DrawerContent.LIVE_VIDEO)"
            [ngClass]="{ 'active': activeDrawer === DrawerContent.LIVE_VIDEO }"
            class="ops-selectable-primary"
    >
        <mat-icon>tap_and_play</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="!asset.hasNoTablet"
            (click)="showDrawerPanel(DrawerContent.ROUTE)"
            [ngClass]="{ 'active': activeDrawer === DrawerContent.ROUTE }"
            class="ops-selectable-primary"
    >
        <mat-icon>route</mat-icon>
    </button>
    <button mat-icon-button
            (click)="showDrawerPanel(DrawerContent.OBSERVATION)"
            [ngClass]="{ 'active': activeDrawer === DrawerContent.OBSERVATION }"
            class="ops-selectable-primary"
    >
        <mat-icon>stars</mat-icon>
    </button>
</ng-template>
