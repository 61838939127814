<div class="filter-container mat-elevation-z2">
    <div class="section-1 border--right">
        <div class="section-1-text bolder">Time Filter</div>
    </div>
    <div class="section-2">

        <div>
            <button
                    mat-button
                    [matMenuTriggerFor]="menu"
                    class="section__text speed__btn"
            >
                <span>{{ speed }}x</span>
            </button>

            <mat-menu #menu="matMenu" class="speed__menu">
                <ng-container *ngFor="let speed of speeds">
                    <button mat-menu-item (click)="speedChanged(speed)">{{ speed }}x</button>
                </ng-container>
            </mat-menu>
        </div>

        <button mat-button color="accent"
                (click)="actionTriggered(PlaybackAction.PLAY)"
                class="action-button"
                *ngIf="playbackStatus === PlaybackStatus.STOPPED">
            <mat-icon>play_arrow</mat-icon>
        </button>
        <button mat-button color="accent"
                (click)="actionTriggered(PlaybackAction.RESUME)"
                class="action-button"
                *ngIf="playbackStatus === PlaybackStatus.PAUSED">
            <mat-icon>play_arrow</mat-icon>
        </button>
        <mat-spinner [diameter]="18"
                     *ngIf="playbackStatus === 'loading'"></mat-spinner>
        <button mat-button color="accent"
                (click)="actionTriggered(PlaybackAction.PAUSE)"
                class="action-button"
                *ngIf="playbackStatus === PlaybackStatus.PLAYING">
            <mat-icon>pause</mat-icon>
        </button>
        <button mat-button color="accent"
                (click)="actionTriggered(PlaybackAction.STOP)"
                class="action-button"
                *ngIf="playbackStatus === PlaybackStatus.PLAYING || playbackStatus === PlaybackStatus.PAUSED">
            <mat-icon>stop</mat-icon>
        </button>

        <ng-container *ngIf="playbackStatus !== PlaybackStatus.STOPPED">
            <div class="progress-time-label" [matTooltip]="'HH:MM:SS'">{{ progressTime | hoursMinutesSeconds }}</div>
            <mat-slider color="accent"
                        [min]="0"
                        [max]="shiftLength"
                        class="progress-bar">
                <input matSliderThumb
                       [(ngModel)]="progressTime"
                       (change)="progressChanged()">
            </mat-slider>
            <div class="time-label" [matTooltip]="'HH:MM:SS'">{{ (absProgressTime * 1000) | date:'mediumTime' }}</div>
        </ng-container>

        <ng-container *ngIf="playbackStatus === PlaybackStatus.STOPPED">
            <div class="time-label-minutes" [matTooltip]="'HH:MM'">{{ timeFilterPctFrom * shiftLength / 100 | hoursMinutes }}</div>
            <mat-slider color="accent"
                        class="progress-bar"
                        [min]="timeFilterOptions.floor"
                        [max]="timeFilterOptions.ceil">
                <input matSliderStartThumb
                       [(ngModel)]="timeFilterPctFrom"
                       (change)="timeFilterChanged()">
                <input matSliderEndThumb
                       [(ngModel)]="timeFilterPctTo"
                       (change)="timeFilterChanged()">
            </mat-slider>
            <div class="time-label-minutes" [matTooltip]="'HH:MM'">{{ timeFilterPctTo * shiftLength / 100 | hoursMinutes }}</div>
        </ng-container>
    </div>
</div>
