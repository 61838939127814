import {Map} from 'maplibre-gl';
import {MapContent} from './MapContent';

export class EmptyMapContent implements MapContent {

  static instance = new EmptyMapContent();

  load(map: Map) {
  }

  unload() {
  }
}
