import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../../../../shared/models/route';
import {RmNode} from './RmNode';
import {RmRoute} from './RmRoute';
import {McRouteConfiguration} from '../../../../../shared/components/map-preview/model/McRouteConfiguration';
import {NodesAndBreadcrumbs} from './NodesAndBreadcrumbs';
import {ErrorTools} from '../../../../../shared/tools/ErrorTools';

export class RmConfiguration implements RmNode {
  base: RouteConfigurationWithSchema;
  children: RmNode[];

  level = 1;
  parent: RmNode = null;
  selected = false;

  constructor(base: RouteConfigurationWithSchema,
              children: RmNode[]) {
    this.base = base;
    this.children = children;
  }

  static fromRouteConfiguration(routeConfiguration: RouteConfigurationWithSchema): RmConfiguration {
    const unprocessedChildren = routeConfiguration.configuration?.children;
    const children = (unprocessedChildren == null) ? [] : unprocessedChildren
      .filter(value => !value.hidden)
      .sort(RouteHierarchyItem.routeHierarchyItemCompareFn)
      .map(value => RmRoute.fromRouteHierarchyItem(value, 2));
    const config = new RmConfiguration(
      routeConfiguration,
      children
    );
    config.setParentOnChildren();
    return config;
  }

  /**
   * Creates input for route configuration map preview content using original structure's function
   * to get only leaf route nodes with explicitly set color (either inherited or an override).
   */
  toMapConfiguration(): McRouteConfiguration {
    const visibleRouteIds = [];
    this.collectVisibleRouteIds(visibleRouteIds);
    return McRouteConfiguration.fromBase(this.base, { routeIds: visibleRouteIds, all: false }, this.selected);
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  isSelected(): boolean {
    return this.selected;
  }

  setParentOnMe(parent: RmNode) {
    this.parent = parent;
  }

  setParentOnChildren() {
    this.children.forEach(value => value.setParentOnMe(this));
  }

  collectVisibleRouteIds(routeIds: number[]) {
    if (this.selected) {
      const selectedChild = this.children.find(value => value.isSelected());
      if (selectedChild == null) {
        this.collectRouteIds(routeIds);
      } else {
        selectedChild.collectVisibleRouteIds(routeIds);
      }
    }
  }

  collectRouteIds(routeIds: number[]) {
    this.children.forEach(value => value.collectRouteIds(routeIds));
  }

  breadcrumbsR(breadcrumbs: string[]): string[] {
    return this.parent.breadcrumbsR([this.base.name, ...breadcrumbs]);
  }

  rightColumnNodesR(): NodesAndBreadcrumbs {
    const selectedChild = this.children.find(value => value.isSelected());
    if (selectedChild == null) {
      return new NodesAndBreadcrumbs(null, this.breadcrumbsR([]), this.children);
    } else {
      if (selectedChild.isIntermediary()) {
        return selectedChild.rightColumnNodesR();
      } else {
        return new NodesAndBreadcrumbs(null, selectedChild.breadcrumbsR([]), this.children);
      }
    }
  }

  delete() {
    this.parent.deleteChild(this);
  }

  deleteChild(it: RmNode) {
    const foundIndex = this.children.findIndex(value => value.isSimilarTo(it));
    if (foundIndex >= 0) {
      this.children.splice(foundIndex, 1);
    }
  }

  isSimilarTo(it: RmNode) {
    return it.isConfig() && this.base.id === it.asConfigBase().id;
  }

  actionMenuItemId(): number {
    return this.base.id;
  }

  actionMenuItemTitle(): string {
    return this.base.name;
  }

  actionMenuItemSubTitle(): string {
    const routeIds = [];
    this.collectRouteIds(routeIds);
    return String(routeIds.length);
  }

  select() {
    this.parent.unselectMyChildren();
    this.selected = true;
  }

  unselectMyChildren() {
    this.children.forEach(value => value.unselectMeAndMyChildren());
  }

  unselectMeAndMyChildren() {
    this.selected = false;
    this.children.forEach(value => value.unselectMeAndMyChildren());
  }

  private isLeaf(): boolean {
    return this.children.length <= 0;
  }

  isRoot(): boolean {
    return false;
  }

  isConfig(): boolean {
    return true;
  }

  isIntermediary(): boolean {
    return false;
  }

  isRoute(): boolean {
    return false;
  }

  asConfigBase(): RouteConfigurationWithSchema {
    return this.base;
  }

  asRouteOrIntermediaryBase(): RouteHierarchyItem {
    throw ErrorTools.unsupported();
  }

  collectPathIdsForQueryParamsR(pathIds: string[]) {
    if (this.selected) {
      pathIds.push(this.base.id.toString());
      this.children.forEach(value => value.collectPathIdsForQueryParamsR(pathIds));
    }
  }

  applyPathIdsFromQueryParamsR(remainingIds: string[]) {
    const currentId = remainingIds[0];
    let currentIdMatches = false;
    if (this.base.id.toString() === currentId) {
      currentIdMatches = true;
    }
    this.selected = currentIdMatches;
    if (currentIdMatches) {
      remainingIds.splice(0, 1);
      if (remainingIds.length > 0) {
        this.children.forEach(value => value.applyPathIdsFromQueryParamsR(remainingIds));
      }
    }
  }
}
