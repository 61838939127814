<h1 mat-dialog-title>
  Delete Shift
</h1>

<mat-dialog-content>
  <p>
    Do you really want to delete shift with ID=<i>{{ data.id }}</i> ?
  </p>
  <p>
    Ended On: {{ data.end | date:'medium' }}<br />
    Vehicle: {{ data.vehicle.name }}<br />
    Driver: {{ data.driver.name }}<br />
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cancel
  </button>
  <button mat-button [mat-dialog-close]="data.id" cdkFocusInitial>
    Yes, delete please
  </button>
</mat-dialog-actions>
