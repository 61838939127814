export class DataSourceModel {
    name: string;
    layers: LayerBasicModel[];
}

export class LayerBasicModel {
    id: number;
    name: string;
}

export class LayerModel {
    name: string;
    fields: FieldFormat[];
}

export class FieldFormat {
    name: string;
    format: string;
}

export class RouteConfiguration {
    childrenAttribute: string;
    children: RouteHierarchyItem[];
}

export class RouteHierarchyItem {
    childrenAttribute: string;
    value: string;
    children: RouteHierarchyItem[];
    routeId: number;
    routeName: string;
    color?: string;
    lineType?: number[];
    hidden?: boolean;
    configId?: number;

    public static routeHierarchyItemCompareFn = (a, b) => a.value.localeCompare(b.value);

    static getVisibleItems(hierarchyItems: RouteHierarchyItem[], routeConfigId: number): RouteHierarchyItem[] {
        const visibleItems = hierarchyItems
            .filter(it => !it.hidden)
            .sort(RouteHierarchyItem.routeHierarchyItemCompareFn);
        visibleItems.forEach(it => {
            if (it.children && it.children.length > 0) {
                it.children = RouteHierarchyItem.getVisibleItems(it.children, routeConfigId);
            } else {
                it.configId = routeConfigId;
            }
        });
        return visibleItems;
    }

    static getFlat(levels: number, hierarchyItems: RouteHierarchyItem[]): RouteHierarchyItem[] {
        const selectedItems: RouteHierarchyItem[] = [];
        selectedItems.push(...hierarchyItems);
        let levelItems: RouteHierarchyItem[] = hierarchyItems;
        for (let i = levels - 1; i > 0; i--) {
            levelItems = levelItems
                .filter(item => !!item.children)
                .map(items => items.children)
                .reduce((accumulator, value) => accumulator.concat(value), []);
            selectedItems.push(...levelItems);
        }
        return selectedItems;
    }

    static getLeaves(levels: number, hierarchyItems: RouteHierarchyItem[]): RouteHierarchyItemWithPath[] {
        let selectedItems: RouteHierarchyItemWithPath[] = hierarchyItems.filter(item => !item.hidden);
        for (let i = levels - 1; i > 0; i--) {
            selectedItems = selectedItems
                .filter(item => !item.hidden)
                .map(item => {
                    return (item.children as RouteHierarchyItemWithPath[]).map(oldChild => {
                        const child = Object.assign({}, oldChild);
                        if (!child.path) {
                            child.path = !item.path ? [] : [...item.path];
                        }
                        child.path.push(item.value);
                        return child;
                    });
                })
                .reduce((accumulator, value) => accumulator.concat(value), []);
        }
        return selectedItems.filter(item => !item.hidden);
    }
    static getLeavesWithExplicitColors(levels: number, hierarchyItems: RouteHierarchyItem[]): RouteHierarchyItem[] {
        let selectedItems: RouteHierarchyItem[] = JSON.parse(JSON.stringify(hierarchyItems));
        for (let i = levels - 1; i > 0; i--) {
            selectedItems = selectedItems
                .map(item => {
                    // pass color downstream if not overridden
                    if (!!item.color) {
                        item.children.forEach(child => {
                            if (!child.color) {
                                child.color = item.color;
                            }
                        });
                    }
                    if (!!item.lineType) {
                        item.children.forEach(child => {
                            if (!child.lineType) {
                                child.lineType = item.lineType;
                            }
                        });
                    }
                    return item.children;
                })
                .reduce((accumulator, value) => accumulator.concat(value), []);
        }
        return selectedItems;
    }
    static getByPath(path: string[], hierarchyItems: RouteHierarchyItem[]): RouteHierarchyItem {
        let items = hierarchyItems;
        let item: RouteHierarchyItem;
        for (const value of path) {
            item = items.find(hierarchyItem => hierarchyItem.value === value);
            if (!item) {
                return null;
            } else {
                items = item.children;
            }
        }
        return item;
    }
    static getPathLabel(path: string[], hierarchyItems: RouteHierarchyItem[]): string {
        let items = hierarchyItems;
        let item: RouteHierarchyItem;
        let label = '';
        for (const [index, pathValue] of path.entries()) {
            item = items.find(hierarchyItem => hierarchyItem.value === pathValue);
            if (!item) {
                return label;
            } else {
                // apply only for last string in path
                if (path.length === index + 1) {
                    if (index > 0) {
                        label = label + '.. / ';
                    }
                    if (item.routeId === -1) {
                        label = label + item.routeName;
                    } else {
                        label = label + item.value;
                    }
                }
                items = item.children;
            }
        }
        return label;
    }
}

export class RouteConfigurationWithSchema {
    id?: number;
    schemaUpdated: Date;
    configurationGenerated: Date;

    constructor(
        public name: string,
        public url: string,
        public username: string,
        public password: string,
        public layerId: number,
        public layerName: string,
        public updateCustomLayers: boolean,
        public schema: RouteSchema,
        public configuration: RouteConfiguration,
    ) {}

    public static routeConfigurationCompareFn = (a, b) => a.name.localeCompare(b.name);
}

export class RouteSchema {
    constructor(
        public classification: RouteImportAttribute[],
        public style: RouteStyle,
    ) {}
}

export class RouteImportAttribute {
    constructor(
        public attribute: string
    ) {}
}

export class RouteStyle {
    constructor(
        public color: string,
        public width: number,
        public opacity: number,
        public lineType?: number[],
    ) {}
}

export class RouteHierarchyItemWithPath extends RouteHierarchyItem {
    path?: string[];
}

export enum TaskStatus {
    IN_PROGRESS= 'IN_PROGRESS',
    DONE = 'DONE',
    FAILURE = 'FAILURE',
    UNKNOWN = 'UNKNOWN',
}
