import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoutesManagerService} from '../../../../../../data/routes/routes-manager.service';
import {RouteAssignment} from '../../../../../../shared/models/route-assignment';
import {ActionMenuItem} from '../../../../../../shared/models/action-menu-item.class';
import moment from 'moment/moment';
import {RouteAssignmentService} from '../../../../../../data/routes/route-assignment.service';
import {ShortDateOrTimePipe} from '../../../../../../shared/formatting/short-date-or-time.pipe';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../../../configuration/configuration.service';
import {ConfigurationModel} from '../../../../../../shared/models/configuration.model';
import {RouteAssignmentManagerService} from '../../../../../../data/routes/route-assignment-manager.service';

@Component({
  selector: 'app-completed-assignments-stats',
  templateUrl: './completed-assignments-stats.component.html',
  styleUrls: ['./completed-assignments-stats.component.scss']
})
export class CompletedAssignmentsStatsComponent implements OnInit, OnDestroy {

  filterRouteConfigId: number = null;
  filterRouteIds: number[] = null;

  allRecentRouteAssignments: RouteAssignment[];
  completeRouteAssignments: RouteAssignment[];
  inProgressRouteAssignments: RouteAssignment[];
  completedItems: ActionMenuItem[] = [];
  assignmentInProgressCount: number;

  // completed metrics
  totalDistance: number;
  totalTime: number;
  avgDistancePerAssignment: number;
  avgTimePerAssignment: number;

  timeFromHours = 24;
  configuration: ConfigurationModel;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private configurationService: ConfigurationService,
      private routeAssignmentService: RouteAssignmentService,
      private routeAssignmentManager: RouteAssignmentManagerService,
      private routesManager: RoutesManagerService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
  ) {
  }

  ngOnInit() {
    const configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });
    this.openSubscriptions.push(configurationSubscription);

    const routesFilterSubscription = this.routesManager.assignmentFilter$.subscribe(filterUpdate => {
      this.filterRouteConfigId = filterUpdate.configId;
      this.filterRouteIds = filterUpdate.routeIds;
      this.filterCompletedItems();
      this.filterInProgressAssignments();
    });
    this.openSubscriptions.push(routesFilterSubscription);

    const assignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
      this.allRecentRouteAssignments = assignmentsUpdate.state;
      this.reloadCompleteRouteAssignments();
      this.reloadInProgressAssignments();
    });
    this.openSubscriptions.push(assignmentsSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  private filterCompletedItems() {
    if (!!this.completeRouteAssignments) {
      // filter route IDs on-the-fly
      const filteredAssignments = this.completeRouteAssignments
          .filter(assignment => !this.filterRouteConfigId || this.filterRouteConfigId === assignment.configId)
          .filter(assignment => !this.filterRouteIds || this.filterRouteIds.includes(assignment.routeId));

      this.totalDistance = 0;
      this.totalTime = 0;
      this.avgDistancePerAssignment = 0;
      this.avgTimePerAssignment = 0;
      for (const assignment of filteredAssignments) {
        this.totalDistance += (assignment.distanceDriven || 0);
        this.totalTime += (assignment.timeDriven || 0);
      }
      const assignmentCount = filteredAssignments.length;
      if (filteredAssignments.length > 0) {
        this.avgDistancePerAssignment = this.totalDistance / assignmentCount;
        this.avgTimePerAssignment = this.totalTime / assignmentCount;
      }
      this.completedItems = filteredAssignments.map(assignment => this.routeAssignmentToActionMenuItem(assignment));
    }
  }

  private reloadCompleteRouteAssignments() {
    if (!!this.allRecentRouteAssignments) {
      this.completedItems = null;
      this.completeRouteAssignments = this.allRecentRouteAssignments.filter(assignment => {
        return !!assignment.completed && moment(assignment.created).isAfter(moment().subtract(this.timeFromHours, 'hour'));
      });
      this.filterCompletedItems();
    }
  }

  private filterInProgressAssignments() {
    if (!!this.inProgressRouteAssignments) {
      const filteredAssignments = this.inProgressRouteAssignments
          .filter(assignment => !this.filterRouteConfigId || this.filterRouteConfigId === assignment.configId)
          .filter(assignment => !this.filterRouteIds || this.filterRouteIds.includes(assignment.routeId));
      this.assignmentInProgressCount = filteredAssignments.length;
    }
  }

  private reloadInProgressAssignments() {
    if (!!this.allRecentRouteAssignments) {
      this.inProgressRouteAssignments = this.allRecentRouteAssignments.filter(assignment => {
            return !!assignment.onAssignmentFrom && !assignment.deleted && !assignment.completed;
      });
      this.filterInProgressAssignments();
    }
  }

  changeTimeFilter(value: number) {
    this.timeFromHours = value;
    this.reloadCompleteRouteAssignments();
  }

  private routeAssignmentToActionMenuItem(routeAssignment: RouteAssignment): ActionMenuItem {
    return new ActionMenuItem(
        routeAssignment.id,
        'route',
        routeAssignment.routeName,
        this.shortDateOrTimePipe.transform(new Date(routeAssignment.completed)),
        null,
        null,
        () => false,
        null,
        () => false,
        null,
        null,
        [],
    );
  }
}
