import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SettingsService} from '../../../../configuration/settings.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LiveMapTab} from '../../../../pages/live-map/models/live-map-tabs';

@Injectable({
  providedIn: 'root'
})
export class LocationHistoryService {

  private visibilitySource = new BehaviorSubject<boolean>(false);
  visibility$ = this.visibilitySource.asObservable();

  isEnabled: boolean;

  private assetDetailVisibility = false;
  private componentBasedVisibility = false;
  private routeStatusModeVisibility = false;
  private routeStatusMode = false;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private http: HttpClient,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private settingsService: SettingsService,
  ) { }

  init(isEnabled: boolean) {
    this.isEnabled = isEnabled;

    if (isEnabled) {
      this.routeStatusMode = this.settingsService.getBooleanValue(SettingsService.ROUTE_STATUS_MODE);
      this.routeStatusModeVisibility = this.isRoutesInStatusMode();
      this.assetDetailVisibility = this.isAssetDetail();
      this.componentBasedVisibility = this.isVisibleBasedOnRoute();
      const routerSubscription = this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          const oldIsAssetDetail = this.assetDetailVisibility;
          this.assetDetailVisibility = this.isAssetDetail();

          const oldComponentBasedVisibility = this.componentBasedVisibility;
          this.componentBasedVisibility = this.isVisibleBasedOnRoute();

          const oldRouteStatusModeVisibility = this.routeStatusModeVisibility;
          this.routeStatusModeVisibility = this.isRoutesInStatusMode();

          // if any change
          if (oldIsAssetDetail !== this.assetDetailVisibility ||
              oldComponentBasedVisibility !== this.componentBasedVisibility ||
              oldRouteStatusModeVisibility !== this.routeStatusModeVisibility
          ) {
            this.visibilitySource.next(this.getVisibleValue());
          }
        }
      });
      this.openSubscriptions.push(routerSubscription);

      // handle visibility
      const settingsChangedSubscription = this.settingsService.settingsChangedObservable.subscribe(
          (newSettings) => {
            if (newSettings.key === SettingsService.ROUTE_STATUS_MODE) {
              this.routeStatusMode = newSettings.value;
              this.routeStatusModeVisibility = this.isRoutesInStatusMode();
              this.visibilitySource.next(this.getVisibleValue());
            }
          });
      this.openSubscriptions.push(settingsChangedSubscription);
    }
  }

  release() {
    this.isEnabled = null;
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
    this.openSubscriptions.length = 0;
    this.visibilitySource.next(false);
  }

  private isVisibleBasedOnRoute() {
    const path = this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path;
    return path !== LiveMapTab.OBSERVATIONS && path !== LiveMapTab.MESSAGES;
  }

  private isAssetDetail() {
    return this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(LiveMapTab.ASSETS + '/:id');
  }

  private isRoutesInStatusMode() {
    return this.routeStatusMode &&
        this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path === LiveMapTab.ROUTES;
  }

  private getVisibleValue() {
    return this.componentBasedVisibility && !this.assetDetailVisibility && !this.routeStatusModeVisibility;
  }
}
