import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AvlModel, SensorInput, SensorType} from '../../../../../shared/models/vehicle.model';
import {Equipment} from '../model/equipment.class';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {NamedId} from '../../../../../shared/models/NamedId';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit, OnChanges {

  @Input() configuration: ConfigurationModel;
  @Input() avlModel: AvlModel;
  @Input() equipment: Equipment;
  @Input() allInputs: NamedId[];
  @Input() excludedInputs: number[];
  @Output() equipmentTypeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() inputsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() equipmentDeleted: EventEmitter<boolean> = new EventEmitter();
  SensorType = SensorType;
  AvlModel = AvlModel;
  filteredInputs: NamedId[];

  constructor() { }

  ngOnInit(): void {
    this.filterInputs();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filterInputs();
  }

  private filterInputs() {
    if (!!this.allInputs && !!this.excludedInputs) {
      this.filteredInputs = this.allInputs
        .filter(input =>
          (!this.excludedInputs.includes(input.id) ||
            input.id === this.equipment.inputUp?.id ||
            input.id === this.equipment.inputDown?.id)
          && (input.id < 6)
        );
    }
  }

  typeChanged() {
    this.equipment.rate = null;
    this.equipment.label = null;
    this.equipmentTypeChanged.emit(true);
  }

  inputChanged() {
    this.inputsChanged.emit(true);
  }

  reverseClicked() {
    this.equipment.reversed = !this.equipment.reversed;
  }

  delete() {
    this.equipmentDeleted.emit();
    this.inputsChanged.emit(true);
  }

  compareSensorInputs(o1: SensorInput, o2: SensorInput): boolean {
    return !!o1 && !!o2 && o1.id === o2.id;
  }
}
