<div class="style-table">
    <div class="style-row style-header">
        <div class="category">
            Category
        </div>
        <div>
            Appearance
        </div>
    </div>
    <ng-container *ngFor="let lineStyle of layerType.measurementUnits; let i = index">
        <div class="style-row" (click)="editLineStyle(i, lineStyle)">
            <div class="category">
                {{ lineStyle.number}}
            </div>
            <div class="style-preview">
                <app-line-dash-array-view
                        [dashArray]="lineStyle.dashArray"
                        [color]="lineStyle.color"
                >
                </app-line-dash-array-view>
            </div>
        </div>
        <div class="style-row" *ngIf="showDetailIndex === i">
            <app-line-style-editor [lineStyle]="lineStyle" (lineStyleChange)="onLineStyleChange()"></app-line-style-editor>
        </div>
    </ng-container>
</div>
