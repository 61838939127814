import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {LngLatBounds, Map} from 'maplibre-gl';
import {Observable, Subscription} from 'rxjs';
import {ConfigurationModel} from '../../models/configuration.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {debounceTime} from 'rxjs/operators';
import {MapContent} from './model/MapContent';
import {MapTools} from '../../tools/MapTools';
import {environment} from '../../../../environments/environment';
import {MapLayersManager} from '../map-viewer/map-layers-manager';
import {EmptyMapContent} from './model/EmptyMapContent';

@Component({
  selector: 'app-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.scss']
})
export class MapPreviewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  static readonly MAP_MARKER_IMAGE = 'address-marker-image';
  static readonly TRACK_ARROW_IMAGE = 'track-arrow-image';

  @Input() nextContent: MapContent;

  @ViewChild('map') private mapContainer: ElementRef<HTMLElement>;
  map: Map;

  private mapLoaded = false;
  loadingContent = true;
  private initialContent: MapContent = null;
  private content: MapContent = null;

  private defaultBounds: LngLatBounds = new LngLatBounds([-105.5, 39], [-105.0, 40]);

  private tenantConfigurationSubscription: Subscription;
  private tenantConfiguration: ConfigurationModel;

  private mapResizeObserver: ResizeObserver;
  private mapResizeObserverSubscription: Subscription;

  constructor(private configurationService: ConfigurationService) { }

  ngOnInit(): void {
    this.initialContent = this.nextContent == null ? EmptyMapContent.instance : this.nextContent;
    this.tenantConfigurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(tenantConfiguration => {
      if (tenantConfiguration) {
        this.tenantConfiguration = tenantConfiguration;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!!this.tenantConfiguration) {
      const tenantConfiguration = this.tenantConfiguration;
      if (tenantConfiguration.region.length > 0) {
        this.defaultBounds = tenantConfiguration.region.reduce((bounds, latLngModel) => {
          return bounds.extend([latLngModel.lng, latLngModel.lat]);
        }, new LngLatBounds(tenantConfiguration.region[0], tenantConfiguration.region[0]));
      } else {
        console.warn('region in tenant configuration is empty - leaving default bounds');
      }
    }

    this.loadMap();
    this.subscribeToMapResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const nextContentChange = changes['nextContent'] as SimpleChange;
    const nextContent = nextContentChange.currentValue as MapContent;

    if (this.isMapLoaded()) {
      this.loadContent(nextContent);
    } else {
      this.initialContent = nextContent;
    }
  }

  private loadContent(nextContent: MapContent) {
    this.loadingContent = true;
    this.content?.unload();
    this.content = nextContent == null ? EmptyMapContent.instance : nextContent;
    this.content.load(this.map);
    this.loadingContent = false;
  }

  ngOnDestroy(): void {
    this.content?.unload();
    this.tenantConfigurationSubscription?.unsubscribe();
    this.mapResizeObserver?.disconnect();
    this.mapResizeObserverSubscription?.unsubscribe();
  }

  private subscribeToMapResize() {
    const resizeObservable = new Observable<void>(subscriber => {
      this.mapResizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          subscriber.next();
        });
      });
    });

    this.mapResizeObserverSubscription = resizeObservable
      .pipe(debounceTime(200))
      .subscribe({ next: () => this.map.resize() });

    this.mapResizeObserver.observe(this.mapContainer.nativeElement);
  }

  loadMap() {
    const useMetric = this.tenantConfiguration?.useMetricSystem;
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: !!useMetric ? MapLayersManager.ACCUTERRA_LIGHT_M_MAP_STYLE : MapLayersManager.ACCUTERRA_LIGHT_MAP_STYLE,
      bounds: this.defaultBounds,
    });

    this.map.on('load', () => {

      // source: https://fonts.google.com/icons?selected=Material+Icons:menu
      MapTools.loadImage(
        this.map,
        MapPreviewComponent.MAP_MARKER_IMAGE,
        `${environment.base_href}assets/baseline_location_on_black_24dp.png`,
        { sdf: true });

      // source: https://fonts.google.com/icons?selected=Material+Icons:menu
      MapTools.loadImage(
        this.map,
        MapPreviewComponent.TRACK_ARROW_IMAGE,
        `${environment.base_href}assets/baseline_expand_less_black_24dp.png`,
        { sdf: false }
      );

      this.mapLoaded = true;
      this.loadContent(this.initialContent);
      this.initialContent = null;
    });
  }

  private isMapLoaded() {
    return this.map != null && this.mapLoaded;
  }
}
