import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {ShiftMapComponent} from './components/shift-map.component';
import {ShiftMapPanelComponent} from './components/shift-map-panel/shift-map-panel.component';
import {MaterialModule} from '../../shared/material/material.module';
import {ShiftMapInspectionDialogComponent} from './components/shift-map-panel/shift-map-panel-dialogs';
import { ShiftObservationsComponent } from './components/shift-observations/shift-observations.component';
import { ShiftVideoComponent } from './components/shift-video/shift-video.component';
import { ShiftAssignmentsComponent } from './components/shift-assignments/shift-assignments.component';


@NgModule({
    declarations: [
        ShiftMapComponent,
        ShiftMapPanelComponent,
        ShiftMapInspectionDialogComponent,
        ShiftObservationsComponent,
        ShiftVideoComponent,
        ShiftAssignmentsComponent
    ],
    exports: [
        ShiftObservationsComponent,
        ShiftVideoComponent,
        ShiftAssignmentsComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        MaterialModule,
    ]
})
export class ShiftMapModule { }
