<div class="left-panel-container">
    <div class="collapse__btn__wrapper">
        <app-menu-toggle-button
                (toggleSideMenu)="closeSideMenu()"
                [collapseMenu]="collapseMenu"
        ></app-menu-toggle-button>
    </div>

    <div class="card__wrapper">
        <div *ngIf="!listData" class="mt-25">
            <app-card-loading-spinner></app-card-loading-spinner>
        </div>
        <div *ngIf="listData">
            <mat-nav-list>
                <ng-container *ngFor="let item of listData">
                    <app-action-menu-item-2line
                            [input]="item"
                    >
                    </app-action-menu-item-2line>
                </ng-container>
            </mat-nav-list>
        </div>
    </div>
</div>
