<div class="gradient__wrapper">
  <div class="cell" *ngFor="let filter of layerType.measurementUnits">
    <div class="cell__text section__text__small">{{ filter.number }}</div>

    <div
            class="cell__color section__text"
            *ngIf="!editable"
            [ngStyle]="{ background: filter.color }"
    ></div>

    <span
            *ngIf="editable"
            [cpPosition]="'top'"
            [cpPositionOffset]="'50%'"
            [cpPositionRelativeToArrow]="true"
            [(colorPicker)]="filter.color"
            (colorPickerChange)="colorChanged($event)">
      <div
              class="cell__color cell__color__pointer section__text"
              [ngStyle]="{ background: filter.color }"
      >
      </div>
    </span>
  </div>
</div>
