<div class="setup-page-container">

    <div class="header-container">
        <h2 class="header-container-filters">
            Drivers Import - Cartegraph
        </h2>
    </div>

    <div class="content-container">
        <div class="selection-container">

            <mat-card appearance="outlined">
                <mat-card-content class="w-100 content">
                    The import will load users from Cartegraph and ingest the data into the PlowOps.
                    <div class="loading__wrapper" *ngIf="currentImportDriversState == ImportDriversStateEnum.WORKING">
                        <div>Working on it ...</div>
                        <mat-spinner></mat-spinner>
                    </div>
                    <div *ngIf="currentImportDriversState == ImportDriversStateEnum.NOOP" class="w-100">
                <span class="ml-10">
                    <button mat-button
                            color="accent" (click)="doImport()"> Import
                    </button>
                </span>
                        <span class="ml-10">
                    <button mat-button
                            color="accent" (click)="cancelImport()"> Cancel
                    </button>
                </span>
                    </div>

                    <div *ngIf="currentImportDriversState == ImportDriversStateEnum.NOOP && uiError && uiError.length > 0"
                         class="w-100 content">
                        <span class="ui-error">{{ uiError }}</span>
                    </div>

                    <div *ngIf="currentImportDriversState == ImportDriversStateEnum.IMPORTED" class="w-100 content">
                        <div>Import successfully finished.
                            <button mat-button color="accent" (click)="changePage()">
                                Navigate to user management
                            </button>
                            to see changes.
                        </div>
                        <div class="mt-25">
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let key of importKeys">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{key.label}}: {{importResult[key.name]?.length || 0}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ol>
                                        <li *ngFor="let row of importResult[key.name]">{{row.key}}<span
                                                *ngIf="key.name=='error'">{{row.note}}</span></li>
                                    </ol>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>

        </div>
    </div>
</div>

<ng-template #table let-data="importData" let-displayedColumns="displayedColumns">
    <table *ngIf="data && data.length>0" mat-table
           [dataSource]="data">

        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef> Import</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef> Line</th>
            <td mat-cell *matCellDef="let element">{{element.index}}</td>
        </ng-container>

        <ng-container matColumnDef="driverName">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element">{{element.row.driverName}} </td>
        </ng-container>

        <ng-container matColumnDef="login">
            <th mat-header-cell *matHeaderCellDef> Login</th>
            <td mat-cell *matCellDef="let element">{{element.dbData?.driver?.login || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Errors/Notes</th>
            <td mat-cell *matCellDef="let element">
                <div *ngFor="let error of element.errors">{{error}}</div>
                <div *ngIf="element.action=='NONE'">skipping import - driver already exists / is portal or admin user
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>
