<div class="basic-page-container">
  <div
    class="left__content"
    [ngClass]="{ collapse: collapseMenu }"
  >
      <app-sidemenu
              *ngIf="!isLoading"
              (toggleSideMenu)="toggleSideMenu()"
              [collapseMenu]="collapseMenu"
              [listData]="settingsSections"
              class="left__panel">
      </app-sidemenu>
  </div>

  <div class="page__container">
    <div class="visible__page__container"
         [@inOutAnimation]
         [ngClass]="{ collapse: collapseMenu }"
    >
      <div class="round__container">

        <div class="wide__page__container" *ngIf="currentRoute; else noSelect">
          <router-outlet></router-outlet>
        </div>

        <ng-template #noSelect>
          <app-blank-page
                  class="centered__page__container"
                  headline="No Setup Category Selected"
                  text="Select a category from the list on the left to begin setting up or modifying your PlowOps deployment."
          >
          </app-blank-page>
        </ng-template>

      </div>
    </div>
  </div>
</div>
