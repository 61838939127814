import {NamedItem} from './named-item';
import {CameraConfiguration, HardwareConfiguration} from './vehicle.model';
import {NamedId} from './NamedId';
import {DeviceInfo} from './device-info';

export class VehicleGroup implements NamedItem {

  vehicles = new Array<Vehicle>();

  constructor(public id: number,
              public title: string) {}
}

export class Vehicle {
  constructor(public id: number,
              public group: VehicleGroup,
              public label: string,
              public licensePlate: string,
              public hardwareConfiguration: HardwareConfiguration,
              public cameraConfiguration: CameraConfiguration,
              public deviceInfo?: DeviceInfo,
  ) {}
}

/**
 * Data returned from backend (DTO only)
 * used throughout the app
 */
export class VehicleCategoryModel {
  id: number;
  name: string;
  title: string;
  useDvir: boolean;
  allowRouteSelfAssign: boolean;
  preTripForm: NamedId;
  postTripForm: NamedId;
  showVehicles: boolean;
  showVehicleHistory: boolean;
  showVehicleObservations: boolean;
  maxStationaryTime?: number;
  observationTypeGroups: NamedId[] = [];
  allowedRouteConfigs: NamedId[] = [];
}

/**
 * Data sent to backend (DTO only)
 * used only in vehicle management
 */
export class VehicleCategoryModelWithIds {
  id: number;
  name: string;
  title: string;
  useDvir: boolean;
  allowRouteSelfAssign: boolean;
  preTripFormJournalId: number;
  postTripFormJournalId: number;
  showVehicles: boolean;
  showVehicleHistory: boolean;
  showVehicleObservations: boolean;
  maxStationaryTime?: number;
  observationTypeGroupIds: number[] = [];
  allowedRouteConfigIds: number[] = [];
}
