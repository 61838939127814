<div class="my-25">

    <mat-card appearance="outlined">

        <mat-card-header>
            <mat-card-title>Submissions to Cartegraph</mat-card-title>
        </mat-card-header>

        <mat-card-content class="mt-25">

            <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                <span class="ui-error">{{ uiError }}</span>
            </div>

            <div *ngIf="!isLoading && (!tasks || tasks.data?.length<=0)">There are no data available with
                status: {{ processingStatus }}
            </div>

            <div class="filter mb-25">

                <app-composite-filter-bar (onSearch)="doSearch($event)" [showBar]="true">
                    <app-dropdown-composite [title]="'Statuses'"
                                            [id]="'status'"
                                            [values]="processingStatuses"
                                            [multiple]="true"></app-dropdown-composite>
                    <app-text-search-composite [title]="'Search ID'" [id]="'search'"></app-text-search-composite>
                </app-composite-filter-bar>
            </div>

            <div *ngIf="isLoading" class="loading__wrapper">
                <mat-spinner></mat-spinner>
            </div>

            <table mat-table *ngIf="!isLoading && tasks && tasks.data?.length>0"
                   class="tasks-table mat-elevation-z4"
                   matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection"
                   [dataSource]="tasks.data"
                   (matSortChange)="sortChange($event)">
                <ng-container matColumnDef="id">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Id.</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngTemplateOutlet="status;context:{status:element.processStatus}"></ng-container>
                        {{ element.id }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taskType">
                    <th mat-header-cell *matHeaderCellDef> Type</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngTemplateOutlet="taskType;context:{type:element.taskType}"></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="created">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Created</th>
                    <td mat-cell *matCellDef="let element"> {{ element.created | date:'short' }}</td>
                </ng-container>

                <ng-container matColumnDef="submitted">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Submitted</th>
                    <td mat-cell *matCellDef="let element"> {{ element.submitted | date:'short' }}</td>
                </ng-container>

                <ng-container matColumnDef="shift">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Shift</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button
                                color="accent"
                                type="button"
                                aria-label="Open shift detail"
                                *ngIf="element.shiftId"
                                (click)="viewShiftDetail(element.shiftId)">
                            {{ element.shiftId }}
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cgIdField">
                    <th mat-header-cell *matHeaderCellDef> Cartegraph</th>
                    <td mat-cell *matCellDef="let element" class="cgfields">
                        <button mat-button
                                color="accent"
                                type="button"
                                aria-label="Open detail in Cartegraph"
                                [title]="task.type"
                                *ngFor="let task of element.cgResult?.tasks"
                                (click)="openCgDetail(task.cgOid)">
                            {{ task.cgId }}<sup *ngIf="task.type=='CDOT'">*</sup>,
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="processStatus">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <div (dblclick)="updateStatus(element)">{{ element.processStatus }}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="processMessage">§
                    <th mat-header-cell *matHeaderCellDef> Message</th>
                    <td mat-cell
                        *matCellDef="let element"
                        (click)="messageDetail(element)">
                        <div class="message-content"> {{ element.processMessage }}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="retryCount">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Retry#</th>
                    <td mat-cell *matCellDef="let element"> {{ element.retryCount }}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>...</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-raised-button
                                *ngIf="element.processStatus==='FAIL' && (element.cgResult===null || element.cgResult?.tasks?.length()==0)"
                                (click)="reprocessSyncTask(element)"
                                class="mat-small"
                                color="warn">
                            Reprocess
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>

        <mat-card-footer>
            <!-- note: cannot be in the ngIf statement! -->
            <mat-paginator showFirstLastButtons
                           (page)="onPage($event)"
                           [pageSize]="pageSize"
                           [pageIndex]="pageIndex"
                           [length]="itemCount"
                           aria-label="Select page">
            </mat-paginator>
        </mat-card-footer>


    </mat-card>

</div>

<ng-template #status datatype="string" let-status="status">
    <span class="icon-status">
        <ng-container [ngSwitch]="status">
            <mat-icon *ngSwitchCase="'WAITING'" class="waiting">hourglass_bottom</mat-icon>
            <mat-icon *ngSwitchCase="'FAIL'" class="fail">error</mat-icon>
            <mat-icon *ngSwitchCase="'SUCCESS'" class="success">check_circle</mat-icon>
            <mat-icon *ngSwitchCase="'NONE'" class="none">block</mat-icon>
        </ng-container>
    </span>
</ng-template>
<ng-template #taskType datatype="string" let-type="type">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'SHIFT_END_POSTPROCESS'">Shift end</ng-container>
        <ng-container *ngSwitchCase="'DVIR_PRE_INSPECTION'">Pre Inspect</ng-container>
        <ng-container *ngSwitchCase="'DVIR_POST_INSPECTION'">Post Inspect</ng-container>
        <ng-container *ngSwitchCase="'OBSERVATION'">Observation</ng-container>
    </ng-container>
</ng-template>

