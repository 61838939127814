<div class="filter-bar-container">
    <div class="filter-bar-start">
        <button mat-button
                color="accent"
                (click)="toggleFilterBar()">
            <div class="menu-button">
                <span>Filter</span>
                <mat-icon [ngClass]="{'open-filter': showingFilterBar}">arrow_drop_down</mat-icon>
            </div>
        </button>

        <div class="chip-list"
             [style.display]="!showingFilterBar ? 'none' : 'flex'"
        >

            <mat-chip-listbox>
                <mat-chip-option *ngFor="let chip of filterChips"
                                 [removable]="true"
                                 [selectable]="false"
                                 (removed)="filterChipRemoved($event, chip)"
                                 (click)="filterChipSelected($event, chip)"
                >
                    <span>{{ chip.filterName }}</span>&nbsp;<span class="text-secondary">{{ chip.operator}}</span>&nbsp;<span>{{ chip.items }}</span>
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-option>
            </mat-chip-listbox>

            <button mat-icon-button
                    #matTriggerAdd="matMenuTrigger"
                    [matMenuTriggerFor]="filtersListMenu"
                    [ngClass]="{hidden: allFiltersSet() }"
            >
                <mat-icon>add</mat-icon>
            </button>

            <mat-menu #filtersListMenu="matMenu">
                <button mat-menu-item
                        *ngIf="useDateFilter"
                        #matTriggerDateFilter="matMenuTrigger"
                        [matMenuTriggerFor]="dateRangeMenu"
                        [ngClass]="{hidden: !dateFilter || (!!dateFilter && (!!dateFilter.from || !!dateFilter.to))}"
                >
                    Date Range
                </button>
                <button mat-menu-item
                        *ngIf="useVehicleGroupFilter"
                        #matTriggerVehicleGroup="matMenuTrigger"
                        [matMenuTriggerFor]="vehicleGroupMenu"
                        [ngClass]="{hidden: !!vehicleGroupFilter}"
                >
                    Vehicle Group
                </button>
                <button mat-menu-item
                        *ngIf="useObservationTypeGroupFilter"
                        #matTriggerObservationTypeGroup="matMenuTrigger"
                        [matMenuTriggerFor]="observationTypeGroupMenu"
                        [ngClass]="{hidden: !!observationTypeGroupFilter}"
                >
                    Observation Group
                </button>
                <button mat-menu-item
                        *ngIf="useAssetStatusFilter"
                        #matTriggerAssetStatus="matMenuTrigger"
                        [matMenuTriggerFor]="statusMenu"
                        [ngClass]="{hidden: !!assetStatusFilter}"
                >
                    Status
                </button>
                <button mat-menu-item
                        *ngIf="useHardwareFilter"
                        #matTriggerHardware="matMenuTrigger"
                        [matMenuTriggerFor]="hardwareMenu"
                        [ngClass]="{hidden: !!hardwareFilter}"
                >
                    Hardware
                </button>
            </mat-menu>

            <mat-menu #dateRangeMenu="matMenu">
                <app-date-filter-dropdown
                        [origin]="origin"
                        [defaultValue]="defaultDateFilter"
                        (filterChanged)="onDateFilterChanged($event)"
                >
                </app-date-filter-dropdown>
            </mat-menu>

            <mat-menu #vehicleGroupMenu="matMenu">
                <app-vehicle-group-filter
                        [origin]="origin"
                        (filterChanged)="onVehicleGroupsChanged($event)"
                ></app-vehicle-group-filter>
            </mat-menu>

            <mat-menu #observationTypeGroupMenu="matMenu">
                <app-observation-group-filter
                        [origin]="origin"
                        (filterChanged)="onObservationTypeGroupFilterChanged($event)"
                ></app-observation-group-filter>
            </mat-menu>

            <mat-menu #statusMenu="matMenu">
                <app-asset-status-filter
                        [origin]="origin"
                        (filterChanged)="onAssetStatusFilterChanged($event)"
                ></app-asset-status-filter>
            </mat-menu>

            <mat-menu #hardwareMenu="matMenu">
                <app-hardware-filter
                        [origin]="origin"
                        (filterChanged)="onHardwareFilterChanged($event)"
                ></app-hardware-filter>
            </mat-menu>
        </div>
    </div>

    <div *ngIf="!showingFilterBar && !noFiltersSet()">
        <button mat-button
                color="accent"
                (click)="clearFilters()"
        >
            Clear Filters
        </button>
    </div>
</div>
