import { trigger, style, transition, animate } from '@angular/animations';

export const fadeUpAnimation = trigger('fadeUpAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(10px)' }),
    animate(
      '100ms ease-out',
      style({ opacity: 1, transform: 'translateY(0)' })
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(
      '100ms ease-in',
      style({ opacity: 0, transform: 'translateY(10px)' })
    ),
  ]),
]);

export const inOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms ease-out', style({ opacity: 1 })),
  ]),
]);

export const filterSelectorFadeUp = trigger('filterSelectorFadeUp', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(100px)' }),
    animate(
      '100ms cubic-bezier(0.22, 1, 0.36, 1)',
      style({ opacity: 1, transform: 'translateY(0px)' })
    ),
  ]),
  transition(':leave', [
    animate(
      '100ms cubic-bezier(0.64, 0, 0.78, 0)',
      style({ opacity: 0, transform: 'translateY(100px)' })
    ),
  ]),
]);

export const filterSelectorFadeDown = trigger('filterSelectorFadeDown', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(50px)' }),
    animate(
      '100ms cubic-bezier(0.22, 1, 0.36, 1)',
      style({ opacity: 1, transform: 'translateY(0px)' })
    ),
  ]),
  transition(':leave', [
    animate(
      '100ms cubic-bezier(0.64, 0, 0.78, 0)',
      style({ opacity: 0, transform: 'translateY(-50px)' })
    ),
  ]),
]);

export const filterSelectorFadeRight = trigger('filterSelectorFadeRight', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-100px)' }),
    animate(
      '100ms cubic-bezier(0.22, 1, 0.36, 1)',
      style({ opacity: 1, transform: 'translateX(0px)' })
    ),
  ]),
  transition(':leave', [
    animate(
      '100ms cubic-bezier(0.64, 0, 0.78, 0)',
      style({ opacity: 0, transform: 'translateX(-100px)' })
    ),
  ]),
]);
