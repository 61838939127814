import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../../../../shared/models/route';
import { RmNode } from './RmNode';
import {NodesAndBreadcrumbs} from './NodesAndBreadcrumbs';
import {ErrorTools} from '../../../../../shared/tools/ErrorTools';

export class RmIntermediary implements RmNode {
  base: RouteHierarchyItem;
  level: number;
  children: RmNode[];

  parent: RmNode = null;
  selected = false;

  constructor(base: RouteHierarchyItem,
              level: number,
              children: RmNode[]) {
    this.base = base;
    this.level = level;
    this.children = children;
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  isSelected(): boolean {
    return this.selected;
  }

  setParentOnMe(parent: RmNode) {
    this.parent = parent;
  }

  setParentOnChildren() {
    this.children.forEach(value => value.setParentOnMe(this));
  }

  collectVisibleRouteIds(routeIds: number[]) {
    if (this.selected) {
      const selectedChild = this.children.find(value => value.isSelected());
      if (selectedChild == null) {
        this.collectRouteIds(routeIds);
      } else {
        selectedChild.collectVisibleRouteIds(routeIds);
      }
    }
  }

  collectRouteIds(routeIds: number[]) {
    this.children.forEach(value => value.collectRouteIds(routeIds));
  }

  breadcrumbsR(breadcrumbs: string[]): string[] {
    return this.parent.breadcrumbsR([this.base.value, ...breadcrumbs]);
  }

  rightColumnNodesR(): NodesAndBreadcrumbs {
    const selectedChild = this.children.find(value => value.isSelected());
    const backButtonTarget = this.level > 1 ? this.parent : null;
    if (selectedChild == null) {
      return new NodesAndBreadcrumbs(backButtonTarget, this.breadcrumbsR([]), this.children);
    } else {
      if (selectedChild.isIntermediary()) {
        return selectedChild.rightColumnNodesR();
      } else {
        return new NodesAndBreadcrumbs(backButtonTarget, selectedChild.breadcrumbsR([]), this.children);
      }
    }
  }

  delete() {
    this.parent.deleteChild(this);
  }

  deleteChild(it: RmNode) {
    const foundIndex = this.children.findIndex(value => value.isSimilarTo(it));
    if (foundIndex >= 0) {
      this.children.splice(foundIndex, 1);
    }
  }

  isSimilarTo(it: RmNode) {
    return it.isIntermediary() && this.base.value === it.asRouteOrIntermediaryBase().value;
  }

  actionMenuItemId(): number {
    return null;
  }

  actionMenuItemTitle(): string {
    return this.base.value;
  }

  actionMenuItemSubTitle(): string {
    const routeIds = [];
    this.collectRouteIds(routeIds);
    return String(routeIds.length);
  }

  select() {
    this.parent.unselectMyChildren();
    this.selected = true;
  }

  unselectMyChildren() {
    this.children.forEach(value => value.unselectMeAndMyChildren());
  }

  unselectMeAndMyChildren() {
    this.selected = false;
    this.children.forEach(value => value.unselectMeAndMyChildren());
  }

  isRoot(): boolean {
    return false;
  }

  isConfig(): boolean {
    return false;
  }

  isIntermediary(): boolean {
    return true;
  }

  isRoute(): boolean {
    return false;
  }

  asConfigBase(): RouteConfigurationWithSchema {
    throw ErrorTools.unsupported();
  }

  asRouteOrIntermediaryBase(): RouteHierarchyItem {
    return this.base;
  }

  collectPathIdsForQueryParamsR(pathIds: string[]) {
    if (this.selected) {
      pathIds.push(this.base.value);
      this.children.forEach(value => value.collectPathIdsForQueryParamsR(pathIds));
    }
  }

  applyPathIdsFromQueryParamsR(remainingIds: string[]) {
    const currentId = remainingIds[0];
    let currentIdMatches = false;
    if (this.base.value === currentId) {
      currentIdMatches = true;
    }
    this.selected = currentIdMatches;
    if (currentIdMatches) {
      remainingIds.splice(0, 1);
      if (remainingIds.length > 0) {
        this.children.forEach(value => value.applyPathIdsFromQueryParamsR(remainingIds));
      }
    }
  }
}
