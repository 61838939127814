import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {MifForms} from '../model/MifForms';
import {InspectionFormsService} from '../../../../../data/inspection-forms/inspection-forms.service';
import {MifForm} from '../model/MifForm';
import {LoadState} from '../../../../../shared/tools/LoadState';
import {
  MifConfirmDeleteFormDialogComponent
} from './mif-confirm-delete-form-dialog/mif-confirm-delete-form-dialog.component';
import {ConfirmDeleteFormDialogData} from './mif-confirm-delete-form-dialog/ConfirmDeleteFormDialogData';
import {MifSystemForm} from '../model/MifSystemForm';
import {SharedDataService} from '../../../../../shared/services/shared-data.service';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {InspectionFormTreeComponent} from '../form/tree/inspection-form-tree.component';

@Component({
  selector: 'app-inspection-form-list',
  templateUrl: './inspection-form-list.component.html',
  styleUrls: ['./inspection-form-list.component.scss']
})
export class InspectionFormListComponent implements OnInit {

  loadState = LoadState.NOT_LOADED_YET;
  loadError = '';


  inspectionForms: MifForms = new MifForms([]);
  // system defined forms, loaded from server resources
  systemForms: MifSystemForm[] = [];
  items: ActionMenuItem[];
  selectedInspectionForm: MifForm;
  selectedInspectionFormNodes: any[];

  LoadState = LoadState;

  @ViewChild(InspectionFormTreeComponent) treeComponent: InspectionFormTreeComponent;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private inspectionFormsService: InspectionFormsService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.loadState = LoadState.LOADING;
    Promise.all([
      this.inspectionFormsService.getForms(),
      this.inspectionFormsService.getSystemForms()])
      .then(results => {
        const [forms, systemForms] = results;
        this.inspectionForms = forms;
        this.systemForms = systemForms;
        this.updateUiItems();
        if (!!this.inspectionForms && this.inspectionForms.contents.length > 0) {
          this.selectedInspectionForm = this.inspectionForms.contents[0];
          this.selectedInspectionFormNodes = this.selectedInspectionForm.generateAllInputNodes();
        }
        this.loadState = LoadState.LOADED_OK;
      })
      .catch(error => {
        this.handleLoadError('Error while loading inspection forms', error);
        this.inspectionForms = null;
        this.systemForms = null;
        this.items = null;
        this.selectedInspectionForm = null;
        this.selectedInspectionFormNodes = null;
      });
  }

  private updateUiItems() {
    this.items = this.inspectionForms.contents.map(inspectionForm => this.inspectionFormToActionMenuItem(inspectionForm));
  }

  private inspectionFormToActionMenuItem(inspectionForm: MifForm): ActionMenuItem {
    return new ActionMenuItem(
        inspectionForm.externalId,
        'fact_check',
        inspectionForm.title,
        `${inspectionForm.numberOfQuestions} Question${inspectionForm.numberOfQuestions > 1 ? 's' : ''}`,
        null,
        null,
        () => inspectionForm.externalId === this.selectedInspectionForm?.externalId,
        null,
        () => {
          this.selectedInspectionForm = inspectionForm;
          this.selectedInspectionFormNodes = this.selectedInspectionForm.generateAllInputNodes();
          // tree model in view has to refresh first
          setTimeout(() => this.treeComponent?.expandAll(), 100);
        },
        null,
        null,
        [
            new ActionMenuItemSubMenu(
                'edit',
                'Edit',
                () => this.editFormEvent(inspectionForm),
            ),
            new ActionMenuItemSubMenu(
                'file_copy',
                'Duplicate',
                () => this.duplicateFormEvent(inspectionForm),
            ),
            new ActionMenuItemSubMenu(
                'delete',
                'Delete',
                () => this.deleteFormEvent(inspectionForm),
            ),
        ],
    );
  }

  buttonClickedNew() {
    this.router.navigate(['/settings/inspection-forms/new']);
  }

  buttonClickAddFromTemplate(form: MifSystemForm) {
    this.sharedDataService.put('default-form', form.form);
    this.router.navigate(['/settings/inspection-forms/system']);
  }

  editFormEvent(it: MifForm) {
    this.router.navigate(['/settings/inspection-forms', it.externalId]);
  }

  duplicateFormEvent(it: MifForm) {
    this.router.navigate(['/settings/inspection-forms', it.externalId, 'copy']);
  }

  deleteFormEvent(it: MifForm) {
    this.showConfirmDeleteFormDialog(it);
  }

  private showConfirmDeleteFormDialog(toBeDeleted: MifForm) {
    const dialogRef = this.dialog.open(MifConfirmDeleteFormDialogComponent, {
      data: new ConfirmDeleteFormDialogData(toBeDeleted),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        const data = result as ConfirmDeleteFormDialogData;
        this.deleteForm(data.form);
      }
    });
  }

  private deleteForm(toBeDeleted: MifForm) {
    this.inspectionForms.remove(toBeDeleted);

    this.inspectionFormsService.deleteForm(toBeDeleted.externalId)
      .then(response => {
        this.showSnackBar('Form deleted');
        this.updateUiItems();
        this.selectedInspectionForm = null;
        this.selectedInspectionFormNodes = null;
      })
      .catch(error => {
        this.handleActionError('Error while deleting form', error);
      });
  }

  private showSnackBar(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  private handleLoadError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);

    this.loadState = LoadState.LOADED_ERROR;
    this.loadError = msgError;
  }

  private handleActionError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);
  }
}
