<div class="layers__header__wrapper" *ngIf="!layerEditMode">
    <div class="header__title">
        <span>Configure My Layers</span>
    </div>
    <div class="header__action">
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="layers__header__wrapper" *ngIf="layerEditMode">
    <div class="header__title">
        <button mat-icon-button (click)="back()">
            <mat-icon>west</mat-icon>
        </button>
        <span>Add New Layer</span>
    </div>
    <div class="header__action">
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>


<mat-spinner *ngIf="!mapLayers"></mat-spinner>

<ng-container *ngIf="!!mapLayers">
    <div class="map__filter__wrapper" *ngIf="!layerEditMode">
        <div class="map__filter__square noselect configure"
             (click)="addLayer()"
        >
            <mat-icon>add</mat-icon>
        </div>
        <div class="map__filter__text">
            New Layer
        </div>
    </div>

    <div class="layer-list" *ngIf="!!items && !layerEditMode">
        <ng-container *ngFor="let item of items">
            <app-action-menu-item
                [input]="item"
            >
            </app-action-menu-item>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="layerEditMode && mapLayer">

    <mat-form-field appearance="outline" color="accent" class="layer-form">
        <mat-label>Name</mat-label>
        <input matInput
               autocomplete="off"
               [(ngModel)]="mapLayer.currentName"
               (ngModelChange)="changed()"
               #ngName="ngModel"
               required
               cdkFocusInitial>
        <mat-error *ngIf="ngName.invalid && ngName.errors.required">
            Name is required.
        </mat-error>
    </mat-form-field>

    <div *ngIf="mapLayer.currentType === MapLayerType.ROUTE">
        The layer is imported by Route Management.
    </div>

    <mat-form-field appearance="outline" color="accent" class="layer-form" *ngIf="mapLayer.currentType !== MapLayerType.ROUTE">
        <mat-label>Layer Type</mat-label>
        <mat-select [(ngModel)]="mapLayer.currentType" (ngModelChange)="changed()">
            <mat-option [value]="'RASTER'">raster</mat-option>
            <mat-option [value]="'VECTOR'">vector</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="layer-form with-bottom-margin"
                    *ngIf="mapLayer.currentType === MapLayerType.RASTER">
        <mat-label>URL</mat-label>
        <input matInput
               autocomplete="off"
               [(ngModel)]="mapLayer.currentUrl"
               (ngModelChange)="changed()"
               #ngUrl="ngModel"
               required
        >
        <mat-error *ngIf="ngUrl.invalid && ngUrl.errors.required">
            URL is required.
        </mat-error>
        <mat-hint>
            Append '/tile/{{ '{z}/{y}/{x}' }}' to the end of your URL string for standard xyz pattern raster file loading
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="layer-form"
                    *ngIf="mapLayer.currentType === MapLayerType.VECTOR">
        <mat-label>JSON configuration</mat-label>
        <textarea matInput
                  autocomplete="off"
                  [(ngModel)]="mapLayer.currentConfiguration"
                  (ngModelChange)="changed()"
                  #ngConfig="ngModel"
                  class="json-text-area"
                  required
        >
                </textarea>
        <mat-error *ngIf="ngConfig.invalid && ngConfig.errors.required">
            JSON Configuration is required.
        </mat-error>
    </mat-form-field>

    <div class="layer-form" *ngIf="mapLayer.currentType === MapLayerType.RASTER">
        <mat-checkbox [(ngModel)]="mapLayer.useCredentials"
                      (change)="onCredentialsToggle()"
                      (ngModelChange)="changed()"
        >
            Use credentials for the server
        </mat-checkbox>
        <div class="credentials-wrapper" *ngIf="mapLayer.useCredentials">
            <mat-form-field appearance="outline" color="accent" class="credentials-input">
                <mat-label>Username</mat-label>
                <input matInput
                       [(ngModel)]="mapLayer.currentUsername"
                       (ngModelChange)="changed()"
                       autocomplete="off"/>
            </mat-form-field>
            <mat-form-field  appearance="outline" color="accent" class="credentials-input">
                <mat-label>Password</mat-label>
                <input matInput
                       [(ngModel)]="mapLayer.currentPassword"
                       (ngModelChange)="changed()"
                       autocomplete="off"
                       type="password"/>
            </mat-form-field>
            <button mat-icon-button
                    color="accent"
                    [matTooltip]="'Test connection'"
                    (click)="testCredentials()"
                    style="margin-bottom: 30px"
            >
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </div>

    <div class="action-buttons">
        <button mat-button
                *ngIf="mapLayer.dirty"
                color="accent"
                [matTooltip]="'Save changes on map layer configuration'"
                class="action-button"
                (click)="saveLayer(mapLayer)"
        >
            <mat-icon>save_as</mat-icon> Save Changes
        </button>
        <button mat-button
                *ngIf="mapLayer.dirty && !mapLayer.new"
                color="accent"
                [matTooltip]="'Cancel the changes'"
                class="action-button"
                (click)="back()"
        >
            <mat-icon>cancel</mat-icon> Cancel
        </button>
    </div>
</ng-container>

