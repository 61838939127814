import { Injectable } from '@angular/core';
import {Client, Message, StompSubscription} from '@stomp/stompjs';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SecurityService} from '../../security/security.service';
import {Subscription} from 'stompjs';
import {EventTypes, PublicEventsService} from 'angular-auth-oidc-client';
import {filter} from 'rxjs/operators';

@Injectable()
export abstract class WebSocketClientService {

  private initialized = false;
  private client: Client;
  private connectedClientObservable = new BehaviorSubject(null);
  connectionStatusObservable = new BehaviorSubject<boolean>(false);

  static jsonHandler(message: Message): any {
    return JSON.parse(message.body);
  }

  protected constructor(private securityService: SecurityService,
                        private eventService: PublicEventsService) {

    // This event is emitted after the library finishes checking the user's authentication status. This includes tasks like:
    // - Silently refreshing tokens
    // - Checking the validity of existing tokens
    // - Performing silent login if necessary
    // By subscribing to this event, you can be notified when the authentication process is fully complete,
    // and you can rely on the current authentication state.
    try {
      console.log('WebSocketClientService registering for events');
      this.eventService
        .registerForEvents()
        .pipe(filter((notification) =>
          notification.type === EventTypes.CheckingAuthFinished ||
          notification.type === EventTypes.NewAuthenticationResult
        ))
        .subscribe((value) => {
          this.securityService.getToken().then(renewedAccessToken => {
            if (!!this.client) {
              this.client.connectHeaders = {'Auth-Token': renewedAccessToken};
            }
          });
        }, error => {
          console.warn('WebSocketClientService subscribing events failed', error);
        });
    } catch (e) {
      console.warn('WebSocketClientService registering events failed', e);
    } finally {
      console.log('WebSocketClientService registered for events');
    }
  }

  public init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.initialized) {
        const message = 'Web Socket is already initialized! ' + this.getEndpointBaseUrl();
        console.error(message);
        reject(message);
      }

      this.securityService.getToken().then(accessToken => {
        this.client = new Client({
          brokerURL: this.getWebSocketEndPoint(),
          connectHeaders: {'Auth-Token': accessToken},
          debug: str => this.onDebug(str),
          reconnectDelay: 5000,
          heartbeatIncoming: 15000,
          heartbeatOutgoing: 15000,
        });

        this.client.onConnect = frame => this.onConnect(frame);
        this.client.onStompError = frame => this.onError(frame);
        this.client.onWebSocketClose = frame => this.onClose(frame);

        this.client.activate();
        this.initialized = true;
        resolve(true);
      });

    });
  }

  public release() {
    if (!this.initialized) {
      console.error('Trying to release Web Socket which is not initialized! ' + this.getEndpointBaseUrl());
      return;
    }

    this.client.deactivate().then(() => {
      this.client = null;
    });

    console.log('Releasing web socket ' + this.getEndpointBaseUrl());
    this.initialized = false;
  }

  abstract getEndpointBaseUrl(): string | null;

  private getWebSocketEndPoint(): string {
    const endpointBaseUrl = this.getEndpointBaseUrl();
    let baseUrl = 'ws';
    if (endpointBaseUrl.startsWith('http')) {
      baseUrl += endpointBaseUrl.substring(4);
    } else {
      if (!endpointBaseUrl.startsWith('/')) {
        throw new Error('Invalid services url. Expecting it to be absolute or starting with /');
      }
      const location = window.location.origin;
      baseUrl += location.substring(4) + endpointBaseUrl;
    }
    return baseUrl + 'events/websocket';
  }

  private onConnect(frame) {
    this.connectionStatusObservable.next(true);
    this.connectedClientObservable.next(this.client);
  }

  private onError(frame) {
    console.warn(frame);
    this.connectionStatusObservable.next(false);
    this.connectedClientObservable.next(null);
  }

  private onClose(frame) {
    this.connectionStatusObservable.next(false);
    this.connectedClientObservable.next(null);
  }

  private onDebug(message: string) {
    // console.log(message);
  }

  onMessage(topic: string, handler = WebSocketClientService.jsonHandler): Observable<any> {
    if (!this.initialized) {
      console.warn('Subscribing on Web Socket which is not initialized! ' + this.getEndpointBaseUrl() + ` Topic: ${topic}`);
    }

    return new Observable<any>(observer => {
      const subscriptions: Subscription[] = [];
      const connectedClientSubscription = this.connectedClientObservable.subscribe(connectedClient => {
        if (!!connectedClient) {
          const subscription: StompSubscription = connectedClient.subscribe(topic, message => {
            observer.next(handler(message));
          });
          subscriptions.push(subscription);
        } else {
          // disconnected - unsubscribe from client
          subscriptions.forEach(s => {
            try {
              s.unsubscribe();
            } catch (e) {
              // silently drop the error, don't flood console
            }
          });
        }
      });
      return () => {
        connectedClientSubscription.unsubscribe();
      };
    });
  }

  // unused
  send(topic: string, payload: any): void {
    this.client.publish({destination: topic, body: JSON.stringify(payload)});
  }
}
