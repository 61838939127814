import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {environment} from '../../../environments/environment';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {ObservationModel, ObservationTypeModel} from '../../shared/models/observation.model';

@Injectable({
  providedIn: 'root'
})
export class ObservationsService {
  constructor(private http: HttpClient) { }

  getObservationTypes()  {
    return this.http.get<JsonApiResponse<Array<ObservationTypeModel>>>(`${environment.services.service}v1/observation-type-group/all/type/list`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getObservations()  {
    const url = `${environment.services.service}v1/observation/active`;
    return this.http
      .get<JsonApiResponse<ObservationModel[]>>(url)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getObservationsByShiftId(shiftId: number)  {
    return this.http
      .get<JsonApiResponse<ObservationModel[]>>(`${environment.services.service}v1/shift/${shiftId}/observation/list`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  deleteObservation(id: number): Promise<void> {
    const requestUrl = `${environment.services.service}v1/observation/${id}`;

    return this.http.delete<void>(requestUrl)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }
}
