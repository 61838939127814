import { Injectable } from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../../../../configuration/settings.service';
import {Map, LngLat, RasterLayerSpecification, RasterSourceSpecification} from 'maplibre-gl';
import {ArcgisApiService} from '../../../../data/address-search/arcgis-api.service';

export class IncidentInfo {
  description: string;
  // tslint:disable-next-line:variable-name
  end_localtime: number;
  // tslint:disable-next-line:variable-name
  end_utctime: number;
  fulldescription: string;
  incidenttype: string;
  // tslint:disable-next-line:variable-name
  lastupdated_localtime: number;
  // tslint:disable-next-line:variable-name
  lastupdated_utctime: number;
  location: string;
  objectid: number;
  severity: string;
  // tslint:disable-next-line:variable-name
  start_localtime: number;
  // tslint:disable-next-line:variable-name
  start_utctime: number;
}

@Injectable({
  providedIn: 'root'
})
export class TrafficLayerService {

  static readonly TRAFFIC_LAYER_ID = 'traffic';
  static readonly TRAFFIC_SOURCE_ID = 'traffic-source';
  static readonly TRAFFIC_SERVICE_URL = `https://traffic.arcgis.com/arcgis/rest/services/World/Traffic/MapServer`;
  static readonly TRAFFIC_SERVICE_RASTER_URL = `${TrafficLayerService.TRAFFIC_SERVICE_URL}/export?dpi=96&transparent=true&format=png32&layers=show:2,3,4,6,18,19,20,22&bbox={bbox-epsg-3857}&bboxSR=3857&imageSR=3857&size=256,256&f=image&token=${ArcgisApiService.ArcGISAPIKey}`;

  private mapLayersManager: MapLayersManager;
  private isEnabled: boolean;
  private shouldBeVisible: boolean;
  settingsSubscription: Subscription;
  readonly refreshInterval = 300;
  refreshTimer = null;

  constructor(
      private http: HttpClient,
      private settingsService: SettingsService,
  ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSourceAndLayer();

    if (this.isEnabled) {
      this.connectToManager();
    }
  }

  release() {
    this.settingsSubscription?.unsubscribe();
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }
    this.cancelRefresh();
    this.mapLayersManager = null;
  }

  addSourceAndLayer(resetRefreshTimer: boolean = false) {
    if (this.isEnabled) {
      this.shouldBeVisible = this.settingsService.getBooleanValue(
          SettingsService.TRAFFIC_LAYER_KEY
      );
      const layer = {
        id: TrafficLayerService.TRAFFIC_LAYER_ID,
        source: TrafficLayerService.TRAFFIC_SOURCE_ID,
        type: 'raster',
        layout: {
          visibility: this.shouldBeVisible ? 'visible' : 'none',
        },
        paint: {
          'raster-opacity': 1.0,
        },
      } as RasterLayerSpecification;

      const source = {
        type: 'raster',
        tiles: [TrafficLayerService.TRAFFIC_SERVICE_RASTER_URL],
        tileSize: 256,
      } as RasterSourceSpecification;

      this.mapLayersManager.addSource(TrafficLayerService.TRAFFIC_SOURCE_ID, source);
      this.mapLayersManager.addLayer(layer);
      if (resetRefreshTimer) {
        this.resetTimer(this.shouldBeVisible);
      }
    }
  }

  updateSource() {
    const source = this.mapLayersManager?.getRasterSource(TrafficLayerService.TRAFFIC_SOURCE_ID);
    if (!!source) {
      source.setTiles([TrafficLayerService.TRAFFIC_SERVICE_RASTER_URL]);
    }
  }

  isVisible(): boolean {
    return this.isEnabled && this.shouldBeVisible;
  }

  // https://developers.arcgis.com/rest/services-reference/enterprise/query-map-service-layer-.htm
  // https://developers.arcgis.com/rest/network/api-reference/traffic-service.htm
  queryIncidents(map: Map, lnglat: LngLat) {
    const incidentLayer = 4; // 4 = North America, 20 = Europe

    const zoomLevel = map.getZoom();
    const deviation = 10 / Math.pow(2, zoomLevel);

    // @ts-ignore
    const params = new URLSearchParams({
      inSR: 4326,
      spatialRel: 'esriSpatialRelIntersects',
      geometryType: 'esriGeometryEnvelope',
      geometry: JSON.stringify({
        xmin: lnglat.lng - deviation,
        ymin: lnglat.lat - deviation,
        xmax: lnglat.lng + deviation,
        ymax: lnglat.lat + deviation,
        spatialReference: {
          wkid: 4326
        }
      }),
      outSR: 4326,
      outFields: 'objectid,severity,incidenttype,location,description,fulldescription,start_localtime,end_localtime,lastupdated_localtime,start_utctime,end_utctime,lastupdated_utctime',
      token: ArcgisApiService.ArcGISAPIKey,
      returnGeometry: true,
      f: 'json'
    });

    /*
    attributes example:

    description: "Between Proskovická and Svinovská - Resurfacing work."
    end_localtime: 1689941457000
    end_utctime: 1689934257000
    fulldescription: "Between Proskovická and Svinovská - Resurfacing work."
    incidenttype: "CONSTRUCTION"
    lastupdated_localtime: 1689934640000
    lastupdated_utctime: 1689927440000
    location: "1. května KLIMKOVICE"
    objectid: 350343126
    severity: "critical"
    start_localtime: 1689903657000
    start_utctime: 1689896457000
    */

    return new Promise((resolve, reject) => {
      fetch(`${TrafficLayerService.TRAFFIC_SERVICE_URL}/${incidentLayer}/query?${params.toString()}`)
          .then(response => response.json())
          .then(data => resolve(data))
          .catch(error => reject(error));
    });
  }

  private handleLayerVisibilityChange(makeVisible: boolean) {
    this.shouldBeVisible = makeVisible;
    this.mapLayersManager.setLayerVisibility(TrafficLayerService.TRAFFIC_LAYER_ID, makeVisible);
    this.resetTimer(makeVisible);
  }

  private resetTimer(setTimer: boolean) {
    if (setTimer) {
      this.scheduleRefresh();
    } else {
      this.cancelRefresh();
    }
  }

  private refreshHandler() {
    this.updateSource();
    this.scheduleRefresh();
  }

  private scheduleRefresh() {
    const that = this;
    this.refreshTimer = setTimeout(() => {
      that.refreshHandler();
    }, that.refreshInterval * 1000);
  }

  private cancelRefresh() {
    if (!!this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  private connectToManager() {
    const that = this;

    this.settingsSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.TRAFFIC_LAYER_KEY) {
          that.handleLayerVisibilityChange(newSettings.value);
        }
      }
    });
  }
}
