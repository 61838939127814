import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {SettingsService, TracksLayerFilter, TracksLayerType} from '../../../../configuration/settings.service';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {ShiftModel, ShiftState} from '../../../models/shift.model';
import {MapLayersManager} from '../map-layers-manager';
import {LineLayerSpecification, VectorSourceSpecification, VectorTileSource} from 'maplibre-gl';
import {environment} from '../../../../../environments/environment';
import {MapStyles, VehicleColor} from '../../../../configuration/map-styles';
import {SecurityService} from '../../../../security/security.service';
import {EventTypes, PublicEventsService} from 'angular-auth-oidc-client';
import {filter} from 'rxjs/operators';
import {LiveMapDataService} from '../../../../pages/live-map/services/live-map-data.service';
import {VehicleModelWithActiveShift} from '../../../models/vehicle.model';
import {MapFilters} from '../../../../configuration/map-filters';
import {ServerEventService} from '../../../../data/server-events/server-event.service';
import {TracksGeoJsonLayerService} from './tracks-geo-json-layer.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../data/assets/assets-manager.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LiveMapTab} from '../../../../pages/live-map/models/live-map-tabs';
import {LocationHistoryService} from './location-history.service';

@Injectable({
  providedIn: 'root'
})
export class TracksVectorTilesLayerService {

  static readonly TRACKS_SOURCE_ID = 'tracks-tiles';
  static readonly LAYER_ID_HISTORY = 'tracks-history-overlay';
  static readonly LAYER_ID_ACTIVE = 'tracks-overlay';

  private mapLayersManager: MapLayersManager;
  private lineLayers: LineLayerSpecification[] = [];

  private assetDetailVisibility = false;
  private locationHistoryVisibility = false;
  private highlightedShift: ShiftModel = null;
  private selectedVehicleIds = null;
  private activeShiftIds = null;
  private isVisible = true;
  private currentTrackLayerType = null;
  private hoursAgoFilter = 12;
  private showRecent = false;
  private customColors: VehicleColor[] = [];
  private flagsFilter: any[] = [];
  private isEnabled: boolean;
  private accessToken: string;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private locationHistoryService: LocationHistoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private serverEventService: ServerEventService,
              private shiftsManagerService: ShiftsManagerService,
              private settingsService: SettingsService,
              private securityService: SecurityService,
              private eventService: PublicEventsService,
              private assetManager: AssetsManagerService,
              private liveMapDataService: LiveMapDataService,
              private configurationService: ConfigurationService,
              ) {

    // This event is emitted after the library finishes checking the user's authentication status. This includes tasks like:
    // - Silently refreshing tokens
    // - Checking the validity of existing tokens
    // - Performing silent login if necessary
    // By subscribing to this event, you can be notified when the authentication process is fully complete,
    // and you can rely on the current authentication state.
    try {
      this.eventService
        .registerForEvents()
        .pipe(filter((notification) =>
          notification.type === EventTypes.CheckingAuthFinished ||
          notification.type === EventTypes.NewAuthenticationResult))
        .subscribe((value) => {
          console.log('TracksVectorTilesLayerService: event catch, getting new auth token');
          this.securityService.getToken()
            .then(accessToken => {
              this.setAccessToken(accessToken);
              this.updateSource();
            })
            .catch(error => {
              console.warn('TracksVectorTilesLayerService get token error', error);
            });
        }, error => {
          console.warn('TracksVectorTilesLayerService registering events error', error);
        });
    } catch (e) {
      console.warn('TracksVectorTilesLayerService events registration failed.', e);
    }
  }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The mapLayersManager has already been set yet.');
    }

    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSourceAndLayers();
    this.connectToManager();
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The mapLayersManager has not been set yet.');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.lineLayers = [];
    this.mapLayersManager = null;
  }

  addSourceAndLayers() {
    this.lineLayers = [];

    this.securityService.getToken().then(accessToken => {
      this.setAccessToken(accessToken);
      // this.accessToken = accessToken;
      // add source
      this.addSource();

      // add layers
      this.currentTrackLayerType = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY);
      this.setVisibility();
      const isVisible = this.isEnabled && this.isVisible ? 'visible' : 'none';
      this.getLineLayers().forEach(layer => {
        layer.layout.visibility = isVisible;
        this.lineLayers.push(layer);
        this.mapLayersManager.addLayer(layer);
        this.mapLayersManager.moveLayer(layer.id, TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON);
      });
    }).catch(error => {
      // HttpErrorHandler.handleError(error);
      console.error(error);
    });
  }

  private setVisibility() {
    // GPS tracks layer is visible also for Asset Detail when displaying Currency/Coverage
    switch (this.currentTrackLayerType) {
      case TracksLayerType.GPS_TRACKS:
        this.isVisible = !!this.assetDetailVisibility || this.locationHistoryVisibility;
        break;
      case TracksLayerType.CURRENCY:
      case TracksLayerType.COVERAGE:
        this.isVisible = !!this.assetDetailVisibility;
        break;
      default:
        this.isVisible = !!this.assetDetailVisibility; // always on for Asset Detail
    }
  }

  private handleLayerVisibilityChange() {
    if (this.isEnabled) {
      this.setVisibility();
      this.lineLayers.forEach(layer => {
        this.mapLayersManager.setLayerVisibility(layer.id, this.isVisible);
      });
    }
  }

  private handleHighlightedShiftChanged(shift?: ShiftModel) {
    this.highlightedShift = shift;
    if (this.lineLayers.length > 0) {
      this.updateFiltersAndStyles();
    }
  }

  private handleTimeFilterChanged(timeFilter: number) {
    this.hoursAgoFilter = timeFilter;
    this.updateFiltersAndStyles();
  }

  private handleShowRecentChanged(showRecent: boolean) {
    this.showRecent = showRecent;
    this.updateFiltersAndStyles();
  }

  private handleTrackFilterChanged(trackFilter: TracksLayerFilter) {
    this.flagsFilter = MapFilters.getTrackFilter(trackFilter);
    this.updateFiltersAndStyles();
  }

  private handleCustomVehicleMapColor(vehicles: VehicleModelWithActiveShift[]) {
    // update custom colors
    this.customColors = vehicles
        .filter(vehicle => !!vehicle.mapColor)
        .map(vehicle => {
          return {id: vehicle.id, color: vehicle.mapColor};
        });
    this.updateFiltersAndStyles();
  }

  private handleAssetsChanged(assets: Asset[]) {
    const activeShiftIds = [];
    const vehicleIds = [];
    assets.forEach(asset => {
      if (asset.shiftStatus !== ShiftState.ENDED && !!asset.shiftId) {
        activeShiftIds.push(asset.shiftId);
      }
      vehicleIds.push(asset.id);
    });
    this.activeShiftIds = activeShiftIds;
    this.selectedVehicleIds = vehicleIds;
    this.updateFiltersAndStyles();
  }

  private connectToManager() {
    const that = this;

    const changesSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
          that.currentTrackLayerType = newSettings.value;
          that.handleLayerVisibilityChange();
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_TIME_FILTER) {
          that.handleTimeFilterChanged(Number(newSettings.value));
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_LAYER_FILTER) {
          that.handleTrackFilterChanged(newSettings.value);
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_SHOW_RECENT) {
          that.handleShowRecentChanged(newSettings.value);
        }
      }
    });
    this.openSubscriptions.push(changesSubscription);

    const routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const oldIsAssetDetail = this.assetDetailVisibility;
        this.assetDetailVisibility =
            this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(LiveMapTab.ASSETS + '/:id');

        // if any change
        if (oldIsAssetDetail !== this.assetDetailVisibility) {
          that.handleLayerVisibilityChange();
        }
      }
    });
    this.openSubscriptions.push(routerSubscription);

    // handle visibility based on route
    const locationHistoryVisibilitySubscription = this.locationHistoryService.visibility$.subscribe(visible => {
      this.locationHistoryVisibility = visible;
      this.handleLayerVisibilityChange();
    });
    this.openSubscriptions.push(locationHistoryVisibilitySubscription);

    const allVehiclesSubscription = this.liveMapDataService.vehicles$.subscribe(vehicles => {
      this.handleCustomVehicleMapColor(vehicles);
    });
    this.openSubscriptions.push(allVehiclesSubscription);

    const assetsSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleAssetsChanged(assets);
    });
    this.openSubscriptions.push(assetsSubscription);

    const highlightedShiftSubscription = this.shiftsManagerService.highlightedShift$.subscribe({
      next(shiftFilterUpdate) {
        that.handleHighlightedShiftChanged(shiftFilterUpdate.shift);
      }
    });
    this.openSubscriptions.push(highlightedShiftSubscription);

    const tileCacheChangeSubscription = this.serverEventService.tilesCacheUpdateObservable.subscribe({
      next(/*cacheUpdate*/) {
        that.updateSource();
      }
    });
    this.openSubscriptions.push(tileCacheChangeSubscription);
  }

  private setAccessToken(token: string) {
    if (this.accessToken === token) {
      console.warn('setting token is same as original!');
    }
    this.accessToken = token;
  }

  private getBaseUrl(): string {
    let baseUrl = environment.services.location;
    if (!baseUrl.startsWith('http')) {
      // fix for relative url
      baseUrl = `${window.location.origin}${baseUrl}`;
    }
    return baseUrl;
  }

  private getTileUrl(accessToken: string): string {
    return `${this.getBaseUrl()}v1/track/tile/{z}/{x}/{y}?access_token=${accessToken}`;
  }

  private getSource(accessToken: string): VectorSourceSpecification {
    return {
      id: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
      type: 'vector',
      maxzoom: 18,
      tiles: [
        this.getTileUrl(accessToken)
      ]
    } as VectorSourceSpecification;
  }

  private addSource() {
    if (!!this.mapLayersManager) {
      this.mapLayersManager.addSource(TracksVectorTilesLayerService.TRACKS_SOURCE_ID, this.getSource(this.accessToken));
    }
  }

  private updateSource() {
    if (!!this.mapLayersManager) {
      const source = this.mapLayersManager.getVectorSource(TracksVectorTilesLayerService.TRACKS_SOURCE_ID);
      if (!!source) {
        (source as VectorTileSource).setTiles([this.getTileUrl(this.accessToken)]);
      }
    }
  }

  getLineLayers(): LineLayerSpecification[] {
    const activeShiftIds = this.activeShiftIds;
    const selectedVehicleIds = this.selectedVehicleIds;
    const highlightedShift = this.highlightedShift;
    return [
      {
        id: TracksVectorTilesLayerService.LAYER_ID_HISTORY,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: MapFilters.getInactiveShiftsFilter(
            activeShiftIds,
            selectedVehicleIds,
            this.hoursAgoFilter,
            this.flagsFilter,
            highlightedShift?.id,
            this.showRecent,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': MapStyles.getHighlightedProperty(
              highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
              this.configurationService.trackStyles.liveMapPlowHighlighted.color,
              this.configurationService.trackStyles.liveMapPlowInactive.color,
          ),
          'line-opacity': this.configurationService.trackStyles.liveMapPlowInactive.opacity,
          'line-width': MapStyles.getHighlightedProperty(
              highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.width,
            this.configurationService.trackStyles.liveMapPlowInactive.width,
          ),
        }
      } as LineLayerSpecification,
      {
        id: TracksVectorTilesLayerService.LAYER_ID_ACTIVE,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: MapFilters.getActiveShiftsFilter(
            activeShiftIds,
            selectedVehicleIds,
            this.hoursAgoFilter,
            this.flagsFilter,
            highlightedShift?.id
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': MapStyles.getHighlightedColor(
              highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.color,
            this.configurationService.trackStyles.liveMapPlowLive.color,
            this.customColors,
          ),
          'line-opacity': this.configurationService.trackStyles.liveMapPlowLive.opacity,
          'line-width': MapStyles.getHighlightedProperty(
              highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.width,
            this.configurationService.trackStyles.liveMapPlowLive.width,
          ),
        }
      } as LineLayerSpecification,
    ];
  }

  updateFiltersAndStyles() {
    const activeShiftIds = this.activeShiftIds;
    const selectedVehicleIds = this.selectedVehicleIds;
    const highlightedShift = this.highlightedShift;
    this.lineLayers.forEach(lineLayer => {
      switch (lineLayer.id) {
        case TracksVectorTilesLayerService.LAYER_ID_HISTORY:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              MapFilters.getInactiveShiftsFilter(
                activeShiftIds,
                selectedVehicleIds,
                this.hoursAgoFilter,
                this.flagsFilter,
                highlightedShift?.id,
                this.showRecent,
              )
          );
          this.mapLayersManager.setLayerPaintProperty(
            lineLayer.id,
            'line-color',
              MapStyles.getHighlightedProperty(
                  highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
                this.configurationService.trackStyles.liveMapPlowHighlighted.color,
                this.configurationService.trackStyles.liveMapPlowInactive.color,
              )
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              MapStyles.getHighlightedProperty(
                  highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
                this.configurationService.trackStyles.liveMapPlowHighlighted.width,
                this.configurationService.trackStyles.liveMapPlowInactive.width,
              )
          );
          break;
        case TracksVectorTilesLayerService.LAYER_ID_ACTIVE:
          this.mapLayersManager.setFilter(lineLayer.id, MapFilters.getActiveShiftsFilter(
              activeShiftIds,
              selectedVehicleIds,
              this.hoursAgoFilter,
              this.flagsFilter,
              highlightedShift?.id,
              )
          );
          this.mapLayersManager.setLayerPaintProperty(
            lineLayer.id,
            'line-color',
            MapStyles.getHighlightedColor(
                highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
              this.configurationService.trackStyles.liveMapPlowHighlighted.color,
              this.configurationService.trackStyles.liveMapPlowLive.color,
              this.customColors,
            )
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              MapStyles.getHighlightedProperty(
                  highlightedShift?.id !== undefined ? [highlightedShift.id] : null,
                this.configurationService.trackStyles.liveMapPlowHighlighted.width,
                this.configurationService.trackStyles.liveMapPlowLive.width,
              )
          );
          break;
      }
    });
  }
}
