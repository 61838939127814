import {Component, OnInit} from '@angular/core';
import {MifForm} from '../model/MifForm';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InspectionFormsService} from '../../../../../data/inspection-forms/inspection-forms.service';
import {LoadState} from '../../../../../shared/tools/LoadState';
import {MifFormReviewingSignaturesOption} from '../model/form-options/MifFormReviewingSignaturesOption';
import {MifFormQuestionFormatOption} from '../model/form-options/MifFormQuestionFormatOption';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MifNode} from '../model/questions-tree/MifNode';
import {MifMoveResult} from '../model/questions-tree/MifMoveResult';
import {MifSectionType} from '../model/questions-tree/node-types/MifSectionType';
import {MifGroupType} from '../model/questions-tree/node-types/MifGroupType';
import {MifQuestionType} from '../model/questions-tree/node-types/MifQuestionType';
import {NodeOperation} from '../model/questions-tree/NodeOperation';
import {NodeOperationEnum} from '../model/questions-tree/NodeOperationEnum';
import {BaseNodeDialogData, NodeDialogData, TextNodeDialogData} from './mif-node-dialog/NodeDialogData';
import {combineLatest} from 'rxjs';
import {SharedDataService} from '../../../../../shared/services/shared-data.service';
import {MifFormDto, MifFormEmailNotificationType} from '../model/dto/MifFormDto';
import {UserManagementService} from '../../../../../data/users/user-management.service';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-inspection-form',
  templateUrl: './inspection-form.component.html',
  styleUrls: ['./inspection-form.component.scss']
})
export class InspectionFormComponent implements OnInit {

  loadState = LoadState.NOT_LOADED_YET;
  loadError = '';
  saving = false;

  formDetailHeader = 'Inspection form';

  inspectionForm: MifForm = null;
  selectedNode: MifNode = null;

  nodes: any[] = [];
  nodeOperations: NodeOperation[] = [];

  unsupportedStructure = false;

  allUserEmails: string[];

  LoadState = LoadState;
  MifFormReviewingSignaturesOption = MifFormReviewingSignaturesOption;
  MifFormQuestionFormatOption = MifFormQuestionFormatOption;
  MifFormEmailNotificationType = MifFormEmailNotificationType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private inspectionFormService: InspectionFormsService,
    private sharedDataService: SharedDataService,
    private userService: UserManagementService,
  ) { }

  ngOnInit(): void {
    combineLatest([this.route.queryParams, this.route.url])
      .subscribe(([urlParams, urlSegments]) => {
        this.loadState = LoadState.LOADING;
        const inspectionFormIdParam = this.route.snapshot.paramMap.get('inspectionFormId');
        const defaultForm = this.route.snapshot.paramMap.get('form');

        if (inspectionFormIdParam === 'new') {
          this.initNew();
        } else if (inspectionFormIdParam === 'system') {
          this.initDefault();
        } else {
          this.initCopyOrEdit(+inspectionFormIdParam, urlSegments);
        }
      });

    // load all supervisors and admins as potential email recipients
    this.userService.getSupervisorList().toPromise().then(response => {
      this.allUserEmails = response.data
          .filter(user => !user.email.startsWith('neotreks_support@'))
          .map(user => user.email);
    });
  }

  private initNew(){
      this.inspectionForm = MifForm.createNew();
      this.formDetailHeader = 'Create new inspection form';
      this.loadState = LoadState.LOADED_OK;
  }

  private initDefault() {
    const other = this.sharedDataService.takeOut('default-form') as MifFormDto;
    if (other == null) {
      this.initNew(); // this is fallback for reload page or after the re-login
      return;
    }
    const form = MifForm.fromDto(other);
    this.formDetailHeader = form.title;
    this.inspectionForm = form;
    this.nodes = this.inspectionForm.generateAllInputNodes();
    this.loadState = LoadState.LOADED_OK;
  }

  private initCopyOrEdit(inspectionFormIdParam, urlSegments: UrlSegment[]) {
      const inspectionFormId = +inspectionFormIdParam;
      this.inspectionFormService.getForm(inspectionFormId)
        .then(response => {
          // copy and edit
          if (urlSegments.some(segment => segment.path === 'copy')) {
            this.formDetailHeader = 'Duplicate inspection form';
            this.inspectionForm = response.duplicate();
          } else { // edit
            this.formDetailHeader = 'Edit inspection form';
            this.inspectionForm = response;
          }
          this.nodes = this.inspectionForm.generateAllInputNodes();
          this.loadState = LoadState.LOADED_OK;
        })
        .catch(error => {
          this.handleLoadError('Error while loading inspection form', error);
          this.inspectionForm = null;
        });
  }

  moveEvent(moveResult: MifMoveResult) {
    if (moveResult.succeeded) {
      this.regenerateTreeNodes(moveResult.operations);
      this.determineUnsupportedStructure();
    } else {
      this.showSnackBar(moveResult.violation.message);
    }
  }

  selectEvent(node: MifNode) {
    this.selectedNode = node;
  }

  deselectEvent() {
    this.selectedNode = null;
  }

  collapseEvent() {
    this.deselectAndNotify();
  }

  private regenerateTreeNodes(nodeOperations: NodeOperation[]) {

    this.nodes = this.inspectionForm.generateAllInputNodes();
    this.nodeOperations = nodeOperations;
  }

  private deselectAndNotify() {
    if (this.selectedNode != null) {
      this.nodeOperations = [this.selectedNode.asNodeOperation(NodeOperationEnum.DESELECT)];
      this.selectedNode = null;
    }
  }

  buttonClickedCancel() {
    this.router.navigate(['/settings/inspection-forms']);
  }

  buttonDisabledSave(): boolean {
    return this.saving || this.inspectionForm.title.trim() === '' || this.unsupportedStructure;
  }

  buttonClickedSave() {
    this.saving = true;
    this.inspectionFormService.saveForm(this.inspectionForm)
      .then(savedForm => {
        this.router.navigate(['/settings/inspection-forms']);
      })
      .catch(error => {
        this.handleActionError('Error while saving form', error);
        this.saving = false;
      });
  }

  treeButtonClickedDeselect() {
    this.deselectAndNotify();
  }

  formMenuItemClickedAddSection() {
    this.showAddDialog(null,
      () => MifSectionType.showCreateDialog(this.dialog),
      data => new MifNode(0, new MifSectionType(data.textField, data.hasRemarks))
    );
  }

  formMenuItemClickedAddGroup() {
    this.showAddDialog(null,
      () => MifGroupType.showCreateDialog(this.dialog),
      data => new MifNode(0, new MifGroupType(data.textField, data.hasRemarks))
    );
  }

  treeMenuItemClickedAddGroup() {
    this.showAddDialog(this.selectedNode,
      () => MifGroupType.showCreateDialog(this.dialog),
      data => new MifNode(0, new MifGroupType(data.textField, data.hasRemarks))
    );
  }

  formMenuItemClickedAddQuestion() {
    this.showAddDialog(null,
      () => MifQuestionType.showCreateDialog(this.dialog),
      data => new MifNode(
        0,
        new MifQuestionType(data.textField, (data as TextNodeDialogData).notApplicable)
      )
    );
  }

  treeMenuItemClickedAddQuestion() {
    this.showAddDialog(this.selectedNode,
      () => MifQuestionType.showCreateDialog(this.dialog),
      data => new MifNode(
        0, new MifQuestionType(
          data.textField,
          (data as TextNodeDialogData).notApplicable)
      ));
  }

  treeButtonClickedEdit() {
    this.showEditDialog();
  }

  treeButtonClickedRemove() {
    if (this.selectedNode != null) {
      this.selectedNode.removeFromParent();
      this.selectedNode = null;
      this.regenerateTreeNodes([]);
      this.determineUnsupportedStructure();
    }
  }

  toggleEmailNotifications() {
    const settings = this.inspectionForm.settings;
    if (settings.enableEmailNotifications) {
      settings.emailNotificationType = MifFormEmailNotificationType.ON_OUT_OF_SERVICE;
      settings.emailRecipients = [...this.allUserEmails]; // clone array
    } else {
      settings.emailNotificationType = undefined;
      settings.emailRecipients = undefined;
    }
  }

  isEmailRecipient(email: string): boolean {
    const emailRecipients = this.inspectionForm.settings.emailRecipients;
    if (!emailRecipients) {
      return false;
    }
    return emailRecipients.includes(email);
  }

  toggleEmailRecipient(e: MatCheckboxChange, email: string) {
    const emailRecipients = this.inspectionForm.settings.emailRecipients;
    if (e.checked) {
      emailRecipients.push(email);
    } else {
      emailRecipients.splice(emailRecipients.indexOf(email), 1);
    }
  }

  private showAddDialog(selected: MifNode, dialogInvocation: () => MatDialogRef<any>, nodeCreation: (data: BaseNodeDialogData | TextNodeDialogData) => MifNode) {
    const dialogRef = dialogInvocation();

    const newNodeOperations: NodeOperation[] = [];
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        const data = result as NodeDialogData;
        const newNode = nodeCreation(data);
        if (selected == null) {
          this.inspectionForm.addChildNode(newNode);
        } else {
          selected.addChildNode(newNode);
          newNodeOperations.push(selected.asNodeOperation(NodeOperationEnum.EXPAND));
        }
        this.determineUnsupportedStructure();
        this.regenerateTreeNodes(newNodeOperations);
      }
    });
  }

  private showEditDialog() {
    if (this.selectedNode != null) {
      this.selectedNode.showEditDialog(this.dialog, () => {
        this.regenerateTreeNodes([]);
      });
    }
  }

  private determineUnsupportedStructure() {
    this.unsupportedStructure = this.inspectionForm.hasUnsupportedStructure();
  }

  private showSnackBar(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  private handleLoadError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);

    this.loadState = LoadState.LOADED_ERROR;
    this.loadError = msgError;
  }

  private handleActionError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);
  }
}
