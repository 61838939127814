import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {
  AvlModel,
  CameraConfiguration,
  DigitalSpreader,
  DigitalSpreaderModel,
  HardwareConfiguration,
  LocalRecording,
  SensorInput,
  SensorType, Streaming
} from '../../../../../shared/models/vehicle.model';
import {NamedId} from '../../../../../shared/models/NamedId';
import {Equipment} from '../model/equipment.class';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {VehicleCategoryModel} from '../../../../../shared/models/vehicle';


export interface VehicleData {
  id: number;
  active: boolean;
  groupId: number;
  name: string;
  label: string;
  licensePlate: string;
  lmuId: string;
  tablet: boolean;
  hasNoTablet: boolean;
  mapColor: string;
  useCamera: boolean;
  cameraRotation: number;
  cameraConfiguration?: CameraConfiguration;
  hardwareConfiguration?: HardwareConfiguration;
  reportingType: string;
}



@Component({
  selector: 'app-dialog-confirm-delete-vehicle',
  templateUrl: 'dialog-confirm-delete-vehicle.html',
})
export class DialogConfirmDeleteVehicleComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VehicleData) {}
}

@Component({
  selector: 'app-dialog-move-vehicle',
  templateUrl: 'dialog-move-vehicle.html',
  styleUrls: ['./dialog-components.scss'],
})
export class DialogMoveVehicleComponent implements OnInit {

  groups: VehicleCategoryModel[];
  vehicleGroup: VehicleCategoryModel;

  constructor(
      public dialogRef: MatDialogRef<DialogMoveVehicleComponent>,
      @Inject(MAT_DIALOG_DATA) public data: VehicleData,
      private vehicleService: VehiclesService,
  ) {}

  ngOnInit() {
    this.vehicleService.getVehicleCategories().toPromise().then(response => {
      this.groups = response.data;
      this.vehicleGroup = this.groups.find(group => this.data.groupId === group.id);
    }).catch(error => {
      console.log(error);
    });
  }

  canMove(): boolean {
    return this.data.groupId !== this.vehicleGroup?.id;
  }
}

abstract class DialogEditVehicleComponent {
  abstract headline: string;
  abstract actionButtonLabel: string;
  vehicles: VehicleData[];
  defaultMapColor: string;
  avl: boolean;

  dashCamOptions: {
    camera?: boolean;
    cameraRotation?: boolean;
    localRecording?: boolean;
    localImagesInterval?: boolean;
    streaming?: boolean;
    streamingDuration?: boolean;
    staticImagesInterval?: boolean;
  } = {};

  equipments: Equipment[] = [];
  usedInputs: number[] = [];
  allInputs: NamedId[];

  // digital spreader integration
  digitalSpreaderCheckbox: boolean;

  // enums
  AvlModel = AvlModel;
  FeatureFlagEnum = FeatureFlagEnum;
  SensorType = SensorType;
  protected readonly DeviceModel = DigitalSpreaderModel;

  protected constructor(
      public dialogRef: MatDialogRef<DialogCreateVehicleComponent | DialogUpdateVehicleComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {
        model: VehicleData, configuration: ConfigurationModel, vehicles: VehicleData[], isImported: boolean, defaultMapColor: string
      }
  ) {
    this.defaultMapColor = data.defaultMapColor;
    this.vehicles = data.vehicles;

    if (data.model.cameraConfiguration && !data.model.cameraConfiguration?.localRecording) {
      data.model.cameraConfiguration.localRecording = new LocalRecording();
    }
    if (data.model.cameraConfiguration && !data.model.cameraConfiguration?.streaming) {
      data.model.cameraConfiguration.streaming = new Streaming();
    }
    if (!data.model.cameraConfiguration) {
      data.model.cameraConfiguration = new CameraConfiguration();
    }

    // camera
    this.dashCamOptions.cameraRotation = data.model.cameraConfiguration?.rotation !== null;

    this.dashCamOptions.localRecording = (!!data.model.cameraConfiguration?.localRecording?.captureEnabled
    || data.model.cameraConfiguration?.localRecording?.captureImageInterval > 0);

    this.dashCamOptions.localImagesInterval = this.dashCamOptions.localRecording
      && data.model.cameraConfiguration?.localRecording?.captureImageInterval > 0;

    this.dashCamOptions.streaming = data.model.cameraConfiguration?.streaming?.captureStreamDuration > 0;
    this.dashCamOptions.streamingDuration = (data.model.cameraConfiguration?.streaming?.captureStreamDuration > 0
      || data.model.cameraConfiguration?.streaming?.captureImageInterval > 0);
    this.dashCamOptions.staticImagesInterval = data.model.cameraConfiguration?.streaming?.captureImageInterval > 0;

    this.dashCamOptions.camera = !!data.model.cameraConfiguration && (
      this.dashCamOptions.localRecording || this.dashCamOptions.streaming);


    this.avl = !!data.model.hardwareConfiguration && !!this.data.model.hardwareConfiguration.avlModel;
    if (!!this.data.model.hardwareConfiguration) {
      if (!!this.data.model.hardwareConfiguration.avlModel) {
        this.initializeAllInputsData();
        this.equipments = this.fromSensorInputsToEquipments(this.data.model.hardwareConfiguration.sensorInputs);
        this.setUsedInputs();
      }
      if (!!this.data.model.hardwareConfiguration.digitalSpreader) {
        this.digitalSpreaderCheckbox = true;
      }
    } else {
      this.data.model.hardwareConfiguration = new HardwareConfiguration();
    }
  }

  abstract vehicleValues(key): string[];

  hasFeatureFlag(featureFlag: string): boolean {
    return this.data.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  onOkClick() {
    if (this.data.model.hardwareConfiguration) {
      this.data.model.hardwareConfiguration.sensorInputs = this.fromEquipmentsToSensorInputs(this.equipments);
    }
    this.dialogRef.close(this.data.model);
  }

  avlModelChanged() {
    this.initializeAllInputsData();
    this.data.model.hardwareConfiguration.sensorInputs = [];
    this.equipments = [];
    this.digitalSpreaderCheckbox = false;
    this.data.model.hardwareConfiguration.digitalSpreader = null;
  }

  onAvlChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.data.model.hardwareConfiguration.avlModel = null;
      this.data.model.hardwareConfiguration.sensorInputs = [];

    } else {
      this.data.model.hardwareConfiguration.avlModel = null;
      this.data.model.hardwareConfiguration.sensorInputs = [];
    }
  }

  onCameraChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.data.model.cameraConfiguration = new CameraConfiguration();
    } else {
      this.data.model.cameraConfiguration = null;
    }
  }

  onCameraRotationChange(event: MatCheckboxChange) {
    this.data.model.cameraConfiguration.rotation = !!event.checked ? 180 : 0;
  }

  onStreamingChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.data.model.cameraConfiguration.streaming = new Streaming();
    } else {
      this.data.model.cameraConfiguration.streaming = null;
    }
  }
  onCamStreamingDurationChange(event: MatCheckboxChange) {
    this.data.model.cameraConfiguration.streaming.captureStreamDuration = !!event.checked ? 30 : null;
  }

  onCamStreamingImageIntervalChange(event: MatCheckboxChange) {
    this.data.model.cameraConfiguration.streaming.captureImageInterval = !!event.checked ? 1 : null;
  }

  onLocalRecordingChange(event: MatCheckboxChange) {
    if (!event.checked) {
      this.dashCamOptions.localImagesInterval = false;
      this.data.model.cameraConfiguration.localRecording.captureImageInterval = null;
      this.data.model.cameraConfiguration.localRecording.captureEnabled = null;
    }
  }

  onLocalImageChange(event: MatCheckboxChange) {
      this.data.model.cameraConfiguration.localRecording.captureImageInterval = !!event.checked ? 1 : null;
  }

  fromSensorInputsToEquipments(sensorInputs: SensorInput[]): Equipment[] {
    const equipments: Equipment[] = [];
    sensorInputs
        .filter(input => input.type !== SensorType.NONE)
        .forEach((sensorInput, index) => {
      if (sensorInput.type !== SensorType.PLOW2W || // 1-input hw
          (sensorInput.type === SensorType.PLOW2W && // 2-input hw with previous non-2-input hw
              (index === 0 ||
                  (index >= 1 && sensorInputs[index - 1].type !== SensorType.PLOW2W ) ||
                  (index >= 1 && sensorInputs[index - 1].type === SensorType.PLOW2W && !!equipments[equipments.length - 1]?.inputDown)
              )
          )
      ) {
        const equipment = {
          type: sensorInput.type,
          label: sensorInput.label,
          rate: sensorInput.rate,
          reversed: sensorInput.reversed,
          bitNumber: sensorInput.bitNumber,
        } as Equipment;
        equipment.inputUp = sensorInput;
        equipments.push(equipment);
      } else {
        // 2-input hw with previous 2-input hw with inputUp
        equipments[equipments.length - 1].inputDown = sensorInput;
      }
    });

    return equipments;
  }

  fromEquipmentsToSensorInputs(equipments: Equipment[]): SensorInput[] {
    const sensorInputs: SensorInput[] = [];
    for (const equipment of equipments) {
      const sensorInput = new SensorInput(equipment.inputUp.id, equipment.inputUp.name);
      sensorInput.type = equipment.type;
      sensorInput.label = equipment.label;
      sensorInput.rate = equipment.rate;
      sensorInput.reversed = equipment.reversed;
      sensorInput.bitNumber = equipment.bitNumber;
      sensorInputs.push(sensorInput);
      if (equipment.type === SensorType.PLOW2W) {
        const sensorInputDown = new SensorInput(equipment.inputDown.id, equipment.inputDown.name);
        sensorInputDown.type = equipment.type;
        sensorInputDown.label = equipment.label;
        sensorInputDown.rate = equipment.rate;
        sensorInputDown.reversed = equipment.reversed;
        sensorInputDown.bitNumber = equipment.bitNumber;
        sensorInputs.push(sensorInputDown);
      }
    }
    return sensorInputs;
  }

  onEquipmentDeleted(index: number) {
    this.equipments.splice(index, 1);
  }

  // update bitNumbers for all equipment
  onEquipmentTypeChanged() {
    const bitOffsetsWithSensorTypes = [
      {
        offset: 0,
        types: [
            SensorType.PLOW,
            SensorType.PLOW2W,
        ]
      },
      {
        offset: 5,
        types: [
            SensorType.GRANULAR_SPREADER,
            SensorType.DIGITAL_GRANULAR_SPREADER,
        ]
      },
      {
        offset: 10,
        types: [
          SensorType.LIQUID_SPREADER,
          SensorType.DIGITAL_LIQUID_SPREADER,
        ]
      },
      {
        offset: 15,
        types: [
          SensorType.MOWER,
        ]
      },
      {
        offset: 20,
        types: [
          SensorType.SWEEPER,
        ]
      }
    ];

    bitOffsetsWithSensorTypes.forEach(offsetWithType => {
      let bitNumber = 0;
      const filteredEquipments = this.equipments.filter(input => offsetWithType.types.includes(input.type));
      for (const filteredEquipment of filteredEquipments) {
        filteredEquipment.bitNumber = bitNumber + offsetWithType.offset;
        bitNumber++;
      }
    });
  }

  onInputsChanged() {
    this.setUsedInputs();
  }

  private initializeAllInputsData() {
    const avlModel = this.data.model.hardwareConfiguration.avlModel;
    if (!avlModel) {
      console.warn('AVL model not initialized!');
    }
    const inputsDefault: string[] = ['Blue', 'Orange', 'Violet', 'Grey', 'Green/White'];
    const inputs5530AndHub: string[] = ['Blue', 'Orange', 'Violet', 'Grey', 'ATD - Analog to Digital, Pink'];
    const inputsHubC: string[] = [...Array(4)].map(() => 'ATD - Analog to Digital'); // same, 4 only
    let inputs: string[];
    switch (avlModel) {
      case AvlModel.CALAMP_5530:
        inputs = inputs5530AndHub;
        break;
      case AvlModel.PLOWOPS_HUB:
        inputs = inputs5530AndHub;
        break;
      case AvlModel.PLOWOPS_HUB_C:
        inputs = inputsHubC;
        break;
      default:
        inputs = inputsDefault;
    }
    this.allInputs = inputs.map((color, index) => new NamedId(index + 1, color));
    this.allInputs.push(...[new NamedId(6, 'Virtual'), new NamedId(7, 'Virtual')]);
  }

  private setUsedInputs() {
    this.usedInputs = this.equipments.map(equipment => equipment.inputUp.id);
    this.usedInputs.push(
        ...this.equipments
            .filter(equipment => !!equipment.inputDown)
            .map(equipment => equipment.inputDown.id)
    );
  }

  canAddEquipment(): boolean {
    if (!!this.data.model.hardwareConfiguration.avlModel && this.data.model.hardwareConfiguration.avlModel === AvlModel.NONE) {
      return false;
    }
    const physicalInputsConfigured = this.equipments.filter(equipment => equipment.inputUp?.id < 6);
    return physicalInputsConfigured.length < 5;
  }

  addEquipment() {
    this.equipments.push({});
  }

  areEquipmentsValid(): boolean {
    if (!!this.equipments && this.equipments.length > 0) {
      // check required fields
      for (const equipment of this.equipments) {
        if (!equipment.type || !equipment.inputUp || (equipment.type === SensorType.PLOW2W && !equipment.inputDown)) {
          return false;
        }
      }
      // check duplicates
      const duplicates = this.equipments
          .slice()
          .map(item => [item.inputUp.id, item.inputDown?.id])
          .flat()
          .filter(item => !!item)
          .sort()
          .filter((e, i, a) => a[i - 1] === e);
      if (duplicates.length > 0) {
        console.log('Duplicate inputs found!');
        return false;
      }

      // check number of spreaders
      const liquidSpreaders = this.equipments.filter(equipment => {
        return equipment.type === SensorType.DIGITAL_LIQUID_SPREADER || equipment.type === SensorType.LIQUID_SPREADER;
      });
      if (liquidSpreaders.length > 5) {
        console.log('Too many liquid spreader sensors configured! ' + liquidSpreaders.length);
        return false;
      }
      const granularSpreaders = this.equipments.filter(equipment => {
        return equipment.type === SensorType.DIGITAL_GRANULAR_SPREADER || equipment.type === SensorType.GRANULAR_SPREADER;
      });
      if (granularSpreaders.length > 5) {
        console.log('Too many granular spreader sensors configured! ' + granularSpreaders.length);
        return false;
      }

      return true;
    } else {
      return true;
    }
  }

  isUniqueVehicleValue(key, value): boolean {
    const vehicleValues = this.vehicleValues(key);
    return vehicleValues.find(vehicleValue => vehicleValue === value) == null;
  }

  onOutOfServiceChange(event: MatCheckboxChange) {
    this.data.model.active = !event.checked;
  }

  onDigitalSpreaderCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.data.model.hardwareConfiguration.digitalSpreader = new DigitalSpreader();
    } else {
      this.data.model.hardwareConfiguration.digitalSpreader = null;
      this.equipments = this.equipments.filter(e =>
          e.type !== SensorType.DIGITAL_GRANULAR_SPREADER &&
          e.type !== SensorType.DIGITAL_LIQUID_SPREADER
      );
      this.onEquipmentTypeChanged();
    }
  }

  onDigitalSpreaderChange(event: DigitalSpreader) {
    const digitalSpreader = event;
    this.data.model.hardwareConfiguration.digitalSpreader = digitalSpreader;
    let equipment = this.equipments.find(e => e.type === SensorType.DIGITAL_GRANULAR_SPREADER);
    if (digitalSpreader.settings.readGranular && !equipment) {
      equipment = new Equipment();
      equipment.type = SensorType.DIGITAL_GRANULAR_SPREADER;
      equipment.inputUp = this.allInputs.find(input => input.id === 6);
      this.equipments.push(equipment);
    } else if (!digitalSpreader.settings.readGranular && equipment) {
      this.equipments.splice(this.equipments.indexOf(equipment), 1);
    }

    equipment = this.equipments.find(e => e.type === SensorType.DIGITAL_LIQUID_SPREADER);
    if (digitalSpreader.settings.readLiquid && !equipment) {
      equipment = new Equipment();
      equipment.type = SensorType.DIGITAL_LIQUID_SPREADER;
      equipment.inputUp = this.allInputs.find(input => input.id === 7);
      this.equipments.push(equipment);
    } else if (!digitalSpreader.settings.readLiquid && equipment) {
      this.equipments.splice(this.equipments.indexOf(equipment), 1);
    }
    this.onEquipmentTypeChanged();
  }

  isDigitalSpreaderValid(): boolean {
    const digitalSpreader = this.data.model.hardwareConfiguration.digitalSpreader;
    if (!!digitalSpreader) {
      return !!digitalSpreader.model &&
        !!digitalSpreader.settings &&
          (
            (digitalSpreader.settings.readGranular
              && !!digitalSpreader.settings.granularMaxRate && !!digitalSpreader.settings.granularBlastRate) ||
            (digitalSpreader.settings.readLiquid && !!digitalSpreader.settings.liquidMaxRate)
          );
    }
    return true;
  }

  cameraConfigurationInvalid() {
    return (!!this.data.model.cameraConfiguration?.localRecording &&
      (this.data.model.cameraConfiguration.localRecording.captureImageInterval > 30
        || this.data.model.cameraConfiguration.localRecording.captureImageInterval < 0)
    );
  }
}

@Component({
  selector: 'app-dialog-update-vehicle',
  templateUrl: 'dialog-edit-vehicle.html',
  styleUrls: ['./dialog-components.scss'],
})
export class DialogUpdateVehicleComponent extends DialogEditVehicleComponent {
  originalVehicleData: VehicleData;
  headline = 'Edit Vehicle';
  actionButtonLabel = 'Update';

  constructor(
    dialogRef: MatDialogRef<DialogUpdateVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
      model: VehicleData,
      configuration: ConfigurationModel,
      vehicles: VehicleData[],
      isImported: boolean,
      defaultMapColor: string }
  ) {
    super(dialogRef, data);
    this.originalVehicleData = { ...data.model };
    this.data.model.tablet = !this.data.model.hasNoTablet;
  }

  vehicleValues(key): string[] {
    return this.vehicles.map(v => v[key]).filter(v => this.originalVehicleData[key] !== v);
  }
}

@Component({
  selector: 'app-dialog-create-vehicle',
  templateUrl: 'dialog-edit-vehicle.html',
  styleUrls: ['./dialog-components.scss'],
})
export class DialogCreateVehicleComponent extends DialogEditVehicleComponent {
  headline = 'Add Vehicle';
  actionButtonLabel = 'Create';

  constructor(
    dialogRef: MatDialogRef<DialogCreateVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
      model: VehicleData,
      configuration: ConfigurationModel,
      vehicles: VehicleData[],
      isImported: boolean,
      defaultMapColor: string }
  ) {
      super(dialogRef, data);
      this.data.model.tablet = true;
      this.data.model.active = true;
  }

  vehicleValues(key): string[] {
    return this.vehicles.map(v => v[key]);
  }
}
