import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {DvirService} from '../../../../../data/dvir/dvir.service';
import {
  DropdownOption,
  InspectionQuestion,
  InspectionQuestionEditable,
  QuestionStage
} from '../../../../../shared/models/dvir';
import {MatDialog} from '@angular/material/dialog';
import {SimpleDialogComponent} from '../../../../../shared/components/dialogs/simple-dialog/simple-dialog.component';
import {
  AnswerMapperDialogData,
  DropdownProviderDialogData,
  DvirAnswerMapperComponent,
  DvirDropdownProviderComponent
} from './dvir-dialogs';
import {MatSelectChange} from '@angular/material/select';
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-manage-dvir-questions',
  templateUrl: './dvir-questions.component.html',
  styleUrls: ['./dvir-questions.component.scss'],
})
export class DvirQuestionsComponent implements OnInit {

  isLoading = true;
  uiError: string;
  editing = false;

  private allDisplayedColumns: string[] = [
    'order', 'text', 'stage', 'dataType', 'dropdownProvider', 'mappingProvider', 'actions'];
  private baseDisplayedColumns: string[] = [
    'order', 'text', 'stage', 'dataType', 'actions'];
  displayedColumns: string[] = this.baseDisplayedColumns;
  questions: MatTableDataSource<InspectionQuestionEditable> = new MatTableDataSource<InspectionQuestionEditable>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  itemCount = 0;
  pageSize = 50;
  pageIndex = 0;
  dropdownProviders: DropdownOption[] = [];
  dataTypes: DropdownOption[] = [];
  stages: QuestionStage[] = [];
  dropdowns: Map<string, DropdownOption[]> = new Map();
  answerProviders: DropdownOption[] = [];
  answerMappings: Map<string, DropdownOption[]> = new Map();

  private readonly MANUAL_CREATION = 'MANUAL';
  hasAnyDropdownProvider = false;
  hasAnyAnswerMapper = false;

  constructor(
    private dvirService: DvirService,
    public dialog: MatDialog
  ) {
    this.questions.paginator = this.paginator;
    this.questions.filterPredicate = (q, flt): boolean => {
      return q.deleted === false;
    };
    this.questions.filter = '___';
  }

  ngOnInit() {
    this.getData();
  }

  private getData() {
    this.isLoading = true;
    this.uiError = null;

    Promise.all([
      this.dvirService.getInspectionQuestions().toPromise(),
      this.dvirService.getDropdownProviders().toPromise(),
      this.dvirService.getQuestionDataTypes().toPromise(),
      this.dvirService.getAnswerMappingProviders().toPromise(),
      this.dvirService.getQuestionStages().toPromise(),
      this.getDropdowns(),
      this.getAnswerMappings()
    ]).then(responses => {
      const [questions, dropdownProviders, dataTypes, mappingProviders, stages] = responses;
      // providers
      this.dropdownProviders = dropdownProviders.data;
      // datatypes
      this.dataTypes = dataTypes.data;
      // question answer mappings
      this.answerProviders = mappingProviders.data;
      // question answer mappings
      this.stages = stages.data;
      // questions
      this.questionsLoaded(questions.data);
      // set some handling flags
      this.hasAnyDropdownProvider = this.dropdownProviders.length > 0;
      this.hasAnyAnswerMapper = this.answerProviders.length > 0;
      if (this.hasAnyDropdownProvider || this.hasAnyAnswerMapper) {
        this.displayedColumns = this.allDisplayedColumns;
      } else {
        this.displayedColumns = this.baseDisplayedColumns;
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private getDropdowns() {
    return new Promise((resolve, reject) => {
      this.dvirService.getDropdownProviders().toPromise().then(response => {
        const providers = response.data;
        const promises = providers.map(provider => {
          return new Promise((providerResolve, providerReject) => {
            this.dvirService.getDropdownLists(provider.id).toPromise().then(result => {
              this.dropdowns[provider.id] = result.data;
              providerResolve(`done ${provider}`);
            }).catch(error => {
              providerReject(error);
            });
          });
        });

        Promise.all(promises).then(result => {
          resolve('done ALL');
        }).catch(error => {
          reject(error);
        });
      });
    });
  }

  private getAnswerMappings() {
    return new Promise((resolve, reject) => {
      this.dvirService.getAnswerMappingProviders().toPromise().then(response => {
        const providers = response.data;
        const promises = providers.map(provider => {
          return new Promise((providerResolve, providerReject) => {
            this.dvirService.getAnswerMappingData(provider.id).toPromise().then(result => {
              this.answerMappings[provider.id] = result.data;
              providerResolve(`done ${provider}`);
            }).catch(error => {
              providerReject(error);
            });
          });
        });

        Promise.all(promises).then(result => {
          resolve('done ALL');
        }).catch(error => {
          reject(error);
        });
      });
    });
  }


  private getQuestions(page: number = 0) {
    this.isLoading = true;
    this.uiError = null;
    this.dvirService.getInspectionQuestions().toPromise().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.questionsLoaded(response.data);
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private questionsLoaded(questions: InspectionQuestion[]) {
    this.questions.data = questions?.map(q => {
      const editableQuestion = (q as InspectionQuestionEditable);
      editableQuestion.editable = editableQuestion.creationType === this.MANUAL_CREATION;
      return editableQuestion;
    });
    // sort questions by order property, it must be only by order so we can use drag&drop functionality
    this.sort();

    // note, we don;t use paging right now, so setting this to max length returned from server
    this.pageSize = questions.length;
    this.pageIndex = 0;
    this.itemCount = questions.length;

    this.questions.data.forEach(q => {
      q.dropdownProviderLabel = this.dropdownProviders?.find(p => p.id === q.dropdownProvider)?.label;
      q.dropdownDataLabel = this.dropdowns[q.dropdownProvider]?.find(p => p.id === q.dropdownData)?.label;
      q.dropdownDefaultValueLabel = this.dropdowns[q.dropdownProvider]
        ?.find(p => p.id === q.dropdownData)?.values?.find(v => v.id === q.defaultValue)?.label;
      q.answerMapperLabel = this.answerProviders?.find(p => p.id === q.answerMapper)?.label;
      q.answerDataLabel = this.answerMappings[q.answerMapper]?.find(p => p.id === q.answerMapperData)?.label;
      q.stageLabel = this.stages?.find(s => s.id === q.stage)?.label;
    });
  }

  onPage(pageEvent: PageEvent) {
    this.getQuestions(pageEvent.pageIndex);
  }

  /**
   * Sort always by order number.
   */
  private sort() {
    function compare(a: number | string, b: number | string, isAscending: boolean) {
      return (a < b ? -1 : 1) * (isAscending ? 1 : -1);
    }

    const data = this.questions.data.slice();
    this.questions.data = data.sort((a, b) => {
      return compare(a.order, b.order, true);
    });
  }

  previewDropdown(provider: string, data: string) {
    this.dialog.open(SimpleDialogComponent, {
      minHeight: '400px',
      minWidth: '600px',
      data: {
        dialogTitle: 'Value preview',
        dialogContent: this.dropdowns[provider]?.find(d => d.id === data)?.values.map(v => `<li><b>${v.label}</b> (${v.id})</li>`).join(''),
        closeButtonLabel: 'Close'
      }
    });
  }

  toggleEdit(shouldBeSaved: boolean = true) {
    if (this.editing === true && shouldBeSaved) {
      this.isLoading = true;
      // save/update on server
      this.dvirService.updateInspectionQuestions(this.questions.data).toPromise().then(response => {
        this.questionsLoaded(response.data);
      }).catch(error => {
        this.uiError = error;
      }).finally(() => {
          this.isLoading = false;
        }
      );
    } else if (!shouldBeSaved) {
      this.getQuestions();
    }
    this.editing = !this.editing;
  }

  addQuestion() {
    const newQuestion = new InspectionQuestionEditable();
    newQuestion.editable = true;
    newQuestion.dataType = 'TEXT';
    newQuestion.creationType = 'MANUAL';
    newQuestion.stage = 'PRE_INSPECT_AND_POST_INSPECT';
    newQuestion.stageLabel = 'Pre+Post Inspect';
    if (this.questions.data && this.questions.data.length > 0) {
      newQuestion.order = Math.max(...this.questions.data.map(q => q.order)) + 1;
    } else {
      newQuestion.order = 1;
    }
    this.questions.data = [...this.questions.data, newQuestion];
  }

  deleteQuestion(element: InspectionQuestionEditable) {
    if (element.id === undefined) {
      const index = this.questions.data.findIndex(q => q.text === element.text);
      this.questions.data.splice(index, 1);
    }
    const question = this.questions.data.find(q => q.id && element.id && q.id === element.id);
    if (question) {
      question.deleted = true;
    }
    this.questions.data = [...this.questions.data];
  }

  selectDropdownProvider(question: InspectionQuestionEditable) {
    const dialog = this.dialog.open(DvirDropdownProviderComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        question, // work with copy, on no change don't change it
        editing: this.editing,
        dropdownProviders: this.dropdownProviders,
        dropdowns: this.dropdowns,
      } as DropdownProviderDialogData
    });
    dialog.afterClosed().subscribe(updatedQuestion => {
      if (updatedQuestion) {
        question.dropdownProvider = updatedQuestion.dropdownProvider;
        question.dropdownData = updatedQuestion.dropdownData;
        question.defaultValue = updatedQuestion.defaultValue;
        question.dropdownProviderLabel = updatedQuestion.dropdownProviderLabel;
        question.dropdownDataLabel = updatedQuestion.dropdownDataLabel;
        question.dropdownDefaultValueLabel = updatedQuestion.dropdownDefaultValueLabel;
      }
    });
  }

  selectQuestionMapper(question: InspectionQuestionEditable) {
    const dialog = this.dialog.open(DvirAnswerMapperComponent, {
      height: 'auto',
      width: 'auto',
      data: {
        question, // work with copy, on no change don't change it
        editing: this.editing,
        answerProviders: this.answerProviders,
        answerMappings: this.answerMappings
      } as AnswerMapperDialogData
    });
    dialog.afterClosed().subscribe(updatedQuestion => {
      if (updatedQuestion) {
        console.log('updatedQuestion', updatedQuestion);
        question.answerMapper = updatedQuestion.answerMapper;
        question.answerMapperLabel = updatedQuestion.answerMapperLabel;
        question.answerMapperData = updatedQuestion.answerMapperData;
        question.answerDataLabel = updatedQuestion.answerDataLabel;
      }
    });
  }

  dataTypeChanged(question: InspectionQuestionEditable, matSelectChange: MatSelectChange) {
    if (matSelectChange.value !== 'DROPDOWN' && matSelectChange.value !== 'MULTIDROPDOWN') {
      // clear dropdown config
      question.dropdownProvider = undefined;
      question.dropdownData = undefined;
      question.dropdownDataLabel = undefined;
      question.defaultValue = undefined;
      question.dropdownDefaultValueLabel = undefined;
      // clear mapping
      question.answerMapper = undefined;
      question.answerMapperLabel = undefined;
      question.answerMapperData = undefined;
      question.answerDataLabel = undefined;
    }
  }

  drop($event: CdkDragDrop<InspectionQuestionEditable, any>) {
    const data = this.questions.data.slice();
    moveItemInArray(data, $event.previousIndex, $event.currentIndex);
    // change order of temp array
    data
      .forEach((question, index) => {
        question.order = index + 1;
      });
    // assign changed data to the question list
    this.questions.data = data;
  }
}
