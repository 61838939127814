import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {Dropdown, DropdownOption, InspectionQuestion, QuestionMapping, QuestionStage} from '../../shared/models/dvir';

@Injectable({
  providedIn: 'root'
})
export class DvirService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getInspectionQuestions(page: number = 0, size: number = 25, sort?: string) {
    const params = {
      page: String(page),
      size: String(size),
      sort
    };

    return this.http.get<JsonApiResponse<InspectionQuestion[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/editable-list`, {params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  updateInspectionQuestions(questions: InspectionQuestion[]) {
    return this.http.post<JsonApiResponse<InspectionQuestion[]>>(
      `${environment.services.service}v1/vehicle-inspection/question`, questions
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getQuestionDataTypes() {
    return this.http.get<JsonApiResponse<DropdownOption[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/data-types`, {}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getQuestionStages() {
    return this.http.get<JsonApiResponse<QuestionStage[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/stage/list`, {}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getDropdownProviders() {
    return this.http.get<JsonApiResponse<DropdownOption[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/dropdown/provider-list`, {}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getDropdownLists(provider: string) {
    return this.http.get<JsonApiResponse<Dropdown[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/dropdown/${provider}/list`, {}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getAnswerMappingProviders() {
    return this.http.get<JsonApiResponse<DropdownOption[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/mapping/list`, {}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getAnswerMappingData(provider: string) {
    return this.http.get<JsonApiResponse<QuestionMapping[]>>(
      `${environment.services.service}v1/vehicle-inspection/question/mapping/${provider}/list`, {}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

}
