import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { JsonApiResponse } from '../../shared/models/JsonApiResponse';
import { environment } from '../../../environments/environment';
import { MessageRecipientCollectionModel } from '../../pages/live-map/models/message-recipient-collection.model';
import { SendMessageModel } from '../../pages/live-map/models/send-message.model';
import { MessageModel } from '../../pages/live-map/models/message.model';
import { Page } from '../../shared/models/Page';
import {HttpErrorHandler} from '../../http.error.handler';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private http: HttpClient) {
  }

  getRecipients(): Promise<JsonApiResponse<MessageRecipientCollectionModel>> {
    const endpointUrl = `${environment.services.service}v1/messaging/recipients`;
    return this.http.get<JsonApiResponse<MessageRecipientCollectionModel>>(endpointUrl, {})
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  getRecentMessages(): Promise<JsonApiResponse<MessageModel[]>> {
    const endpointUrl = `${environment.services.service}v1/messaging/recentMessages`;
    return this.http.get<JsonApiResponse<MessageModel[]>>(endpointUrl, {})
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  getArchivedMessages(page: number = 0, size: number = 20): Promise<JsonApiResponse<Page<MessageModel>>> {
    const params = {
      page: String(page),
      size: String(size),
    };

    const endpointUrl = `${environment.services.service}v1/messaging/archivedMessages`;
    return this.http.get<JsonApiResponse<Page<MessageModel>>>(endpointUrl, { params })
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  sendMessage(sendMessageDto: SendMessageModel): Promise<JsonApiResponse<MessageModel>> {
    const endpointUrl = `${environment.services.service}v1/messaging/send`;
    return this.http.post<JsonApiResponse<MessageModel>>(endpointUrl, sendMessageDto)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      )
      .toPromise();
  }

  deleteMessage(messageId: number) {
    const requestUrl = `${environment.services.service}v1/messaging/${messageId}/delete`;

    return this.http.delete<void>(requestUrl)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }
}
