import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {SessionStatistics, ShiftDistance, ShiftModel, ShiftWithDriverAndVehicleModel} from '../../shared/models/shift.model';
import {TrackBoundingBox} from '../../shared/models/TrackBoundingBox';
import {Page} from '../../shared/models/Page';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {FeatureCollection} from 'geojson';
import {GeobufResponseHandler} from '../../geobuf.response.handler';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {

  constructor(private http: HttpClient) { }

  getActiveShifts()  {
    return this.http.get<JsonApiResponse<ShiftModel[]>>(`${environment.services.service}v1/shift/active/list`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getShiftInfo(shiftId: number) {
    return this.http.get<JsonApiResponse<ShiftWithDriverAndVehicleModel>>(`${environment.services.service}v1/shift/${shiftId}/info`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  forceShiftEnd(shiftId: number) {
    return this.http.post<JsonApiResponse<ShiftModel>>(`${environment.services.service}v1/shift/${shiftId}/end`, null)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  deleteShift(shiftId: number) {
    return this.http.delete(`${environment.services.service}v1/shift/${shiftId}`)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        ).toPromise();
  }

  getCompleteShifts(from?: Date, to?: Date) {
    let params = new HttpParams();
    if (!!from) {
      params = params.set('from', from.toISOString());
    }
    if (!!to) {
      params = params.set('to', to.toISOString());
    }
    return this.http.get<JsonApiResponse<ShiftWithDriverAndVehicleModel[]>>(
        `${environment.services.service}v1/shift/complete/list`, {params}
    )
        .pipe(
            retry(3), // retry a failed request up to 3 times
            catchError(HttpErrorHandler.handleError) // then handle the error
        ).toPromise();
  }

  getCompleteShiftsByVehicleAndDriver(vehicleId: number,
                                      driverId: number,
                                      from: Date,
                                      to: Date,
                                      page: number = 0, size: number = 10, sort?: string) {
    const params = {
      page: String(page),
      size: String(size),
    };
    if (sort) {
      params['sort'] = sort;
    }
    if (vehicleId) {
      params['vehicleId'] = String(vehicleId);
    }
    if (driverId) {
      params['driverId'] = String(driverId);
    }
    if (from ) {
      params['from'] = from.toISOString();
    }
    if (to) {
      params['to'] = to.toISOString();
    }
    return this.http.get<JsonApiResponse<Page<ShiftWithDriverAndVehicleModel>>>(
        `${environment.services.service}v1/shift/complete/page`, {params}
    )
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getShiftTrackThumbnail(shiftId: number, width: number, height: number) {
    let params = new HttpParams();
    params = params.set('width', String(width));
    params = params.set('height', String(height));
    return this.http.get(`${environment.services.service}v1/shift/${shiftId}/track-thumbnail`, {params, responseType: 'blob'})
      .pipe(
        retry(0),
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getShiftTrackBoundingBox(shiftId: number) {
    return this.http.get<JsonApiResponse<TrackBoundingBox>>(`${environment.services.service}v1/shift/${shiftId}/track-bounding-box`)
      .pipe(
          retry(0),
          catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getShiftDistanceDriven(shiftId: number) {
    return this.http.get<JsonApiResponse<ShiftDistance>>(`${environment.services.service}v1/shift/${shiftId}/distance-driven`)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        );
  }

  getShiftStatistics(shiftId: number) {
    return this.http.get<JsonApiResponse<SessionStatistics[]>>(`${environment.services.location}v1/session/statistic?sessionKey=${shiftId}`)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        );
  }

  getShiftPoints(shiftId: number, vehicleId: number): Promise<FeatureCollection> {
    return this.http.get(
        `${environment.services.location}v1/track/vertex?sessionKey=${shiftId}&locationSourceId=${vehicleId}&anchorTime=0`,
        {
          observe: 'response',
          responseType: 'arraybuffer',
          headers: {Accept: 'application/octet-stream'}
        }
    )
        .pipe(
            map( response => GeobufResponseHandler.handleResponse(response)),
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  getShiftTrack(shiftId: number, vehicleId: number): Promise<FeatureCollection> {
    return this.http.get(
        `${environment.services.location}v1/track/segment?sessionKey=${shiftId}&locationSourceId=${vehicleId}`,
        {observe: 'response', responseType: 'arraybuffer', headers: {Accept: 'application/octet-stream'}}
    )
        .pipe(
            map( response => GeobufResponseHandler.handleResponse(response)),
            catchError(HttpErrorHandler.handleError) // then handle the error
        ).toPromise();
  }
}
