import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DriverImport} from '../../../../../shared/models/driver.model';
import {DriversService} from '../../../../../data/drivers/drivers.service';


@Component({
    selector: 'app-drivers-import',
    templateUrl: './manage-drivers-import.component.html',
    styleUrls: ['./manage-drivers-import.component.scss'],
})
export class ImportDriversComponent {

    @Output() changePageEvent = new EventEmitter<string>();

    uiError: string;

    selectedFile: File;
    validData: DriverImport[];
    invalidData: DriverImport[];
    displayedColumnsValid: string[];
    displayedColumnsInvalid: string[];
    allColumns: {
        title: string;
        valid: boolean;
        invalid: boolean;
    }[] = [
        {title: 'selected', valid: true, invalid: false},
        {title: 'line', valid: true, invalid: true},
        {title: 'driverName', valid: true, invalid: true},
        {title: 'login', valid: false, invalid: true},
        {title: 'errors', valid: false, invalid: true},
    ];
    states = ImportDriversStateEnum; // allow access from template via variable
    currentState = ImportDriversStateEnum.NOOP;

    @ViewChild('fileInput')
    fileInput: ElementRef;

    constructor(public dialog: MatDialog,
                private driversService: DriversService) {

        this.updateColumns();
    }

    handleFileInput(event: Event) {
        this.selectedFile = (event.target['files'] as FileList)[0];
    }

    upload() {
        this.currentState = ImportDriversStateEnum.WORKING;
        this.driversService.uploadAndValidateCsvDrivers(this.selectedFile)
            .then((o) => {
                this.validData = o.data.filter(row => row.action !== 'NONE' && (row.errors == null || row.errors.length === 0));
                this.invalidData = o.data.filter(row => row.action === 'NONE' || row.errors != null && row.errors.length > 0);
                this.currentState = ImportDriversStateEnum.NOOP;
                // make the valid rows all selected
                this.validData.forEach(row => row.selected = true);
            }).catch(error => {
            this.uiError = error;
        }).finally(() => {
            this.currentState = ImportDriversStateEnum.NOOP;
        });
    }

    changePage(page?: string) {
        this.changePageEvent.emit(page);
    }

    cancelImport() {
        this.doCancel();
        this.changePage();
    }

    doImport() {
        this.currentState = ImportDriversStateEnum.WORKING;
        const selectedData = this.validData.filter(row => row.selected === true);
        this.driversService.uploadAndImportCsvDrivers(selectedData)
            .then((response) => {
                this.currentState = ImportDriversStateEnum.IMPORTED;
            }).catch(error => {
            this.uiError = error;
            this.currentState = ImportDriversStateEnum.NOOP;
        });
    }

    doCancel() {
        this.currentState = ImportDriversStateEnum.WORKING;
        this.validData = [];
        this.invalidData = [];
        this.fileInput.nativeElement.value = '';
        this.selectedFile = undefined;
        this.currentState = ImportDriversStateEnum.NOOP;
    }

    importDisabled(): boolean {
        return this.validData.filter(row => row.selected === true).length === 0;
    }

    private updateColumns() {
        this.displayedColumnsValid = this.allColumns
            .filter(col => col.valid === true)
            .map(col => col.title);

        this.displayedColumnsInvalid = this.allColumns
            .filter(col => col.invalid === true)
            .map(col => col.title);
    }

}

enum ImportDriversStateEnum {
    NOOP,
    WORKING,
    IMPORTED
}
