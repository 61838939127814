
<div class="setup-page-container">

    <div class="header-container">
        <div>Route configuration</div>
        <div class="header-container-buttons">
            <button mat-button
                    *ngIf="wizard && !loadingFields && !loadingFieldItems && routeConfiguration && routeConfiguration.children.length > 0"
                    color="accent"
                    [disabled]="wizard && !loadingFields && !loadingFieldItems && isUpdating"
                    (click)="updateRouteConfig()">
                <mat-icon *ngIf="isUpdating"><mat-spinner diameter="17"></mat-spinner></mat-icon>
                <mat-icon *ngIf="!isUpdating">save</mat-icon>
                Save
            </button>
            <button mat-button
                    color="accent"
                    (click)="cancelWizard()">
                <mat-icon>cancel</mat-icon>Cancel
            </button>
        </div>
    </div>

    <div class="content-container">
        <div class="ops-form form-container" *ngIf="wizard">

            <div class="detail-section">

                <h2>Details</h2>

                <mat-form-field appearance="outline" color="accent" class="wide no-hint">
                    <mat-label>Name</mat-label>
                    <input matInput type=text [(ngModel)]="configurationName">
                </mat-form-field>

                <div class="field-with-right-buttons wide">
                    <mat-form-field appearance="outline" color="accent" class="no-hint">
                        <mat-label>Feature Service URL</mat-label>
                        <input matInput type=text [(ngModel)]="featureServiceUrl">
                    </mat-form-field>
                    <div>
                        <button mat-icon-button
                                color="accent"
                                [matTooltip]="'Pull data schema from the feature service'"
                                (click)="getFeatureServiceDescription()"
                        >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>

                <div class="">
                    <mat-checkbox [(ngModel)]="useCredentials" (change)="onCredentialsToggle()">Use credentials for the server</mat-checkbox>
                    <div class="credentials-wrapper" *ngIf="useCredentials">
                        <mat-form-field appearance="outline" color="accent" class="credentials-input">
                            <mat-label>Username</mat-label>
                            <input matInput [(ngModel)]="username" autocomplete="off"/>
                        </mat-form-field>
                        <mat-form-field  appearance="outline" color="accent" class="credentials-input">
                            <mat-label>Password</mat-label>
                            <input matInput [(ngModel)]="password" autocomplete="off" type="password"/>
                        </mat-form-field>
                        <button mat-icon-button
                                color="accent"
                                [matTooltip]="'Test connection'"
                                (click)="testFeatureServiceCredentials()"
                                style="margin-bottom: 20px"
                        >
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                </div>

                <mat-spinner diameter="30" *ngIf="loadingSchema"></mat-spinner>

                <mat-form-field *ngIf="!loadingSchema" appearance="outline" color="accent" class="wide no-hint">
                    <mat-label>Select Route Layers</mat-label>
                    <mat-select [(ngModel)]="layerId" (ngModelChange)="getFeatureServiceLayerDescription()" [disabled]="!layers">
                        <ng-container *ngFor="let layer of layers">
                            <mat-option [value]="layer.id">{{ layer.name }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-spinner diameter="30" *ngIf="loadingFields"></mat-spinner>

                <div class="field-with-right-buttons wide" *ngIf="!loadingFields">
                    <mat-form-field appearance="outline" color="accent" class="no-hint">
                        <mat-label>Select Route Name Field</mat-label>
                        <mat-select [(ngModel)]="routeField" (ngModelChange)="getFeatureServiceLayerDescription()" [disabled]="!fields">
                            <ng-container *ngFor="let field of fields">
                                <mat-option [value]="field.name">{{ field.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <button mat-icon-button
                                color="accent"
                                (click)="getFeatureServiceLayerFieldItems()"
                                [disabled]="!categoriesFilled() || !routeField"
                                [matTooltip]="'Generate routes'"
                        >
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <mat-spinner diameter="30" *ngIf="wizard && !loadingFields && loadingFieldItems"></mat-spinner>

            <div class="appearance-section" *ngIf="wizard && !loadingFields && !loadingFieldItems && routeConfiguration && routeConfiguration.children.length > 0">

                <mat-checkbox
                        [(ngModel)]="exportToCustomMapLayers"
                >
                    Add to Live Map as "{{ configurationName }}"
                </mat-checkbox>

                <h2>Appearance</h2>

                <div class="style-section">
                    <div class="section-title">Global Style Settings</div>
                    <div class="style-editor">
                        <app-route-style-editor
                                [routeStyle]="routeStyle"
                                (routeStyleChanged)="routeStyleEdited($event)"
                        >
                        </app-route-style-editor>
                    </div>
                </div>

                <div class="groups-section">
                    <div class="section-header">
                        <div class="section-title">Grouping</div>
                        <div class="section-buttons">
                            <button mat-button
                                    color="accent"
                                    *ngIf="categories.length < 4"
                                    (click)="addCategory()">
                                <mat-icon>add</mat-icon>Add group
                            </button>
                            <button mat-button
                                    color="accent"
                                    (click)="getFeatureServiceLayerFieldItems()"
                                    [disabled]="!categoriesFilled() || !routeField">
                                <mat-icon>update</mat-icon> Update routes
                            </button>
                        </div>
                    </div>
                    <div class="field-with-right-buttons" *ngFor="let category of categories; let i = index">
                        <mat-form-field appearance="outline" color="accent" class="no-hint">
                            <mat-label>Category {{ i+1 }}</mat-label>
                            <mat-select [(ngModel)]="categories[i]">
                                <ng-container *ngFor="let field of fields">
                                    <mat-option [value]="field.name">{{ field.name }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <button mat-icon-button
                                    color="accent"
                                    *ngIf="categories.length > 0"
                                    (click)="removeCategory()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="routes-section">
                    <div class="section-title">Routes List</div>
                    <div class="routes-tree">
                        <app-route-tree
                                [routeConfiguration]="routeConfiguration"
                                [attributes]="attributes"
                                [editing]="true"
                                (routeFiltered)="filterRoutes($event)"
                                (styleChanged)="colorsOverrideChanged($event)"
                        ></app-route-tree>
                    </div>
                </div>

            </div>
        </div>

        <div class="map-preview-container">
            <ng-container *ngIf="wizard && !loadingFields && !loadingFieldItems && routeConfiguration && routeConfiguration.children.length > 0 && mapContent !== null">
                <app-map-preview
                        [nextContent]="mapContent"
                ></app-map-preview>
            </ng-container>
        </div>

    </div>
</div>
