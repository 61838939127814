<ng-container [ngSwitch]="page" *ngIf="configuration">
    <app-import-vehicles *ngSwitchCase="'import'"
                         (changePageEvent)="pageChanged($event)"
    ></app-import-vehicles>
    <app-import-vehicles-cartegraph *ngSwitchCase="'import-cartegraph'"
                                    (changePageEvent)="pageChanged($event)"
    ></app-import-vehicles-cartegraph>
    <app-manage-vehicles *ngSwitchDefault
                         (changePageEvent)="pageChanged($event)"
                         [configuration]="configuration"
    ></app-manage-vehicles>
</ng-container>