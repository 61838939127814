import {VehicleModel} from '../../../../../shared/models/vehicle.model';
import {NamedId} from '../../../../../shared/models/NamedId';
import {VehicleCategoryModel} from '../../../../../shared/models/vehicle';

export class VehicleGroupModelWithVehicles {
    id: number;
    name: string;
    title: string;
    useDvir: boolean;
    allowRouteSelfAssign: boolean;
    preTripForm: NamedId;
    postTripForm: NamedId;
    showVehicles: boolean;
    showVehicleHistory: boolean;
    showVehicleObservations: boolean;
    maxStationaryTime?: number;
    vehicles: VehicleModel[];
    observationTypeGroups: NamedId[];
    allowedRouteConfigs: NamedId[];

    static fromDto(dto: VehicleCategoryModel): VehicleGroupModelWithVehicles {
        const model = new VehicleGroupModelWithVehicles();
        model.id = dto.id;
        model.name = dto.name;
        model.title = dto.title;
        model.useDvir = dto.useDvir;
        model.allowRouteSelfAssign = dto.allowRouteSelfAssign;
        model.preTripForm = dto.preTripForm;
        model.postTripForm = dto.postTripForm;
        model.showVehicles = dto.showVehicles;
        model.showVehicleHistory = dto.showVehicleHistory;
        model.showVehicleObservations = dto.showVehicleObservations;
        model.maxStationaryTime = dto.maxStationaryTime;
        model.observationTypeGroups = dto.observationTypeGroups;
        model.allowedRouteConfigs = dto.allowedRouteConfigs;
        model.vehicles = [];
        return model;
    }

    static fromDtoAndAllVehicles(dto: VehicleCategoryModel, allVehicles: VehicleModel[]): VehicleGroupModelWithVehicles {
        const model = this.fromDto(dto);
        for (const vehicle of allVehicles) {
            if (vehicle.groupId === model.id) {
                model.vehicles.push(vehicle);
            }
        }
        return model;
    }

    public static vehicleGroupCompareFn = (a, b) => a.name.localeCompare(b.name);

    updateFromDto(dto: VehicleCategoryModel) {
        this.name = dto.name;
        this.title = dto.title;
        this.useDvir = dto.useDvir;
        this.allowRouteSelfAssign = dto.allowRouteSelfAssign;
        this.preTripForm = dto.preTripForm;
        this.postTripForm = dto.postTripForm;
        this.showVehicles = dto.showVehicles;
        this.showVehicleHistory = dto.showVehicleHistory;
        this.showVehicleObservations = dto.showVehicleObservations;
        this.maxStationaryTime = dto.maxStationaryTime;
        this.observationTypeGroups = dto.observationTypeGroups;
        this.allowedRouteConfigs = dto.allowedRouteConfigs;
    }
}
