import { Component } from '@angular/core';
import {IncidentInfo} from '../services/traffic-layer.service';

@Component({
  selector: 'app-incident-info-window-content',
  templateUrl: './incident-info-window-content.component.html',
  styleUrls: ['./incident-info-window-content.component.scss']
})
export class IncidentInfoWindowContentComponent {

  incidentInfo: IncidentInfo;
}
