<ng-container *ngIf="!!shift && !!items">
    <div class="observation-lines" *ngIf="!!items && items.length > 0">
        <ng-container *ngFor="let item of items">
            <div [id]="item.id">
                <app-action-menu-item [input]="item"></app-action-menu-item>
            </div>
        </ng-container>
    </div>

    <div *ngIf="!!shift && !!items && items.length === 0">
        <span>Driver {{ shift.driver.name }} has not created any observations on this shift.</span>
    </div>
</ng-container>
