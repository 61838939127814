import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  BaseMapType,
  ISettingKeyValuePair,
  SettingsService,
  TracksLayerFilter,
  TracksLayerType,
  WeatherLayerType,
} from 'src/app/configuration/settings.service';
import {
  filterSelectorFadeDown,
} from 'src/app/shared/animations/animations';
import {ConfigurationModel, MapLayer, FeatureFlagEnum} from 'src/app/shared/models/configuration.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {MapLayersManager} from '../map-viewer/map-layers-manager';
import {environment} from '../../../../environments/environment';
import {MapControlService} from '../map-viewer/services/map-control.service';
import {TrackStylesService} from '../../../configuration/track-styles.service';
import {LayerTypeFilter} from '../../../configuration/model/LayerTypeFilter';
import {SecurityService} from '../../../security/security.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LiveMapTab} from '../../../pages/live-map/models/live-map-tabs';

enum ConfigurationType {
  CUSTOM_LAYERS,
  ACTIVITIES,
  LOCATION_HISTORY,
}

export interface ActivityFilter {
  key: string;
  label: string;
  type: TracksLayerFilter;
  icon: string;
  iconMirrored: boolean;
  visible: boolean;
}

interface TracksLayer {
  type: TracksLayerType;
  label: string;
}

@Component({
  selector: 'app-map-layer-switcher',
  templateUrl: './map-layer-switcher.component.html',
  styleUrls: ['./map-layer-switcher.component.scss'],
  animations: [filterSelectorFadeDown],
})
export class MapLayerSwitcherComponent implements OnInit, OnDestroy {
  @Input() mapLayersManager: MapLayersManager;

  isAdmin: boolean;
  filterOpen = false;
  @Input() showLocationHistory = true;
  showConfiguration = -1;
  isAssetDetailRoute = false;
  isRoutesRoute = false;
  isRouteStatusMode = false;

  showRadar = false;
  showWarnings = false;
  showTraffic = false;
  baseMapLayerType: string;
  tracksLayerType: string;
  tracksFilter: string;

  configuration: ConfigurationModel;
  customMapLayers: MapLayer[];
  tracksLayers: TracksLayer[];

  activityFilters: ActivityFilter[] = [
    {
      key: SettingsService.SHOW_ACTIVITY_PLOWING,
      label: 'Plowing',
      type: TracksLayerFilter.PLOWING,
      icon: 'signal_cellular_1_bar',
      iconMirrored: true,
      visible: true,
    },
    {
      key: SettingsService.SHOW_ACTIVITY_LIQUID,
      label: 'Liquid',
      type: TracksLayerFilter.LIQUID_SPREADING,
      icon: 'water_drop',
      iconMirrored: false,
      visible: true,
    },
    {
      key: SettingsService.SHOW_ACTIVITY_GRANULAR,
      label: 'Granular',
      type: TracksLayerFilter.GRANULAR_SPREADING,
      icon: 'grain',
      iconMirrored: false,
      visible: false,
    },
    {
      key: SettingsService.SHOW_ACTIVITY_MOWING,
      label: 'Mowing',
      type: TracksLayerFilter.MOWING,
      icon: 'grass',
      iconMirrored: false,
      visible: false,
    },
    {
      key: SettingsService.SHOW_ACTIVITY_SWEEPING,
      label: 'Sweeping',
      type: TracksLayerFilter.SWEEPING,
      icon: 'cleaning_services',
      iconMirrored: false,
      visible: false,
    },
  ];
  activeTracksFilter: ActivityFilter;

  baseMaps: BaseMapType[] = [
    BaseMapType.LIGHT,
    BaseMapType.DARK,
    BaseMapType.IMAGERY,
    BaseMapType.OUTDOORS,
  ];

  coverageLayerTypeFilter: LayerTypeFilter;
  currencyLayerTypeFilter: LayerTypeFilter;

  // this is a hack to access enums from templates
  BaseMapType = BaseMapType;
  FeatureFlagEnum = FeatureFlagEnum;
  WeatherLayerType = WeatherLayerType;
  ConfigurationType = ConfigurationType;
  TracksLayerType = TracksLayerType;
  TracksLayerFilter = TracksLayerFilter;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
    private settingsService: SettingsService,
    private configurationService: ConfigurationService,
    private trackStylesService: TrackStylesService,
    private mapControlService: MapControlService,
    private securityService: SecurityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();
    this.isAssetDetailRoute = this.activatedRoute.firstChild?.snapshot?.routeConfig?.path?.startsWith(LiveMapTab.ASSETS + '/:id');
    this.isRoutesRoute = this.activatedRoute.firstChild?.snapshot?.routeConfig?.path === LiveMapTab.ROUTES;
    const routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isAssetDetailRoute = this.activatedRoute.firstChild?.snapshot?.routeConfig?.path?.startsWith(LiveMapTab.ASSETS + '/:id');
        this.isRoutesRoute = this.activatedRoute.firstChild?.snapshot?.routeConfig?.path === LiveMapTab.ROUTES;
      }
    });
    this.openSubscriptions.push(routerSubscription);

    const configurationSubscription =
      this.configurationService.sharedConfigurationModel.subscribe(
        (configuration) => {
          this.configuration = configuration;
          this.customMapLayers = configuration.additionalLayers;
          this.customMapLayers.forEach(layer => {
            layer.checked = !!this.settingsService.getBooleanValue(layer.name);
          });
        }
      );
    this.openSubscriptions.push(configurationSubscription);

    const settingsSubscription =
      this.settingsService.settingsChangedObservable.subscribe(
        (keyValuePair: ISettingKeyValuePair) => {
          this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
        }
      );
    this.openSubscriptions.push(settingsSubscription);

    this.mapControlService.mapLayerSwitcher$.subscribe((state) => {
      this.filterOpen = state;
    });

    this.activityFilters.forEach(filter => {
      filter.visible = this.settingsService.getBooleanValue(filter.key);
    });

    this.tracksLayers = [
      {
        type: TracksLayerType.GPS_TRACKS,
        label: 'GPS',
      }
    ];
    if (this.hasFeatureFlag(FeatureFlagEnum.RoadStatus)) {
      this.tracksLayers.push(...[
        {
          type: TracksLayerType.CURRENCY,
          label: 'Currency',
        },
        {
          type: TracksLayerType.COVERAGE,
          label: 'Coverage',
        },
      ]);
    }

    const stylesSubscription = this.configurationService.trackStylesChangedSubject.subscribe(trackStyles => {
      this.coverageLayerTypeFilter = LayerTypeFilter.coverageLayerTypeFilter(trackStyles);
      this.currencyLayerTypeFilter = LayerTypeFilter.currencyLayerTypeFilter(trackStyles, 1);
    });
    this.openSubscriptions.push(stylesSubscription);
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
        this.configuration.featureFlags.find(
            (value) => value.isEnabled && value.name === featureFlag
        ) !== undefined
    );
  }

  toggleVisibility() {
    this.mapControlService.toggleMapLayerSwitcher(!this.filterOpen);
  }

  switchBaseMap(baseMapType: BaseMapType) {
    this.settingsService.setStringValue(
      SettingsService.BASE_MAP_LAYER_KEY,
      baseMapType
    );
  }

  toggleRadar() {
    this.toggleCustomLayer(SettingsService.WEATHER_RADAR_LAYER_KEY, !this.showRadar);
    if (!this.showRadar) {
      this.toggleCustomLayer(SettingsService.WEATHER_WARNINGS_LAYER_KEY, false);
    }
  }

  toggleTrafficLayer() {
    this.toggleCustomLayer(SettingsService.TRAFFIC_LAYER_KEY, !this.showTraffic);
  }

  toggleCustomLayer(key: string, checked: boolean) {
    this.settingsService.setBooleanValue(key, checked);
  }

  private onSettingsChanged(key: string, value: any) {
    switch (key) {
      case SettingsService.BASE_MAP_LAYER_KEY:
        this.baseMapLayerType = value;
        break;
      case SettingsService.TRACKS_LAYER_TYPE_KEY:
        this.tracksLayerType = value;
        break;
      case SettingsService.PLOW_TRACK_LAYER_FILTER:
        this.tracksFilter = value;
        this.activeTracksFilter = this.activityFilters.find(filter => filter.type === value);
        break;
      case SettingsService.WEATHER_RADAR_LAYER_KEY:
        this.showRadar = value;
        break;
      case SettingsService.WEATHER_WARNINGS_LAYER_KEY:
        this.showWarnings = value;
        break;
      case SettingsService.TRAFFIC_LAYER_KEY:
        this.showTraffic = value;
        break;
      case SettingsService.SHOW_ACTIVITY_PLOWING:
      case SettingsService.SHOW_ACTIVITY_LIQUID:
        this.updateActivityFilter(key, value);
        break;
      case SettingsService.ROUTE_STATUS_MODE:
        this.isRouteStatusMode = value;
        break;
      default: // other layers in configuration
        const mapLayer = this.customMapLayers.find((it) => it.name === key);
        if (mapLayer !== undefined) {
          mapLayer.checked = value as boolean;
        }
        break;
    }
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  private updateActivityFilter(key: string, value: boolean) {
    const item = this.activityFilters.find(filter => filter.key === key);
    if (!!item) {
      item.visible = value;
    }
  }

  baseMapTypeBackground(baseMapType: BaseMapType) {
    // if class defined in SCSS then there is a problem with base_href on AWS env
    // this is a workaround where JS knows about base_href
    const basePath = environment.base_href;
    switch (baseMapType) {
      case BaseMapType.OUTDOORS:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/outdoor_thumb.png\')`,
        };
      case BaseMapType.LIGHT:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/lightgrey_thumb.png\')`,
        };
      case BaseMapType.DARK:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/darkgrey_thumb.png\')`,
        };
      case BaseMapType.IMAGERY:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/satellite_thumb.png\')`,
        };
      default: {
        return {};
      }
    }
  }

  weatherLayerToggleBackground(weatherLayerName: string) {
    const basePath = environment.base_href;
    switch (weatherLayerName) {
      case WeatherLayerType.RADAR:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/radar_thumb.png\')`,
        };
      case WeatherLayerType.WARNINGS:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/warnings_thumb.png\')`,
        };
      case WeatherLayerType.TRAFFIC:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/traffic_thumb.png\')`,
        };
      default:
        return {};
    }
  }

  locationLayerButtonBackground(layerType: TracksLayerType) {
    const basePath = environment.base_href;
    switch (layerType) {
      case TracksLayerType.GPS_TRACKS:
        return {
          'background-image': `url(\'${basePath}assets/tracks_thumbnails/gps.png\')`,
        };
      case TracksLayerType.COVERAGE:
        return {
          'background-image': `url(\'${basePath}assets/tracks_thumbnails/coverage.png\')`,
        };
      case TracksLayerType.CURRENCY:
        return {
          'background-image': `url(\'${basePath}assets/tracks_thumbnails/currency.png\')`,
        };
      default:
        return {};
    }
  }

  configure(configType: ConfigurationType) {
    this.showConfiguration = configType;
  }

  closeConfiguration() {
    this.showConfiguration = -1;
  }

  setTracksLayerType(filterBy: TracksLayerType) {
    const activeFilter = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY);
    if (filterBy !== activeFilter) {
      this.settingsService.setStringValue(
          SettingsService.TRACKS_LAYER_TYPE_KEY,
          filterBy,
      );
      this.tracksLayerType = filterBy;
    } else {
      this.settingsService.setStringValue(
          SettingsService.TRACKS_LAYER_TYPE_KEY,
          TracksLayerType.NONE,
      );
      this.tracksLayerType = TracksLayerType.NONE;
    }
  }

  setTrackFilter(trackLayerFilter: TracksLayerFilter) {
    const activeFilter = this.settingsService.getStringValue(SettingsService.PLOW_TRACK_LAYER_FILTER);
    if (trackLayerFilter !== activeFilter) {
      this.settingsService.setStringValue(
          SettingsService.PLOW_TRACK_LAYER_FILTER,
          trackLayerFilter,
      );
      this.tracksFilter = trackLayerFilter;
      this.activeTracksFilter = this.activityFilters.find(filter => filter.type === trackLayerFilter);
    } else {
      this.settingsService.setStringValue(
          SettingsService.PLOW_TRACK_LAYER_FILTER,
          TracksLayerFilter.NONE,
      );
      this.tracksFilter = TracksLayerFilter.NONE;
      this.activeTracksFilter = null;
    }
  }

  toggleTrackFilterVisibility(trackLayerFilter: ActivityFilter) {
    trackLayerFilter.visible = !trackLayerFilter.visible;
    this.settingsService.setBooleanValue(trackLayerFilter.key, trackLayerFilter.visible);
  }

  toggleCustomMapLayer(customLayer: MapLayer, enable: boolean) {
    this.settingsService.setBooleanValue(customLayer.name, enable);
  }
}
