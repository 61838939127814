import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MultiSelectComponent, MultiSelectFilter} from '../../../../../../shared/components/multi-select-component';
import {ObservationTypeGroup} from '../../../../../../shared/models/observation-group';
import {ActivatedRoute, Router} from '@angular/router';
import {ObservationManagementService} from '../../../../../../data/observations/observation-management.service';
import {ToastService} from '../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-observation-type-group-filter-button',
  templateUrl: './observation-type-group-filter-button.component.html',
  styleUrls: ['./observation-type-group-filter-button.component.scss']
})
export class ObservationTypeGroupFilterButtonComponent extends MultiSelectComponent<ObservationTypeGroup> implements OnInit {

  SETTINGS_KEY = 'selectedObservationTypeGroupIds';
  OPERATOR_SETTINGS_KEY = null;
  URL_PARAM_NAME = 'observationTypeGroupIds';
  URL_OP_NAME = null;
  USE_FULLY_CHECKED_AS_DEFAULT = true;

  @Input() origin;
  @Output() filterChanged = new EventEmitter<MultiSelectFilter<ObservationTypeGroup>>();

  filteredItems: ObservationTypeGroup[];

  constructor(
      protected router: Router,
      protected route: ActivatedRoute,
      private observationService: ObservationManagementService,
      private toast: ToastService,
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.observationService.getObservationTypeGroups().toPromise().then(response => {
      this.initComponent(response.data);
      this.filteredItems = this.selected?.slice();
    }).catch(error => {
      console.log(error);
      this.toast.longer('Error while loading observation type groups!');
    });
  }

  getLabel(item: ObservationTypeGroup): string {
    return item.name;
  }

  openMenu() {
    // not implemented
  }

  getSelectedList(): string {
    if (this.filteredItems.length > 3) {
      return 'Multiple Observation Groups Selected';
    } else {
      return this.filteredItems.map(group => this.getLabel(group)).join(', ');
    }
  }

  onApply() {
    this.filteredItems = this.selected?.slice();
  }
}
