import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {Page} from '../../shared/models/Page';
import {DvirReport, InspectionReport} from '../../shared/models/dvir';
import {ShiftReportModel} from '../../shared/models/shift.model';
import {Moment} from 'moment';
import {MultiSelectFilter} from '../../shared/components/multi-select-component';
import {Observable} from 'rxjs';
import {DeviceReportModel} from '../../shared/models/device-report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getDvirReport(
    from: Moment,
    to: Moment,
    vehicleGroupFilter: MultiSelectFilter<number>,
    page: number = 1,
    size: number = 25,
    sort?: string
  ) {
    const params = {
      page: String(page),
      size: String(size),
      sort
    };

    if (!!from) {
      params['from'] = from.toISOString();
    }

    if (!!to) {
      params['to'] = to.toISOString();
    }

    if (!!vehicleGroupFilter && !!vehicleGroupFilter.operator && !!vehicleGroupFilter.elements) {
      params['vehicleGroupIds'] = vehicleGroupFilter.elements.join(':::');
      params['vehicleGroupOp'] = vehicleGroupFilter.operator;
    }

    return this.http.get<JsonApiResponse<Page<DvirReport>>>(
      `${environment.services.service}v1/report/dvir`, {params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getInspectionReport(id: number) {
    return this.http.get<JsonApiResponse<InspectionReport>>(
      `${environment.services.service}v1/report/inspection/${id}`
    ).pipe(catchError(HttpErrorHandler.handleError)).toPromise();
  }

  getDvirPDF(vehicleId: number = null, from: Moment = null, to: Moment = null, vehicleGroupFilter: MultiSelectFilter<number> = null) {
    const params = {};
    if (!!vehicleId) {
      params['vehicleId'] = vehicleId;
    }
    if (!!from) {
      params['dateFrom'] = from.toISOString();
    }
    if (!!to) {
      params['dateTo'] = to.toISOString();
    }
    if (!!vehicleGroupFilter && !!vehicleGroupFilter.operator && !!vehicleGroupFilter.elements) {
      params['vehicleGroupIds'] = vehicleGroupFilter.elements.join(':::');
      params['vehicleGroupOp'] = vehicleGroupFilter.operator;
    }

    return this.http.post(
      `${environment.services.service}v1/report/inspection/pdf`,
      {},
      {responseType: 'blob', headers: {Accept: 'application/pdf'}, params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getDvirByShiftPDF(shiftId: number, preTrip: boolean) {
    const params = {};
    if (preTrip !== undefined) {
      params['pre-trip'] = preTrip;
    }
    return this.http.post(
      `${environment.services.service}v1/report/inspection/${shiftId}/pdf`,
      {},
      {responseType: 'blob', headers: {Accept: 'application/pdf'}, params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getShiftReport(
    from: Moment,
    to: Moment,
    vehicleGroupFilter: MultiSelectFilter<number>,
    page: number = 1,
    size: number = 25,
    sort?: string
  ) {
    const params = {
      page: String(page),
      size: String(size),
      sort
    };

    if (!!from) {
      params['from'] = from.toISOString();
    }

    if (!!to) {
      params['to'] = to.toISOString();
    }

    if (!!vehicleGroupFilter && !!vehicleGroupFilter.operator && !!vehicleGroupFilter.elements) {
      params['vehicleGroupIds'] = vehicleGroupFilter.elements.join(':::');
      params['vehicleGroupOp'] = vehicleGroupFilter.operator;
    }

    return this.http.get<JsonApiResponse<ShiftReportModel>>(
      `${environment.services.service}v1/report/shift`, {params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getShiftReportCSV(from: Moment, to: Moment, vehicleGroupFilter: MultiSelectFilter<number>) {
    const params = {};

    if (!!from) {
      params['from'] = from.toISOString();
    }

    if (!!to) {
      params['to'] = to.toISOString();
    }

    if (!!vehicleGroupFilter && !!vehicleGroupFilter.operator && !!vehicleGroupFilter.elements) {
      params['vehicleGroupIds'] = vehicleGroupFilter.elements.join(':::');
      params['vehicleGroupOp'] = vehicleGroupFilter.operator;
    }

    return this.http.get(
      `${environment.services.service}v1/report/shift/csv`, {params, responseType: 'blob'}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  getShiftReportPDF(from: Moment, to: Moment, vehicleGroupFilter: MultiSelectFilter<number>, hiddenColumns: string[]) {
    const params = {};

    if (!!from) {
      params['from'] = from.toISOString();
    }

    if (!!to) {
      params['to'] = to.toISOString();
    }

    if (!!vehicleGroupFilter && !!vehicleGroupFilter.operator && !!vehicleGroupFilter.elements) {
      params['vehicleGroupIds'] = vehicleGroupFilter.elements.join(':::');
      params['vehicleGroupOp'] = vehicleGroupFilter.operator;
    }

    params['hiddenColumns'] = hiddenColumns.join(',');

    return this.http.get(
      `${environment.services.service}v1/report/shift/pdf`, {params, responseType: 'blob'}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }


  getDeviceReport(
    criteria?: {},
    page: number = 1,
    size: number = 25,
    sort?: string
  ): Observable<JsonApiResponse<DeviceReportModel>> {
    const params = {
      page: String(page),
      size: String(size),
      sort
    };

    Object.assign(params, criteria);

    return this.http.get<JsonApiResponse<DeviceReportModel>>(
      `${environment.services.service}v1/report/device`, {params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

}
