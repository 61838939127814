import {Component, OnInit} from '@angular/core';
import {AlertGeoJson, WeatherWarningsLayerService} from '../services/weather-warnings-layer.service';
import {ToastService} from '../../../services/toast.service';

@Component({
    selector: 'app-alert-info-window-content',
    templateUrl: './alert-info-window-content.component.html',
    styleUrls: ['./alert-info-window-content.component.scss']
})
export class AlertInfoWindowContentComponent implements OnInit {

    oid: string;
    title: string;
    isLoading = true;

    alert: AlertGeoJson;

    constructor(
        private weatherWarningsService: WeatherWarningsLayerService,
        private toast: ToastService,
    ) {
    }

    ngOnInit() {
        this.weatherWarningsService.getAlert(this.oid).toPromise().then(alert => {
            this.alert = alert;
            this.isLoading = false;
        }).catch(message => {
            console.log(message);
            this.toast.long('Failed to load data from weather.gov!');
            this.isLoading = false;
        });
    }

}
