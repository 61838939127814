import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface RouteConfigData {
  id: number;
  name: string;
}

@Component({
  selector: 'app-dialog-confirm-delete-route-config',
  templateUrl: 'dialog-confirm-delete-route-config.html',
})
export class DialogConfirmDeleteRouteConfigComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteRouteConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RouteConfigData) {}
}
