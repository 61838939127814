import {LocationModel} from './location.model';
import {Vehicle} from './vehicle';
import {DriverModel} from './driver.model';

export class VehicleBreadcrumb {
  constructor(readonly vehicle: Vehicle,
              readonly driver: DriverModel,
              readonly breadcrumb: LocationModel,
              readonly shiftId: number) {}
}

export class VehicleBreadcrumbUpdate {
  constructor(readonly breadcrumbs: Array<VehicleBreadcrumb>|null,
              readonly source: string) {}
}

export class VehicleLocationUpdate {

  constructor(readonly vehicleId: number,
              readonly location?: LocationModel) {}
}
