<button
  (click)="toggleMenu()"
  mat-flat-button
  aria-label="close menu"
  class="menu__toggle__btn"
  [matTooltip]="sidenavOpen ? 'Close Menu' : 'Open Menu'"
  matTooltipPosition="right"
>
  <mat-icon
    class="arrow__icon"
    [ngClass]="{ collapse: !sidenavOpen }"
    >chevron_left</mat-icon
  >
</button>
