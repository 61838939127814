<div>
    <h1>{{ title }}</h1>
    <mat-spinner *ngIf="isLoading" [diameter]="18"></mat-spinner>
    <ng-container *ngIf="!!alert">
        <h2>{{ alert.properties.headline}}</h2>
        <pre>{{ alert.properties.description}}</pre>
    </ng-container>

    <ng-container *ngIf="!!alert?.properties?.instruction">
        <h2>Instruction</h2>
        <pre>{{ alert.properties.instruction}}</pre>
    </ng-container>
</div>
