import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ShiftMapDataService} from '../../pages/shift-map/services/shift-map-data.service';
import {Subscription} from 'rxjs';
import {ShiftWithDriverAndVehicleModel} from '../../shared/models/shift.model';

export enum DrawerContent {
  QUICK_MESSAGE = 'message',
  LIVE_VIDEO = 'video',
  ROUTE = 'route',
  OBSERVATION = 'observation',
}

export class DrawerType {
  type: DrawerContent;
  headerIcon: string;
  headerText: string;
}

@Component({
  selector: 'app-right-drawer',
  templateUrl: './right-drawer.component.html',
  styleUrls: ['./right-drawer.component.scss']
})
export class RightDrawerComponent implements OnInit, OnDestroy {

  isDisplayed = false;
  DrawerContent = DrawerContent;
  drawer: string;
  isShiftDetail = false;
  shift: ShiftWithDriverAndVehicleModel;

  drawersAll: DrawerType[] = [
    {
      type: DrawerContent.QUICK_MESSAGE,
      headerIcon: 'message',
      headerText: 'Quick Message'
    },
    {
      type: DrawerContent.LIVE_VIDEO,
      headerIcon: 'tap_and_play',
      headerText: 'Livestreaming',
    },
    {
      type: DrawerContent.ROUTE,
      headerIcon: 'route',
      headerText: 'Quick Route Assignment',
    },
    {
      type: DrawerContent.OBSERVATION,
      headerIcon: 'stars',
      headerText: 'Observations',
    },
  ];
  selectedDrawer: DrawerType;

  shiftSubscription: Subscription;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private shiftMapDataService: ShiftMapDataService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isDisplayed = !!params.drawer;
      this.drawer = params.drawer;
      this.selectedDrawer = this.drawersAll.find(drawer => drawer.type === this.drawer);
    });

    // get shift ID from URL and only for ShiftMapComponent
    this.setShiftId();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setShiftId();
      }
    });

    // get shift entity from shared service
    this.shiftSubscription = this.shiftMapDataService.shift$.subscribe(shift => {
      this.shift = shift;
    });
  }

  ngOnDestroy() {
    this.shiftSubscription?.unsubscribe();
    this.shift = null;
    this.isShiftDetail = false;
  }

  hideDrawer() {
    this.isDisplayed = false;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { drawer: undefined },
      queryParamsHandling: 'merge',
    });
  }

  private setShiftId() {
    this.isShiftDetail = this.router.routerState.snapshot.url.startsWith('/shift-detail');
  }
}
