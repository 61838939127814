import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';

import {MatTableDataSource} from '@angular/material/table';
import {UpdateUserPasswordModel, UserModelView} from '../../../../shared/models/User';
import {UserManagementService} from '../../../../data/users/user-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {
  DialogChangePasswordComponent,
  DialogConfirmDeleteUserComponent,
  DialogEditUserComponent,
} from './dialogs/dialog-components';
import {MatSort} from '@angular/material/sort';
import {UserFilterEvent} from './user-filter/user-filter-bar.component';
import {InfiniteScroll} from '../../../../shared/tools/InfiniteScroll';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements AfterViewInit, OnDestroy {

  @Input() origin: any;
  @Input() cartegraphEnabled = false;
  @Output() changePageEvent = new EventEmitter<string>();
  @ViewChild(MatSort) sort: MatSort;

  isLoading = true;
  uiError: string;
  dataSource: MatTableDataSource<UserModelView> = new MatTableDataSource<UserModelView>([]);
  displayedColumns = [
    'firstName',
    'lastName',
    'roles',
    'login',
    'phoneNumber',
    'actions',
  ];
  private loadedRecordsCount = 0;
  private page: { pageIndex: number; pageSize: number; totalElements: number } = {
    pageIndex: 0,
    pageSize: 50,
    totalElements: 0
  };

  private contentInitCount = 0;
  private scrollSubject = new Subject<Event>(); // to debounce multiple event in short time

  private filter: {
    search?: string,
    roles?: string[],
    rolesOperator?: string
  } = {};

  constructor(
    private userManagementService: UserManagementService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    // the scroll events comes multiple when scrolled, let's filter/postpone them
    this.scrollSubject.pipe(debounceTime(200)).subscribe(event => this.whenScrolled(event));
  }

  ngOnDestroy(): void {
    this.scrollSubject.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  deleteUser(email: string) {
    const that = this;
    const user = this.findUser(email);
    const dialogRef = this.dialog.open(DialogConfirmDeleteUserComponent, {
      width: '450px',
      data: {
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.userManagementService
          .deleteUser(email)
          .then(() => {
            that.removeUser(email);
            that.showSnackBar('User deleted.');
            this.uiError = '';
          })
          .catch((error) => {
            this.uiError = error;
            this.showSnackBar('User is NOT deleted!');
          });
      }
    });
  }

  updateUser(email: string) {
    // reference only
    const user = this.findUser(email);
    // const user = Object.assign({}, this.findUser(userId)); // shallow copy
    // const user = {...this.findUser(userId)}; // shallow copy

    // make deep copy
    const userCopy = JSON.parse(JSON.stringify(this.findUser(email)));
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      width: '450px',
      data: {
        mode: 'update',
        user: {
          email: userCopy.email,
          familyName: userCopy.familyName,
          givenName: userCopy.givenName,
          roles: userCopy.roles,
          phoneNumber: userCopy.phoneNumber,
        } as UserModelView
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result && result.user) {
        this.userManagementService
          .updateUser(result.user)
          .then((response) => {
            Object.assign(user, UserModelView.preload(response.data, this.cartegraphEnabled));
            this.showSnackBar('User updated.');
            this.uiError = '';
          })
          .catch(error => {
            this.uiError = error.error;
            this.showSnackBar(error);
          });
      }
    });
  }

  changePassword(email: string) {
    const user = this.findUser(email);
    const dialogRef = this.dialog.open(DialogChangePasswordComponent, {
      width: '450px',
      data: {
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.userManagementService
          .updatePassword(new UpdateUserPasswordModel(user.email, result))
          .then((response) => {
            this.showSnackBar('Password updated.');
            this.uiError = '';
          })
          .catch((error) => {
            this.uiError = error;
            this.showSnackBar(error);
          });
      }
    });
  }

  addNewUser() {
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      width: '450px',
      data: {mode: 'create', user: new UserModelView()},
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult !== undefined && dialogResult && dialogResult.user) {
        this.userManagementService
          .createUser(dialogResult.user)
          .then((response) => {
            this.addUser(UserModelView.preload(response.data, this.cartegraphEnabled));
            this.showSnackBar('User created.');
            this.uiError = '';
          })
          .catch((error) => {
            this.uiError = error;
            this.showSnackBar('User creation failed!');
          });
      }
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  private findUser(email: string): UserModelView | null {
    return this.dataSource.data.find(record => record.email === email);
  }

  private removeUser(email: string): void {
    this.dataSource.data = this.dataSource.data.filter(user => user.email !== email);
    this.loadedRecordsCount--;
    this.page.totalElements--;
  }

  private addUser(user: UserModelView) {
    const users = this.dataSource.data;
    users.push(user);
    this.dataSource.data = users;
    this.loadedRecordsCount++;
    this.page.totalElements++;
  }

  changePage(page: string) {
    this.changePageEvent.emit(page);
  }

  private loadUsersAndDrivers(initialize: boolean) {

    if (initialize) {
      this.page.pageIndex = 0;
    } else {
      this.page.pageIndex = this.page.pageIndex + 1;
    }

    this.isLoading = true;
    this.userManagementService
      .getUsers(this.page.pageIndex,
        this.page.pageSize,
        this.getSort(),
        this.filter.roles,
        this.filter.rolesOperator,
        this.filter.search
      )
      .toPromise()
      .then((response) => {
        const loadedRecordsCount = response.data.content.length;
        const data = response.data.content
            .filter(user => !user.email.startsWith('neotreks_support')) // do now show support user
            .map<UserModelView>(user => {
              return UserModelView.preload(user, this.cartegraphEnabled);
            });

        if (initialize) {
          this.dataSource.data = data;
          this.loadedRecordsCount = loadedRecordsCount;
        } else {
          const extended = [...this.dataSource.data];
          extended.push(...data);
          this.dataSource.data = extended;
          this.loadedRecordsCount += loadedRecordsCount;
        }
        this.page.totalElements = response.data.totalElements;
        this.page.pageSize = response.data.pageable.pageSize;

      })
      .catch((error) => {
        console.log(error);
        this.uiError = error;
      }).finally(() => {
      this.isLoading = false;
    });
  }

  private whenScrolled(e: Event) {
    InfiniteScroll.onScroll(e, () => {
      if (!this.isLoading && this.loadedRecordsCount < this.page.totalElements) {
        this.loadUsersAndDrivers(false);
      }
    });
  }

  onScroll(e: Event) {
    this.scrollSubject.next(e);
  }

  sortBy() {
    this.loadUsersAndDrivers(true);
  }

  private getSort(): string | undefined {
    if (!this.sort?.active) {
      return undefined;
    }
    return `${this.sort.active},${this.sort.direction}`;
  }

  onUserFilterChanged($event: UserFilterEvent) {
    this.filter.search = $event.search;
    this.filter.roles = $event.rolesFilter?.elements;
    this.filter.rolesOperator = $event.rolesFilter?.operator;
    // hack, we have to check if all children already called the parent, otherwise it loads data without filtering
    // if more children in the chips then raise the check
    if (this.contentInitCount++ > 0) {
      this.loadUsersAndDrivers(true);
    }
  }
}
