import {Component, OnInit} from '@angular/core';
import {RoutesService} from '../../../../data/routes/routes.service';
import {
  FieldFormat,
  LayerBasicModel,
  RouteConfiguration,
  RouteConfigurationWithSchema,
  RouteImportAttribute,
  RouteSchema,
  RouteStyle,
  TaskStatus
} from '../../../../shared/models/route';
import {RouteFilter} from './route-tree/route-tree.component';
import {Wizard} from './route-configuration/route-configuration.component';
import {ActivatedRoute, Router} from '@angular/router';
import {McRouteConfiguration} from '../../../../shared/components/map-preview/model/McRouteConfiguration';
import {
  RouteConfigDetailMapContent
} from '../../../../shared/components/map-preview/model/map-content/RouteConfigDetailMapContent';
import {MapContent} from '../../../../shared/components/map-preview/model/MapContent';
import {EmptyMapContent} from '../../../../shared/components/map-preview/model/EmptyMapContent';
import {ToastService} from '../../../../shared/services/toast.service';
import {ExtAuthService} from '../../../../data/ext-auth/ext-auth.service';
import { ConfigurationService } from 'src/app/configuration/configuration.service';

@Component({
  selector: 'app-manage-routes',
  templateUrl: './manage-routes.component.html',
  styleUrls: ['./manage-routes.component.scss']
})
export class ManageRoutesComponent implements OnInit {

  wizard = false;
  readonly DEFAULT_CONFIG_NAME = 'All Routes';
  configurationName: string = this.DEFAULT_CONFIG_NAME;
  featureServiceUrl: string;
  layerId: number;
  layers: LayerBasicModel[];
  fields: FieldFormat[];
  loadingSchema = false;
  loadingFields = false;
  loadingFieldItems = false;
  exportToCustomMapLayers = false;

  useCredentials: boolean;
  username: string;
  password: string;

  routeField: string;
  categories: string[] = [];
  routeConfiguration: RouteConfiguration;
  configurationWithSchema: RouteConfigurationWithSchema;

  routeConfigurationId: number;
  attributes: string[];
  routeStyle: RouteStyle;

  routeFilter: RouteFilter = {routeIds: [], all: true};
  isUpdating: boolean;

  featureTaskUuid: string;
  mapContent: MapContent = EmptyMapContent.instance;

  constructor(private routesService: RoutesService,
              private configurationService: ConfigurationService,
              private extAuthService: ExtAuthService,
              private router: Router,
              private toastService: ToastService,
              private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const configurationId = +this.route.snapshot.paramMap.get('configurationId');
    let level;
    if (configurationId >= 0) {
      level = 'keep-schema';
      this.routesService.getRouteConfiguration(configurationId).then(response => {
        this.runWizard({level, configuration: response.data});
      });
    } else {
      level = 'new';
      this.runWizard({level});
    }
  }

  runWizard(wizardConfig: Wizard) {
    this.wizard = true;
    if (wizardConfig.level === 'keep-schema') {
      const config = wizardConfig.configuration;
      this.configurationName = config.name;
      this.featureServiceUrl = config.url;
      this.useCredentials = !!config.username;
      this.username = config.username;
      this.password = config.password;
      this.getFeatureServiceDescription();
      this.layerId = config.layerId;
      this.getFeatureServiceLayerDescription();
      this.attributes = config.schema.classification.map(att => att.attribute);
      this.routeStyle = config.schema.style;
      this.categories = this.attributes.splice(0, this.attributes.length - 1);
      this.routeField = this.attributes[this.attributes.length - 1];
      this.routeConfiguration = config.configuration;
      this.routeConfigurationId = config.id;
      this.exportToCustomMapLayers = config.updateCustomLayers;
      this.getFeatureServiceLayerFieldItems();
    } else {
      this.configurationName = this.DEFAULT_CONFIG_NAME;
      this.featureServiceUrl = null;
      this.username = null;
      this.password = null;
      this.useCredentials = false;
      this.layerId = null;
      this.layers = null;
      this.fields = null;
      this.categories = [];
      this.routeConfiguration = null;
      this.configurationWithSchema = null;
      this.routeStyle = null;
      this.routeConfigurationId = null;
    }
  }

  cancelWizard() {
    // this.wizard = false;
    this.cleanFeatureTask();
    this.router.navigate(['/settings/manage-routes']);
  }

  private cleanFeatureTask() {
    if (!!this.featureTaskUuid) {
      this.routesService.cleanFeatureTaskStatus(this.featureTaskUuid).catch();
      this.featureTaskUuid = null;
    }
  }

  getFeatureServiceDescription() {
    this.layerId = null;
    this.layers = null;
    this.fields = null;
    this.attributes = [];
    this.categories = [];
    this.routeConfiguration = null;
    this.loadingSchema = true;
    if (this.featureServiceUrl) {
      this.routesService.getFeatureServiceDescription(this.featureServiceUrl, this.username, this.password).then(response => {
        this.layers = response.data.layers;
        this.loadingSchema = false;
      }).catch(error => {
        console.error(error);
        this.loadingSchema = false;
        this.toastService.long('Failed to get Feature Service Description! Error: ' + error);
      });
    }
  }

  getFeatureServiceLayerDescription() {
    this.fields = null;
    this.routeConfiguration = null;
    this.loadingFields = true;
    if (this.featureServiceUrl && (!!this.layerId || this.layerId === 0)) {
      this.routesService.getFeatureServiceLayerDescription(
          this.featureServiceUrl, this.layerId, this.username, this.password
      ).then(response => {
        this.fields = response.data.fields;
        this.loadingFields = false;
      }).catch(error => {
        console.error(error);
        this.loadingFields = false;
        this.toastService.long(error);
      });
    }
  }

  getFeatureServiceLayerFieldItems() {
    this.loadingFieldItems = true;
    this.attributes = [];
    this.attributes.push(...this.categories);
    this.attributes.push(this.routeField);
    if (!this.routeStyle) {
      this.routeStyle = new RouteStyle(
          '#FF0000',
          4,
          1.0
      );
    }
    if (this.featureServiceUrl && (!!this.layerId || this.layerId === 0)) {
      // start async get feature config
      this.routesService.readFeatureCollectionFromFeatureProxyAsync(
          this.featureServiceUrl,
          this.layerId,
          this.attributes,
          this.username,
          this.password,
      ).then(response => {
        // first clean any previous features data
        this.cleanFeatureTask();
        this.featureTaskUuid = response.data;
        console.log(`Task UUID: ${this.featureTaskUuid}`);
        // repeatedly check the task status
        this.checkTaskStatus(this.featureTaskUuid);
      }).catch(error => {
        console.error(error);
        this.loadingFieldItems = false;
        this.toastService.long(error);
      });
    }
  }

  checkTaskStatus(taskUuid: string) {
    setTimeout(
        () => this.taskStatusHandler(taskUuid),
        3000,
    );
  }

  private taskStatusHandler(taskUuid: string) {
    console.log('Task status check...');
    this.routesService.getFeatureTaskStatus(taskUuid).then(taskStatus => {
      const status = taskStatus.data;
      console.log(`Status: ${status}`);
      if (status === TaskStatus.IN_PROGRESS) {
        this.checkTaskStatus(taskUuid);
      } else if (status === TaskStatus.DONE) {
        console.log(`Getting updated configuration...`);
        this.routesService.getRouteConfigurationFromFeatureProxy(
            taskUuid,
            this.routeConfigurationId,
            this.featureServiceUrl,
            this.layerId,
            this.attributes,
        ).then(response => {
          this.routeConfiguration = response.data;
          this.updateRouteConfigurationWithSchemaFromWizard();
          this.loadingFieldItems = false;
        }).catch(error => {
          console.log(error);
          this.loadingFieldItems = false;
          this.toastService.long('Import failure! Getting new configuration failed. See browser log.');
        });
      } else if (status === TaskStatus.FAILURE) {
        // doing nothing
        this.loadingFieldItems = false;
        this.featureTaskUuid = null;
        this.toastService.long('Import failure! Task status "failure". See browser log.');
      } else {
        // UNKNOWN
        this.loadingFieldItems = false;
        this.featureTaskUuid = null;
        this.toastService.long('Import failure! Task status undefined. See browser log.');
      }
    }).catch(error => {
      console.log(error);
      this.loadingFieldItems = false;
      this.toastService.long('Import failure! Task check failed. See browser log.');
    });
  }

  testFeatureServiceCredentials() {
    this.extAuthService.verifyEsriCredentials(this.featureServiceUrl, this.username, this.password).then(response => {
      if (response.data) {
        this.toastService.short('Connection is OK!');
      } else {
        this.toastService.long('Error on connection!');
      }
    });
  }

  onCredentialsToggle() {
    if (!this.useCredentials) {
      this.username = null;
      this.password = null;
    }
  }

  addCategory() {
    this.categories.push(null);
  }

  removeCategory() {
    this.categories.pop();
  }

  categoriesFilled() {
    return this.categories.filter(category => !category).length === 0;
  }

  filterRoutes(routeFilter: RouteFilter) {
    this.routeFilter = routeFilter;

    this.updateMapRouteVisibility();
  }

  routeStyleEdited(routeStyle: RouteStyle) {
    this.updateMapConfigurationStyle(routeStyle);
  }

  colorsOverrideChanged(e) {
    this.updateMapRouteColorOverrides();
  }

  updateRouteConfig() {
    this.isUpdating = true;
    this.configurationWithSchema.name = this.configurationName;
    this.configurationWithSchema.updateCustomLayers = this.exportToCustomMapLayers;
    console.log('Saving route configuration...');
    this.routesService.updateRouteConfiguration(this.configurationWithSchema).then(response => {
      console.log('Route configuration saved.');
      console.log('Saving route geometries...');
      this.routesService.updateRouteGeoJson(this.featureTaskUuid, response.data.id, this.configurationWithSchema).then(() => {
        console.log('Route geometries saved.');
        this.isUpdating = false;
        this.wizard = false;
        this.cleanFeatureTask();
        this.router.navigate(['/settings/manage-routes']);
      }).catch(error => {
        this.isUpdating = false;
        this.toastService.long('Updating geometries failed! ' + error);
      });
      if (this.exportToCustomMapLayers) {
        this.configurationService.updateRouteMapLayer(response.data.id).then(mapLayerResponse => {
          console.log('Custom map layer saved.');
          this.configurationService.refreshConfiguration();
        }).catch(error => {
          this.toastService.long('Updating custom map layer failed! ' + error);
        });
      }
    }).catch(error => {
      this.isUpdating = false;
      this.toastService.long('Updating configuration failed! ' + error);
    });
  }

  updateRouteConfigurationWithSchemaFromWizard() {
    const routeConfig = new RouteConfiguration();
    routeConfig.childrenAttribute = this.attributes[0];
    routeConfig.children = this.routeConfiguration.children;
    this.configurationWithSchema = new RouteConfigurationWithSchema(
        this.configurationName,
        this.featureServiceUrl,
        this.username,
        this.password,
        this.layerId,
        this.layers.find(layer => layer.id === this.layerId).name,
        this.exportToCustomMapLayers,
        new RouteSchema(
            this.attributes.map(attribute => {
              return new RouteImportAttribute(attribute);
            }),
            this.routeStyle,
        ),
        routeConfig,
    );
    this.configurationWithSchema.id = this.routeConfigurationId;
    this.recreateMapContent();
  }

  private recreateMapContent() {
    if (this.configurationWithSchema == null) {
      this.mapContent = EmptyMapContent.instance;
    } else {
      console.log('Getting updated geometries...');
      this.routesService.getRoutesGeoJsonFromExternalService(
        this.featureTaskUuid,
        this.configurationWithSchema.layerId,
        this.configurationWithSchema.schema.classification.map(att => att.attribute),
        this.configurationWithSchema.configuration,
      ).then(response => {
        console.log('Updated geometries retrieved. Updating map content...');
        this.mapContent = new RouteConfigDetailMapContent(
          McRouteConfiguration.fromBase(this.configurationWithSchema, this.routeFilter, true),
          response.data);
        console.log('Map content updated.');
      }).catch(error => {
        const msg = 'Error while loading map features';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
        this.mapContent = EmptyMapContent.instance;
        this.featureTaskUuid = null;
      });
    }
  }

  private updateMapConfigurationStyle(routeStyle: RouteStyle) {
    if (this.isMapContentUpdateAllowed()) {
      const castMapContent = this.mapContent as RouteConfigDetailMapContent;
      castMapContent.changeConfigurationStyle(
        McRouteConfiguration.fromBaseAndStyle(this.configurationWithSchema, routeStyle, this.routeFilter, true));
    }
  }

  private updateMapRouteColorOverrides() {
    if (this.isMapContentUpdateAllowed()) {
      const castMapContent = this.mapContent as RouteConfigDetailMapContent;
      castMapContent.changeRouteColorOverrides(
        McRouteConfiguration.fromBaseAndStyle(this.configurationWithSchema, this.routeStyle, this.routeFilter, true));
    }
  }

  private updateMapRouteVisibility() {
    if (this.isMapContentUpdateAllowed()) {
      const castMapContent = this.mapContent as RouteConfigDetailMapContent;
      castMapContent.changeRouteVisibility(
        McRouteConfiguration.fromBase(this.configurationWithSchema, this.routeFilter, true));
    }
  }

  private isMapContentUpdateAllowed() {
    return this.configurationWithSchema != null && this.mapContent != null && this.mapContent instanceof RouteConfigDetailMapContent;
  }
}
