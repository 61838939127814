<div *ngIf="isLoading" class="loading__wrapper">
    <mat-spinner></mat-spinner>
</div>

<div class="my-25" *ngIf="!isLoading && configuration">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Imports from Cartegraph</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                <span class="ui-error">{{ uiError }}</span>
            </div>

            <div *ngIf="!isLoading && (!tasks || tasks.data?.length<=0)">There are no data available with
                status: {{taskStatus}}</div>

            <div class="filter my-25">
                <button mat-button
                        color="accent"
                        (click)="refresh()">Refresh
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-form-field appearance="outline" color="accent" class="ml-25" subscriptSizing="dynamic">
                    <mat-label>Status</mat-label>
                    <mat-select class="ml-10" multiple (selectionChange)="processingStatusChange($event)"
                                [value]="taskStatus">
                        <mat-option *ngFor="let status of taskStatuses"
                                    [value]="status">{{status.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <table mat-table *ngIf="!isLoading && tasks && tasks.data?.length>0"
                       class="tasks-table mat-elevation-z4 mt-15"
                       matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection"
                       [dataSource]="tasks.data"
                       (matSortChange)="sortChange($event)">
                <ng-container matColumnDef="id">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Id.</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngTemplateOutlet="status;context:{status:element.taskStatus}"></ng-container>
                        {{element.id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="created">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Created</th>
                    <td mat-cell *matCellDef="let element"> {{element.created | date:'medium' }} </td>
                </ng-container>

                <ng-container matColumnDef="finished">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Finished</th>
                    <td mat-cell *matCellDef="let element"> {{element.finished | date:'medium' }} </td>
                </ng-container>

                <ng-container matColumnDef="taskStatus">
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.taskStatus}} </td>
                </ng-container>

                <ng-container matColumnDef="taskMessage">
                    <th mat-header-cell *matHeaderCellDef> Message</th>
                    <td mat-cell
                        *matCellDef="let element"
                        (click)="messageDetail(element)">
                        <div class="message-content">{{element.taskMessage}}</div></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>

        <mat-card-footer>
            <!-- note: cannot be in the ngIf statement! -->
            <mat-paginator showFirstLastButtons
                           (page)="onPage($event)"
                           [pageSize]="pageSize"
                           [pageIndex]="pageIndex"
                           [length]="itemCount"
                           aria-label="Select page">
            </mat-paginator>
        </mat-card-footer>


    </mat-card>

</div>

<ng-template #status datatype="string" let-status="status">
    <span class="icon-status">
        <ng-container [ngSwitch]="status">
            <mat-icon *ngSwitchCase="CartegraphImportTaskStatus.IN_PROGRESS"
                      class="waiting spin">hourglass_bottom</mat-icon>
            <mat-icon *ngSwitchCase="CartegraphImportTaskStatus.FAIL" class="fail">error</mat-icon>
            <mat-icon *ngSwitchCase="CartegraphImportTaskStatus.SUCCESS" class="success">check_circle</mat-icon>
        </ng-container>
    </span>
</ng-template>
