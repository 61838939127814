import {NodeOperationEnum} from './NodeOperationEnum';

/**
 * Definition of what operation should be executed on MifTreeComponent nodes (representing MifNodes).
 */
export class NodeOperation {
  id: string; // internal ID in MifNode and also ID in MifTreeComponent nodes
  label: string; // node label for logging and debugging only
  operation: NodeOperationEnum;

  constructor(id: string, label: string, operation: NodeOperationEnum) {
    this.id = id;
    this.label = label;
    this.operation = operation;
  }

  static from(id: string, label: string, operation: NodeOperationEnum): NodeOperation {
    return new NodeOperation(id, label, operation);
  }
}
