import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'hoursMinutes'
})
export class HoursMinutesPipe implements PipeTransform {

  /**
   * Transforms duration in seconds to HH:MM
   * @param value Must be a number
   */
  transform(value: any): any {
    if (!value) {
      return '00:00';
    }

    const finalDuration = moment.duration(value, 'seconds');

    const hours = finalDuration.asHours();
    const hoursFormatted = hours && hours > 1 ? `${Math.floor(hours)}`.padStart(2, '0') : '00';

    const minutes = finalDuration.minutes();
    const minutesFormatted = minutes ? `${minutes}`.padStart(2, '0') : '00';

    return [hoursFormatted, minutesFormatted].join(':');
  }
}
