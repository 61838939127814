import {Injectable} from '@angular/core';
import {VehiclesManagerService} from '../../../../data/vehicles/vehicles-manager.service';
import {MapLayersManager} from '../map-layers-manager';
import {
  CircleLayerSpecification,
  ExpressionFilterSpecification,
  FilterSpecification,
  GeoJSONSourceSpecification,
  SymbolLayerSpecification
} from 'maplibre-gl';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {ShiftFilterUpdate} from '../../../models/shift-filter';
import {ShiftState} from '../../../models/shift.model';
import {FeatureCollection, GeoJSON} from 'geojson';
import {LiveMapDataService} from '../../../../pages/live-map/services/live-map-data.service';
import {ServerEventService} from '../../../../data/server-events/server-event.service';
import {MapStyles} from '../../../../configuration/map-styles';
import {AssetsManagerService} from '../../../../data/assets/assets-manager.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';

@Injectable({
  providedIn: 'root'
})
export class VehicleMapMarkerService {

  static readonly LAYER_ID_VEHICLES_CIRCLE = 'vehicles_circle';
  static readonly LAYER_ID_VEHICLES_SHADOW = 'vehicles_shadow';
  static readonly LAYER_ID_VEHICLES_AZIMUTH = 'vehicles_azimuth';
  static readonly LAYER_ID_VEHICLES_LABEL = 'vehicles_label';
  static readonly VEHICLE_SOURCE_ID = 'vehicles';

  static readonly AZIMUTH_ICON = 'azimuth';
  static readonly EXCLAMATION_ICON = 'exclamation';
  static readonly PAUSE_ICON = 'pause';
  static readonly LABEL_BACKGROUND = 'vehicle_label';
  static readonly HIGHLIGHTED_LABEL_BACKGROUND = 'vehicle_label_highlighted';

  private mapLayersManager: MapLayersManager;
  private readonly assetsMap = new Map<number, Asset>();
  private highlightedVehicleId: number = null;
  private featureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features: []
  };

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private vehiclesManagerService: VehiclesManagerService,
              private shiftsManagerService: ShiftsManagerService,
              private serverEventService: ServerEventService,
              private liveMapDataService: LiveMapDataService,
              private assetManager: AssetsManagerService,
              ) {}

  init(mapLayersManager: MapLayersManager, useManager: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;

    // load icon for azimuth
    // source: https://fonts.google.com/icons?selected=Material+Icons:menu
    this.mapLayersManager.loadImage(
        VehicleMapMarkerService.AZIMUTH_ICON,
        `${environment.base_href}assets/baseline_20_sdf.png`,
        { sdf: true }
        );

    // exclamation mark for stationary state
    this.mapLayersManager.loadImage(
        VehicleMapMarkerService.EXCLAMATION_ICON,
        `${environment.base_href}assets/exclamation.png`,
        { sdf: true }
    );

    // icon for paused shift
    this.mapLayersManager.loadImage(
      VehicleMapMarkerService.PAUSE_ICON,
      `${environment.base_href}assets/pause.png`,
      { sdf: true }
    );

    // load icon for label background
    this.mapLayersManager.loadImage(
        VehicleMapMarkerService.LABEL_BACKGROUND,
        `${environment.base_href}assets/vehicle_label_bg.png`,
        { sdf: false }
    );

    // load icon for highlighted label background
    this.mapLayersManager.loadImage(
        VehicleMapMarkerService.HIGHLIGHTED_LABEL_BACKGROUND,
        `${environment.base_href}assets/vehicle_label_bg_highlighted.png`,
        { sdf: false }
    );

    this.addSourceAndLayers();

    if (useManager) {
      this.connectToManager();
    }
  }

  addSourceAndLayers() {
    this.mapLayersManager.addSource(VehicleMapMarkerService.VEHICLE_SOURCE_ID, this.createVehicleSource());

    this.mapLayersManager.addLayer(this.createLabelLayer());
    this.mapLayersManager.addLayer(this.createShadowLayer());
    this.mapLayersManager.addLayer(this.createCircleLayer());
    this.mapLayersManager.addLayer(this.createAzimuthLayer());
  }

  private createVehicleSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.featureCollection,
      cluster: false,
      clusterMaxZoom: 13, // Max zoom to cluster points on
      clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)
    } as GeoJSONSourceSpecification;
  }

  private updateVehicleSource() {
    const source = this.mapLayersManager.getGeoJsonSource(VehicleMapMarkerService.VEHICLE_SOURCE_ID);
    if (!!source) {
     source.setData(this.featureCollection);
    }
  }

  private createCircleLayer(): CircleLayerSpecification {
    return {
      id: VehicleMapMarkerService.LAYER_ID_VEHICLES_CIRCLE,
      type: 'circle',
      source: VehicleMapMarkerService.VEHICLE_SOURCE_ID,
      filter: this.getLayerFilter(),
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          [
            'case',
            ['==', ['get', 'shiftState'], ShiftState.PAUSED], MapStyles.PAUSED_COLOR,
            ['==', ['get', 'shiftState'], ShiftState.STATIONARY], MapStyles.STATIONARY_COLOR,
            ['==', ['get', 'shiftState'], ShiftState.ENDED], MapStyles.DISABLED_COLOR,
            MapStyles.LIVE_COLOR,
          ],
        ],
        'circle-radius': 14,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'white',
        'circle-opacity': 1.0
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

  private createShadowLayer(): CircleLayerSpecification {
    return {
      id: VehicleMapMarkerService.LAYER_ID_VEHICLES_SHADOW,
      type: 'circle',
      source: VehicleMapMarkerService.VEHICLE_SOURCE_ID,
      filter: this.getLayerFilter(),
      paint: {
        'circle-radius': 18,
        'circle-color': '#000',
        'circle-blur': 0.75,
        'circle-translate': [2, 2],
      },
    } as CircleLayerSpecification;
  }

  private createLabelLayer(): SymbolLayerSpecification {
    return {
      id: VehicleMapMarkerService.LAYER_ID_VEHICLES_LABEL,
      type: 'symbol',
      source: VehicleMapMarkerService.VEHICLE_SOURCE_ID,
      filter: this.getLayerFilter(),
      layout: {
        'icon-image': [
          'case',
          ['boolean', ['get', 'highlighted']],
          VehicleMapMarkerService.HIGHLIGHTED_LABEL_BACKGROUND,
          VehicleMapMarkerService.LABEL_BACKGROUND,
        ],
        'icon-anchor': 'left',
        'icon-text-fit': 'width',
        'icon-text-fit-padding': [0, 10, 0, 20],
        'icon-allow-overlap': true,
        'text-field': ['get', 'title'],
        'text-font': [
          'case',
          ['boolean', ['get', 'highlighted']],
          ['literal', ['Roboto Medium']],
          ['literal', ['Roboto']],
        ],
        'text-size': 12,
        'text-offset': [2, 0.0],
        'text-anchor': 'left',
      },
      paint: {
        'text-opacity': 1.0,
        'text-color': [
          'case',
          ['boolean', ['get', 'highlighted']], 'white',
          ['==', ['get', 'shiftState'], ShiftState.PAUSED], MapStyles.PAUSED_COLOR,
          ['==', ['get', 'shiftState'], ShiftState.STATIONARY], MapStyles.STATIONARY_COLOR,
          ['==', ['get', 'shiftState'], ShiftState.ENDED], MapStyles.DISABLED_COLOR,
          MapStyles.LIVE_COLOR,
        ],
        'icon-opacity': 0.9,
      },
    } as SymbolLayerSpecification;
  }

  private createAzimuthLayer(): SymbolLayerSpecification {
    return {
      id: VehicleMapMarkerService.LAYER_ID_VEHICLES_AZIMUTH,
      type: 'symbol',
      source: VehicleMapMarkerService.VEHICLE_SOURCE_ID,
      filter: this.getLayerFilter(),
      layout: {
        'icon-image': [
          'case',
          ['==', ['get', 'shiftState'], ShiftState.PAUSED], VehicleMapMarkerService.PAUSE_ICON,
          ['==', ['get', 'shiftState'], ShiftState.STATIONARY], VehicleMapMarkerService.EXCLAMATION_ICON,
          VehicleMapMarkerService.AZIMUTH_ICON
        ],
        'icon-rotate':  [
          'case',
          ['==', ['get', 'shiftState'], ShiftState.PAUSED], 0,
          ['==', ['get', 'shiftState'], ShiftState.STATIONARY], 0,
          ['get', 'heading']
        ],
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': 'white',
        'icon-halo-width': 1,
        'icon-halo-color': 'white',
      }
    } as SymbolLayerSpecification;
  }

  private handleSourceUpdate(geojson: GeoJSON) {
    this.featureCollection = geojson as FeatureCollection;
    this.updateProperties();
    this.updateVehicleSource();
  }

  private handleAssetsUpdate(assets: Asset[]) {
    this.assetsMap.clear();
    assets.forEach(asset => {
      this.assetsMap.set(asset.id, asset);
    });
    this.updateProperties();
    this.updateVehicleSource();
  }

  private updateProperties() {
    const features = this.featureCollection.features;
    if (features.length > 0) {
      features.forEach(feature => {
        const asset = this.assetsMap.get(feature.properties.locationsourceid);
        if (!!asset) {
          const isHighlighted = !!this.highlightedVehicleId && this.highlightedVehicleId === asset.id;
          feature.properties.id = asset.id;
          feature.properties.title = asset.name;
          feature.properties.highlighted = isHighlighted;
          feature.properties.hidden = false;
          feature.properties.shiftState = asset.shiftStatus;
        } else {
          feature.properties.hidden = true;
        }
      });
    }
  }

  private highlightVehicle(vehicleId: number) {
    this.highlightedVehicleId = vehicleId;
    this.updateProperties();
    this.updateVehicleSource();
  }

  private getLayerFilter(): FilterSpecification {
    const filter: ExpressionFilterSpecification = ['all'];
    filter.push(['!', ['get', 'hidden']]);
    return filter;
  }

  private connectToManager() {
    const that = this;

    const assetManagerSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleAssetsUpdate(assets);
    });
    this.openSubscriptions.push(assetManagerSubscription);

    const cacheChangeSubscription = this.serverEventService.currentLocationsObservable.subscribe({
      next(currentLocations) {
        that.handleSourceUpdate(currentLocations);
      }
    });
    this.openSubscriptions.push(cacheChangeSubscription);

    const highlightedShiftSubscription = this.shiftsManagerService.highlightedShift$.subscribe({
      next(shiftFilterUpdate) {
        that.highlightVehicle(shiftFilterUpdate.shift?.vehicleId);
      }
    });
    this.openSubscriptions.push(highlightedShiftSubscription);

    const highlightedVehicleSubscription = this.vehiclesManagerService.highlightedVehicle$.subscribe(vehicle => {
      this.highlightVehicle(vehicle?.id);
    });
    this.openSubscriptions.push(highlightedVehicleSubscription);
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map layer manager has not been set.');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
    this.assetsMap.clear();
    this.featureCollection = {
      type: 'FeatureCollection',
      features: []
    };
    this.highlightedVehicleId = null;
  }
}
