import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable} from 'rxjs';
import {UserManagement} from '../shared/models/User';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private currentUser;

  constructor(
    private oidcSecurityService: OidcSecurityService
  ) {
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.oidcSecurityService.isAuthenticated().subscribe(authenticated => {
        if (authenticated) {
          this.oidcSecurityService.getAccessToken().subscribe(token => {
            resolve(token);
          }, error => {
            reject(error);
          });
        } else {
          reject('Authentication failed, not authenticated (yet)');
        }
      }, error => {
        reject(error);
      });
    });
  }

  isAdminSync(): boolean {
      return !!this.currentUser ? this.hasAdminRole(this.currentUser) : false;
  }

  isAdmin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.oidcSecurityService.getPayloadFromIdToken().toPromise()
        .then(payload => {
          resolve(this.hasAdminRole(payload));
        })
          .catch(error => reject(error));
    });
  }

  public getUserEmail(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.oidcSecurityService.getUserData().toPromise()
          .then(data => resolve(data.email))
          .catch(error => reject(error));
    });
  }

  public getUserName(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.oidcSecurityService.getUserData().toPromise()
          .then(data => {
            this.currentUser = data;
            resolve(data?.email);
          })
          .catch(error => reject(error));
    });
  }

  public getUserData(): Observable<any> {
    return this.oidcSecurityService.getUserData();
  }

  public logout() {
    this.oidcSecurityService.logoffLocal();
    window.location.href = `${environment.authentication.authority}/login?logout`;
  }

  private hasAdminRole(userData) {
      return userData.roles.includes(UserManagement.ROLE_PORTAL_ADMIN);
  }
}
