import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LiveMapComponent } from './components/live-map.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { LiveMapPanelComponent } from './components/panel/live-map-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MessagesComponent } from './components/panel-sections/messages/messages.component';
import { ObservationsComponent } from './components/panel-sections/observations/observations.component';
import { NewMessageComponent } from './components/panel-sections/messages/new-message/new-message.component';
import { OpenMessageComponent } from './components/panel-sections/messages/open-message/open-message.component';
import { MatHoverDirective } from 'src/app/shared/directives/mat-hover.directive';
import { LiveMapRoutingModule } from './live-map-routing.module';
import {CommonModule, DatePipe} from '@angular/common';
import { AllMessagesComponent } from './components/panel-sections/messages/all-messages/all-messages.component';
import { LiveMapLoaderComponent } from './components/ui-components/live-map-loader/live-map-loader.component';
import {FilterRecipientsPipe} from './utilities/filter-messages.pipe';
import {ConfirmDeleteMessageDialogComponent} from './components/panel-sections/messages/dialogs/confirm-delete-message-dialog.component';
import { QuickMessageComponent } from './components/panel-sections/messages/quick-message/quick-message.component';
import { LiveVideoComponent } from './components/panel-sections/live-video/live-video.component';
import { RoutesComponent } from './components/panel-sections/routes/routes.component';
import { AssetsComponent } from './components/panel-sections/assets/assets/assets.component';
import { AssetComponent } from './components/panel-sections/assets/asset/asset.component';
import { QuickRouteAssignmentComponent } from './components/panel-sections/routes/quick-route-assignment/quick-route-assignment.component';
import { RouteSelectorComponent } from './components/panel-sections/routes/route-selector/route-selector.component';
import { DriverObservationsComponent } from './components/panel-sections/observations/driver-observations/driver-observations.component';
import {ShortDateOrTimePipe} from '../../shared/formatting/short-date-or-time.pipe';
import { VehicleGroupFilterButtonComponent } from './components/ui-components/filters/vehicle-group-filter-button/vehicle-group-filter-button.component';
import { StatusFilterButtonComponent } from './components/ui-components/filters/status-filter-button/status-filter-button.component';
import { HardwareTypeFilterButtonComponent } from './components/ui-components/filters/hardware-type-filter-button/hardware-type-filter-button.component';
import { ObservationTypeGroupFilterButtonComponent } from './components/ui-components/filters/observation-type-group-filter-button/observation-type-group-filter-button.component';
import { DateRangeFilterButtonComponent } from './components/ui-components/filters/date-range-filter-button/date-range-filter-button.component';
import { SearchBarComponent } from './components/ui-components/search-bar/search-bar.component';
import { CompletedAssignmentsStatsComponent } from './components/panel-sections/routes/completed-assignments-stats/completed-assignments-stats.component';
import { RouteModeToggleComponent } from './components/ui-components/route-mode-toggle/route-mode-toggle.component';

@NgModule({
    declarations: [
        LiveMapComponent,
        LiveMapPanelComponent,
        FilterRecipientsPipe,
        MessagesComponent,
        ObservationsComponent,
        NewMessageComponent,
        OpenMessageComponent,
        ConfirmDeleteMessageDialogComponent,
        MatHoverDirective,
        AllMessagesComponent,
        LiveMapLoaderComponent,
        QuickMessageComponent,
        LiveVideoComponent,
        RoutesComponent,
        AssetsComponent,
        AssetComponent,
        QuickRouteAssignmentComponent,
        RouteSelectorComponent,
        DriverObservationsComponent,
        VehicleGroupFilterButtonComponent,
        StatusFilterButtonComponent,
        HardwareTypeFilterButtonComponent,
        ObservationTypeGroupFilterButtonComponent,
        DateRangeFilterButtonComponent,
        SearchBarComponent,
        CompletedAssignmentsStatsComponent,
        RouteModeToggleComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        LiveMapRoutingModule,
    ],
    providers: [
        DatePipe,
        ShortDateOrTimePipe,
    ],
    exports: [
        LiveVideoComponent,
        QuickMessageComponent,
        QuickRouteAssignmentComponent,
        DriverObservationsComponent,
    ]
})
export class LiveMapModule {}
