import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {ImportResultRow} from '../../../../../shared/models/cartegraph.model';


@Component({
  selector: 'app-drivers-import-cartegraph',
  templateUrl: './manage-drivers-import-cartegraph.component.html',
  styleUrls: ['./manage-drivers-import-cartegraph.component.scss'],
})
export class ImportDriversCartegraphComponent {

  @Output() changePageEvent = new EventEmitter<string>();

  uiError: string;
  ImportDriversStateEnum = ImportDriversStateEnum; // allow access from template via variable
  currentImportDriversState = ImportDriversStateEnum.NOOP;

  importKeys: { name: string, label: string }[] = [
    {name: 'added', label: 'Added'},
    {name: 'activated', label: 'Updated or reactivated'},
    {name: 'deactivated', label: 'Deactivated'},
    {name: 'skipped', label: 'Skipped'},
    {name: 'error', label: 'Errors'}];
  importResult: { key: string; list: ImportResultRow[] };

  constructor(public dialog: MatDialog,
              private cartegraphService: CartegraphManagementService) {
  }

  changePage(page?: string) {
    this.changePageEvent.emit(page);
  }

  cancelImport() {
    this.doCancel();
    this.changePage();
  }

  doImport() {
    this.currentImportDriversState = ImportDriversStateEnum.WORKING;
    this.cartegraphService.importDrivers()
      .then((response) => {
        if (response.error) {
          this.uiError = response.error;
          this.currentImportDriversState = ImportDriversStateEnum.NOOP;
        } else {
          this.importResult = response.data;
          this.currentImportDriversState = ImportDriversStateEnum.IMPORTED;
        }
      }).catch(error => {
      this.uiError = error;
      this.currentImportDriversState = ImportDriversStateEnum.NOOP;
    });
  }

  doCancel() {
    this.currentImportDriversState = ImportDriversStateEnum.WORKING;
    this.currentImportDriversState = ImportDriversStateEnum.NOOP;
  }

}

enum ImportDriversStateEnum {
  NOOP,
  WORKING,
  IMPORTED
}
