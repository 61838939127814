import {Component, OnDestroy, OnInit} from '@angular/core';
import {AssetRightPanel} from '../../assets/asset-right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../../data/assets/assets-manager.service';
import {ToastService} from '../../../../../../shared/services/toast.service';
import {ObservationsManagerService} from '../../../../../../data/observations/observations-manager.service';
import {Subscription} from 'rxjs';
import {Observation} from '../../../../../../shared/models/observation';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../../shared/models/action-menu-item.class';
import {LiveMapTab} from '../../../../models/live-map-tabs';
import {ShortDateOrTimePipe} from '../../../../../../shared/formatting/short-date-or-time.pipe';

@Component({
  selector: 'app-driver-observations',
  templateUrl: './driver-observations.component.html',
  styleUrls: ['./driver-observations.component.scss']
})
export class DriverObservationsComponent extends AssetRightPanel implements OnInit, OnDestroy {

  selectedObservationId: number;
  allObservations: Observation[];
  driverObservations: Observation[] = [];
  items: ActionMenuItem[];
  observationSubscription: Subscription;

  constructor(
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private observationsManager: ObservationsManagerService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
      private toast: ToastService,
  ) {
    super(
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
  }

  ngOnInit(): void {
    this.initializeAssets();
    this.loadConfiguration();

    this.observationSubscription = this.observationsManager.filteredObservations$.subscribe(allObservations => {
      let observations = [];
      if (!!allObservations && allObservations.length > 0) {
        observations = allObservations;
      }
      if (this.allObservations?.length !== observations.length) {
        this.allObservations = observations;
        this.updateShiftObservations();
        this.updateUiItems();
      }
    });
  }

  ngOnDestroy(): void {
    this.configSubscription?.unsubscribe();
    this.observationSubscription?.unsubscribe();
  }

  onAssetChange() {
    this.selectedObservationId = null;
    if (!!this.asset && !!this.allObservations) {
      this.updateShiftObservations();
      this.updateUiItems();
    }
  }

  onConfigurationLoad() {
    // do nothing
  }

  private updateShiftObservations() {
    if (!!this.asset && !!this.allObservations) {
      this.driverObservations = this.allObservations
          .filter(observation => observation.shiftId === this.asset.shiftId)
          .sort((a, b) => {
            return +new Date(a.location.time) - +new Date(b.location.time);
          });
    }
  }

  private updateUiItems() {
    if (!!this.asset && !!this.driverObservations) {
      this.items = this.driverObservations.map(observation => this.observationToActionMenuItem(observation));
    }
  }

  private selectObservation(observation: Observation) {
    this.selectedObservationId = observation.id;
    this.observationsManager.highlightObservation(observation.id, ObservationsManagerService.LIST_ACTION_SOURCE);
  }

  private viewObservation(observation: Observation) {
    this.observationsManager.highlightObservation(observation.id, ObservationsManagerService.LIST_ACTION_SOURCE);
    this.router.navigate(['/live-map', LiveMapTab.OBSERVATIONS], {
      queryParams: { id: observation.id, scrollToView: true },
    });
  }

  private observationToActionMenuItem(observation: Observation): ActionMenuItem {
    return new ActionMenuItem(
        observation.id,
        'stars',
        observation.observationType.title,
        this.shortDateOrTimePipe.transform(observation.location.time),
        '',
        null,
        () => this.selectedObservationId === observation.id,
        null,
        () => this.selectObservation(observation),
        null,
        null,
        [
            new ActionMenuItemSubMenu(
              'visibility',
                'View Observation Detail',
                () => this.viewObservation(observation),
            )
        ],
    );
  }
}
