import {MifSectionDto} from './MifSectionDto';
import {MifGroupDto} from './MifGroupDto';
import {MifQuestionDto} from './MifQuestionDto';

export class MifNodeDto {
  nodeType: string;
  section: MifSectionDto;
  group: MifGroupDto;
  question: MifQuestionDto;
  nodes: MifNodeDto[];

  constructor(nodeType: string, section: MifSectionDto, group: MifGroupDto, question: MifQuestionDto, nodes: MifNodeDto[]) {
    this.nodeType = nodeType;
    this.section = section;
    this.group = group;
    this.question = question;
    this.nodes = nodes;
  }
}
