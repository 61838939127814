<div class="video-wrapper">
    <video [ngClass]="{ hidden: isLoading || isError }"
           (loadedmetadata)="onMetadataLoaded()"
           (timeupdate)="onTimeUpdate()"
           #video
           class="video"
           width="250"
           [controls]="false"
           [muted]="true"
           playsinline
           [autoplay]="autoplay"
           preload="none"
    >
    </video>

    <div class="video-empty"
         *ngIf="isLoading || isError"
    >
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span *ngIf="!isLoading && isError">No video</span>
        <button mat-icon-button
                *ngIf="!isLoading && isError && liveMode"
                (click)="refresh()"
        >
            <mat-icon>refresh</mat-icon>
        </button>
    </div>

    <div class="video-controls">
        <button mat-icon-button
                (click)="onPlayPauseClick()"
                *ngIf="!isError"
        >
            <mat-icon *ngIf="video.paused">play_arrow</mat-icon>
            <mat-icon *ngIf="!video.paused">pause</mat-icon>
        </button>
        <button mat-icon-button
                (click)="onFullScreen()"
                *ngIf="browserSupportsFullScreen && !isError"
        >
            <mat-icon>fullscreen</mat-icon>
        </button>
        <button mat-button
                    (click)="onLiveToggle()"
                    *ngIf="!!timeFrom && !timeTo"
        >
            <span *ngIf="liveMode">Go to Shift Start</span>
            <span *ngIf="!liveMode">Switch to Live</span>
        </button>
    </div>

    <div class="progress-info-text" [ngClass]="{'hidden': liveMode}">
        <div><span *ngIf="!!currentTime">{{ currentTime | hoursMinutesSeconds }}</span></div>
        <div><span *ngIf="!!timeFrom && !!currentTime">{{ (absUnixTime * 1000) | date:'mediumTime' }}</span></div>
    </div>

    <div class="progress-info-text" [ngClass]="{'hidden': !liveMode}">
        <div><span *ngIf="!!liveDiff">Live - {{ (liveDiff / 1000).toFixed(1) }} seconds</span></div>
    </div>

    <div class="progress-bar" [ngClass]="{'hidden': liveMode}">
        <div class="progress">
            <progress #progress value="0" (click)="onProgressClick($event)">
            </progress>
        </div>
    </div>


</div>
