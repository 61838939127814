import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../../../../configuration/settings.service';

@Component({
  selector: 'app-route-mode-toggle',
  templateUrl: './route-mode-toggle.component.html',
  styleUrls: ['./route-mode-toggle.component.scss']
})
export class RouteModeToggleComponent implements OnInit {

  routeStatusMode: boolean;
  legacyMode: boolean;

  constructor(
      private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.routeStatusMode = this.settingsService.getBooleanValue(SettingsService.ROUTE_STATUS_MODE);
    this.legacyMode = !this.routeStatusMode;
  }

  toggleMode() {
    this.routeStatusMode = !this.legacyMode;
    const routeStatusMode = this.settingsService.getBooleanValue(SettingsService.ROUTE_STATUS_MODE);
    if (routeStatusMode !== this.routeStatusMode) {
      this.settingsService.setBooleanValue(SettingsService.ROUTE_STATUS_MODE, this.routeStatusMode);
    }
  }
}
