import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseNodeDialogData, NodeDialogData, TextNodeDialogData} from './NodeDialogData';

@Component({
  selector: 'app-mif-node-dialog',
  templateUrl: './mif-node-dialog.component.html',
  styleUrls: ['./mif-node-dialog.component.scss']
})
export class MifNodeDialogComponent {

  textData: TextNodeDialogData = (this.data instanceof TextNodeDialogData) ? this.data : undefined;
  baseData: BaseNodeDialogData = (this.data instanceof BaseNodeDialogData) ? this.data : undefined;

  constructor(
    public dialogRef: MatDialogRef<MifNodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NodeDialogData | TextNodeDialogData) {}

  buttonClickedSave(): void {
    if (this.isDialogValid()) {
      this.dialogRef.close(this.data);
    }
  }

  isDialogValid(): boolean {
    return this.data.textField.trim() !== '';
  }

}
