import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {LayerSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {LocationInfo} from '../../../../models/AddressLookup';
import {Feature} from 'geojson';
import {PointFeature} from '../../../../models/GeoJson';
import {MapPreviewComponent} from '../../map-preview.component';
import {MapTools} from '../../../../tools/MapTools';
import {Map} from 'maplibre-gl';

export class MarkerLayer extends MapContentLayer {

  static readonly MARKER_FEATURE_TYPE = 'marker';

  constructor(
    source: MapContentSource
  ) {
    super('marker-layer', source);
  }

  zoomToVisibleFeatures(map: Map, zoomLevel: number = 14) {

    const visibleFeatures = this.sourceRef.features
      .filter(feature => feature.properties.type === MarkerLayer.MARKER_FEATURE_TYPE && feature.properties.visible);

    if (visibleFeatures.length > 1) {
      MapTools.zoomToFeatures(map, visibleFeatures);

    } else if (visibleFeatures.length === 1) {
      const marker = visibleFeatures[0] as PointFeature;
      MapTools.zoomToPointFeature(map, marker, zoomLevel);
    }
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceRef.sourceId,
      filter: ['in', 'type', MarkerLayer.MARKER_FEATURE_TYPE],
      layout: {
        'icon-image': MapPreviewComponent.MAP_MARKER_IMAGE
      },
      paint: {
        'icon-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          '#F8BA00',
          'rgb(255, 0, 0)'
        ],
      },
      visibility: 'visible'
    } as SymbolLayerSpecification;
  }

  addMarker(location: LocationInfo) {
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          location.geometry.lng,
          location.geometry.lat
        ]
      },
      properties: {
        id: location.id,
        type: MarkerLayer.MARKER_FEATURE_TYPE,
        highlighted: false,
        visible: true
      }
    } as Feature;
    this.sourceRef.addFeature(feature);
  }
}
