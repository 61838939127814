<div class="setup-page-container">
    <div class="header-container">
        <h2 class="header-container-filters">
            My vehicle groups
        </h2>
    </div>

    <div class="selector-container mt-25">

        <div class="loading__wrapper" *ngIf="currentState == ImportVehiclesStateEnum.WORKING">
            <div>Working on it ...</div>
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="currentState == ImportVehiclesStateEnum.NOOP">
            <mat-card appearance="outlined">
                <mat-card-title class="text-accent pl-15">Import vehicles from remote Cartegraph server</mat-card-title>
                <mat-card-actions>
                    <button mat-button
                            color="accent"
                            [disabled]="importDisabled()"
                            (click)="doImport()">Import
                    </button>
                    <button mat-button
                            color="accent"
                            (click)="doCancel()">Cancel
                    </button>
                </mat-card-actions>
            </mat-card>

        </div>

        <div *ngIf="currentState != ImportVehiclesStateEnum.WORKING && uiError && uiError.length > 0"
             class="mt-25 w-100 content">
            <span class="ui-error">{{ uiError }}</span>
        </div>

        <div *ngIf="currentState == ImportVehiclesStateEnum.IMPORTED">
            <div>Import successfully finished.
                <button mat-button color="accent" (click)="changePage()"
                        class="text-accent">Navigate to vehicle groups
                </button>
                to see changes.
            </div>
            <div class="mt-15">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let key of importKeys">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{key.label}}: {{importResult[key.name]?.length || 0}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ol>
                            <li *ngFor="let row of importResult[key.name]">{{row.key}} <span *ngIf="key.name=='error'"
                                                                                             class="mat-small">{{row.note}}</span>
                            </li>
                        </ol>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

        </div>


    </div>

</div>

