import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../../configuration/settings.service';
import {ShiftPlaybackService} from '../map-viewer/services/shift-playback.service';
import {Subscription} from 'rxjs';
import {ToastService} from '../../services/toast.service';

enum PlaybackAction {
  STOP = 'stop',
  PLAY = 'play',
  RESUME = 'resume',
  PAUSE = 'pause'
}

enum PlaybackStatus {
  STOPPED = 'stopped',
  PLAYING = 'playing',
  PAUSED = 'paused',
  LOADING = 'loading'
}

@Component({
  selector: 'app-time-filter-bar',
  templateUrl: './time-filter-bar.component.html',
  styleUrls: ['./time-filter-bar.component.scss']
})
export class TimeFilterBarComponent implements OnInit, OnDestroy {

  @Input() shiftLength: number;
  timeOffset: number;

  timeFilterPctFrom = 0;
  timeFilterPctTo = 100;
  timeFilterOptions = {
    floor: 0,
    ceil: 100,
    hideLimitLabels: true,
    hidePointerLabels: true
  };

  playbackStatus: PlaybackStatus = PlaybackStatus.STOPPED;
  progressSubscription: Subscription;
  progressTime = 0;
  absProgressTime = 0;
  speed = 10;
  speeds = [5, 10, 20];

  PlaybackAction = PlaybackAction;
  PlaybackStatus = PlaybackStatus;

  constructor(private settingsService: SettingsService,
              private shiftPlaybackService: ShiftPlaybackService,
              private toast: ToastService) { }

  ngOnInit() {
    const that = this;

    this.progressSubscription = this.shiftPlaybackService.progressObservable.subscribe({
      next(time: number) {
        that.progressTime = time;
        that.absProgressTime = that.timeOffset + time;
      }
    });
  }

  ngOnDestroy(): void {
    this.settingsService.setStringValue(
        SettingsService.SHIFT_TRACK_TIME_FILTER,
        '0_100'
    );
    this.settingsService.setStringValue(
        SettingsService.SHIFT_TRACK_ANIM_SPEED,
        String(10)
    );
    this.cleanSubscription();
  }

  timeFilterChanged() {
    this.settingsService.setStringValue(
        SettingsService.SHIFT_TRACK_TIME_FILTER,
        String(this.timeFilterPctFrom) + '_' + String(this.timeFilterPctTo)
    );
  }

  actionTriggered(action: PlaybackAction) {
    switch (action) {
      case PlaybackAction.STOP:
        this.playbackStatus = PlaybackStatus.STOPPED;
        this.shiftPlaybackService.stopAnimation();
        this.progressTime = 0;
        break;
      case PlaybackAction.PLAY:
        this.playbackStatus = PlaybackStatus.LOADING;
        this.shiftPlaybackService.loadDataIfNeededAndPlay().then(response => {
          if (response) {
            this.timeOffset = this.shiftPlaybackService.getShiftTimeOffset();
            if (this.timeFilterPctFrom === 0) {
              this.playbackStatus = PlaybackStatus.PLAYING;
            } else {
              // start shifted a bit
              // pause, jump and play again
              const newStart = Math.round(this.timeFilterPctFrom / 100 * this.shiftLength);
              this.playbackStatus = PlaybackStatus.PAUSED;
              this.shiftPlaybackService.pauseAnimation();
              this.shiftPlaybackService.jumpToPosition(newStart);
              this.playbackStatus = PlaybackStatus.PLAYING;
              this.shiftPlaybackService.playAnimation();
            }
          } else {
            this.playbackStatus = PlaybackStatus.STOPPED;
            console.error('Something wrong happened with data load.');
          }
        }).catch(() => {
          this.playbackStatus = PlaybackStatus.STOPPED;
          this.toast.long('Data load failed.');
        });
        break;
      case PlaybackAction.PAUSE:
        this.playbackStatus = PlaybackStatus.PAUSED;
        this.shiftPlaybackService.pauseAnimation();
        break;
      case PlaybackAction.RESUME:
        this.playbackStatus = PlaybackStatus.PLAYING;
        this.shiftPlaybackService.playAnimation();
        break;
      default:
        this.playbackStatus = PlaybackStatus.STOPPED;
        this.shiftPlaybackService.stopAnimation();
        this.progressTime = 0;
    }
  }

  private cleanSubscription() {
    if (this.progressSubscription) {
      this.progressSubscription?.unsubscribe();
    }
  }

  progressChanged() {
    this.actionTriggered(PlaybackAction.PAUSE);
    this.shiftPlaybackService.jumpToPosition(this.progressTime);
  }

  speedChanged(speed: number) {
    if (this.playbackStatus !== PlaybackStatus.STOPPED) {
      this.actionTriggered(PlaybackAction.PAUSE);
    }
    this.speed = speed;
    this.settingsService.setStringValue(
        SettingsService.SHIFT_TRACK_ANIM_SPEED,
        String(this.speed)
    );
    if (this.playbackStatus !== PlaybackStatus.STOPPED) {
      this.shiftPlaybackService.jumpToPosition(this.progressTime);
      this.actionTriggered(PlaybackAction.RESUME);
    }
  }
}
