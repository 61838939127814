<ng-container *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam)">
    <div *ngIf="!shift.vehicle.cameraConfiguration?.streaming?.captureStreamDuration">
        <span>Video Streaming not configured for the asset</span>
    </div>
    <div class="shift-video" *ngIf="shift.vehicle.cameraConfiguration?.streaming?.captureStreamDuration > 0">
        <app-video-player [vehicleId]="shift.vehicleId"
                          [timeFrom]="shift.start"
                          [timeTo]="shift.end">
        </app-video-player>
    </div>

    <div class="shift-images" *ngIf="shift.vehicle.cameraConfiguration?.streaming?.captureImageInterval > 0">
        <mat-spinner *ngIf="loadingImages"></mat-spinner>
        <div *ngIf="!loadingImages && !!images && images.length === 0">
            No still images
        </div>
        <app-image-viewer
                *ngIf="!!images && images.length > 0"
                [images]="images"
        ></app-image-viewer>
    </div>
</ng-container>
