<div *ngIf="isLoading" class="loading__wrapper">
    <mat-spinner></mat-spinner>
</div>

<div class="my-25" *ngIf="!isLoading && configuration">
    <mat-card appearance="outlined">

        <mat-card-header>
            <mat-card-title>Account setup</mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="!isLoading" class="mt-25">
            <form [formGroup]="updateForm">

                <div class="form-row"
                     [ngClass]="{'password-error-row': updateForm.controls['baseUrl'].touched && updateForm.controls['baseUrl'].invalid}">
                    <mat-form-field appearance="outline" color="accent" class="form-input">
                        <mat-label>Base URL</mat-label>
                        <input type="text" matInput placeholder="Base URL" formControlName='baseUrl'
                               name="baseUrl">
                        <mat-error *ngIf="updateForm.controls['baseUrl'].hasError('required')">
                            Base URL is required.
                        </mat-error>
                    </mat-form-field>

                </div>

                <div class="form-row"
                     [ngClass]="{'password-error-row': updateForm.controls['username'].touched && updateForm.controls['username'].invalid}">
                    <mat-form-field appearance="outline" color="accent" class="form-input">
                        <mat-label>Username</mat-label>
                        <input type="text" matInput placeholder="Username" formControlName='username'
                               name="baseUrl">
                        <mat-error *ngIf="updateForm.controls['username'].hasError('required')">
                            Username is required.
                        </mat-error>
                    </mat-form-field>

                </div>

                <mat-expansion-panel hideToggle [expanded]="passwordPanelExpanded" [disabled]="isTesting || isLoading"
                                     class="password-expansion">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Password
                        </mat-panel-title>
                        <mat-panel-description>
                            ...
                            <mat-icon>key</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-row"
                         [ngClass]="{'password-error-row': updateForm.controls['password'].touched && updateForm.controls['password'].invalid}">
                        <mat-form-field class="form-input" appearance="outline" color="accent">
                            <mat-label>Password</mat-label>
                            <input type="password" matInput placeholder="Password" formControlName="password"
                                   name="password" autocomplete="password">
                            <mat-error *ngIf="updateForm.controls['password'].hasError('required')">
                                Password is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-row">
                        <mat-form-field class="form-input" appearance="outline" color="accent">
                            <mat-label>Repeat Password</mat-label>
                            <input type="password" matInput placeholder="Verify Password"
                                   formControlName="verifyPassword"
                                   name="verifyPassword"
                                   autocomplete="verifyPassword">
                            <mat-error *ngIf="updateForm.controls['verifyPassword'].hasError('required')">
                                Password is required.
                            </mat-error>
                            <mat-error *ngIf="updateForm.hasError('passwordsDoNotMatch')">
                                Passwords do not match!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </mat-card-content>


        <mat-card-actions *ngIf="!isLoading" class="mt-10">
            <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                <span class="ui-error">{{ uiError }}</span>
            </div>

            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="testConnection()"
                    [disabled]="!(updateForm.controls['baseUrl'].valid && updateForm.controls['username'].valid)
                                || isTesting"
            >
                <mat-icon *ngIf="!isTesting">published_with_changes</mat-icon>
                <mat-spinner *ngIf="isTesting" diameter="20" color="accent" class="spinner"></mat-spinner>
                Test connection
            </button>
            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="updatePassword()"
                    [disabled]="updateForm.hasError('passwordsDoNotMatch') ||
            updateForm.controls['password'].hasError('required') ||
            updateForm.controls['password'].hasError('pattern') || isTesting"
            >
                <mat-icon>save</mat-icon>
                Update configuration
            </button>
        </mat-card-actions>

        <mat-card-footer *ngIf="!isLoading">
            <div *ngIf="connectionTested?.data" class="success">Connection successful.</div>
            <div *ngIf="connectionTested?.error" class="fail">Connection
                failed. {{connectionTested.error}}</div>

        </mat-card-footer>
    </mat-card>
</div>
