<div class="live-map-filter">
    <button mat-button
            class="button-wrapper mat-elevation-z2"
            [matMenuTriggerFor]="menu"
            [ngClass]="{'active': !!filter && (!!filter.from || !!filter.to)}"
    >
        <div class="filter-button" *ngIf="!filter || (!filter.from && !filter.to)">
            <mat-icon>
                stars
            </mat-icon>
            <span>Date</span>
            <mat-icon>
                arrow_drop_down
            </mat-icon>
        </div>

        <div class="filter-button" *ngIf="!!filter && (!!filter.from || !!filter.to)">
            <mat-icon>
                check
            </mat-icon>
            <span>{{ filter.label }}</span>
            <mat-icon>
                arrow_drop_down
            </mat-icon>
        </div>
    </button>
</div>

<mat-menu #menu="matMenu">
    <app-date-filter-dropdown
            class="live-map-filter-menu-wrapper"
            [origin]="'liveMap'"
            (filterChanged)="onDateFilterChange($event)"
    >
    </app-date-filter-dropdown>
</mat-menu>
