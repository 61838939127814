<div class="menu-wrapper">
  <div class="date-range-menu">
    <div class="named-ranges">
      <button mat-menu-item
              (click)="setFilter(TimeRangeFilter.TODAY.key)"
              [ngClass]="{ 'active-date-range': dateFilterType === TimeRangeFilter.TODAY }"
      >
        {{ TimeRangeFilter.TODAY.select }}
      </button>
      <button mat-menu-item
              (click)="setFilter(TimeRangeFilter.WEEK.key)"
              [ngClass]="{ 'active-date-range': dateFilterType === TimeRangeFilter.WEEK }"
      >
        {{ TimeRangeFilter.WEEK.select }}
      </button>
      <button mat-menu-item
              (click)="setFilter(TimeRangeFilter.MONTH.key)"
              [ngClass]="{ 'active-date-range': dateFilterType === TimeRangeFilter.MONTH }"
      >
        {{ TimeRangeFilter.MONTH.select }}
      </button>
      <button mat-menu-item
              (click)="setFilter(TimeRangeFilter.PAST_90_DAYS.key)"
              [ngClass]="{ 'active-date-range': dateFilterType === TimeRangeFilter.PAST_90_DAYS }"
      >
        {{ TimeRangeFilter.PAST_90_DAYS.select }}
      </button>
      <button mat-menu-item
              (click)="toggleCustom($event)"
              [ngClass]="{ 'active-date-range': dateFilterType === TimeRangeFilter.CUSTOM }"
      >
        <div class="menu-button">
          <span>{{ TimeRangeFilter.CUSTOM.select }}</span>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </button>
    </div>
    <mat-divider *ngIf="showCustom" [vertical]="true"></mat-divider>
    <div class="expanded-custom" *ngIf="showCustom">
      <mat-form-field appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput
               [matDatepicker]="startDatePicker"
               [formControl]="startDateForm"
               (click)="startDatePicker.open(); $event.stopPropagation()"
        >
        <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>End Date</mat-label>
        <input matInput
               [matDatepicker]="endDatePicker"
               [formControl]="endDateForm"
               (click)="endDatePicker.open(); $event.stopPropagation()"
        >
        <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

  </div>
  <mat-divider></mat-divider>
  <div class="menu-footer">
    <button
            mat-button
            color="accent"
            class="ml-10"
            (click)="setFilter(TimeRangeFilter.NONE.key)"
    >
      Clear All
    </button>
    <div>
      <button
              mat-button
              color="accent"
      >
        Cancel
      </button>
      <button
              mat-button
              color="accent"
              class="mr-10"
              (click)="setCustomRangeFilter()"
              [disabled]="!showCustom || startDateForm.invalid || endDateForm.invalid"
      >
        Apply
      </button>
    </div>
  </div>
</div>
