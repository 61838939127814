import {Driver} from './driver.model';
import {HardwareConfiguration, VehicleModel} from './vehicle.model';
import {Page} from './Page';
import {Coordinate} from './breadcrumb.model';

export class ShiftModel {

  readonly start?: Date;
  readonly end?: Date;

  constructor(readonly id: number,
              readonly vehicleId: number,
              readonly driverId: number,
              public state: ShiftState = ShiftState.NORMAL,
              ) {}
}

export enum ShiftState {
  NORMAL = 'NORMAL',
  PAUSED = 'PAUSED',
  STATIONARY = 'STATIONARY',
  ENDED = 'ENDED',
}

export class ShiftWithDriverAndVehicleModel {
  readonly id: number;
  readonly vehicleId: number;
  readonly vehicle: VehicleModel;
  readonly driverId: number;
  readonly driver: Driver;
  readonly start: Date;
  readonly end: Date;
  readonly distanceDriven?: number;
  readonly distancePlowed?: number;
  readonly distanceSpreadGranular?: number;
  readonly distanceSpreadLiquid?: number;
  readonly distanceMowed?: number;
  readonly distanceSwept?: number;
  readonly materialUsedGranular?: number;
  readonly materialUsedLiquid?: number;
  readonly imageGenerationStatus: ImageGenerationStatus;
  readonly startInspectionId?: number;
  readonly endInspectionId?: number;
  readonly vehicleHardwareConfiguration?: HardwareConfiguration;
  readonly statusStatistics: ShiftStatisticsStatus;
}

export class ShiftReportRecordModel {

  constructor(readonly id: number,
              readonly vehicle: string,
              readonly driver: string,
              readonly startTime: string,
              readonly endTime: string,
              readonly observationsCount: number,
              readonly distanceDriven: number,
              readonly distancePlowed: number,
              readonly distanceSpreadGranular: number,
              readonly distanceSpreadLiquid: number,
              readonly distanceMowed: number,
              readonly distanceSwept: number,
              readonly materialUsedGranular: number,
              readonly materialUsedLiquid: number,
              readonly maxSpeed: number,
              readonly durationInSeconds: number,
              readonly phoneNumber?: string
  ) {}
}

export class ShiftReportModel {

  constructor(readonly records: Page<ShiftReportRecordModel>,
              readonly total: ShiftReportTotalModel
  ) {}
}

export class ShiftReportTotalModel {

constructor(readonly count: number,
            readonly driversCount: number,
            readonly vehiclesCount: number,
            readonly observationsCount: number,
            readonly distanceDriven: number,
            readonly distancePlowed: number,
            readonly distanceSpreadGranular: number,
            readonly distanceSpreadLiquid: number,
            readonly distanceMowed: number,
            readonly distanceSwept: number,
            readonly materialUsedGranular: number,
            readonly materialUsedLiquid: number,
            readonly maxSpeed: number,
            readonly firstStartTime: string,
            readonly lastEndTime: string,
            readonly durationInSeconds: number
  ) {}
}

export enum ImageGenerationStatus {
  NOT_GENERATED = 0,
  GENERATED = 1,
  FAILURE_LOCATION_SERVICE = 2,
  FAILURE_AWS = 3,
}

export interface ShiftDistance {
  id: number;
  distance: number;
  distancePlowed: number;
  distanceSpreadGranular: number;
  distanceSpreadLiquid: number;
  distanceMowed: number;
  distanceSwept: number;
}

export interface SessionStatistics {
  sessionKey: number;
  locationSourceId: number;
  maxSpeed: number;
  length: number;
  start: Location;
  end: Location;
  gpsSources: (number | undefined)[];
}

export interface Location {
  coordinates: Coordinate;
  unixtime: number;
}

export enum ShiftStatisticsStatus {
  READY = 'READY',
  // error during processing, stats, materials compute etc.
  ERROR = 'ERROR',
  // in processing state, if needed
  PROCESSING = 'PROCESSING',
  // shift stats computed successfully
  COMPLETE = 'COMPLETE',
}
