import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  short(message: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(
        message,
        null,
        {
          duration: 2000,
          verticalPosition: 'bottom',
          panelClass: 'toast-panel',
        });
  }

  longer(message: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(
        message,
        null,
        {
          duration: 3000,
          verticalPosition: 'bottom',
          panelClass: 'toast-panel',
        });
  }

  long(message: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(
        message,
        null,
        {
          duration: 5000,
          verticalPosition: 'bottom',
          panelClass: 'toast-panel',
        });
  }
}
