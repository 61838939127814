import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CartegraphConfiguration} from 'src/app/shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {JsonApiResponse} from '../../../../../shared/models/JsonApiResponse';

@Component({
  selector: 'app-manage-cartegraph-setup',
  templateUrl: './cartegraph-setup.component.html',
  styleUrls: ['./cartegraph-setup.component.scss'],
})
export class CartegraphSetupComponent implements OnInit {

  isLoading = true;
  isTesting = false;
  uiError: string;
  updateForm: UntypedFormGroup;
  connectionTested: JsonApiResponse<string>;

  @Input()
  configuration: CartegraphConfiguration;
  @Output()
  configurationChange = new EventEmitter<CartegraphConfiguration>();
  passwordPanelExpanded = false;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.initForm();
  }

  initForm() {
    this.updateForm = this.fb.group({
      baseUrl: '',
      username: '',
      password: '',
      verifyPassword: '',
    }, {
      validators: [this.passwordValidator]
    });
    this.updateForm.controls[FormFields.PASSWORD].setValidators([
      Validators.required,
    ]);
    this.updateForm.valueChanges.subscribe(selectedValue => {
      this.connectionTested = null;
    });
  }

  passwordValidator(form: UntypedFormGroup) {
    const condition = form.get(FormFields.PASSWORD).value !== form.get('verifyPassword').value;
    return condition ? {passwordsDoNotMatch: true} : null;
  }

  ngOnInit() {
    this.isLoading = true;
    this.updateForm.controls[FormFields.BASE_URL].setValue(this.configuration.baseUrl);
    this.updateForm.controls[FormFields.USERNAME].setValue(this.configuration.username);
    this.passwordPanelExpanded = this.configuration.username === undefined;
    this.isLoading = false;
  }


  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  updatePassword() {
    this.isLoading = true;
    const config = new CartegraphConfiguration();
    config.baseUrl = this.baseUrlValue();
    config.username = this.usernameValue();
    config.password = this.passwordValue();
    this.cartegraphManagementService.updateConfiguration(config).then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.configurationChange.emit(response.data);
        this.showSnackBar('Configuration updated.');
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  testConnection() {
    this.isTesting = true;
    // use data from input or if missing from config itself
    const url = this.baseUrlValue() ? this.baseUrlValue() : this.configuration.baseUrl;
    const user = this.usernameValue() ? this.usernameValue() : this.configuration.username;
    const pwd = this.passwordValue() ? this.passwordValue() : this.configuration.password;
    this.cartegraphManagementService.testConnection(url, user, pwd)
      .then(result => {
        this.connectionTested = result;
      }).catch(error => {
      this.connectionTested = error;
    }).finally(() => {
      this.isTesting = false;
      this.showSnackBar('Connection verified.');
    });
  }

  private baseUrlValue() {
    return this.updateForm.get(FormFields.BASE_URL).value;
  }

  private usernameValue() {
    return this.updateForm.get(FormFields.USERNAME).value;
  }

  private passwordValue() {
    return this.updateForm.get(FormFields.PASSWORD).value;
  }


}

enum FormFields {
  USERNAME = 'username',
  PASSWORD = 'password',
  BASE_URL = 'baseUrl',
}
