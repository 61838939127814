import {TracksLayerFilter} from './settings.service';
import {ExpressionFilterSpecification} from 'maplibre-gl';

export class MapFilters {
    static getInactiveShiftsFilter(
        shiftIds: number[],
        vehicleIds: number[],
        hours: number,
        flagsFilter: any[],
        highlightedShiftId: number,
        showRecent: boolean
    ) {
        const filter: any[] = ['all'];
        if (!showRecent && !highlightedShiftId) {
            // hide all unless a shift is highlighted
            filter.push(['boolean', false]);
            return filter;
        }
        if (!!highlightedShiftId) {
            // only highlighted shift
            filter.push(['==', ['get', 'sessionkey'], highlightedShiftId]);
        } else {
            if (shiftIds && shiftIds.length) {
                // filter only inactive shifts
                filter.push(['!', ['in', ['get', 'sessionkey'], ['literal', shiftIds]]]);
            }
            if (vehicleIds && vehicleIds.length) {
                // only selected vehicles
                filter.push(['in', ['get', 'locationsourceid'], ['literal', vehicleIds]]);
            }
        }

        if (hours && hours < 12) {
            // apply hour filter from track bar
            const minTime = new Date().getTime() / 1000 - hours * 3600;
            filter.push(['>=', ['get', 'startunixtime'], minTime]);
        }

        // apply flagsFilter
        if (flagsFilter.length > 0) {
            filter.push(flagsFilter);
        }

        return filter;
    }

    static getActiveShiftsFilter(
        shiftIds: number[],
        vehicleIds: number[],
        hours: number,
        flagsFilter: any[],
        highlightedShiftId: number
    ) {
        const filter: any[] = ['all'];
        if (!!highlightedShiftId) {
            // only highlighted shift
            filter.push(['==', ['get', 'sessionkey'], highlightedShiftId]);
        }
        if (shiftIds && shiftIds.length) {
            // filter only active shifts
            filter.push(['in', ['get', 'sessionkey'], ['literal', shiftIds]]);
        } else {
            // no selection, no active shift - hide all the data
            filter.push(['boolean', false]);
            return filter;
        }
        if (vehicleIds && vehicleIds.length) {
            // only selected vehicles
            filter.push(['in', ['get', 'locationsourceid'], ['literal', vehicleIds]]);
        }

        if (hours && hours < 12) {
            // apply hour filter from track bar
            const minTime = new Date().getTime() / 1000 - hours * 3600;
            filter.push(['>=', ['get', 'startunixtime'], minTime]);
        }

        // apply flagsFilter
        if (flagsFilter.length > 0) {
            filter.push(flagsFilter);
        }

        return filter;
    }

    static getTrackFilter(trackFilter: TracksLayerFilter): any[] {
        const filter: any[] = ['any'];
        let bitNumbers;
        switch (trackFilter) {
            case TracksLayerFilter.PLOWING:
                bitNumbers = [0, 1, 2, 3, 4];
                break;
            case TracksLayerFilter.GRANULAR_SPREADING:
                bitNumbers = [5, 6, 7, 8, 9];
                break;
            case TracksLayerFilter.LIQUID_SPREADING:
                bitNumbers = [10, 11, 12, 13, 14];
                break;
            case TracksLayerFilter.MOWING:
                bitNumbers = [15, 16, 17, 18, 19];
                break;
            case TracksLayerFilter.SWEEPING:
                bitNumbers = [20, 21, 22, 23, 24];
                break;
            case TracksLayerFilter.NONE:
            default:
                filter.push(['boolean', true]);
                return filter;
        }
        for (const bitNumber of bitNumbers) {
            filter.push(['>=', ['%', ['get', 'flags'], Math.pow(2, bitNumber + 1)], Math.pow(2, bitNumber)]);
        }
        return filter;
    }

    static getShiftGeoJsonFilter(
        trackFilter: TracksLayerFilter,
        positionFilterFrom?: number,
        positionFilterTo?: number
    ) {
        const filter: any[] = ['all'];
        if (!!positionFilterFrom) {
            filter.push(['>=', ['get', 'time_start'], positionFilterFrom]);
        }
        if (!!positionFilterTo) {
            filter.push(['<=', ['get', 'time_end'], positionFilterTo]);
        }

        let bitNumbers;
        switch (trackFilter) {
            case TracksLayerFilter.PLOWING:
                bitNumbers = [0, 1, 2, 3, 4];
                break;
            case TracksLayerFilter.GRANULAR_SPREADING:
                bitNumbers = [5, 6, 7, 8, 9];
                break;
            case TracksLayerFilter.LIQUID_SPREADING:
                bitNumbers = [10, 11, 12, 13, 14];
                break;
            case TracksLayerFilter.SPREADING:
                bitNumbers = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
                break;
            case TracksLayerFilter.MOWING:
                bitNumbers = [15, 16, 17, 18, 19];
                break;
            case TracksLayerFilter.SWEEPING:
                bitNumbers = [20, 21, 22, 23, 24];
                break;
            case TracksLayerFilter.PLOWING_MOWING_SWEEPING:
                bitNumbers = [0, 1, 2, 3, 4, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
                break;
            case TracksLayerFilter.NONE:
            default:
                return filter;
        }
        const trackTypeFilter: any[] = ['any'];
        for (const bitNumber of bitNumbers) {
            trackTypeFilter.push(['>=', ['%', ['get', 'flags'], Math.pow(2, bitNumber + 1)], Math.pow(2, bitNumber)]);
        }
        filter.push(trackTypeFilter);

        return filter;
    }

    /**
     * -2 equals none
     * -1 equals all
     * any number filters the route ids
     */
    static getRoutesFilter(
        configId: number,
        routeLayerId: number,
        configIdFilter: number,
        routeIdFilter: number[],
    ): ExpressionFilterSpecification {
        const filter: ExpressionFilterSpecification = ['all'];
        // filter only route id layer
        filter.push(['==', ['get', 'routeid'], routeLayerId]);
        filter.push(['==', ['get', 'configid'], configId]);
        if (configIdFilter) {
            filter.push(['==', ['get', 'configid'], configIdFilter]);
        }
        if (routeIdFilter && routeIdFilter.length > 0 &&
            (routeIdFilter.length > 1 || routeIdFilter[0] >= 0)
        ) {
            // selected routes
            filter.push(['in', ['get', 'routeid'], ['literal', routeIdFilter]]);
        } else if (routeIdFilter && routeIdFilter.length === 1 && routeIdFilter[0] === -2) {
            // none
            filter.push(['boolean', false]);
        } // else no filter = show all

        return filter;
    }
}

