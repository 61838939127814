import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {TokenResponse} from './model/ext-auth.class';

@Injectable({
    providedIn: 'root'
})
export class ExtAuthService {

    constructor(private http: HttpClient) { }

    verifyEsriCredentials(url: string, username: string, password: string) {
    return this.http.post<JsonApiResponse<boolean>>(
        `${environment.services.service}v1/extauth/verifyEsriCredentials`,
        {
          serverUrl: url,
          userName: username,
          password
        }
    )
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
    }

    getToken(url: string) {
        const params = new HttpParams()
            .set('url', url);
        return this.http.get<JsonApiResponse<TokenResponse>>(
            `${environment.services.service}v1/extauth/getToken`,
            { params }
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }
}
