import {OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from '../../../../../data/messages/messages.service';
import { MessageRecipientCollectionModel } from '../../../models/message-recipient-collection.model';
import { MessageModel } from '../../../models/message.model';
import { Subscription } from 'stompjs';
import { ServicesSocketService } from '../../../../../data/websocket/services-socket.service';
import { JsonApiResponse } from '../../../../../shared/models/JsonApiResponse';
import { LiveMapDataService } from '../../../services/live-map-data.service';
import { Page } from '../../../../../shared/models/Page';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ObservationsManagerService} from '../../../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../../../data/images/images-manager.service';
import {AssetStatus} from '../../../../../shared/models/asset-status';
import {HardwareTypeId} from '../../../../../shared/components/hardware-filter/hardware-filter.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessagesComponent implements OnInit, OnDestroy {
  private servicesSubscriptions = [];
  private readonly openSubscriptions = Array<Subscription>();
  @ViewChild('rightPanelTemplate') rightPanelTemplate: TemplateRef<string>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messagesService: MessagesService,
    private servicesSocketService: ServicesSocketService,
    private liveMapDataService: LiveMapDataService,
    private assetsManager: AssetsManagerService,
    private observationManager: ObservationsManagerService,
    private imageManager: ImagesManagerService,
  ) {}

  ngOnInit() {
    // show only active assets with tablet
    this.assetsManager.filterByVehicleIds(null);
    this.assetsManager.filterByStatus(
        AssetStatus.getAll().filter(status => status.id !== AssetStatus.OFFLINE.id).map(status => status.id)
    );
    this.assetsManager.filterByHardware(HardwareTypeId.TABLET);
    this.observationManager.hideAll();
    this.imageManager.filterByShift(null);

    // initialize available recipients
    this.loadRecipients();
    const shiftSub = this.servicesSocketService
      .onMessage('/shift')
      .subscribe(() => {
        setTimeout(() => this.loadRecipients(), 2000);
      });
    this.servicesSubscriptions.push(shiftSub);

    // update recent messages when a message is read
    const messageReadSub = this.servicesSocketService
      .onMessage('/message/read')
      .subscribe((event) => {
        this.liveMapDataService.handleMessageRead(event);
      });
    this.servicesSubscriptions.push(messageReadSub);

    // initialize recent messages
    this.messagesService
      .getRecentMessages()
      .then((response: JsonApiResponse<MessageModel[]>) => {
        this.liveMapDataService.sendRecentMessages(response.data);
      });

    // TODO create infinite list?
    // initialize archived messages
    this.messagesService
      .getArchivedMessages(0, 30)
      .then((response: JsonApiResponse<Page<MessageModel>>) => {
        this.liveMapDataService.sendArchivedMessages(response.data.content);
      });
  }

  ngOnDestroy(): void {
    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;

    if (this.servicesSubscriptions) {
      this.servicesSubscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  loadRecipients() {
    this.messagesService
      .getRecipients()
      .then((response: JsonApiResponse<MessageRecipientCollectionModel>) => {
        this.liveMapDataService.sendAvailableRecipients(response.data);
      });
  }
}
