import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {LiveMapDataService} from '../../../../services/live-map-data.service';
import {MessageModel} from '../../../../models/message.model';
import {MessagesService} from '../../../../../../data/messages/messages.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDeleteMessageDialogComponent} from '../dialogs/confirm-delete-message-dialog.component';

@Component({
  selector: 'app-open-message',
  templateUrl: './open-message.component.html',
  styleUrls: ['./open-message.component.scss'],
})
export class OpenMessageComponent implements OnInit {

  recentMessages: MessageModel[];
  archivedMessages: MessageModel[];
  openMessage?: MessageModel;

  allFilters = ['All', 'Read', 'Unread'];
  recipientFilter = 'All';

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private liveMapDataService: LiveMapDataService,
              private messageService: MessagesService,
              private dialog: MatDialog,
              ) {}

  ngOnInit() {
    this.liveMapDataService.recentMessages$.subscribe(messages => {
      this.recentMessages = messages;
      if (!this.openMessage) {
        const messageId = +this.activatedRoute.snapshot.queryParamMap.get('id');
        this.changeOpenMessage(messageId);
      }
    });

    this.liveMapDataService.archivedMessages$.subscribe(messages => {
      this.archivedMessages = messages;
      if (!this.openMessage) {
        const messageId = +this.activatedRoute.snapshot.queryParamMap.get('id');
        this.changeOpenMessage(messageId);
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      const messageId = +params.id;
      this.changeOpenMessage(messageId);
    });
  }

  closeMsgWindow() {
    this.router.navigate(['live-map/messages']);
  }

  changeOpenMessage(messageId: number) {
    let msg = this.recentMessages?.find(
        (message: any) => message.id === messageId
    );
    if (!msg) {
      msg = this.archivedMessages?.find(
          (message: any) => message.id === messageId
      );
    }

    this.openMessage = msg;
  }

  deleteMsg() {
    const dialogRef = this.dialog.open(ConfirmDeleteMessageDialogComponent, {
      data: { id: this.openMessage.id, name: this.openMessage.subject },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.messageService.deleteMessage(this.openMessage.id).then(() => {
          let messageIndex = this.recentMessages.findIndex(message => message.id === this.openMessage.id);
          if (messageIndex > -1) {
            this.recentMessages.splice(messageIndex, 1);
          } else {
            messageIndex = this.archivedMessages.findIndex(message => message.id === this.openMessage.id);
            if (messageIndex > -1) {
              this.archivedMessages.splice(messageIndex, 1);
            }
          }
          this.router.navigate(['live-map/messages']);
        }).catch(error => {
          console.error(error);
        });
      }
    });
  }

  changeFilter(filter: string) {
    this.recipientFilter = filter;
  }
}
