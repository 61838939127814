
export class MifFormReviewingSignaturesOption {
    public static ALWAYS_REQUIRED = new MifFormReviewingSignaturesOption('Always required', 'ALWAYS_REQUIRED');
    public static REQUIRED_ON_ISSUE = new MifFormReviewingSignaturesOption('Required if issue is identified', 'REQUIRED_ON_ISSUE');
    public static NEVER_REQUIRED = new MifFormReviewingSignaturesOption('Never required', 'NEVER_REQUIRED');

    public static options = [
        MifFormReviewingSignaturesOption.ALWAYS_REQUIRED,
        MifFormReviewingSignaturesOption.REQUIRED_ON_ISSUE,
        MifFormReviewingSignaturesOption.NEVER_REQUIRED
    ];
    static label = 'Reviewing Signatures';
    static default = MifFormReviewingSignaturesOption.ALWAYS_REQUIRED;
    static optionCode = 'REVIEWING_SIGNATURES';

    label: string;
    code: string;

    constructor(label: string, code: string) {
        this.label = label;
        this.code = code;
    }

    static fromCode(code: string) {
        const found = MifFormReviewingSignaturesOption.options.find(value => value.code === code);
        if (found == null) { throw new Error(`error parsing MifFormReviewingSignaturesOption, code = ${code}`); }
        return found;
    }
}
