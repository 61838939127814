import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {ObservationManagementService} from '../../../../data/observations/observation-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserImport} from '../../../../shared/models/User';
import {UserManagementService} from '../../../../data/users/user-management.service';


@Component({
    selector: 'app-import-users',
    templateUrl: './manage-users-import.component.html',
    styleUrls: ['./manage-users-import.component.scss'],
})
export class ImportUsersComponent {

    @Output() changePageEvent = new EventEmitter<string>();

    uiError: string;

    selectedFile: File;
    validData: UserImport[];
    invalidData: UserImport[];
    displayedColumnsValid: string[];
    displayedColumnsInvalid: string[];
    allColumns: {
        title: string;
        valid: boolean;
        invalid: boolean;
    }[] = [
        {title: 'selected', valid: true, invalid: false},
        {title: 'line', valid: true, invalid: true},
        {title: 'email', valid: true, invalid: true},
        {title: 'givenName', valid: true, invalid: true},
        {title: 'familyName', valid: true, invalid: true},
        {title: 'phoneNumber', valid: true, invalid: true},
        {title: 'isAdmin', valid: true, invalid: true},
        {title: 'sendWelcomeEmail', valid: true, invalid: false},
        {title: 'action', valid: true, invalid: false},
        {title: 'errors', valid: false, invalid: true},
    ];
    states = ImportUsersStateEnum; // alow acces from template via variable
    currentState = ImportUsersStateEnum.NOOP;

    @ViewChild('fileInput')
    fileInput: ElementRef;

    constructor(public dialog: MatDialog,
                private snackBar: MatSnackBar,
                private observationManagementService: ObservationManagementService,
                private userService: UserManagementService) {

        this.updateColumns();
    }

    handleFileInput(event: Event) {
        this.selectedFile = (event.target['files'] as FileList)[0];
    }

    upload() {
        this.currentState = ImportUsersStateEnum.WORKING;
        const formData: FormData = new FormData();
        formData.append('file', this.selectedFile, this.selectedFile.name);
        this.userService.uploadAndVerifyCsvUserImport(this.selectedFile).then((o) => {
            this.validData = o.data.filter(row => row.action !== 'NONE' && (row.errors == null || row.errors.length === 0));
            this.invalidData = o.data.filter(row => row.action === 'NONE' || row.errors != null && row.errors.length > 0);
            this.currentState = ImportUsersStateEnum.NOOP;
            // make the valid rows all selected
            this.validData.forEach(row => row.selected = true);
        }).catch(error => {
            this.uiError = error;
        }).finally(() => {
            this.currentState = ImportUsersStateEnum.NOOP;
        });
    }

    changePage(page?: string) {
        this.changePageEvent.emit(page);
    }

    cancelImport() {
        this.doCancel();
        this.changePage();
    }

    doImport() {
        this.currentState = ImportUsersStateEnum.WORKING;
        const selectedData = this.validData.filter(row => row.selected === true);
        this.userService.uploadAndSaveCsvUserImport(selectedData).then((response) => {
            this.currentState = ImportUsersStateEnum.IMPORTED;
        }).catch(error => {
            this.uiError = error;
            this.currentState = ImportUsersStateEnum.NOOP;
        });
    }

    doCancel() {
        this.currentState = ImportUsersStateEnum.WORKING;
        this.validData = [];
        this.invalidData = [];
        this.fileInput.nativeElement.value = '';
        this.selectedFile = undefined;
        this.currentState = ImportUsersStateEnum.NOOP;
    }

    importDisabled(): boolean {
        return this.validData.filter(row => row.action !== 'NONE').length === 0;
    }

    private updateColumns() {
        this.displayedColumnsValid = this.allColumns
            .filter(col => col.valid === true)
            .map(col => col.title);

        this.displayedColumnsInvalid = this.allColumns
            .filter(col => col.invalid === true)
            .map(col => col.title);
    }

}

enum ImportUsersStateEnum {
    NOOP,
    WORKING,
    IMPORTED
}
