import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MultiSelectComponent, MultiSelectFilter, Operator} from '../../../../../../shared/components/multi-select-component';
import {VehicleCategoryModel} from '../../../../../../shared/models/vehicle';
import {ActivatedRoute, Router} from '@angular/router';
import {VehiclesService} from '../../../../../../data/vehicles/vehicles.service';
import {ToastService} from '../../../../../../shared/services/toast.service';

@Component({
  selector: 'app-vehicle-group-filter-button',
  templateUrl: './vehicle-group-filter-button.component.html',
  styleUrls: ['./vehicle-group-filter-button.component.scss']
})
export class VehicleGroupFilterButtonComponent extends MultiSelectComponent<VehicleCategoryModel> implements OnInit {

  SETTINGS_KEY = 'selectedVehicleGroupIds';
  OPERATOR_SETTINGS_KEY = null;
  URL_PARAM_NAME = 'vehicleGroupIds';
  URL_OP_NAME = null;
  USE_FULLY_CHECKED_AS_DEFAULT = true;

  @Input() origin;
  @Input() disabled = false;
  @Output() filterChanged = new EventEmitter<MultiSelectFilter<VehicleCategoryModel>>();

  filteredItems: VehicleCategoryModel[];

  constructor(
      protected router: Router,
      protected route: ActivatedRoute,
      private vehicleService: VehiclesService,
      private toast: ToastService,
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.operator = Operator.IS;
    this.vehicleService.getVehicleCategories().toPromise().then(response => {
      this.initComponent(response.data);
      this.filteredItems = this.selected?.slice();
    }).catch(error => {
      console.log(error);
      this.toast.longer('Error while loading vehicle groups!');
    });
  }

  getLabel(item: VehicleCategoryModel): string {
    return !!item.title ? item.title : item.name;
  }

  openMenu() {
    // not implemented
  }

  getSelectedList(): string {
    if (this.filteredItems.length > 3) {
      return 'Multiple Vehicle Groups Selected';
    } else {
      return this.filteredItems.map(group => this.getLabel(group)).join(', ');
    }
  }

  onApply() {
    this.filteredItems = this.selected?.slice();
  }
}
