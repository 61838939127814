<div class="vertical-layer-container">
    <div class="layer">
        <div class="layer-checkbox" *ngIf="mapLayer.public">
            <mat-checkbox
                    [disabled]="mapLayer.new"
                    [(ngModel)]="mapLayer.enabled"
                    (ngModelChange)="toggle()"
            >
            </mat-checkbox>
        </div>
        <div class="layer-fields">

            <div class="field-with-right-buttons wide">
                <mat-form-field appearance="outline" color="accent" class="no-hint" >
                    <mat-label>Layer Type</mat-label>
                    <mat-select [(ngModel)]="mapLayer.currentType" (ngModelChange)="changed()">
                        <mat-option [value]="'RASTER'">raster</mat-option>
                        <mat-option [value]="'VECTOR'">vector</mat-option>
                    </mat-select>
                </mat-form-field>
                <div>
                    <button mat-icon-button
                            color="accent"
                            *ngIf="mapLayer.dirty"
                            (click)="save()"
                            [matTooltip]="'Save'"
                    >
                        <mat-icon>save</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="accent"
                            *ngIf="!mapLayer.new && mapLayer.dirty"
                            (click)="cancel()"
                            [matTooltip]="'Cancel'"
                    >
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="accent"
                            (click)="moveLayerDown()"
                            [matTooltip]="'Move down in order'"
                            class="action-button">
                        <mat-icon>south</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="accent"
                            (click)="deleteMapLayer()"
                            [matTooltip]="'Remove layer'"
                            class="action-button">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>

            <mat-form-field appearance="outline" color="accent" class="wide no-hint">
                <mat-label>Name</mat-label>
                <input matInput
                       autocomplete="off"
                       [(ngModel)]="mapLayer.currentName"
                       (ngModelChange)="changed()"
                       #ngName="ngModel"
                       required
                       cdkFocusInitial>
                <mat-error *ngIf="ngName.invalid && ngName.errors.required">
                    Name is required.
                </mat-error>
            </mat-form-field>

            <div *ngIf="mapLayer.type === MapLayerType.ROUTE">
                The layer is imported by Route Management.
            </div>

            <mat-form-field appearance="outline" color="accent" class="wide no-hint"
                            *ngIf="mapLayer.type === MapLayerType.RASTER">
                <mat-label>URL</mat-label>
                <input matInput
                       autocomplete="off"
                       [(ngModel)]="mapLayer.currentUrl"
                       (ngModelChange)="changed()"
                       #ngUrl="ngModel"
                       required
                >
                <mat-error *ngIf="ngUrl.invalid && ngUrl.errors.required">
                    URL is required.
                </mat-error>
                <mat-hint>
                    Append '/tile/{{ '{z}/{y}/{x}' }}' to the end of your URL string for standard xyz pattern raster file loading
                </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" color="accent" class="no-height-mod"
                            *ngIf="mapLayer.type === MapLayerType.VECTOR">
                <mat-label>JSON configuration</mat-label>
                <textarea matInput
                          autocomplete="off"
                          [(ngModel)]="mapLayer.currentConfiguration"
                          (ngModelChange)="changed()"
                          #ngConfig="ngModel"
                          class="json-text-area"
                          required
                >
                </textarea>
                <mat-error *ngIf="ngConfig.invalid && ngConfig.errors.required">
                    JSON Configuration is required.
                </mat-error>
            </mat-form-field>

            <div class="layer-form" *ngIf="mapLayer.currentType === MapLayerType.RASTER">
                <mat-checkbox [(ngModel)]="mapLayer.useCredentials"
                              (change)="onCredentialsToggle()"
                              (ngModelChange)="changed()"
                >
                    Use credentials for the server
                </mat-checkbox>
                <div class="credentials-wrapper" *ngIf="mapLayer.useCredentials">
                    <mat-form-field appearance="outline" color="accent" class="credentials-input">
                        <mat-label>Username</mat-label>
                        <input matInput
                               [(ngModel)]="mapLayer.currentUsername"
                               (ngModelChange)="changed()"
                               autocomplete="off"/>
                    </mat-form-field>
                    <mat-form-field  appearance="outline" color="accent" class="credentials-input">
                        <mat-label>Password</mat-label>
                        <input matInput
                               [(ngModel)]="mapLayer.currentPassword"
                               (ngModelChange)="changed()"
                               autocomplete="off"
                               type="password"/>
                    </mat-form-field>
                    <button mat-icon-button
                            color="accent"
                            [matTooltip]="'Test connection'"
                            (click)="testCredentials()"
                            style="margin-bottom: 15px"
                    >
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
