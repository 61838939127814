<div class="live-map-filter">
    <button mat-button
            class="button-wrapper mat-elevation-z2"
            [matMenuTriggerFor]="menu"
            [disabled]="disabled"
            [ngClass]="{'active': !!filteredItems && !disabled}"
    >
        <div class="filter-button" *ngIf="!filteredItems">
            <mat-icon>
                directions_car
            </mat-icon>
            <span>Status</span>
            <mat-icon>
                arrow_drop_down
            </mat-icon>
        </div>
        <div class="filter-button" *ngIf="!!filteredItems">
            <mat-icon>
                check
            </mat-icon>
            <span>{{ getSelectedList() }}</span>
            <mat-icon>
                arrow_drop_down
            </mat-icon>
        </div>
    </button>
</div>

<mat-menu #menu="matMenu">
    <div class="live-map-filter-menu-wrapper">
        <ng-container *ngFor="let item of items">
            <mat-checkbox [checked]="isChecked(item)"
                          (change)="check($event, item)"
                          (click)="$event.stopPropagation()"
                          class=""
            >
                {{ item.label }}
            </mat-checkbox>
        </ng-container>
        <mat-divider></mat-divider>
        <div class="menu-footer">
            <button
                    mat-button
                    color="accent"
                    class="ml-10"
                    (click)="setFilter(null, null); onApply();"
            >
                Reset
            </button>
            <button
                    mat-button
                    color="accent"
                    class="mr-10"
                    (click)="apply()"
            >
                Apply
            </button>
        </div>
    </div>
</mat-menu>
