<h1 mat-dialog-title>
    Map Layers Preview
</h1>

<mat-dialog-content>
    <div class="map-container">
        <div #map class="map"></div>
        <div *ngIf="loaded">
            <ng-container *ngFor="let mapLayer of mapLayers">
                <mat-checkbox [(ngModel)]="mapLayer.checked"
                              (ngModelChange)="toggleMapLayer(mapLayer)"
                              [ngModelOptions]="{standalone: true}"
                              [disabled]="mapLayer.disabledOnPreview"
                              class="layer-control"
                >
                    {{ mapLayer.name }}
                </mat-checkbox>
            </ng-container>
        </div>
        <div *ngIf="errors.length > 0">
            <ng-container *ngFor="let error of errors">
                <span>{{ error }}</span>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="" cdkFocusInitial>
        Close
    </button>
</mat-dialog-actions>
