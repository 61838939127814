
export class MifNodeTypes {

  public static FORM = new MifNodeTypes('form', 'FORM');
  public static SECTION = new MifNodeTypes('section', 'SECTION');
  public static GROUP = new MifNodeTypes('group', 'GROUP');
  public static QUESTION = new MifNodeTypes('question', 'QUESTION');

  label: string;
  code: string;

  constructor(label: string, code: string) {
    this.label = label;
    this.code = code;
  }
}
