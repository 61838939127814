import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../../../http.error.handler';
import {JsonApiResponse} from '../../../models/JsonApiResponse';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionMetricService {

  constructor(private http: HttpClient) {
  }

  closestSessionMetricEvent(shiftId: number, vehicleId: number, metricId: number, unixtime: number = Date.now() / 1000) {
    const request: SessionMetricEventModel = {
      locationSourceId: vehicleId,
      sessionKey: shiftId,
      metricId,
      unixtime
    };
    return this.http.post<JsonApiResponse<SessionMetricEventModel>>(
      `${environment.services.location}v1/session-metric/event/closest`,
      request,
      {
        headers: {Accept: 'application/json'}
      }
    )
      .pipe(
        catchError((err, caught) => {
          if (err.status === 500 && err.error && err.error.error && err.error.error.indexOf('404 NOT_FOUND') >= 0) {
            const other = new JsonApiResponse<SessionMetricEventModel>();
            other.data = new SessionMetricEventModel();
            return of(other);
          } else {
            return HttpErrorHandler.handleError(err);
          }
        })
      ).toPromise<JsonApiResponse<SessionMetricEventModel>>();
  }

}


export class SessionMetricEventModel {
  locationSourceId: number;
  sessionKey: number;
  unixtime: number;
  metricValue?: number;
  metricId: number;
  metricUnit?: string;
}

export class MetricType {
  static GRANULAR_RATE = 1;
  static LIQUID_RATE = 2;
  static ROAD_TEMPERATURE = 3;
}
