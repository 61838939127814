import {Component, OnInit} from '@angular/core';
import {MapStyles} from '../../../../configuration/map-styles';
import {SettingsService} from '../../../../configuration/settings.service';

@Component({
  selector: 'app-route-status-mode-legend',
  templateUrl: './route-status-mode-legend.component.html',
  styleUrls: ['./route-status-mode-legend.component.scss']
})
export class RouteStatusModeLegendComponent implements OnInit {

  displaySettings = false;
  threshold: number;
  thresholdIndex: number;
  thresholdValues: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 24, 30, 36, 48, 72];
  MapStyles = MapStyles;

  constructor(
      private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.threshold = +this.settingsService.getStringValue(SettingsService.ROUTE_STATUS_THRESHOLD);
    this.thresholdIndex = this.thresholdValues.indexOf(this.threshold) + 1;
  }

  toggleSettings() {
    this.displaySettings = !this.displaySettings;
  }

  // we do not have access to component members here, so we pass it via parameter
  formatLabel(values: number[]) {
    return (index: number): string => {
      return `${values[index - 1]}h`;
    };
  }

  onThresholdChange(e) {
    const threshold = +this.settingsService.getStringValue(SettingsService.ROUTE_STATUS_THRESHOLD);
    if (threshold !== this.thresholdValues[this.thresholdIndex - 1]) {
      this.threshold = this.thresholdValues[this.thresholdIndex - 1];
      this.settingsService.setStringValue(SettingsService.ROUTE_STATUS_THRESHOLD, String(this.threshold));
    }
  }
}
