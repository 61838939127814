import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import moment from 'moment';
import {LatLngModel} from '../../shared/models/lat.lng.model';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {AddressVisit} from '../../shared/models/AddressLookup';
import {environment} from '../../../environments/environment';
import {HttpErrorHandler} from '../../http.error.handler';
import {BackendLocationModelWithTime} from '../../shared/models/location.model';
import {Moment} from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class AddressLookupService {

  constructor(private http: HttpClient) { }

  findLocationHistory(
      location: LatLngModel,
      maxAge: Moment,
      minAge: Moment = null
  ) {
    let params: HttpParams = new HttpParams()
      .set('latitude', String(location.lat))
      .set('longitude', String(location.lng))
      .set('maxAge', !!maxAge ? maxAge.toISOString() : moment().startOf('year').toISOString());
    if (minAge != null) {
      params = params.set('minAge', minAge.toISOString());
    }
    return this.http.get<JsonApiResponse<AddressVisit[]>>(`${environment.services.service}v1/address-lookup/locationVisits`, {params})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  findPassPoints(time: Date, shiftId: number) {
    const params: HttpParams = new HttpParams()
      .set('time', moment(time).toISOString())
      .set('shiftId', String(shiftId));
    return this.http.get<JsonApiResponse<BackendLocationModelWithTime[]>>(
        `${environment.services.service}v1/address-lookup/passPoints`,
        {params}
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }
}
