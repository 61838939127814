<div class="input-row">

    <mat-form-field class="device-model" appearance="outline" color="accent">
        <mat-label>Make/Model</mat-label>
        <mat-select [(ngModel)]="digitalSpreader.model"
                    (ngModelChange)="change()"
                    #ngDeviceModel="ngModel"
                    required>
            <mat-option [value]="DeviceModel.SPREADER_CPS_CIRRUS">CPS Cirus</mat-option>
            <mat-option [value]="DeviceModel.SPREADER_CPS_FREEDOM">CPS Freedom</mat-option>
            <mat-option [value]="DeviceModel.SPREADER_FORCE_AMERICA_GENERIC">Force America (generic)</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="input-row" *ngIf="!!digitalSpreader.model">

    <div class="checkbox-wrapper">
        <mat-checkbox [(ngModel)]="digitalSpreader.settings.readGranular"
                      (change)="onReadGranularCheckboxChange($event)"
                      (ngModelChange)="change()"
        >
            Read Granular Information
        </mat-checkbox>

        <mat-form-field class="device-rate"
                        appearance="outline"
                        color="accent"
                        *ngIf="!!digitalSpreader.model && digitalSpreader.settings.readGranular">
            <mat-label>Granular max rate</mat-label>
            <input matInput
                   type="number"
                   autocomplete="off"
                   [(ngModel)]="digitalSpreader.settings.granularMaxRate"
                   (ngModelChange)="change()"
            >
            <span class="units">
                {{ 'granular' | rateUnit: configuration.useMetricSystem }}
            </span>
        </mat-form-field>

        <mat-form-field class="device-rate"
                        appearance="outline"
                        color="accent"
                        *ngIf="!!digitalSpreader.model && digitalSpreader.settings.readGranular">
            <mat-label>Granular blast rate</mat-label>
            <input matInput
                   type="number"
                   autocomplete="off"
                   [(ngModel)]="digitalSpreader.settings.granularBlastRate"
                   (ngModelChange)="change()"
            >
            <span class="units">
                {{ 'granular' | rateUnit: configuration.useMetricSystem }}
            </span>
        </mat-form-field>
    </div>

    <div class="checkbox-wrapper">
        <mat-checkbox [(ngModel)]="digitalSpreader.settings.readLiquid"
                      (change)="onReadLiquidCheckboxChange($event)"
                      (ngModelChange)="change()"
        >
            Read Liquid Information
        </mat-checkbox>

        <mat-form-field class="device-rate"
                        appearance="outline"
                        color="accent"
                        *ngIf="!!digitalSpreader.model && digitalSpreader.settings.readLiquid">
            <mat-label>Liquid max rate</mat-label>
            <input matInput
                   type="number"
                   autocomplete="off"
                   [(ngModel)]="digitalSpreader.settings.liquidMaxRate"
                   (ngModelChange)="change()"
            >
            <span class="units">
                {{ 'liquid' | rateUnit: configuration.useMetricSystem }}
            </span>
        </mat-form-field>
    </div>
</div>

