<div class="warnings" (click)="onWarningsClick()" *ngIf="!!warningsLabel">
    {{ warningsLabel }}
</div>
<div class="weather-state"
     (click)="onWeatherClick()"
     [matTooltip]="stationName || 'Invalid observation data'"
     *ngIf="!!configuration && temperatureInCelsius !== undefined">
    <div class="temperature">
        <span *ngIf="!configuration.useMetricSystem">{{ getTemperature() }}°F</span>
        <span *ngIf="configuration.useMetricSystem">{{ getTemperature() }}°C</span>
    </div>
    <div class="weather-icon" *ngIf="!!weatherIcon && temperatureInCelsius !== null">
        <mat-icon>
            {{ weatherIcon }}
        </mat-icon>
    </div>
</div>
