<h1 mat-dialog-title>
  Delete Message
</h1>

<mat-dialog-content>
  <p>
    Do you really want to delete message <i>{{ data.name }}</i>?
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cancel
  </button>
  <button mat-button [mat-dialog-close]="data.id" cdkFocusInitial>
    Delete
  </button>
</mat-dialog-actions>
