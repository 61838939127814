import {LatLngModel} from '../../../shared/models/lat.lng.model';
import {CameraConfiguration, HardwareConfiguration, VehicleModel} from '../../../shared/models/vehicle.model';
import {RouteAssignment, RouteAssignmentStatus} from '../../../shared/models/route-assignment';
import {ShiftState} from '../../../shared/models/shift.model';
import {DeviceInfo} from '../../../shared/models/device-info';

export class Asset {

    shiftStatus: ShiftState;
    routeAssignmentStatus: RouteAssignmentStatus;
    currentRoute: RouteAssignment;
    time: Date;
    shiftStartTime: Date;
    shiftDuration: number;
    distanceDriven: number;
    location: LatLngModel;
    speed: number;
    currentHeading: number;
    flags: number;
    driverId: number;
    driverName: string;
    driverIsGuest: boolean;
    vehicleType: string;
    vehicleHardwareConfiguration: HardwareConfiguration;
    cameraConfiguration: CameraConfiguration;
    vehicleLmuId?: string;
    hasNoTablet: boolean;
    digitalGranularStatus: DigitalSpreaderStatus;
    digitalLiquidStatus: DigitalSpreaderStatus;
    deviceInfo?: DeviceInfo;

    constructor(
        public id: number,
        public shiftId: number,
        public name: string,
        public vehicleTypeId: number,
    ) {
        this.vehicleType = 'Unknown vehicle group';
        this.driverName = 'Unknown driver';
        this.driverIsGuest = false;
        this.shiftStatus = ShiftState.ENDED;
        this.routeAssignmentStatus = RouteAssignmentStatus.NO_ASSIGNMENT;
        this.time = new Date();
        this.hasNoTablet = true;
    }

    setDriverName(driverName: string, vehicle: VehicleModel): void {
        this.driverName = vehicle?.hasNoTablet === true ? 'Unknown driver' : driverName;
    }
}

export class DigitalSpreaderStatus {
    controllerCurrentRate: number;
    controllerMaxRate: number;
}
