<button mat-menu-item
        #matTriggerIs="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{operator: Operator.IS}"
        (mouseenter)="onOperatorEnter(Operator.IS)"
        (click)="setOperator(Operator.IS)"
>
    IS
</button>
<button mat-menu-item
        #matTriggerIsNot="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerData]="{operator: Operator.IS_NOT}"
        (mouseenter)="onOperatorEnter(Operator.IS_NOT)"
        (click)="setOperator(Operator.IS_NOT)"
>
    IS NOT
</button>

<mat-menu #menu="matMenu">
    <div class="menu-wrapper">
        <ng-container *ngFor="let item of items">
            <mat-checkbox [checked]="isChecked(item)"
                          (change)="check($event, item)"
                          (click)="$event.stopPropagation()"
                          class=""
            >
                {{ item.name }}
            </mat-checkbox>
        </ng-container>
        <mat-divider></mat-divider>
        <div class="menu-footer">
            <button
                    mat-button
                    color="accent"
                    class="ml-10"
                    (click)="setFilter([], null)"
            >
                Clear All
            </button>
            <button
                    mat-button
                    color="accent"
                    class="mr-10"
                    (click)="apply()"
            >
                Apply
            </button>
        </div>
    </div>
</mat-menu>

