import {Injectable} from '@angular/core';
import {ShiftModel, ShiftState} from '../../shared/models/shift.model';
import {ShiftsService} from './shifts.service';
import {BehaviorSubject, ReplaySubject, Unsubscribable} from 'rxjs';
import {ServicesSocketService} from '../websocket/services-socket.service';
import {ListWithUpdates} from '../../shared/models/list-with-updates.class';
import {ConfigurationModel} from '../../shared/models/configuration.model';
import {ShiftFilterUpdate} from '../../shared/models/shift-filter';
import {Router} from '@angular/router';
import {LiveMapTab} from '../../pages/live-map/models/live-map-tabs';

export class ShiftEvent {
  type: ShiftEventType;
  shiftId: number;
  vehicleId: number;
  driverId: number;
  state: ShiftState;
}

export enum ShiftEventType {
  START = 'start',
  END = 'end',
  UPDATE = 'update',
}


@Injectable({
  providedIn: 'root'
})
export class ShiftsManagerService {

  static readonly MAP_SOURCE = 'map';
  static readonly PANEL_SOURCE = 'panel';

  private isInitialized = false;

  private readonly shiftsMap = new Map<number, ShiftModel>();
  private readonly shiftsByVehicleIdMap = new Map<number, ShiftModel>();
  private readonly recentShiftsMap = new Map<number, ShiftModel>();

  readonly activeShifts$ = new ReplaySubject<ListWithUpdates<ShiftModel>>(1);
  shiftEventSubscription: Unsubscribable;

  private highlightedShiftSource = new BehaviorSubject<ShiftFilterUpdate>(new ShiftFilterUpdate(null, null));
  readonly highlightedShift$ = this.highlightedShiftSource.asObservable();

  constructor(
      private router: Router,
      private shiftsService: ShiftsService,
      private servicesSocketService: ServicesSocketService,
  ) {
  }

  public init(configuration: ConfigurationModel): Promise<void> {
    if (this.isInitialized) {
      throw Error('The ShiftsManagerService has already been initialized.');
    }
    const that = this;

    const initializedPromise = new Promise((resolve, reject) => {
      this.shiftEventSubscription = this.servicesSocketService.onMessage('/shift')
        .subscribe((e: ShiftEvent) => {
          const shift = new ShiftModel(e.shiftId, e.vehicleId, e.driverId, e.state);
          if (e.type === ShiftEventType.START) {
            that.handleShiftStart(shift);
          } else if (e.type === ShiftEventType.END) {
            that.handleShiftEnd(shift);
          } else if (e.type === ShiftEventType.UPDATE) {
            that.handleShiftUpdate(shift);
          }
        });
      this.shiftsService.getActiveShifts().toPromise().then(result => {
        this.handleActiveShifts(result.data);
        resolve(true);
      }).catch(e => {
        reject(e);
      });
    });

    // get recent shifts
    const fromDate =  new Date(new Date().getTime() - configuration.lookBackPeriod * 60 * 60 * 1000);
    this.shiftsService.getCompleteShifts(
        fromDate, // by default 12 hours
        null,
    ).then(response => {
      response.data.forEach(recentShift => {
        this.recentShiftsMap.set(recentShift.id, new ShiftModel(recentShift.id, recentShift.vehicleId, recentShift.driverId));
      });
    }).catch(error => {
      console.error(error);
    });

    return initializedPromise.then(() => {
      this.isInitialized = true;
    });
  }

  handleShiftEnd(shift: ShiftModel) {
    const shiftEnded = this.shiftsMap.get(shift.id);
    if (shiftEnded) {
      this.recentShiftsMap.set(shiftEnded.id, shiftEnded);
    }
    this.shiftsMap.delete(shift.id);
    this.shiftsByVehicleIdMap.delete(shift.vehicleId);
    const update = new ListWithUpdates<ShiftModel>(
      [...this.shiftsMap.values()],
      [],
      [shift]
    );
    this.notifyActiveShiftsChanged(update);
  }

  handleShiftStart(shift: ShiftModel) {
    this.shiftsMap.set(shift.id, shift);
    this.shiftsByVehicleIdMap.set(shift.vehicleId, shift);
    const update = new ListWithUpdates<ShiftModel>(
      [...this.shiftsMap.values()],
      [shift]
    );
    this.notifyActiveShiftsChanged(update);
  }

  handleShiftUpdate(shift: ShiftModel) {
    // ignore update if the shift is not active
    if (this.shiftsMap.has(shift.id)) {
      this.shiftsMap.set(shift.id, shift);
      this.shiftsByVehicleIdMap.set(shift.vehicleId, shift);
      this.notifyActiveShiftsChanged(
          new ListWithUpdates<ShiftModel>(
              [...this.shiftsMap.values()],
              [],
              [],
              [shift],
          )
      );
    }
  }

  notifyActiveShiftsChanged(update: ListWithUpdates<ShiftModel>) {
    this.activeShifts$.next(update);
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    this.shiftEventSubscription.unsubscribe();

    this.shiftsMap.clear();
    this.shiftsByVehicleIdMap.clear();

    this.isInitialized = false;
  }

  private handleActiveShifts(activeShifts: ShiftModel[]) {
    this.shiftsMap.clear();
    this.shiftsByVehicleIdMap.clear();

    activeShifts.forEach((shift: ShiftModel) => {
      this.shiftsMap.set(shift.id, shift);
      this.shiftsByVehicleIdMap.set(shift.vehicleId, shift);
    });
    this.notifyActiveShiftsChanged(
        new ListWithUpdates<ShiftModel>([...this.shiftsMap.values()])
    );
  }

  public getActiveShiftByVehicleId(vehicleId: number): ShiftModel | null {
    return this.shiftsByVehicleIdMap.get(vehicleId);
  }

  public hasActiveShift(vehicleId: number): boolean {
    return this.shiftsByVehicleIdMap.has(vehicleId);
  }

  forceEndShiftForVehicle(shiftId: number) {
    this.shiftsService.forceShiftEnd(shiftId);
  }

  public isShiftActive(shiftId: number) {
    return this.shiftsMap.get(shiftId) !== undefined;
  }

  public highlightShift(shiftId: number, source: string) {
    // get a shift from active shift map, or from recent
    const activeShift = this.shiftsMap.get(shiftId);
    this.highlightedShiftSource.next(
        new ShiftFilterUpdate(activeShift ? activeShift : this.recentShiftsMap.get(shiftId), source)
    );
    if (source === ShiftsManagerService.MAP_SOURCE) {
      this.router.navigate(['/live-map', LiveMapTab.ASSETS, activeShift.vehicleId]);
    }
  }
}
