import { Injectable } from '@angular/core';
import {SettingsService, TracksLayerType} from '../../../../configuration/settings.service';
import {Subscription} from 'rxjs';
import {LineLayerSpecification} from 'maplibre-gl';
import {MapLayersManager} from '../map-layers-manager';
import {HttpClient} from '@angular/common/http';
import {RoadStatusService} from './road-status.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {LocationHistoryService} from './location-history.service';
import {TrackStyles} from '../../../../configuration/model/TrackStyles';

@Injectable({
  providedIn: 'root'
})
export class RoadStatusCoverageLayerService {

  static readonly LAYER_ID_PREFIX = 'road-segments-coverage';

  private mapLayersManager: MapLayersManager;
  private lineLayers: LineLayerSpecification[] = [];

  private isEnabled: boolean;
  private locationHistoryVisibility = false;
  private visibleBySettings = false;
  private trackStyles: TrackStyles;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private configurationService: ConfigurationService,
              private locationHistoryService: LocationHistoryService,
  ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.trackStyles = this.configurationService.trackStyles;
    this.addLayers();

    if (this.isEnabled) {
      this.connectToManager();
    }
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
  }

  connectToManager() {
    // handle visibility from settings
    const settingsChangedSubscription = this.settingsService.settingsChangedObservable.subscribe(
        (newSettings) => {
        if (newSettings.key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
          this.visibleBySettings = newSettings.value === TracksLayerType.COVERAGE;
          this.handleLayerVisibilityChange();
        }
      });
    this.openSubscriptions.push(settingsChangedSubscription);

    // handle visibility based on route
    const locationHistoryVisibilitySubscription = this.locationHistoryService.visibility$.subscribe(visible => {
      this.locationHistoryVisibility = visible;
      this.handleLayerVisibilityChange();
    });
    this.openSubscriptions.push(locationHistoryVisibilitySubscription);

    // handle style change
    const trackStylesSubscription = this.configurationService.trackStylesChangedSubject.subscribe(styles => {
      this.handleStyleChange(styles);
    });
    this.openSubscriptions.push(trackStylesSubscription);
  }

  addLayers() {
    const source = this.mapLayersManager.getGeoJsonSource(RoadStatusService.ROAD_SEGMENTS_SOURCE_ID);
    if (source === undefined || source === null) {
      console.error('Road Segments Source is null. This should not happen!');
    }

    // add layers
    const tracksLayerType = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY);
    this.visibleBySettings = tracksLayerType === TracksLayerType.COVERAGE;
    const isVisible = this.isEnabled && this.getVisibilityValue() ? 'visible' : 'none';
    this.getLineLayers().forEach(layer => {
      layer.layout.visibility = isVisible;
      this.lineLayers.push(layer);
      this.mapLayersManager.addLayer(layer);
    });
  }

  private handleLayerVisibilityChange() {
    this.lineLayers.forEach(lineLayer => {
      this.mapLayersManager.setLayerVisibility(
          lineLayer.id,
          this.getVisibilityValue(),
      );
    });
  }

  private handleStyleChange(styles: TrackStyles) {
    this.trackStyles = styles;
    this.lineLayers.forEach(lineLayer => {
      const layerId = lineLayer.id;
      const trackStyle = this.getTrackStyle(layerId);
      this.mapLayersManager.setLayerPaintProperty(
          layerId,
          'line-color',
          trackStyle.color,
      );
      this.mapLayersManager.setLayerPaintProperty(
          layerId,
          'line-opacity',
          trackStyle.opacity,
      );
      this.mapLayersManager.setLayerPaintProperty(
          layerId,
          'line-blur',
          trackStyle.blur,
      );
      this.mapLayersManager.setLayerPaintProperty(
          layerId,
          'line-width',
          trackStyle.width,
      );
      this.mapLayersManager.setLayerPaintProperty(
          layerId,
          'line-dasharray',
          !!trackStyle.dasharray ? ['literal',  trackStyle.dasharray] : undefined,
      );
    });
  }

  private getLineLayers(): LineLayerSpecification[] {
    return [1, 2, 3, 4, 5].map(layerNumber => {
      const layerId = `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-${layerNumber}`;
      const trackStyle = this.getTrackStyle(layerId);
      return {
        id: layerId,
        type: 'line',
        source: RoadStatusService.ROAD_SEGMENTS_SOURCE_ID,
        filter: this.getCoverageLayerFilterExpression(layerNumber),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': trackStyle.color,
          'line-opacity': trackStyle.opacity,
          'line-blur': trackStyle.blur,
          'line-width': trackStyle.width,
          'line-dasharray': ['literal', !!trackStyle.dasharray ? trackStyle.dasharray : [10]],
        }
      } as LineLayerSpecification;
    });
  }

  private getCoverageLayerFilterExpression(layerNumber: number) {
    switch (layerNumber) {
      case 1:
        return ['==', ['get', 'passcount'], 1];
      case 2:
        return ['all', ['>', ['get', 'passcount'], 1], ['<=', ['get', 'passcount'], 4]];
      case 3:
        return ['all', ['>', ['get', 'passcount'], 4], ['<=', ['get', 'passcount'], 7]];
      case 4:
        return ['all', ['>', ['get', 'passcount'], 7], ['<=', ['get', 'passcount'], 10]];
      default:
        return ['>', ['get', 'passcount'], 10];
    }
  }

  private getVisibilityValue(): boolean {
    return this.visibleBySettings && this.locationHistoryVisibility;
  }

  private getTrackStyle(layerId: string) {
    switch (layerId) {
      case `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-1`:
        return this.trackStyles.roadStatusCoverageLevel1;
      case `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-2`:
        return this.trackStyles.roadStatusCoverageLevel2;
      case `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-3`:
        return this.trackStyles.roadStatusCoverageLevel3;
      case `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-4`:
        return this.trackStyles.roadStatusCoverageLevel4;
      case `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-5`:
        return this.trackStyles.roadStatusCoverageLevel5;
      default:
        console.error(`Getting Track Style for non-existent Layer ID: ${layerId}!`);
        return null;
    }
  }
}
