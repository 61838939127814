import {Component} from '@angular/core';


@Component({
  selector: 'app-manage-dvir',
  templateUrl: './manage-dvir.component.html',
  styleUrls: ['./manage-dvir.component.scss'],
})
export class ManageDvirComponent {

  isLoading = true;
  uiError: string;

  constructor() {
  }


}
