import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  CartegraphConfiguration,
  CartegraphImportTaskStatus,
  CgImportTask
} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSelectChange} from '@angular/material/select';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-manage-cartegraph-tasks-import',
  templateUrl: './cartegraph-tasks-import.component.html',
  styleUrls: ['./cartegraph-tasks-import.component.scss'],
})
export class CartegraphTasksImportComponent implements OnInit, AfterViewInit {

  @Input()
  configuration: CartegraphConfiguration;

  isLoading = false;
  uiError: string;

  CartegraphImportTaskStatus = CartegraphImportTaskStatus;

  displayedColumns: string[] = [
    'id', 'created', 'finished', 'taskStatus', 'taskMessage'];

  taskStatuses: { id: string, label: string }[] = [
    { id: CartegraphImportTaskStatus.IN_PROGRESS, label: 'In progress'},
    { id: CartegraphImportTaskStatus.SUCCESS, label: 'Success'},
    { id: CartegraphImportTaskStatus.FAIL, label: 'Failed'}
  ];

  taskStatus: { id: string, label: string }[] = this.taskStatuses; // default to success to see something
  tasks: MatTableDataSource<CgImportTask> = new MatTableDataSource<CgImportTask>([]);

  sortActive = 'created';
  sortDirection: SortDirection = 'desc';

  // used in *ngIf, requires to be loaded dynamically
  @ViewChild(MatSort, {static: false}) set sorter(sort: MatSort) {
    if (sort) {
      this.tasks.sort = sort;
    }
  }

  // used out of *ngIf can be injected
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  itemCount = 0;
  pageSize = 20;
  pageIndex = 0;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.tasks.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getTasks();
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  getTasks(page: number = 0) {
    this.isLoading = true;
    this.uiError = null;
    const sortField = this.tasks?.sort?.active ? `${this.tasks?.sort?.active},${this.tasks?.sort?.direction}`
      : `${this.sortActive},${this.sortDirection}`;
    const statuses = this.taskStatus.map( i => i.id);
    this.cartegraphManagementService
      .getImportTaskList(page, this.pageSize, statuses, sortField).toPromise().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.tasks.data = response.data.content;
        this.pageSize = response.data.size;
        this.pageIndex = response.data.number;
        this.itemCount = response.data.totalElements;
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  onPage(pageEvent: PageEvent) {
    // decide to reset sorting here
    this.getTasks(pageEvent.pageIndex);
  }

  openCgDetail(cgOid: string) {
    // this must be based on OID not cgField
    window.open(`${this.configuration.baseUrl}/#FlexUI/Edit/cgTasksClass/${cgOid}`, '_blank');
  }

  processingStatusChange(changeEvent: MatSelectChange) {
    this.taskStatus = changeEvent.value;
    this.getTasks();
  }

  refresh() {
    this.getTasks(this.pageIndex);
  }

  sortChange(sort: Sort) {
    this.sortActive = sort.active;
    this.sortDirection = sort.direction;
    this.getTasks();
  }

  messageDetail(task: CgImportTask) {
    if (task.taskMessage?.trim().length > 0) {
      const dialogRef = this.dialog.open(CartegraphTasksImportDialogComponent, {
        width: '800px',
        height: '800px',
        data: task.taskMessage,
      });
    }
  }

}

@Component({
  selector: 'app-manage-cartegraph-tasks-import-msg',
  template: `<h1 mat-dialog-title>Detail</h1>
  <mat-dialog-content class="dialog">
      {{ data }}
  </mat-dialog-content>
  <mat-dialog-actions>
      <button mat-button mat-dialog-close color="accent">Close</button>
  </mat-dialog-actions>`
})
export class CartegraphTasksImportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CartegraphTasksImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
  }
}
