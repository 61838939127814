import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import {CommonModule} from '@angular/common';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CallbackComponent } from './components/callback/callback.component';

// Material
@NgModule({
  declarations: [
    UnauthorizedComponent,
    CallbackComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [],
})
export class PublicModule {}
