import {MifNodeDto} from '../dto/MifNodeDto';
import {MifNodeTypes} from './node-types/MifNodeTypes';
import {MifSectionType} from './node-types/MifSectionType';
import {MifGroupType} from './node-types/MifGroupType';
import {MifQuestionType} from './node-types/MifQuestionType';
import {RankingFunction} from './node-types/RankingFunction';
import {MifNode} from './MifNode';

export class MifNodeHelper {

  static transformDtoToNode(dto: MifNodeDto): MifNode {
    switch (dto.nodeType) {
      case MifNodeTypes.SECTION.code:
        return new MifNode(dto.section.id, MifSectionType.fromDto(dto.section));
      case MifNodeTypes.GROUP.code:
        return new MifNode(dto.group.id, MifGroupType.fromDto(dto.group));
      case MifNodeTypes.QUESTION.code:
        return new MifNode(dto.question.id, MifQuestionType.fromDto(dto.question));
      default:
        throw new Error(`error parsing nodeType '${dto.nodeType}' while transforming DTO to Node`);
    }
  }

  static determineRankingFunction(dto: MifNodeDto): RankingFunction {
    switch (dto.nodeType) {
      case MifNodeTypes.SECTION.code:
        return comparable => comparable.section?.rank == null ? 0 : comparable.section.rank;
      case MifNodeTypes.GROUP.code:
        return comparable => comparable.group?.rank == null ? 0 : comparable.group.rank;
      case MifNodeTypes.QUESTION.code:
        return comparable => comparable.question?.rank == null ? 0 : comparable.question.rank;
      default:
        throw new Error(`error parsing nodeType '${dto.nodeType}' while determining ranking function`);
    }
  }

  /**
   * Sorting generic nodes, with rank / order hidden inside specific type.
   * Assuming that there can be different types of nodes on one level.
   * To be removed when sorting is implemented on backend.
   */
  static sortDTOs(dtos: MifNodeDto[]): MifNodeDto[] {
    return dtos.sort((n1, n2) => {
      const f1 = MifNodeHelper.determineRankingFunction(n1);
      const f2 = MifNodeHelper.determineRankingFunction(n2);
      const r1 = f1(n1);
      const r2 = f2(n2);

      if (r1 > r2) {
        return 1;
      }
      if (r1 < r2) {
        return -1;
      }
      return 0;
    });
  }
}
