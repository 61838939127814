import {Component, OnInit, ViewChild} from '@angular/core';
import {CartegraphManagementService} from '../../../../data/cartegraph/cartegraph-management.service';
import {CartegraphConfiguration} from '../../../../shared/models/cartegraph.model';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';


@Component({
  selector: 'app-manage-cartegraph',
  templateUrl: './manage-cartegraph.component.html',
  styleUrls: ['./manage-cartegraph.component.scss'],
})
export class ManageCartegraphComponent implements OnInit {

  isLoading = true;
  uiError: string;

  configuration: CartegraphConfiguration;
  @ViewChild(MatTabGroup) tab: MatTabGroup;

  tabId: string;
  selectedTabIndex: number;
  private tabList = ['config', 'submissions', 'imports'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cartegraphManagementService: CartegraphManagementService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.uiError = null;

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.tabId = params.get('tabId');
      this.selectedTabIndex = this.tabList.findIndex(t => t === this.tabId);

      this.cartegraphManagementService.getConfiguration().toPromise().then(response => {
        if (response.error) {
          this.uiError = response.error;
        } else {
          this.configuration = response.data ? response.data : new CartegraphConfiguration();
        }
      }).catch((error) => {
        console.log(error);
        this.uiError = error;
        this.isLoading = false;
      }).finally(() => {
        this.isLoading = false;
      });

    });

  }

  onSelectedTab(event: MatTabChangeEvent) {
    this.router.navigate([`settings/cartegraph-settings/${this.tabList[event.index]}`]);
  }
}


