<table
        mat-table
        matSort
        matSortDisableClear
        [dataSource]="dataSource"
        class="ops-table"
>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef class="mat-body"
        mat-sort-header sortActionDescription="Sort by title">
      Title
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.title }}
    </td>
  </ng-container>

  <ng-container matColumnDef="abbreviation">
    <th mat-header-cell *matHeaderCellDef class="mat-body"
        mat-sort-header sortActionDescription="Sort by abbreviation">
      Map Label
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.abbreviation }}
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
    <td mat-cell *matCellDef="let element; let i = index" class="ops-table-action-cell" >
      <button mat-icon-button class="ops-table-action-button"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()">
        <mat-icon class="ops-table-action-icon">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editObservationType.emit(element)">
          <mat-icon>edit</mat-icon>
          <span>Edit observation type</span>
        </button>
        <button mat-menu-item (click)="deleteObservationType.emit(element)">
          <mat-icon>delete</mat-icon>
          <span>Delete observation type</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
