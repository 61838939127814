<div *ngIf="isLoading" class="loading__wrapper">
    <mat-spinner></mat-spinner>
</div>

<div class="my-25">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>Import data</mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="!isLoading" class="mt-25">
            <p>Import all the data immediately. Currently it is only way to import data from Cartegraph.</p>
            <p>This will download all required data, pparse them, prepare and import into PlowOps.</p>

            <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                <span class="ui-error">{{ uiError }}</span>
            </div>

            <div class="mt-15">
                <mat-checkbox [(ngModel)]="fetchAllData">Fetch all metadata before import.</mat-checkbox>
                <div class="ml-25"><small class="ml-10">Takes longer, downloads all metadata. Use only e.g. when inspection form changed.</small></div>
            </div>
        </mat-card-content>

        <mat-card-actions *ngIf="!isLoading">
            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="importAllData()"
                    [disabled]="isLoading || configuration.baseUrl==null">
                <mat-icon>cloud_download</mat-icon>
                Import data
            </button>
        </mat-card-actions>
    </mat-card>
</div>
