import {UListItem} from './UListItem';
import {UId} from './UId';

/**
 * Implementation of a common list used in UI model where list is manipulated locally
 * and sent to back-end later.
 */
export class UList<T extends UListItem> {
    contents: T[];

    constructor(contents: T[]) {
        this.contents = contents;
    }

    isEmpty(): boolean {
        return this.contents == null || this.contents.length < 1;
    }

    isNotEmpty(): boolean {
        return this.contents != null && this.contents.length > 0;
    }

    size(): number {
        return this.contents.length;
    }

    add(it: T) {
        this.contents.push(it);
    }

    /**
     * Adds item at index (index at array length adds at the end)
     */
    addAtIndex(it: T, targetIndex: number) {
        if (targetIndex != null && targetIndex >= 0 && targetIndex <= this.contents.length) {
            this.contents.splice(targetIndex, 0, it);
        } else {
            throw new Error('addAtIndex() :: invalid index');
        }
    }

    /**
     * Moves item to target index. Target index is index as seen in an original array, not in a new array.
     */
    move(it: T, targetIndex: number) {
        let sourceIndex = this.contents.findIndex(value => value.internalId.isSameAs(it.internalId));
        if (sourceIndex >= 0 && targetIndex != null && targetIndex >= 0 && targetIndex <= this.contents.length) {
            this.contents.splice(targetIndex, 0, it);
            if (targetIndex <= sourceIndex) {
                sourceIndex = sourceIndex + 1;
            }
            this.contents.splice(sourceIndex, 1);
        } else {
            throw new Error('move() :: no such item or invalid index');
        }
    }

    remove(it: T) {
        const sourceIndex = this.contents.findIndex(value => value.internalId.isSameAs(it.internalId));
        if (sourceIndex >= 0) {
            this.contents.splice(sourceIndex, 1);
        } else {
            throw new Error('remove() :: no such item');
        }
    }

    removeAtIndex(sourceIndex: number) {
        if (sourceIndex != null && sourceIndex >= 0 && sourceIndex < this.contents.length) {
            this.contents.splice(sourceIndex, 1);
        } else {
            throw new Error('removeAtIndex() :: invalid index');
        }
    }

    removeAllSelected() {
        let index = this.contents.findIndex(value => value.selected);
        while (index !== -1) {
            this.contents.splice(index, 1);
            index = this.contents.findIndex(value => value.selected);
        }
    }

    isAnySelected(): boolean {
        return this.contents.some(value => value.selected);
    }

    findIndexOfOneWithSameInternalId(uid: UId) {
        return this.contents.findIndex(value => value.internalId.isSameAs(uid));
    }

    findIndexOfOneWithSameInternalIdAs(other: T) {
        return this.contents.findIndex(value => value.internalId.isSameAs(other.internalId));
    }

    containsOneWithSameInternalId(uid: UId): boolean {
        return this.contents.some(one => one.internalId.isSameAs(uid));
    }

    containsOneWithSameInternalIdAs(other: T): boolean {
        return this.contents.some(one => one.internalId.isSameAs(other.internalId));
    }

    findOneWithSameInternalId(uid: UId): T {
        return this.contents.find(one => one.internalId.isSameAs(uid));
    }

    findOneWithSameInternalIdAs(other: T): T {
        return this.contents.find(one => one.internalId.isSameAs(other.internalId));
    }

    contentsShallowCopy(): T[] {
        return [...this.contents];
    }
}
