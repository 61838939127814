import {Component, Input} from '@angular/core';
import {LatLngPipe} from '../../../formatting/latLng.pipe';
import {Observation} from '../../../models/observation';
import {Router} from '@angular/router';
import {ToastService} from '../../../services/toast.service';
import { LiveMapTab } from 'src/app/pages/live-map/models/live-map-tabs';


@Component({
  selector: 'app-observation-info-window-content',
  templateUrl: './observation-info-window-content.component.html',
  styleUrls: ['./observation-info-window-content.component.scss']
})
export class ObservationInfoWindowContentComponent {

  @Input()
  observation: Observation;

  @Input()
  isLiveMap: boolean;

  LiveMapTab = LiveMapTab;

  constructor(private toast: ToastService,
              private router: Router,
              private latLngPipe: LatLngPipe) { }

  handleLocationClick(event) {
    event.stopPropagation();

    const locationText = this.latLngPipe.transform(this.observation.location.coords);
    window.navigator.clipboard.writeText(locationText)
      .then(() => {
        this.toast.short('Location copied to clipboard.');
      })
      .catch((reason) => {
        this.toast.short(`Failed to copy the location. ${reason}`);
      });
  }

  isLinkActive(url): boolean {
    return this.router.url.startsWith(url);
  }
}
