import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {ManageObservationsComponent} from './manage-observations.component';
import {ObservationGroupComponent} from './observation-group/observation-group.component';
import {CreateObservationTypeDialogComponent} from './observation-group/dialogs/create-observation-type-dialog/create-observation-type-dialog.component';
import {DeleteObservationTypeDialogComponent} from './observation-group/dialogs/delete-observation-type-dialog/delete-observation-type-dialog.component';
import {UpdateObservationTypeDialogComponent} from './observation-group/dialogs/update-observation-type-dialog/update-observation-type-dialog.component';
import {CreateObservationGroupDialogComponent} from './dialogs/create-observation-group-dialog/create-observation-group-dialog.component';
import {DeleteObservationGroupDialogComponent} from './dialogs/delete-observation-group-dialog/delete-observation-group-dialog.component';
import {UpdateObservationGroupDialogComponent} from './dialogs/update-observation-group-dialog/update-observation-group-dialog.component';

@NgModule({
    declarations: [
        ManageObservationsComponent,
        ObservationGroupComponent,
        CreateObservationTypeDialogComponent,
        DeleteObservationTypeDialogComponent,
        UpdateObservationTypeDialogComponent,
        CreateObservationGroupDialogComponent,
        DeleteObservationGroupDialogComponent,
        UpdateObservationGroupDialogComponent,
    ],
    exports: [
        ManageObservationsComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
    ]
})
export class ObservationManagementModule { }
