import {Meta} from './Meta';
import {Link} from './Link';

export class JsonApiResponse<T> {
    data: T;
    error: string;
    links: Link[];
    meta: Meta;
}

