import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorHandler} from '../../../../http.error.handler';
import {MapLayersManager} from '../map-layers-manager';
import {GeoJSONSourceSpecification} from 'maplibre-gl';
import {SettingsService, TracksLayerFilter} from '../../../../configuration/settings.service';
import {GeobufResponseHandler} from '../../../../geobuf.response.handler';
import {ServerEventService} from '../../../../data/server-events/server-event.service';

@Injectable({
  providedIn: 'root'
})
export class RoadStatusService {

  static readonly ROAD_SEGMENTS_SOURCE_ID = 'road-segments';
  private mapLayersManager: MapLayersManager;
  private isEnabled: boolean;
  layerFilter: TracksLayerFilter = TracksLayerFilter.NONE;
  readonly trackLayerFilterToFlagMask = new Map<TracksLayerFilter, number>([
      [TracksLayerFilter.NONE, null],
      [TracksLayerFilter.PLOWING, 31], // 2^0 + 2^1 + 2^2 + 2^3 + 2^4
      [TracksLayerFilter.LIQUID_SPREADING, 31744],
      [TracksLayerFilter.GRANULAR_SPREADING, 992],
      [TracksLayerFilter.SPREADING, 32736],
      [TracksLayerFilter.MOWING, 1015808],
      [TracksLayerFilter.SWEEPING, 32505856],
      [TracksLayerFilter.PLOWING_MOWING_SWEEPING, 65011743],
  ]);

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private serverEventService: ServerEventService) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSource();

    if (this.isEnabled) {
      this.connectToManager();
    }
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
  }

  connectToManager() {
    const that = this;

    // handle refresh on derivedLayerCache update
    const cacheChangeSubscription = this.serverEventService.derivedLayerCacheUpdateObservable.subscribe({
      next(/*cacheUpdate*/) {
        that.handleSourceRefresh();
      }
    });
    this.openSubscriptions.push(cacheChangeSubscription);

    const changesSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.PLOW_TRACK_LAYER_FILTER) {
          that.layerFilter = newSettings.value;
          that.handleSourceRefresh();
        }
      }
    });
    this.openSubscriptions.push(changesSubscription);
  }

  getRoadSegmentFeatures() {
    let params: HttpParams = new HttpParams();
    const flagMask = this.trackLayerFilterToFlagMask.get(this.layerFilter);
    if (!!flagMask) {
      params = params.set('flagMask', flagMask);
    }
    return this.http.get(
        `${environment.services.location}v1/road/segment`,
        {
          params,
          observe: 'response',
          responseType: 'arraybuffer',
          headers: {Accept: 'application/octet-stream'}
        }
    )
      .pipe(
          map( response => GeobufResponseHandler.handleResponse(response)),
          catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  addSource() {
    this.mapLayersManager.addSource(RoadStatusService.ROAD_SEGMENTS_SOURCE_ID, this.getEmptySource());
    if (this.isEnabled) {
      this.updateSource();
    }
  }

  private getEmptySource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    } as GeoJSONSourceSpecification;
  }

  private updateSource() {
    this.getRoadSegmentFeatures().then(response => {
      this.mapLayersManager?.getGeoJsonSource(RoadStatusService.ROAD_SEGMENTS_SOURCE_ID)
          .setData(response);
    });
  }

  private handleSourceRefresh() {
    this.updateSource();
  }
}
