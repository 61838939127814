import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LabeledDateFilter, TimeRangeFilter} from '../../models/DateFilter';
import moment from 'moment';
import { Moment } from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import {FormControl, Validators} from '@angular/forms';

const SELECTED_TIME_RANGE = 'selectedTimeRange';
const CUSTOM_TIME_RANGE = 'customTimeRange';

@Component({
  selector: 'app-date-filter-dropdown',
  templateUrl: './date-filter-dropdown.component.html',
  styleUrls: ['./date-filter-dropdown.component.scss'],
})
export class DateFilterDropdownComponent implements OnInit {
  @Input() origin;
  @Output() filterChanged = new EventEmitter<LabeledDateFilter>();
  @Input() defaultValue: TimeRangeFilter;
  dateFilterType: TimeRangeFilter;
  dateFrom: Moment;
  dateTo: Moment;

  startDateForm: FormControl;
  endDateForm: FormControl;

  TimeRangeFilter = TimeRangeFilter;
  showCustom = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.dateFilterType = this.defaultValue || TimeRangeFilter.NONE;
    this.initCustomRangeForm(null, null);
    const dateFilterType = this.route.snapshot.queryParams['dateFilter'];
    if (!dateFilterType) {
      // get from browser cache
      this.setFilter(this.getCachedSelectedTimeRange());
      if (this.dateFilterType.equals(TimeRangeFilter.CUSTOM)) {
        this.showCustom = true;
        const customRangeCache = this.getCachedCustomRange();
        this.initCustomRangeForm(customRangeCache.start?.toISOString(), customRangeCache.end?.toISOString());
        if (customRangeCache.start && customRangeCache.end) {
          this.setCustomRangeFilter();
        }
      }
    } else {
      // get from URL
      this.setFilter(dateFilterType);
      if (dateFilterType === TimeRangeFilter.CUSTOM.key) {
        this.showCustom = true;
        const start = this.route.snapshot.queryParams['start'];
        const end = this.route.snapshot.queryParams['end'];
        this.initCustomRangeForm(start, end);
        if (start && end) {
          this.setCustomRangeFilter();
        }
      }
    }
  }

  initCustomRangeForm(start: string, end: string) {
    this.startDateForm = new FormControl(start ? moment(start) : '', [Validators.required]);
    this.endDateForm = new FormControl(end ? moment(end) : '', [Validators.required]);
  }

  setFilter(dateFilterType: string) {
    let emitEvent = true;
    this.dateTo = null;
    const now = moment();
    if (dateFilterType === TimeRangeFilter.NONE.key) {
      this.dateFrom = null;
      this.showCustom = false;
      this.appendFilterToURL(TimeRangeFilter.NONE);
    }
    if (dateFilterType === TimeRangeFilter.TODAY.key) {
      this.dateFrom = now.startOf('day');
      this.showCustom = false;
      this.appendFilterToURL(TimeRangeFilter.TODAY);
    }
    if (dateFilterType === TimeRangeFilter.WEEK.key) {
      this.dateFrom = now.startOf('week');
      this.showCustom = false;
      this.appendFilterToURL(TimeRangeFilter.WEEK);
    }
    if (dateFilterType === TimeRangeFilter.MONTH.key) {
      this.dateFrom = now.startOf('month');
      this.showCustom = false;
      this.appendFilterToURL(TimeRangeFilter.MONTH);
    }
    if (dateFilterType === TimeRangeFilter.PAST_90_DAYS.key) {
      this.dateFrom = now.subtract(90, 'day').startOf('day');
      this.showCustom = false;
      this.appendFilterToURL(TimeRangeFilter.PAST_90_DAYS);
    }
    if (dateFilterType === TimeRangeFilter.CUSTOM.key) {
      this.appendFilterToURL(TimeRangeFilter.CUSTOM);
      emitEvent = false;
    }
    if (emitEvent) {
      localStorage.setItem(this.getSelectedTimeRangeKey(), dateFilterType);
      this.filterChanged.emit(
          new LabeledDateFilter(this.getLabel(), this.dateFrom, this.dateTo)
      );
    }
  }

  private getLabel() {
    if (this.dateFilterType !== TimeRangeFilter.CUSTOM) {
      return this.dateFilterType.display;
    } else {
      return `${this.dateFrom.format('YYYY-MM-DD')} - ${this.dateTo.format('YYYY-MM-DD')}`;
    }
  }

  appendFilterToURL(dateFilterType: TimeRangeFilter) {
    this.router.navigate([], {
      queryParams: {
        dateFilter: dateFilterType.key,
        start: null,
        end: null
      },
      queryParamsHandling: 'merge',
    });
    this.dateFilterType = dateFilterType;
  }

  isCustomRangePickerShown() {
    return this.dateFilterType === TimeRangeFilter.CUSTOM;
  }

  toggleCustom($event) {
    this.showCustom = !this.showCustom;
    $event.stopPropagation();
  }

  setCustomRangeFilter() {
    this.dateFilterType = TimeRangeFilter.CUSTOM;
    this.dateFrom = this.startDateForm.value.startOf('day');
    this.dateTo = this.endDateForm.value.endOf('day');

    const customRangeCache = {
      start: this.startDateForm.value,
      end: this.endDateForm.value,
    };

    localStorage.setItem(
        this.getCustomTimeRangeKey(),
        JSON.stringify(customRangeCache)
    );
    localStorage.setItem(this.getSelectedTimeRangeKey(), TimeRangeFilter.CUSTOM.key);

    this.router.navigate([], {
      queryParams: {
        dateFilter: TimeRangeFilter.CUSTOM.key,
        start: this.dateFrom.toISOString(),
        end: this.dateTo.toISOString(),
      },
      queryParamsHandling: 'merge',
    });

    this.filterChanged.emit(
        new LabeledDateFilter(this.getLabel(), this.dateFrom, this.dateTo)
    );
  }

  getCachedSelectedTimeRange(): string {
    const selectedCachedRange = localStorage.getItem(
        this.getSelectedTimeRangeKey()
    );

    if (selectedCachedRange) {
      return selectedCachedRange;
    }

    return this.dateFilterType.key;
  }

  getCachedCustomRange() {
    const selectedCustomRange = localStorage.getItem(
        this.getCustomTimeRangeKey()
    );

    if (!selectedCustomRange) {
      return null;
    }

    const parsedObject = JSON.parse(selectedCustomRange);

    return {
      start: moment(parsedObject.start),
      end: moment(parsedObject.end),
    };
  }

  private getCustomTimeRangeKey() {
    return `${CUSTOM_TIME_RANGE}:${this.origin}`;
  }

  private getSelectedTimeRangeKey() {
    return `${SELECTED_TIME_RANGE}:${this.origin}`;
  }
}
