import {Component, OnDestroy, OnInit} from '@angular/core';
import {DateFilter} from '../../../../shared/models/DateFilter';
import {VehicleBasic} from '../../../../shared/models/vehicle.model';
import {ShiftWithDriverAndVehicleModel} from '../../../../shared/models/shift.model';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {VehiclesService} from '../../../../data/vehicles/vehicles.service';
import {ShiftsService} from '../../../../data/shifts/shifts.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from '../../../../shared/services/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SecurityService} from '../../../../security/security.service';
import {DriverWithShiftCount} from '../../../../shared/models/driver.model';
import {DriversService} from '../../../../data/drivers/drivers.service';
import {PageEvent} from '@angular/material/paginator';
import {VerticalIconButton} from '../../../../shared/components/buttons/vertical-icon-button/vertical-icon-button.component';
import {InfiniteScroll} from '../../../../shared/tools/InfiniteScroll';
import {ActionMenuItem} from '../../../../shared/models/action-menu-item.class';

enum ShiftLookupCategory {
  VEHICLES = 'vehicles',
  DRIVERS = 'drivers',
}

@Component({
  selector: 'app-shift-lookup',
  templateUrl: './shift-lookup.component.html',
  styleUrls: ['./shift-lookup.component.scss']
})
export class ShiftLookupComponent implements OnInit, OnDestroy {

  isAdmin = false;
  dateFilter: DateFilter = new DateFilter(null, null);
  selectedItem: any;
  selectedId: number;
  selectedCategory: string = ShiftLookupCategory.VEHICLES;
  vehicles: VehicleBasic[];
  drivers: DriverWithShiftCount[];
  shifts: ShiftWithDriverAndVehicleModel[] = [];
  items: ActionMenuItem[];

  shiftsLoading = true;
  shiftsScrollLoading = false;
  page = 0;
  pageSize = 5;
  total: number;
  token: string;

  configuration: ConfigurationModel;
  configurationSubscription: Subscription;
  ShiftLookupCategory = ShiftLookupCategory;

  vehicleFilterButton: VerticalIconButton = {
    title: 'Vehicles',
    icon: 'directions_car',
    activePredicate: () => this.selectedCategory === ShiftLookupCategory.VEHICLES,
    onClick: () => { this.selectCategory(ShiftLookupCategory.VEHICLES); },
  };

  driverFilterButton: VerticalIconButton = {
    title: 'Drivers',
    icon: 'people',
    activePredicate: () => this.selectedCategory === ShiftLookupCategory.DRIVERS,
    onClick: () => { this.selectCategory(ShiftLookupCategory.DRIVERS); },
  };

  // @ViewChild(DriverStatsCardComponent) driverStatsComponent: DriverStatsCardComponent;
  // @ViewChild(VehicleStatsCardComponent) vehicleStatsComponent: VehicleStatsCardComponent;

  constructor(
      private vehicleService: VehiclesService,
      private driversService: DriversService,
      private shiftService: ShiftsService,
      private configurationService: ConfigurationService,
      public dialog: MatDialog,
      private toast: ToastService,
      private route: ActivatedRoute,
      private securityService: SecurityService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isAdmin = this.securityService.isAdminSync();

    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      const category = paramMap.get('category');
      if (!!category && category !== this.selectedCategory) {
        this.selectedCategory = paramMap.get('category');
        this.updateItems();
      }
      const id = paramMap.get('id');
      if (!!id) {
        this.selectedId = +id;
        this.loadShifts();
      }
    });

    this.securityService.getToken()
        .then(accessToken => this.token = accessToken)
        .catch(error => {
          console.error(error);
        });

    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  loadVehicles() {
    this.vehicleService.getVehiclesBasic(
        this.dateFilter.from ? this.dateFilter.from.toDate() : null,
        this.dateFilter.to ? this.dateFilter.to.toDate() : null,
    ).toPromise().then(response => {
      this.vehicles = response.data;
      if (this.selectedCategory === ShiftLookupCategory.VEHICLES) {
        this.updateItems();
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  loadDrivers() {
    this.driversService.getDriverList(
        this.dateFilter.from ? this.dateFilter.from.toDate() : null,
        this.dateFilter.to ? this.dateFilter.to.toDate() : null,
    ).toPromise().then(response => {
      this.drivers = response.data;
      if (this.selectedCategory === ShiftLookupCategory.DRIVERS) {
        this.updateItems();
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  selectCategory(category: ShiftLookupCategory) {
    this.selectedId = null;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { category, id: undefined },
      queryParamsHandling: 'merge',
    });
  }

  selectItem(id: number) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { id },
      queryParamsHandling: 'merge',
    });
  }

  onScroll(e) {
    InfiniteScroll.onScroll(e, () => this.extendShifts());
  }

  private updateItems() {
    if ((this.selectedCategory === ShiftLookupCategory.VEHICLES && !!this.vehicles) ||
        (this.selectedCategory === ShiftLookupCategory.DRIVERS && !!this.drivers)
    ) {
      if (this.selectedCategory === ShiftLookupCategory.VEHICLES) {
        this.items = this.vehicles.map(vehicle => {
          return new ActionMenuItem(
              vehicle.id,
              'directions_car',
              !!vehicle.label ? vehicle.label : vehicle.name,
              vehicle.shiftCount + ' shift' + (vehicle.shiftCount > 1 ? 's' : ''),
              '',
              null,
              () => this.selectedId === vehicle.id,
              null,
              () => { this.selectItem(vehicle.id); },
              null,
              null,
              [],
          );
        });
      } else {
        this.items = this.drivers.map(driver => {
          return new ActionMenuItem(
              driver.id,
              'person',
              driver.name,
              driver.shiftCount + ' shift' + (driver.shiftCount > 1 ? 's' : ''),
              '',
              null,
              () => this.selectedId === driver.id,
              null,
              () => { this.selectItem(driver.id); },
              null,
              null,
              [],
          );
        });
      }
    }
  }

  dateFilterChanged(dateFilter: DateFilter) {
    if (!dateFilter.equals(this.dateFilter)) {
      this.page = 0;
      this.dateFilter = dateFilter;
      this.loadVehicles();
      this.loadDrivers();
      this.loadShifts();
    }
  }

  filter(filter: string) {
    /*if (filter !== null && filter !== undefined && filter.length > 0) {
      this.filteredListData = this.listData.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()));
    } else {
      this.filteredListData = this.listData;
    }*/
  }

  handlePageEvent(event: PageEvent) {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadShifts();
  }

  handleShiftDeleted() {
    /*if (this.selectedCategory === ShiftLookupCategory.VEHICLES && !!this.vehicleStatsComponent) {
      this.vehicleStatsComponent.loadStats();
    }
    if (this.selectedCategory === ShiftLookupCategory.DRIVERS && !!this.driverStatsComponent) {
      this.driverStatsComponent.loadStats();
    }*/
    this.loadShifts();
  }

  loadShifts() {
    if (this.selectedId) {
      this.shiftsLoading = true;
      this.shiftsScrollLoading = false;
      this.page = 0;
      this.shifts = [];
      this.shiftService
          .getCompleteShiftsByVehicleAndDriver(
              this.selectedCategory === ShiftLookupCategory.VEHICLES ? this.selectedId : null,
              this.selectedCategory === ShiftLookupCategory.DRIVERS ? this.selectedId : null,
              this.dateFilter.from ? this.dateFilter.from.toDate() : null,
              this.dateFilter.to ? this.dateFilter.to.toDate() : null,
              this.page,
              this.pageSize
          )
          .then((response) => {
            // console.log(`initialized, load count ${response.data.content.length} of ${response.data.totalElements}`);
            this.shifts = response.data.content;
            this.total = response.data.totalElements;
            this.shiftsLoading = false;
          })
          .catch((message) => {
            this.toast.longer('Error while loading initial shifts!');
            console.error(message);
            this.shiftsLoading = false;
          });
    }
  }

  private extendShifts() {
    if (this.selectedId && !this.shiftsScrollLoading && this.shifts.length < this.total) {
      this.page++;
      this.shiftsScrollLoading = true;
      this.shiftService
        .getCompleteShiftsByVehicleAndDriver(
          this.selectedCategory === ShiftLookupCategory.VEHICLES ? this.selectedId : null,
          this.selectedCategory === ShiftLookupCategory.DRIVERS ? this.selectedId : null,
          this.dateFilter.from ? this.dateFilter.from.toDate() : null,
          this.dateFilter.to ? this.dateFilter.to.toDate() : null,
          this.page,
          this.pageSize
      ).then(response => {
        const extendedData = [...this.shifts];
        extendedData.push(...response.data.content);
        // console.log(`extended, load count ${extendedData.length} of ${this.total}`);
        this.shifts = extendedData;
        this.shiftsScrollLoading = false;
      }).catch(message => {
        this.toast.longer('Error while loading more shifts!');
        console.error(message);
        this.shiftsScrollLoading = false;
      });
    }
  }
}
