import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationModel, FeatureFlagEnum} from '../../../models/configuration.model';
import {ISettingKeyValuePair, SettingsService, TracksLayerType} from '../../../../configuration/settings.service';
import {Subscription} from 'rxjs';
import {TrackStylesService} from '../../../../configuration/track-styles.service';
import {TrackStyles} from '../../../../configuration/model/TrackStyles';
import {LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ToastService} from '../../../services/toast.service';
import {SecurityService} from '../../../../security/security.service';

@Component({
  selector: 'app-location-history-layers-config',
  templateUrl: './location-history-layers-config.component.html',
  styleUrls: ['./location-history-layers-config.component.scss']
})
export class LocationHistoryLayersConfigComponent implements OnInit, OnDestroy {

  @Input() configuration: ConfigurationModel;
  localTrackStyles: TrackStyles;

  isAdmin: boolean;
  showRecent: boolean;
  timeLimit: number;
  coverageLayerTypeFilter: LayerTypeFilter;
  currencyLayerTypeFilter: LayerTypeFilter;
  loadingStyles: boolean;
  stylesDirty: boolean;

  private settingsSubscription: Subscription;

  FeatureFlagEnum = FeatureFlagEnum;

  constructor(
      private settingsService: SettingsService,
      private securityService: SecurityService,
      private trackStylesService: TrackStylesService,
      private configurationService: ConfigurationService,
      private toast: ToastService,
  ) {
  }

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();
    this.settingsSubscription =
        this.settingsService.settingsChangedObservable.subscribe(
            (keyValuePair: ISettingKeyValuePair) => {
              this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
            }
        );

    this.loadingStyles = true;
    this.trackStylesService.loadTrackStyles()
        .then(trackStyles => {
          this.reloadLocalTrackStyles(trackStyles);
        });

    this.showRecent = this.settingsService.getBooleanValue(SettingsService.PLOW_TRACK_SHOW_RECENT);
    this.timeLimit = +this.settingsService.getStringValue(SettingsService.PLOW_TRACK_TIME_FILTER);
  }

  ngOnDestroy() {
    this.settingsSubscription?.unsubscribe();
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
        this.configuration.featureFlags.find(
            (value) => value.isEnabled && value.name === featureFlag
        ) !== undefined
    );
  }

  private onSettingsChanged(key: string, value: any) {
    if (key === SettingsService.PLOW_TRACK_SHOW_RECENT) {
      this.showRecent = value;
    } else if (key === SettingsService.PLOW_TRACK_TIME_FILTER) {
      this.timeLimit = Number(value);
    }
  }

  private reloadLocalTrackStyles(trackStyles: TrackStyles) {
    this.localTrackStyles = trackStyles;
    this.coverageLayerTypeFilter = LayerTypeFilter.coverageLayerTypeFilter(this.localTrackStyles);
    this.currencyLayerTypeFilter = LayerTypeFilter.currencyLayerTypeFilter(this.localTrackStyles, 1);
    this.loadingStyles = false;
    this.stylesDirty = false;
  }

  coverageColorsChanged(coverageStyle: LayerTypeFilter) {
    this.stylesDirty = true;
    if (!!coverageStyle && coverageStyle.filterName === TracksLayerType.COVERAGE && coverageStyle.measurementUnits.length === 5) {
      this.localTrackStyles.roadStatusCoverageLevel1.color = coverageStyle.measurementUnits[0].color;
      this.localTrackStyles.roadStatusCoverageLevel1.dasharray = coverageStyle.measurementUnits[0].dashArray;
      this.localTrackStyles.roadStatusCoverageLevel2.color = coverageStyle.measurementUnits[1].color;
      this.localTrackStyles.roadStatusCoverageLevel2.dasharray = coverageStyle.measurementUnits[1].dashArray;
      this.localTrackStyles.roadStatusCoverageLevel3.color = coverageStyle.measurementUnits[2].color;
      this.localTrackStyles.roadStatusCoverageLevel3.dasharray = coverageStyle.measurementUnits[2].dashArray;
      this.localTrackStyles.roadStatusCoverageLevel4.color = coverageStyle.measurementUnits[3].color;
      this.localTrackStyles.roadStatusCoverageLevel4.dasharray = coverageStyle.measurementUnits[3].dashArray;
      this.localTrackStyles.roadStatusCoverageLevel5.color = coverageStyle.measurementUnits[4].color;
      this.localTrackStyles.roadStatusCoverageLevel5.dasharray = coverageStyle.measurementUnits[4].dashArray;
    } else {
      console.warn('Something wrong with coverage style!');
      console.log(coverageStyle);
    }
  }

  currencyColorsChanged(currencyStyle: LayerTypeFilter) {
    this.stylesDirty = true;
    if (!!currencyStyle && currencyStyle.filterName === TracksLayerType.CURRENCY && currencyStyle.measurementUnits.length === 5) {
      this.localTrackStyles.roadStatusCurrencyLevel1.color = currencyStyle.measurementUnits[0].color;
      this.localTrackStyles.roadStatusCurrencyLevel1.dasharray = currencyStyle.measurementUnits[0].dashArray;
      this.localTrackStyles.roadStatusCurrencyLevel2.color = currencyStyle.measurementUnits[1].color;
      this.localTrackStyles.roadStatusCurrencyLevel2.dasharray = currencyStyle.measurementUnits[1].dashArray;
      this.localTrackStyles.roadStatusCurrencyLevel3.color = currencyStyle.measurementUnits[2].color;
      this.localTrackStyles.roadStatusCurrencyLevel3.dasharray = currencyStyle.measurementUnits[2].dashArray;
      this.localTrackStyles.roadStatusCurrencyLevel4.color = currencyStyle.measurementUnits[3].color;
      this.localTrackStyles.roadStatusCurrencyLevel4.dasharray = currencyStyle.measurementUnits[3].dashArray;
      this.localTrackStyles.roadStatusCurrencyLevel5.color = currencyStyle.measurementUnits[4].color;
      this.localTrackStyles.roadStatusCurrencyLevel5.dasharray = currencyStyle.measurementUnits[4].dashArray;
    } else {
      console.warn('Something wrong with currency style!');
      console.log(currencyStyle);
    }
  }

  isButtonVisibleSave(): boolean {
    return this.stylesDirty;
  }

  buttonClickedSave() {

    TrackStyles.saveCoverageAndCurrency(this.localTrackStyles, this.trackStylesService)
        .then(trackStyles => {
          this.reloadLocalTrackStyles(trackStyles);
          this.toast.longer('Track layer colors have been saved');
          this.configurationService.refreshTrackStyles();
        })
        .catch(error => {
          console.error(`error while saving track layer colors :: error = '${error}'`);
          this.toast.longer('Error while saving track layer colors');
        });
  }

  isButtonVisibleCancel(): boolean {
    return this.stylesDirty;
  }

  buttonClickedCancel() {
    this.trackStylesService.loadTrackStyles()
        .then(trackStyles => {
          this.reloadLocalTrackStyles(trackStyles);
        });
  }

  isButtonVisibleReset(): boolean {
    return true;
  }

  buttonClickedReset() {
    TrackStyles.resetCoverageAndCurrency(this.localTrackStyles, this.trackStylesService)
        .then(trackStyles => {
          this.reloadLocalTrackStyles(trackStyles);
          this.toast.longer('Track layer colors have been reset and saved');
          this.configurationService.refreshTrackStyles();
        })
        .catch(error => {
          console.error(`error while resetting track layer colors :: error = '${error}'`);
          this.toast.longer('Error while resetting track layer colors');
        });
  }

  toggleRecentlyEnded() {
    const showRecent = this.settingsService.getBooleanValue(SettingsService.PLOW_TRACK_SHOW_RECENT);
    if (showRecent !== this.showRecent) {
      this.settingsService.setBooleanValue(SettingsService.PLOW_TRACK_SHOW_RECENT, this.showRecent);
    }
  }

  updateTimeLimit() {
    const timeLimit = +this.settingsService.getStringValue(SettingsService.PLOW_TRACK_TIME_FILTER);
    if (timeLimit !== this.timeLimit) {
      this.settingsService.setStringValue(SettingsService.PLOW_TRACK_TIME_FILTER, String(this.timeLimit));
    }
  }
}
