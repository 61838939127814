import { Pipe, PipeTransform } from '@angular/core';
import {LatLng} from './lat-lng';
import {LatLngModel} from '../models/lat.lng.model';

@Pipe({
  name: 'latLng'
})
export class LatLngPipe implements PipeTransform {

  transform(latLng: LatLngModel): string;
  transform(latLng: LatLng);
  transform(value: any): any {
    if (!!value) {
      if (!!value.lat && !!value.lng) {
        const latChar = value.lat > 0 ? 'N' : 'S';
        const lngChar = value.lng < 0 ? 'W' : 'E';
        return `${latChar} ${value.lat.toFixed(4)}, ${lngChar} ${value.lng.toFixed(4)}`;
      }
    }
    return value;
  }

}
