import {Component, Input} from '@angular/core';
import {ActionMenuItem} from '../../models/action-menu-item.class';

@Component({
  selector: 'app-action-menu-item',
  templateUrl: './action-menu-item.component.html',
  styleUrls: ['./action-menu-item.component.scss']
})
export class ActionMenuItemComponent {
  @Input() input: ActionMenuItem;
  @Input() small = false;
}
