import {Injectable} from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {LineLayerSpecification} from 'maplibre-gl';
import {RouteConfigurationWithSchema, RouteHierarchyItem, RouteStyle} from '../../../models/route';
import {RoutesManagerService} from '../../../../data/routes/routes-manager.service';
import {RouteSourceService} from './route-source.service';
import {MapFilters} from '../../../../configuration/map-filters';


@Injectable({
    providedIn: 'root'
})
export class StaticRoutesLayerService {

    static readonly LAYER_ID_STATIC_ROUTES_PREFIX = 'static-routes-layer';

    private mapLayersManager: MapLayersManager;
    private lineLayers: LineLayerSpecification[] = [];
    private routes: RouteHierarchyItem[];
    private routeConfigurations: RouteConfigurationWithSchema[];

    private visibleRouteConfigIds: number[] = [];

    constructor(private routesManager: RoutesManagerService,
    ) { }

    init(mapLayersManager: MapLayersManager) {
        if (!!this.mapLayersManager) {
            throw Error('The map layers manager has already been set.');
        }
        this.mapLayersManager = mapLayersManager;
        this.routeConfigurations = this.routesManager.getRouteConfigurations();
        this.routes = this.routesManager
            .getRouteHierarchyLeaves()
            .filter(it => !it.hidden);
    }

    release() {
        if (!this.mapLayersManager) {
            throw Error('The map has not been set!');
        }

        this.mapLayersManager = null;
        this.lineLayers = [];
        this.visibleRouteConfigIds = [];
        this.routes = undefined;
        this.routeConfigurations = undefined;
    }

    // on map style change
    reset() {
        this.lineLayers = [];
        this.visibleRouteConfigIds = [];
    }

    addRouteConfigLayers(routeConfigurationId: number, isVisible: boolean) {
        if (isVisible) {
            this.visibleRouteConfigIds.push(routeConfigurationId);
        }
        this.addLayers(routeConfigurationId, isVisible);
    }

    setRouteConfigLayersVisibility(routeConfigurationId: number, isVisible: boolean) {
        // update visible field
        if (isVisible) {
            if (!this.visibleRouteConfigIds.includes(routeConfigurationId)) {
                this.visibleRouteConfigIds.push(routeConfigurationId);
            }
        } else {
            const visibleIndex = this.visibleRouteConfigIds.indexOf(routeConfigurationId);
            if (visibleIndex >= 0) {
                this.visibleRouteConfigIds.splice(visibleIndex, 1);
            }
        }

        this.lineLayers
            .filter(layer => layer.id.startsWith(`${StaticRoutesLayerService.LAYER_ID_STATIC_ROUTES_PREFIX}_${routeConfigurationId}_`))
            .forEach(lineLayer => {
                this.mapLayersManager.setLayerVisibility(lineLayer.id, isVisible);
            });
    }

    private addLayers(routeConfigurationId: number, isVisible: boolean) {

        this.getLineLayers(routeConfigurationId).forEach(layer => {
            layer.layout.visibility = isVisible ? 'visible' : 'none';
            this.lineLayers.push(layer);
            this.mapLayersManager.addLayer(layer);
        });
    }

    private getLineLayers(routeConfigurationId: number): LineLayerSpecification[] {
        return this.routes
            .filter(route => route.configId === routeConfigurationId)
            .map(route => {
                let globalRouteStyle = this.routeConfigurations.find(config => config.id === route.configId)?.schema?.style;
                if (!globalRouteStyle) {
                    console.warn('Missing schema style. This should not happen!');
                    globalRouteStyle = new RouteStyle('#FF0000', 4, 1.0);
                }
                return {
                    id: `${StaticRoutesLayerService.LAYER_ID_STATIC_ROUTES_PREFIX}_${route.configId}_${route.routeId}`,
                    type: 'line',
                    source: RouteSourceService.ROUTES_SOURCE_ID,
                    filter: MapFilters.getRoutesFilter(route.configId, route.routeId, null, null),
                    layout: {
                        'line-join': 'miter',
                        'line-cap': 'butt',
                        'line-miter-limit': 2,
                    },
                    paint: {
                        'line-color': !!route.color ? route.color : globalRouteStyle.color,
                        'line-width': [
                            'interpolate',
                            ['exponential', 2],
                            ['zoom'],
                            10, ['*', globalRouteStyle.width, ['^', 2, -1]],
                            24, ['*', globalRouteStyle.width, ['^', 2, 8]]
                        ],
                        'line-opacity': globalRouteStyle.opacity,
                        'line-dasharray': !!route.lineType
                            ? ['literal', route.lineType]
                            : ['literal', !!globalRouteStyle.lineType ? globalRouteStyle.lineType : [10]],
                    }
                } as LineLayerSpecification;
        });
    }
}
