<h1 mat-dialog-title>
  Confirmation
</h1>

<mat-dialog-content class="dialog-content">
  <p>
    Are you sure you want to delete <b>"{{data.title}} ({{data.abbreviation}})"</b>?
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="warn" [mat-dialog-close]="data" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
