import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof(value) === 'number') {
      if (args[0]) { // use metric system
        return `${formatNumber(value, 'en-US', '0.1-2')} kg`;
      } else {
        return `${formatNumber(value * 2.2046, 'en-US', '0.1-2')} lbs`;
      }
    } else {
      return 'N/A';
    }
  }
}
