<div class="setup-page-container">

    <div class="header-container">
        <h2 class="header-container-filters">
            Vehicle Management (CSV)
        </h2>
    </div>

    <div class="content-container">
        <div class="selection-container">

            <ng-container>
                <mat-card appearance="outlined">
                    <mat-card-content class="w-100 content">
                        <div class="loading__wrapper" *ngIf="currentState == ImportVehiclesStateEnum.WORKING">
                            <div>Working on it ...</div>
                            <mat-spinner></mat-spinner>
                        </div>
                        <div *ngIf="currentState == ImportVehiclesStateEnum.NOOP" class="w-100">
                            <input hidden type="file"
                                   id="file"
                                   accept=".csv"
                                   #fileInput
                                   (change)="handleFileInput($event)">
                            <button mat-button
                                    color="accent" (click)="fileInput.click()"> Choose CSV file
                            </button>
                            <span class="ml-10" *ngIf="selectedFile">{{selectedFile.name}}</span>

                            <span class="ml-10">
                    <button mat-button
                            color="accent" [disabled]="selectedFile == null" (click)="validateDataToImport()"> Upload CSV file
                    </button>
                </span>
                            <span class="ml-10">
                    <button mat-button
                            color="accent" (click)="cancelImport()"> Cancel
                    </button>
                </span>
                            <div class="mt-15">
                                Sample CSV content
                                <div>
                                    Map Label and License Plate are optional.
                                    <pre>
name,mapLabel,licensePlate,vGroup
4431009435,X0035,XE0436-X0035,Truck 4x4
                </pre>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="currentState == ImportVehiclesStateEnum.NOOP && uiError && uiError.length > 0"
                             class="w-100 content">
                            <span class="ui-error">{{ uiError }}</span>
                        </div>

                        <div *ngIf="currentState == ImportVehiclesStateEnum.IMPORTED" class="w-100 content">
                            <div>Import successfully finished.
                                <button mat-button (click)="changePage()" class="text-accent">
                                    Navigate to vehicle management</button> to see changes.
                            </div>
                        </div>


                    </mat-card-content>
                </mat-card>
            </ng-container>

            <ng-container *ngIf="currentState == ImportVehiclesStateEnum.NOOP">
                <mat-card class="mt-15" appearance="outlined" *ngIf="validData && validData.length>0">
                    <mat-card-title class="text-accent pl-15"> Valid data</mat-card-title>
                    <mat-card-actions>
                        <button mat-button
                                color="accent"
                                (click)="doCancel()">Cancel
                        </button>
                        <button mat-button
                                color="accent"
                                [disabled]="importDisabled()"
                                (click)="doImport()">Import
                        </button>
                    </mat-card-actions>
                    <mat-card-content>
                        <ng-container
                                *ngTemplateOutlet="table;context:{importData: validData,displayedColumns:displayedColumnsValid}"></ng-container>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mt-15" appearance="outlined" *ngIf="invalidData && invalidData.length>0">
                    <mat-card-title class="text-accent pl-15"> Invalid data</mat-card-title>
                    <mat-card-content>
                        <ng-container
                                *ngTemplateOutlet="table;context:{importData: invalidData,displayedColumns:displayedColumnsInvalid}"></ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-container>


        </div>
    </div>
</div>

<ng-template #table let-data="importData" let-displayedColumns="displayedColumns">
    <table *ngIf="data && data.length>0" mat-table
           [dataSource]="data">

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef> Line</th>
            <td mat-cell *matCellDef="let element">{{element.index}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element">{{element.row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="mapLabel">
            <th mat-header-cell *matHeaderCellDef> Map Label</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.mapLabel}}
                <del *ngIf="element.vehicle?.mapLabel != element.row?.mapLabel">{{element.vehicle?.mapLabel}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="licensePlate">
            <th mat-header-cell *matHeaderCellDef> License Plate</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.licensePlate}}
                <del *ngIf="element.vehicle?.licensePlate != element.row?.licensePlate">{{element.vehicle?.licensePlate}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="vGroup">
            <th mat-header-cell *matHeaderCellDef> Vehicle Group</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.vehicleGroup}}
                <del *ngIf="element.vehicleCategory?.title != element.row?.vehicleGroup">{{element.vehicleCategory?.title}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="action;context:{action:element.action}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Errors</th>
            <td mat-cell *matCellDef="let element">
                <div *ngFor="let error of element.errors">{{error}}</div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>

<ng-template #action let-action="action">
    <ng-container [ngSwitch]="action">
        <mat-icon *ngSwitchCase="'CREATE'" color="accent" [title]="action">add_circle</mat-icon>
        <mat-icon *ngSwitchCase="'UPDATE'" color="accent" [title]="action">sync</mat-icon>
    </ng-container>
</ng-template>
