<div class="forecast-dialog-header">
    <div class="header__wrapper">
        <div class="forecast-title">
            Weather Forecast for <span>{{ city }}</span> <span *ngIf="!city">{{ lngLat | latLng }}</span>

            <button mat-icon-button (click)="editLocation()" [matTooltip]="'Edit Location'">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="actions">
            <button mat-icon-button (click)="loadData()" [matTooltip]="'Reload the forecast'">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button mat-dialog-close cdkfocusinitial>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <h2>24-Hour Forecast</h2>
    <div class="forecast-wrapper">
        <ng-container *ngFor="let hourData of hourlyData">
            <div class="forecast-column">
                <div>{{ hourData.startTime | date: 'shortTime' }}</div>
                <img [ngSrc]="getIconUrl(hourData.icon)"
                     width="56"
                     height="56"
                     [alt]="hourData.startTime | date: 'shortTime'"
                >
                <div>{{ hourData.temperature }}°{{ hourData.temperatureUnit}}</div>
                <div class="short-forecast">{{ hourData.shortForecast}}</div>
            </div>
        </ng-container>
    </div>

    <h2>7-Day Forecast</h2>
    <div class="forecast-wrapper">
        <ng-container *ngFor="let forecastData of dailyData">
            <div class="forecast-column">
                <div class="day-name">{{ forecastData.name }}</div>
                <img [ngSrc]="getIconUrl(forecastData.icon)"
                     width="86"
                     height="86"
                     [alt]="forecastData.name"
                     [matTooltip]="forecastData.detailedForecast"/>
                <div>{{ forecastData.temperature }}°{{ forecastData.temperatureUnit}}</div>
                <div class="short-forecast">{{ forecastData.shortForecast}}</div>
            </div>
        </ng-container>
    </div>
</div>


