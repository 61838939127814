import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AssetRightPanel} from '../../assets/asset-right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../../data/assets/assets-manager.service';
import {FormBuilder} from '@angular/forms';
import {ToastService} from '../../../../../../shared/services/toast.service';
import {RouteAssignmentService} from '../../../../../../data/routes/route-assignment.service';
import {
  RouteAssignment,
  RouteAssignmentQueue,
  RouteAssignmentStatus,
  VehicleRouteAssignmentStatus
} from '../../../../../../shared/models/route-assignment';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../../shared/models/action-menu-item.class';
import {
  RouteConfigurationWithSchema,
  RouteHierarchyItem,
  RouteHierarchyItemWithPath
} from '../../../../../../shared/models/route';
import {RouteAssignmentManagerService} from '../../../../../../data/routes/route-assignment-manager.service';
import {Subscription} from 'rxjs';
import {SecurityService} from '../../../../../../security/security.service';
import {RouteSelectorComponent} from '../route-selector/route-selector.component';
import {LiveMapTab} from '../../../../models/live-map-tabs';
import {LiveMapDataService} from '../../../../services/live-map-data.service';

@Component({
  selector: 'app-quick-route-assignment',
  templateUrl: './quick-route-assignment.component.html',
  styleUrls: ['./quick-route-assignment.component.scss']
})
export class QuickRouteAssignmentComponent extends AssetRightPanel implements OnInit, OnDestroy {


  routeAssignments: RouteAssignment[];
  items: ActionMenuItem[];
  readonly vehiclesStatus: Map<number, VehicleRouteAssignmentStatus> = new Map<number, VehicleRouteAssignmentStatus>();

  assigningMode = false;
  showConfirmDialog = false;
  isAdmin = false;

  routeItems: RouteHierarchyItemWithPath[];

  selectedRoute: RouteHierarchyItem;
  vehicleStatusSubscription: Subscription;
  routeSubscription: Subscription;

  RouteAssignmentQueue = RouteAssignmentQueue;

  @ViewChild(RouteSelectorComponent) routeSelector: RouteSelectorComponent;

  constructor(
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private routeAssignmentService: RouteAssignmentService,
      private routeAssignmentManager: RouteAssignmentManagerService,
      private securityService: SecurityService,
      private liveMapDataService: LiveMapDataService,
      private formBuilder: FormBuilder,
      private toast: ToastService,
  ) {
    super(
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
  }

  ngOnInit() {
    this.initializeAssets();
    this.loadConfiguration();
    this.securityService.isAdmin().then(response => this.isAdmin = response);

    const that = this;
    this.vehicleStatusSubscription = this.routeAssignmentManager.vehicleRouteAssignmentStatus$.subscribe({
      next(statusUpdate) {
        let vehiclesStatus: VehicleRouteAssignmentStatus[];
        if (statusUpdate.updated.length === 0) {
          // initial setup
          vehiclesStatus = statusUpdate.state;
        } else {
          // only update
          vehiclesStatus = statusUpdate.updated;
        }
        vehiclesStatus.forEach(vehicleStatus => {
          that.vehiclesStatus.set(vehicleStatus.vehicleId, vehicleStatus);
        });
        if (statusUpdate.updated.length === 0) {
          // initial setup, update only UI items
          that.onVehicleStatusUpdated();
        } else {
          // route assignments has changed, reload
          that.reloadRouteAssignments();
        }
      }
    });

    this.routeSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfiguration => {
      this.routeItems = routeConfiguration.map(routeConfig => {
        return this.routeConfigToHierarchy(routeConfig);
      }).flat();
    });
  }

  ngOnDestroy() {
    this.configSubscription?.unsubscribe();
    this.vehicleStatusSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.vehiclesStatus.clear();
  }

  onAssetChange() {
    this.routeAssignments = null;
    if (!!this.asset) {
      this.reloadRouteAssignments();
    }
  }

  onConfigurationLoad() {
    // do nothing
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItemWithPath[] {
    return RouteHierarchyItem.getLeaves(routeConfiguration.schema.classification.length, routeConfiguration.configuration.children)
        .map(item => {
          item.configId = routeConfiguration.id;
          item.routeName = null;
          return item;
        });
  }

  private onVehicleStatusUpdated() {
    if (!!this.asset && !!this.routeAssignments) {
      this.updateUiItems();
    }
  }

  reloadRouteAssignments() {
    this.routeAssignmentService.getRouteAssignments(
        null,
        this.asset.id,
        this.asset.shiftId,
        null,
        null,
        true,
    ).then(response => {
      this.routeAssignments = response.data;
      this.updateUiItems();
    }).catch(error => {
      this.toast.longer('Cannot read Route Assignments from server!');
    });
  }

  private updateUiItems() {
    this.items = this.routeAssignments.map(it => this.routeAssignmentToActionMenuItem(it));
  }

  private routeAssignmentToActionMenuItem(routeAssignment: RouteAssignment): ActionMenuItem {
    const actions: ActionMenuItemSubMenu[] = [];
    const vehicleStatus = this.vehiclesStatus.get(routeAssignment.vehicleId);
    const isInProgress = this.isInProgress(vehicleStatus, routeAssignment);
    if (this.isAdmin && !isInProgress && !routeAssignment.completed) {
      actions.push(
          new ActionMenuItemSubMenu(
              'delete',
              'Delete',
              () => this.deleteRouteAssignment(routeAssignment),
          )
      );
    }
    actions.push(
        new ActionMenuItemSubMenu(
            'map',
            'View Route',
            () => this.viewRoute(routeAssignment),
        )
    );
    return new ActionMenuItem(
        `${routeAssignment.configId}_${routeAssignment.routeId}`,
        'route',
        routeAssignment.routeName,
        routeAssignment.completed ? 'Completed' : (isInProgress ? 'In Progress' : 'Pending'),
        '',
        null,
        () => false,
        null,
        () => {},
        null,
        null,
        actions,
    );
  }

  private isInProgress(vehicleStatus: VehicleRouteAssignmentStatus, routeAssignment: RouteAssignment) {
    if (!!vehicleStatus) {
      if (vehicleStatus.status === RouteAssignmentStatus.ON_ASSIGNMENT
          && vehicleStatus.routeAssignment?.configId === routeAssignment.configId
          && vehicleStatus.routeAssignment?.routeId === routeAssignment.routeId) {
        return true;
      }
    }
    return false;
  }

  toggleAssigningMode() {
    this.assigningMode = !this.assigningMode;
    this.selectedRoute = null;
    this.showConfirmDialog = false;
  }

  onRouteSelected(route: RouteHierarchyItem) {
    this.selectedRoute = route;
  }

  assignRoute(queueOrder: RouteAssignmentQueue) {
    const routeAssignment = new RouteAssignment();
    routeAssignment.configId = this.selectedRoute.configId;
    routeAssignment.routeId = this.selectedRoute.routeId;
    routeAssignment.routeName = this.selectedRoute.value;
    routeAssignment.vehicleId = this.asset.id;
    routeAssignment.shiftId = this.asset.shiftId;

    // UI validation
    if (this.routeAssignmentManager.canRouteBeAssigned(
        routeAssignment.vehicleId,
        routeAssignment.shiftId,
        routeAssignment.configId,
        routeAssignment.routeId,
        )
    ) {
      this.routeAssignmentService.createRouteAssignment(routeAssignment, queueOrder)
          .then(response => {
            this.routeAssignments = response.data;
            this.selectedRoute = null;
            this.routeSelector?.resetSelectedItem();
            this.showConfirmDialog = false;
            this.updateUiItems();
            this.toast.short(`Route '${routeAssignment.routeName}' assigned!`);
          })
          .catch(error => {
            this.toast.longer('Error while assigning route: ' + error);
          });
    } else {
      this.toast.short(`Route '${routeAssignment.routeName}' is already queued!`);
      return;
    }
  }

  deleteRouteAssignment(routeAssignment: RouteAssignment) {
    this.routeAssignmentService.deleteRouteAssignment(routeAssignment).then(() => {
      this.routeAssignments.splice(this.routeAssignments.indexOf(routeAssignment), 1);
      this.updateUiItems();
      this.toast.short(`Route '${routeAssignment.routeName}' unassigned.`);
    }).catch(error => {
      this.toast.longer(`Route '${routeAssignment.routeName}' could not be unassigned. Error: ${error}`);
    });
  }

  confirm() {
    this.showConfirmDialog = true;
  }

  cancelConfirmation() {
    this.showConfirmDialog = false;
  }

  getCurrentAssignmentInProgressIfAny(): RouteAssignment {
    if (!!this.asset) {
      const vehicleStatus = this.vehiclesStatus.get(this.asset.id);
      return vehicleStatus?.routeAssignment;
    }
    return null;
  }

  viewRoute(routeAssignment: RouteAssignment) {
    if (!!this.routeItems) {
      const route = this.routeItems.find(item => item.configId === routeAssignment.configId && item.routeId === routeAssignment.routeId);
      if (!!route) {
        const path = [String(route.configId)];
        if (!!route.path && route.path.length > 0) {
          path.push(...route.path);
        }
        path.push(route.value);
        this.router.navigate(['/live-map', LiveMapTab.ROUTES], {
          queryParams: {'path-ids': path.join(':::')}
        });
      } else {
        const msg = `Route Configuration not found! Config ID: ${routeAssignment.configId} Route ID: ${routeAssignment.routeId}`;
        console.warn(msg);
        this.toast.longer(msg);
      }
    } else {
      console.warn('Route Configuration not initialized!');
    }
  }
}
