<button
  (click)="toggleMenu()"
  mat-flat-button
  aria-label="close menu"
  class="menu__toggle__btn"
  [matTooltip]="collapseMenu ? 'Open Menu' : 'Close Menu'"
  matTooltipPosition="right"
>
  <mat-icon
    class="arrow__icon"
    [ngClass]="{ collapse: collapseMenu }"
    >chevron_left</mat-icon
  >
</button>
