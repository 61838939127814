import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {Vehicle, VehicleCategoryModel, VehicleGroup} from '../../shared/models/vehicle';
import {VehicleModelWithActiveShift} from '../../shared/models/vehicle.model';
import {VehicleLocationUpdate} from '../../shared/models/vehicle-breadcrumb';
import {ListWithUpdates} from '../../shared/models/list-with-updates.class';

@Injectable({
  providedIn: 'root'
})
export class VehiclesManagerService {

  private isInitialized = false;

  private readonly vehicleGroupsMap = new Map<number, VehicleGroup>();
  private readonly vehiclesMap = new Map<number, Vehicle>();

  // keep the latest vehicle location
  private readonly vehicleLocationsSource = new Map<number, VehicleLocationUpdate>();
  readonly vehicleLocations$ = new ReplaySubject<ListWithUpdates<VehicleLocationUpdate>>(1);

  private highlightedVehicle = new BehaviorSubject<Vehicle>(null);
  readonly highlightedVehicle$ = this.highlightedVehicle.asObservable();

  constructor() { }

  public init(categories: VehicleCategoryModel[], vehicles: VehicleModelWithActiveShift[]) {
    if (this.isInitialized) {
      throw Error('The VehiclesManagerService has already been initialized.');
    }

    for (const category of categories) {
      this.addGroup(new VehicleGroup(category.id, category.title));
    }

    for (const vehicle of vehicles) {
      const group = this.vehicleGroupsMap.get(vehicle.groupId);
      if (group === undefined) {
        throw Error(`Vehicle (${vehicle?.id}, ${vehicle?.name}) has no active group.`);
      }
      this.addVehicle(
          new Vehicle(
              vehicle.id,
              group,
              !!vehicle.label ? vehicle.label : vehicle.name,
              vehicle.licensePlate,
              vehicle.hardwareConfiguration,
              vehicle.cameraConfiguration,
          )
      );
    }
    this.isInitialized = true;
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    this.vehicleGroupsMap.clear();
    this.vehiclesMap.clear();
    this.vehicleLocationsSource.clear();

    this.isInitialized = false;
  }

  public updateVehicleLocation(vehicleLocationUpdate: VehicleLocationUpdate[]) {
    const updatesToNotify = [];
    const isMapEmpty = this.vehicleLocationsSource.size === 0;
    vehicleLocationUpdate.forEach(update => {
      if (!isMapEmpty) {
        const previousLocation = this.vehicleLocationsSource.get(update.vehicleId);
        if (!previousLocation || previousLocation.location.time !== update.location.time) {
          this.vehicleLocationsSource.set(update.vehicleId, update);
          updatesToNotify.push(update);
        }
      } else {
        this.vehicleLocationsSource.set(update.vehicleId, update);
      }
    });

    if (isMapEmpty) {
      this.vehicleLocations$.next(
          new ListWithUpdates<VehicleLocationUpdate>(
              vehicleLocationUpdate,
          )
      );
    } else {
      if (updatesToNotify.length > 0) {
        this.vehicleLocations$.next(
          new ListWithUpdates<VehicleLocationUpdate>(
              [...this.vehicleLocationsSource.values()],
              [],
              [],
              updatesToNotify,
          )
        );
      }
    }
  }

  private addGroup(newGroup: VehicleGroup) {
    if (this.vehicleGroupsMap.has(newGroup.id)) {
      throw Error(`A group with id ${newGroup.id} and title (${newGroup.title}) already exists.`);
    }

    this.vehicleGroupsMap.set(newGroup.id, newGroup);
  }

  private addVehicle(newVehicle: Vehicle) {
    if (!this.vehicleGroupsMap.has(newVehicle.group.id)) {
      throw new Error(`Unregistered vehicle group ${newVehicle.group.id}.`);
    }
    if (this.vehiclesMap.has(newVehicle.id)) {
      throw new Error(`A vehicle with id ${newVehicle.id} already exists.`);
    }
    if (newVehicle.group.vehicles.includes(newVehicle)) {
      throw new Error(`The group ${newVehicle.group.id} already contains the vehicle ${newVehicle.id}.`);
    }

    this.vehiclesMap.set(newVehicle.id, newVehicle);

    newVehicle.group.vehicles.push(newVehicle);
  }

  public getVehicle(id: number): Vehicle {
    const vehicle = this.vehiclesMap.get(id);
    if (!vehicle) {
        console.error(Error(`Vehicle id=${id} not found.`));
        // return first vehicle
        return this.vehiclesMap.entries().next().value[1];
    }
    return vehicle;
  }

  public highlightVehicle(id: number) {
    if (!!id) {
      const vehicle = this.vehiclesMap.get(id);
      if (!vehicle) {
        console.error(Error(`Vehicle id=${id} not found.`));
      }
      this.highlightedVehicle.next(vehicle);
    } else {
      this.highlightedVehicle.next(null);
    }
  }
}
