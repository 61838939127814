import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../../../../../shared/models/observation-type';

@Component({
  selector: 'app-create-observation-type-dialog',
  templateUrl: 'create-observation-type-dialog.component.html',
  styleUrls: ['create-observation-type-dialog.component.scss'],
})
export class CreateObservationTypeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CreateObservationTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
