import {Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  CartegraphFeature,
  CartegraphSettings,
  CartegraphTaskDefault,
  CartegraphTaskDefaults,
  CgDatasetEntry
} from '../../../../../../shared/models/cartegraph.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CartegraphManagementService} from '../../../../../../data/cartegraph/cartegraph-management.service';


@Component({
  selector: 'app-cartegraph-settings-defaults',
  templateUrl: './cartegraph-settings-defaults.component.html',
  styleUrls: ['./cartegraph-settings.scss'],
})
export class CartegraphSettingsDefaults implements OnInit, DoCheck {

  @Output()
  onValidityChange = new EventEmitter<boolean>(false);

  @Input() settings: CartegraphSettings;
  taskDefaults: CartegraphTaskDefaults;

  isLoading = true;
  uiError: string;

  formObservation: UntypedFormGroup;
  formShift: UntypedFormGroup;
  formPreInspection: UntypedFormGroup;
  formPostInspection: UntypedFormGroup;
  private sendShiftFeature = false;
  private sendPreInspectFeature = false;
  private sendPostInspectFeature = false;
  private sendObservationFeature = false;

  datasetPriority: CgDatasetEntry[];
  datasetStatus: CgDatasetEntry[];
  datasetActivity: CgDatasetEntry[];
  datasetWorkCompletedFor: CgDatasetEntry[];

  constructor(
    private fb: UntypedFormBuilder,
    private cartegraphManagementService: CartegraphManagementService) {
  }

  ngDoCheck(): void {
    this.taskDefaults = this.settings?.taskDefaults ?? new CartegraphTaskDefaults();
    this.sendShiftFeature = this.hasFeatureEnabled(CartegraphFeature.SEND_SHIFT);
    this.sendPreInspectFeature = this.hasFeatureEnabled(CartegraphFeature.SEND_PRE_INSPECTION);
    this.sendPostInspectFeature = this.hasFeatureEnabled(CartegraphFeature.SEND_POST_INSPECTION);
    this.sendObservationFeature = this.hasFeatureEnabled(CartegraphFeature.SEND_OBSERVATION);

    this.initShiftForm();
    this.initPreInspectForm();
    this.initPostInspectForm();
    this.initObservationForm();
    // other setup
    this.updateValidity();
  }

  ngOnInit(): void {
    this.loadDatasets().then(() => {
      this.initShiftForm();
      this.initPreInspectForm();
      this.initPostInspectForm();
      this.initObservationForm();
      // other setup
      this.updateValidity();
    });
  }

  updateValidity() {
    const valid = (this.formShift?.valid ?? true)
      && (this.formPreInspection?.valid ?? true)
      && (this.formPostInspection?.valid ?? true)
      && (this.formObservation?.valid ?? true);
    this.onValidityChange.emit(valid);
  }

  private initShiftForm() {
    if (this.sendShiftFeature) {
      this.formShift = this.fb.group({
        priority: this.fb.control(this.taskDefaults.shift?.priority, [Validators.required]),
        status: this.fb.control(this.taskDefaults.shift?.status, [Validators.required]),
        activity: this.fb.control(this.taskDefaults.shift?.activity),
        workCompletedFor: this.fb.control(this.taskDefaults.shift?.workCompletedFor),
        workCompletedForCDOT: this.fb.control(this.taskDefaults.shift?.workCompletedForCDOT),
        sectorAreaStatus: this.fb.control(this.taskDefaults.shift?.sectorAreaStatus)
      });
      this.subscribeFields(this.formShift, this.taskDefaults.shift);
      this.formShift.valueChanges.subscribe(() => {
        this.taskDefaults.shift.workCompletedForCDOT = this.formShift.controls['workCompletedForCDOT'].value;
        this.taskDefaults.shift.sectorAreaStatus = this.formShift.controls['sectorAreaStatus'].value;
      });
    } else {
      this.formShift = undefined;
    }
  }

  private initPreInspectForm() {
    if (this.sendPreInspectFeature) {
      this.formPreInspection = this.fb.group({
        priority: this.fb.control(this.taskDefaults.preInspect?.priority, [Validators.required]),
        status: this.fb.control(this.taskDefaults.preInspect?.status, [Validators.required]),
        activity: this.fb.control(this.taskDefaults.preInspect?.activity, [Validators.required]),
        workCompletedFor: this.fb.control(this.taskDefaults.preInspect?.workCompletedFor)
      });
      this.subscribeFields(this.formPreInspection, this.taskDefaults.preInspect);
    } else {
      this.formPreInspection = undefined;
    }
  }

  private initPostInspectForm() {
    if (this.sendPostInspectFeature) {
      this.formPostInspection = this.fb.group({
        priority: this.fb.control(this.taskDefaults.postInspect?.priority, [Validators.required]),
        status: this.fb.control(this.taskDefaults.postInspect?.status, [Validators.required]),
        activity: this.fb.control(this.taskDefaults.postInspect?.activity, [Validators.required]),
        workCompletedFor: this.fb.control(this.taskDefaults.postInspect?.workCompletedFor)
      });
      this.subscribeFields(this.formPostInspection, this.taskDefaults.postInspect);
    }

  }

  private initObservationForm() {
    if (this.sendObservationFeature) {
      this.formObservation = this.fb.group({
        priority: this.fb.control(this.taskDefaults.observation?.priority, [Validators.required]),
        status: this.fb.control(this.taskDefaults.observation?.status, [Validators.required]),
        activity: this.fb.control(this.taskDefaults.observation?.activity),
        workCompletedFor: this.fb.control(this.taskDefaults.observation?.workCompletedFor)
      });
      this.subscribeFields(this.formObservation, this.taskDefaults.observation);
    } else {
      this.formObservation = undefined;
    }
  }

  private subscribeFields(form: UntypedFormGroup, defaultsObject: CartegraphTaskDefault) {
    form.valueChanges.subscribe(() => {
      defaultsObject.priority = form.controls['priority'].value;
      defaultsObject.status = form.controls['status'].value;
      defaultsObject.activity = form.controls['activity'].value;
      defaultsObject.workCompletedFor = form.controls['workCompletedFor'].value;

      // on change update overall validity
      this.updateValidity();
    });
  }

  private loadDatasets() {
    return new Promise<void>((resolve) => {
      this.cartegraphManagementService.getDatasetList([
        {dataset: 'cgStatusClass', field: 'StatusField'},
        {dataset: 'cgPrioritiesClass', field: 'PriorityField'},
        {dataset: 'cgActivitiesClass', field: 'ActivityField'},
        {dataset: 'WorkCompletedForClass', field: 'WorkCompletedForField'}
      ]).then(response => {
        this.datasetPriority = response.data.find(datasetList => datasetList.name === 'cgPrioritiesClass')?.list;
        this.datasetStatus = response.data.find(datasetList => datasetList.name === 'cgStatusClass')?.list;
        this.datasetActivity = response.data.find(datasetList => datasetList.name === 'cgActivitiesClass')?.list;
        this.datasetWorkCompletedFor = response.data.find(datasetList => datasetList.name === 'WorkCompletedForClass')?.list;
      }).catch(e => {
        this.uiError = e.error;
      })
        .finally(() => {
          this.isLoading = false;
          resolve();
        });
    });
  }

  private hasFeatureEnabled(feature: CartegraphFeature): boolean {
    const found = this.settings?.features?.find(f => f.feature === feature);
    return found?.enabled ?? false;
  }

}
