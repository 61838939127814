import {Component, Input} from '@angular/core';
import {ActionMenuItem} from '../../models/action-menu-item.class';

@Component({
  selector: 'app-action-menu-item-2line',
  templateUrl: './action-menu-item-2line.component.html',
  styleUrls: ['./action-menu-item-2line.component.scss']
})
export class ActionMenuItem2LineComponent {
  @Input() input: ActionMenuItem;
}
