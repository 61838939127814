<p>
  <strong>{{ currentBreadcrumb.vehicle.label }} <span *ngIf="currentBreadcrumb.driver != null">: {{currentBreadcrumb.driver.name}}</span></strong>
</p>

<div *ngIf="hasDashCamFeatureFlag">
  <mat-spinner *ngIf="loadingImage" [diameter]="18"></mat-spinner>
  <img
          class="preview_image"
          [src]="imageData"
          [alt]="'dashcam preview'"
          (error)="onImageError()"
          (load)="onImageLoad()"
          [hidden]="imageError"
  />
  <span *ngIf="!loadingImage && imageError">No video</span>
</div>

<table>
  <tr>
    <th>Location:</th>
    <td>{{currentBreadcrumb.breadcrumb.coords | latLng}}</td>
  </tr>
  <tr>
    <th>Time:</th>
    <td>{{currentBreadcrumb.breadcrumb.time | date:'shortDate'}} {{currentBreadcrumb.breadcrumb.time | date:'mediumTime'}}</td>
  </tr>
  <tr *ngIf="currentBreadcrumb.breadcrumb.speed">
    <th>Speed:</th>
    <td>
      <span *ngIf="!!configuration">{{currentBreadcrumb.breadcrumb.speed | speed:configuration.useMetricSystem}}</span>
    </td>
  </tr>
  <tr *ngIf="currentBreadcrumb.breadcrumb.heading">
    <th>Heading:</th>
    <td>{{currentBreadcrumb.breadcrumb.heading | compass}}</td>
  </tr>
  <tr *ngIf="currentBreadcrumb.breadcrumb.gpsSource">
    <th>GPS Source:</th>
    <td>{{ currentBreadcrumb.breadcrumb.gpsSource | gpsSource }}</td>
  </tr>
  <ng-container *ngIf="currentBreadcrumb.vehicle.hardwareConfiguration &&
          currentBreadcrumb.vehicle.hardwareConfiguration.sensorInputs &&
          currentBreadcrumb.vehicle.hardwareConfiguration.sensorInputs.length > 0">
    <tr *ngFor="let sensorInput of HardwareConfiguration.getValidInputs(currentBreadcrumb.vehicle.hardwareConfiguration.sensorInputs)">
      <ng-container *ngIf="sensorInput.type === SensorType.PLOW || sensorInput.type === SensorType.PLOW2W">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Plow' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | plowStatus: currentBreadcrumb.breadcrumb.flags }}
        </td>
      </ng-container>
      <ng-container *ngIf="sensorInput.type === SensorType.GRANULAR_SPREADER">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Granular Spreader' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | spreaderStatus: currentBreadcrumb.breadcrumb.flags }}
          ({{ sensorInput.rate || 'N/A' }} {{'granular' | rateUnit:configuration.useMetricSystem}})
        </td>
      </ng-container>
      <ng-container *ngIf="sensorInput.type === SensorType.DIGITAL_GRANULAR_SPREADER && !!digitalGranularStatus">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Digital Granular Spreader' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | spreaderStatus: currentBreadcrumb.breadcrumb.flags }}
          ({{(digitalGranularStatus.controllerCurrentRate | spreaderMass:configuration.useMetricSystem) || 'N/A' }})
        </td>
      </ng-container>
      <ng-container *ngIf="sensorInput.type === SensorType.LIQUID_SPREADER">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Liquid Spreader' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | spreaderStatus: currentBreadcrumb.breadcrumb.flags }}
          ({{ sensorInput.rate || 'N/A' }} {{'liquid' | rateUnit:configuration.useMetricSystem }})
        </td>
      </ng-container>
      <ng-container *ngIf="sensorInput.type === SensorType.DIGITAL_LIQUID_SPREADER && !!digitalLiquidStatus">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Digital Liquid Spreader' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | spreaderStatus: currentBreadcrumb.breadcrumb.flags }}
          ({{(digitalLiquidStatus.controllerCurrentRate | spreaderLiquid:configuration.useMetricSystem) || 'N/A' }})
        </td>
      </ng-container>
      <ng-container *ngIf="sensorInput.type === SensorType.MOWER">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Mower' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | mowerStatus: currentBreadcrumb.breadcrumb.flags }}
        </td>
      </ng-container>
      <ng-container *ngIf="sensorInput.type === SensorType.SWEEPER">
        <th>
          {{ !!sensorInput.label ? sensorInput.label : 'Sweeper' }}
        </th>
        <td>
          {{ sensorInput.bitNumber | sweeperStatus: currentBreadcrumb.breadcrumb.flags }}
        </td>
      </ng-container>
    </tr>
  </ng-container>
</table>

<div *ngIf="isLiveMap && !isShiftActive(currentBreadcrumb.shiftId)">
  <a [routerLink]="['/shift-detail', currentBreadcrumb.shiftId]"
    class="view-link">Open Shift Detail</a>
</div>

<div *ngIf="currentBreadcrumb.breadcrumb.imageUrl"
     class="dash-cam-image-thumbnail">
  <app-retrying-image-viewer [imageUrl]="currentBreadcrumb.breadcrumb.imageUrl"
                             [alternativeText]="'Dashboard Camera Image'"></app-retrying-image-viewer>
</div>

<mat-paginator *ngIf="breadcrumbs.length > 1"
               [length]="breadcrumbs.length"
               [pageSize]="1"
               [hidePageSize]="true"
               [showFirstLastButtons]="true"
               (page)="handlePageEvent($event)">
</mat-paginator>
