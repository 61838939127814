<div class="my-25">
    <mat-card appearance="outlined">

        <mat-card-header>
            <mat-card-title>Fetch/download data from Cartegraph</mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="isLoading"  class="mt-25">
            <div *ngIf="isLoading" class="loading__wrapper">
                <mat-spinner></mat-spinner>
            </div>
        </mat-card-content>

        <mat-card-content *ngIf="!isLoading"  class="mt-25">
            <p>Fetch the data from Cartegraph to be available for next steps in the setup.</p>
            <p>Note: this may take a while.</p>

            <div *ngIf="!isLoading && uiError && uiError.length > 0" class="content mb-25">
                <span class="ui-error">{{ uiError }}</span>
            </div>
        </mat-card-content>

        <mat-card-actions *ngIf="!isLoading">
            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="fetchAllData()"
                    [disabled]="configuration.baseUrl==null || isLoading">
                <mat-icon>cloud_download</mat-icon>
                Fetch data
            </button>
        </mat-card-actions>

        <mat-card-footer *ngIf="!isLoading">
            <div *ngIf="fetched" class="success">Data successfully downloaded</div>
        </mat-card-footer>

    </mat-card>
</div>
