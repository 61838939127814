import {MifNode} from './MifNode';

export class MifMoveViolation {

  static MSG_MOVE_INTO_ITSELF = 'Cannot move a node into itself';
  static MSG_SECTION_UNDER_SECTION = 'Section cannot be placed under another section';
  static MSG_SECTION_UNDER_GROUP = 'Section cannot be placed under a group';
  static MSG_SECTION_UNDER_QUESTION = 'Section cannot be placed under a question';
  static MSG_GROUP_UNDER_FORM = 'Group cannot be placed directly under a form';
  static MSG_GROUP_UNDER_GROUP = 'Group cannot be placed under another group';
  static MSG_GROUP_UNDER_QUESTION = 'Group cannot be placed under a question';
  static MSG_QUESTION_UNDER_QUESTION = 'Question cannot be placed under another question';
  static MSG_MOVE_INTO_CURRENT_PARENT = 'Node is already there';
  static MSG_MOVE_UNDER_OWN_CHILD = 'Cannot move a node under its own child';

  message: string;

  constructor(message: string) {
    this.message = message;
  }

  static from(message: string): MifMoveViolation {
    return new MifMoveViolation(message);
  }

  static checkMoveViolations(source: MifNode, target: MifNode, toSpecificIndex: boolean, targetIndex: number): MifMoveViolation {

    if (source.hasSameInternalIdAs(target)) {
      return MifMoveViolation.from(MifMoveViolation.MSG_MOVE_INTO_ITSELF);

    } else if (source.isSection() && target.isSection()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_SECTION_UNDER_SECTION);

    } else if (source.isSection() && target.isGroup()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_SECTION_UNDER_GROUP);

    } else if (source.isSection() && target.isQuestion()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_SECTION_UNDER_QUESTION);

    } else if (source.isGroup() && target.isForm()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_GROUP_UNDER_FORM);

    } else if (source.isGroup() && target.isGroup()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_GROUP_UNDER_GROUP);

    } else if (source.isGroup() && target.isQuestion()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_GROUP_UNDER_QUESTION);

    } else if (source.isQuestion() && target.isQuestion()) {
      return MifMoveViolation.from(MifMoveViolation.MSG_QUESTION_UNDER_QUESTION);

    } else if (!toSpecificIndex && source.isDirectChildOfTarget(target)) {
      return MifMoveViolation.from(MifMoveViolation.MSG_MOVE_INTO_CURRENT_PARENT);

    } else if (target.hasSuchParentNode(source)) {
      return MifMoveViolation.from(MifMoveViolation.MSG_MOVE_UNDER_OWN_CHILD);

    } else {
      return null;
    }
  }
}
