import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gpsSource'
})
export class GpsSourcePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!!value) {
      switch (value) {
        case 2: return 'Android Device with LMU';
        case 3: return 'Android Device';
        case 4: return 'LMU';
        case 5: return 'LMU Device';
        default: return 'N/A';
      }
    } else {
      return 'N/A';
    }
  }

}
