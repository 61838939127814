import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {MatSnackBar} from '@angular/material/snack-bar';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {ImportResultRow} from '../../../../../shared/models/cartegraph.model';


@Component({
  selector: 'app-import-vehicles-cartegraph',
  templateUrl: './import-vehicles-cartegraph.component.html',
  styleUrls: ['./import-vehicles-cartegraph.component.scss'],
})
export class ImportVehiclesCartegraphComponent {

  @Output() changePageEvent = new EventEmitter<string>();

  uiError: string;

  ImportVehiclesStateEnum = ImportVehiclesStateEnum;
  currentState = ImportVehiclesStateEnum.NOOP;

  importKeys: { name: string, label: string }[] = [
    {name: 'added', label: 'Added'},
    {name: 'activated', label: 'Updated or reactivated'},
    {name: 'deactivated', label: 'Deactivated'},
    {name: 'skipped', label: 'Skipped'},
    {name: 'error', label: 'Errors'}];

  importResult: { key: string; list: ImportResultRow[] };

  constructor(public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private cartegraphManagementService: CartegraphManagementService) {
  }

  changePage(page?: string) {
    this.changePageEvent.emit(page);
  }

  doImport() {
    this.currentState = ImportVehiclesStateEnum.WORKING;
    this.uiError = null;
    this.cartegraphManagementService.importVehicles().then(response => {
      if (response.error) {
        this.uiError = response.error;
        this.currentState = ImportVehiclesStateEnum.NOOP;
      } else {
        this.importResult = response.data;
        this.currentState = ImportVehiclesStateEnum.IMPORTED;
      }
    }).catch(error => {
      this.uiError = error;
      this.currentState = ImportVehiclesStateEnum.NOOP;
    });
  }

  doCancel() {
    this.currentState = ImportVehiclesStateEnum.WORKING;
    this.currentState = ImportVehiclesStateEnum.NOOP;
    this.uiError = null;
    this.changePage();
  }

  importDisabled(): boolean {
    return false; // return this.validData.filter(row => row.action !== 'NONE').length === 0;
  }

}

enum ImportVehiclesStateEnum {
  NOOP,
  WORKING,
  IMPORTED
}
