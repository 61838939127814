import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RouteStyle} from '../../../../../shared/models/route';

@Component({
  selector: 'app-route-style-editor',
  templateUrl: './route-style-editor.component.html',
  styleUrls: ['./route-style-editor.component.scss']
})
export class RouteStyleEditorComponent implements OnInit {

  @Input() routeStyle: RouteStyle;
  @Output() routeStyleChanged: EventEmitter<RouteStyle> = new EventEmitter<RouteStyle>();

  constructor() { }

  ngOnInit(): void {
  }

  styleChanged() {
    this.routeStyleChanged.emit(this.routeStyle);
  }
}
