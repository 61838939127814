import {Injectable} from '@angular/core';
import {SettingsService, TracksLayerFilter} from '../../../../configuration/settings.service';
import {Subscription} from 'rxjs';
import {MapLayersManager} from '../map-layers-manager';
import {
  GeoJSONSourceSpecification,
  LayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification
} from 'maplibre-gl';
import {environment} from '../../../../../environments/environment';
import {ShiftWithDriverAndVehicleModel} from '../../../models/shift.model';
import {HardwareConfiguration} from '../../../models/vehicle.model';
import {MapFilters} from '../../../../configuration/map-filters';
import moment from 'moment';
import {ShiftsService} from '../../../../data/shifts/shifts.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftTrackLayerService {

  static readonly SHIFT_TRACK_SOURCE_ID = 'shift-track-source';
  static readonly LAYER_ID_TRACK = 'shift-track';
  static readonly LAYER_ID_PLOW_DOWN = 'shift-track-plow-down';
  static readonly LAYER_ID_SPREADER_ON = 'shift-track-spreader-on';
  static readonly LAYER_ID_ARROWS = 'shift-track-arrows';
  static readonly ICON_ARROW = 'arrow';

  private mapLayersManager: MapLayersManager;
  private shiftTrackLayers: LayerSpecification[] = [];

  private readonly openSubscriptions = Array<Subscription>();
  shift: ShiftWithDriverAndVehicleModel = null;
  shiftId: number = null;
  shiftStart: number = null;
  shiftLength: number = null;

  featureCollection = {
    type: 'FeatureCollection',
    features: []
  };
  filterFrom = 0;
  filterTo = 100;
  private vehicleHardwareConfiguration: HardwareConfiguration;

  constructor(private settingsService: SettingsService,
              private shiftsService: ShiftsService,
              private configurationService: ConfigurationService,
  ) { }

  init(shift: ShiftWithDriverAndVehicleModel, mapLayersManager: MapLayersManager) {
    this.shift = shift;
    this.shiftId = shift?.id;
    this.shiftStart = !!shift ? moment(shift.start).unix() : null;
    this.shiftLength = !!shift ? moment(shift.end).diff(moment(shift.start), 'seconds') : null;

    if (!!this.mapLayersManager) {
      throw Error('The mapLayersManager has already been set yet.');
    }

    this.mapLayersManager = mapLayersManager;

    // source: https://fonts.google.com/icons?selected=Material+Icons:menu
    this.mapLayersManager.loadImage(
        ShiftTrackLayerService.ICON_ARROW,
        `${environment.base_href}assets/baseline_expand_less_black_24dp.png`,
        { sdf: false }
    );

    this.initializeSourceAndLayers();

    const that = this;
    const changesSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.SHIFT_TRACK_TIME_FILTER) {
          const valueFromSettings = newSettings.value;
          if (valueFromSettings) {
            that.filterFrom = +valueFromSettings.substring(0, valueFromSettings.indexOf('_'));
            that.filterTo = +valueFromSettings.substring(valueFromSettings.indexOf('_') + 1);
          }
          that.handleTimeFilterChanged();
        }
      }
    });
    this.openSubscriptions.push(changesSubscription);
  }

  setShift(shiftId: number, shift: ShiftWithDriverAndVehicleModel, vehicleId: number) {
    this.shiftId = shiftId;
    this.vehicleHardwareConfiguration = !!shift ? shift.vehicle.hardwareConfiguration : null;
    if (!!this.shiftId) {
      this.shiftsService.getShiftTrack(this.shiftId, vehicleId).then(response => {
        this.featureCollection = response;
        this.updateSource();
        this.makeBreadcrumbsLayerVisible();
      });
    } else {
      this.hideBreadcrumbsLayer();
    }
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The mapLayersManager has not been set yet.');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;

    this.shiftTrackLayers = [];
    this.mapLayersManager = null;
    this.filterFrom = 0;
    this.filterTo = 100;
  }

  initializeSourceAndLayers() {
    this.mapLayersManager.addSource(ShiftTrackLayerService.SHIFT_TRACK_SOURCE_ID, this.getSource());

    // add layers
    const isVisible = this.shiftId ? 'visible' : 'none';
    this.getMapLayers().forEach(layer => {
      layer.layout.visibility = isVisible;
      this.shiftTrackLayers.push(layer);
      this.mapLayersManager.addLayer(layer as LineLayerSpecification | SymbolLayerSpecification);
    });

    this.setShift(this.shiftId, this.shift, this.shift?.vehicleId);
  }

  private handleLayerVisibilityChanged(enableBreadcrumbsLayer: boolean) {
    if (enableBreadcrumbsLayer) {
      this.makeBreadcrumbsLayerVisible();
    } else {
      this.hideBreadcrumbsLayer();
    }
  }

  private makeBreadcrumbsLayerVisible() {
    if (this.shiftId === null || this.shiftId === undefined) {
      // console.warn('Shift ID is null');
      return;
    }

    this.shiftTrackLayers.forEach(lineLayer => {
      this.mapLayersManager.setLayerVisibility(lineLayer.id, true);
    });
  }

  private hideBreadcrumbsLayer() {
    this.shiftTrackLayers.forEach(lineLayer => {
      this.mapLayersManager.hideLayer(lineLayer);
    });
  }

  private handleTimeFilterChanged() {
    this.updateFilters();
  }

  private getAbsTime(relativePosition: number): number {
    // unknown start or length
    if (!this.shiftStart || !this.shiftLength) {
      return null;
    }
    // do not filter start or end at all
    if (relativePosition === 0 || relativePosition === 100) {
      return null;
    }
    return Math.round(this.shiftStart + relativePosition / 100 * this.shiftLength);
  }

  private getSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.featureCollection
    } as GeoJSONSourceSpecification;
  }

  private updateSource() {
    this.mapLayersManager.getGeoJsonSource(ShiftTrackLayerService.SHIFT_TRACK_SOURCE_ID)
        ?.setData(this.featureCollection as any);
  }

  private getMapLayers(): LayerSpecification[] {
    return [
      {
        id: ShiftTrackLayerService.LAYER_ID_TRACK,
        type: 'line',
        source: ShiftTrackLayerService.SHIFT_TRACK_SOURCE_ID,
        filter: MapFilters.getShiftGeoJsonFilter(
            TracksLayerFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowNormal.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowNormal.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowNormal.width,
        }
      } as LineLayerSpecification,
      {
        id: ShiftTrackLayerService.LAYER_ID_PLOW_DOWN,
        type: 'line',
        source: ShiftTrackLayerService.SHIFT_TRACK_SOURCE_ID,
        filter: MapFilters.getShiftGeoJsonFilter(
            TracksLayerFilter.PLOWING_MOWING_SWEEPING, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowPlowDown.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowPlowDown.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowPlowDown.width,
          'line-offset': this.configurationService.trackStyles.shiftPlowPlowDown.offset,
        }
      } as LineLayerSpecification,
      {
        id: ShiftTrackLayerService.LAYER_ID_SPREADER_ON,
        type: 'line',
        source: ShiftTrackLayerService.SHIFT_TRACK_SOURCE_ID,
        filter: MapFilters.getShiftGeoJsonFilter(
            TracksLayerFilter.SPREADING, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowSpreaderOn.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowSpreaderOn.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowSpreaderOn.width,
          'line-offset': this.configurationService.trackStyles.shiftPlowSpreaderOn.offset,
        }
      } as LineLayerSpecification,
      {
        id: ShiftTrackLayerService.LAYER_ID_ARROWS,
        type: 'symbol',
        source: ShiftTrackLayerService.SHIFT_TRACK_SOURCE_ID,
        filter: MapFilters.getShiftGeoJsonFilter(
            TracksLayerFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
        ),
        layout: {
          'symbol-placement': 'line',
          'icon-image': ShiftTrackLayerService.ICON_ARROW,
          'icon-rotate': 90,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true
        },
        paint: {
        }
      } as SymbolLayerSpecification,
    ];
  }

  private updateFilters() {
    this.shiftTrackLayers.forEach(lineLayer => {
      switch (lineLayer.id) {
        case ShiftTrackLayerService.LAYER_ID_TRACK:
        case ShiftTrackLayerService.LAYER_ID_ARROWS:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              MapFilters.getShiftGeoJsonFilter(
                  TracksLayerFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
              )
          );
          break;
        case ShiftTrackLayerService.LAYER_ID_PLOW_DOWN:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              MapFilters.getShiftGeoJsonFilter(
                  TracksLayerFilter.PLOWING_MOWING_SWEEPING, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
              )
          );
          break;
        case ShiftTrackLayerService.LAYER_ID_SPREADER_ON:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              MapFilters.getShiftGeoJsonFilter(
                  TracksLayerFilter.SPREADING, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
              )
          );
          break;
      }
    });
  }
}
