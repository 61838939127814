
export class TrackStyle {
  id: string;
  color: string;
  opacity: number;
  blur: number;
  width: number;
  offset: number;
  dasharray: number[];

  constructor(id: string, color: string, opacity: number, blur: number, width: number, offset: number, dasharray: number[]) {
    this.id = id;
    this.color = color;
    this.opacity = opacity;
    this.blur = blur;
    this.width = width;
    this.offset = offset;
    this.dasharray = dasharray;
  }
}
