<ng-container>
    <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>GPS Tracks</span>
        <div class="title__suffix"></div>
    </div>
    <div class="content" *ngIf="!!configuration">
        <div class="configuration__row">
            <span>Recently Ended Shifts</span>
            <div>
                <mat-slide-toggle
                        class="mat-small"
                        [(ngModel)]="showRecent"
                        (change)="toggleRecentlyEnded()"
                ></mat-slide-toggle>
            </div>
        </div>
        <div class="configuration__row">
            <span>Live Track Duration</span>
        </div>
        <div class="configuration__row">
            <mat-slider
                    color="accent"
                    showTickMarks
                    step="1.0"
                    min="1.0"
                    [max]="configuration.lookBackPeriod"
                    aria-label="unit(s)"
                    [disabled]="false"
                    style="width: 100%"
            >
                <input matSliderThumb (change)="updateTimeLimit()" [(ngModel)]="timeLimit"/>
            </mat-slider>
        </div>
        <div class="configuration__row">
            <span>1 hr</span>
            <span>{{ configuration.lookBackPeriod }} hr</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="hasFeatureFlag(FeatureFlagEnum.RoadStatus) && isAdmin">
    <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>Coverage</span>
        <div class="title__suffix"></div>
    </div>
    <div class="content" *ngIf="!loadingStyles">
        <app-location-history-category-view
                [layerType]="coverageLayerTypeFilter"
                (colorsChangedEvent)="coverageColorsChanged($event)"
        ></app-location-history-category-view>
    </div>
</ng-container>

<ng-container *ngIf="hasFeatureFlag(FeatureFlagEnum.RoadStatus) && isAdmin">
    <div class="title__wrapper">
        <div class="title__prefix"></div>
        <span>Currency</span>
        <div class="title__suffix"></div>
    </div>
    <div class="content" *ngIf="!loadingStyles">
        <app-location-history-category-view
                [layerType]="currencyLayerTypeFilter"
                (colorsChangedEvent)="currencyColorsChanged($event)"
        ></app-location-history-category-view>

        <div class="configuration__row">
            <button mat-icon-button
                    color="accent"
                    *ngIf="isButtonVisibleSave()"
                    [matTooltip]="'Save'"
                    (click)="buttonClickedSave()">
                <mat-icon>save</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    *ngIf="isButtonVisibleCancel()"
                    [matTooltip]="'Cancel'"
                    (click)="buttonClickedCancel()">
                <mat-icon>cancel</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    *ngIf="isButtonVisibleReset()"
                    [matTooltip]="'Reset to defaults'"
                    (click)="buttonClickedReset()">
                <mat-icon>sync</mat-icon>
            </button>
        </div>
    </div>
</ng-container>
