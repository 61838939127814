import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {AssetRightPanel} from '../assets/asset-right-panel.class';
import {ShiftState} from 'src/app/shared/models/shift.model';
import {FeatureFlagEnum} from 'src/app/shared/models/configuration.model';
import {ImageModel} from '../../../../../shared/models/image';
import {ImagesManagerService} from '../../../../../data/images/images-manager.service';
import {Subscription} from 'rxjs';
import {VideoStreamingService} from '../../../../../data/video-streaming/video-streaming.service';
import {timeout} from 'rxjs/operators';

@Component({
  selector: 'app-live-video',
  templateUrl: './live-video.component.html',
  styleUrls: ['./live-video.component.scss']
})
export class LiveVideoComponent extends AssetRightPanel implements OnInit, OnDestroy {

  loadingImages = false;
  images: ImageModel[];

  ShiftState = ShiftState;
  FeatureFlagEnum = FeatureFlagEnum;
  imageSubscription: Subscription;
  streaming = false;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected configurationService: ConfigurationService,
    protected assetsManager: AssetsManagerService,
    private videoStreamingService: VideoStreamingService,
    private imageManager: ImagesManagerService,
  ) {
    super(
      router,
      activatedRoute,
      configurationService,
      assetsManager,
    );
  }

  ngOnInit() {
    this.initializeAssets();
    this.loadConfiguration();
  }

  ngOnDestroy() {
    this.configSubscription?.unsubscribe();
    this.imageSubscription?.unsubscribe();
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration?.featureFlags?.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  onAssetChange() {
    this.loadImages();
  }

  onConfigurationLoad() {
    this.loadImages();
  }

  private loadImages() {
    if (this.hasFeatureFlag(FeatureFlagEnum.DashCam) && this.asset?.cameraConfiguration?.streaming?.captureImageInterval > 0) {
      this.loadingImages = true;
      if (!!this.imageSubscription) {
        this.imageSubscription.unsubscribe();
      }
      this.imageSubscription = this.imageManager.filteredImages$.subscribe(images => {
        this.images = images;
        this.loadingImages = false;
      });
    }
  }

  startStreaming() {
    if (this.hasFeatureFlag(FeatureFlagEnum.DashCam) && this.asset?.cameraConfiguration?.streaming?.captureStreamDuration > 0) {
      this.streaming = true;
      this.videoStreamingService.sendVideoStreamEvent(this.asset.driverId).then(() => {
        // disable the button for a stream duration set for this vehicle
        // half of the time at least, and note, it must be in milliseconds
        const duration = ((this.asset?.cameraConfiguration?.streaming?.captureStreamDuration || 10) * 1000) / 2;
        // timeout after the duration ...
        setTimeout(() => {
          this.streaming = false;
        }, (duration));
      }).catch(() => {
      });
    }
  }
}
