import {LatLngModel} from './lat.lng.model';
import {MapLayerType} from './map-layer.model';
import {BaseMapType} from '../../configuration/settings.service';

export class ConfigurationModel {
  readonly productName: string;
  readonly id: number;
  readonly customerName: string;
  readonly logoUrl: string;
  readonly driverSuffix: string;
  readonly additionalLayers: MapLayer[];
  /**
   * Number of hours for how long to keep the data.
   * If not provided then let's use 12h as default.
   */
  readonly lookBackPeriod: number;
  readonly region: LatLngModel[];
  readonly featureFlags: FeatureFlag[];
  readonly useMetricSystem: boolean;
  readonly weatherLocation: Point;
}

export class MapLayer {
  type: MapLayerType;
  name: string;
  url: string;
  configuration: string;
  routeConfigurationId: number;
  tokenRequired: boolean;

  // driven by settings
  checked = false;
}

export class FeatureFlag {
  readonly name: string;
  readonly label: string;
  readonly isEnabled: boolean;
}

export enum FeatureFlagEnum {
  AddressLookup = 'address_lookup',
  StationaryAlert = 'stationary_alert',
  RoadStatus = 'heat_layer',
  ReportDvir = 'report_dvir',
  ReportImage = 'report_image',
  ReportShift = 'report_shift',
  FleetMessaging = 'fleet_messaging',
  Poi = 'poi',
  Weather = 'weather',
  CartegraphIntegration = 'cartegraph_integration',
  CartegraphIntegrationNewDvir = 'cartegraph_integration_new_dvir',
  GuestDrivers = 'guest_drivers',
  DashCam = 'dashcam',
}

export class PublicPortalSettings {
  title: string;
  titleColor?: string;
  titleBackgroundColor?: string;
  headline: Headline[];
  logoLink: string;
  forceScrollingHeadline: boolean;
  scrollingSpeed: number;
  showVehicleMarkers: boolean;
  tracksLayer: PublicPortalTrackLayer;
  excludeVehicleGroupIds: number[];
  routeStatusLayerConfig: RouteStatusLayerConfig;
  hideHeader: boolean;
  hideMessageBar: boolean;
  baseMapLayer: BaseMapType;
}

export enum PublicPortalTrackLayer {
  NONE = 'None',
  GPS = 'Gps',
  CURRENCY = 'Currency',
  ROUTE_STATUS = 'RouteStatus',
}

export class RouteStatusLayerConfig {
  static THRESHOLD_VALUES: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 24, 30, 36, 48, 72];

  routeConfigurationId: number;
  servicedThreshold: number;
}

export class Headline {
  text: string;
  selected?: boolean;
  color?: string;
  backgroundColor?: string;
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
