<div class="filter-bar-container">
    <div class="filter-bar-start">
        <button mat-button
                color="accent"
                (click)="toggleFilterBar()">
            <div class="menu-button">
                <span>Filter</span>
                <mat-icon [ngClass]="{'open-filter': showingFilterBar}">arrow_drop_down</mat-icon>
            </div>
        </button>

        <div class="chip-list"
             [style.display]="!showingFilterBar ? 'none' : 'flex'"
        >

            <mat-chip-listbox>
                <mat-chip-option *ngFor="let chip of filterChips"
                                 [removable]="true"
                                 [selectable]="false"
                                 (removed)="filterChipRemoved($event, chip)"
                                 (click)="filterChipSelected($event, chip)"
                >
                    <span>{{ chip.filterName }}</span>&nbsp;<span class="text-secondary">{{ chip.operator}}</span>&nbsp;<span>{{ chip.items }}</span>
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-option>
            </mat-chip-listbox>

            <button mat-icon-button
                    #matTriggerAdd="matMenuTrigger"
                    [matMenuTriggerFor]="filtersListMenu"
                    [ngClass]="{hidden: allFiltersSet() }"
            >
                <mat-icon>add</mat-icon>
            </button>

            <mat-menu #filtersListMenu="matMenu">
                <button mat-menu-item
                        #matTriggerUserRole="matMenuTrigger"
                        [matMenuTriggerFor]="userRoleMenu"
                        [ngClass]="{hidden: !!userRoleFilter}">
                    User Role
                </button>
            </mat-menu>

            <mat-menu #userRoleMenu="matMenu">
                <app-user-role-filter
                        [origin]="origin"
                        (filterChanged)="onUserRoleFilterChanged($event)"
                ></app-user-role-filter>
            </mat-menu>
        </div>

        <div class="ml-10" [style.display]="!showingFilterBar ? 'none' : 'flex'">
            <mat-form-field color="accent" appearance="outline" subscriptSizing="dynamic">
                <mat-label>Search</mat-label>
                <input matInput #search
                       placeholder="Search"
                       autocomplete="off"
                       [(ngModel)]="searchFilter"
                       (keyup.enter)="onSearchChanged($event)">
                <button mat-icon-button matSuffix (click)="searchFilter = null; onSearchChanged($event)" *ngIf="search.value">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-form-field>
        </div>

    </div>

    <div *ngIf="!showingFilterBar && !noFiltersSet()">
        <button mat-button
                color="accent"
                (click)="clearFilters()"
        >
            Clear Filters
        </button>
    </div>
</div>
