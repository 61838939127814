<div class="assets-container">

    <div *ngFor="let item of items">
        <app-action-menu-item-2line
            [input]="item"
        ></app-action-menu-item-2line>
    </div>

    <div *ngIf="!!assets && assets.length === 0" class="no-items">
        No Results match current filters
    </div>
</div>
