<div class="drawer-container" *ngIf="isDisplayed">
    <div class="drawer-header">
        <div class="drawer-header__left">
            <mat-icon color="primary">
                {{ selectedDrawer.headerIcon }}
            </mat-icon>
            <span>
                {{ selectedDrawer.headerText }}
            </span>
        </div>
        <div class="drawer-header__right">
            <button mat-icon-button (click)="hideDrawer()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="!isShiftDetail" [ngSwitch]="drawer" class="drawer-body">
        <ng-container *ngSwitchCase="DrawerContent.QUICK_MESSAGE">
            <app-quick-message></app-quick-message>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.LIVE_VIDEO">
            <app-live-video></app-live-video>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.ROUTE">
            <app-quick-route-assignment></app-quick-route-assignment>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.OBSERVATION">
            <app-driver-observations></app-driver-observations>
        </ng-container>
    </div>
    <div *ngIf="isShiftDetail && !!shift" [ngSwitch]="drawer" class="drawer-body">
        <ng-container *ngSwitchCase="DrawerContent.LIVE_VIDEO">
            <app-shift-video [shift]="shift"></app-shift-video>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.ROUTE">
            <app-shift-assignments [shift]="shift"></app-shift-assignments>
        </ng-container>
        <ng-container *ngSwitchCase="DrawerContent.OBSERVATION">
            <app-shift-observations [shift]="shift"></app-shift-observations>
        </ng-container>
    </div>
</div>

