import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MapPanelComponent} from '../../../shared/components/map-viewer/map-panel/map-panel.component';
import {ShiftWithDriverAndVehicleModel} from '../../../shared/models/shift.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ShiftsService} from '../../../data/shifts/shifts.service';
import {TrackBoundingBox} from '../../../shared/models/TrackBoundingBox';
import {select, Store} from '@ngrx/store';
import {ObservationsService} from '../../../data/observations/observations.service';
import moment from 'moment';
import {ConfigurationModel, FeatureFlagEnum} from '../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {LngLatBounds} from 'maplibre-gl';
import {ToastService} from '../../../shared/services/toast.service';
import {DrawerContent} from '../../../layouts/right-drawer/right-drawer.component';
import {debounceTime} from 'rxjs/operators';
import {MapControlService} from '../../../shared/components/map-viewer/services/map-control.service';
import {ShiftMapDataService} from '../services/shift-map-data.service';
import {ObservationsManagerService} from '../../../data/observations/observations-manager.service';
import {ObservationManagementService} from '../../../data/observations/observation-management.service';
import {ImagesManagerService} from '../../../data/images/images-manager.service';
import {InsightsRoute} from '../../insights/insights-routing.module';

@Component({
  selector: 'app-shift-map',
  templateUrl: './shift-map.component.html',
  styleUrls: ['./shift-map.component.scss']
})
export class ShiftMapComponent implements OnInit, OnDestroy {

  configuration: ConfigurationModel;
  configurationSubscription: Subscription;
  sidenavOpen$?: Observable<boolean>;
  activeDrawer: DrawerContent;

  shift: ShiftWithDriverAndVehicleModel;

  source: string = InsightsRoute.SHIFT_LOOKUP;
  bounds: LngLatBounds;

  private mapResizeObserver: ResizeObserver;
  private mapResizeObserverSubscription: Subscription;
  @ViewChild('mapContent') private mapContainer: ElementRef<HTMLElement>;
  @ViewChild(MapPanelComponent) mapComponent: MapPanelComponent;

  isLoading = true;
  isMapConfigLoading = true;

  DrawerContent = DrawerContent;
  FeatureFlagEnum = FeatureFlagEnum;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store<any>,
              private configurationService: ConfigurationService,
              private shiftService: ShiftsService,
              private shiftMapDataService: ShiftMapDataService,
              private observationService: ObservationsService,
              private observationManager: ObservationsManagerService,
              private observationGroupsService: ObservationManagementService,
              private imageManager: ImagesManagerService,
              private mapControlService: MapControlService,
              private toast: ToastService) { }

  ngOnInit(): void {
    this.sidenavOpen$ = this.store.pipe(
        select((state) => state.ui.sidenavOpen)
    );

    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;

        const shiftId = +this.activatedRoute.snapshot.paramMap.get('id');
        Promise.all([
          this.shiftService.getShiftInfo(shiftId).toPromise(),
          this.observationService.getObservationTypes().toPromise(),
          this.observationGroupsService.getObservationTypeGroups().toPromise(),
        ]).then(responses => {
          const [shiftInfoResponse, typesResponse, typeGroupResponse] = responses;

          this.shift = shiftInfoResponse.data;
          this.shiftMapDataService.sendShift(this.shift);

          this.observationManager.init(
              this.shift,
              typesResponse.data,
              typeGroupResponse.data,
          );
          this.imageManager.init(
              this.shift,
              this.configuration,
          );

          this.loadMapConfiguration(this.shift.id);
          this.isLoading = false;
        }).catch(message => {
          console.error(message);
          this.isLoading = false;
        });
      }
    });

    const source = this.activatedRoute.snapshot.queryParams['source'];
    if (!!source) {
      this.source = source;
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.activeDrawer = params.drawer;
    });
  }

  ngOnDestroy() {
    this.observationManager.release();
    this.imageManager.release();
    this.configurationSubscription?.unsubscribe();
    this.mapResizeObserver?.disconnect();
    this.mapResizeObserverSubscription?.unsubscribe();
    this.shiftMapDataService.sendShift(null);
  }

  private subscribeToMapResize() {
    const resizeObservable = new Observable<void>(subscriber => {
      this.mapResizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          subscriber.next();
        });
      });
    });

    // added delay to allow mapContainer to be initialized
    setTimeout(() => {
      this.mapResizeObserverSubscription = resizeObservable
          .pipe(debounceTime(200))
          .subscribe({ next: () => {
              if (!this.activatedRoute.snapshot.queryParams?.drawer) {
                console.log('Resizing maplibre component explicitly.');
                this.mapControlService.resizeMap();
              }
            } });
      this.mapResizeObserver.observe(this.mapContainer?.nativeElement);
    }, 2000);
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  showDrawerPanel(drawer: DrawerContent) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { drawer },
      queryParamsHandling: 'merge',
    });
  }

  private loadMapConfiguration(shiftId: number) {
    this.shiftService.getShiftTrackBoundingBox(shiftId).toPromise().then(response => {
      const boundingBox = response.data;
      if (!boundingBox) {
        this.toast.long('No bounding box found!');
        console.error('No bounding box found');
      } else {
        this.bounds = TrackBoundingBox.toLngLatBounds(boundingBox);
      }

      this.isMapConfigLoading = false;
      this.subscribeToMapResize();
    }).catch(message => {
      // on error display Castle Rock area
      console.error(message);
      this.isMapConfigLoading = false;
    });
  }

  getShiftLength(): number {
    return moment(this.shift.end).diff(moment(this.shift.start), 'seconds');
  }
}
