<p>
  <strong>{{ observation.observationType.title }}</strong>
</p>

<table>
  <tr *ngIf="isLiveMap">
    <th>Vehicle:</th>
    <td>
      <a [routerLink]="['/live-map', LiveMapTab.ASSETS, observation.vehicle.id]"
         [class.hidden-link]="isLinkActive('/live-map/' + LiveMapTab.ASSETS)"
         class="view-link"
      >
        {{ observation.vehicle.label | uppercase }}
      </a>
      <span *ngIf="isLinkActive('/live-map/' + LiveMapTab.ASSETS)">
        {{ observation.vehicle.label | uppercase }}
      </span>
    </td>
  </tr>
  <tr>
    <th>Location:</th>
    <td (click)="handleLocationClick($event)">{{this.observation.location.coords | latLng}}</td>
  </tr>
  <tr>
    <th>Time:</th>
    <td>{{this.observation.location.time | date:'shortDate'}} {{this.observation.location.time | date:'mediumTime'}}</td>
  </tr>
</table>

<div *ngIf="isLiveMap">
  <a [routerLink]="['/live-map', LiveMapTab.OBSERVATIONS]"
     [queryParams]="{id: this.observation.id, scrollToView: true}"
     [class.hidden-link]="isLinkActive('/live-map/' + LiveMapTab.OBSERVATIONS)"
     class="view-link"
  >
    View Observation Detail
  </a>
</div>

<div *ngIf="this.observation.location.imageUrl"
     class="dash-cam-image-thumbnail">
  <app-retrying-image-viewer [imageUrl]="this.observation.location.imageUrl"
                             [alternativeText]="'Dashboard Camera Image'"></app-retrying-image-viewer>
</div>
