import {Component, Inject} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SecurityService} from '../../../../../security/security.service';
import {environment} from '../../../../../../environments/environment';
import {Moment} from 'moment';
import {MultiSelectFilter} from '../../../../../shared/components/multi-select-component';


@Component({
  selector: 'app-dvir-report-preview',
  templateUrl: './dvir-report-preview.component.html',
  styles: [
    '.iframe { border: 0; width: 1024px; height: 800px; }',
    '.frame { overflow: clip; }',
  ]
})
export class DvirReportPreviewDialogComponent {

  sanitizedUrl: SafeResourceUrl;
  private token: string;

  constructor(
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DvirReportPreviewDialogComponent>,
    private securityService: SecurityService,
    @Inject(MAT_DIALOG_DATA) public data: { vehicleId: number, from: Moment, to: Moment, vehicleGroupFilter: MultiSelectFilter<number> }
  ) {
    this.securityService.getToken().then(accessToken => {
      this.token = accessToken;

      let params = '?';
      if (!!data.vehicleId) {
        params = `${params}vehicleId=${data.vehicleId}`;
      }
      if (!!data.from ) {
        params = `${params}&dateFrom=${data.from.toISOString()}`;
      }
      if (!!data.to) {
        params = `${params}&dateTo=${data.to.toISOString()}`;
      }
      if (!!data.vehicleGroupFilter && !!data.vehicleGroupFilter.operator && !!data.vehicleGroupFilter.elements) {
        params = `${params}&vehicleGroupIds=${data.vehicleGroupFilter.elements.join(':::')}`;
        params = `${params}&vehicleGroupOp=${data.vehicleGroupFilter.operator}`;
      }
      params = `${params}&access_token=${this.token}`;

      const url = `${environment.services.service}v1/report/inspection/html${params}`;
      this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

}
