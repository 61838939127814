import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MultiSelectComponent, Operator} from '../multi-select-component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {UserManagement} from '../../models/User';
import {UserRole} from '../../../pages/settings/components/manage-users/user-filter/user-filter-bar.component';

/**
 * This component allows to create a multi-select filter of user roles.
 * It either emits 'undefined' value if no filter is set or an array of entities.
 *
 * In addition, it saves the state of the filter to URL params and browser's storage.
 */

@Component({
  selector: 'app-user-role-filter',
  templateUrl: './user-role-filter.component.html',
  styleUrls: ['./user-role-filter.component.scss']
})
export class UserRoleFilterComponent extends MultiSelectComponent<UserRole> implements OnInit, AfterViewInit, OnDestroy {

  private ROLES = [
    {
      id: UserManagement.ROLE_DRIVER,
      label: UserManagement.ROLE_DRIVER_LABEL
    },
    {
      id: UserManagement.ROLE_PORTAL_USER,
      label: UserManagement.ROLE_PORTAL_USER_LABEL
    },
    {
      id: UserManagement.ROLE_PORTAL_ADMIN,
      label: UserManagement.ROLE_PORTAL_ADMIN_LABEL
    },
  ];

  SETTINGS_KEY = 'selectedUserRoleIds';
  OPERATOR_SETTINGS_KEY = 'selectedUserRoleOperator';
  URL_PARAM_NAME = 'userRoleIds';
  URL_OP_NAME = 'userRoleOp';
  USE_FULLY_CHECKED_AS_DEFAULT = false;

  @Input() origin;
  @Output() filterChanged;

  @ViewChild('menu') menu: MatMenu;
  @ViewChild('matTriggerIs') matTriggerIs: MatMenuTrigger;
  @ViewChild('matTriggerIsNot') matTriggerIsNot: MatMenuTrigger;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    super(router, route);
  }

  ngOnDestroy(): void {
    this.destroyFilter();
  }

  ngOnInit(): void {
    this.initComponent(this.ROLES);
  }

  ngAfterViewInit(): void {
  }

  public destroyFilter() {
    localStorage.removeItem(this.getSelectedItemKey());
  }


  getLabel(item: UserRole): string {
    return item.label;
  }

  onApply() {
  }

  onOperatorEnter(operator: Operator) {
    // hide other operator menu
    if (operator === Operator.IS) {
      if (this.matTriggerIsNot.menuOpen) {
        this.matTriggerIsNot.closeMenu();
      }
    }

    if (operator === Operator.IS_NOT) {
      if (this.matTriggerIs.menuOpen) {
        this.matTriggerIs.closeMenu();
      }
    }
  }

  openMenu() {
    if (this.operator === Operator.IS) {
      this.matTriggerIs.openMenu();
    } else {
      this.matTriggerIsNot.openMenu();
    }
  }

}
