import {Map} from 'maplibre-gl';
import {MapContent} from '../MapContent';
import {LocationInfo} from '../../../../models/AddressLookup';
import {BackendLocationModelWithTime} from '../../../../models/location.model';
import {PointFeature} from '../../../../models/GeoJson';
import {MapContentSource} from '../MapContentSource';
import {MapTools} from '../../../../tools/MapTools';
import {MarkerLayer} from './MarkerLayer';
import {BreadcrumbLayer} from './BreadcrumbLayer';
import {FeatureCollection} from 'geojson';
import {TrackStyles} from '../../../../../configuration/model/TrackStyles';
import {ShiftTrackLayer} from './ShiftTrackLayer';
import {ShiftTrackArrowsLayer} from './ShiftTrackArrowsLayer';

export class AddressLookupPassMapContent implements MapContent {

  private map: Map = null;
  private source: MapContentSource = new MapContentSource('only-source');
  private tracksSource: MapContentSource = new MapContentSource('tracks-source');
  private markerLayer: MarkerLayer = new MarkerLayer(this.source);
  private breadcrumbLayer: BreadcrumbLayer = new BreadcrumbLayer(this.source);
  private shiftTrackLayer: ShiftTrackLayer;
  private shiftTrackArrowsLayer: ShiftTrackArrowsLayer = new ShiftTrackArrowsLayer(this.tracksSource);

  private readonly breadcrumbClickListener: any;

  constructor(
    marker: LocationInfo,
    breadcrumbs: BackendLocationModelWithTime[],
    shiftTrackFeatureCollection: FeatureCollection,
    trackStyles: TrackStyles,
    private closestBreadcrumbId: number,
    private breadcrumbHighlighted: (breadcrumbId: number) => void,
  ) {

    this.markerLayer.addMarker(marker);
    breadcrumbs.forEach(breadcrumb =>
      this.breadcrumbLayer.addBreadcrumb(breadcrumb, closestBreadcrumbId === breadcrumb.id));

    this.breadcrumbClickListener = (e) => {
      if (MapTools.checkPropagation(e)){
        return;
      }
      const pointFeature = e.features[0] as PointFeature;
      this.breadcrumbClicked(pointFeature.properties.id);
    };

    this.tracksSource.loadFromFeatureCollection(shiftTrackFeatureCollection);
    this.shiftTrackLayer = new ShiftTrackLayer(this.tracksSource, trackStyles);
  }

  load(map: Map) {
    this.map = map;

    this.source.updateMap(this.map);
    this.tracksSource.updateMap(this.map);
    this.map.addLayer(this.shiftTrackLayer?.toLayerSpecification());
    this.map.addLayer(this.shiftTrackArrowsLayer.toLayerSpecification());
    this.map.addLayer(this.markerLayer.toLayerSpecification());
    this.map.addLayer(this.breadcrumbLayer.toLayerSpecification());

    MapTools.pointCursorForLayer(this.map, this.breadcrumbLayer, true);
    this.map.on('click', this.breadcrumbLayer.layerId, this.breadcrumbClickListener);

    this.zoom();
  }

  unload() {
    MapTools.pointCursorForLayer(this.map, this.breadcrumbLayer, false);
    this.map.off('click', this.breadcrumbLayer.layerId, this.breadcrumbClickListener);

    this.map.removeLayer(this.markerLayer.layerId);
    this.map.removeLayer(this.breadcrumbLayer.layerId);
    this.map.removeLayer(this.shiftTrackLayer.layerId);
    this.map.removeLayer(this.shiftTrackArrowsLayer.layerId);
    this.map.removeSource(this.source.sourceId);
    this.map.removeSource(this.tracksSource.sourceId);
    this.map = null;
  }

  zoom() {
    MapTools.onMap(this.map, () => {
      this.markerLayer.zoomToVisibleFeatures(this.map, 16);
    });
  }

  breadcrumbClicked(breadcrumbId: number) {
    this.highlightBreadcrumb(breadcrumbId);
  }

  private highlightBreadcrumb(breadcrumbId: number) {
    this.source.features.forEach(feature => {
      // skip found addresses and closest breadcrumb
      if (feature.properties.type === 'breadcrumb' && feature.properties.id !== this.closestBreadcrumbId) {
        feature.properties.status = (breadcrumbId === feature.properties.id) ? 'highlighted' : 'normal';
      }
    });

    this.source.updateMap(this.map);
    this.breadcrumbHighlighted(breadcrumbId);
  }
}
