<div class="routes-container">
    <div class="top-bar" >
        <button
                class="back__btn"
                (click)="showUpperHierarchyLevel()"
                mat-icon-button
                *ngIf="!!path && path.length > 0"
        >
            <mat-icon class="action-icon">arrow_back</mat-icon>
        </button>
    </div>

    <div class="label-bar" *ngIf="!!path && path.length > 0">
        <div class="path-label">
            {{ getPathLabel() }}
        </div>
        <div class="assign-button-wrapper" *ngIf="!!isAdmin && showingRouteLevel">
            <button
                    (click)="toggleAssigningMode()"
                    mat-button
            >
                <mat-icon *ngIf="!assigningMode">add</mat-icon>
                <mat-icon *ngIf="assigningMode">close</mat-icon>
                <span *ngIf="!assigningMode">Start Assigning</span>
                <span *ngIf="assigningMode">Stop Assigning</span>
            </button>
        </div>
    </div>

    <div *ngIf="!!path && path.length === 0 && !!currentLevelItems && currentLevelItems.length === 0">
        <div class="no-items">
            No Routes Configured
        </div>

        <button
                mat-button
                color="accent"
                [routerLink]="['/settings', 'manage-routes']"
        >
            Configure Routes Now
        </button>
    </div>

    <ng-container *ngFor="let levelItem of currentLevelItems">
        <app-action-menu-item-2line
                [input]="levelItem"
        ></app-action-menu-item-2line>
    </ng-container>

    <ng-container *ngIf="assigningMode">
        <div class="assigning-box">
            <span class="assign-text">Click to assign</span>
        </div>
        <div *ngIf="!!availableItemsForAssigning && availableItemsForAssigning.length === 0">
            No available asset
        </div>
        <ng-container *ngIf="!!availableItemsForAssigning && availableItemsForAssigning.length > 0">
            <ng-container *ngFor="let item of availableItemsForAssigning">
                <app-action-menu-item-2line
                        [input]="item"
                ></app-action-menu-item-2line>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<!-- right panel -->
<ng-template #rightPanelTemplate let-option>

    <app-completed-assignments-stats>
    </app-completed-assignments-stats>

</ng-template>
