<div class="live-map-filter">
    <button mat-button
            class="button-wrapper mat-elevation-z2"
            [matMenuTriggerFor]="menu"
            [disabled]="disabled"
            [ngClass]="{'active': !!selectedItem && !disabled}"
    >
        <div class="filter-button" *ngIf="!selectedItem">
            <mat-icon>
                directions_car
            </mat-icon>
            <span>Type</span>
            <mat-icon>
                arrow_drop_down
            </mat-icon>
        </div>

        <div class="filter-button" *ngIf="!!selectedItem">
            <mat-icon>
                check
            </mat-icon>
            <span>{{ selectedItem.name }}</span>
            <mat-icon>
                arrow_drop_down
            </mat-icon>
        </div>
    </button>
</div>

<mat-menu #menu="matMenu">
    <app-hardware-filter
            class="overlay-filter"
            [origin]="'liveMap'"
            (filterChanged)="onHardwareFilterChange($event)"
    >
    </app-hardware-filter>

    <button
            mat-button
            color="accent"
            class="ml-10"
            (click)="clear()"
    >
        Reset
    </button>
</mat-menu>
