import {TracksLayerType} from '../settings.service';
import {TrackStyles} from './TrackStyles';

export class LayerTypeFilter {
  filterName: string;
  measurement: string;
  measurementUnits?: FilterSetting[];

  static coverageLayerTypeFilter(trackStyles: TrackStyles): LayerTypeFilter {
    return {
      filterName: TracksLayerType.COVERAGE,
      measurement: 'Passes',
      measurementUnits: [
        {
          number: '1',
          color: trackStyles.roadStatusCoverageLevel1.color,
          dashArray: trackStyles.roadStatusCoverageLevel1.dasharray,
        },
        {
          number: '2-4',
          color: trackStyles.roadStatusCoverageLevel2.color,
          dashArray: trackStyles.roadStatusCoverageLevel2.dasharray,
        },
        {
          number: '5-7',
          color: trackStyles.roadStatusCoverageLevel3.color,
          dashArray: trackStyles.roadStatusCoverageLevel3.dasharray,
        },
        {
          number: '8-10',
          color: trackStyles.roadStatusCoverageLevel4.color,
          dashArray: trackStyles.roadStatusCoverageLevel4.dasharray,
        },
        {
          number: '10+',
          color: trackStyles.roadStatusCoverageLevel5.color,
          dashArray: trackStyles.roadStatusCoverageLevel5.dasharray,
        },
      ],
    };
  }

  static currencyLayerTypeFilter(trackStyles: TrackStyles, timeUnit: number): LayerTypeFilter {
    return {
      filterName: TracksLayerType.CURRENCY,
      measurement: 'Time',
      measurementUnits: [
        {
          number: `< ${timeUnit} hr`,
          color: trackStyles.roadStatusCurrencyLevel1.color,
          dashArray: trackStyles.roadStatusCurrencyLevel1.dasharray,
        },
        {
          number: `${timeUnit}-${timeUnit * 2} hr`,
          color: trackStyles.roadStatusCurrencyLevel2.color,
          dashArray: trackStyles.roadStatusCurrencyLevel2.dasharray,
        },
        {
          number: `${timeUnit * 2}-${timeUnit * 3} hr`,
          color: trackStyles.roadStatusCurrencyLevel3.color,
          dashArray: trackStyles.roadStatusCurrencyLevel3.dasharray,
        },
        {
          number: `${timeUnit * 3}-${timeUnit * 4} hr`,
          color: trackStyles.roadStatusCurrencyLevel4.color,
          dashArray: trackStyles.roadStatusCurrencyLevel4.dasharray,
        },
        {
          number: `${timeUnit * 4}-${timeUnit * 12} hr`,
          color: trackStyles.roadStatusCurrencyLevel5.color,
          dashArray: trackStyles.roadStatusCurrencyLevel5.dasharray,
        },
      ],
    };
  }
}

export interface FilterSetting {
  number: string;
  color: string;
  dashArray: number[];
}
