import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeleteMessageDialogData {
  id: number;
  name: string;
}

@Component({
  selector: 'app-confirm-delete-message-dialog',
  templateUrl: './confirm-delete-message-dialog.component.html',
  styleUrls: ['./confirm-delete-message-dialog.component.scss']
})
export class ConfirmDeleteMessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteMessageDialogData) {}
}
