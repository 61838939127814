<div class="routes-container">
    <div class="top-bar" *ngIf="!!path && path.length > 0">
        <button
                class="back__btn"
                (click)="showUpperHierarchyLevel()"
                mat-icon-button
        >
            <mat-icon class="action-icon">arrow_back</mat-icon>
        </button>
    </div>

    <div class="label-bar" *ngIf="!!path && path.length > 0">
        <div class="path-label">
            {{ getPathLabel() }}
        </div>
    </div>

    <ng-container *ngFor="let levelItem of currentLevelItems">
        <app-action-menu-item
                [input]="levelItem"
        ></app-action-menu-item>
    </ng-container>
</div>
