import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {RouteConfiguration, RouteHierarchyItem} from '../../../../../shared/models/route';
import {SelectionModel} from '@angular/cdk/collections';
import {NestedTreeControl} from '@angular/cdk/tree';

export interface RouteFilter {
  routeIds: number[];
  all: boolean;
}

@Component({
  selector: 'app-route-tree',
  templateUrl: './route-tree.component.html',
  styleUrls: ['./route-tree.component.scss']
})
export class RouteTreeComponent implements OnInit {

  @Input() routeConfiguration: RouteConfiguration;
  @Input() attributes: string[];
  @Input() editing = false;
  @Output() routeFiltered: EventEmitter<RouteFilter> = new EventEmitter<RouteFilter>();
  @Output() styleChanged: EventEmitter<RouteConfiguration> = new EventEmitter<RouteConfiguration>();
  @ViewChild('tree') tree;

  dataSource: MatTreeNestedDataSource<RouteHierarchyItem> = new MatTreeNestedDataSource<RouteHierarchyItem>();
  treeControl = new NestedTreeControl<RouteHierarchyItem>(node => node.children);
  checklistSelection: SelectionModel<RouteHierarchyItem>;

  allDashArrays: number[][] = [
    [10],
    [5, 4],
    [2, 4],
    [1, 4],
    [4, 5, 1, 5],
  ];

  readonly colorNoOverride = '#dbdbdd';

  constructor() { }

  ngOnInit(): void {
    this.dataSource.data = [{
      children: this.routeConfiguration.children,
      childrenAttribute: this.routeConfiguration.childrenAttribute,
      value: 'Show/Hide All',
      routeId: null,
      routeName: null,
    }];

    const allItems = RouteHierarchyItem.getFlat(this.attributes.length + 1, this.routeConfiguration.children);
    const visibleItems = allItems.filter(it => !it.hidden);
    if (allItems.length === visibleItems.length) {
      visibleItems.push(this.dataSource.data[0]);
    }

    // filter leave level items and select by default
    this.checklistSelection = new SelectionModel<RouteHierarchyItem>(
        true,
        visibleItems
    );
    this.nodeSelectionChanged();

    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }

  hasChild = (_: number, node: RouteHierarchyItem) => !!node.children && node.children.length > 0;

  getLevel(node: RouteHierarchyItem) {
    if (!node.childrenAttribute) {
      return this.attributes.length;
    }
    return this.attributes.indexOf(node.childrenAttribute);
  }
  /*selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.checklistSelection.select(...RouteHierarchyItem.getLeaves(this.attributes.length, this.routeConfiguration.children));
      this.changeMapLayerFilter([], true);
    } else {
      this.checklistSelection.clear();
      this.changeMapLayerFilter([], false);
    }
  }*/

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: RouteHierarchyItem): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 &&
        descendants.every(child => {
          return this.checklistSelection.isSelected(child);
        });
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: RouteHierarchyItem): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: RouteHierarchyItem): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    const isNodeSelected = this.checklistSelection.isSelected(node);
    node.hidden = !isNodeSelected;
    isNodeSelected
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => {
      this.checklistSelection.isSelected(child);
      child.hidden = !isNodeSelected;
    });
    this.checkAllParentsSelection(node);

    this.nodeSelectionChanged();
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: RouteHierarchyItem): void {
    this.checklistSelection.toggle(node);
    const isNodeSelected = this.checklistSelection.isSelected(node);
    node.hidden = !isNodeSelected;
    this.checkAllParentsSelection(node);

    this.nodeSelectionChanged();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: RouteHierarchyItem): void {
    let parent: RouteHierarchyItem | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: RouteHierarchyItem): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
        descendants.length > 0 &&
        descendants.every(child => {
          return this.checklistSelection.isSelected(child);
        });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: RouteHierarchyItem): RouteHierarchyItem | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  nodeSelectionChanged() {
    const routeIds = this.checklistSelection.selected.map(node => node.routeId).filter(routeId => !!routeId);
    this.changeMapLayerFilter(routeIds);
  }

  changeMapLayerFilter(routeIds: number[], all: boolean = false) {
    this.routeFiltered.emit({routeIds, all});
  }

  clearColor(item: RouteHierarchyItem) {
    item.color = undefined;
    this.styleChanged.emit(this.routeConfiguration);
  }

  colorChanged() {
    this.styleChanged.emit(this.routeConfiguration);
  }

  selectDashArray(item: RouteHierarchyItem, index: number) {
    item.lineType = Array.from(this.allDashArrays[index]);
    this.styleChanged.emit(this.routeConfiguration);
  }

  clearDashArray(item: RouteHierarchyItem) {
    item.lineType = undefined;
    this.styleChanged.emit(this.routeConfiguration);
  }
}
