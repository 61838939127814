export class ActionMenuItem {
    constructor(
        readonly id: number | string,
        readonly icon: string,
        readonly title: string,
        readonly subtitle: string,
        readonly text3: string,
        readonly actionIcon: string,
        readonly activePredicate: () => boolean,
        readonly inactivePredicate: () => boolean,
        readonly onClick: () => void,
        readonly onHoverOn: () => void,
        readonly onHoverOff: () => void,
        readonly subMenus: ActionMenuItemSubMenu[]
    ) {}
}

export class ActionMenuItemSubMenu {
    constructor(
        readonly icon: string,
        readonly label: string,
        readonly onClick: () => void,
    ) {}
}
