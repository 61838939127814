<div class="dialog-header">
    <h1 mat-dialog-title>{{ headline }}</h1>
    <div class="header-right">
        <mat-checkbox [(ngModel)]="!data.model.active" (change)="onOutOfServiceChange($event)">Out of Service
        </mat-checkbox>
    </div>
</div>

<mat-dialog-content>
    <div class="data-section">
        Vehicle Details
    </div>
    <div>
        <mat-form-field class="form-input" appearance="outline" color="accent">
            <mat-label>Name</mat-label>
            <input matInput
                   autocomplete="off"
                   [disabled]="data.isImported"
                   [appUniqueValue]="vehicleValues('name')"
                   [(ngModel)]="data.model.name"
                   #ngName="ngModel"
                   required
                   [attr.cdkFocusInitial]="data.isImported !== true ? 'true' : null"
            >
            <mat-error *ngIf="ngName.invalid && ngName.errors.required">
                Name is required.
            </mat-error>
            <mat-error *ngIf="ngName.errors?.['uniqueValue']">
                Name already exists.
            </mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="form-input" appearance="outline" color="accent">
            <mat-label>Map Label</mat-label>
            <input matInput
                   autocomplete="off"
                   [disabled]="data.isImported"
                   [(ngModel)]="data.model.label"
                   [appUniqueValue]="vehicleValues('label')"
                   #ngLabel="ngModel">
            <mat-error *ngIf="ngLabel.errors?.['uniqueValue']">
                Map Label already exists.
            </mat-error>
            <mat-hint>
                Optional. Use a map label if the vehicle name is more than 20 characters.
            </mat-hint>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="form-input" appearance="outline" color="accent">
            <mat-label>License Plate</mat-label>
            <input matInput
                   autocomplete="off"
                   [disabled]="data.isImported"
                   [(ngModel)]="data.model.licensePlate"
                   [appUniqueValue]="vehicleValues('licensePlate')"
                   #ngPlate="ngModel">
            <mat-error *ngIf="ngPlate.errors?.['uniqueValue']">
                License Plate already exists.
            </mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="form-input" appearance="outline" color="accent" floatLabel="always"
                        [(colorPicker)]="data.model.mapColor"
                        [cpPosition]="'auto'">
            <mat-label>GPS Track Color</mat-label>
            <input matInput
                   autocomplete="off"
                   [placeholder]="defaultMapColor"
                   [value]="data.model.mapColor"
            >
            <button *ngIf="data.model.mapColor" matSuffix mat-icon-button aria-label="Clear"
                    (click)="data.model.mapColor=null">
                <mat-icon>close</mat-icon>
            </button>
            <mat-hint>Leave blank to use default color (recommended)</mat-hint>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="form-input" appearance="outline" color="accent">
            <mat-label>Reporting Metric</mat-label>
            <mat-select [(ngModel)]="data.model.reportingType">
                <mat-option [value]="'DISTANCE'">Odometer</mat-option>
                <mat-option [value]="'TIME'">Hour Meter</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="data-section">
        Hardware Configuration
    </div>

    <div class="data-section-wrapper">
        <div class="checkbox-wrapper">
            <mat-checkbox [(ngModel)]="data.model.tablet">Tablet</mat-checkbox>
            <div *ngIf="!data.model.tablet && !data.model.hardwareConfiguration?.avlModel" class="checkbox-form">
                <span class="text-accent">Please configure integrated sensors to begin tracking this vehicle</span>
            </div>
        </div>

        <!-- dash camera settings -->
        <ng-container *ngTemplateOutlet="dashCamera;context:{data:data, options: dashCamOptions}"></ng-container>


        <div class="checkbox-wrapper">
            <mat-checkbox #avlCheck [(ngModel)]="avl" (change)="onAvlChange($event)">Additional Hardware Integration</mat-checkbox>
            <div *ngIf="data.model.hardwareConfiguration && avlCheck.checked" class="checkbox-form">
                <div class="avl-row">
                    <mat-form-field class="avl-select" appearance="outline" color="accent">
                        <mat-label>AVL Model</mat-label>
                        <mat-select [(ngModel)]="data.model.hardwareConfiguration.avlModel"
                                    (ngModelChange)="avlModelChanged()"
                                    #ngAvlModel="ngModel"
                                    required
                        >
                            <mat-option [value]="AvlModel.PLOWOPS_HUB">PlowOps Hub (Rev B)</mat-option>
                            <mat-option [value]="AvlModel.PLOWOPS_HUB_C">PlowOps Hub (Rev C)</mat-option>
                            <mat-option [value]="AvlModel.CALAMP_2630">CalAmp 2630</mat-option>
                            <mat-option [value]="AvlModel.CALAMP_3641">CalAmp 3641</mat-option>
                            <mat-option [value]="AvlModel.CALAMP_5530">CalAmp 5530</mat-option>
                            <mat-option [value]="AvlModel.NONE">None</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="avl-select" appearance="outline" color="accent" *ngIf="data.model.hardwareConfiguration?.avlModel">
                        <mat-label>Serial Number</mat-label>
                        <input matInput
                               autocomplete="off"
                               [(ngModel)]="data.model.lmuId"
                               [appUniqueValue]="vehicleValues('lmuId')"
                               [disabled]="!data.model.hardwareConfiguration.avlModel"
                               #ngLmuId="ngModel">
                        <mat-error *ngIf="ngLmuId.errors?.['uniqueValue']">
                            Serial Number is already registered.
                        </mat-error>
                    </mat-form-field>

                </div>

                <ng-container *ngIf="data.model.hardwareConfiguration.avlModel">
                    <div>
                        <ng-container *ngFor="let equipment of equipments; let i = index">
                            <ng-container *ngIf="equipment.type !== SensorType.DIGITAL_GRANULAR_SPREADER && equipment.type !== SensorType.DIGITAL_LIQUID_SPREADER">
                                <app-equipment [configuration]="data.configuration"
                                               [equipment]="equipment"
                                               [avlModel]="data.model.hardwareConfiguration.avlModel"
                                               [allInputs]="allInputs"
                                               [excludedInputs]="usedInputs"
                                               (equipmentDeleted)="onEquipmentDeleted(i)"
                                               (equipmentTypeChanged)="onEquipmentTypeChanged()"
                                               (inputsChanged)="onInputsChanged()"
                                ></app-equipment>
                            </ng-container>
                        </ng-container>

                        <button
                                *ngIf="canAddEquipment()"
                                (click)="addEquipment()"
                                [disabled]="!data.model.hardwareConfiguration.avlModel || !areEquipmentsValid()"
                                mat-button
                                class="small__btn"
                                color="accent"
                        >
                            Add Equipment
                        </button>
                    </div>

                    <div class="checkbox-wrapper my-15">
                        <mat-checkbox #digitalSpreader [(ngModel)]="digitalSpreaderCheckbox" (change)="onDigitalSpreaderCheckboxChange($event)">Digital Spreader Integration</mat-checkbox>
                        <div *ngIf="data.model.hardwareConfiguration && digitalSpreader.checked" class="checkbox-form">

                            <app-digital-spreader
                                    (digitalSpreaderChanged)="onDigitalSpreaderChange($event)"
                                    [configuration]="data.configuration"
                                    [hardwareConfiguration]="data.model.hardwareConfiguration"
                                    class="w-100"
                            ></app-digital-spreader>

                        </div> <!-- end of checkbox-form - digital spreader -->
                    </div> <!-- end of checkbox-wrapper - digital spreader -->
                </ng-container>

            </div><!-- end of checkbox-form - equipment -->
        </div><!-- end of checkbox-wrapper - equipment -->

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close [attr.cdkFocusInitial]="data.isImported === true ? 'true' : null">
        Cancel
    </button>
    <button mat-button
            (click)="onOkClick()"
            [disabled]="ngName.invalid ||
            ngLabel.invalid ||
            ngPlate.invalid ||
            (data.model.lmuId !== null && !isUniqueVehicleValue('lmuId', data.model.lmuId)) ||
            (!data.model.tablet && !data.model.hardwareConfiguration?.avlModel) ||
            cameraConfigurationInvalid() ||
            (avlCheck.checked && !data.model.hardwareConfiguration?.avlModel) ||
            !areEquipmentsValid() ||
            !isDigitalSpreaderValid()
          "
    >
        {{ actionButtonLabel}}
    </button>
</mat-dialog-actions>


<ng-template #dashCamera let-data="data" let-options="options">
    <div class="checkbox-wrapper" *ngIf="data.configuration && hasFeatureFlag(FeatureFlagEnum.DashCam)">
        <mat-checkbox [(ngModel)]="options.camera" (change)="onCameraChange($event)">Dash Camera</mat-checkbox>

        <div *ngIf="options.camera && !!data.model.cameraConfiguration" class="checkbox-form">
            <mat-checkbox [(ngModel)]="data.model.cameraConfiguration.rotation"
                          (change)="onCameraRotationChange($event)">
                Rotate Camera Image 180°
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="options.localRecording" (change)="onLocalRecordingChange($event)">
                Local Recording</mat-checkbox>
            <div *ngIf="options.localRecording" class="checkbox-form">
                <mat-checkbox [(ngModel)]="data.model.cameraConfiguration.localRecording.captureEnabled">
                    Capture video
                </mat-checkbox>
                <mat-checkbox [(ngModel)]="options.localImagesInterval" (change)="onLocalImageChange($event)">
                    Capture image on Time Interval
                </mat-checkbox>
                <div *ngIf="!!options.localImagesInterval" class="checkbox-form">
                    <mat-form-field appearance="outline" color="accent" class="no-hint form">
                        <mat-label>Interval (minutes)</mat-label>
                        <input matInput
                               type="number"
                               min="1"
                               max="30"
                               [required]="!!options.localImagesInterval"
                               [(ngModel)]="data.model.cameraConfiguration.localRecording.captureImageInterval">
                    </mat-form-field>
                </div>
            </div>


            <mat-checkbox [(ngModel)]="options.streaming"
                          (change)="onStreamingChange($event)">
                Streaming to Dashboard
            </mat-checkbox>
            <div *ngIf="options.streaming" class="checkbox-form">
                <mat-checkbox [(ngModel)]="options.streamingDuration"
                              (change)="onCamStreamingDurationChange($event)">
                    Live video streaming on demand
                </mat-checkbox>
                <div *ngIf="!!options.streamingDuration" class="checkbox-form">
                    <mat-form-field appearance="outline" color="accent" class="no-hint form">
                        <mat-label>Duration (seconds)</mat-label>
                        <input matInput
                               type="number"
                               min="15"
                               max="60"
                               [(ngModel)]="data.model.cameraConfiguration.streaming.captureStreamDuration">
                    </mat-form-field>
                </div>
                <mat-checkbox [(ngModel)]="options.staticImagesInterval" (change)="onCamStreamingImageIntervalChange($event)">
                    Capture image on Time Interval
                </mat-checkbox>
                <div *ngIf="!!options.staticImagesInterval" class="checkbox-form">
                    <mat-form-field appearance="outline" color="accent" class="no-hint form">
                        <mat-label>Interval (minutes)</mat-label>
                        <input matInput
                               type="number"
                               min="1"
                               max="30"
                               [(ngModel)]="data.model.cameraConfiguration.streaming.captureImageInterval">
                    </mat-form-field>
                </div>
                <mat-checkbox [(ngModel)]="data.model.cameraConfiguration.streaming.observationCapture">
                    Capture Image with Observations
                </mat-checkbox>

            </div>
        </div>
    </div>
</ng-template>
