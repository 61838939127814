import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../../../../shared/models/route';
import {RmNode} from './RmNode';
import {RmConfiguration} from './RmConfiguration';
import {McRouteConfiguration} from '../../../../../shared/components/map-preview/model/McRouteConfiguration';
import {NodesAndBreadcrumbs} from './NodesAndBreadcrumbs';
import {ErrorTools} from '../../../../../shared/tools/ErrorTools';

export class RmRoot implements RmNode {
  children: RmConfiguration[];

  level = 0;

  constructor(children: RmConfiguration[]) {
    this.children = children;
  }

  static fromRouteConfigurations(routeConfigurations: RouteConfigurationWithSchema[]): RmRoot {
    const unprocessedChildren = routeConfigurations;
    const children = (unprocessedChildren == null) ? [] : unprocessedChildren
      .sort(RouteConfigurationWithSchema.routeConfigurationCompareFn)
      .map(value => RmConfiguration.fromRouteConfiguration(value));
    const root = new RmRoot(children);
    root.setParentOnChildren();
    return root;
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  isSelected(): boolean {
    return false;
  }

  setParentOnMe(parent: RmNode) {
    throw ErrorTools.unsupported();
  }

  setParentOnChildren() {
    this.children.forEach(value => value.setParentOnMe(this));
  }

  isAnyConfigurationSelected(): boolean {
    return this.children.some(value => value.isSelected());
  }

  /**
   * For map content purposes.
   */
  getMapContentRouteConfigurations(): McRouteConfiguration[] {
    return this.children.map(value => {
      return value.toMapConfiguration();
    });
  }

  collectVisibleRouteIds(routeIds: number[]) {
    throw ErrorTools.unsupported();
  }

  collectRouteIds(routeIds: number[]) {
    this.children.forEach(value => value.collectRouteIds(routeIds));
  }

  delete() {
    throw ErrorTools.unsupported();
  }

  deleteChild(it: RmNode) {
    const foundIndex = this.children.findIndex(value => value.isSimilarTo(it));
    if (foundIndex >= 0) {
      this.children.splice(foundIndex, 1);
    }
  }

  isSimilarTo(it: RmNode) {
    return it.isRoot();
  }

  /**
   * Nodes shown in left column - configuration nodes.
   */
  leftColumnNodes(): RmConfiguration[] {
    return this.children.map(value => value as RmConfiguration);
  }

  /**
   * Nodes shown in right column - nodes of selected configuration or intermediary.
   */
  rightColumnNodes(): NodesAndBreadcrumbs {
    const selectedChild = this.children.find(value => value.isSelected());
    if (selectedChild == null) {
      return new NodesAndBreadcrumbs(null, [], []);
    } else {
      return selectedChild.rightColumnNodesR();
    }
  }

  breadcrumbsR(breadcrumbs: string[]): string[] {
    return breadcrumbs;
  }

  rightColumnNodesR(): NodesAndBreadcrumbs {
    throw ErrorTools.unsupported();
  }

  actionMenuItemId(): number {
    throw ErrorTools.unsupported();
  }

  actionMenuItemTitle(): string {
    throw ErrorTools.unsupported();
  }

  actionMenuItemSubTitle(): string {
    throw ErrorTools.unsupported();
  }

  select() {
    throw ErrorTools.unsupported();
  }

  unselectMyChildren() {
    this.children.forEach(value => value.unselectMeAndMyChildren());
  }

  unselectMeAndMyChildren() {
    this.children.forEach(value => value.unselectMeAndMyChildren());
  }

  isRoot(): boolean {
    return true;
  }

  isConfig(): boolean {
    return false;
  }

  isIntermediary(): boolean {
    return false;
  }

  isRoute(): boolean {
    return false;
  }

  asConfigBase(): RouteConfigurationWithSchema {
    throw ErrorTools.unsupported();
  }

  asRouteOrIntermediaryBase(): RouteHierarchyItem {
    throw ErrorTools.unsupported();
  }

  collectPathIdsForQueryParams(): string[] {
    const pathIds: string[] = [];
    this.children.forEach(value => value.collectPathIdsForQueryParamsR(pathIds));
    return pathIds;
  }

  collectPathIdsForQueryParamsR(pathIds: string[]) {
    throw ErrorTools.unsupported();
  }

  /**
   * Selects nodes according to IDs
   */
  applyPathIdsFromQueryParams(pathIds: string[]) {
    if (pathIds.length > 0) {
      this.children.forEach(value => value.applyPathIdsFromQueryParamsR(pathIds));
    } else {
      this.children.forEach(value => value.unselectMeAndMyChildren());
    }
  }

  applyPathIdsFromQueryParamsR(remainingIds: string[]) {
    throw ErrorTools.unsupported();
  }
}
