<div class="style-editor-wrapper">

    <mat-form-field appearance="outline" color="accent" class="no-hint form"
                    (colorPickerChange)="styleChanged()"
                    [(colorPicker)]="routeStyle.color"
                    [cpAlphaChannel]="'disabled'">
        <mat-label>Route Color</mat-label>
        <input matInput
               [value]="routeStyle.color"
               [style.color]="routeStyle.color"
        >
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="no-hint wide-90">
        <mat-label>Route Width</mat-label>
        <input matInput
               type="number"
               [(ngModel)]="routeStyle.width"
               (change)="styleChanged()">
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="no-hint wide-90">
        <mat-label>Route Opacity</mat-label>
        <input matInput
               type="number"
               [matTooltip]="'Decimal value 0.0 - 1.0'"
               [(ngModel)]="routeStyle.opacity"
               (ngModelChange)="styleChanged()">
    </mat-form-field>

    <app-line-type-selector
            [(dashArray)]="routeStyle.lineType"
            (lineTypeChanged)="styleChanged()"
    >
    </app-line-type-selector>

    <!-- preview -->
    <!--<div
         [style.width]="'80px'"
         [style.opacity]="routeStyle?.opacity"
         [style.height]="routeStyle?.width + 'px'"
         [style.background-color]="routeStyle?.color"
    ></div>-->
</div>
