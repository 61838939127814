
/**
 * Input for Route configuration map preview content
 */
export class McRoute {

  constructor(
    public id: number,
    public colorOverride: string,
    public lineTypeOverride: number[],
    public visible: boolean,
  ) {}
}
