<mat-card appearance="outlined" class="dialog-card">
    <mat-card-title>Dropdown provider setting</mat-card-title>
    <mat-card-content>
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Dropdown provider</mat-label>
            <mat-select
                    [disabled]="!data.editing"
                    [(ngModel)]="question.dropdownProvider"
                    (ngModelChange)="updateLabels()">
                <mat-option (click)="question.dropdownProvider = null"></mat-option>
                <mat-option
                        *ngFor="let provider of data.dropdownProviders"
                        [value]="provider.id">
                    {{provider.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Dropdown</mat-label>
            <mat-select
                    [disabled]="!data.editing || !question.dropdownProvider"
                    [(ngModel)]="question.dropdownData"
                    (ngModelChange)="updateLabels()">
                <mat-option
                        *ngFor="let dropdown of data.dropdowns[question.dropdownProvider]"
                        [value]="dropdown.id">
                    {{dropdown.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>
        <mat-form-field appearance="outline" color="accent">
            <mat-label>Default value</mat-label>
            <mat-select
                    [disabled]="!data.editing || !question.dropdownProvider || !question.dropdownData"
                    [(ngModel)]="question.defaultValue"
                    (ngModelChange)="updateLabels()">
                <mat-option
                        *ngFor="let dropdown of dropdownOptions(question.dropdownProvider, question.dropdownData)"
                        [value]="dropdown.id">
                    {{dropdown.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
    <mat-card-footer align="right">
        <button mat-button type="button" mat-dialog-close>
            Cancel
        </button>
        <button mat-button type="button" color="accent" cdkFocusInitial [mat-dialog-close]="question" [disabled]="!data.editing">
            Update
        </button>
    </mat-card-footer>
</mat-card>
