import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { InsightsComponent } from './components/insights.component';
import { ShiftReportComponent } from './components/shift-report/shift-report.component';
import {ShiftReportTableComponent} from './components/shift-report-table/shift-report-table.component';
import {RouterModule} from '@angular/router';
import {
  DvirReportComponent
} from './components/dvir-report/dvir-report.component';
import {DvirReportTableComponent} from './components/dvir-report-table/dvir-report-table.component';
import {DvirInspectionComponent} from './components/dvir-inspection/dvir-inspection.component';
import { ShiftReportTableSettingsDialogComponent } from './components/shift-report-table/settings/shift-report-table-settings-dialog/shift-report-table-settings-dialog.component';
import {InsightsRoutingModule} from './insights-routing.module';
import { ShiftLookupComponent } from './components/shift-lookup/shift-lookup.component';
import {DvirReportPreviewDialogComponent} from './components/dvir-report/dialogs/dvir-report-dialogs.component';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { ImageRetrievalComponent } from './components/image-retrieval/image-retrieval.component';
import { ImageReportTableComponent } from './components/image-retrieval/image-report-table/image-report-table.component';
import {DeviceReportComponent} from './components/device-report/device-report/device-report.component';
import {DeviceReportTableComponent} from './components/device-report/device-report-table/device-report-table.component';

@NgModule({
  declarations: [
    InsightsComponent,
    ShiftReportComponent,
    ShiftReportTableComponent,
    DvirReportComponent,
    DvirReportTableComponent,
    DvirReportPreviewDialogComponent,
    DvirInspectionComponent,
    ShiftReportTableSettingsDialogComponent,
    ShiftLookupComponent,
    AddressLookupComponent,
    ImageRetrievalComponent,
    ImageReportTableComponent,
    DeviceReportComponent,
    DeviceReportTableComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    InsightsRoutingModule,
  ],
  providers: [],
})
export class InsightsModule {}
