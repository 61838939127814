import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {LocationSearchParams} from '../../shared/models/breadcrumb.model';
import {FeatureCollection} from 'geojson';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  constructor(private http: HttpClient) {
  }

  search(searchParams: LocationSearchParams) {
    return this.http.post<FeatureCollection>(
        `${environment.services.location}v1/location/history/search`,
        searchParams,
        {
          headers: {Accept: 'application/json'}
        }
    )
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }
}
