import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {CgQuestion, CgQuestionSelectable, CgQuestionType} from '../../../../../shared/models/cartegraph.model';
import {MatSelectChange} from '@angular/material/select';
import {Subscription} from 'rxjs';
import {CartegraphMessagingService} from '../../../../../data/cartegraph/cartegraph-messaging';
import {BaseMapType} from '../../../../../configuration/settings.service';

@Component({
  selector: 'app-manage-cartegraph-questions-mapping',
  templateUrl: './cartegraph-questions-mapping.component.html',
  styleUrls: ['./cartegraph-questions-mapping.component.scss'],
})
export class CartegraphQuestionsMappingComponent implements OnInit, OnDestroy {

  isLoading = true;
  uiError: string;
  questionsList: CgQuestionSelectable[];
  selectableQuestions: CgQuestionSelectable[];
  subscription: Subscription;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar,
    private messagingService: CartegraphMessagingService
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  ngOnInit() {
    this.isLoading = true;

    this.subscription = this.messagingService.dataObservable.subscribe((msg: string) => {
      if (msg === 'cg_fetched') {
        this.loadQuestions();
      }
    });

    this.loadQuestions();
  }

  private loadQuestions() {
    this.isLoading = true;
    this.cartegraphManagementService.getQuestions(CgQuestionType.AUTO).toPromise().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.initQuestions(response.data);
      }
    }).catch((error) => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private initQuestions(questions: CgQuestion[]) {
    this.questionsList = ([...questions.filter(q => q.readOnly === false)] as CgQuestionSelectable[]).sort(this.sortFn);
    this.questionsList.forEach(q => q.selected = false);
    this.selectableQuestions = [...this.questionsList];
    const parentOids = this.questionsList.filter(q => q.parentQuestion !== null).map(m => m.parentQuestion);

    // remove used selections from main list
    this.questionsList = this.questionsList.filter(q => q.parentQuestion === null);

    // mark selected those assigned to parent or parents used in assignment
    this.selectableQuestions.forEach(q => {
      q.selected = q.parentQuestion !== null;
      if (parentOids.findIndex(p => p === q.oid) >= 0) {
        q.selected = true;
      }
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  select(main: CgQuestionSelectable, event: MatSelectChange) {

    const changed = event?.value as CgQuestionSelectable;

    const unselected = this.selectableQuestions.find(q => q.parentQuestion === main.oid);
    if (unselected) {
      unselected.selected = false;
      unselected.parentQuestion = null;
      this.questionsList.push(Object.assign({}, unselected));
      this.questionsList.sort(this.sortFn);
    }

    const selected = this.selectableQuestions.find(q => q.oid === changed.oid);
    if (selected) {
      selected.parentQuestion = main?.oid;
      selected.selected = true;

      const foundIndex = this.questionsList.findIndex(q => q.oid === changed.oid);
      if (foundIndex >= 0) {
        this.questionsList.splice(foundIndex, 1);
      }
    }

    main.selected = !main.selected;
    this.selectableQuestions.sort(this.sortFn);

    this.showSnackBar(`'${main?.displayText}' updated`);
  }

  selected(mainQuestion: CgQuestionSelectable) {
    const selected = this.selectableQuestions.find(q => q.parentQuestion === mainQuestion.oid);
    return selected ? selected : '-without-note-field-';
  }

  private sortFn(a: CgQuestionSelectable, b: CgQuestionSelectable): number {
    if (a.layoutFieldId < b.layoutFieldId) {
      return -1;
    }
    if (a.layoutFieldId > b.layoutFieldId) {
      return 1;
    }
    return 0;
  }

  getSelectableQuestions(main: CgQuestionSelectable) {
    return this.selectableQuestions.filter(q => (q.oid !== main.oid) && (!q.selected || q.parentQuestion === main.oid));
  }

  update() {
    this.isLoading = true;
    this.cartegraphManagementService.updateQuestions(this.selectableQuestions)
      .then(response => {
        this.initQuestions(response.data);
      })
      .catch(error => {
        console.log(error);
        this.uiError = error;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  reload() {
    this.loadQuestions();
  }

  protected readonly BaseMapType = BaseMapType;
}
