import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ObservationType} from '../../../../../shared/models/observation-type';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-observation-group',
  templateUrl: './observation-group.component.html',
  styleUrls: ['./observation-group.component.scss']
})
export class ObservationGroupComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  observationTypes: ObservationType[];

  displayedColumns = ['title', 'abbreviation', 'action'];
  dataSource = new MatTableDataSource<ObservationType>();

  @Output() editObservationType: EventEmitter<ObservationType> = new EventEmitter();
  @Output() deleteObservationType: EventEmitter<ObservationType> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit() {
    this.dataSource.data = this.observationTypes;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = this.observationTypes;
  }
}
