import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speed'
})
export class SpeedPipe implements PipeTransform {

  /**
   * Transforms a speed from m/s to km/h or mph based on first argument if metric system is required.
   *
   * @param value Must be a number
   * @param args First argument must be boolean if metric system is used for the tenant
   */
  transform(value: any, ...args: any[]): any {
    if (typeof(value) === 'number') {
      if (args[0]) { // use metric system
        return `${(value * 3.6).toFixed(0)} km/h`;
      } else {
        return `${(value * 2.23694).toFixed(0)} mph`;
      }
    }

    return value;
  }

}
