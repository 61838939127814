import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'rateUnit'
})
export class RateUnitPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof(value) === 'string') {
      switch (value) {
        case 'liquid':
          return args[0] ? 'l/km' : 'gal/mi';
        case 'granular':
          return args[0] ? 'kg/km' : 'lbs/mi';
      }
    } else {
      return 'N/A';
    }
  }
}
