import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'spreaderLiquid'
})
export class SpreaderLiquidPipe implements PipeTransform {

  transform(value: any, useMetricSystem?: boolean, showUnits: boolean = true): any {
    // liquid param is in kg/m, recalculate to gal/mi or l/km
    if (typeof(value) === 'number') {
      if (useMetricSystem) { // use metric system
        return `${formatNumber(value * 1000, 'en-US', '0.1-2')}${showUnits ? ' l/km' : ''}`;
      } else {
        return `${formatNumber(value * 425.1437, 'en-US', '0.1-2')}${showUnits ? ' gal/mi' : ''}`;
      }
    } else {
      return 'N/A';
    }
  }
}
