import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationModel, FeatureFlagEnum} from 'src/app/shared/models/configuration.model';
import {ShiftWithDriverAndVehicleModel} from '../../../../shared/models/shift.model';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Subscription} from 'rxjs';
import {ImageModel} from '../../../../shared/models/image';
import {ImagesManagerService} from '../../../../data/images/images-manager.service';

@Component({
  selector: 'app-shift-video',
  templateUrl: './shift-video.component.html',
  styleUrls: ['./shift-video.component.scss']
})
export class ShiftVideoComponent implements OnInit, OnDestroy {

  @Input() shift: ShiftWithDriverAndVehicleModel;
  configuration: ConfigurationModel;

  loadingImages = true;
  images: ImageModel[];

  FeatureFlagEnum = FeatureFlagEnum;
  configSubscription: Subscription;
  imageSubscription: Subscription;

  constructor(
      private configurationService: ConfigurationService,
      private imageManager: ImagesManagerService,
  ) {}

  ngOnInit() {
    this.loadConfiguration();
  }

  ngOnDestroy() {
    this.configSubscription?.unsubscribe();
    this.imageSubscription?.unsubscribe();
  }

  private loadConfiguration() {
    this.configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
        this.loadImages();
      }
    });
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  private loadImages() {
    if (this.hasFeatureFlag(FeatureFlagEnum.DashCam) && this.shift.vehicle.cameraConfiguration?.streaming?.captureImageInterval > 0) {
      this.loadingImages = true;
      if (!!this.imageSubscription) {
        this.imageSubscription.unsubscribe();
      }
      this.imageSubscription = this.imageManager.filteredImages$.subscribe(images => {
        this.images = images;
        this.loadingImages = false;
      });
    }
  }
}
