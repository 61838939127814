import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConfigurationService } from '../../configuration/configuration.service';
import { ConfigurationModel } from '../../shared/models/configuration.model';
import {SecurityService} from '../../security/security.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard-layout ',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  configuration: ConfigurationModel;
  username: string;
  configurationSubscription: Subscription;

  constructor(
    private configurationService: ConfigurationService,
    private securityService: SecurityService
  ) {}

  public isExpanded = true; // This is backwards

  ngOnInit(): void {
    this.securityService.getUserEmail().then(email => {
      window.document.title = `PlowOps - ${email}`;
    });

    this.loadGeneralConfiguration();
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  private loadGeneralConfiguration() {
    this.configurationService.refreshConfiguration();
    this.configurationService.refreshTrackStyles();
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });
  }
}
