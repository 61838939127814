import {Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ImageModel} from '../../models/image';
import {SecurityService} from '../../../security/security.service';
import {ImagesManagerService} from '../../../data/images/images-manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() images: ImageModel[];

  imageData: any;
  token: string;
  loadingImage = false;
  imageError = false;

  selectedItem: ImageModel = null;
  selectedItemIndex: number = null;
  positionString: string;

  highlightedImageSubscription: Subscription;

  constructor(
      private securityService: SecurityService,
      private imageManager: ImagesManagerService,
  ) {
  }

  ngOnInit() {
    if (this.selectedItem === null && !!this.images && this.images.length > 0) {
      this.selectedItemIndex = 0;
      this.updatePosition();
      this.selectedItem = this.images[this.selectedItemIndex];
    }
    this.securityService.getToken().then(accessToken => {
      this.token = accessToken;
      this.loadImage();
    });

    this.highlightedImageSubscription = this.imageManager.highlightedImage$.subscribe(imageUpdate => {
      if (!!imageUpdate.image && imageUpdate.source === ImagesManagerService.MAP_SOURCE) {
        this.selectedItemIndex = this.images.findIndex(image => image.id === imageUpdate.image.id);
        this.updatePosition();
        this.selectedItem = this.images[this.selectedItemIndex];
        this.loadImage();
      }
    });
  }

  ngOnDestroy() {
    this.highlightedImageSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if already initialized
    if (!!this.token) {
      this.updatePosition();
    }
  }

  private loadImage() {
    this.imageError = false;
    this.loadingImage = true;
    this.imageData = undefined;
    this.imageData = this.selectedItem.imageUrl + `?access_token=${this.token}`;
  }

  onImageError() {
    // console.log('on error');
    this.imageError = true;
    this.loadingImage = false;
  }

  onImageLoad() {
    // console.log('on load');
    this.imageError = false;
    this.loadingImage = false;
  }

  previousImage() {
    const newIndex = this.selectedItemIndex - 1;
    if (newIndex < 0) {
      this.selectedItemIndex = this.images.length - 1;
    } else {
      this.selectedItemIndex = newIndex;
    }
    this.updatePosition();
    this.selectedItem = this.images[this.selectedItemIndex];
    this.imageManager.highlightImage(this.selectedItem.id, ImagesManagerService.LIST_ACTION_SOURCE);
    this.loadImage();
  }

  nextImage() {
    const newIndex = this.selectedItemIndex + 1;
    if (newIndex > this.images.length - 1) {
      this.selectedItemIndex = 0;
    } else {
      this.selectedItemIndex = newIndex;
    }
    this.updatePosition();
    this.selectedItem = this.images[this.selectedItemIndex];
    this.imageManager.highlightImage(this.selectedItem.id, ImagesManagerService.LIST_ACTION_SOURCE);
    this.loadImage();
  }

  private updatePosition() {
    this.positionString = `${this.selectedItemIndex + 1} / ${this.images.length}`;
  }
}
