import { Injectable } from '@angular/core';
import {DriverListUpdate, DriverModel} from '../../shared/models/driver.model';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DriversManagerService {
  private isInitialized = false;

  private readonly driversMap = new Map<number, DriverModel>();

  private readonly driversSubscribers = new Array<Subscriber<DriverListUpdate>>();

  readonly driversObservable = new Observable<DriverListUpdate>((observer) => {
    this.driversMap.forEach((driver: DriverModel) => {
      const driversListUpdate = new DriverListUpdate(driver);
      observer.next(driversListUpdate);
    });

    this.driversSubscribers.push(observer);
  });

  constructor() { }

  public init(drivers: DriverModel[]) {
    if (this.isInitialized) {
      throw Error('The DriversManagerService has already been initialized.');
    }

    this.addDrivers(drivers);

    this.isInitialized = true;
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    this.driversMap.clear();

    const arrayOfSubscribers = [
      this.driversSubscribers
    ];
    for (const subscribers of arrayOfSubscribers) {
      for (const subscriber of subscribers) {
        subscriber.complete();
      }
      subscribers.length = 0;
    }

    this.isInitialized = false;
  }

  public getDriver(driverId: number): DriverModel|null {
    return this.driversMap.get(driverId);
  }

  private addDrivers(newDrivers: DriverModel[]) {
    for (const driver of newDrivers) {
      this.driversMap.set(driver.id, driver);
    }
  }
}
