import {McRouteConfiguration} from '../McRouteConfiguration';
import {Map} from 'maplibre-gl';
import {Feature, FeatureCollection} from 'geojson';
import {MapContent} from '../MapContent';
import {MapContentSource} from '../MapContentSource';
import {ExternalConfigRouteLayer} from './ExternalConfigRouteLayer';
import {MapTools} from '../../../../tools/MapTools';
import {RouteStyle} from '../../../../models/route';

export class RouteConfigDetailMapContent implements MapContent {

  private readonly fallbackRouteStyle =  new RouteStyle('#000000', 2, 1.0);

  private map: Map = null;
  private source: MapContentSource = new MapContentSource('only-source');
  private configRouteLayers: ExternalConfigRouteLayer[] = [];

  // stored for finding currently displayed features
  private visibleLayerIds = new Set<string>();

  constructor(
    configuration: McRouteConfiguration,
    configRouteFeatureCollection: FeatureCollection,
  ) {
    this.source.loadFromFeatureCollection(configRouteFeatureCollection);

    this.configRouteLayers = configuration.routes.map(route => {
      if (route.visible) {
        this.visibleLayerIds.add(route.id.toString());
      }
      return new ExternalConfigRouteLayer(
          route.id,
          route.visible,
          route.colorOverride,
          route.lineTypeOverride,
          configuration.style,
          this.fallbackRouteStyle,
          this.source,
      );
    });
  }

  load(map: Map) {
    console.log('Loading a map...');
    this.map = map;

    this.source.updateMap(this.map);
    console.log('Setting up route configuration layers...');
    this.configRouteLayers.forEach(layer => this.map.addLayer(layer.toLayerSpecification()));

    console.log('Filtering visible features...');
    const visibleFeatures = this.filterVisibleFeatures();

    console.log('Zooming to visible features...');
    MapTools.zoomToFeatures(this.map, visibleFeatures);
    console.log('Map loaded.');
  }

  unload() {
    this.configRouteLayers.forEach(layer => this.map.removeLayer(layer.layerId));
    this.map.removeSource(this.source.sourceId);
    this.map = null;
  }

  // TODO tk, get rid of visibleLayerIds, move it as property to each route layer
  //  update route layer when visibility / style / color overrides change
  //  in addition to direct map layer changes here;
  //  now changes would be lost on map content reload

  changeRouteVisibility(configuration: McRouteConfiguration) {
    if (this.map != null) {

      this.visibleLayerIds.clear();
      configuration.routes.forEach(route => {
        const layerId = route.id.toString();
        if (route.visible) {
          this.visibleLayerIds.add(layerId);
        }
        this.map.setLayoutProperty(
          layerId,
          'visibility',
          route.visible ? 'visible' : 'none'
        );
      });

      MapTools.zoomToFeatures(this.map, this.filterVisibleFeatures());
    }
  }

  changeConfigurationStyle(configuration: McRouteConfiguration) {
    this.updateLayerStyles(configuration);
  }

  changeRouteColorOverrides(configuration: McRouteConfiguration) {
    this.updateLayerStyles(configuration);
  }

  private updateLayerStyles(configuration: McRouteConfiguration) {
    if (this.map != null) {

      configuration.routes.forEach(route => {
        const layerId = route.id.toString();
        this.map.setPaintProperty(layerId, 'line-color', !!route.colorOverride ? route.colorOverride : configuration.style.color);
        this.map.setPaintProperty(layerId, 'line-width', [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          10, ['*', configuration.style.width, ['^', 2, -1]],
          24, ['*', configuration.style.width, ['^', 2, 8]]
        ]);
        this.map.setPaintProperty(layerId, 'line-opacity', configuration.style.opacity);
        this.map.setPaintProperty(
            layerId,
            'line-dasharray',
            !!route.lineTypeOverride
            ? ['literal', route.lineTypeOverride]
            : (!!configuration.style.lineType ? ['literal',  configuration.style.lineType] : undefined),
        );
      });
    }
  }

  private filterVisibleFeatures(): Feature[] {
    const allFeatures = this.source.features;
    if (allFeatures != null && allFeatures.length > 0) {
      return allFeatures.filter(feature => {
        const layerId = feature.properties['routeId']; // beware - returns number instead of string
        return this.visibleLayerIds.has(layerId.toString());
      });
    } else {
      return [];
    }
  }
}
