<h1 mat-dialog-title>
  Change Password
</h1>

<form [formGroup]="passwordUpdateForm">
<mat-dialog-content class="dialog-content">
  <p>
    Enter the new password for user <b>{{data.givenName}} {{data.familyName}} ({{data.email}})</b>:
  </p>
  <p>
    <mat-form-field class="password-form-input" appearance="outline" color="accent">
      <input type="password" matInput placeholder="Password" formControlName='newPassword' name="newPassword" autocomplete="new-password" cdkFocusInitial>
      <mat-error *ngIf="passwordUpdateForm.controls['newPassword'].hasError('minlength')">
        Password must contain at least 8 characters.
      </mat-error>
      <mat-error *ngIf="passwordUpdateForm.controls['newPassword'].hasError('required')">
        Password is required.
      </mat-error>
      <mat-error *ngIf="passwordUpdateForm.controls['newPassword'].hasError('pattern')">
        Password must contain a lower case letter, an upper case letter, a number and a special character.
      </mat-error>
    </mat-form-field>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    Cancel
  </button>
  <button mat-button
          [disabled]="passwordUpdateForm.controls['newPassword'].hasError('minlength') ||
            passwordUpdateForm.controls['newPassword'].hasError('required') ||
            passwordUpdateForm.controls['newPassword'].hasError('pattern')"
          [mat-dialog-close]="this.passwordUpdateForm.get('newPassword').value"
          color="warn"
  >
    Change
  </button>
</mat-dialog-actions>
</form>
