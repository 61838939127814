<div class="setup-page-container">

  <div class="header-container">
    <h2 class="header-container-filters">
      My vehicle groups
    </h2>
    <div class="header-container-buttons">
      <div *ngIf="!isLoading && configuration">
        <button
                (click)="addNewVehicleGroup()"
                [disabled]="isImported"
                mat-button
                color="accent"
        >
          <mat-icon>add</mat-icon>
          Add Group
        </button>
        <button
                (click)="addNewVehicle()"
                [disabled]="isAddVehicleDisabled()"
                mat-button
                color="accent"
        >
          <mat-icon>add</mat-icon>
          Add Vehicle
        </button>
        <button
                (click)="changePage('import')"
                [disabled]="isImported"
                mat-button
                color="accent"
        >
          <mat-icon>add</mat-icon>
          Import vehicles
        </button>
        <button
                (click)="changePage('import-cartegraph')"
                *ngIf="isImported"
                mat-button
                color="accent"
        >
          <mat-icon>add</mat-icon>
          Import from Cartegraph
        </button>
      </div>

    </div>
  </div>

  <div class="content-container">
    <div class="selector-container">
      <div class="selector-container-list">
          <ng-container *ngFor="let vehicleGroupMenuItem of vehicleGroupMenuItems">
            <app-action-menu-item style="width: 100%;" [input]="vehicleGroupMenuItem"></app-action-menu-item>
          </ng-container>
      </div>
    </div>
    <div class="divider"></div>
    <div class="selection-container">
      <mat-spinner *ngIf="isSpinnerShown()"></mat-spinner>
      <ng-container *ngIf="isDetailShown()">
          <app-vehicle-group
                  [configuration]="configuration"
                  [vehicleGroup]="selectedVehicleGroup"
                  [vehicles]="getVehicles()"
                  (editVehicleGroup)="updateVehicleGroup(selectedVehicleGroup.id)"
                  (deleteVehicleGroup)="deleteVehicleGroup(selectedVehicleGroup.id)"
                  (vehicleDeletedEvent)="updateVehicleGroupMenu()"
                  (vehicleMovedEvent)="onVehicleMoved($event)"
          ></app-vehicle-group>
      </ng-container>
      <ng-container *ngIf="isNoGroupMsgShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No vehicle group"
                text="You can add vehicle group by using 'Add Group' button."
        >
        </app-blank-page>
      </ng-container>
      <ng-container *ngIf="isSelectionPromptShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No vehicle group selected"
                text="Select a group from list on the left to see all associated vehicles."
        >
        </app-blank-page>
      </ng-container>
      <ng-container *ngIf="isNoVehicleMsgShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No vehicles in selected vehicle group"
                text="You can add vehicle by using 'Add Vehicle' button."
        >
        </app-blank-page>
      </ng-container>
      <ng-container *ngIf="isNoSuchIdErrorShown()">
        <app-blank-page
                class="centered__page__container"
                headline="No such vehicle group"
                text="No vehicle group could be found for ID provided in URL."
        >
        </app-blank-page>
      </ng-container>
    </div>
  </div>

</div>
