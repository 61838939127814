import {Component, Input, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-tenant-public-map',
  templateUrl: './tenant-public-map.component.html',
  styleUrls: ['./tenant-public-map.component.scss']
})
export class TenantPublicMapComponent implements OnChanges {

  @Input() customerId: string;
  @Input() previewTimestamp: string;
  publicMapUrl: string;
  sanitizedUrl: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges) {
    const id = changes['customerId'] as SimpleChange;
    const timestamp = changes['previewTimestamp'] as SimpleChange;
    if ((!!id && !!id.currentValue) || (!!timestamp && !!timestamp.currentValue)) {
      if (environment.name !== 'local') {
        this.publicMapUrl = `${window.location.origin}/public/?id=${this.customerId}&timestamp=${this.previewTimestamp}`;
      } else {
        // docker compose container
        this.publicMapUrl = `http://127.0.0.1:9001/?id=${this.customerId}&timestamp=${this.previewTimestamp}`;
      }
      this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.publicMapUrl);
    }
  }
}
