import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {inOutAnimation} from 'src/app/shared/animations/animations';
import {SecurityService} from '../../../security/security.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {FeatureFlagEnum} from '../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {ActionMenuItem} from '../../../shared/models/action-menu-item.class';

interface SettingSection {
  id: string;
  icon: string;
  name: string;
  description: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [inOutAnimation],
})
export class SettingsComponent implements OnInit, OnDestroy {
  isLoading = true;
  isAdmin = false;
  public collapseMenu = false;
  public currentRoute?: any;
  public settingsSections: ActionMenuItem[] = [];

  private cartegraphIntegrationEnabled = false;
  private cartegraphUseNewDvir = false;
  private readonly openSubscriptions = Array<Subscription>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private securityService: SecurityService,
              private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.currentRoute = this.route.firstChild?.routeConfig.path;

    const configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(cfg => {
      if (cfg) {
        if (cfg.featureFlags) {
          this.cartegraphIntegrationEnabled = this.configurationService
              .hasFeatureFlag(cfg.featureFlags, FeatureFlagEnum.CartegraphIntegration);
          this.cartegraphUseNewDvir = this.configurationService
              .hasFeatureFlag(cfg.featureFlags, FeatureFlagEnum.CartegraphIntegrationNewDvir);
        }
        this.securityService.isAdmin().then(isAdmin => {
          this.isAdmin = isAdmin;
          this.initMenu();
          this.isLoading = false;
        }).catch(error => console.error(error));
      }
    });
    this.openSubscriptions.push(configurationSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  toggleSideMenu() {
    this.collapseMenu = !this.collapseMenu;
  }

  selectSection(sectionId: string) {
    this.router.navigate(['/settings', sectionId]);
    this.currentRoute = sectionId;
  }

  private initMenu() {

    if (this.settingsSections.length !== 0) {
      return;
    }

    const adminSections: SettingSection[] = [
      {
        id: 'manage-users',
        icon: 'people',
        name: 'Users',
        description: 'Manage users and assign roles',
      },
      {
        id: 'manage-vehicles',
        icon: 'directions_car',
        name: 'Vehicles',
        description: 'Manage and configure your fleet',
      },
      {
        id: 'manage-routes',
        icon: 'route',
        name: 'Routes',
        description: 'Upload and manage your routes',
      },
      {
        id: 'manage-observations',
        icon: 'stars',
        name: 'Observations',
        description: 'Create and manage driver observations',
      },
      {
        id: 'manage-public-portal',
        icon: 'co_present',
        name: 'Public Portal',
        description: 'Share your progress with the public',
      }
    ];

    if (this.isAdmin) {
      this.settingsSections.push(
          ...adminSections.map(section => {
            return this.settingsSectionToActionMenuItem(section);
          })
      );
    }

    if (this.isAdmin && (!this.cartegraphIntegrationEnabled || this.cartegraphUseNewDvir)) {
      const inspectionFormsSection = {
        id: 'inspection-forms',
        icon: 'fact_check',
        name: 'Inspection Forms',
        description: 'Manage pre and post trip inspection forms',
      };
      this.settingsSections.push(
          this.settingsSectionToActionMenuItem(inspectionFormsSection)
      );
    }

    if (this.isAdmin && this.cartegraphIntegrationEnabled) {
      const cartegraphSection = {
        id: 'cartegraph-settings',
        icon: 'done',
        name: 'Cartegraph Settings',
        description: '',
      };
      this.settingsSections.push(
          this.settingsSectionToActionMenuItem(cartegraphSection),
      );

      if (!this.cartegraphUseNewDvir) {
        const cartegraphDVIR = {
          id: 'dvir-questions',
          icon: 'fact_check',
          name: 'Inspection Forms',
          description: 'Manage pre and post trip inspection forms',
        };
        this.settingsSections.push(
          this.settingsSectionToActionMenuItem(cartegraphDVIR)
        );
      }
    }

    const sections = [
      {
        id: 'my-account',
        icon: 'portrait',
        name: 'Account',
        description: 'Manage your account',
      },
    ];
    this.settingsSections.push(
        ...sections.map(section => this.settingsSectionToActionMenuItem(section))
    );
  }

  private settingsSectionToActionMenuItem(section: SettingSection): ActionMenuItem {
    return new ActionMenuItem(
        section.id,
        section.icon,
        section.name,
        section.description,
        '',
        null,
        () => this.currentRoute?.startsWith(section.id),
        null,
        () => { this.selectSection(section.id); },
        null,
        null,
        [],
    );
  }

}
