import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {
  MultiSelectComponent,
  MultiSelectFilter,
  Operator
} from '../../../../../shared/components/multi-select-component';
import {ObjectWithId} from '../../../../../shared/models/NamedId';
import {UserRoleFilterComponent} from '../../../../../shared/components/user-role-filter/user-role-filter.component';
import {ActivatedRoute, Router} from '@angular/router';

export class UserFilterEvent {
  rolesFilter?: MultiSelectFilter<string>;
  search?: string;
}

export class UserRole extends ObjectWithId {
  label: string;
}

interface Chip {
  type: ChipType;
  filterName: string;
  operator: string;
  items: string;
}

enum ChipType {
  USER_ROLE_FILTER,
}

@Component({
  selector: 'app-user-filter-bar',
  templateUrl: './user-filter-bar.component.html',
  styleUrls: ['./user-filter-bar.component.scss']
})
export class UserFilterBarComponent {

  @Input() origin: string;
  @Output() userFilterChanged = new EventEmitter<UserFilterEvent>();

  userRoleFilter: UserRole[];
  searchFilter: string;
  private SEARCH_FILTER_LOCAL_KEY = `userSearchFilter:${origin}`;
  private SEARCH_FILTER_URL_KEY = 'search';

  filterChips: Chip[] = [];
  showingFilterBar = true;

  private userFilterEvent = new UserFilterEvent();

  @ViewChild('matTriggerAdd', {static: true}) matTriggerAdd: MatMenuTrigger;
  @ViewChild('matTriggerUserRole', {static: true}) matTriggerUserRole: MatMenuTrigger;
  @ViewChild(UserRoleFilterComponent, {static: true}) urFilterComponent: UserRoleFilterComponent;

  constructor(protected router: Router,
              protected route: ActivatedRoute,
  ) {
    // const itemIdsString = this.route.snapshot.queryParams[this.URL_PARAM_NAME];
    this.route.paramMap.subscribe(params => {
      const urlSearch = params.get(this.SEARCH_FILTER_URL_KEY);
      if (!!urlSearch) {
        this.searchFilter = urlSearch;
      } else {
        this.searchFilter = localStorage.getItem(this.SEARCH_FILTER_LOCAL_KEY);
      }
      this.onSearchChanged(null);
    });
  }

  toggleFilterBar() {
    this.showingFilterBar = !this.showingFilterBar;
  }

  allFiltersSet(): boolean {
    return (!!this.userRoleFilter);
  }

  noFiltersSet(): boolean {
    return (!this.userRoleFilter || this.userRoleFilter.length === 0);
  }

  clearFilters() {
    this.filterChips.forEach(chip => {
      this.filterChipRemoved(null, chip);
    });
    this.filterChips = [];
    this.searchFilter = null;
  }

  onUserRoleFilterChanged(filter: MultiSelectFilter<UserRole>) {

    this.userRoleFilter = filter.elements;

    // update user role chip
    const chipIndex = this.filterChips.findIndex(chip => chip.type === ChipType.USER_ROLE_FILTER);
    if (!!filter.elements && filter.elements.length > 0) {
      const filterName = 'Role';
      const operatorLabel = this.operatorToLabel(filter.operator);
      let chip: Chip;
      if (filter.elements.length === 1) {
        const firstRole = filter.elements[0];
        // const itemLabel = UserRoleFilterComponent.ROLES.find(r => r.id === firstRole.id).label;
        const itemLabel = this.urFilterComponent.getLabel(firstRole);
        chip = {
          type: ChipType.USER_ROLE_FILTER,
          filterName,
          operator: operatorLabel,
          items: itemLabel,
        };
      } else {
        const itemList = filter.elements.map(role => this.urFilterComponent.getLabel(role)).join(', ');
        chip = {
          type: ChipType.USER_ROLE_FILTER,
          filterName,
          operator: `${operatorLabel} IN`,
          items: `(${itemList})`,
        };
      }
      if (chipIndex >= 0) {
        this.filterChips[chipIndex] = chip;
      } else {
        this.filterChips.push(chip);
      }
    } else {
      if (chipIndex >= 0) {
        this.filterChips.splice(chipIndex, 1);
      }
    }

    this.userFilterEvent.rolesFilter = new MultiSelectFilter<string>(
      filter.operator,
      (!!filter.elements ? filter.elements.map(role => role.id) : null)
    );

    // emit event to parent
    this.userFilterChanged.emit(this.userFilterEvent);
  }

  onSearchChanged($event: any) {
    if (!!this.searchFilter && this.searchFilter.length > 0) {
      localStorage.setItem(this.SEARCH_FILTER_LOCAL_KEY, this.searchFilter);
    } else {
      localStorage.removeItem(this.SEARCH_FILTER_LOCAL_KEY);
    }

    // change url
    this.router.navigate([], {
      queryParams: {
        [this.SEARCH_FILTER_URL_KEY]: this.searchFilter,
      },
      queryParamsHandling: 'merge',
    }).then(_ => {
      // emit event to parent
      this.userFilterEvent.search = this.searchFilter;
      this.userFilterChanged.emit(this.userFilterEvent);
    });
  }


  filterChipRemoved(e, chip: Chip) {
    switch (chip.type) {
      case ChipType.USER_ROLE_FILTER: {
        this.urFilterComponent.setFilter(null, null);
        break;
      }
      default: {
        console.warn('Unhandled switch case! ' + chip.type);
      }
    }
  }

  filterChipSelected(e, chip: Chip) {
    let trigger: MatMenuTrigger;
    let component: MultiSelectComponent<any> | any;
    switch (chip.type) {
      case ChipType.USER_ROLE_FILTER: {
        trigger = this.matTriggerUserRole;
        component = this.urFilterComponent;
        break;
      }
      default: {
        console.warn('Unhandled switch case! ' + chip.type);
      }
    }
    if (!trigger) {
      return;
    }
    // entered delay to wait for menu initialization
    setTimeout(
      () => {
        trigger.openMenu();
        const panelElement: HTMLElement = document.getElementById(trigger.menu.panelId);
        panelElement.style.position = `absolute`;
        panelElement.style.left = e.x + 'px';
        panelElement.style.top = e.y + 'px';
        if (component instanceof MultiSelectComponent) {
          setTimeout(() => {
            component.openMenu();
          }, 200);
        }
      }, 100
    );
  }

  private operatorToLabel(operator: Operator) {
    switch (operator) {
      case Operator.IS:
        return 'IS';
      case Operator.IS_NOT:
        return 'IS NOT';
    }
  }

}
