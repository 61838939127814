<div class="dvir-report-container">
  <div class="report-menu">

    <app-filter-bar
            class="report-menu__filters"
            [origin]="'dvirReport'"
            [useDateFilter]="true"
            [useVehicleGroupFilter]="true"
            (dateFilterChanged)="dateFilterChanged($event)"
            (vehicleGroupFilterChanged)="vehicleGroupFilterChanged($event)"
    >
    </app-filter-bar>

    <div class="report-menu__actions">
      <button
              mat-button
              color="accent"
              [matMenuTriggerFor]="downloadMenu"
              class="caption text-primary"
      >
        <div class="download-button">
          <span>Inspection Form Reports</span>
          <mat-icon>
            arrow_drop_down
          </mat-icon>
        </div>
      </button>
      <mat-menu #downloadMenu="matMenu">
        <button
                mat-menu-item
                (click)="previewPDFExportFile()"
        >
          Preview report
        </button>
        <button
                mat-menu-item
                (click)="loadPDFExportFile()"
        >
          Download report as PDF
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="report__table__wrapper">
    <app-dvir-report-table
      [dataSource]="dataSource"
      (sortChangedEvent)="loadReport()"
      (scrolledToBottomEvent)="extendReport()"
    >
    </app-dvir-report-table>
  </div>
</div>
