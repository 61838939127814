import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {CircleLayerSpecification, LayerSpecification} from 'maplibre-gl';
import {BackendLocationModelWithTime} from '../../../../models/location.model';
import {Feature} from 'geojson';
import {MapStyles} from '../../../../../configuration/map-styles';

export class BreadcrumbLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('breadcrumb-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'circle',
      source: this.sourceRef.sourceId,
      filter: ['in', 'type', 'breadcrumb'],
      paint: {
        'circle-color': [
          'match',
          ['get', 'status'],
          'closest', MapStyles.ADDRESS_PASS_BREADCRUMB_CLOSEST,
          'highlighted', MapStyles.ADDRESS_PASS_BREADCRUMB_HIGHLIGHTED,
          MapStyles.ADDRESS_PASS_BREADCRUMB_DEFAULT
        ],
        'circle-radius': 8,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
        'circle-opacity': 1.0
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

  addBreadcrumb(breadcrumb: BackendLocationModelWithTime, closest: boolean) {
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          breadcrumb.longitude,
          breadcrumb.latitude
        ]
      },
      properties: {
        id: breadcrumb.id,
        type: 'breadcrumb',
        status: closest ? 'closest' : 'normal' // 'normal' / 'closest' / 'highlighted'
      }
    } as Feature;
    this.sourceRef.addFeature(feature);
  }
}
