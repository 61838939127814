import {Vehicle} from './vehicle';
import {NamedItem} from './named-item';
import {LocationModel} from './location.model';
import {Driver, DriverModel} from './driver.model';
import {VehicleModel} from './vehicle.model';
import {ObservationTypeGroup} from './observation-group';

export class ObservationType implements NamedItem {

  group?: ObservationTypeGroup;

  constructor(public readonly id: number,
              public readonly title: string,
              public readonly mapLabel: string,
              public readonly observationTypeGroupId: number) {}

  static isStationaryAlert(type: ObservationType) {
    return type.title === 'Stationary Alert' || type.title === 'No Longer Stationary';
  }
}

export class Observation implements NamedItem {
  get title(): string {
    return this.vehicle.label;
  }

  constructor(public readonly id: number,
              public readonly shiftId: number,
              public readonly vehicle: Vehicle | VehicleModel,
              public readonly driver: Driver | DriverModel,
              public readonly observationType: ObservationType,
              public readonly location: LocationModel,
              public readonly expiration: Date = null) {}

  static observationDateCompare(o1: Observation, o2: Observation): number {
    if (o1.expiration === null) {
      return 1;
    } else if (o2.expiration === null) {
      return -1;
    } else {
      // positive = o1.expiration is after o2.expiration
      // negative = o1.expiration is before o2.expiration
      return o1.expiration.getTime() - o2.expiration.getTime();
    }
  }
}
