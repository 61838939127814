<tree-root #tree
    [nodes]="nodes"
    [options]="options"
    (toggleExpanded)="treeEvent_toggleExpanded($event)"
    (activate)="treeEvent_activate($event)"
    (deactivate)="treeEvent_deactivate($event)"
>

    <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
            <tree-node-expander [node]="node"></tree-node-expander>
            <div class="mif-tree-row">
                <div class="node-content-wrapper"
                     [class.node-content-wrapper-active]="node.isActive"
                     [class.node-content-wrapper-focused]="node.isFocused"
                     (click)="node.mouseAction('click', $event)"
                     (dblclick)="node.mouseAction('dblClick', $event)"
                     (contextmenu)="node.mouseAction('contextMenu', $event)"
                     (treeDrop)="node.onDrop($event)"
                     [treeAllowDrop]="node.allowDrop"
                     [treeDrag]="node"
                     [treeDragEnabled]="node.allowDrag()">

                    <div class="mif-tree-title" *ngIf="node.data.mifIsSection">
                        <span class="tree-section-title">{{ node.data.name }}</span>
                        <mat-icon *ngIf="node.data.mifHasRemarks" color="accent" class="mif-remark-icon">chat_bubble_outline</mat-icon>
                    </div>

                    <div class="mif-tree-title" *ngIf="node.data.mifIsGroup">
                        <span class="tree-group-title">{{ node.data.name }}</span>
                        <mat-icon *ngIf="node.data.mifHasRemarks" color="accent" class="mif-remark-icon">chat_bubble_outline</mat-icon>
                    </div>

                    <div class="mif-tree-title" *ngIf="node.data.mifIsQuestion">
                        <span class="tree-question-title">{{ node.data.name }}</span>
                    </div>

                </div>
                <div *ngIf="isEditable && node.isActive" class="mif-inline-actions">
                    <mat-icon mat-icon-button
                            color="accent"
                            class="mif-inline-action"
                            [matTooltip]="buttonTooltipDeselect(node)"
                            (click)="buttonClickedDeselect(node)"
                    >
                        clear
                    </mat-icon>
                    <mat-icon mat-icon-button
                            color="accent"
                            class="mif-inline-action"
                            [matTooltip]="buttonTooltipAddQuestion(node)"
                            *ngIf="buttonVisibleAddQuestion(node)"
                            (click)="buttonClickedAddQuestion(node)"
                    >
                        add_circle
                    </mat-icon>
                    <mat-icon mat-icon-button
                            aria-label="Add"
                            color="accent"
                            class="mif-inline-action"
                            [matMenuTriggerFor]="menu"
                            [matTooltip]="buttonTooltipAdd(node)"
                            *ngIf="buttonVisibleAdd(node)"
                    >
                        add_circle
                    </mat-icon>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                *ngIf="menuItemVisibleAddGroup(node)"
                                (click)="menuItemClickedAddGroup(node)"
                        >
                            <span>Group</span>
                        </button>
                        <button mat-menu-item
                                *ngIf="menuItemVisibleAddQuestion(node)"
                                (click)="menuItemClickedAddQuestion(node)"
                        >
                            <span>Question</span>
                        </button>
                    </mat-menu>
                    <mat-icon mat-icon-button
                            color="accent"
                            class="mif-inline-action"
                            [matTooltip]="buttonTooltipEdit(node)"
                            (click)="buttonClickedEdit(node)"
                    >
                        edit
                    </mat-icon>
                    <mat-icon mat-icon-button
                            color="accent"
                            class="mif-inline-action"
                            [matTooltip]="buttonTooltipRemove(node)"
                            (click)="buttonClickedRemove(node)"
                    >
                        delete
                    </mat-icon>
                </div>
            </div>
        </div>
    </ng-template>

</tree-root>
