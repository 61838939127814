<div class="filter-bar-container">
    <div class="filter-bar-start">

        <button mat-button
                color="accent"
                (click)="toggleFilterBar()">
            <div class="menu-button">
                <span>Filter</span>
                <mat-icon [ngClass]="{'open-filter': showBar}">arrow_drop_down</mat-icon>
            </div>
        </button>

        <ng-container *ngIf="showBar">
            <ng-content></ng-content>
        </ng-container>

        <div *ngIf="showBar">
            <button mat-button color="accent" (click)="doFilter()">
                Filter
            </button>
            <button mat-button color="accent" (click)="clearFilters()">
                Clear Filters
            </button>
        </div>

    </div>
