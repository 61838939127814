import {MifNodeType} from './MifNodeType';
import {MifNodeTypes} from './MifNodeTypes';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MifNodeDialogComponent} from '../../../form/mif-node-dialog/mif-node-dialog.component';
import {MifSectionDto} from '../../dto/MifSectionDto';
import {MifQuestionDto} from '../../dto/MifQuestionDto';
import {MifGroupDto} from '../../dto/MifGroupDto';
import {BaseNodeDialogData, NodeDialogData} from '../../../form/mif-node-dialog/NodeDialogData';

export class MifGroupType implements MifNodeType {

  constructor(title: string, hasRemarks: boolean) {
    this.title = title;
    this.hasRemarks = hasRemarks;
  }

  title: string;
  hasRemarks: boolean;

  static fromDto(dto: MifGroupDto): MifGroupType {
    return new MifGroupType(dto.title, dto.hasRemarks);
  }

  static showCreateDialog(dialogs: MatDialog): MatDialogRef<MifNodeDialogComponent> {
    return dialogs.open(MifNodeDialogComponent, {
      data: new BaseNodeDialogData(
        'Create group',
        'Group',
        'Title',
        '',
        false,
        'Create'
      ),
      width: '300px',
    });
  }

  nodeLabel(): string {
    return this.title;
  }

  nodeType(): MifNodeTypes {
    return MifNodeTypes.GROUP;
  }

  showEditDialog(dialogs: MatDialog, afterEdit: () => void) {
    const dialogRef = dialogs.open(MifNodeDialogComponent, {
      data: new BaseNodeDialogData(
        'Edit group',
        'Group',
        'Title',
        this.title,
        this.hasRemarks,
        'Edit'
      ),
      width: '300px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        const data = result as NodeDialogData;
        this.title = data.textField;
        this.hasRemarks = data.hasRemarks;
        afterEdit();
      }
    });
  }

  sectionDto(externalId: number, order: number): MifSectionDto {
    return null;
  }

  groupDto(externalId: number, order: number): MifGroupDto {
    return new MifGroupDto(
      externalId === 0 ? null : externalId,
      order,
      this.title,
      this.hasRemarks
    );
  }

  questionDto(externalId: number, order: number): MifQuestionDto {
    return null;
  }

  hasNodeTypeInstanceRemarks(): boolean {
    return this.hasRemarks;
  }
}
