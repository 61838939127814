<div *ngIf="!!lineStyle" class="editor-wrapper">

    <div class="dash-array-selector">
        <div *ngFor="let dashArray of allDashArrays; let dashArrayIndex = index"
             class="dash-array"
             [ngClass]="{ 'selected-dash-array': !!selectedDashArray && arraysEqual(selectedDashArray, dashArray)}"
             (click)="selectDashArray(dashArrayIndex)"
        >
            <app-line-dash-array-view [dashArray]="dashArray"></app-line-dash-array-view>
        </div>
    </div>

    <div class="color-selector-wrapper">
        <div class="color-selector"
             [ngStyle]="{background: lineStyle.color}"
             [(colorPicker)]="lineStyle.color"
             (colorPickerChange)="onColorChange()"
        >
            <span>{{ lineStyle.color}}</span>
        </div>
    </div>
</div>
