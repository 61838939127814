import {RmNode} from './RmNode';

/**
 * For right column use only.
 */
export class NodesAndBreadcrumbs {

  constructor(
    public backButtonTarget: RmNode,
    public breadcrumbs: string[],
    public nodes: RmNode[],
  ) {}

  shortenedBreadcrumbs(): string {
    if (this.breadcrumbs.length < 1) {
      return '';
    } else if (this.breadcrumbs.length === 1) {
      return this.breadcrumbs[0];
    } else {
      return `.. / ${this.breadcrumbs[this.breadcrumbs.length - 1]}`;
    }
  }
}
