<form [formGroup]="updateForm" *ngIf="!!filterSettings && !!updateForm">

    <mat-card appearance="outlined" class="mb-15">
        <mat-card-header>
            <mat-card-title>Import filters configuration</mat-card-title>
        </mat-card-header>


        <mat-card-content class="mt-25">

            <!-- driver filter-->
            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['driverFilter'].touched && updateForm.controls['driverFilter'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Drivers import filter</mat-label>
                    <input type="text" matInput
                           placeholder="Driver filter e.g. Department in ('Parks', 'Streets')"
                           formControlName='driverFilter'
                           name="driverFilter">
                    <mat-error *ngIf="updateForm.controls['driverFilter'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>

                <div class="mw-125">
                    <button
                            mat-button
                            color="accent"
                            (click)="testLaborFilter()"
                            [disabled]="!(updateForm.controls['driverFilter'].valid) || isTesting.driver || isLoading">
                        <mat-icon *ngIf="!isTesting.driver">published_with_changes</mat-icon>
                        <mat-spinner *ngIf="isTesting.driver" diameter="20" color="accent"
                                     class="spinner"></mat-spinner>
                        Test
                    </button>
                </div>
            </div>
            <div class="form-row mb-25" *ngIf="laborFilterResult?.length>0">
                <div class="form-test">
                    <ul class="scrollable">
                        <li *ngFor="let labor of laborFilterResult">{{labor.IDField}}, {{labor.FullNameField}}
                            , {{labor.EmailAddressField}}, {{labor.DepartmentField}}</li>
                    </ul>

                </div>
            </div>

            <!-- vehicle filter -->
            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['vehicleFilter'].touched && updateForm.controls['vehicleFilter'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Vehicle import filter</mat-label>
                    <input type="text" matInput
                           placeholder="Vehicle filter e.g. Description in ('SUV', 'Pickup')"
                           formControlName='vehicleFilter'
                           name="vehicleFilter">
                    <mat-error *ngIf="updateForm.controls['vehicleFilter'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>

                <div class="mw-125 bolder mat-caption">
                    <button
                            mat-button
                            color="accent"
                            (click)="testVehicleFilter()"
                            [disabled]="!(updateForm.controls['vehicleFilter'].valid) || isTesting.vehicle || isLoading">
                        <mat-icon *ngIf="!isTesting.vehicle">published_with_changes</mat-icon>
                        <mat-spinner *ngIf="isTesting.vehicle" diameter="20" color="accent"
                                     class="spinner"></mat-spinner>
                        Test
                    </button>
                </div>
            </div>
            <div class="form-row mb-25" *ngIf="vehicleFilterResult?.length>0">
                <div class="form-test">
                    <ul class="scrollable">
                        <li *ngFor="let vehicle of vehicleFilterResult">{{vehicle.IDField}}
                            , {{vehicle.ManufacturerField}}, {{vehicle.ModelField}}
                            , {{vehicle.SerialVINNumberField}}, {{vehicle.ClassificationField}}
                            , {{vehicle.DepartmentField}}</li>
                    </ul>

                </div>
            </div>

            <!--work orders filter-->
            <div class="form-row mb-15"
                 *ngIf="hasWorkOrdersFeature()"
                 [ngClass]="{'password-error-row': updateForm.controls['workOrderFilter'].touched && updateForm.controls['workOrderFilter'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Work Order import filter</mat-label>
                    <input type="text" matInput
                           placeholder="Work Order filter"
                           formControlName='workOrderFilter'
                           name="workOrderFilter">
                    <mat-error *ngIf="updateForm.controls['workOrderFilter'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>

                <div class="mw-125 bolder mat-caption">
                    <button
                            mat-button
                            color="accent"
                            (click)="testWorkOrderFilter()"
                            [disabled]="!(updateForm.controls['workOrderFilter'].valid) || isTesting.workorder || isLoading">
                        <mat-icon *ngIf="!isTesting.workorder">published_with_changes</mat-icon>
                        <mat-spinner *ngIf="isTesting.workorder" diameter="20" color="accent"
                                     class="spinner"></mat-spinner>
                        Test
                    </button>
                </div>
            </div>
            <div class="form-row mb-25" *ngIf="workOrderFilterResult?.length>0">
                <div class="form-test">
                    <ul class="scrollable">
                        <li *ngFor="let workOrder of workOrderFilterResult">{{workOrder['IDField']}}
                            ({{workOrder['DepartmentField']}}, {{workOrder['StatusField']}})
                        </li>
                    </ul>
                </div>
            </div>


            <!-- vehicle equipment filter -->
            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['vehicleEquipmentFilter'].touched && updateForm.controls['vehicleEquipmentFilter'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Vehicle equipment import filter</mat-label>
                    <input type="text" matInput
                           placeholder="Vehicle equipment filter"
                           formControlName='vehicleEquipmentFilter'
                           name="vehicleEquipmentFilter">
                    <mat-error *ngIf="updateForm.controls['vehicleEquipmentFilter'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>

                <div class="mw-125 bolder mat-caption">
                    <button
                            mat-button
                            color="accent"
                            (click)="testVehicleEquipmentFilter()"
                            [disabled]="!(updateForm.controls['vehicleEquipmentFilter'].valid) || isTesting.equipment || isLoading">
                        <mat-icon *ngIf="!isTesting.equipment">published_with_changes</mat-icon>
                        <mat-spinner *ngIf="isTesting.equipment" diameter="20" color="accent"
                                     class="spinner"></mat-spinner>
                        Test
                    </button>
                </div>
            </div>
            <div class="form-row mb-25" *ngIf="vehicleEquipmentFilterResult?.length>0">
                <div class="form-test">
                    <ul class="scrollable">
                        <li *ngFor="let vehicle of vehicleEquipmentFilterResult">{{vehicle.IDField}}
                            , {{vehicle.ManufacturerField}}, {{vehicle.ModelField}}
                            , {{vehicle.SerialVINNumberField}}, {{vehicle.ClassificationField}}
                            , {{vehicle.DepartmentField}}</li>
                    </ul>

                </div>
            </div>
            <!-- vehicle groups filter -->
            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['vehicleGroupField'].touched && updateForm.controls['vehicleGroupField'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Vehicle group field</mat-label>
                    <input *ngIf="!equipmentClassFields || equipmentClassFields.length==0"
                           type="text" matInput
                           placeholder="Vehicle's group field e.g. 'DepartmentField'"
                           formControlName='vehicleGroupField'
                           name="vehicleGroupField">
                    <mat-select *ngIf="equipmentClassFields && equipmentClassFields.length>0"
                                placeholder="Vehicle's group field e.g. 'DepartmentField'"
                                formControlName="vehicleGroupField"
                    >
                        <mat-option
                                *ngFor="let field of equipmentClassFields"
                                [value]="field.name">{{field.displayName}} ({{field.name}})
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="updateForm.controls['vehicleGroupField'].hasError('required')">
                        Field is required
                    </mat-error>
                </mat-form-field>
            </div>


            <!--activity filter-->
            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['activityFilter'].touched && updateForm.controls['activityFilter'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Activity filter</mat-label>
                    <input type="text" matInput
                           placeholder="Activity Filter"
                           formControlName='activityFilter'
                           name="activityFilter">
                </mat-form-field>

                <div class="mw-125 bolder mat-caption">
                    <button
                            mat-button
                            color="accent"
                            (click)="testActivityFilter()"
                            [disabled]="!(updateForm.controls['activityFilter'].valid) || isTesting.activityFilter || isLoading">
                        <mat-icon *ngIf="!isTesting.activityFilter">published_with_changes</mat-icon>
                        <mat-spinner *ngIf="isTesting.activityFilter" diameter="20" color="accent"
                                     class="spinner"></mat-spinner>
                        Test
                    </button>
                </div>
            </div>
            <div class="form-row mb-25" *ngIf="activityFilterResult?.length>0">
                <div class="form-test">
                    <ul class="scrollable">
                        <li *ngFor="let item of activityFilterResult">{{item['ActivityField']}}</li>
                    </ul>
                </div>
            </div>


            <!--material filter-->
            <div class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['materialFilter'].touched && updateForm.controls['materialFilter'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Material filter</mat-label>
                    <input type="text" matInput
                           placeholder="Material Filter"
                           formControlName='materialFilter'
                           name="materialFilter">
                </mat-form-field>

                <div class="mw-125 bolder mat-caption">
                    <button
                            mat-button
                            color="accent"
                            (click)="testMaterialFilter()"
                            [disabled]="!(updateForm.controls['materialFilter'].valid) || isTesting.materialFilter || isLoading">
                        <mat-icon *ngIf="!isTesting.materialFilter">published_with_changes</mat-icon>
                        <mat-spinner *ngIf="isTesting.materialFilter" diameter="20" color="accent"
                                     class="spinner"></mat-spinner>
                        Test
                    </button>
                </div>
            </div>
            <div class="form-row mb-25" *ngIf="materialFilterResult?.length>0">
                <div class="form-test">
                    <ul class="scrollable">
                        <li *ngFor="let item of materialFilterResult"><span class="w-100px">{{item['IDField']}}</span> - {{item['DescriptionField']}}</li>
                    </ul>
                </div>
            </div>

            <div *ngIf="hasMaterialMatchingFeature()"
                 class="form-row mb-15"
                 [ngClass]="{'password-error-row': updateForm.controls['materialMatchingFields'].touched && updateForm.controls['materialMatchingFields'].invalid}">
                <mat-form-field appearance="outline" color="accent" class="form-input">
                    <mat-label>Material matching fields</mat-label>
                    <input type="text" matInput
                           placeholder="SnowRemovalOpsField,SprayingOpsField,HaulingOpsField,SweepingOpsField"
                           formControlName='materialMatchingFields'
                           name="materialMatchingFields">
                </mat-form-field>
            </div>

        </mat-card-content>
    </mat-card>
</form>