import { Injectable } from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {GeoJSONSourceSpecification, LineLayerSpecification} from 'maplibre-gl';
import {SettingsService} from '../../../../configuration/settings.service';
import {SecurityService} from '../../../../security/security.service';
import {ShiftWithDriverAndVehicleModel} from '../../../models/shift.model';
import {Feature, Position} from 'geojson';
import {PointGeometry} from '../../../models/GeoJson';
import {Subject, Subscription} from 'rxjs';
import {ShiftsService} from '../../../../data/shifts/shifts.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftPlaybackService {

  static readonly SHIFT_PLAYBACK_SOURCE_ID = 'shift-playback-source';
  static readonly LAYER_ID_TRACK = 'shift-playback-track';
  static readonly LAYER_ID_MARKER = 'shift-playback-marker';

  private mapLayersManager: MapLayersManager;
  private lineLayer: LineLayerSpecification;

  shift: ShiftWithDriverAndVehicleModel = null;
  allShiftPoints: Feature[];
  coordinates: Position[] = [];
  shiftTimeOffset = 0;
  shiftTimeLength = 0;

  speedFactor = 10; // 1 = normal time, 10 = 10x faster
  animation; // to store and cancel the animation
  startTime = 0;
  progress = 0; // progress = timestamp - startTime
  resetTime = false; // indicator of whether time reset is needed for the animation
  latestIndex = 0;

  readonly progressObservable = new Subject<number>();
  private readonly openSubscriptions = Array<Subscription>();

  constructor(private settingsService: SettingsService,
              private securityService: SecurityService,
              private shiftsService: ShiftsService,
              private configurationService: ConfigurationService,
  ) { }

  init(shift: ShiftWithDriverAndVehicleModel, mapLayersManager: MapLayersManager) {
    this.shift = shift;

    if (!!this.mapLayersManager) {
      throw Error('The mapLayersManager has already been set yet.');
    }

    this.mapLayersManager = mapLayersManager;
    this.initializeSourceAndLayers();

    const that = this;
    const subscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.SHIFT_TRACK_ANIM_SPEED) {
          const valueFromSettings = newSettings.value;
          if (valueFromSettings) {
            that.speedFactor = +valueFromSettings;
          }
        }
      }
    });
    this.openSubscriptions.push(subscription);
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The mapLayersManager has not been set yet.');
    }
    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;

    this.mapLayersManager = null;
    this.shift = null;
    this.allShiftPoints = null;
    this.coordinates = [];
    this.shiftTimeOffset = 0;
    this.shiftTimeLength = 0;
  }

  initializeSourceAndLayers() {
    this.mapLayersManager.addSource(ShiftPlaybackService.SHIFT_PLAYBACK_SOURCE_ID, this.getSource());

    this.lineLayer = this.getLineLayer();
    this.mapLayersManager.addLayer(this.lineLayer);
  }

  private getSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.getSourceData()
    } as GeoJSONSourceSpecification;
  }

  private getSourceData() {
    return {
      type: 'FeatureCollection',
      features: [{type: 'Feature', geometry: {type: 'LineString', coordinates: this.coordinates}}]
    };
  }

  private updateSource() {
    this.mapLayersManager.getGeoJsonSource(ShiftPlaybackService.SHIFT_PLAYBACK_SOURCE_ID)?.setData(
        this.getSourceData() as any
    );
  }

  private getLineLayer(): LineLayerSpecification {
    return {
        id: ShiftPlaybackService.LAYER_ID_TRACK,
        type: 'line',
        source: ShiftPlaybackService.SHIFT_PLAYBACK_SOURCE_ID,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'red',
          'line-opacity': this.configurationService.trackStyles.shiftPlowNormal.opacity,
          'line-width': 10,
        }
      } as LineLayerSpecification;
  }

  loadDataIfNeededAndPlay(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.shift) {
        if (!this.allShiftPoints) {
          this.shiftsService.getShiftPoints(this.shift.id, this.shift.vehicleId).then(featureCollection => {
            const points = featureCollection.features;
            this.allShiftPoints = points;
            if (points.length > 0) {
              this.shiftTimeOffset = points[0].properties['unixtime'];
              this.shiftTimeLength = points[points.length - 1].properties['unixtime'] - this.shiftTimeOffset;
            }
            this.playAnimation();
            resolve(true);
          }).catch(error => reject(error));
        } else {
          // using existing points
          this.playAnimation();
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  }

  getShiftTimeOffset(): number {
    if (!!this.shift) {
      return this.shiftTimeOffset;
    } else {
      console.warn('Incorrect usage! Shift Playback service not initialized!');
      return 0;
    }
  }

  playAnimation() {
    console.log('playing animation');
    this.startTime = performance.now();
    this.resetTime = true;
    this.animateLine(this.startTime);
  }

  // DOMHighResTimeStamp parameter
  private animateLine(timestamp) {
    if (this.resetTime) {
      // resume previous progress
      this.startTime = timestamp - this.progress;
      this.resetTime = false;
    } else {
      this.progress = timestamp - this.startTime;
    }

    if (this.progress / 1000 * this.speedFactor > this.shiftTimeLength) {
      console.log('Shift end has been reached.');
      return;
    }

    const calcNextPointTime = this.progress / 1000 * this.speedFactor;
    const nextPointTime = this.allShiftPoints[this.latestIndex].properties['unixtime'] - this.shiftTimeOffset;
    if (nextPointTime < calcNextPointTime) {
      // use the latest point and move index
      this.coordinates.push((this.allShiftPoints[this.latestIndex].geometry as PointGeometry).coordinates);
      this.updateSource();
      this.latestIndex = this.latestIndex + 1;
      this.progressObservable.next(nextPointTime);
    } else {
      // keeping index and interpolate
      if (this.latestIndex > 0) {
        const from = this.allShiftPoints[this.latestIndex - 1];
        const fromLng = (from.geometry as PointGeometry).coordinates[0];
        const fromLat = (from.geometry as PointGeometry).coordinates[1];
        const fromTime = from.properties['unixtime'] - this.shiftTimeOffset;
        const to = this.allShiftPoints[this.latestIndex];
        const toLng = (to.geometry as PointGeometry).coordinates[0];
        const toLat = (to.geometry as PointGeometry).coordinates[1];
        const toTime = to.properties['unixtime'] - this.shiftTimeOffset;
        const timeDiff = toTime - fromTime;
        const timeBetweenPoints = calcNextPointTime - fromTime;
        const pctBetweenPoints = timeBetweenPoints / timeDiff;
        const interpolatedLng = fromLng + (toLng - fromLng) * pctBetweenPoints;
        const interpolatedLat = fromLat + (toLat - fromLat) * pctBetweenPoints;
        const interpolated = [interpolatedLng, interpolatedLat];
        this.coordinates.push(interpolated);
        this.updateSource();
        this.progressObservable.next(fromTime + timeBetweenPoints);
      }
    }

    // Request the next frame of the animation.
    this.animation = requestAnimationFrame(time => this.animateLine(time));
  }

  pauseAnimation() {
    console.log('pausing animation...');
    cancelAnimationFrame(this.animation);
  }

  stopAnimation() {
    console.log('stopping animation...');
    this.latestIndex = 0;
    this.progress = 0;
    cancelAnimationFrame(this.animation);
    this.coordinates = [];
    this.updateSource();
  }

  jumpToPosition(newProgress: number) {
    console.log('jumping to position: ' + newProgress);
    this.progressObservable.next(newProgress);
    this.progress = newProgress / this.speedFactor * 1000;
    this.coordinates = [];
    let index = 0;
    for (const pointFeature of this.allShiftPoints) {
      const pointTime = pointFeature.properties['unixtime'] - this.shiftTimeOffset;
      if (pointTime > newProgress) {
        this.coordinates.push(...this.allShiftPoints.slice(0, index).map((feature) => {
          return [
              (feature.geometry as PointGeometry).coordinates[0],
              (feature.geometry as PointGeometry).coordinates[1]
          ];
        }));
        this.latestIndex = index;
        this.updateSource();
        break;
      }
      index += 1;
    }
  }
}
