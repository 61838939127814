import {AfterViewInit, Component, ElementRef, forwardRef, ViewChild} from '@angular/core';
import {FilterCompositeComponent} from './filter-composite.component';


@Component({
  selector: 'app-text-search-composite',
  templateUrl: './text-search-composite.html',
  providers: [
    {
      provide: FilterCompositeComponent,
      useExisting: forwardRef(() => TextSearchCompositeComponent)
    }
  ]
})
export class TextSearchCompositeComponent extends FilterCompositeComponent implements AfterViewInit {

  @ViewChild('searchInput')
  private input: ElementRef<HTMLInputElement>;

  constructor() {
    super();
  }


  override criteria(): string {
    if (!!this.input?.nativeElement?.value) {
      return this.input?.nativeElement?.value;
    } else {
      return null;
    }
  }

  override clear() {
    if (!!this.input?.nativeElement?.value) {
      this.input.nativeElement.value = null;
    }
  }

  ngAfterViewInit(): void {
  }

}
