<div class="ml-10">
    <mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
        <mat-label>{{ title }}</mat-label>
        <mat-select class="ml-10" multiple="{{multiple}}" (selectionChange)="onSelectionChange($event)"
                    [(ngModel)]="selectedValues">
            <mat-option *ngFor="let item of values"
                        [value]="item">{{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
