import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDeleteFormDialogData} from './ConfirmDeleteFormDialogData';

@Component({
  selector: 'app-mif-confirm-delete-form-dialog',
  templateUrl: './mif-confirm-delete-form-dialog.component.html',
  styleUrls: ['./mif-confirm-delete-form-dialog.component.scss']
})
export class MifConfirmDeleteFormDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<MifConfirmDeleteFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteFormDialogData) {}
}
