<div class="compose__btn__wrapper">
  <button
    mat-button
    color="accent"
    (click)="navigateToNewMessagePanel()"
  >
    <mat-icon>edit</mat-icon> New Message
  </button>
</div>

<div class="all-message-container">
  <div *ngFor="let message of recentItems">
    <app-action-menu-item-2line
            [input]="message"
    ></app-action-menu-item-2line>
  </div>
  <div *ngFor="let message of archivedItems">
    <app-action-menu-item-2line
            [input]="message"
    ></app-action-menu-item-2line>
  </div>
</div>
