import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {CartegraphConfiguration} from '../../../../../shared/models/cartegraph.model';

@Component({
  selector: 'app-manage-cartegraph-config',
  templateUrl: './cartegraph-config.component.html',
  styleUrls: ['./cartegraph-config.component.scss'],
})
export class CartegraphConfigComponent implements OnInit {

  isLoading = true;
  isTesting = false;
  uiError: string;

  @Input()
  configuration: CartegraphConfiguration;

  @Output()
  configurationChange = new EventEmitter<CartegraphConfiguration>();
  defaultsValid = false;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar
  ) {
  }


  ngOnInit() {
    this.uiError = null;
    this.isLoading = false;

  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }


  updateSettings() {
    this.uiError = null;
    this.isLoading = true;
    const config = new CartegraphConfiguration();
    config.settings = this.configuration.settings;
    this.cartegraphManagementService.updateSettings(config.settings).then(response => {
      if (!!response.error) {
        this.uiError = response.error;
        this.showSnackBar('Configuration update failed.');
      } else {
        this.configuration = response.data;
        this.configurationChange.emit(response.data);
        this.showSnackBar('Configuration filters updated.');
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  onDefaultsValidity(isValid: boolean) {
    this.defaultsValid = isValid;
  }
}
