import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Observation, ObservationType} from '../../../models/observation';
import {ObservationsManagerService} from '../../../../data/observations/observations-manager.service';
import {ObservationFilterUpdate} from '../../../models/observations-filter';
import {MapLayersManager} from '../map-layers-manager';
import {CircleLayerSpecification, GeoJSONSourceSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import {Subscription} from 'rxjs';
import {PointFeature} from '../../../models/GeoJson';
import {MapControlService} from './map-control.service';
import {environment} from '../../../../../environments/environment';
import {MapStyles} from '../../../../configuration/map-styles';

@Injectable({
  providedIn: 'root'
})
export class ObservationMapMarkerService {

  static readonly LAYER_ID_OBSERVATIONS_CIRCLE = 'observations_circle';
  static readonly LAYER_ID_OBSERVATIONS_SHADOW = 'observations_shadow';
  static readonly LAYER_ID_OBSERVATIONS_ICON = 'observations_icon';
  static readonly LAYER_ID_OBSERVATIONS_LABEL = 'observations_label';
  static readonly LAYER_ID_OBSERVATION_CLUSTERS = 'observations-clusters';
  static readonly OBSERVATION_SOURCE_ID = 'observations';
  static readonly STAR_ICON = 'star';

  private liveMap: boolean;
  private mapLayersManager: MapLayersManager;
  private readonly observationMap = new Map<number, Observation>();
  private highlightedObservationId: number = null;
  private observationSourceData: PointFeature[] = [];

  private readonly openSubscriptions = Array<Subscription>();

  constructor(@Inject(DOCUMENT) private document: Document,
              private observationsManagerService: ObservationsManagerService,
              private mapControlService: MapControlService,
  ) {}

  init(mapLayersManager: MapLayersManager, liveMap: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.observationSourceData = [];
    this.liveMap = liveMap;

    this.mapLayersManager.loadImage(
        ObservationMapMarkerService.STAR_ICON,
        `${environment.base_href}assets/star.png`,
        { sdf: true }
    );

    this.createSourceAndLayers();
    this.connectToManager();
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.observationMap.clear();
    this.observationSourceData = [];
    this.mapLayersManager = null;
    this.liveMap = null;
  }

  /**
   * This method is called within init or when the base map is changed
   */
  createSourceAndLayers() {
    // set geojson source
    this.mapLayersManager.addSource(ObservationMapMarkerService.OBSERVATION_SOURCE_ID, this.createObservationSource());

    // set observation layers
    this.mapLayersManager.addLayer(this.createShadowLayer());
    this.mapLayersManager.addLayer(this.createCircleLayer());
    this.mapLayersManager.addLayer(this.createObservationIconLayer());
    this.mapLayersManager.addLayer(this.createLabelLayer());

    // this.mapLayersManager.addLayer(this.createObservationClustersLayer());
    // this.mapLayersManager.addLayer(this.createObservationClusterCountLayer());
  }

  private getObservationSourceData() {
    return {
      type: 'FeatureCollection',
      features: this.observationSourceData
    };
  }

  private createObservationSource(): GeoJSONSourceSpecification {
    return {
      type: 'geojson',
      data: this.getObservationSourceData(), // 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'
      cluster: false,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    } as GeoJSONSourceSpecification;
  }

  private updateObservationSource() {
    if (!!this.mapLayersManager) {
      this.mapLayersManager.getGeoJsonSource(ObservationMapMarkerService.OBSERVATION_SOURCE_ID)
          ?.setData(this.getObservationSourceData() as any);
    }
  }

  private createLabelLayer(): SymbolLayerSpecification {
    return {
      id: ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_LABEL,
      type: 'symbol',
      source: ObservationMapMarkerService.OBSERVATION_SOURCE_ID,
      layout: {
        'text-field': ['get', 'title'],
        'text-font': ['Roboto'],
        'text-size': 12,
        'text-offset': [1.5, 0.0],
        'text-anchor': 'left',
      },
      paint: {
        'text-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          ['all', ['has', 'color'], ['!=', ['get', 'color'], null]],
          ['get', 'color'],
          MapStyles.LIVE_COLOR,
        ],
        'text-halo-color': '#ffffff',
        'text-halo-width': 2,
      },
    } as SymbolLayerSpecification;
  }

  private createCircleLayer(): CircleLayerSpecification {
    return {
      id: ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_CIRCLE,
      type: 'circle',
      source: ObservationMapMarkerService.OBSERVATION_SOURCE_ID,
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          ['all', ['has', 'color'], ['!=', ['get', 'color'], null]],
          ['get', 'color'],
          MapStyles.LIVE_COLOR,
        ],
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'white',
        'circle-opacity': 1.0
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

  private createShadowLayer(): CircleLayerSpecification {
    return {
      id: ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_SHADOW,
      type: 'circle',
      source: ObservationMapMarkerService.OBSERVATION_SOURCE_ID,
      paint: {
        'circle-radius': 15,
        'circle-color': '#000',
        'circle-blur': 0.75,
        'circle-translate': [2, 2],
      },
    } as CircleLayerSpecification;
  }

  private createObservationIconLayer(): SymbolLayerSpecification {
    return {
      id: ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_ICON,
      type: 'symbol',
      source: ObservationMapMarkerService.OBSERVATION_SOURCE_ID,
      layout: {
        'icon-image': ObservationMapMarkerService.STAR_ICON,
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': 'white',
      },
    } as SymbolLayerSpecification;
  }

  private createObservationClustersLayer(): CircleLayerSpecification {
    return {
      id: ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS,
      type: 'circle',
      source: ObservationMapMarkerService.OBSERVATION_SOURCE_ID,
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

  private createObservationClusterCountLayer(): SymbolLayerSpecification {
    return {
      id: 'observations-cluster-count',
      type: 'symbol',
      source: ObservationMapMarkerService.OBSERVATION_SOURCE_ID,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    } as SymbolLayerSpecification;
  }

  private observationToFeature(observation: Observation) {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          observation.location.coords.lng,
          observation.location.coords.lat
        ]
      },
      properties: {
        id: observation.id,
        title: observation.observationType.mapLabel,
        highlighted: this.highlightedObservationId === observation.id,
        color: !!observation.observationType.group?.mapColor ? observation.observationType.group.mapColor : undefined,
      }
    };
  }

  private handleObservationsAdded(observations: Observation[]) {
    this.observationMap.clear();
    this.observationSourceData = [];
    observations.forEach(observation => {
      // add observation marker only for non-stationary alerts OR on shift detail map
      if (!this.liveMap || !ObservationType.isStationaryAlert(observation.observationType)) {
        this.observationMap.set(observation.id, observation);
        this.observationSourceData.push(this.observationToFeature(observation));
      }
    });

    // update source
    this.updateObservationSource();
  }

  private handleHighlightedChange(filterUpdate: ObservationFilterUpdate) {
    this.highlightedObservationId = filterUpdate.observation?.id;
    this.observationSourceData.forEach(feature => {
      feature.properties.highlighted = (!!filterUpdate.observation && this.highlightedObservationId === feature.properties.id);
    });
    this.updateObservationSource();

    // if clicked from Live Map panel then zoom in
    if (filterUpdate.source === ObservationsManagerService.LIST_ACTION_SOURCE) {
      if (!!filterUpdate.observation) {
        this.mapControlService.zoomToCoordinates(filterUpdate.observation.location.coords, 15);
      }
    }
  }

  private connectToManager() {
    const filteredObservationsSubscription = this.observationsManagerService.filteredObservations$.subscribe(observations => {
        this.handleObservationsAdded(observations);
    });
    this.openSubscriptions.push(filteredObservationsSubscription);

    const filterSubscription = this.observationsManagerService.highlightedObservation$.subscribe(activeObservation => {
        this.handleHighlightedChange(activeObservation);
    });
    this.openSubscriptions.push(filterSubscription);
  }
}
