<h1 mat-dialog-title>
  Confirmation
</h1>

<mat-dialog-content class="dialog-content">
  <p>
    Are you sure you want to delete route configuration named <b>{{data.name}} ({{data.id}})</b>?
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="warn" [mat-dialog-close]="data.id" cdkFocusInitial>
    Yes
  </button>
</mat-dialog-actions>
