import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ObservationManagementService} from '../../../data/observations/observation-management.service';
import {ObservationTypeGroup} from '../../models/observation-group';
import {ToastService} from '../../services/toast.service';
import {MultiSelectFilter, MultiSelectComponent, Operator} from '../multi-select-component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';


/**
 * This component allows to create a multi-select filter of Observation Type Group entities.
 * It either emits 'undefined' value if no filter is set or an array of entities.
 *
 * In addition, it saves the state of the filter to URL params and browser's storage.
 */

@Component({
  selector: 'app-observation-group-filter',
  templateUrl: './observation-group-filter.component.html',
  styleUrls: ['./observation-group-filter.component.scss']
})
export class ObservationGroupFilterComponent extends MultiSelectComponent<ObservationTypeGroup> implements OnInit {

  SETTINGS_KEY = 'selectedObservationTypeGroupIds';
  OPERATOR_SETTINGS_KEY = 'selectedObservationTypeGroupOperator';
  URL_PARAM_NAME = 'observationTypeGroupIds';
  URL_OP_NAME = 'observationTypeGroupOp';
  USE_FULLY_CHECKED_AS_DEFAULT = false;


  @Input() origin;
  @Output() filterChanged = new EventEmitter<MultiSelectFilter<ObservationTypeGroup>>();

  @ViewChild('menu') menu: MatMenu;
  @ViewChild('matTriggerIs') matTriggerIs: MatMenuTrigger;
  @ViewChild('matTriggerIsNot') matTriggerIsNot: MatMenuTrigger;

  constructor(
      protected router: Router,
      protected route: ActivatedRoute,
      private observationService: ObservationManagementService,
      private toast: ToastService,
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.observationService.getObservationTypeGroups().toPromise().then(response => {
      this.initComponent(response.data);
    }).catch(error => {
      console.log(error);
      this.toast.longer('Error while loading observation type groups!');
    });
  }

  getLabel(item: ObservationTypeGroup): string {
    return item.name;
  }

  onApply() {}

  onOperatorEnter(operator: Operator) {
    // hide other operator menu
    if (operator === Operator.IS) {
      if (this.matTriggerIsNot.menuOpen) {
        this.matTriggerIsNot.closeMenu();
      }
    }

    if (operator === Operator.IS_NOT) {
      if (this.matTriggerIs.menuOpen) {
        this.matTriggerIs.closeMenu();
      }
    }
  }

  openMenu() {
    if (this.operator === Operator.IS) {
      this.matTriggerIs.openMenu();
    } else {
      this.matTriggerIsNot.openMenu();
    }
  }
}
