import { Component, Input } from '@angular/core';
import { SecurityService } from 'src/app/security/security.service';

@Component({
  selector: 'app-live-map-loader',
  templateUrl: './live-map-loader.component.html',
  styleUrls: ['./live-map-loader.component.scss'],
})
export class LiveMapLoaderComponent {
  @Input()
  message: string;
  @Input()
  loadErrorMessage: string;

  constructor(private securityService: SecurityService) {}

  onLogout() {
    this.securityService.logout();
  }
}
