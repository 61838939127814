import {Component, OnDestroy, OnInit} from '@angular/core';
import {ISettingKeyValuePair, SettingsService} from '../../../../configuration/settings.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-weather-legend',
  templateUrl: './weather-legend.component.html',
  styleUrls: ['./weather-legend.component.scss']
})
export class WeatherLegendComponent implements OnInit, OnDestroy {

  showRadar: boolean;
  showWarnings: boolean;

  settingsSubscription: Subscription;

  constructor(
      private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.showRadar = this.settingsService.getBooleanValue(SettingsService.WEATHER_RADAR_LAYER_KEY);
    this.showWarnings = this.settingsService.getBooleanValue(SettingsService.WEATHER_WARNINGS_LAYER_KEY);
    this.settingsSubscription = this.settingsService.settingsChangedObservable.subscribe(
        (keyValuePair: ISettingKeyValuePair) => {
          this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
        });
  }

  ngOnDestroy() {
    this.settingsSubscription?.unsubscribe();
  }

  onSettingsChanged(key: string, value: any) {
    if (key === SettingsService.WEATHER_RADAR_LAYER_KEY) {
      this.showRadar = value;
    }
    if (key === SettingsService.WEATHER_WARNINGS_LAYER_KEY) {
      this.showWarnings = value;
    }
  }

  toggleRadar() {
    this.settingsService.setBooleanValue(SettingsService.WEATHER_RADAR_LAYER_KEY, this.showRadar);
  }

  toggleWarnings() {
    this.settingsService.setBooleanValue(SettingsService.WEATHER_WARNINGS_LAYER_KEY, this.showWarnings);
  }
}
