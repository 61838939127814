import {Component, Input, OnInit} from '@angular/core';
import {ShiftWithDriverAndVehicleModel} from '../../../../shared/models/shift.model';
import {RouteAssignment} from '../../../../shared/models/route-assignment';
import {ActionMenuItem} from '../../../../shared/models/action-menu-item.class';
import {RouteAssignmentService} from '../../../../data/routes/route-assignment.service';
import {ToastService} from '../../../../shared/services/toast.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-shift-assignments',
  templateUrl: './shift-assignments.component.html',
  styleUrls: ['./shift-assignments.component.scss']
})
export class ShiftAssignmentsComponent implements OnInit {

  @Input() shift: ShiftWithDriverAndVehicleModel;
  routeAssignments: RouteAssignment[];
  items: ActionMenuItem[];

  constructor(
      private routeAssignmentService: RouteAssignmentService,
      private toast: ToastService,
      private datePipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.loadRouteAssignments();
  }

  loadRouteAssignments() {
    this.routeAssignmentService.getRouteAssignments(
        null,
        this.shift.vehicleId,
        this.shift.id,
        null,
        null,
        true,
        false,
    ).then(response => {
      this.routeAssignments = response.data;
      this.updateUiItems();
    }).catch(error => {
      this.toast.longer('Cannot read Route Assignments from server!');
    });
  }

  private updateUiItems() {
    this.items = this.routeAssignments.map(it => this.routeAssignmentToActionMenuItem(it));
  }

  private routeAssignmentToActionMenuItem(routeAssignment: RouteAssignment): ActionMenuItem {
    return new ActionMenuItem(
        `${routeAssignment.configId}_${routeAssignment.routeId}`,
        'route',
        routeAssignment.routeName,
        `Completed on ${this.datePipe.transform(routeAssignment.completed, 'short')}`,
        '',
        null,
        () => false,
        null,
        () => {},
        null,
        null,
        [],
    );
  }
}
