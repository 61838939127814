export abstract class NodeDialogData {
  dialogTitle: string;
  dialogSectionTitle: string;
  textFieldLabel: string;
  textField: string;
  hasRemarks: boolean;
  okButtonLabel: string;

  constructor(dialogTitle: string,
              dialogSectionTitle: string,
              textFieldLabel: string,
              textField: string,
              hasRemarks: boolean,
              okButtonLabel: string) {
    this.dialogTitle = dialogTitle;
    this.dialogSectionTitle = dialogSectionTitle;
    this.textFieldLabel = textFieldLabel;
    this.textField = textField;
    this.hasRemarks = hasRemarks;
    this.okButtonLabel = okButtonLabel;
  }
}

export class BaseNodeDialogData extends NodeDialogData {

  constructor(dialogTitle,
              dialogSectionTitle,
              textFieldLabel,
              textField,
              hasRemarks,
              okButtonLabel) {

    super(dialogTitle,
      dialogSectionTitle,
      textFieldLabel,
      textField,
      hasRemarks,
      okButtonLabel);
  }

}

export class TextNodeDialogData extends NodeDialogData {

  notApplicable?: boolean;

  constructor(dialogTitle,
              dialogSectionTitle,
              textFieldLabel,
              textField,
              hasRemarks,
              okButtonLabel,
              notApplicable?) {

    super(dialogTitle,
      dialogSectionTitle,
      textFieldLabel,
      textField,
      hasRemarks,
      okButtonLabel);
    this.notApplicable = notApplicable;
  }

}
