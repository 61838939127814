import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compass'
})
export class CompassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    const val = value as number;
    let dir = '';
    if ((val >= 0 && val < 22.5) || (val >= 337.5 && val <= 360)) {
      dir = 'N';
    } else if (val >= 22.5 && val < 67.5) {
      dir = 'NE';
    } else if (val >= 67.5 && val < 112.5) {
      dir = 'E';
    } else if (val >= 112.5 && val < 157.5) {
      dir = 'SE';
    } else if (val >= 157.5 && val < 202.5) {
      dir = 'S';
    } else if (val >= 202.5 && val < 247.5) {
      dir = 'SW';
    } else if (val >= 247.5 && val < 292.5) {
      dir = 'W';
    } else if (val >= 292.5 && val < 337.5) {
      dir = 'NW';
    } else {
      return 'N/A';
    }

    return dir + ' ' + Math.round(value) + '°';
  }

}
