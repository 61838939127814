<h1 mat-dialog-title>
  Move Vehicle to Another Group
</h1>

<mat-dialog-content class="dialog-content">
  <p>
    Select a new group for the vehicle <b>{{data.name}}</b>:
  </p>

  <mat-radio-group
          class="form-radio-group"
          [(ngModel)]="vehicleGroup">
    <ng-container *ngFor="let group of groups">
      <mat-radio-button class="form-radio-button"
                        [value]="group">
        {{ group.title }}
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="vehicleGroup?.id" [disabled]="!canMove()">
    Move
  </button>
</mat-dialog-actions>
