import { Pipe, PipeTransform } from '@angular/core';
import {MessageDeliveryDto} from '../models/message.model';

@Pipe({
  name: 'filterRecipients',
})
export class FilterRecipientsPipe implements PipeTransform {
  transform(items: MessageDeliveryDto[], query: string): MessageDeliveryDto[] {
    if (!items) {
      return items;
    }
    const filter = query.toLowerCase();
    if (filter === '' || filter === 'all') {
      return items;
    } else if (filter === 'read') {
      return items.filter(item => {
        return item.seenAt !== null;
      });
    } else {
      return items.filter(item => {
        return item.seenAt === null;
      });
    }
  }
}
