import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../shared/models/action-menu-item.class';
import {Address, ArcgisApiService} from '../../../../data/address-search/arcgis-api.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {AddressPass, AddressVisit, LocationInfo} from '../../../../shared/models/AddressLookup';
import {AddressLookupService} from '../../../../data/address-search/address-lookup.service';
import {
  AddressLookupVisitMapContent
} from '../../../../shared/components/map-preview/model/map-content/AddressLookupVisitMapContent';
import {ShiftsService} from '../../../../data/shifts/shifts.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {
  AddressLookupPassMapContent
} from '../../../../shared/components/map-preview/model/map-content/AddressLookupPassMapContent';
import {BackendLocationModelWithTime} from '../../../../shared/models/location.model';
import {MapContent} from '../../../../shared/components/map-preview/model/MapContent';
import {EmptyMapContent} from '../../../../shared/components/map-preview/model/EmptyMapContent';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {HardwareConfiguration, SensorInput, SensorType} from '../../../../shared/models/vehicle.model';
import {DatePipe, Location} from '@angular/common';
import {
  AddressLookupAddressMapContent
} from '../../../../shared/components/map-preview/model/map-content/AddressLookupAddressMapContent';
import {ToastService} from '../../../../shared/services/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DateFilter, TimeRangeFilter} from '../../../../shared/models/DateFilter';
import {Base64Tools} from '../../../../shared/tools/Base64Tools';
import { MapStyles } from 'src/app/configuration/map-styles';
import {InsightsRoute} from '../../insights-routing.module';
import {FeatureCollection} from 'geojson';


@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss']
})
export class AddressLookupComponent implements OnInit, OnDestroy {

  isLoadingFromQueryParams = false;
  isLoadingVisits = false;

  searchString = '';
  searchStringSubject: Subject<string> = new Subject<string>();
  dateFilter: DateFilter;

  addressItems: ActionMenuItem[] = [];
  visitItems: ActionMenuItem[] = [];
  mapContent: MapContent = EmptyMapContent.instance;

  addresses: LocationInfo[] = [];
  addressMapContent: AddressLookupAddressMapContent = null;
  selectedAddress: LocationInfo = null;

  visits: AddressVisit[] = [];
  hoveredOnVisit: AddressVisit = null;
  selectedVisit: AddressVisit = null;
  selectedVisitValidInputs: SensorInput[] = null;
  lastShiftTrack: FeatureCollection = null;

  passMapContent: AddressLookupPassMapContent = null;
  breadcrumbs: BackendLocationModelWithTime[] = [];
  closestBreadcrumb: BackendLocationModelWithTime = null;
  selectedBreadcrumb: BackendLocationModelWithTime = null;

  private tenantConfigurationSubscription: Subscription;
  private tenantConfiguration: ConfigurationModel;
  useMetricSystem = false;

  SensorType = SensorType;
  MapStyles = MapStyles;
  InsightsRoute = InsightsRoute;
  TimeRangeFilter = TimeRangeFilter;

  constructor(
    private arcGisApiService: ArcgisApiService,
    private addressLookupService: AddressLookupService,
    private shiftsService: ShiftsService,
    private configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private toastService: ToastService,
    private datePipe: DatePipe,
  ) {

    this.searchStringSubject
      .pipe(
        debounceTime(500), // wait 500ms before emitting
        distinctUntilChanged() // only if value is different
      )
      .subscribe(model => {
        this.reset();
        this.location.go(this.newPathWithQueryParams());

        this.suggestAddresses();
      });
  }

  ngOnInit(): void {
    this.tenantConfigurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(tenantConfiguration => {
      if (tenantConfiguration) {
        this.tenantConfiguration = tenantConfiguration;
        this.useMetricSystem = tenantConfiguration.useMetricSystem;
        this.subscribeToQueryParamChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.tenantConfigurationSubscription?.unsubscribe();
    this.lastShiftTrack = null;
  }

  showHeaderContent(): boolean {
    return this.showFormattedAddress() || this.showVisitDetails() || this.showPassDetails();
  }

  showFormattedAddress(): boolean {
    return (this.mapContent instanceof AddressLookupAddressMapContent) && this.selectedAddress?.formatted_address != null;
  }

  showVisitDetails(): boolean {
    return (this.mapContent instanceof AddressLookupVisitMapContent);
  }

  showPassDetails(): boolean {
    return (this.mapContent instanceof AddressLookupPassMapContent);
  }

  cancelSearch() {
    this.searchString = '';
    this.reset();
    this.location.go(this.newPathWithQueryParams());
  }

  backToAddressList() {
    this.selectedAddress = null;
    this.location.go(this.newPathWithQueryParams());
    this.visitItems = [];
    this.resetVisits();
    this.addressMapContent = new AddressLookupAddressMapContent(
        this.addresses, markerId => {});
    this.mapContent = this.addressMapContent;
  }

  backToVisitList() {
    this.selectedVisit = null;
    this.selectedBreadcrumb = null;
    this.location.go(this.newPathWithQueryParams());
    this.addressMapContent = new AddressLookupAddressMapContent(
        [this.selectedAddress], markerId => {});
    this.mapContent = this.addressMapContent;
  }

  searchStringChanged() {
    if (!this.isLoadingFromQueryParams) {
      this.searchStringSubject.next(this.searchString);
    }
  }

  markerHighlighted(markerId: number) {
    const found = this.addresses.find(location => location.id === markerId);
    if (found != null && this.selectedAddress !== found) {
      this.addressClicked(found);
    }
  }

  breadcrumbHighlighted(breadcrumbId: number) {
    this.selectedBreadcrumb = this.breadcrumbs == null ? null :
      this.breadcrumbs.find(breadcrumb => breadcrumb.id === breadcrumbId);
  }

  onDateFilterChanged(dateFilter: DateFilter) {
    this.dateFilter = dateFilter;
    if (this.selectedAddress != null) {
      this.loadVisits();
    }
  }

  private addressClicked(clicked: LocationInfo, onDone: (success: boolean) => void = success => {}) {
    this.selectedAddress = clicked;
    this.location.go(this.newPathWithQueryParams());

    this.addressMapContent = new AddressLookupAddressMapContent([this.selectedAddress], markerId => this.markerHighlighted(markerId));
    this.mapContent = this.addressMapContent;
    this.isLoadingVisits = true;
    this.resetVisits();
    this.loadVisits(onDone);
  }

  private loadVisits(onDone: (success: boolean) => void = success => {}) {
    if (!!this.dateFilter) {
      this.addressLookupService.findLocationHistory(
          LocationInfo.toLatLngModel(this.selectedAddress),
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
      ).toPromise().then(foundVisitsResponse => {
        this.visits = foundVisitsResponse.data;
        this.visitItems = this.visits.map(this.mapVisitsToMenuItems());
        this.isLoadingVisits = false;
        onDone(true);
      }).catch(error => {
        const msg = 'error while loading visits';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
        this.isLoadingVisits = false;
        onDone(false);
      });
    }
  }

  private visitClicked(visit: AddressVisit, onDone: (success: boolean) => void = success => {}) {

    // select address visit only if it is not selected yet
    if (this.selectedVisit !== visit) {
      this.selectedVisit = visit;
      this.location.go(this.newPathWithQueryParams());

      this.updateSelectedAddressVisitValidInputs();

      const pass: AddressPass = visit as AddressPass;
      this.addressLookupService.findPassPoints(
        pass.time,
        pass.shiftId
      ).toPromise().then(passPointsResponse => {
        this.breadcrumbs = passPointsResponse.data;

        this.closestBreadcrumb = this.breadcrumbs.find(breadcrumb => {
          return new Date(breadcrumb.time).getTime() === new Date(pass.time).getTime();
        });
        if (!!this.closestBreadcrumb?.id) {
          this.breadcrumbHighlighted(this.closestBreadcrumb.id);
        }

        this.passMapContent = new AddressLookupPassMapContent(
          this.selectedAddress,
            this.breadcrumbs,
            this.lastShiftTrack,
            this.configurationService.trackStyles,
            this.closestBreadcrumb?.id,
            breadcrumbId => {
            this.breadcrumbHighlighted(breadcrumbId);
            }
        );
        this.mapContent = this.passMapContent;
        onDone(true);

      }).catch(error => {
        const msg = 'error while loading pass points';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
        onDone(false);
      });

    } else { // only re-zoom
      this.passMapContent.zoom();
    }
  }

  private updateSelectedAddressVisitValidInputs() {
    if (this.selectedVisit == null || this.selectedVisit.vehicle?.hardwareConfiguration?.sensorInputs == null) {
      this.selectedVisitValidInputs = null;
    } else {
      this.selectedVisitValidInputs = HardwareConfiguration.getValidInputs(this.selectedVisit.vehicle.hardwareConfiguration.sensorInputs);
    }
  }

  private onHoverOnAddress(address: LocationInfo) {
    if (this.selectedAddress == null) { // nothing selected yet
      this.addressMapContent.highlightMarker(address.id);
    }
  }

  private onHoverOffAddress() {
    if (this.selectedAddress == null) { // nothing selected yet
      this.addressMapContent.cancelMarkerHighlights();
    }
  }

  private onHoverOnVisit(visit: AddressVisit) {
    if (this.selectedVisit == null) { // nothing selected yet
      this.hoveredOnVisit = visit;

      this.shiftsService.getShiftTrack(visit.shiftId, visit.vehicleId).then(shiftTrackResponse => {
        this.lastShiftTrack = shiftTrackResponse;
        this.mapContent = new AddressLookupVisitMapContent(
          this.selectedAddress, this.lastShiftTrack, this.configurationService.trackStyles
        );
      }).catch(error => {
        const msg = 'error while loading shift track';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
      });
    }
  }

  private onHoverOffVisit() {
    if (this.selectedVisit == null) { // nothing selected yet
      this.lastShiftTrack = null;
      this.hoveredOnVisit = null;

      if (this.passMapContent == null && this.addressMapContent == null) {
        this.mapContent = EmptyMapContent.instance;
      } else if (this.passMapContent != null) {
        // this.mapContent = this.passMapContent;
      } else {
        this.mapContent = this.addressMapContent;
      }
    }
  }

  private mapLocationsToLeftItems(): (LocationInfo) => ActionMenuItem {
    return (location: LocationInfo) => {
      return new ActionMenuItem(
        1,
        'home',
        location.formatted_address,
        '',
        '',
        null,
        () => this.selectedAddress === location,
        null,
        () => this.addressClicked(location),
        () => this.onHoverOnAddress(location),
        () => this.onHoverOffAddress(),
        []
      );
    };
  }

  private mapVisitsToMenuItems(): (AddressVisit) => ActionMenuItem {
    return visit => new ActionMenuItem(
      1,
      'route',
      `${this.datePipe.transform(visit.time, 'longDate')}`,
      visit.driver.name,
      `${this.datePipe.transform(visit.time, 'shortTime')}`,
      null,
      () => this.selectedVisit === visit,
      null,
      () => this.visitClicked(visit),
      () => this.onHoverOnVisit(visit),
      () => this.onHoverOffVisit(),
      [
          new ActionMenuItemSubMenu(
              'map',
              'View Shift',
              () => this.router.navigate(['/shift-detail', visit.shiftId], {
                queryParams: {
                  source: InsightsRoute.ADDRESS_LOOKUP,
                },
              })
          )
      ]
    );
  }

  private reset() {
    this.addressItems = [];
    this.visitItems = [];
    this.mapContent = EmptyMapContent.instance;

    this.addresses = [];
    this.addressMapContent = null;
    this.selectedAddress = null;
    this.isLoadingFromQueryParams = false;

    this.resetVisits();
  }

  private resetVisits() {
    this.visits = [];
    this.hoveredOnVisit = null;
    this.selectedVisit = null;
    this.selectedVisitValidInputs = null;

    this.passMapContent = null;
    this.breadcrumbs = [];
    this.closestBreadcrumb = null;
    this.selectedBreadcrumb = null;
  }

  private subscribeToQueryParamChanges() {
    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      const searchQueryParam = paramMap.get('search');
      const addressQueryParam = paramMap.get('address');
      const visitQueryParam = paramMap.get('visit');

      // at least search param is present
      if (!!searchQueryParam) {
        this.isLoadingFromQueryParams = true;
        const searchDecoded = Base64Tools.decodeText(searchQueryParam);

        this.searchString = searchDecoded;
        this.suggestAddresses(suggestionsDone => {
          if (!!addressQueryParam) {
            const addressDecoded = Base64Tools.decodeText(addressQueryParam);

            const foundAddress = this.addresses.find(value => value.id.toString() === addressDecoded);
            if (foundAddress != null) {
              this.addressClicked(foundAddress, addressDone => {

                if (!!visitQueryParam) {
                  const visitDecoded = Base64Tools.decodeText(visitQueryParam);

                  const foundVisit = this.visits.find(value => value.shiftId.toString() === visitDecoded);
                  if (foundVisit != null) {
                    this.visitClicked(foundVisit, visitDone => {
                      // address selected, visit selected
                      this.isLoadingFromQueryParams = false;
                    });
                  } else {
                    // address selected, but no such visit
                    this.isLoadingFromQueryParams = false;
                  }
                } else {
                  // address selected, but no visit param
                  this.isLoadingFromQueryParams = false;
                }
              });
            } else {
              // no such address
              this.isLoadingFromQueryParams = false;
            }
          } else {
            // no address param
            this.isLoadingFromQueryParams = false;
          }
        });
      }
    });
  }

  private newPathWithQueryParams(): string {
    const basePath = this.location.path().split('?')[0];
    let newPath: string = basePath;
    if (this.searchString != null && this.searchString.length > 0) {
      newPath = newPath.concat(`?search=${Base64Tools.encodeText(this.searchString)}`);
      if (this.selectedAddress != null) {
        newPath = newPath.concat(`&address=${Base64Tools.encodeText(this.selectedAddress.id.toString())}`);
        if (this.selectedVisit != null) {
          newPath = newPath.concat(`&visit=${Base64Tools.encodeText(this.selectedVisit.shiftId.toString())}`);
        }
      }
    }
    return newPath;
  }

  private suggestAddresses(onDone: (success: boolean) => void = success => {}) {
    if (this.searchString.length > 2) {
      this.arcGisApiService.suggestAddresses(this.searchString, true).then(suggestions => {

        // make promise for each suggestion
        const promises: Promise<Address>[] = suggestions.map(suggestion =>
          this.arcGisApiService.findAddressBasedOnSuggestion(this.searchString, suggestion.magicKey)
            .catch(error => {
              const msg = 'error while finding location for one of the suggestions';
              console.error(`${msg} :: ${error}`);
              return Promise.resolve(null);
            }));

        Promise.all(promises).then(addresses => {
          this.addresses = addresses.filter(address => address != null).map((address, index) =>
            LocationInfo.fromArcGisGeocodeResponse(address, index + 1));

          this.addressItems = this.addresses.map(this.mapLocationsToLeftItems());
          this.addressMapContent = new AddressLookupAddressMapContent(
            this.addresses, markerId => this.markerHighlighted(markerId));
          this.mapContent = this.addressMapContent;
          onDone(true);
        });
      }).catch(reason => {
        const msg = 'error while suggesting addresses';
        this.toastService.long(msg);
        console.error(`${msg} :: ${reason}`);
        onDone(false);
      });
    }
  }
}
