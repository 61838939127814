<div class="left-panel-container noselect">
  <div class="collapse__btn__wrapper">
    <app-map-panel-toggle-button></app-map-panel-toggle-button>
  </div>

  <div class="pt-15">
    <button mat-button
            color="accent"
            (click)="goBack()"
    >
      <mat-icon>arrow_back</mat-icon>
      <span class="text-primary" *ngIf="source === LiveMapTab.ASSETS || source === LiveMapTab.OBSERVATIONS">Back to Live Map</span>
      <span class="text-primary" *ngIf="source === InsightsRoute.SHIFT_REPORT">Back to Shift Report</span>
      <span class="text-primary" *ngIf="source === InsightsRoute.SHIFT_LOOKUP">Back to Shift lookup</span>
      <span class="text-primary" *ngIf="source === InsightsRoute.DVIR_REPORT">Back to Inspection Form retrieval</span>
      <span class="text-primary" *ngIf="source === InsightsRoute.IMAGE_REPORT">Back to Image Retrieval</span>
      <span class="text-primary" *ngIf="source === InsightsRoute.ADDRESS_LOOKUP">Back to Address Lookup</span>
    </button>
  </div>

  <div class="shift__details__container px-15">
    <div class="shift__details__wrapper__header mt-15">
      <div class="mat-headline-5 text-primary-dark bolder">Shift Details</div>
      <div class="mat-body-1 text-primary m0">
        {{ !!shift.vehicle.label ? shift.vehicle.label : shift.vehicle.name }}
      </div>
      <div class="mat-body-1 text-primary m0">
        {{ shift.start | date: 'medium' }}
      </div>
    </div>
    <div class="shift__details__wrapper__body mt-25">
      <div class="mat-body-1 text-primary-dark m0">SHIFT STATISTICS</div>
      <div *ngIf="statsLoading" class="stats__container__loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <div class="stats__container" *ngIf="!statsLoading">
        <div class="stats__row">
          <div class="mat-body">Vehicle:</div>
          <div class="mat-body">{{ !!shift.vehicle.label ? shift.vehicle.label : shift.vehicle.name }}</div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Driver:</div>
          <div class="mat-body">{{ shift.driver.name }}</div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Start Time::</div>
          <div class="mat-body">
            {{ shift.start | date: 'MM/dd/yy HH:mm' }}
          </div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Finish Time:</div>
          <div class="mat-body">{{ shift.end | date: 'MM/dd/yy HH:mm' }}</div>
        </div>
          <ng-container *ngIf="shift.statusStatistics === StatisticsStatus.ERROR">
              <div class="stats__row mt-10 mb-10">
                  <span>Statistics processing failed</span>
                  <button mat-button color="warn" (click)="loadStats()">Refresh</button>
              </div>
          </ng-container>
          <ng-container *ngIf="shift.statusStatistics === StatisticsStatus.READY || shift.statusStatistics === StatisticsStatus.PROCESSING">
              <div class="stats__row mt-10 mb-10">
                  <span>Processing statistics...</span>
                  <button mat-button color="accent" (click)="loadStats()">Refresh</button>
              </div>
          </ng-container>
          <ng-container *ngIf="shift.statusStatistics === StatisticsStatus.COMPLETE">
              <div class="stats__row">
                  <div class="mat-body">Duration:</div>
                  <div class="mat-body">{{ getDuration() }}</div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Driven:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ stats.trackLength | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Plowed:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distancePlowed | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Spread (Granular):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceSpreadGranular | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Spread (Liquid):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceSpreadLiquid | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Mowed:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceMowed | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Swept:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceSwept | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Material Used (Granular):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.materialUsedGranular | weight:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Material Used (Liquid):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.materialUsedLiquid | volume:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">GPS Source:</div>
                  <div class="mat-body">{{ getGpsSource() }}</div>
              </div>
          </ng-container>

        <div class="stats__row" *ngIf="!!deviceInfo?.phoneNumber">
          <div class="mat-body">Phone number:</div>
          <div class="mat-body">{{ deviceInfo?.phoneNumber }}</div>
        </div>
      </div>

      <div class="shift__details__wrapper__body mt-25">
        <div class="mat-body-1 text-primary-dark m0">INSPECTION FORMS</div>
        <div class="stats__container">
          <div class="stats__row mb-10" *ngIf="!!shift.startInspectionId">
            <div class="mat-body">Pre-Trip Inspection:</div>
            <button
              mat-button
              color="accent"
              (click)="previewDVIR(true)"
            >
              View Form
            </button>
          </div>
          <div class="stats__row mb-10" *ngIf="!!shift.endInspectionId">
            <div class="mat-body">Post-Trip Inspection:</div>
            <button
              mat-button
              color="accent"
              (click)="previewDVIR(false)"
            >
              View Form
            </button>
          </div>
          <div *ngIf="!shift.startInspectionId && !shift.endInspectionId">
            <div class="mat-body">No Inspection Form Links Available</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
