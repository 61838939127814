<mat-spinner *ngIf="!incidentInfo" [diameter]="18"></mat-spinner>

<div *ngIf="!!incidentInfo">
    <h1>{{ incidentInfo.incidenttype }}</h1>

    <ng-container>
        <h2>{{ incidentInfo.location }}<br />{{ incidentInfo.description }}</h2>
        <div>{{ incidentInfo.fulldescription }}</div>

        <div>
            <span>Started:</span>
            <span>
                {{ incidentInfo.start_utctime | date:'medium' }}
            </span>
        </div>

        <div>
            <span>Expected to end:</span>
            <span>
                {{ incidentInfo.end_utctime | date:'medium' }}
            </span>
        </div>

        <div>
            <span>Last updated:</span>
            <span>
                {{ incidentInfo.lastupdated_utctime | date:'medium' }}
            </span>
        </div>
    </ng-container>

</div>

