import { loadAnimations } from '../../animations/load-animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { ConfigurationModel, FeatureFlagEnum } from '../../models/configuration.model';
import { SecurityService } from '../../../security/security.service';
import { MatMenuTrigger } from '@angular/material/menu';
import {RouterLinkActive} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [loadAnimations.opacityLoadIn],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class NavigationComponent implements OnInit {

  @Input() configuration: ConfigurationModel;
  @Output() toggleMenu = new EventEmitter();
  @Output() darkMode = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  username: string;
  userData$: Observable<any>;

  routeLinks = [
    /*{ link: 'dashboard', name: 'Dashboard', icon: 'assessment' },*/
    { link: 'live-map', name: 'Live Map', icon: 'map' },
    { link: 'insights', name: 'Insights', icon: 'info' },
    { link: 'settings', name: 'Setup', icon: 'settings' },
  ];

  dashboardVersion = environment.version;
  envName = environment.name;

  FeatureFlagEnum = FeatureFlagEnum;

  constructor(
    private securityService: SecurityService,
    private oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit(): void {
    this.userData$ = this.securityService.getUserData();

    this.oidcSecurityService.isAuthenticated$.subscribe(
        ({ isAuthenticated }) => {
          this.securityService.getUserName().then(userName => {
            this.username = userName;
          });
        }
    );
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
        this.configuration.featureFlags.find(
            (value) => value.isEnabled && value.name === featureFlag
        ) !== undefined
    );
  }

  logout() {
    this.securityService.logout();
  }

  // workaround, if directly accessed - variable not resolved
  isActive(rla: any) {
    return (rla as RouterLinkActive).isActive;
  }
}
