import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DateFilterDropdownComponent} from '../../../../../../shared/components/date-filter-dropdown/date-filter-dropdown.component';
import {LabeledDateFilter} from '../../../../../../shared/models/DateFilter';


@Component({
  selector: 'app-date-range-filter-button',
  templateUrl: './date-range-filter-button.component.html',
  styleUrls: ['./date-range-filter-button.component.scss']
})
export class DateRangeFilterButtonComponent {

  @Input() origin;
  @Output() filterChanged = new EventEmitter<LabeledDateFilter>();

  filter: LabeledDateFilter;

  @ViewChild(DateFilterDropdownComponent) dateFilterComponent: DateFilterDropdownComponent;

  onDateFilterChange(filter: LabeledDateFilter) {
    this.filter = filter;
    this.filterChanged.emit(filter);
  }
}
