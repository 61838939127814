import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {HardwareFilterComponent, HardwareType} from '../../../../../../shared/components/hardware-filter/hardware-filter.component';

@Component({
  selector: 'app-hardware-type-filter-button',
  templateUrl: './hardware-type-filter-button.component.html',
  styleUrls: ['./hardware-type-filter-button.component.scss']
})
export class HardwareTypeFilterButtonComponent {

  @Input() origin;
  @Input() disabled = false;
  @Output() filterChanged = new EventEmitter<HardwareType>();

  selectedItem: HardwareType;

  @ViewChild(HardwareFilterComponent) hardwareFilterComponent: HardwareFilterComponent;

  onHardwareFilterChange(filter: HardwareType) {
    this.selectedItem = filter;
    this.filterChanged.emit(filter);
  }

  clear() {
    this.selectedItem = null;
    this.hardwareFilterComponent.setFilter(null);
  }
}
