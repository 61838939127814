import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {RoutesService} from '../../../../../data/routes/routes.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../../../../shared/services/toast.service';
import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../../../../shared/models/route';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {RoutesManagerService} from '../../../../../data/routes/routes-manager.service';
import {LiveMapDataService} from '../../../services/live-map-data.service';
import {RouteAssignmentService} from '../../../../../data/routes/route-assignment.service';
import {RouteAssignment, RouteAssignmentQueue} from '../../../../../shared/models/route-assignment';
import {RouteAssignmentManagerService} from '../../../../../data/routes/route-assignment-manager.service';
import {Subscription} from 'rxjs';
import {ListWithUpdates} from '../../../../../shared/models/list-with-updates.class';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {Asset} from '../../../models/asset.class';
import {ShiftState} from '../../../../../shared/models/shift.model';
import {ObservationsManagerService} from '../../../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../../../data/images/images-manager.service';
import {SecurityService} from '../../../../../security/security.service';
import {ISettingKeyValuePair, SettingsService} from '../../../../../configuration/settings.service';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit, OnDestroy {

  isLoading = true;
  isAdmin: boolean;
  showingRouteLevel = false;
  assigningMode = false;
  color = '#ff0000';
  routeStatusMode = false;

  readonly assignmentsQueuedCountMap: Map<string, number> = new Map<string, number>();
  vehicleIdsWithAssignmentInProgress: number[] = [];

  routeConfigurations: RouteConfigurationWithSchema[];
  routeItems: RouteHierarchyItem[];
  currentLevelItems: ActionMenuItem[] = [];

  path: string[] = [];
  selectedAsset: Asset;
  currentParentItem: RouteHierarchyItem;

  assets: Asset[] = [];
  availableItemsForAssigning: ActionMenuItem[] = [];

  @ViewChild('rightPanelTemplate') rightPanelTemplate: TemplateRef<string>;
  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private securityService: SecurityService,
      private settingsService: SettingsService,
      private routesService: RoutesService,
      private routeManager: RoutesManagerService,
      private routeAssignmentService: RouteAssignmentService,
      private routeAssignmentManager: RouteAssignmentManagerService,
      private liveMapDataService: LiveMapDataService,
      private assetsManager: AssetsManagerService,
      private observationManager: ObservationsManagerService,
      private imageManager: ImagesManagerService,
      private toast: ToastService,
  ) {
  }

  ngOnInit() {
    this.liveMapDataService.sendRightPanelWidth(250);
    this.isAdmin = this.securityService.isAdminSync();
    this.observationManager.hideAll();
    this.imageManager.filterByShift(null);
    this.routeStatusMode = this.settingsService.getBooleanValue(SettingsService.ROUTE_STATUS_MODE);

    const assetsSubscription = this.assetsManager.assets$.subscribe(assets => {
      this.assets = assets;
    });
    this.openSubscriptions.push(assetsSubscription);

    this.routeManager.updateVisibility(true);
    const routeConfigSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfigurations => {
      this.routeConfigurations = routeConfigurations;
      this.routeItems = routeConfigurations
          .sort(RouteConfigurationWithSchema.routeConfigurationCompareFn)
          .map(routeConfig => {
            return this.routeConfigToHierarchy(routeConfig);
          });

      this.activatedRoute.queryParamMap.subscribe(paramMap => {
        const pathIdsQueryParam = paramMap.get('path-ids');
        if (!!pathIdsQueryParam) {
          this.path = pathIdsQueryParam.split(':::');
          if (this.path.length > 0) {
            const routeConfig = this.routeConfigurations.find(conf => conf.id === +this.path[0]);
            const routeConfigSchemaLength = routeConfig?.schema?.classification?.length;
            this.showingRouteLevel = (this.path.length - 1) === routeConfigSchemaLength;
          } else {
            this.showingRouteLevel = false;
          }
        } else {
          this.path = [];
        }

        this.selectedAsset = null;
        const selectedAssetId = paramMap.get('id');
        if (!!selectedAssetId) {
          if (this.path.length === 0) {
            console.error('Incorrect set of parameters! Path cannot be empty with selected item set.');
          } else {
            const routeConfig = this.routeConfigurations.find(conf => conf.id === +this.path[0]);
            const routeConfigSchemaLength = routeConfig?.schema?.classification?.length;
            if ((this.path.length - 1) !== routeConfigSchemaLength) {
              console.error('Path length doesn\'t match with route config schema!');
            } else {
              this.selectedAsset = this.assets.find(asset => asset.id === +selectedAssetId);
            }
          }
        }
        this.updateRouteManagerFilter();
        this.updateUiModel();
      });
    });
    this.openSubscriptions.push(routeConfigSubscription);

    const routeAssignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
      this.vehicleIdsWithAssignmentInProgress = assignmentsUpdate.state
          .filter(assignment => !assignment.completed && !!assignment.onAssignmentFrom)
          .map(assignment => assignment.vehicleId);
      this.handleViewingModeChange();
      this.updateQueuedRouteAssignments(assignmentsUpdate);
      this.updateUiModel();
    });
    this.openSubscriptions.push(routeAssignmentsSubscription);

    const settingsChangedSubscription = this.settingsService.settingsChangedObservable.subscribe(
        (newSettings: ISettingKeyValuePair) => {
          if (newSettings.key === SettingsService.ROUTE_STATUS_MODE) {
            this.routeStatusMode = newSettings.value;
            this.handleViewingModeChange();
          }
        }
    );
    this.openSubscriptions.push(settingsChangedSubscription);
  }

  ngOnDestroy() {
    this.routeManager.updateVisibility(false);
    this.openSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  showUpperHierarchyLevel() {
    this.assigningMode = false;
    this.path.pop();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {'path-ids': this.path.join(':::'), id: undefined},
      queryParamsHandling: '',
    });
  }

  getPathLabel(): string {
    return RouteHierarchyItem.getPathLabel(this.path, this.routeItems);
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItem {
    if (!!routeConfiguration.schema.style) {
      this.color = routeConfiguration.schema.style.color;
    }

    const items = RouteHierarchyItem.getVisibleItems(routeConfiguration.configuration.children, routeConfiguration.id);
    return {
      routeId: -1,
      routeName: routeConfiguration.name,
      children: items,
      childrenAttribute: '',
      value: String(routeConfiguration.id)
    };
  }

  private updateRouteManagerFilter() {
    if (this.path.length === 0) {
      this.routeManager.updateRouteFilter(
          null,
          null,
      );
    } else {
      const routeConfigPath = this.path.slice(1);
      this.routeManager.updateRouteFilter(
          +this.path[0],
          routeConfigPath,
      );
    }
  }

  private updateQueuedRouteAssignments(assignmentUpdate: ListWithUpdates<RouteAssignment>) {
    if (this.assignmentsQueuedCountMap.size === 0 ||
        (assignmentUpdate.updated.length === 0 && assignmentUpdate.removed.length === 0 && assignmentUpdate.added.length === 0)
    ) {
      // initialization
      this.assignmentsQueuedCountMap.clear();
      const routeKeys = assignmentUpdate.state
          .filter(assignment => !assignment.completed)
          .map(assignment => {
            return {routeConfigId: assignment.configId, routeId: assignment.routeId};
          });
      const counts = {};
      routeKeys.forEach((routeKey) => {
        counts[`${routeKey.routeConfigId}_${routeKey.routeId}`] = (counts[`${routeKey.routeConfigId}_${routeKey.routeId}`] || 0) + 1;
      });
      Object.entries(counts).forEach(([key, value]) => {
        this.assignmentsQueuedCountMap.set(key, +value);
      });
    } else {
      if (assignmentUpdate.added.length > 0) {
        assignmentUpdate.added.forEach(assignment => {
          const routeKey = `${assignment.configId}_${assignment.routeId}`;
          let count = this.assignmentsQueuedCountMap.get(routeKey);
          count = !!count ? count : 0;
          this.assignmentsQueuedCountMap.set(routeKey, count + 1);
        });
      }
      if (assignmentUpdate.removed.length > 0) {
        assignmentUpdate.removed.forEach(assignment => {
          const routeKey = `${assignment.configId}_${assignment.routeId}`;
          const count = this.assignmentsQueuedCountMap.get(routeKey);
          if (!!count) {
            const newCount = count - 1;
            if (newCount > 0) {
              this.assignmentsQueuedCountMap.set(routeKey, newCount);
            } else {
              this.assignmentsQueuedCountMap.delete(routeKey);
            }
          }
        });
      }
      if (assignmentUpdate.updated.length > 0) {
        assignmentUpdate.updated.forEach(assignment => {
          const routeKey = `${assignment.configId}_${assignment.routeId}`;
          const count = this.assignmentsQueuedCountMap.get(routeKey);
          // if assignment was completed then remove from queue sum
          if (!!assignment.completed) {
            const newCount = count - 1;
            if (newCount > 0) {
              this.assignmentsQueuedCountMap.set(routeKey, newCount);
            } else {
              this.assignmentsQueuedCountMap.delete(routeKey);
            }
          }
        });
      }
    }
  }

  private updateUiModel() {
    this.currentParentItem = null;
    if (!!this.routeConfigurations) {
      // based on path and selectedItem
      //   update currentLevelItems
      //   update width of right panel
      //   update right panel items
      if (this.path.length === 0) {
        this.currentLevelItems = this.routeItems.map(routeItem => {
          const routeConfig = this.routeConfigurations.find(conf => conf.id === +routeItem.value);
          return this.routeHierarchyItemToActionMenuItem(
              routeItem,
              routeConfig.schema.classification.length
          );
        });
        this.routeManager.updateAssignmentFilter(null, null);
        return;
      }
      const routeConfiguration = this.routeConfigurations.find(conf => conf.id === +this.path[0]);
      this.currentParentItem = RouteHierarchyItem.getByPath(this.path, this.routeItems);
      const filteredRouteItems = this.currentParentItem.children;
      if (!!filteredRouteItems) {
        this.currentLevelItems = filteredRouteItems.map(routeItem => {
          return this.routeHierarchyItemToActionMenuItem(
              routeItem,
              routeConfiguration.schema.classification.length - this.path.length
          );
        });
      } else {
        // last asset level
        const assignments = this.routeAssignmentManager.getAssignmentsForRoute(this.currentParentItem.configId, this.currentParentItem.routeId)
            .map(assignment => assignment.vehicleId);
        this.currentLevelItems = this.assets
            .filter(asset => assignments.includes(asset.id))
            .map(asset => this.assetToActionMenuItem(asset));
        this.updateAvailableItemsForAssigning();
      }
      this.setRouteAssignmentsFilter(routeConfiguration, this.currentParentItem);
    }
  }

  private isAssetSelected(asset: Asset): boolean {
    if (!!this.selectedAsset) {
      return this.selectedAsset.id === asset.id;
    } else {
      return false;
    }
  }

  private selectRouteHierarchyItem(routeHierarchyItem: RouteHierarchyItem) {
    this.path.push(routeHierarchyItem.value);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {'path-ids': this.path.join(':::'), id: undefined},
      queryParamsHandling: '',
    });
  }

  private selectAsset(asset: Asset) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {'path-ids': this.path.join(':::'), id: asset.id},
      queryParamsHandling: '',
    });
  }

  private viewAssetDetail(asset: Asset) {
    this.router.navigate(['/live-map', 'assets', asset.id]);
  }

  private assignAsset(asset: Asset) {
    if (!!this.currentParentItem) {
      const routeAssignment = new RouteAssignment();
      routeAssignment.configId = this.currentParentItem.configId;
      routeAssignment.routeId = this.currentParentItem.routeId;
      routeAssignment.routeName = this.currentParentItem.value;
      routeAssignment.vehicleId = asset.id;
      routeAssignment.shiftId = asset.shiftId;
      this.routeAssignmentService.createRouteAssignment(routeAssignment, RouteAssignmentQueue.END)
          .then(response => {
            this.updateUiModel();
            this.toast.short(`Vehicle ${asset.name} assigned to Route '${routeAssignment.routeName}'!`);
          })
          .catch(error => {
            this.toast.longer('Error while assigning route: ' + error);
          });
    } else {
      console.warn('Missing current parent value!');
    }
  }

  private routeHierarchyItemToActionMenuItem(routeHierarchyItem: RouteHierarchyItem, leafPathLength: number): ActionMenuItem {
    let queuedAssignmentCount = 0;
    if (!!routeHierarchyItem.routeId && routeHierarchyItem.routeId > 0) {
      // already on leaf level
      if (leafPathLength > 0) {
        console.warn('This should not happen! Expecting leafPathLevel=0');
      }
      const routeKey = `${routeHierarchyItem.configId}_${routeHierarchyItem.routeId}`;
      const routeCount = this.assignmentsQueuedCountMap.get(routeKey);
      queuedAssignmentCount = !!routeCount ? routeCount : 0;
    } else {
      // get route keys from leaves and get current route count in progress
      const routeKeys = RouteHierarchyItem.getLeaves(
          leafPathLength,
          routeHierarchyItem.children
      ).map(item => `${item.configId}_${item.routeId}`);
      routeKeys.forEach(routeKey => {
        const routeCount = this.assignmentsQueuedCountMap.get(routeKey);
        queuedAssignmentCount = queuedAssignmentCount + (!!routeCount ? routeCount : 0);
      });
    }
    return new ActionMenuItem(
        routeHierarchyItem.routeId,
        'route',
        routeHierarchyItem.routeId === -1 ? routeHierarchyItem.routeName : routeHierarchyItem.value,
        `${queuedAssignmentCount} Assignment${queuedAssignmentCount === 1 ? '' : 's'} in queue`,
        '',
        !!routeHierarchyItem.children ? 'chevron_right' : null,
        () => false,
        null,
        () => this.selectRouteHierarchyItem(routeHierarchyItem),
        null,
        null,
        [],
    );
  }

  private assetToActionMenuItem(asset: Asset): ActionMenuItem {
    return new ActionMenuItem(
        asset.id,
        'directions_car',
        asset.name,
        asset.driverName,
        '',
        null,
        () => this.isAssetSelected(asset),
        null,
        () => this.selectAsset(asset),
        null,
        null,
        [
            new ActionMenuItemSubMenu(
                'visibility',
                'View Asset Detail',
                () => this.viewAssetDetail(asset),
            )
        ],
    );
  }

  private setRouteAssignmentsFilter(routeConfiguration: RouteConfigurationWithSchema, routeHierarchyItem: RouteHierarchyItem) {
    let routeConfigId;
    let routeIds;
    if (this.path.length > 0) {
      routeConfigId = +this.path[0];
      const targetPath = routeConfiguration.schema.classification.length - this.path.length + 1;
      if (targetPath > 0) {
        routeIds = RouteHierarchyItem.getLeaves(
            targetPath,
            routeHierarchyItem.children
        ).map(item => item.routeId);
      } else {
        const routeId = routeHierarchyItem.routeId;
        if (!routeId) {
          console.warn('Expecting non-null routeId!');
          routeIds = null;
        } else {
          routeIds = [routeId];
        }
      }
    } else {
      routeConfigId = null;
      routeIds = null;
    }
    this.routeManager.updateAssignmentFilter(routeConfigId, routeIds);
  }

  private updateAvailableItemsForAssigning() {
    if (this.assigningMode) {
      this.availableItemsForAssigning = this.assets
          .filter(asset => {
            return asset.shiftStatus !== ShiftState.ENDED &&
                this.currentLevelItems.findIndex(item => item.id === asset.id) === -1 &&
                !asset.hasNoTablet;
          })
          .map(asset => {
            return new ActionMenuItem(
                asset.id,
                'directions_car',
                asset.name,
                asset.driverName,
                '',
                null,
                () => false,
                null,
                () => this.assignAsset(asset),
                null,
                null,
                [],
            );
          });
    }
  }

  toggleAssigningMode() {
    this.assigningMode = !this.assigningMode;
    this.updateAvailableItemsForAssigning();
  }

  handleViewingModeChange() {
    if (this.routeStatusMode) {
      this.assetsManager.filterByVehicleIds(this.vehicleIdsWithAssignmentInProgress);
    } else {
      this.assetsManager.filterByVehicleIds(null);
    }
  }
}
