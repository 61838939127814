<mat-card
  appearance="outlined"
  class="map-card-container text-primary-dark"
  (click)="viewShift()"
>
  <mat-card-header>
    <div class="card-header">
      <div class="header__wrapper">
        <span>
          {{ shift.start | date: 'medium' }} - {{ shift.end | date: 'medium' }}
        </span>

        <button
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
                class="header__button"
                mat-icon-button
                aria-label="Three dot icon button"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="canDeleteShift()"
                  (click)="confirmDeletingShift()"
                  mat-menu-item>
            <mat-icon>delete</mat-icon>
            Delete
          </button>
          <button (click)="viewShift()"
                  mat-menu-item>
            <mat-icon>map</mat-icon>
            View Shift
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="text-primary-dark">
    <div class="map__image__wrapper">
      <app-card-loading-spinner *ngIf="isLoading"
      ></app-card-loading-spinner>
      <img
              *ngIf="!isLoading && imageData && token && !!configuration"
              class="map__image"
              [src]="imageData"
              [alt]="'Shift ' + shift.id + ': ' + shift.driver.name"
              [matTooltip]="(shift.distanceDriven | distance:configuration.useMetricSystem) + ' | ' + getDuration()"
      />
      <span *ngIf="!isLoading && !imageData">No track data available for this shift</span>
    </div>
  </mat-card-content>
</mat-card>
