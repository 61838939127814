import {MifNodeType} from './MifNodeType';
import {MifNodeTypes} from './MifNodeTypes';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MifNodeDialogComponent} from '../../../form/mif-node-dialog/mif-node-dialog.component';
import {MifSectionDto} from '../../dto/MifSectionDto';
import {MifGroupDto} from '../../dto/MifGroupDto';
import {MifQuestionDto} from '../../dto/MifQuestionDto';
import {TextNodeDialogData} from '../../../form/mif-node-dialog/NodeDialogData';

export class MifQuestionType implements MifNodeType {

  constructor(text: string, notApplicable?: boolean) {
    this.text = text;
    this.notApplicable = notApplicable;
  }

  text: string;
  notApplicable?: boolean;

  static fromDto(dto: MifQuestionDto): MifQuestionType {
    return new MifQuestionType(dto.title, dto.notApplicable);
  }

  static showCreateDialog(dialogs: MatDialog): MatDialogRef<MifNodeDialogComponent> {
    return dialogs.open(MifNodeDialogComponent, {
      data: new TextNodeDialogData(
        'Create question',
        'Question',
        'Text',
        '',
        false,
        'Create',
      ),
      width: '300px',
    });
  }

  nodeLabel(): string {
    return this.text;
  }

  nodeType(): MifNodeTypes {
    return MifNodeTypes.QUESTION;
  }

  showEditDialog(dialogs: MatDialog, afterEdit: () => void) {
    const dialogRef = dialogs.open(MifNodeDialogComponent, {
      data: new TextNodeDialogData(
        'Edit question',
        'Question',
        'Text',
        this.text,
        false,
        'Edit',
        this.notApplicable
      ),
      width: '300px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        const data = result as TextNodeDialogData;
        this.text = data.textField;
        this.notApplicable = data.notApplicable;
        afterEdit();
      }
    });
  }

  sectionDto(externalId: number, order: number): MifSectionDto {
    return null;
  }

  groupDto(externalId: number, order: number): MifGroupDto {
    return null;
  }

  questionDto(externalId: number, order: number): MifQuestionDto {
    return new MifQuestionDto(
      externalId === 0 ? null : externalId,
      order,
      this.text,
      this.notApplicable
    );
  }

  hasNodeTypeInstanceRemarks(): boolean {
    return false;
  }
}
