import {ObservationTypeGroup} from '../../../shared/models/observation-group';

export class LiveMapObservation {
    activeShift: boolean;
    deletable: boolean;
    constructor(
        public id: number,
        public title: string,
        public observationTypeGroup: ObservationTypeGroup,
        public shiftId: number,
        public vehicleId: number,
        public time: Date,
    ) {
        this.deletable = true;
        this.activeShift = true;
    }
}
