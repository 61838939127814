<h1 mat-dialog-title>
    Shift Report Settings
</h1>

<mat-dialog-content>
    <div class="data-section">Displayed columns</div>
    <div class="data-section-wrapper">
        <ng-container *ngFor="let column of data.columns">
            <mat-checkbox class="form-input" [(ngModel)]="column.displayed">{{ column.name }}</mat-checkbox>
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="buttonClickedOk()" [disabled]="!isDialogValid()">
        OK
    </button>
</mat-dialog-actions>
