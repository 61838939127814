import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ShiftWithDriverAndVehicleModel} from '../../../models/shift.model';

@Component({
  selector: 'app-confirm-delete-shift-dialog',
  templateUrl: './confirm-delete-shift-dialog.component.html',
  styleUrls: ['./confirm-delete-shift-dialog.component.scss']
})
export class ConfirmDeleteShiftDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteShiftDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShiftWithDriverAndVehicleModel) {}

  handleCancelClick(): void {
    this.dialogRef.close();
  }
}
