import {Component, Inject, OnInit} from '@angular/core';
import {AlertGeoJson, WeatherWarningsLayerService} from '../map-viewer/services/weather-warnings-layer.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '../../services/toast.service';
import {Feature} from 'geojson';

@Component({
  selector: 'app-weather-warning',
  templateUrl: './weather-warning.component.html',
  styleUrls: ['./weather-warning.component.scss']
})
export class WeatherWarningComponent implements OnInit {

  isLoading;
  alert: AlertGeoJson;
  index = 0;

  constructor(
      private weatherWarningsService: WeatherWarningsLayerService,
      public dialogRef: MatDialogRef<WeatherWarningComponent>,
      @Inject(MAT_DIALOG_DATA) public data: Feature[],
      private dialog: MatDialog,
      private toast: ToastService,
  ) {
  }

  ngOnInit() {
    const oid = this.data[0].properties.cap_id;
    this.loadAlert(oid);
  }

  private loadAlert(oid: string) {
    this.weatherWarningsService.getAlert(oid).toPromise().then(alert => {
      this.alert = alert;
      this.isLoading = false;
    }).catch(message => {
      console.log(message);
      this.toast.long('Failed to load data from weather.gov!');
      this.isLoading = false;
    });
  }

  showNext() {
    this.index += 1;
    if (this.index >= this.data.length) {
      this.index = 0;
    }
    this.loadAlert(this.data[this.index].properties.cap_id);
  }
}
